// react
import React, { useEffect, useState } from 'react'
// ui components
import { Button, Dialog, Input, Select } from "../../../components/UI"
// constants (icon)
import { BackIcon, GetDetailsIcon } from "../../../constants/icons"
import { typesOfBody } from "../../../constants/partners-form"
// custom state
import { StateObjType, useDirectSalesAgentForm } from "./useCustomState"
// utils (validation)
import { validateAddress1, validateAddress2, validateCity, validateFirstName, validateLastName, validateContactNumber, validatePassword, validatePincode, validateVehicleNumber } from "../../../utils/validation"
import showMessage, { showHttpError, success } from "../../../../utils/message"
// services
import { getIntraCityRates } from "../../../../services/intraCityRate"
import { getCityByPincode } from "../../../../services/area"
import { getOtp, individualData, verifyOtpForReg } from "../../../../services/area"
// components
import { OTPInput } from "./OTPInput"
import { resetState } from "../../../utils/helper"
import { listAssetSubType, listassetType } from "../../../../services/assetType"
import VehicleType from "../../../components/VehicleType/VehicleType"
import { OtpTypes } from '../../../../utils/otp'


// FC: IndividualWithFleet Component
const IndividualWithFleet = () => {
  // State
  const { firstName, setFirstName, lastName, setLastName, password, setPassword, contactNumber, setContactNumber, otp, setOtp, addressLine1, setAddressLine1, addressLine2, setAddressLine2, pincode, setPincode, city, setCity, isOtp, setIsOtp, currentPageOfIndividualWithFleet, setCurrentPageOfIndividualWithFleet, cities, setCities, typesOfFleets, setTypesOfFleets, vehicleNumber, setVehicleNumber, selectedFleetType, setSelectedFleetType, selectedSubFleetType, setSelectedSubFleetType, selectedCity, setSelectedCity, selectedBodytype, setSelectedBodytype, setTypesOfSubFleets } = useDirectSalesAgentForm();
  const [pincodeCityName, setPincodeCityName] = useState("");

  // constants
  // does: find city options
  const citiesOption = Array.from(new Set(cities?.map((e: any) => e.name))).map(name => {
    const city = cities.find((e: any) => e.name === name);
    return {
      label: city.name,
      value: city._id
    };
  });
  // does: find vehicle options
  console.log(`typesOfFleets 2.0`, typesOfFleets);
  const vehicleOption = typesOfFleets?.map((ele: any) => {
    return {
      label: ele?.name,
      value: ele?._id
    };
  });
  console.log(`typesOfFleets 2.0 vehicleOption`, vehicleOption);
  // does: fetch city by pin
  async function fetchCityByPin() {
    try {
      if (typeof pincode !== "object" || typeof city !== "object") return;
      const response = await getCityByPincode(pincode.value);
      // setCityId(response?._id)
      return [city.value == response?.name, response?._id]
    } catch (error) {
      showHttpError(error);
    }
  }
  // does: get the city list
  async function getCityList() {
    try {
      let payload = { type: "EXPRESS" }
      const basicCityList: any = [];
      const responseForCity = await getIntraCityRates(payload);
      responseForCity.map((ele: any) => {
        basicCityList.push(ele.city)
      })
      const FinalCityList: any = [...new Map(basicCityList.map((x: any) => [x["name"], x])).values()]
      setCities(FinalCityList);
    } catch (error) {
      showHttpError(error);
    }
  }
  // does: get all types of vehicle list
  async function assetTypeList() {
    try {
      let response = await listassetType();
      setTypesOfFleets(response);
    } catch (error) {
      showHttpError(error);
    }
  }
  // does: get all types of vehicle list
  async function assetSubTypeList() {
    const data = await listAssetSubType();
    setTypesOfSubFleets(data);
    console.log("Console data", data);
  }
  // does: get the otp
  async function handleGetOtp(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let flag = validateFirstName(firstName, setFirstName) && validateLastName(lastName, setLastName) && validatePassword(password, setPassword) && validateContactNumber(contactNumber, setContactNumber);
    if (typeof firstName === "string" || typeof lastName === "string" || typeof password === "string" || typeof contactNumber === "string") return;
    if (!contactNumber.value || !firstName.value || !lastName.value  || !password.value) {
      alert("Please provie all fields!");
      return;
    }
    if (contactNumber.error || firstName.error || lastName.error || password.error) {
      if (!flag) {
        return;
      }
      alert("Please provide all field correctly!");
      return;
    }
    if (!flag) {
      return;
    }
    setIsOtp(true);
    if (typeof contactNumber !== "object") return;
    await getOtp({
      contact: +(contactNumber.value),
      otpType: OtpTypes.IndividualWithFleetVerification
    });
    showMessage("Otp sent successfully!", success, 2000)
  }
  // does: handle the OTP
  async function handleOtp() {
    if (typeof otp !== "object") return;
    if (!otp.value) {
      alert("Please enter OTP");
      return;
    } else if (otp.error) {
      alert(otp.errorMessage);
      return;
    } else {
      if (typeof contactNumber !== "object") return;
      const payload = { contact: +(contactNumber.value), otp: +(otp.value), otpType: OtpTypes.IndividualWithFleetVerification }
      const response: any = await verifyOtpForReg(payload);
      if (response) {
        showMessage("Otp verfied successfully!", success, 2000)
        setCurrentPageOfIndividualWithFleet(2);
        setIsOtp(false);
      }
      else {
        showHttpError("Otp MissMatched , Kindly Resend Otp Or Enter Valid Otp")
      }
    }
  }
  // does: handle the form submit
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (typeof firstName !== "object" || typeof lastName !== "object" || typeof password !== "object" || typeof contactNumber !== "object" || typeof addressLine1 !== "object" || typeof addressLine2 !== "object" || typeof pincode !== "object" || typeof city !== "object" || typeof otp !== "object" || typeof vehicleNumber !== "object") return;
    if (!selectedSubFleetType || !selectedFleetType || !vehicleNumber || !selectedCity || !selectedBodytype) {
      return alert("All information is required!");
    }
    try {
      let res: any = await fetchCityByPin();
      let payload: any = {
        contactPerson: {
          name: {
            fName: firstName.value,
            lName: lastName.value
          },
          contact: contactNumber.value,
          password: password.value,
        },
        address: {
          l1: addressLine1.value,
          l2: addressLine2.value,
          pincode: pincode.value,
          city: res[1],
        },
        otp: otp.value,
        individual: false,
        bodyType: selectedBodytype?.value,
        fleetType: selectedFleetType?.value,
        assetSubType: selectedSubFleetType?.value,
        regNumber: vehicleNumber.value,
        allowedOFDCities:[selectedCity?.value]
      }
      await individualData(payload);
      resetState(setFirstName, setLastName, setContactNumber, setPassword, setAddressLine1, setAddressLine2, setPincode, setOtp, setSelectedBodytype, setSelectedFleetType, setVehicleNumber);
      showMessage("Individual With Fleet Registered Successfully", success, 2000);
    } catch (error) {
      showHttpError(error);
    }
    setCurrentPageOfIndividualWithFleet(1);
  }
  // does: validate the second form
  function validateSecondForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (typeof city === "object" && pincodeCityName === city.value) {
      if (!validateAddress1(addressLine1, setAddressLine1) &&
        !validateAddress2(addressLine2, setAddressLine2) &&
        !validateCity(city, setCity) &&
        !validatePincode(pincode, setPincode)) {
        alert("Hello!");
        return
      } else {
        setCurrentPageOfIndividualWithFleet(3);
      }
    } else {
      console.log("This is not the same!")
    }
  }
  // does: call pincode api
  async function callPincodeAPI(e: React.ChangeEvent<HTMLInputElement>) {
    const pincode = e.target.value;

    const isNumeric = /^\d+$/.test(pincode);
    if (!isNumeric) return;

    if (pincode.length === 6) {
      const response = await getCityByPincode(pincode);
      setPincodeCityName(response?.name);
      setCity((prevCity) => {
        const prev = prevCity as StateObjType
        return {
          ...prev,
          value: response?.name
        }
      })
      console.log("Response of PINcode API", response);
    }

    console.log("Pincode API", pincode);
  }

  // useEffect
  // does: get the city list and vechile type list
  useEffect(() => {
    getCityList();
    assetTypeList();
    assetSubTypeList();
  }, [])


  // component return
  // General Details Form
  if (currentPageOfIndividualWithFleet === 1) {
    return (
      <>
        {/* Dialog for OTP */}
        <Dialog header={<>Enter your OTP</>} body={<><OTPInput otp={otp} setOtp={setOtp} /></>} footer={<><Button variant="primary" action="secondary" onClick={handleOtp}>Enter</Button></>} state={isOtp} setState={setIsOtp} variant="primary" size="small" isOutsideClickOff={false} />
        <form className="| flex-col gap-1 m-gap-20 w-100" onSubmit={handleGetOtp}>
          <div className="| flex-between fs-link text-white-400 fw-700">
            <span>General Details</span>
            <span className="">1/3</span>
          </div>
          <div className="| two-column gap-8 t-one-column m-gap-20">
            {/* First Name */}
            <Input title="First Name" variant="white" weight={400} width="1px" state={{ value: firstName, setValue: setFirstName }} onBlur={() => validateFirstName(firstName, setFirstName)} warningWeight={400} required />
            {/* Last Name */}
            <Input title="Last Name" variant="white" weight={400} width="1px" state={{ value: lastName, setValue: setLastName }} onBlur={() => validateLastName(lastName, setLastName)} warningWeight={400} required />
          </div>
          {/* Contact Number */}
          <Input title="Contact Number" type="tel" maxLength={10} variant="white" weight={400} width="1px" state={{ value: contactNumber, setValue: setContactNumber }} onBlur={() => validateContactNumber(contactNumber, setContactNumber)} warningWeight={400} required />
          {/* Email */}
          {/* <Input title="Email" type="email" variant="white" weight={400} width="1px" state={{ value: email, setValue: setEmail }} onBlur={() => validateEmail(email, setEmail)} warningWeight={400} required /> */}
          {/* Password */}
          <Input title="Password" type="password" variant="white" weight={400} width="1px" state={{ value: password, setValue: setPassword }} onBlur={() => validatePassword(password, setPassword)} warningWeight={400} required />
          {/* Get OTP Button */}
          <Button variant="secondary" action="secondary" Icon={<GetDetailsIcon />} className="| as-start fs-button my-1">Get OTP</Button>
        </form>
      </>
    )
  }
  // Address Details Form
  else if (currentPageOfIndividualWithFleet === 2) {
    return (
      <>
        <form className="| flex-col gap-1 w-100" onSubmit={validateSecondForm}>
          <div className="| flex-between fs-link text-white-400 fw-900">
            <span>Address Details</span>
            <span>2/3</span>
          </div>
          {/* Address Line 1 */}
          <Input title="Address Line 1" variant="white" weight={400} width="1px" state={{ value: addressLine1, setValue: setAddressLine1 }} onBlur={() => validateAddress1(addressLine1, setAddressLine1)} warningWeight={400} required />
          {/* Address Line 2 */}
          <Input title="Address Line 2" variant="white" weight={400} width="1px" state={{ value: addressLine2, setValue: setAddressLine2 }} onBlur={() => validateAddress2(addressLine2, setAddressLine2)} warningWeight={400} required />
          <div className="| two-column gap-8 t-one-column">
            {/* Pincode */}
            <Input title="Pincode" type="tel" maxLength={6} variant="white" weight={400} width="1px" onChange={callPincodeAPI} state={{ value: pincode, setValue: setPincode }} onBlur={() => validatePincode(pincode, setPincode)} warningWeight={400} required />
            {/* City */}
            <Input title="City" variant="white" weight={400} width="1px" state={{ value: city, setValue: setCity }} onBlur={() => validateCity(city, setCity)} warningWeight={400} required />
          </div>
          <div className="| flex jc-end ai-center gap-1">
            {/* Previous Button */}
            <Button variant="primary" type="button" action="secondary" Icon={<BackIcon />} className="| fs-button" onClick={() => setCurrentPageOfIndividualWithFleet(1)}>Previous</Button>
            {/* Next Button */}
            <Button variant="secondary" action="secondary" Icon={<GetDetailsIcon />} className="| fs-button">Next</Button>
          </div>
        </form>
      </>
    )
  }
  // Vehicle Info
  else {
    return (
      <>
        <form className="| flex-col gap-1 w-100" onSubmit={handleSubmit}>
          <div className="| flex-between fs-link text-white-400 fw-900">
            <span>Vehicle Info</span>
            <span>3/3</span>
          </div>
          {/* Vehicle Number (Reg Number) */}
          <Input title="Vehicle Number" maxLength={10} placeholder="Ex:- GJ01XX1234" variant="white" weight={400} width="1px" state={{ value: vehicleNumber, setValue: setVehicleNumber }} onBlur={() => validateVehicleNumber(vehicleNumber, setVehicleNumber)} warningWeight={400} required />
          {/* Availability Service */}
          <Select isClearable={true} isSearchable={true} options={citiesOption} value={selectedCity} onChange={(e: any) => setSelectedCity(e)} placeholder={"Select Your Availability Service"} />
          {/* Fleet Type */}
          {/* <Select isClearable={true} isSearchable={true} options={vehicleOption} value={selectedFleetType} onChange={(e: any) => setSelectedFleetType(e)} placeholder={"Select Fleet Type"} /> */}
          {/* Fleet Sub Type */}
          {/* <Select isClearable={true} isSearchable={true} options={vehicleSubOption} value={selectedSubFleetType} onChange={(e: any) => setSelectedSubFleetType(e)} placeholder={"Select Sub Fleet Type"} /> */}
          <VehicleType vehicleTypeValue={selectedFleetType} onVehicleTypeChange={(e: any) => setSelectedFleetType(e)} vehicleTypePlaceholder="Selected Fleet Type" vehicleSubTypeValue={selectedSubFleetType} onVehicleSubTypeChange={(e: any) => setSelectedSubFleetType(e)} />
          {/* Body Type */}
          <Select isClearable={true} isSearchable={true} options={typesOfBody} value={selectedBodytype} onChange={(e: any) => setSelectedBodytype(e)} placeholder={"Select Body Type"} />
          <div className="| flex jc-end ai-center gap-1">
            {/* Previous Button */}
            <Button variant="primary" type="button" action="secondary" Icon={<BackIcon />} className="| fs-button" onClick={() => setCurrentPageOfIndividualWithFleet(2)}>Previous</Button>
            {/* Submit Button */}
            <Button variant="secondary" action="secondary" Icon={<GetDetailsIcon />} className="| fs-button">Submit</Button>
          </div>
        </form>
      </>
    )
  }
}

export default IndividualWithFleet