import React, { Fragment, useState, useEffect, useCallback } from "react";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { connect } from "react-redux";
import { reCaptchaKey } from "../../../constant";
import PartnersForms from "./PartnersForms";


//const styles=useStyles()

// const Step1 = (props: any) => {
//   // const { errors1 } = props;
//   // const styleFn = (base:CSSProperties ) => ({ ...base, border: "5px solid #ccc",height:"50px" });

//   return (
//     <Paper elevation={4} style={{ padding: "36px", borderRadius: "6px" }}>
//       <GridContainer>
//         <Input12
//           id="regNumber"
//           type="text"
//           label="Registration Number"
//           onChange={(e: any) => props.handleChange(["fleetNo"], e.target.value)}
//           value={props.fleetNo}
//         />
//         {/* <div style={{ color: "red" }}>
//           {" "}
//           {errors1.fleetNo && props.fleetNo == ""
//             ? "Fleet No Required"
//             : ""}{" "}
//         </div> */}

//         <Select12
//           placeholder="Fleet Type"
//           onChange={(e: any) => props.handleChange(["fleetType"], e.value)}
//           options={[
//             { label: "Bolero", value: "Bolero" },
//             { label: "Eicher ltd", value: "Eicher" },
//             { label: "Bus", value: "Bus" },
//             { label: "Tempo", value: "Tempo" },
//           ]}
//           value={
//             props.fleetType == ""
//               ? { label: "select fleetType", value: "" }
//               : { label: props.fleetType, value: props.fleetType }
//           }
//         />
//         {/* <div style={{ color: "red" }}>
//           {" "}
//           {errors1.fleetType && props.fleetType == ""
//             ? "Fleet Type  Requeired"
//             : ""}{" "}
//         </div> */}
//         <Select12
//           placeholder="Body Type"
//           onChange={(e: any) => props.handleChange(["bodyType"], e.value)}
//           options={[
//             { label: "Flat", value: "Flat" },
//             { label: "Open", value: "Open" },
//             { label: "Full Covered", value: "Full Covered" },
//             { label: "Half Covered", value: "Half Covered" },
//           ]}
//           value={
//             props.bodyType == ""
//               ? { label: "select bodyType", value: "" }
//               : { label: props.bodyType, value: props.bodyType }
//           }
//         />
//         {/* <div style={{ color: "red" }}>
//           {" "}
//           {errors1.bodyType && props.bodyType == ""
//             ? "bodyType  Requeired"
//             : ""}{" "}
//         </div> */}
//       </GridContainer>
//     </Paper>
//   );
// };

// const Step2 = (props: any) => {
//   return (
//     <Paper elevation={4} style={{ padding: "36px", borderRadius: "6px" }}>
//       <GridContainer>
//         {/* <strong>Contact Person</strong> */}
//         <Input6
//           disabled={false}
//           type="text"
//           id={"fName"}
//           // min={0}
//           margin="none"
//           autoComplete="nope"
//           label="First Name"
//           value={props.fName}
//           onChange={(e: any) => props.handleChange(["fName"], e.target.value)}
//           error={props.errors2.fName && props.fName == ""}
//           message={"First Name  Requeired"}
//         />
//         <Input6
//           disabled={false}
//           type="text"
//           id={"lname"}
//           // min={0}
//           margin="none"
//           autoComplete="nope"
//           label="Last name"
//           value={props.lName}
//           onChange={(e: any) => props.handleChange(["lName"], e.target.value)}
//           error={props.errors2.lName && props.lName == ""}
//           message={"lName  Requeired"}
//         />
//         <Input6
//           disabled={false}
//           type="text"
//           id={"contact"}
//           // min={0}
//           margin="none"
//           // autoComplete={false}
//           label="E-mail"
//           value={props.email}
//           onChange={(e: any) => props.handleChange(["email"], e.target.value)}
//           error={props.errors2.email && props.email == ""}
//           message={"email  Requeired"}
//         />
//         <Input6
//           type="password"
//           margin="none"
//           label="Password"
//           value={props.password}
//           onChange={(e: any) =>
//             props.handleChange(["password"], e.target.value)
//           }
//           error={props.errors2.password && props.password == ""}
//           message={"password  Requeired"}
//         />

//         <Grid md={4} lg={4} sm={9} xs={9} style={{ margin: "8px 0px" }}>
//           <Input
//             disabled={false}
//             type="number"
//             id={"contact"}
//             // min={0}
//             margin="none"
//             autoComplete="nope"
//             label="Contact No"
//             // className={this.props.textFieldClass}
//             value={props.contactNo}
//             onChange={(e: any) =>
//               props.handleChange(["contactNo"], e.target.value)
//             }
//             error={props.errors2.contactNo && props.contactNo == ""}
//             message={"contactNo  Requeired"}
//           />
//         </Grid>

//         <Grid
//           md={2}
//           lg={2}
//           sm={3}
//           xs={3}
//           style={{ paddingLeft: "10px", margin: "8px 0px" }}
//         >
//           <button
//             className="btn btn-primary"
//             style={{ height: "35px", marginTop: "3px" }}
//             onClick={(e) => {
//               e.stopPropagation();
//               props.handelgetOtp();
//             }}
//           >
//             Get OTP{" "}
//           </button>
//         </Grid>
//         <Grid
//           md={6}
//           lg={6}
//           sm={12}
//           xs={12}
//           style={{ paddingLeft: "10px", margin: "8px 0px" }}
//         >
//           <Input12
//             disabled={false}
//             type="number"
//             margin="none"
//             label="Otp"
//             value={props.otp}
//             onChange={(e: any) => props.handleChange(["otp"], e.target.value)}
//             error={props.errors2.otp && props.otp == ""}
//             message={"otp  Requeired"}
//           />
//         </Grid>
//       </GridContainer>
//     </Paper>
//   );
// };

// const Step3 = (props: any) => {
//   return (
//     <Fragment>
//       <Paper elevation={4} style={{ padding: "36px", borderRadius: "6px" }}>
//         <GridContainer>
//           <Input6
//             disabled={false}
//             // style={textFieldStyle}
//             type="text"
//             value={props.line1}
//             onChange={(e: any) => props.handleChange(["line1"], e.target.value)}
//             id={"l1"}
//             // min={0}
//             margin="none"
//             autoComplete="nope"
//             label="Address Line1"
//             className={props.textFieldClass}
//             // style={{ width: "342px" }}
//             error={props.errors3.line1 && props.line1 == ""}
//             message={"line1  Requeired"}
//           />

//           <Input6
//             disabled={false}
//             // style={textFieldStyle}
//             type="text"
//             value={props.line2}
//             onChange={(e: any) => props.handleChange(["line2"], e.target.value)}
//             id={"l2"}
//             // min={0}
//             margin="none"
//             autoComplete="nope"
//             label="Address Line2"
//             className={props.textFieldClass}
//             // style={{ width: "342px" }}
//             message={"line2  Requeired"}
//             error={props.errors3.line2 && props.line2 == ""}
//           />
//           <Input6
//             disabled={false}
//             // style={textFieldStyle}
//             type="number"
//             // value={props.company.getIn(["address", "pincode"])}
//             value={props.pincode}
//             onChange={(e: any) =>
//               props.handleChange(["pincode"], e.target.value)
//             }
//             id={"pincode"}
//             // min={0}
//             margin="none"
//             autoComplete="nope"
//             label="Pincode"
//             className={props.textFieldClass}
//             // style={{ width: "342px" }}
//             error={props.errors3.pincode && props.pincode == ""}
//             message={"pincode  Required"}
//           />
//           <Select6
//             // disabled={!company.getIn(["isNew"])}
//             // styles={{ width: "155px" }}
//             options={props.Cities}
//             value={props.Cities.find((city: any) => {
//               return city.value == props.city;
//             })}
//             onChange={(e: any) => props.handleChange(["city"], e.value)}
//             placeholder="city"
//             // styles={{ width:"155px" }}

//             error={props.errors3.city && props.city == ""}
//             message={"city  Requeired"}
//           />
//         </GridContainer>
//       </Paper>{" "}
//     </Fragment>
//   );
// };

// const Step4 = (props: any) => {
//   return (
//     <Paper elevation={4} style={{ padding: "36px", borderRadius: "6px" }}>
//       <Grid container>
//         <Input12
//           type="text"
//           value={props.displayName}
//           onChange={(e: any) => {
//             props.handleChange(["displayName"], e.target.value);
//           }}
//           label="Display Name"
//           style={{ zIndex: 0 }}
//         />

//         <Input12
//           disabled={false}
//           type="number"
//           id={"account"}
//           // min={0}
//           // maxLength={16}
//           margin="none"
//           autoComplete="nope"
//           label="Account Number"
//           value={props.AccountNo}
//           onChange={(e: any) =>
//             props.handleChange(["AccountNo"], e.target.value)
//           }
//           error={props.errors4.AccountNo && props.AccountNo == ""}
//           message={"AccountNo  Requeired"}
//         />

//         <Input12
//           disabled={false}
//           type="text"
//           onChange={(e: any) => {
//             props.handleChange(["IFSCcode"], e.target.value);
//           }}
//           value={props.IFSCcode}
//           id={"l2"}
//           // min={0}
//           margin="none"
//           autoComplete="nope"
//           label="IFSC Code"
//           error={props.errors4.IFSCcode && props.IFSCcode == ""}
//           message="IFSCcode  Requeired"
//         />
//       </Grid>
//     </Paper>
//   );
// };

// const Step5 = (props: any) => {
//   return (
//     <React.Fragment>
//       <Paper elevation={4} style={{ padding: "36px", borderRadius: "6px" }}>
//         <GridContainer>
//           <Grid md={4} lg={4} sm={4} xs={4}>
//             <span>pancard:</span>
//             <ImageInput
//               name="pancard"
//               Icon={PanIcon}
//               value={props.pancard}
//               onChange={(e) => props.handlePanCardChange(e)}
//             />
//           </Grid>

//           <Grid md={4} lg={4} sm={4} xs={4}>
//             <span>License:</span>
//             <ImageInput
//               name="License"
//               Icon={LicenceIcon}
//               value={props.licence}
//               onChange={(e) => props.handleLicenceUserChange(e)}
//             />
//           </Grid>
//           <Grid md={4} lg={4} sm={4} xs={4}>
//             <span> User Profile:</span>
//             <ImageInput
//               name="User Profile"
//               Icon={CompanyIcon}
//               value={props.userProfile}
//               onChange={(e) => props.handleUserProfileChange(e)}
//             />
//           </Grid>
//           <Input3
//             disabled={false}
//             type="text"
//             id={"pan"}
//             // margin="none"
//             value={props.panNumber}
//             onChange={(e: any) =>
//               props.handleChange(["panNumber"], e.target.value)
//             }
//             autoComplete="nope"
//             label="Pan number"
//           />
//           <Grid md={12} lg={12} sm={12} xs={12} style={{ margin: "8px 0px" }}>
//             <DatePicker
//               name=" Licence Expire Date"
//               placeholderText="Licence Expire Date"
//               selected={props.licenceExpire}
//               dateFormat="dd/MM/yyyy"
//               // showTimeSelect
//               // timeFormat="HH:mm"
//               timeIntervals={5}
//               // timeCaption="time"
//               onChange={(e: any) => props.handleChange(["licenceExpire"], e)}
//               value={props.licenceExpire}
//               autoComplete="off"
//             />
//           </Grid>
//         </GridContainer>
//       </Paper>
//     </React.Fragment>
//   );
// };
const IndivisualRegistration = (props: any) => {
  // const [value, setValue] = useState(0);

  // const initialError1 = {
  //   fleetNo: "",
  //   fleetType: "",
  //   bodyType: "",
  // };

  // const [data, setdata] = useState<any>({
  //   displayName: "",
  //   fleetNo: "",
  //   fleetType: "",
  //   bodyType: "",
  //   fName: "",
  //   lName: "",
  //   email: "",
  //   contactNo: "",
  //   otp: "",
  //   password: "",
  //   line1: "",
  //   line2: "",
  //   pincode: "",
  //   city: "",
  //   AccountNo: "",
  //   IFSCcode: "",
  //   pancard: null,
  //   panNumber: "",
  //   licenceExpire: "",
  //   licence: null,
  //   userProfile: null,
  // });
  // const [pancard, setPancard] = useState<any>(null);
  // const [licence, setlicence] = useState<any>(null);
  // const [userProfile, setuserProfile] = useState<any>(null);
  // const [errors, setErrors] = useState<any>(initialError);
  // const [errors1, setErrors1] = useState<any>(initialError1);
  // const [step, setstep] = useState(1);
  const [token, setToken] = useState("");

  const onVerify = useCallback((token:any) => {
    setToken(token);
  }, []);

  useEffect(() => {
    onVerify(token);
  }, [onVerify]);
  // useEffect(() => {
  //   setUpData();
  // }, []);
  // const handleChange = (name: any, event: any) => {
  //   setdata({ ...data, [name]: event });
  // };

  // const handlePanCardChange = (event: any) => {
  //   setPancard(event);
  // };

  // const handleLicenceUserChange = (event: any) => {
  //   setlicence(event);
  // };
  // const handleUserProfileChange = (event: any) => {
  //   setuserProfile(event);
  // };

  // const handleChangeTab = (event: any) => {
  //   setValue(event);
  // };

  // const handleSubmit = async () => {
  //   _next();
  //   try {
  //     if (data.panNumber && data.licenceExpire) {
  //       let payload = {
  //         otp: data.otp,
  //         recaptcha: token,
  //         contactPerson: {
  //           contact: data.contactNo,
  //           name: {
  //             fName: data.fName,
  //             lName: data.lName,
  //           },
  //           password: data.password,
  //           email: data.email,
  //         },
  //         address: {
  //           l1: data.line1,
  //           l2: data.line2,
  //           pincode: data.pincode,
  //           city: data.city,
  //         },
  //         panNumber: data.panNumber,
  //         bankAccount: data.AccountNo,
  //         ifscCode: data.IFSCcode,
  //         individual: true,
  //         licenseExpiry: data.licenceExpire,
  //         regNumber: data.fleetNo,
  //         fleetType: data.fleetType,
  //         bodyType: data.bodyType,
  //         panCardImage: pancard,
  //         licenseImage: licence,
  //         userProfile: userProfile,
  //         displayName: data.displayName,
  //       };
  //       await individualData(jsonToFormdata(payload));
  //       toast.success("individual create successfully");
  //       setdata({
  //         displayName: "",
  //         fleetNo: "",
  //         fleetType: "",
  //         bodyType: "",
  //         fName: "",
  //         lName: "",
  //         email: "",
  //         contactNo: "",
  //         otp: "",
  //         password: "",
  //         line1: "",
  //         line2: "",
  //         pincode: "",
  //         city: "",
  //         AccountNo: "",
  //         IFSCcode: "",
  //         pancard: "",
  //         panNumber: "",
  //         licenceExpire: "",
  //         licence: "",
  //         userProfile: "",
  //       });
  //       setlicence(null);
  //       setuserProfile(null);
  //       setPancard(null);
  //       setstep(1);
  //     } else if (data.panNumber == "") {
  //       toast.error("pancard Required");
  //       showHttpError("pancard Required");
  //     } else {
  //       showHttpError(" Licence ExpireDate Required");
  //       toast.error("ExpireDate Required");
  //     }
  //   } catch (err : any) {
  //     toast.error(err.message);
  //     showHttpError(err.message);
  //   }
  // };

  /*
   * the functions for our button
   */
  // const handlenexttab = () => {
  //   if (props.displayFleet) {
  //     if (value < 4) {
  //       setValue(value + 1);
  //     }
  //   } else {
  //     if (value < 3) {
  //       setValue(value + 1);
  //     }
  //   }
  // };
  // const handleprevtab = () => {
  //   if (value > 0) {
  //     setValue(value - 1);
  //   }
  // };

  // const _prev = () => {
  //   let currentStep = step;
  //   currentStep = currentStep <= 1 ? 1 : currentStep - 1;
  //   setstep(currentStep);
  // };

  // console.log(_prev, _next);

  // const handelgetOtp = () => {
  //   getOtp(data.contactNo);
  // };

  return (
    <Fragment>
      <>
            {/* <Tabs value={value}>
              <Tab
                style={{ fontSize: "11px", color: "rgb(0 0 0 / 65%)" }}
                label="General Info"
                onClick={() => handleChangeTab(0)}
              />
              <Tab
                style={{ fontSize: "11px", color: "rgb(0 0 0 / 65%)" }}
                label="Contact"
                onClick={() => handleChangeTab(1)}
              />
              <Tab
                style={{ fontSize: "11px", color: "rgb(0 0 0 / 65%)" }}
                label="Address"
                onClick={() => handleChangeTab(2)}
              />
              <Tab
                style={{ fontSize: "11px", color: "rgb(0 0 0 / 65%)" }}
                label="Attachments"
                onClick={() => handleChangeTab(3)}
              />
              {props.displayFleet && (
                <Tab
                  style={{ fontSize: "11px", color: "rgb(0 0 0 / 65%)" }}
                  label="Fleet"
                  onClick={() => handleChangeTab(4)}
                />
              )}
            </Tabs>
            <TabPanel value={value} index={0}>
              <Paper style={{ margin: 10 }}>
                <div className="row">
                  <div className="col-lg-6">
                    <Paper className={style.paper}>
                      <div className={style.labels}>
                        <strong>Bank Details</strong>
                      </div>
                      <Step4
                        handleChange={handleChange}
                        AccountNo={data.AccountNo}
                        IFSCcode={data.IFSCcode}
                        errors4={errors4}
                      />
                    </Paper>
                  </div>
                </div>
              </Paper>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Paper className={style.paper}>
                <div className={style.labels}>
                  <strong>Contact Person</strong>
                </div>
                <Step2
                  handleChange={handleChange}
                  fName={data.fName}
                  lName={data.lName}
                  email={data.email}
                  contactNo={data.contactNo}
                  password={data.password}
                  otp={data.otp}
                  errors2={errors2}
                  handelgetOtp={handelgetOtp}
                />
              </Paper>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Paper className={style.paper}>
                <div className={style.labels}>
                  <strong>Address</strong>
                </div>
                <Step3
                  handleChange={handleChange}
                  line1={data.line1}
                  line2={data.line2}
                  pincode={data.pincode}
                  city={data.city}
                  Cities={Cities}
                  errors3={errors3}
                />
              </Paper>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Paper className={style.paper}>
                <div className={style.labels}>
                  <strong>Attachments</strong>
                </div>
                <Step5
                  handleChange={handleChange}
                  handlePanCardChange={handlePanCardChange}
                  handleLicenceUserChange={handleLicenceUserChange}
                  handleUserProfileChange={handleUserProfileChange}
                  panNumber={data.panNumber}
                  licenceExpire={data.licenceExpire}
                  pancard={pancard}
                  licence={licence}
                  userProfile={userProfile}
                />
              </Paper>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Paper className={style.paper}>
                <div className={style.labels}>
                  <strong>Fleet</strong>
                </div>
                <Step1
                  handleChange={handleChange}
                  fleetNo={data.fleetNo}
                  fleetType={data.fleetType}
                  bodyType={data.bodyType}
                  // errors1={errors1}
                />
              </Paper>
            </TabPanel>
            <div>
              <div style={{ display: "flex" }}>
                <ButtonPrimaryBold
                  onClick={() => handleSubmit()}
                  style={{ margin: " 20px 40px 0px 0px" }}
                >
                  Create
                </ButtonPrimaryBold>

                {value > 0 ? (
                  <ButtonPrimaryBold
                    onClick={() => {
                      handleprevtab();
                    }}
                    style={{ margin: " 20px 40px 0px 0px" }}
                  >
                    Previous
                  </ButtonPrimaryBold>
                ) : null}
                {props.displayFleet && value < 4 ? (
                  <ButtonSecondaryBold
                    onClick={() => {
                      handlenexttab();
                    }}
                    style={{ margin: " 20px 40px 0px 0px" }}
                  >
                    Next
                  </ButtonSecondaryBold>
                ) : null}
                {!props.displayFleet && value < 3 ? (
                  <ButtonSecondaryBold
                    onClick={() => {
                      handlenexttab();
                    }}
                    style={{ margin: " 20px 40px 0px 0px" }}
                  >
                    Next
                  </ButtonSecondaryBold>
                ) : null}
              </div>
            </div> */}
            {/* </form> */}
              {/* Create update*/}
      </>
      <br />  
      {props.displayFleet === true ?   <PartnersForms fleet={props.displayFleet} /> : <PartnersForms fleet={props.displayFleet} />}
      <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
        <GoogleReCaptcha onVerify={onVerify} />
      </GoogleReCaptchaProvider>
    </Fragment>
  );
};
const mapDispatchToProps = {
  //   getCompanyList,
  //   changeCompanyDoc,
  //   createCompanyDocAction,
  //   updateCompanyDocAction,
  //   setCompanyDocAction,
  //   clearCompanyDoc,
  //   getCompany,
  //   setCompanyError,
};

const mapStateToProps = (state: any) => {
  return {
    cities: state.user.allcities,
    companyList: state.company.getIn(["companyList"]),
    company: state.company.get("company_doc"),
    role: state.user.role,
    userCompanyID: state.user.company.id,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndivisualRegistration);
