import React from 'react'
import { connect } from 'react-redux'
import {
  Navigate,
  // Redirect, 
  // useHistory, 
  useLocation
} from "react-router-dom";

export const BlindPage = (props: any) => {
  console.log(`BlindPage`, props);
  const location = useLocation();

  console.log(`BlindPage`, props, `location`, location, `history`, history);

  if (location && location?.pathname === "/BlindPage" && location?.search !== "") {
    let finalAns = location?.search.split("&")
    console.log(`BlindPage ANKT-2324-2304`, finalAns)
    let finalAns2: any = finalAns[finalAns?.length - 1].split("=")
    console.log(`BlindPage ANKT-2324-2304 20`, finalAns2)
    return (
      <Navigate
        to="/TempTracking"
        state={{
          from: "Customer-NotAuthenticated-BranchOrder",
          docketNumber: { docket: finalAns2[1] },
          typeOfDocket: "Branch Order Tracking Id"
        }}
      />
    );
  }

  return (
    <div></div>
  )
}

const mapStateToProps = (state: any) => ({
  user: state?.user,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(BlindPage)