import React, { Fragment, useState } from "react";
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import { getWhatsappMessageContent } from "../../services/feedback";
import { showHttpError } from "../../utils/message";

interface IProps {
  docketId: string;
}

const ShareToWhatsapp: React.FC<IProps> = ({ docketId }) => {
  const size = "2rem";
  const [data, setData] = useState<any>("");

  const handleClick = async () => {
    try {
      const response = await getWhatsappMessageContent(docketId);
      setData(response);
    } catch (err : any) {
      showHttpError(err);
    }
  };

  return (
    <Fragment>
      <WhatsappShareButton title={data.text} url={data.url}>
        <WhatsappIcon size={size} onClick={handleClick} className="circle" style={{width: "28px", height: "28px"}} />
      </WhatsappShareButton>
    </Fragment>
  );
};

export default ShareToWhatsapp;
