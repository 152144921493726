// react
import React from 'react'

// FC: Investors
const Investors = () => {
  return (
    <p className="text-white-900 fw-600 fs-medium text-center">Comming Soon😊!</p>
  )
}

export default Investors