import React, { ComponentProps } from "react";

type FormHelperTextProps = {} & ComponentProps<"span">;
export default function FormHelperText({
  children,
  className = "",
}: FormHelperTextProps) {
  return (
    <span className={`${className} fs-small text-black-100`}>{children}</span>
  );
}
