import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate, } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import CustomerTrackMapView from '../../CustomerSection/CustomerBookingTracking/CustomerTrackMapView';
import formatDate from "../../../services/FormatDate";
import { setTimeFormat } from './../../../services/FormatTime';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '../../../Components/Dialog/DialogComponent';
import ImageInput from "../../../Components/ImageInput";
import { getCustomerdocketDataTrack, getOtpForBranchOrderTracking } from './../../../services/customersection';
import Rating from '@mui/material/Rating';
import { ButtonDefault, ButtonPrimary } from './../../../Components/Button/Buttoncontainer';
import { showMessage, success, failed } from "../../../utils/message";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { addQrReprintPackageLog, fetchDocketDetails } from '../../../services/operations';
import DoorDeliveryDialog from "../../operations/Booking/DoorDeliveryDialog";
import { showHttpError } from "../../../utils/message";
import { Input12, Input } from "../../../Components/Input/Inputcomponents";
import ImageInputPackgeWise from "../../../Components/ImageInputPackgeWise";
import { Select, Select12 } from "../../../Components/Selectcomponents/Select";
import ShareToWhatsapp from "../../sharing/WhatsAppSharing";
import { sendBackToNewDestination, sendBackToOrigin, setReceivedPackages } from '../../../services/bookTrack';
import { addCustomerComplaintForBranchOrder, createComplaintGuest, createComplaintUser, customerReviewRating, getGuestComplaint, sendGuestMessage, sendUserMessage } from '../../../services/complaint';
import Complaint from '../../Complaint/complaint';
import StatusHeader from '../../operations/StatusHeader';
import MyImage from '../../Image/MyImage';
import { Switch } from '../../../Components/Switch/Switch';
import classNames from "classnames";
import Dot from '../../generics/Dot';
import { setMissingPackages } from './../../../services/bookTrack';
import { printQRDocket } from '../../operations/bookutils/steps/builty';
import { listUsersService } from '../../../services/paymentType';
import ToolTip from '../../../Components/ToolTip/ToolTip';
import { listAllUsersForCustomers } from '../../../services/user';
import { FormControlLabel } from '../../../Components/Radio/CustomRadio';
import CheckBox from '../../../Components/CheckBox/CheckBox';
import { sendCustomerOTP } from '../../../services/auth';
import { setCustomerPassword } from '../../../services/auth';
import OnlyImage from '../../Image/OnlyImage';
import ChatContainer from '../../../Components/ChatModule/ChatContainer';
import jsonToFormdata from '../../../utils/jsonToFormdata';
import DoorDeliveryVoucher from "../../expense/DoorDeliveryVoucher";
import Printd from "printd";
import formatTime from "../../../Components/FormatTime";
import { createCustomerCCAvenueTxn } from "../../../services/paymentGateWay";
import ReactGA4 from 'react-ga4';
import { CalendarIcon, CallIcon, CashIcon, DocketNumberIcon, HistoryIcon, HoursIcon, RupeeIcon, TimelineIcon, UserIcon, BackIcon, DownloadIcon, CancelIcon, MessageAltIcon, SendIcon, MovingTruckIcon, LocationIcon, ChatIcon, PackageIcon, DropboxIcon, ImagesIcon, ThreeDotsIcon, InfoIcon, DebugDisconnectIcon, TagsIcon, OrderPlayLineIcon, UngroupByRefTypeIcon, QRCodeIcon, BranchIcon, PincodeIcon, BranchCallIcon, CityIcon, UserInfoIcon, CalendarCheckIcon, SharpLocationIcon, DownIcon, UpIcon, StartDateIcon, EndDateIcon, MapPinIcon } from "../../../newSrc/constants/icons";
import { Button } from "../../../newSrc/components/UI";
import PageTitle from "../../../Components/Title/PageTitle";
import ComplaintButtons from "./../Components/ComplaintButtons";
import { socket } from "../../../store";
import { getCustomerDocketLocation } from "../../../actions/liveLocation";
import { customerLogin } from "../../../actions/customerLogin";
import { setCustomerBookingTracking } from "../../../actions/customerBooking";
import { ResetCustomerTracking } from "../../../actions/trackingModule";
import { hideLoader, resetDoorDelivery, setDoorDelivery, showLoader } from "../../../actions/UserActions";
import { useAppSelector } from "../../../hooks/useAppSelector";


const meterTOkmChange = (meter: any) => {
    return (meter / 1000).toFixed(2)
}

const secondTominConvert = (time: any, index: any) => {
    let t = new Date();
    t.setSeconds(t.getSeconds() + time + index * 30 * 60);
    return `${formatDate(t)}- ${setTimeFormat(t)}`
}

const timeConvert = (time: any) => {
    const timeString12hr = new Date(time).toLocaleTimeString("en-US", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
    });
    return timeString12hr;
}

var iconsClassName = `text-primary-400 fs-link flex-shrink-0`;

export const BranchTrackingView = (newProps: any) => {
    const BranchData: any = newProps?.data?.customerTrackingData?.orderData;
    const SourceName: any = newProps?.data?.customerTrackingData?.source;
    const [activeDest, setActiveDest] = useState<any>(null);
    const [uniqueLogs, setUniqueLogs] = useState<any>("");
    const [packageMap, setPackageMap] = useState<any>({});
    const [doorDeliveryDocketData, setDoorDeliveryDocketData] = useState<any>({});
    const [orderDetails, setOrderDetails] = useState<any>({})
    const [latComplaint, setLastComplaint] = useState<any>();
    const [isDialogOpen, setIsDialogOpen] = useState(0);
    const [ratingFromUser, setRatingFromUser] = useState(0);
    const [reviewFromUser, setReviewFromUser] = useState("");
    const [openDoorDelDialog, setOpenDoorDelDialg] = useState(false);
    const [DoorDelBookingCheckBox, setDoorDelBookingCheckBox] = useState(false);
    const [mainBookingCheckBoxPart, setMainBookingCheckBoxPart] = useState(true);
    const [imagePackages, setImagePackages] = useState<any>([]);
    const [newDestOpen, setNewDestOpen] = useState(false);
    const [imagedPackageData, setImagedPackageData] = useState<any>([]);
    const [arrayOfImage, setArrayOfImage] = useState<any>([]);
    const [NDAmount, setNDAmount] = useState<any>("");
    const [selectedND, setSelectedND] = useState<any>("");
    const [newDestOptions, setNewDestOptions] = useState<any>([]);
    const [backToOriginOpen, setBackToOriginOpen] = useState(false);
    const [showPackages, setShowPackages] = useState(false);
    const [ratingDone, setRatingDone] = useState(false);
    const [moreBtns, showMoreBtns] = useState(false);
    const [packageImageDialog, setPackageImageDialog] = useState(false);
    const [selectedImage, setSelectedImage] = useState({ label: 'Select Any One Image', value: "Select Any One Image" });
    const [btoAmount, setbtoAmount] = useState<any>("");
    const [orderArrayOption, setorderArrayOption] = useState<any>("");
    const [branchOptions, setBranchOptions] = useState<any>("");
    const [userOptions, setUserOptions] = useState<any>("");
    const [fleetOrderNumbers, setFleetOrderNumbers] = useState<any>([]);
    const [openAction, setOpenAction] = useState<any>(null);
    const [openActionForRecieved, setOpenActionForRecieved] = useState<any>(false);
    const [reason, setReason] = useState("");
    const [Image, setImage] = useState<any>("");
    const [feedbackResponse, setFeedbackResponse] = useState<any>("");
    // const [originalDocket, setOriginalDocket] = useState<any>()
    const [allPackages, setAllPackages] = useState<any>([]);
    const [packageOrderForRecieved, setPackageOrderForRecieved] = useState<any>([]);
    const [openFleetOrder, setOpenFleetOrder] = useState(false)
    const [outsideDialogOpen, setOutsideDialogOpen] = useState(false)
    const [outsidePaymentDialogOpen, setOutsidePaymentDialogOpen] = useState(false)
    const [outsideRating, setOutsideRating] = useState(false)
    const [firstPopup, setFirstPopup] = useState(false)
    const [chatPopup, setChatPopup] = useState(false)
    const [firstPopupTabs, setFirstPopupTabs] = useState(0)
    const [firstPopupPassword, setFirstPopupPassword] = useState("")
    const [firstPopupNewPassword, setFirstPopupNewPassword] = useState("")
    const [firstPopupConfirmPassword, setFirstPopupConfirmPassword] = useState("")
    const [branchAdd, setBranchAdd] = useState(false)
    const [paymentInfo, setPaymentInfo] = useState(false)
    const [customerType, setCustomerType] = useState<any>("");
    const [customerTypeForRating, setCustomerTypeForRating] = useState<any>("");
    const [name, setName] = useState("");
    const [contact, setContact] = useState("");
    const [misBehaveUser, setMissBehaveUSer] = useState<any>("");
    const [missBehaveBranch, setMissBehaveBranch] = useState<any>("");
    const [details, setDetails] = useState("");
    const [ImageTwo, setImageTwo] = useState<any>("");
    const [feedBack, setFeedBack] = useState("");
    let mobileviewinitial = window.innerWidth > 770 ? false : true;
    const [mobileView, setmobileView] = useState(mobileviewinitial);
    const [missingParcel, setMissingParcel] = useState(false);
    const [notReceivedDisabled, setNotReceivedDisabled] = useState(false);
    const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    const [open, setOpen] = useState(false);
    const [firstPopupOtp, setFirstPopupOtp] = useState("");
    const [senderOptIn, setSenderOptIn] = useState("");
    const [senderOptInForPayment, setSenderOptInForPayment] = useState("");
    const [selectedNumber, setSelectedNumber] = useState<any>()
    const [selectedNumberForPayment, setSelectedNumberForPayment] = useState<any>()
    const [userHasPass, setUserHasPass] = useState(false);
    const [otpForDelivery, setOtpForDelivery] = useState<any>()
    const [replyImage, setReplyImage] = useState("");
    const [guestMessage, setGuestMessage] = useState("");
    const [printDoorDelVoucher, setPrintDoorDelVoucher] = useState(false)
    const [selectPaymentOptionBtn2, setSelectPaymentOptionBtn2] = useState(BranchData?.docket.paymentMode);
    const [isComplaintsOpen, setIsComplaintOpen] = useState(() => false);
    // const [txnsNumber, setTxnsNumber] = useState("")
    let isDelivered = false;
    const navigate = useNavigate()
    let recordsMap = new Map();

    BranchData?.packages.map((d: any) => {
        if (recordsMap.has(`${d?.materialType?.name}${d?.packingType?.name}`)) {
            recordsMap.get(`${d?.materialType?.name}${d?.packingType?.name}`).push(d);
        } else {
            recordsMap.set(`${d?.materialType?.name}${d?.packingType?.name}`, [d]);
        }
    });

    let imagesOptions = [...arrayOfImage?.map((x: any) => { return { label: x?.name, value: x?.url } })];

    const { branchListData } = useAppSelector((state: any) => ({ branchListData: state.branch.filteredBranches.data.Branches }))

    const giveUser = function (user: any) {
        if (
            user &&
            typeof user === "object" &&
            user.hasOwnProperty("name") &&
            user.name.fName &&
            user.name.lName
        ) {
            return [user.name.fName, user.name.lName].join(" ");
        }
        return "Anonymous";
    };

    const setIsDialogOpenFunc = (num: number) => {
        if (allPackages.length < 1) {
            return showHttpError('Turn on Show Packages, ThenKindly Select Any One or More Package')
        }
        if (num === 1) {

            if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                // setIsDialogOpen(1)
                setOutsideDialogOpen(true);
            }
            else {
                setIsDialogOpen(1), setOpenAction("M")
            }
        }
        if (num === 2) {
            if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                // setIsDialogOpen(2) 
                setOutsideDialogOpen(true);
            }
            else {
                setIsDialogOpen(2), setOpenAction("PF")
            }
        }
        if (num === 3) {
            if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                // setIsDialogOpen(3), 
                setOutsideDialogOpen(true);
            }
            else {
                setIsDialogOpen(3), setOpenAction("DM")
            }
        }
        if (num === 4) {
            if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                // setIsDialogOpen(4), 
                setOutsideDialogOpen(true);
            }
            else {
                setIsDialogOpen(4), setOpenAction("MB")
            }
        }
        if (num === 5) {
            if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                // setIsDialogOpen(5), 
                setOutsideDialogOpen(true);
            }
            else {
                setIsDialogOpen(5), setOpenAction("LD")
            }
        }
    }

    const processLogs = (packages: any) => {
        let uniqueLogs: any = {},
            packageMap: any = {},
            global: any = {};
        packages.forEach((item: any) => {
            let { logs: tLogs, pod, idProof, destBranch, originBranch } = item;

            let current = "";
            if (tLogs) {
                tLogs.forEach((item: any) => {
                    let {
                        regNumber,
                        branch,
                        // trackingTime,
                        user,
                        action,
                        remarkImage,
                        remarks,
                        secondVerified,
                        secondActionBy,
                        secondRemark,
                        secondaryActionAt,
                        isQrScan,
                        branch: fromBranch
                    } = item;

                    let entity = regNumber || (branch ? branch.branchName : "ENTITY");
                    let presentBranch = branch ? branch._id : "";

                    current +=
                        [
                            action == "unloading"
                                ? originBranch == presentBranch
                                    ? "booking"
                                    : destBranch !== presentBranch
                                        ? "loading"
                                        : action
                                : action,
                            entity,
                            giveUser(user),
                            // trackingTime,
                            pod || "NOPOD",
                            idProof || "NOID",
                            remarks || "RTEXT",
                            remarkImage || "RIMAGE",
                            action,
                            secondVerified,
                            giveUser(secondActionBy),
                            secondRemark,
                            secondaryActionAt,
                            isQrScan === true ? "YES" : "NO",
                            fromBranch ? fromBranch.branchName : "ENTITY",
                        ].join("@") + ";";
                    if (action === "inGstCustody" && entity === 'GJ38T5676') {
                    }
                });
                if (current in global) {
                    packageMap[current].push(item.order);
                } else {
                    global[current] = 1;
                    uniqueLogs[current] = current;
                    packageMap[current] = [item.order];
                }
            }
        });
        return {
            uniqueLogs,
            packageMap,
            global,
        };
    };
    const getBranchOrderData = () => {

        let resPackages = BranchData?.packages.map((p: any, i: any) => {
            isDelivered = isDelivered || p.status === "D";
            return {
                ...p,
                logs: p.logs.map((l: any, li: any) => ({
                    ...l,
                    remarkImage: BranchData?.logImages[i][li],
                })),
            };
        });

        const { uniqueLogs, packageMap } = processLogs(resPackages);

        setPackageMap(packageMap);
        setUniqueLogs(uniqueLogs);

        let isOrderStarted = BranchData?.doorDeliveryDocket?.Route?.find((x: any) => {
            return x.checkIn
        }) ? true : false

        setDoorDeliveryDocketData({
            ...BranchData?.doorDeliveryDocket.doorDelAddress,
            docket: BranchData?.docket?.docketNumber,
            doorDelDocketNumber: BranchData?.docket?.doorDeliveryDocketNumber,
            serviceType: BranchData?.doorDeliveryDocket.serviceType,
            assetType: BranchData?.doorDeliveryDocket.assetType,
            fromPlaceId: BranchData?.docket?.destBranch?.address?.placeId,
            toPlaceId: BranchData?.doorDeliveryDocket.toPlace,
            packages: BranchData?.docket?.packages,
            delCity: BranchData?.docket?.destBranch?.address?.city?._id,
            city: { value: BranchData?.doorDeliveryDocket?.doorDelAddress?.city?._id, label: BranchData?.doorDeliveryDocket?.doorDelAddress?.city?.name },
            state: { value: BranchData?.doorDeliveryDocket?.doorDelAddress?.city?.state?._id, label: BranchData?.doorDeliveryDocket?.doorDelAddress?.city?.state?.name },
            doorDeliveryPaymentMode: BranchData?.doorDeliveryDocket.paymentMode,
            deliveryCharge: BranchData?.doorDeliveryDocket.doorDelCharges,

            placeValue: BranchData?.doorDeliveryDocket?.doorDelAddress?.place?.placeValue,
            placeId: BranchData?.doorDeliveryDocket?.doorDelAddress?.place?.placeId,
            coordinate: BranchData?.doorDeliveryDocket?.doorDelAddress?.place?.coordinate,
            formatted_address: BranchData?.doorDeliveryDocket?.doorDelAddress?.place?.formatted_address,
            name: BranchData?.doorDeliveryDocket?.doorDelAddress?.place?.name,
            area: BranchData?.doorDeliveryDocket?.doorDelAddress?.place?.area,
            isOrderStarted
        })

        setInState(BranchData);

        setOrderDetails({
            packages: BranchData?.doorDeliveryDocket.packages,
            totalDuration: BranchData?.doorDeliveryDocket.totalDuration,
            totalDistance: BranchData?.doorDeliveryDocket.totalDistance,
        })
        if (BranchData?.doorDeliveryDocket.Route && BranchData?.doorDeliveryDocket.Route.length > 0) {
            let estimation = BranchData?.doorDeliveryDocket?.Route.sort((a: any, b: any) => {
                return a?.placeIndex - b?.placeIndex
            }).map((x: any) => {
                return { ...x, placeId: [x.place] }
            }) || []
            if (isOrderStarted) {
                setActiveDest(estimation.find((x: any) => !x.checkIn && !x.checkOut)?.placeIndex)
            }

            let origin = {
                distance: 0,
                duration: 0,
                doorDelDocketId: estimation[0].doorDelDocketId,
                placeId: [BranchData?.docket?.actDestBranch?.place],
                placeIndex: 0,
                place: BranchData?.docket?.actDestBranch?.place
            }
            newProps?.data?.setCustomerBookingTracking({
                route: [origin, ...estimation]
            })
        }
    }

    const checkSelected = (pId: any) => {
        let x = [...allPackages];
        if (x.includes(pId)) {
            let id = x.indexOf(pId);
            x.splice(id, 1);
        } else {
            x.push(pId);
        }
        setAllPackages(x);
    };

    const handleDetailedLogsForBranch = (uniquelogsArr: any, allProps: any, orderNumber?: number) => {
        if (SourceName === "Customer-Authenticated-BranchOrder") {
            // historyData.push({
            //   pathname: "/CustomerDashBoard/detailedLogs",
            //   state: {
            //     from: "Customer-Order-Track",
            //     logsInfo: uniquelogsArr,
            //     AllProps: allProps,
            //     BranchDetails: BranchData,
            //     view: "Branch-Order"
            //   }
            // })
            navigate("/CustomerDashBoard/detailedLogs", {
                state: {
                    from: "Customer-Order-Track",
                    logsInfo: uniquelogsArr,
                    AllProps: allProps,
                    BranchDetails: BranchData,
                    view: "Branch-Order",
                    orderNum: orderNumber,
                }
            })
        }
        else if (SourceName === "Customer-Authenticated-CustomerOrder") {
            // historyData.push({
            //   pathname: "/CustomerDashBoard/detailedLogs",
            //   state: {
            //     from: "Customer-Order-Track",
            //     logsInfo: uniquelogsArr,
            //     view: "Customer-Order"
            //   }
            // })
            navigate("/CustomerDashBoard/detailedLogs", {
                state: {
                    from: "Customer-Order-Track",
                    logsInfo: uniquelogsArr,
                    view: "Customer-Order"
                }
            })
        }
        else if (SourceName === "Branch-Authenticated-BranchOrder") {
            // historyData.push({
            //   pathname: "/dashboard/detailedLogs",
            //   state: {
            //     from: "Branch-Order-Track",
            //     logsInfo: uniquelogsArr,
            //     AllProps: allProps,
            //     BranchDetails: BranchData,
            //     view: "Branch-Order"
            //   }
            // })
            navigate("/dashboard/detailedLogs", {
                state: {
                    from: "Branch-Order-Track",
                    logsInfo: uniquelogsArr,
                    AllProps: allProps,
                    BranchDetails: BranchData,
                    view: "Branch-Order",
                    orderNum: orderNumber,
                }
            })
        }
        else if (SourceName === "Branch-Authenticated-CustomerOrder") {
            // historyData.push({
            //   pathname: "/dashboard/detailedLogs",
            //   state: {
            //     from: "Branch-Order-Track",
            //     logsInfo: uniquelogsArr,
            //     view: "Customer-Order"
            //   }
            // })
            navigate("/dashboard/detailedLogs", {
                state: {
                    from: "Branch-Order-Track",
                    logsInfo: uniquelogsArr,
                    view: "Customer-Order"
                }
            })
        }
        else if (SourceName === "Customer-NotAuthenticated-CustomerOrder") {
            // historyData.push({
            //   pathname: "/detailedLogs",
            //   state: {
            //     from: "Customer-Order-Track",
            //     logsInfo: uniquelogsArr,
            //     view: "Customer-Order"
            //   }
            // })
            navigate("/detailedLogs", {
                state: {
                    from: "Customer-Order-Track",
                    logsInfo: uniquelogsArr,
                    view: "Customer-Order"
                }
            })
        }
        else if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
            // historyData.push({
            //   pathname: "/detailedLogs",
            //   state: {
            //     from: "Branch-Order-Track",
            //     logsInfo: uniquelogsArr,
            //     AllProps: allProps,
            //     BranchDetails: BranchData,
            //     view: "Branch-Order"
            //   }
            // })
            navigate("/detailedLogs", {
                state: {
                    from: "Branch-Order-Track",
                    logsInfo: uniquelogsArr,
                    AllProps: allProps,
                    BranchDetails: BranchData,
                    view: "Branch-Order",
                    orderNum: orderNumber,
                }
            })
        }
    }

    const SingleStatus = (props: any) => {
        const [isDialogOpen, setIsDialogOpen] = useState(false);
        let lastStatus = props.statusArr[props.statusArr.length - 1];
        const statusSplit = lastStatus.split("@");
        let ParcelReachedLastBranch: any = statusSplit[1];
        const steps = getSteps();
        function getSteps() {
            return [1, 2, 3, 4, 5];
        }

        if (statusSplit[1] === "GJ38T5676") {
        }

        let result, procStatus;

        switch (statusSplit[0]) {
            case "loading": {
                result = "In transit";
                procStatus = 1;
                break;
            }
            case "payment": {
                result = "Waiting for Delivery";
                procStatus = 3;
                // procStatus = "Waiting for Delivery";
                break;
            }
            case "unloading": {
                result = "Waiting for Delivery";
                procStatus = 2;
                // procStatus = "Waiting for Delivery";
                break;
            }
            case "booking": {
                result = "Booked";
                procStatus = 0;
                break;
            }
            case "delivery": {
                result = "Delivered";
                procStatus = 5;
                // procStatus = "Delivered";
                break;
            }
            case "cancel": {
                result = "Canceled";
                procStatus = 0;
                // procStatus = "Canceled";
                break;
            }
            case "cancelPending": {
                result = "Waiting for cancellation";
                // procStatus = "Waiting for cancellation";
                break;
            }
            case "review": {
                result = "Review";
                // procStatus = "Review";
                break;
            }
            case "missing": {
                result = "Missing";
                // procStatus = "Missing";
                break;
            }
            case "missingRequest": {
                result = "Missing Request";
                // procStatus = "Missing Request";
                break;
            }
            default: {
                result = "Booked";
                procStatus = 0;
            }
        }

        return (
            <div className="paper">
                {mobileView ?
                    <div className="grid gap-1">
                        {/* <p className="bg-primary-400 text-white-900 br-4 p-1 max-content">
                    Last Activity
                  </p> */}
                        {/* No of Packages & Current Location */}
                        <div className='two-column gap-1'>
                            <div className="flex ai-center gap-4" onClick={() => { alert('dfvjkdfvmn') }}>
                                <PackageIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-500)" }} />
                                <p> {props.totalPkg} </p>
                            </div>
                            <div className="flex ai-center gap-4">
                                <MapPinIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />{" "}
                                <p> {ParcelReachedLastBranch} </p>
                            </div>
                        </div>

                        <div className="two-column gap-1">
                            <div className="fs-link flex ai-center gap-4">
                                <DropboxIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                <p>{props?.materialType && props?.materialType.name}</p>
                            </div>
                            <div className="fs-link flex ai-center gap-4">
                                <PackageIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                <p>{props?.packingType && props?.packingType.name}</p>
                            </div>
                        </div>

                        <p className="p-10 py-1 border-white shadow-small br-4">
                            <Stepper activeStep={procStatus} className={"singleProgressBar flex flex-wrap"}>
                                {steps.map((label) => (
                                    <Step key={label} className={"singleStepz"} >
                                        <StepLabel></StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <p className="p-8 fs-link flex-center gap-4"><TimelineIcon className="text-primary-400" />{result}</p>
                        </p>
                        {/* <p onClick={() => { handleDetailedLogsForBranch(props.EachlogsArr, props) }} className='bg-primary-50 text-primary-900 fs-link p-8 br-4 flex-center gap-8'> */}
                        {/* {SourceName === "Customer-NotAuthenticated-BranchOrder" ? <></> :
                    } */}
                        {/* <HistoryIcon />
                    History
                  </p> */}
                        <Button fullWidth variant="primary" action="secondary" className="flex-center pointer" Icon={<HistoryIcon />} onClick={() => { setIsDialogOpen(true) }}>History</Button>

                        <Dialog open={isDialogOpen} onClose={() => { setIsDialogOpen(false) }}>
                            <DialogTitle style={{ cursor: "move", textAlign: "center" }} id="draggable-dialog-title">
                                List of Package Numbers.
                            </DialogTitle>
                            <DialogContent>
                                <div className="pkgDialogueContent">
                                    <div className="listOfPackages">
                                        {props.PkgList.map((c: any) => {
                                            return (<Button onClick={() => { handleDetailedLogsForBranch(c.logs, props, c?.order) }} variant="primary" action="secondary">
                                                {c?.order}
                                            </Button>
                                            )
                                        })}
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <ButtonDefault onClick={() => setIsDialogOpen(false)} className={`button-danger-secondary fs-popup-btn`}>
                                    Cancel
                                </ButtonDefault>
                            </DialogActions>
                        </Dialog>
                    </div>
                    :
                    <>
                        <div className="flex ai-center jc-between">
                            <p className="fs-link flex ai-center gap-4">
                                <div><PackageIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-500)" }} /> </div>
                                <div> {props.totalPkg} </div>
                            </p>
                            <p className="fs-link flex ai-center gap-4">
                                <span><DropboxIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} /> </span>
                                {props?.materialType && props?.materialType.name}
                            </p>
                            <p className="fs-link flex ai-center gap-4">
                                <span><PackageIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} /> </span>
                                {props?.packingType && props?.packingType.name}
                            </p>

                            <p className="fs-link flex ai-center gap-4">
                                <span><TimelineIcon className={`${iconsClassName}`} />{" "}</span>
                                <div>
                                    {ParcelReachedLastBranch}
                                </div>
                            </p>


                            <p className='fs-link flex ai-center gap-4'>
                                <HistoryIcon className="text-primary-400 pointer" onClick={() => { setIsDialogOpen(true) }} />
                            </p>
                            <Dialog open={isDialogOpen} onClose={() => { setIsDialogOpen(false) }}>
                                <DialogTitle style={{ cursor: "move", textAlign: "center" }} id="draggable-dialog-title">
                                    List of Packge Numbers.
                                </DialogTitle>
                                <DialogContent>
                                    <div className="pkgDialogueContent">
                                        <div className="listOfPackages flex gap-1 flex-wrap">
                                            {props.PkgList.map((c: any) => {
                                                return (<Button onClick={() => { handleDetailedLogsForBranch(c.logs, props, c?.order) }} variant="primary" action="secondary">
                                                    {c?.order}
                                                </Button>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <ButtonDefault className={`button-danger-secondary fs-popup-btn`} onClick={() => setIsDialogOpen(false)}>
                                        Cancel
                                    </ButtonDefault>
                                </DialogActions>
                            </Dialog>
                        </div>
                        <div className="progressBarDiv my-1 p-1 border-white shadow-small br-4">
                            <Stepper activeStep={procStatus} className={"singleProgressBar"}>
                                {steps.map((label) => (
                                    <Step key={label} className={"singleStepz"} >
                                        <StepLabel></StepLabel>
                                    </Step>
                                ))}
                                <p className="lastResultName">{result}</p>
                            </Stepper>
                        </div>
                    </>
                }

                {showPackages &&
                    <div className="packageBtnsForComplaints">
                        {props?.PkgList && props?.PkgList?.map((i: any, index: any) => {
                            return (<div
                                key={index}
                                className={`${classNames(
                                    {
                                        inner_selected: allPackages.includes(i._id),
                                    },
                                    {
                                        inner_package: !allPackages.includes(i._id),
                                    }
                                )}`}
                                style={{
                                    margin: "0",
                                    marginRight: "1rem"
                                }}
                                onClick={() => {
                                    checkSelected(i._id);
                                    // checkboxSelected(props.data.packages, props.index);
                                }}
                            >
                                <div style={{ display: "inline" }}>
                                    <Dot
                                        root={
                                            allPackages.includes(i._id)
                                                ? "verified_dot"
                                                : "unverified_dot"
                                        }
                                    />
                                </div>

                                <div style={{ display: "inline" }}>
                                    {i.order}
                                </div>
                            </div>)
                        })}
                    </div>
                }
            </div>
        )
    }

    const checkingPastComplaints = async () => {
        if (SourceName === "Branch-Authenticated-BranchOrder") {
            setLastComplaint(BranchData?.docket?.feedBacks?.complaints);
        }
        if (SourceName === "Customer-Authenticated-BranchOrder") {
            const response: any = await getCustomerdocketDataTrack({
                docket: BranchData?.docket?.docketNumber,
                customer: newProps?.data?.user?._id,
                isFromMainPage: false
            });
            setLastComplaint(response?.docket?.feedBacks?.complaints);
        }
    }

    const handleIndividualChat = () => {
        {
            SourceName === "Branch-Authenticated-CustomerOrder" ?
                (alert("This Chat Module Only Valid For Customer Kindly Login as a Customer !"))
                :
                (
                    navigate("customerChat")
                )
        }
    }

    const trackFromTrackingID = async (docketNo: any) => {
        const payload = {
            docket: docketNo,
            sub: "B",
            entity: "5e8ec1a63657974a54b5d56a",
            queryType: "tracking",
            old: false,
        };
        const res = await fetchDocketDetails(payload);
        let {
            // docket,
            packages,
            // logImages,
            // consigneeIdImage,
            // podImage: delPOD,
            // reSolveIssues,
            // doorDeliveryDocket,
            // receiverDoorDeliveryAddr,
        } = res;
        // setOriginalDocket(docket)

        setorderArrayOption([
            ...packages.map((i: any) => ({
                label: i.order,
                value: i._id,
            })),
        ]);

        let uniqueFleetOrders = [
            ...new Map(
                res?.packages.map((item: any) => [
                    item["fleetOrderNumber"],
                    item.fleetOrderNumber,
                ])
            ).values(),
        ];
        setFleetOrderNumbers(
            uniqueFleetOrders && uniqueFleetOrders.length > 0
                ? uniqueFleetOrders
                : []
        );
    }

    // const printBuilty = async () => {
    //   try {
    //     const elem: any = document.getElementById("track_builty_download");

    //     if (typeof elem === null || elem === "undefined") {
    //     } else {
    //       elem.style.opacity = 1;
    //       let style = `
    //       @page { size: auto;  margin: 0mm; }
    //                   table, th, td {
    //                       border: 1px solid black;
    //                       border-collapse: collapse;
    //                   }
    //                   th, td {
    //                       text-align: justify;
    //                       padding: 5px;
    //                   }`;
    //       let divContents = elem.innerHTML;
    //       var a: any = window.open("", "", "");
    //       a.document.write("<head><style>" + style + "</style>");
    //       a.document.write('<body onload="window.print()">');
    //       a.document.write(divContents);
    //       a.document.write("</body>");
    //       a.document.write("</head>");
    //       a.document.close();
    //       elem.style.opacity = 0;
    //     }
    //   } catch (err) {
    //     const elem: any = document.getElementById("track_builty_download");
    //     if (typeof elem === null || elem === "undefined") {
    //     } else {
    //       elem.style.opacity = 0;
    //     }
    //   }
    // };

    const downloadBuilty = async () => {
        try {
            const elem: any = document.getElementById("track_builty_download");

            if (typeof elem === null || elem === "undefined") {
            } else {
                elem.style.opacity = 1;
                let style = `
              @page { size: auto;  margin: 0mm; }
                          table, th, td {
                              border: 1px solid black;
                              border-collapse: collapse;
                          }
                          th, td {
                              text-align: justify;
                              padding: 5px;
                          }`;
                let divContents = elem.innerHTML;
                var a: any = window.open("", "", "");
                a.document.write("<head><style>" + style + "</style>");
                a.document.write('<body onload="window.print()">');
                a.document.write(divContents);
                a.document.write("</body>");
                a.document.write("</head>");
                a.document.close();
                elem.style.opacity = 0;
            }
        } catch (err) {
            const elem: any = document.getElementById("track_builty_download");
            if (typeof elem === null || elem === "undefined") {
            } else {
                elem.style.opacity = 0;
            }
        }
    };

    const handleViewDocketBtn = () => {
        navigate("/dashboard/operations/fetchDocket", {
            state: {
                from: "tracking",
                data: BranchData?.docket.docketNumber,
            }
        })
    }

    const handleImagePackages = () => {
        setImagePackages(
            BranchData?.packages.map((r: any) => ({
                order: r.order,
                _id: r._id,
            }))
        );
    };

    const handleSubmitND = async () => {
        if (imagedPackageData?.length <= 0) {
            return showHttpError(`All package Images are required !`)
        }
        newProps.data.showLoader();
        try {
            const payload = {
                docket: BranchData?.docket._id,
                branch: newProps?.entity,
                NDCharge: NDAmount,
                newDestination: selectedND && selectedND.value,
                packages: imagedPackageData,
            };
            if (payload.NDCharge % 10 !== 0) {
                showMessage(
                    "New Destination charge should be a multiple of 10",
                    failed
                );
                newProps.data.hideLoader();
                return null;
            }
            await sendBackToNewDestination(payload);
            newProps.data.hideLoader();
            showMessage("Successfull !!", success);
            handleNDClose();
        } catch (err) {
            newProps.data.hideLoader();
            showHttpError(err);
        }
    };

    const addImagedPackage = (ele: any) => {
        const data = [...imagedPackageData];
        data.map((r: any, i: number) => {
            if (r.order === ele.order) {
                data.splice(i, 1);
            }
        });
        data.push(ele);
        setImagedPackageData(data);
    };

    const handleNDClose = () => {
        setNewDestOpen(false);
        setNDAmount("");
        setSelectedND("");
        setImagedPackageData([]);
        setImagePackages([]);
    };

    const handleSubmitBTO = async () => {
        if (imagedPackageData?.length <= 0) {
            return showHttpError(`All package Images are required !`)
        }
        newProps.data.showLoader();
        try {
            const payload = {
                docket: BranchData?.docket._id,
                branch: BranchData?.docket.entity,
                BTOCharge: btoAmount,
                packages: imagedPackageData,
            };
            await sendBackToOrigin(payload);
            newProps.data.hideLoader();
            showMessage("Successfull !!", success);
            handleBTOClose();
        } catch (err) {
            newProps.data.hideLoader();
            showHttpError(err);
        }
    };

    const handleBTOClose = () => {
        setBackToOriginOpen(false);
        setbtoAmount("");
        setImagedPackageData([]);
        setImagePackages([]);
    };

    const getGuestUpdatedData = async (docket: any) => {
        let feedBackPayload = {
            docketId: docket,
        };
        getGuestComplaint(feedBackPayload)
            .then((feedbackRes: any) => {
                setFeedbackResponse(feedbackRes);
            })
            .catch(() => setFeedbackResponse(""));
    };

    const handleDialogClose = () => {
        setIsDialogOpen(0)
        setOpenActionForRecieved(false)
        setOutsideDialogOpen(false)
        setOutsidePaymentDialogOpen(false)
        setOutsideRating(false)
        setOpen(false)
        setOpenAction(null);
        setReason("");
        setCustomerType("");
        setName("");
        setContact("");
        setDetails("");
        setImageTwo("");
        setMissBehaveUSer("");
        setMissBehaveBranch("");
        setFeedBack("");
        setImage("");
    };

    const handleReceived = async () => {
        newProps?.data?.showLoader();
        try {
            let selectedPackages = packageOrderForRecieved && packageOrderForRecieved?.map((x: any) => x?.value);
            const payload = {
                packages: selectedPackages,
                sub: newProps?.sub,
                entity: newProps?.entity,
                remarkText: reason,
            };
            await setReceivedPackages(payload);
            await trackFromTrackingID(BranchData?.docket?.docketNumber);
            handleDialogClose();
            // setPackageStatus(null);
            newProps?.data?.hideLoader();
        } catch (err) {
            newProps?.data?.hideLoader();
            showHttpError(err);
        }
    };

    const handleSubmitRemarks = async () => {
        newProps?.data?.showLoader();
        try {
            if (reason === "") {
                newProps?.data?.hideLoader();
                return showHttpError(`Remarks Should Not Be Empty !~`);
            }
            if (allPackages.length > 0) {
                let payload: { [k: string]: any } = {
                    packages: allPackages,
                    remarkType: openAction,
                    loginType: newProps?.sub,
                    remarkText: reason,
                    branch: newProps?.entity
                };
                if (Image) {
                    payload.remarkImage = Image;
                }

                let response = await setMissingPackages(jsonToFormdata(payload));
                showMessage(response.message, success, 2000);
                newProps?.data?.hideLoader();
                setIsDialogOpen(0)
                setOpenAction(null);
                setReason("");
                setImage("");
                setAllPackages([])
                setPackageOrderForRecieved([])
            }
            else {
                newProps?.data?.hideLoader();
                showMessage("Kindly Select Any Package", failed)
            }
        } catch (err) {
            showHttpError(err);
            newProps?.data?.hideLoader();
            setIsDialogOpen(0)
            setOpenAction(null);
            setReason("");
            setImage("");
            setAllPackages([])
            setPackageOrderForRecieved([])
        }
    };

    const qrRePrint = async () => {
        try {
            let payload: { [k: string]: any } = {
                docket: BranchData?.docket?.docketNumber,
                queryType: "tracking",
                old: false,
                sub: "B",
                entity: newProps?.entity
            }
            const res = await fetchDocketDetails(payload);
            await addQrReprintPackageLog({
                entity: newProps?.entity,
                docketNumber: BranchData?.docket?.docketNumber
            })
            printQRDocket(res.docket, res.packages);
        } catch (Err) {
            showMessage("Qr print failed!", failed);
        }
    }

    const fetchUser = async () => {
        newProps?.data?.showLoader();
        try {
            let tempArr: any = [];
            let res = await listUsersService()
            res.map((ele: any) => {
                tempArr.push({ label: `${ele?.name?.fName} ${ele?.name?.lName}`, value: ele?._id })
            })
            setUserOptions(tempArr);
            newProps?.data?.hideLoader();
        } catch (error) {
        }
    };

    const fetchUserForCustomer = async () => {
        newProps?.data?.showLoader();
        try {
            let tempArr: any = [];
            let res = await listAllUsersForCustomers()
            res.map((ele: any) => {
                tempArr.push({ label: `${ele?.name?.fName} ${ele?.name?.lName}`, value: ele?._id })
            })
            setUserOptions(tempArr);
            newProps?.data?.hideLoader();
        } catch (error) {
        }
    };

    const customerOptions = [
        { label: "Sender", value: "sender" },
        { label: "Receiver", value: "receiver" },
    ];

    const openInNewTab = (url: any) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const handleMBandLDcomplaints = async (value: any) => {
        newProps?.data?.showLoader();
        try {
            if (details === "") {
                newProps?.data?.hideLoader();
                return showHttpError(`Remarks Should Not Be Empty !~`);
            }
            let payload: any = {
                feedbackType: "COMP",
                complaintType: value,
                docketNumber: BranchData?.docket?.docketNumber,
                contactType: customerType && customerType.value,
                contactNumber: contact,
                contactName: name,
                messageText: details,
            };
            if (missBehaveBranch && missBehaveBranch.value) {
                payload.misbehaveBranch = missBehaveBranch.value;
            }
            if (misBehaveUser && misBehaveUser.value) {
                payload.misbehaveBy = misBehaveUser.value;
            }
            if (ImageTwo) {
                payload.media = ImageTwo;
            }
            const response: any = await createComplaintUser(payload)
            setFeedBack(response.feedbackNumber);
            setOpen(true);
            newProps?.data?.hideLoader();
        } catch (err) {
            showHttpError(err);
            newProps?.data?.hideLoader();
        }
    };

    const handleCustomerComplaintOutSide = async (value: any) => {
        newProps?.data?.showLoader();
        try {
            let payload: any = {
                feedbackType: "COMP",
                complaintType: value,
                docketNumber: BranchData?.docket?.docketNumber,
                contactType: customerType && customerType.value,
                contactNumber: contact,
                contactName: name,
                messageText: details,
            };

            await createComplaintGuest(payload);
            newProps?.data?.hideLoader();
            handleDialogClose()
        } catch (error) {
            showHttpError(error);
            newProps?.data?.hideLoader();
        }
    }

    const hanldeCustomerComaplints = async (value: any) => {
        newProps?.data?.showLoader();
        try {
            if (details === "") {
                newProps?.data?.hideLoader();
                return showHttpError(`Remarks Should Not Be Empty !~`);
            }
            let payload: any = {
                feedbackType: "COMP",
                docketNumber: BranchData?.docket?.docketNumber,
                complaintType: value,
                messageText: details
            }
            const response: any = await addCustomerComplaintForBranchOrder(payload)
            showMessage("Your Complaint has been created, To see all Complaints Click on the Message Icon", success, 3000)
            setFeedBack(response.feedbackNumber);
            setOpen(true);
            setDetails("")
            handleDialogClose()
            newProps?.data?.hideLoader();
        } catch (err) {
            showHttpError(err);
            newProps?.data?.hideLoader();
        }
    };

    const handleLogin = async () => {
        // newProps?.data?.showloader();
        try {
            localStorage.removeItem("refreshToken")
            localStorage.removeItem("refreshTokenExpiresAt")
            socket.emit("logout", localStorage.getItem("kabra:customertoken"));
            await newProps?.data?.customerLogin(selectedNumber, firstPopupConfirmPassword, false, localStorage.getItem("deviceId"), localStorage.getItem("latitude"), localStorage.getItem("longitude"))
            navigate("/customerDashboard/booking")
            return;
            // newProps?.data?.hideloader();
        } catch (error) {
            // newProps?.data?.hideloader();
            showHttpError(error)
        }
    }

    const handleSignUp = async () => {
        // newProps?.data?.showloader();
        try {
            if (firstPopupNewPassword === firstPopupConfirmPassword) {
                let payload: any = {
                    password: firstPopupNewPassword,
                    contact: selectedNumber,
                    OTP: firstPopupOtp
                }
                await setCustomerPassword(payload);
                // let payloadForOtp:any = {
                //   contact: selectedNumber,
                //   OTP: firstPopupOtp
                // }
                // let response = await verifyOTP(payloadForOtp)
                handleLogin()
                // historyData.push("/customerDashboard/booking")
                // newProps?.data?.hideloader();
            }
            else {
                return showMessage("New Password and Confirm Password is not Matched ~!", failed, 3000);
            }
        } catch (error) {
            // newProps?.data?.hideloader();
            showHttpError(error)
        }
    }

    const getPhoneNumberPrefix = (contact: any) => {
        let newcontact = contact.toString();
        let firstThreeNumber: any = newcontact.substr(0, 3)
        let lastThreeNumber: any = newcontact.substr(7, 9)
        return `${firstThreeNumber}****${lastThreeNumber}`
    }

    const handleRatingForBranchOrder = async () => {
        if (!customerTypeForRating) {
            return showHttpError("Select Any One Customer Type !")
        }
        try {
            let payload = {
                "feedbackType": "ALL",
                "feedbackTitle": reviewFromUser,
                "docketNumber": BranchData?.docket.docketNumber,
                "rating": ratingFromUser,
                "customerContact": customerTypeForRating?.value === "sender" ? BranchData?.docket?.sender?.contact : BranchData?.docket?.receiver?.contact,
                "customerName": customerTypeForRating?.value === "sender" ? BranchData?.docket?.sender?.name : BranchData?.docket?.receiver?.name,
            }
            await customerReviewRating(payload);
            setRatingDone(true);
            showMessage("Review & Rating Succesfully Given", success, 2000);
            handleDialogClose();
        } catch (error) {
            showHttpError(error);
        }
    }

    const getOtpForBranchorder = async () => {
        try {
            let payload = { docketNumber: BranchData?.docket.docketNumber };
            let response = await getOtpForBranchOrderTracking(payload);
            setOtpForDelivery(response);
        } catch (error) {
            showHttpError(error);
        }
    }

    const handleIndividualBranchOrderChat = (extraData: any, feedbackNumber: any, bookingtype?: any) => {
        // historyData.push({
        //   pathname: "customerChat/individualChat",
        //   state: {
        //     from: "redirect-chat-from-customerchat-list",
        //     data: { feedbackNumber },
        //     anotherData: extraData,
        //     bookingType: bookingtype
        //   }
        // })
        navigate("/CustomerDashboard/customerChat/individualChat", {
            state: {
                from: "redirect-chat-from-customerchat-list",
                data: { feedbackNumber },
                anotherData: extraData,
                bookingType: bookingtype
            }
        })
    }

    useEffect(() => {
        getBranchOrderData();
        getOtpForBranchorder();
        // newProps.data.resetDoorDelivery();
        if (SourceName === "Customer-Authenticated-BranchOrder") {
            fetchUserForCustomer();
            checkingPastComplaints();
        }
        if (SourceName === "Branch-Authenticated-BranchOrder") {
            fetchUser();
            checkingPastComplaints();
        }
        if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
            setFirstPopup(true);
            // initGA4();
        }

        branchListData &&
            branchListData.length > 0 &&
            setNewDestOptions([
                ...branchListData.map((i: any) => ({
                    label: i.branchName,
                    value: i._id,
                })),
            ]);
        branchListData &&
            branchListData.length > 0 &&
            setBranchOptions([
                ...branchListData.map((i: any) => ({
                    label: i.branchName,
                    value: i._id,
                })),
            ]);
        // 
        showMessage("Order Tracked Succesfully", success, 2000);
        if (SourceName === "Branch-Authenticated-BranchOrder") {
            trackFromTrackingID(BranchData?.docket.docketNumber)
        }

        BranchData?.packages?.map((x: any) => {
            if (x.status === "MR") {
                setMissingParcel(true)
            }
            if (x.status === "M") {
                setMissingParcel(true)
            }
            if (x.status === "INIT") {
                setNotReceivedDisabled(true);
            }
        }
        )

        if (BranchData?.docket?.feedBacks && BranchData?.docket?.feedBacks?.review?.length > 0) {
            setReviewFromUser(BranchData?.docket?.feedBacks?.review[0]?.feedbackTitle)
            setRatingFromUser(BranchData?.docket?.feedBacks?.review[0]?.rating)
            setRatingDone(true)
        }
    }, [])

    useEffect(() => {
        function handleresize() {
            if (window.innerWidth > 770) {
                setmobileView(false);
            } else {
                setmobileView(true);
            }
        }
        window.addEventListener("resize", handleresize);
        return () => {
            window.removeEventListener("resize", handleresize);
        };
    }, []);

    useEffect(() => {
        if (senderOptIn === "Sender") {
            if (BranchData?.docket?.sender?.isPassWordExists === true) {
                setUserHasPass(true);
            }
        }
        else if (senderOptIn === "Reciever") {
            if (BranchData?.docket?.receiver?.isPassWordExists === true) {
                setUserHasPass(true);
            }
        }
    }, [senderOptIn])

    useEffect(() => {
    }), [newProps?.data?.customerChatHistory, ratingDone]

    const goToMap = () => {
        if (iOS === true) {
            window.open(
                `comgooglemapsurl://maps.google.com/maps?z=12&t=m&q=loc:${BranchData?.docket?.actDestBranch?.bLocation?.coordinates[1]}+${BranchData?.docket?.actDestBranch?.bLocation?.coordinates[0]}`
                , '_blank');
        }
        else {
            window.open(
                `http://maps.google.com/maps?z=12&t=m&q=loc:${BranchData?.docket?.actDestBranch?.bLocation?.coordinates[1]}+${BranchData?.docket?.actDestBranch?.bLocation?.coordinates[0]}`
                , '_blank');
        }

    }

    const setInState = (Data: any) => {

        const doorDeliveryDocketData = Data?.doorDeliveryDocket?.doorDelAddress
        newProps.data.setDoorDelivery("officeName", doorDeliveryDocketData?.officeName);
        newProps.data.setDoorDelivery("floor", doorDeliveryDocketData?.floor);
        newProps.data.setDoorDelivery("tower", doorDeliveryDocketData?.tower);
        newProps.data.setDoorDelivery("nearby_landmark", doorDeliveryDocketData?.nearby_landmark);
        if (Data?.doorDeliveryDocket?.doorDelDocketNumber)
            newProps.data.setDoorDelivery("city", { label: Data?.docket?.destBranch?.address?.city?.name, value: Data?.docket?.destBranch?.address?.city?._id });
        newProps.data.setDoorDelivery("delCity", { label: Data?.docket?.destBranch?.address?.city?.name, value: Data?.docket?.destBranch?.address?.city?._id });
        newProps.data.setDoorDelivery("state", { label: Data?.docket?.destBranch?.address?.city?.state?.name, value: Data?.docket?.destBranch?.address?.city?.state?._id });
        newProps.data.setDoorDelivery("pincode", doorDeliveryDocketData?.pincode);
        newProps.data.setDoorDelivery("origin", {
            place_id: doorDeliveryDocketData?.place?.placeId,
            formatted_address: doorDeliveryDocketData?.place?.placeValue,
            name: doorDeliveryDocketData?.place?.name,
            geometry: {
                location: {
                    lat: doorDeliveryDocketData?.place?.coordinate?.latitude,
                    lng: doorDeliveryDocketData?.place?.coordinate?.longitude,
                },
            },
        })
    }

    function toHoursAndMinutes(totalSeconds: any) {
        let hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
        return `${hours} hr ${minutes} min`
    }

    const handleSend = async () => {
        try {
            let payload: any = {
                messageText: guestMessage,
            };
            if (replyImage) {
                payload.media = replyImage;
            }
            newProps?.data?.userInfo?.uid
                ? await sendUserMessage(
                    latComplaint?.feedbackNumber,
                    jsonToFormdata(payload)
                )
                : await sendGuestMessage(
                    latComplaint?.feedbackNumber,
                    jsonToFormdata(payload)
                );
            setGuestMessage("");
            setReplyImage("");

            await getGuestUpdatedData(BranchData?.docket?._id);
        } catch (err) {
            showHttpError(err);
        }
    };

    //   const handleIndividualChatBranchOrder = (otherData:any,feedBackNumber:any,type:any) => {
    //     historyData.push({pathname:"/dashboard/individualChat",
    //                         state:{
    //                           from: "redirect-chat-from-customerchat-list",
    //                           data: feedBackNumber,
    //                           anotherData : otherData,
    //                           bookingType : type
    //                         }
    //                       })
    //  }

    const rePrintVoucher = () => {
        const csstext: any = `
          h2 {
            color: black;
            font-family: sans-serif;
          }
          `;
        const d = new Printd();
        let doc: any = document.getElementById("door_delivery_voucher_print");
        d.print(doc, csstext);
    }

    const handleClose = async (ind: any) => {
        try {
            let payload: any = {
                messageText: guestMessage,
                complaintStatus: newProps?.data?.userInfo?.uid ? "CU" : "CC",
            };
            if (replyImage) {
                payload.media = replyImage;
            }
            newProps?.data?.userInfo.uid
                ? await sendUserMessage(
                    BranchData?.docket?.feedBacks?.complaints[ind]?.feedbackNumber,
                    jsonToFormdata(payload)
                )
                : await sendGuestMessage(
                    BranchData?.docket?.feedBacks?.complaints[ind]?.feedbackNumber,
                    jsonToFormdata(payload)
                );
            setGuestMessage("");
            setReplyImage("");
            await getGuestUpdatedData(BranchData?.docket?._id);
        } catch (err) {
            showHttpError(err);
        }
    };

    const getFinalDeliveryTime = (value: any) => {
        var flag = true;
        BranchData?.packages?.map((val: any, index: any) => {
            let ref = val[0]?.expectedReachTime;
            if (val[index + 1]?.expectedReachTime !== ref) {
                flag = false;
            }
        });
        if (flag) {
            if (value === "Branch-Authenticated-BranchOrder") {
                return (<div style={{
                    color: "var(--clr-primary-400)"
                }} className="fs-link">
                    <p className="mx-auto fs-link">{`Your Parcel is Expected to Reach the Destination on`}</p> {" "}
                    <span>
                        {`${formatDate(BranchData?.packages[0]?.expectedReachTime)}`}
                    </span>
                    <span>
                        {` at `}
                    </span>
                    <span>
                        {`${formatTime(BranchData?.packages[0]?.expectedReachTime)}.`}
                    </span>
                </div>)
            }
            else if (value === "Customer-Authenticated-BranchOrder") {
                return <p style={{ color: "var(--clr-primary-400)" }} className="mx-auto fs-link">{`Your Parcel is Expected to Reach the  Destination on ${formatDate(BranchData?.packages[0]?.expectedReachTime)} at ${formatTime(BranchData?.packages[0]?.expectedReachTime)}.`}</p>
            }
            else {
                return <p style={{ color: "var(--clr-primary-400)" }} className="mx-auto fs-link">{`Your Parcel is Expected to Reach the Destination on ${formatDate(BranchData?.packages[0]?.expectedReachTime)} at ${formatTime(BranchData?.packages[0]?.expectedReachTime)}.`}</p>
            }
        }
        else {
            return <div></div>
        }
    }
    //amount,docketNumber,txnsNumber,nonCashPaymentId
    const handlePayment = async () => {
        newProps?.data?.showLoader();
        try {
            const currentURLWithPort = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
            let body: any = {
                "merchant_id": 174716,
                "amount": process.env.NODE_ENV != "development" ? (DoorDelBookingCheckBox ? BranchData?.doorDeliveryDocket?.deliveryCharge : BranchData?.docket.gross) : 5,

                "frontend_redirect_url": `${currentURLWithPort}/customerDashboard/booking`,
                nonCashPaymentId: "655dabeb1baae4150e9ad853",// for testing need to call api
                docketNumber: BranchData?.docket.docketNumber,
                contact: BranchData?.docket?.sender?.contact,// default set for testing
                txnsType: DoorDelBookingCheckBox ? "DoorDeliveryCustomerOnline" : "CustomerOnline"
            }
            let response = await createCustomerCCAvenueTxn(body);
            const encRequest = response && response?.encRequest;
            const accessCode20 = response && response?.accessCode;
            // setTxnsNumber(response.CCAvenueTxns.txnsNumber)
            if (encRequest) {
                // const redirectURL = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${encRequest}&access_code=${accessCode}`;
                // window.location.href = redirectURL;
                const a = document.createElement("a");
                // a.setAttribute("target", "_blank");
                a.href = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${encRequest}&access_code=${accessCode20}`;
                document.body.appendChild(a);
                a.click();
            }

            newProps?.data?.hideLoader();
        } catch (error) {
            newProps?.data?.hideLoader();
            showHttpError(error)
        }
    };

    const handleOutsidePayment = async () => {
        if (!senderOptInForPayment) {
            return showHttpError(`Select Sender Or Reciever Is Mandatory !`);
        }
        newProps?.data?.showLoader();
        try {
            const currentURLWithPort = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
            let body: any = {
                "merchant_id": 174716,
                "amount": process.env.NODE_ENV === "production" ? (DoorDelBookingCheckBox ? BranchData?.doorDeliveryDocket?.deliveryCharge : BranchData?.docket.gross) : 5,
                "frontend_redirect_url": `${currentURLWithPort}/BlindPage`,
                nonCashPaymentId: "655dabeb1baae4150e9ad853",// for testing need to call api
                docketNumber: BranchData?.docket.docketNumber,
                txnsType: DoorDelBookingCheckBox ? "DoorDeliveryCustomerOnline" : "CustomerOnline",
                contact: selectedNumberForPayment
            }
            let response = await createCustomerCCAvenueTxn(body);
            const encRequest = response && response?.encRequest;
            const accessCode20 = response && response?.accessCode;
            // setTxnsNumber(response.CCAvenueTxns.txnsNumber)
            if (encRequest) {
                // const redirectURL = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${encRequest}&access_code=${accessCode}`;
                // window.location.href = redirectURL;
                const a = document.createElement("a");
                // a.setAttribute("target", "_blank");
                a.href = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${encRequest}&access_code=${accessCode20}`;
                document.body.appendChild(a);
                a.click();
            }

            newProps?.data?.hideLoader();
        } catch (error) {
            newProps?.data?.hideLoader();
            showHttpError(error)
        }
    };

    // const makePayment=async()=>{

    //     showLoader();
    //     try {
    //       const payload={
    //         "amount":5,
    //         nonCashPaymentId:"655dabeb1baae4150e9ad853",
    //         docketNumber:BranchData?.docket.docketNumber,
    //         txnsNumber:"000250"
    //       }
    //   await  BranchBookingMakePayment(payload)
    //   hideLoader();
    // } catch (error) {
    //   hideLoader();
    //   showHttpError(error)
    // }
    //   }

    let checkValidity = (date: string) => {
        let validDate = new Date(date)
        validDate.setDate(validDate.getDate() + 1)

        return validDate >= new Date()
    }

    let branchDeliveryCharge =
        (BranchData?.docket?.settled == false && !BranchData?.docket?.isDeliveryChargePaid &&
            typeof BranchData?.docket?.branchDeliveryChargeInfo?.acceptedBranchDeliveryCharge === "number" && BranchData?.docket?.branchDeliveryChargeInfo.status === "A" &&
            checkValidity(BranchData?.docket?.branchDeliveryChargeInfo?.processedAt)) ?
            BranchData?.docket?.branchDeliveryChargeInfo?.acceptedBranchDeliveryCharge :
            typeof BranchData?.docket?.deliveryCharge === "number" ?
                BranchData?.docket?.deliveryCharge : 0;


    let demurrage = typeof BranchData?.docket?.paidDemurrage === "number"
        ? BranchData?.docket?.paidDemurrage
        : typeof BranchData?.docket?.actualDemurrage === "number"
            ? BranchData?.docket?.actualDemurrage
            : 0;

    let discount = typeof BranchData?.docket?.discount === "number" ? BranchData?.docket?.discount : typeof BranchData?.docket?.discountInfo?.acceptedDiscount === "number" && checkValidity(BranchData?.docket?.discountInfo?.processedAt) && BranchData?.docket?.discountInfo?.status === "A" ? BranchData?.docket?.discountInfo?.acceptedDiscount : 0;

    let gstCustodyPenalty = typeof BranchData?.docket?.gstCustodyPenalty === "number"
        ? BranchData?.docket?.gstCustodyPenalty
        : 0;

    let deliveryCharge = BranchData?.docket?.doorDelCharges;


    let additionalCost =
        BranchData &&
        BranchData?.docket?.bilty +
        BranchData?.docket?.handling +
        (BranchData?.docket?.gstAmount ? BranchData?.docket?.gstAmount : 0);


    let FinalAsn = (BranchData &&
        BranchData?.docket?.settled &&
        BranchData?.packages &&
        BranchData?.packages?.length > 0
        ? BranchData?.packages?.reduce((a: any, b: any) => a + b.amount, 0)
        : BranchData?.packages?.reduce(
            (a: any, b: any) => a + b.amount,
            0
        )) +
        additionalCost +
        (BranchData && (BranchData?.docket?.pickupCharge ? BranchData?.docket?.pickupCharge : 0)) +
        (BranchData && (BranchData?.docket?.colAmt ? BranchData?.docket?.colAmt : 0)) +
        (BranchData &&
            (BranchData?.docket?.BTOCharge
                ? BranchData?.docket?.BTOCharge
                : 0)) +
        (BranchData &&
            (BranchData?.docket?.BTHCharge
                ? BranchData?.docket?.BTHCharge
                : 0)) +
        (BranchData &&
            (BranchData?.docket?.NDCharge
                ? BranchData?.docket?.NDCharge
                : 0)) +
        (BranchData &&
            (BranchData?.docket?.intermediateCharge
                ? BranchData?.docket?.intermediateCharge
                : 0)) +
        (BranchData?.packages && BranchData?.packages?.length > 0 ?
            BranchData?.packages?.reduce(
                (a: any, b: any) => a + (b.extraGrossFreight || 0),
                0
            ) : 0
        )

    let TotalAmount: any = Math.round(FinalAsn / 10) * 10;

    let totalAmount =
        BranchData &&
        (Math.round(
            (parseInt(TotalAmount) +
                +Number(branchDeliveryCharge) +
                Number(deliveryCharge) +
                Number(gstCustodyPenalty) +
                Number(demurrage) -
                Number(discount)) / 10
        ) * 10);


    let totalNetCost =
        BranchData &&
        (Math.round(
            (parseInt(TotalAmount) +
                +Number(branchDeliveryCharge) +
                Number(deliveryCharge) -
                Number(discount)) / 10
        ) * 10);

    let totalDoorDelCost = BranchData &&
        (Math.round(
            Number(!BranchData?.doorDeliveryDocket?.settled ? Number(BranchData?.doorDeliveryDocket?.totalDoorDelCharges) : 0) / 10
        ) * 10);

    let totalAmountWithoutTopay = totalAmount - BranchData?.docket?.gross;

    // let combinedTotalAmount =
    //     BranchData &&
    //     (Math.round(
    //         (parseInt(TotalAmount) +
    //             +Number(branchDeliveryCharge) +
    //             Number(deliveryCharge) +
    //             (!BranchData?.doorDeliveryDocket?.settled ? Number(BranchData?.doorDeliveryDocket?.deliveryCharge) : 0) +
    //             Number(gstCustodyPenalty) +
    //             Number(demurrage) -
    //             Number(discount)) / 10
    //     ) * 10);

    // let combinedAmountWithoutTopay = combinedTotalAmount - TotalAmount;

    let showOnlyTotalAmount =
        BranchData &&
        (Math.round(
            (parseInt(TotalAmount) +
                +Number(branchDeliveryCharge) +
                Number(deliveryCharge) +
                (BranchData?.doorDeliveryDocket?.docketNumber ? Number(BranchData?.doorDeliveryDocket?.deliveryCharge) : 0) +
                Number(gstCustodyPenalty) +
                Number(demurrage) -
                Number(discount)) / 10
        ) * 10);

    // let onlyDoorDeliveryAmount = BranchData && BranchData?.doorDeliveryDocket && (Math.round(((!BranchData?.doorDeliveryDocket?.settled ? Number(BranchData?.doorDeliveryDocket?.deliveryCharge) : 0)) / 10) * 10);

    useEffect(() => {
        const { billUrl, idUrl, consigneeIdImage, receiverPhoto, docketUrls } = BranchData?.imageGallery;

        const Arr: any = [];

        if (billUrl) {
            Arr.push({ name: "Bill Image", url: billUrl });
        }
        if (docketUrls?.length > 0) {
            docketUrls?.map((x: any, index: any) => {
                Arr.push({ name: `Docket Image ${index + 1}`, url: x });
            })
        }
        if (idUrl) {
            Arr.push({ name: "Id Image", url: idUrl });
        }
        if (consigneeIdImage) {
            Arr.push({ name: "Consignee Id Image", url: consigneeIdImage });
        }
        if (receiverPhoto) {
            Arr.push({ name: "Receiver Image", url: receiverPhoto });
        }

        setArrayOfImage(Arr);

    }, [BranchData?.imageGallery])

    return (
        // <div id="BranchPersonAuthenticatedViewContainer">
        <>
            {mobileView && SourceName !== "Customer-NotAuthenticated-BranchOrder" ?
                <div className="p-1 pt-0 bg-white-900 flex ai-center gap-8 relative fs-heading">
                    <BackIcon className="text-primary-900" onClick={() => navigate(newProps?.data?.customerTrackingData?.backToOriginPath)} />
                    <div className="" style={{ position: "absolute", left: "50%", transform: "translateX(-50%)" }}>
                        <PageTitle title="Order Tracking" className="bg-transparent text-primary-900" style={{ fontSize: "clamp(1.75rem, 3vw, 2rem)" }} />
                    </div>
                </div>
                :
                <div className="p-1 bg-white-900">
                    <PageTitle title="Order Tracking" className="fs-heading bg-transparent text-primary-900" style={{ fontSize: "clamp(22px, 3vw, 24px)" }} />
                </div>
            }
            <div className="p-1 border-white shadow-small br-4 max-w-1200 mx-auto">

                <div className="headingBranchTracking m-0 w-100 flex jc-end ai-center gap-1 flex-wrap m-flex-col m-ai-end relative">
                    {/* <div>
                <ButtonPrimary
                  onClick={() => {
                    if(SourceName === "Customer-Authenticated-BranchOrder"){
                      historyData.push("/CustomerDashboard/pastBookings")
                    }
                    else{
                      historyData.goBack()
                    }
      
                  }}
                  className="backBTN"
                > 
                  <BackIcon />
                </ButtonPrimary>
              </div> */}
                    {/* {SourceName === "Customer-NotAuthenticated-BranchOrder" &&
                <div className="outSideOrderTrackBackBtn">
                  <ButtonPrimary
                    onClick={() => historyData.push("/")}
                    className="backBTN"
                  > 
                    <BackIcon />
                  </ButtonPrimary>
                </div>
              } */}
                    {/* {!mobileView && SourceName !== "Customer-NotAuthenticated-BranchOrder" &&
              <div className="outSideOrderTrackBackBtn">
                <ButtonPrimary
                  onClick={() => historyData.push(newProps?.data?.customerTrackingData?.backToOriginPath)}
                  className="backBTN"
                > 
                  <BackIcon />
                </ButtonPrimary>
              </div>
              } */}
                    <div className="flex gap-1 ai-center fs-link p-1">
                        {arrayOfImage?.length > 0 &&
                            <ToolTip title="Photo Gallery">
                                <span onClick={() => { setPackageImageDialog(true) }}><ImagesIcon className={`${iconsClassName}`} /></span>
                            </ToolTip>
                        }
                        {SourceName === "Branch-Authenticated-BranchOrder" &&
                            <>
                                <ToolTip title="QR Print">
                                    <button onClick={() => { qrRePrint() }}><QRCodeIcon className={`${iconsClassName}`} /></button>
                                </ToolTip>
                                {/* <ToolTip title="Print Builty">
                      <button onClick={printBuilty} ><FaPrint style={{fontSize:"x-large"}} /></button>
                    </ToolTip> */}
                            </>
                        }
                        {SourceName === "Customer-Authenticated-BranchOrder" ?
                            // SourceName === "Branch-Authenticated-BranchOrder" || 
                            <ToolTip title="Download Builty">
                                <button className="bg-transparent border-none outline-none" onClick={downloadBuilty}><DownloadIcon className={`${iconsClassName}`} /></button>
                            </ToolTip>
                            :
                            <></>
                        }
                        <ShareToWhatsapp docketId={BranchData?.docket?._id} />
                        {SourceName === "Branch-Authenticated-BranchOrder" &&
                            <ToolTip title="BTO , Fetch , New Dest , Complaint">
                                <button onClick={() => { showMoreBtns(!moreBtns) }}><ThreeDotsIcon className={`${iconsClassName}`} /></button>
                            </ToolTip>
                        }
                        {/* Images Popup Started */}
                        <Dialog open={packageImageDialog} onClose={() => { setPackageImageDialog(false) }}>
                            <DialogTitle>
                                Gallery
                            </DialogTitle>
                            <DialogContent>
                                <Select
                                    placeholder="Select Image Type"
                                    onChange={(e: any) => {
                                        setSelectedImage(e);
                                    }}
                                    value={selectedImage}
                                    options={imagesOptions}
                                />
                                {selectedImage?.value !== ''
                                    &&
                                    <MyImage
                                        rotate={false}
                                        isSigned={true}
                                        pagination={false}
                                        objectID={selectedImage?.value}
                                    />
                                }
                            </DialogContent>
                            <DialogActions>
                                <ButtonDefault onClick={() => { setPackageImageDialog(false) }} className={`button-danger-secondary fs-popup-btn`}>Close</ButtonDefault>
                            </DialogActions>
                        </Dialog>
                        {/* Images Popup Ended */}
                    </div>
                </div>

                {mobileView ?
                    <div className="p-1 border-white grid gap-1 shadow-small br-4 fs-link">
                        {/* Docket Number & Number of Packages */}
                        <div className='flex gap-1 flex-wrap'>
                            <div className='flex gap-4 ai-center'>
                                <DocketNumberIcon className={`${iconsClassName}`} />
                                <p>
                                    {SourceName === "Branch-Authenticated-BranchOrder" ? BranchData?.docket.docketNumber : BranchData?.docket.trackingNumber}
                                </p>
                            </div>
                            <div className='flex gap-4 ai-center'>
                                <span><PackageIcon className={`${iconsClassName}`} />{" "}</span>
                                <p>
                                    {BranchData?.packages?.length}
                                </p>
                            </div>
                        </div>
                        {/* Created At & Delivered At */}
                        <div className="two-column gap-1">
                            <div className='flex ai-center gap-4'>
                                <StartDateIcon className={`${iconsClassName}`} />{" "}
                                <p>
                                    {formatDate(BranchData?.docket.createdAt)}
                                </p>
                            </div>
                            <div className='flex ai-center gap-4'>
                                {BranchData?.docket.deliveredAt ? <EndDateIcon className={`${iconsClassName}`} />
                                    : <TimelineIcon className={`${iconsClassName}`} />
                                }{" "}
                                <p>
                                    {BranchData?.docket.deliveredAt ? formatDate(BranchData?.docket.deliveredAt) : "In Transit"}
                                </p>
                            </div>
                        </div>
                        {/* Origin & Destination Branch */}
                        <div className="two-column gap-1">
                            <div className='flex ai-center gap-4'>
                                <LocationIcon className={`${iconsClassName}`} />{" "}
                                <p>
                                    {BranchData?.docket.originBranch.branchName}{" "}
                                </p>
                            </div>
                            <div className='flex ai-center gap-4'>
                                <SharpLocationIcon className={`${iconsClassName}`} />{" "}
                                <p className="text-primary-400" onClick={() => setBranchAdd(true)}>
                                    {BranchData?.docket.destBranch.branchName}{" "}
                                    {/* {BranchData?.docket?.deliveredAt === undefined &&
                  <span className='destBranchCss'>
                    <InfoIcon style={{ color: "var(--clr-primary-500)" }} onClick={() => { setBranchAdd(true) }} />
                  </span>
                } */}
                                </p>
                            </div>
                        </div>
                        <div className='two-column gap-1'>
                            <div className='flex ai-center gap-4'>
                                <CashIcon className={`${iconsClassName}`} />{" "}
                                {BranchData?.docket.canceledInfo ?
                                    <p style={{ width: "100%" }}>
                                        Order Cancelled
                                    </p>
                                    :
                                    <p className="paymentOptionContainerForBranchDocket flex-center">
                                        {!BranchData?.docket.settled ? (BranchData?.docket.paymentMode === "topay" ?
                                            <div className="ownBtn23 flex-wrap">
                                                <button className={selectPaymentOptionBtn2 === BranchData?.docket.paymentMode ? "activeButton" : ""} onClick={() => { setSelectPaymentOptionBtn2(BranchData?.docket.paymentMode) }} > {BranchData?.docket.paymentMode} </button>
                                                <button className={selectPaymentOptionBtn2 === "online" ? "" : "payOnlineAttractive"} onClick={() => {
                                                    if (SourceName === "Customer-NotAuthenticated-BranchOrder" || SourceName === "Customer-NotAuthenticated-CustomerOrder") {
                                                        setOutsidePaymentDialogOpen(true)
                                                    }
                                                    else {
                                                        handlePayment()
                                                    }
                                                }}>Online</button>
                                            </div>
                                            :
                                            <div>{" "} {BranchData?.docket.paymentMode}</div>) : <div>{" "} {BranchData?.docket.paymentMode}</div>
                                        }
                                    </p>
                                }
                            </div>
                            <div className='flex ai-center gap-4'>
                                <RupeeIcon style={{ backgroundColor: "white", width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />{" "}
                                <p>
                                    {" "} {showOnlyTotalAmount}
                                </p>
                                <InfoIcon className={`${iconsClassName}`} onClick={() => { setPaymentInfo(true) }} />
                            </div>
                        </div>

                        <Dialog
                            open={branchAdd}
                            maxWidth={"xl"}
                            onClose={() => { setBranchAdd(false) }}
                        >
                            <div className='tac bold'>
                                <DialogTitle
                                    style={{ textAlign: "center" }}
                                    id="draggable-dialog-title"
                                >
                                    <div className='commonTitleOfPopup'>
                                        Branch Address
                                    </div>
                                </DialogTitle>
                            </div>
                            <DialogContent>
                                <div className='popupForbranchAddress'>
                                    {!BranchData?.doorDeliveryDocket.doorDelDocketNumber &&
                                        <>
                                            {BranchData?.docket?.deliveredAt === undefined ?
                                                <div className='branchAddressContainer'>
                                                    <div>{BranchData?.docket?.actDestBranch?.branchName}</div>
                                                    <div>{BranchData?.docket?.actDestBranch?.address?.l1}</div>
                                                    <div>{BranchData?.docket?.actDestBranch?.address?.l2}</div>
                                                    <div>{BranchData?.docket?.actDestBranch?.address?.pincode}</div>
                                                    <div>{BranchData?.docket?.actDestBranch?.pContact}</div>
                                                    <div>{BranchData?.docket?.actDestBranch?.address?.city?.name}</div>
                                                    <div>{BranchData?.docket?.actDestBranch?.address?.city?.state?.name}</div>
                                                    <div>{BranchData?.docket?.actDestBranch?.manager?.name?.fName} , {BranchData?.docket?.actDestBranch?.manager?.contact}</div>
                                                </div>
                                                :
                                                <></>
                                            }
                                        </>
                                    }
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <div className='onLinePkgbtnMobile'>
                                    {BranchData?.docket?.actDestBranch?.bLocation &&
                                        <ButtonPrimary
                                            className=""
                                            onClick={() => { goToMap() }}
                                        >
                                            Navigate
                                        </ButtonPrimary>
                                    }
                                    <ButtonDefault
                                        className=""
                                        onClick={() => { setBranchAdd(false) }}
                                    >
                                        close
                                    </ButtonDefault>
                                </div>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={paymentInfo}
                            maxWidth={"xl"}
                            onClose={() => { setPaymentInfo(false) }}
                        >
                            <div className='tac bold'>
                                <DialogTitle
                                    style={{ textAlign: "center" }}
                                    id="draggable-dialog-title"
                                >
                                    <div className='commonTitleOfPopup'>
                                        Payment Details
                                    </div>
                                </DialogTitle>
                            </div>
                            <DialogContent>
                                <div className='popupForbranchAddress'>

                                    {!BranchData?.doorDeliveryDocket.doorDelDocketNumber &&
                                        <div>
                                            <table>
                                                <thead>
                                                    <tr className="fs-link" style={{ justifyContent: "space-evenly", textAlign: "center" }}>
                                                        <th className="bg-primary-400 text-white-900" style={{ border: "1px solid var(--clr-white-400)", width: "70%", padding: "8px" }}>PARTICULARS</th>
                                                        <th className="bg-primary-400 text-white-900" style={{ border: "1px solid var(--clr-white-400)", padding: "8px" }}>AMOUNT</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="fs-link">
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px" }}>Net Cost</td>
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px", textAlign: "center" }}>{TotalAmount}</td>
                                                    </tr>
                                                    <tr className="fs-link">
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px" }}>Branch Delivery</td>
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px", textAlign: "center" }}>{BranchData?.docket?.doorDelivery ? 0 : branchDeliveryCharge}</td>
                                                    </tr>
                                                    <tr className="fs-link">
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px" }}>Demurrage</td>
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px", textAlign: "center" }}>{demurrage ? demurrage : 0}</td>
                                                    </tr>
                                                    <tr className="fs-link">
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px" }}>Discount</td>
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px", textAlign: "center" }}>{discount ? discount : 0}</td>
                                                    </tr>
                                                    <tr className="fs-link">
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px" }}>GST Penalty</td>
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px", textAlign: "center" }}>{gstCustodyPenalty ? gstCustodyPenalty : 0}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {/* <div>Net Cost : {TotalAmount} </div>
                      <div>Branch Delivery : {BranchData?.docket?.doorDelivery  ? 0 : branchDeliveryCharge}</div>
                      <div>Demurrage : {demurrage ? demurrage : 0}</div>
                      <div>Discount : {discount ? discount : 0}</div>
                      <div>GST Penalty : {gstCustodyPenalty ? gstCustodyPenalty : 0}</div> */}
                                        </div>
                                    }
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <div className='onLinePkgbtnMobile'>
                                    <ButtonDefault
                                        className="button-danger-secondary fs-popup-btn"
                                        onClick={() => { setPaymentInfo(false) }}
                                    >
                                        close
                                    </ButtonDefault>
                                </div>
                            </DialogActions>
                        </Dialog>
                    </div>
                    :
                    <div className="">
                        <div className='generalInfoForBranchTracking m-0 w-100'>
                            <div className='four-column t-two-column m-one-column m-one-column mt-1 p-1 border-white shadow-small br-4 gap-1'>
                                <div className="flex gap-4 ai-center">
                                    <span><DocketNumberIcon className={`${iconsClassName}`} /></span>
                                    <p>
                                        {SourceName === "Branch-Authenticated-BranchOrder" ? BranchData?.docket.docketNumber : BranchData?.docket.trackingNumber}
                                    </p>
                                </div>
                                <div className="flex gap-4 ai-center">
                                    <span><CalendarIcon className={`${iconsClassName}`} />{" "}</span>
                                    <p>
                                        {formatDate(BranchData?.docket.createdAt)}
                                    </p>
                                </div>
                                <div className="flex gap-4 ai-center">
                                    <span>
                                        {BranchData?.docket.deliveredAt ?
                                            <CalendarCheckIcon className={`${iconsClassName}`} />
                                            :
                                            <TimelineIcon className={`${iconsClassName}`} />
                                        }
                                    </span>
                                    <p>
                                        {BranchData?.docket.deliveredAt ? formatDate(BranchData?.docket.deliveredAt) : "In Transit"}
                                    </p>
                                </div>
                                <div className="flex gap-4 ai-center">
                                    <span><CashIcon className={`${iconsClassName}`} />{" "}</span>
                                    {BranchData?.docket.canceledInfo ?
                                        <p>
                                            Cancelled
                                        </p>
                                        :
                                        <p className="paymentOptionContainerForBranchDocket">
                                            {!BranchData?.docket.settled ? (BranchData?.docket.paymentMode === "topay" ?
                                                <div className="ownBtn23">
                                                    <button className={selectPaymentOptionBtn2 === BranchData?.docket.paymentMode ? "activeButton" : ""} onClick={() => { setSelectPaymentOptionBtn2(BranchData?.docket.paymentMode) }} > {BranchData?.docket.paymentMode} </button>
                                                    <button className={selectPaymentOptionBtn2 === "online" ? "" : "payOnlineAttractive"} onClick={() => {
                                                        if (SourceName === "Customer-NotAuthenticated-BranchOrder" || SourceName === "Customer-NotAuthenticated-CustomerOrder") {
                                                            setOutsidePaymentDialogOpen(true)
                                                        }
                                                        else {
                                                            handlePayment()
                                                        }
                                                    }}>Online</button>
                                                </div>
                                                :
                                                <>{" "} {BranchData?.docket.paymentMode}</>) : <>{" "} {BranchData?.docket.paymentMode}</>
                                            }
                                        </p>
                                    }
                                    {/* <button onClick={()=>{
      makePayment()
                    }}>make payment</button> */}
                                </div>
                                {/* : */}
                                {/* <div style={{border : "0.2px solid transparent"}}>
                    <span style={{fontSize:"x-large",padding:'1%',border : "0.2px solid transparent"}}><CashIcon style={{backgroundColor:"white",color:"var(--clr-primary-400)"}} />{" "}</span>
                    <div style={{border : "0.2px solid transparent" , display : "flex" , justifyContent : "left" , width : "100%"}}>
                      <p style={{border : "0.2px solid transparent" , width : "30%"}}>
                        {" "} {BranchData?.docket.paymentMode}
                      </p>
                      <p style={{border : "0.2px solid transparent", width : "50%" , textAlign : "left"}} className="payOnlineAttractive">
                        {" "} Pay Online
                      </p>
                    </div>
                  </div> */}
                                <div className="flex ai-center gap-4">
                                    <span><PackageIcon className={`${iconsClassName}`} />{" "}</span>
                                    <p>
                                        {BranchData?.packages?.length}
                                    </p>
                                </div>
                                <div className="flex ai-center gap-4">
                                    <span><LocationIcon className={`${iconsClassName}`} />{" "}</span>
                                    <p>
                                        {BranchData?.docket.originBranch.branchName}{" "}
                                    </p>
                                </div>
                                <div className="flex ai-center gap-4">
                                    <span><SharpLocationIcon className={`${iconsClassName}`} />{" "}</span>
                                    <p className="flex ai-center gap-4">
                                        {BranchData?.docket?.actDestBranch ? BranchData?.docket?.actDestBranch?.branchName : BranchData?.docket.destBranch.branchName}{" "}
                                        {!BranchData?.doorDeliveryDocket.doorDelDocketNumber && BranchData?.docket?.deliveredAt === undefined && <></>
                                        }
                                        <InfoIcon style={{ color: "var(--clr-primary-400)" }} onClick={() => { setBranchAdd(true) }} />
                                    </p>
                                </div>
                                <div className="flex ai-center gap-4">
                                    <span><RupeeIcon className={`${iconsClassName}`} />{" "}</span>
                                    <p className="flex gap-4">
                                        {" "} {showOnlyTotalAmount}
                                        <span className='destBranchCss' style={{ marginLeft: "2.5%", marginTop: "2.5%" }}>
                                            <InfoIcon style={{ color: "var(--clr-primary-400)" }} onClick={() => { setPaymentInfo(true) }} />
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <Dialog
                            open={branchAdd}
                            maxWidth={"xl"}
                            onClose={() => { setBranchAdd(false) }}
                        >
                            <div className='tac bold'>
                                <DialogTitle
                                    style={{ textAlign: "center" }}
                                    id="draggable-dialog-title"
                                >
                                    <div className='commonTitleOfPopup'>
                                        Branch Address
                                    </div>
                                </DialogTitle>
                            </div>
                            <DialogContent>
                                <div className='popupForbranchAddress'>
                                    {!BranchData?.doorDeliveryDocket.doorDelDocketNumber &&
                                        <>
                                            {/* {BranchData?.docket?.deliveredAt === undefined ? */}
                                            <div className='flex-col gap-8'>
                                                <div className="fs-link flex ai-center gap-4"><BranchIcon /><span className="fw-500 line-height-100">{BranchData?.docket?.actDestBranch?.branchName}</span></div>
                                                <div className="fs-link flex gap-4"><LocationIcon />
                                                    <div className="flex-col">
                                                        <span className="fw-500 line-height-100">{BranchData?.docket?.actDestBranch?.address?.l1}</span>
                                                        <span className="fw-500 line-height-100">{BranchData?.docket?.actDestBranch?.address?.l2}</span>
                                                    </div>
                                                </div>
                                                <div className="fs-link flex ai-center gap-4">
                                                    <PincodeIcon />
                                                    <span className="fw-500 line-height-100">{BranchData?.docket?.actDestBranch?.address?.pincode}</span>
                                                </div>
                                                <div className="fs-link flex ai-center gap-4">
                                                    <BranchCallIcon />
                                                    <span className="fw-500 line-height-100">{BranchData?.docket?.actDestBranch?.pContact}</span>
                                                </div>
                                                <div className="fs-link flex ai-center gap-4">
                                                    <CityIcon />
                                                    <div className="">
                                                        <span className="fw-500 line-height-100">{BranchData?.docket?.actDestBranch?.address?.city?.name},</span>
                                                        <span className="fw-500 line-height-100">{BranchData?.docket?.actDestBranch?.address?.city?.state?.name}</span>
                                                    </div>
                                                </div>
                                                <div className="fs-link flex ai-center gap-4">
                                                    <UserInfoIcon />
                                                    <span className="fw-500 line-height-100">
                                                        {BranchData?.docket?.actDestBranch?.manager?.name?.fName} - {BranchData?.docket?.actDestBranch?.manager?.contact}
                                                    </span>
                                                </div>
                                            </div>
                                            {/* :
                      <></> */}
                                            {/* } */}
                                        </>
                                    }
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <div className='flex jc-end gap-1 ai-center'>
                                    {BranchData?.docket?.actDestBranch?.bLocation &&
                                        <ButtonPrimary
                                            className="button-primary-secondary fs-popup-btn fw-500"
                                            onClick={() => { goToMap() }}
                                        >
                                            Navigate
                                        </ButtonPrimary>
                                    }
                                    <ButtonDefault
                                        className="button-danger-secondary fs-popup-btn fw-500"
                                        onClick={() => { setBranchAdd(false) }}
                                    >
                                        close
                                    </ButtonDefault>
                                </div>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={paymentInfo}
                            maxWidth={"xl"}
                            onClose={() => { setPaymentInfo(false) }}
                        >
                            <div className='tac bold'>
                                <DialogTitle
                                    style={{ textAlign: "center" }}
                                    id="draggable-dialog-title"
                                >
                                    <div className='commonTitleOfPopup'>
                                        Payment Details
                                    </div>
                                </DialogTitle>
                            </div>
                            <DialogContent>
                                <div className='popupForbranchAddress'>
                                    {!BranchData?.doorDeliveryDocket.doorDelDocketNumber &&
                                        <div>
                                            <table>
                                                <thead>
                                                    <tr className="fs-link" style={{ justifyContent: "space-evenly", textAlign: "center" }}>
                                                        <th style={{ border: "1px solid var(--clr-white-400)", width: "70%", padding: "8px", backgroundColor: "var(--clr-primary-400)", color: "var(--clr-white-900)" }}>PARTICULARS</th>
                                                        <th style={{ border: "1px solid var(--clr-white-400)", padding: "8px", backgroundColor: "var(--clr-primary-400)", color: "var(--clr-white-900)" }}>AMOUNT</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="fs-link" >
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px" }}>Net Cost</td>
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px", textAlign: "center" }}>{TotalAmount}</td>
                                                    </tr>
                                                    <tr className="fs-link" >
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px" }}>Branch Delivery</td>
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px", textAlign: "center" }}>{BranchData?.docket?.doorDelivery ? 0 : branchDeliveryCharge}</td>
                                                    </tr>
                                                    <tr className="fs-link" >
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px" }}>Demurrage</td>
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px", textAlign: "center" }}>{demurrage ? demurrage : 0}</td>
                                                    </tr>
                                                    <tr className="fs-link" >
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px" }}>Discount</td>
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px", textAlign: "center" }}>{discount ? discount : 0}</td>
                                                    </tr>
                                                    <tr className="fs-link" >
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px" }}>GST Penalty</td>
                                                        <td style={{ border: "1px solid var(--clr-white-400)", padding: "8px", textAlign: "center" }}>{gstCustodyPenalty ? gstCustodyPenalty : 0}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {/* <div>Net Cost : {TotalAmount} </div>
                      <div>Branch Delivery : {BranchData?.docket?.doorDelivery  ? 0 : branchDeliveryCharge}</div>
                      <div>Demurrage : {demurrage ? demurrage : 0}</div>
                      <div>Discount : {discount ? discount : 0}</div>
                      <div>GST Penalty : {gstCustodyPenalty ? gstCustodyPenalty : 0}</div> */}
                                        </div>
                                    }
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <div className='flex jc-end gap-1 ai-center'>
                                    <ButtonDefault
                                        className="button-danger-secondary fs-popup-btn"
                                        onClick={() => { setPaymentInfo(false) }}
                                    >
                                        close
                                    </ButtonDefault>
                                </div>
                            </DialogActions>
                        </Dialog>
                    </div>
                }

                {SourceName === "Branch-Authenticated-BranchOrder" &&
                    <>
                        {moreBtns &&
                            <div className='branchExecutiveBtnsContainer p-1 border-white shadow-small br-4 mt-1'>
                                <div className='branchExecutiveBtns flex flex-wrap ai-center gap-1'>
                                    <div>
                                        {BranchData?.docket && (
                                            <ButtonPrimary onClick={() => { handleViewDocketBtn() }}> Fetch Docket </ButtonPrimary>
                                        )}
                                    </div>
                                    {!missingParcel &&
                                        <div>
                                            {BranchData?.docket &&
                                                (BranchData?.docket.entity === BranchData?.docket.destBranch._id ||
                                                    BranchData?.docket.entity === BranchData?.docket.originBranch._id ||
                                                    BranchData?.docket.entity === BranchData?.docket.hub) &&
                                                !BranchData?.docket.settled &&
                                                BranchData?.docket?.packages[0].status !== "D" &&
                                                BranchData?.docket?.packages[0].status !== "CR" &&
                                                BranchData?.docket?.packages[0].status !== "CC" &&
                                                BranchData?.docket?.packages[0].status !== "SOLD" && (
                                                    <ButtonPrimary
                                                        // style={{ marginLeft: 25 }}
                                                        onClick={() => {
                                                            setBackToOriginOpen(true);
                                                            handleImagePackages();
                                                        }}
                                                    >
                                                        Send Back to Origin
                                                    </ButtonPrimary>
                                                )
                                            }
                                            <Dialog
                                                open={backToOriginOpen}
                                                onClose={() => handleBTOClose()}
                                                maxWidth="sm"
                                                fullWidth={true}
                                            >
                                                <DialogTitle>
                                                    Send back to Origin
                                                </DialogTitle>
                                                <DialogContent>
                                                    <input
                                                        placeholder="Back to origin charges"
                                                        className="py-8 px-1 border-white br-4 w-100 my-1"
                                                        value={btoAmount}
                                                        onChange={(e: any) => setbtoAmount(e.target.value)}
                                                        title={`Back to origin charges must be greater than ${BranchData?.docket.gross - 1
                                                            }`}
                                                    />
                                                    <ImageInputPackgeWise
                                                        packages={imagePackages}
                                                        showLoader={newProps.data.showLoader}
                                                        hideLoader={newProps.data.hideLoader}
                                                        reqType="BTO"
                                                        addImagedPackage={addImagedPackage}
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <ButtonPrimary
                                                        onClick={() => handleSubmitBTO()}
                                                        className={`button-primary-secondary fs-popup-btn`}
                                                        disabled={btoAmount > BranchData?.docket.gross - 1 ? false : true}
                                                    >
                                                        Submit
                                                    </ButtonPrimary>
                                                    <ButtonDefault className={`button-danger-secondary fs-popup-btn`} onClick={() => handleBTOClose()}>Cancel</ButtonDefault>
                                                </DialogActions>
                                            </Dialog>
                                        </div>
                                    }
                                    {!missingParcel &&
                                        <div>
                                            {BranchData?.docket &&
                                                (BranchData?.docket.entity === BranchData?.docket.destBranch._id ||
                                                    BranchData?.docket.entity === BranchData?.docket.originBranch._id ||
                                                    BranchData?.docket.entity === BranchData?.docket.hub) &&
                                                !BranchData?.docket.settled &&
                                                BranchData?.packages[0].status !== "D" &&
                                                BranchData?.packages[0].status !== "CR" &&
                                                BranchData?.packages[0].status !== "CC" &&
                                                BranchData?.packages[0].status !== "SOLD" && (
                                                    <ButtonPrimary
                                                        // style={{ marginLeft: 25, marginRight: 25 }}
                                                        onClick={() => {
                                                            setNewDestOpen(true);
                                                            handleImagePackages();
                                                        }}
                                                    >
                                                        Send to New destination
                                                    </ButtonPrimary>
                                                )
                                            }
                                            <Dialog
                                                open={newDestOpen}
                                                onClose={() => handleNDClose()}
                                                maxWidth="sm"
                                                fullWidth={true}
                                            >
                                                <DialogTitle>
                                                    Send to New destination
                                                </DialogTitle>
                                                <DialogContent>
                                                    <Select12
                                                        options={newDestOptions}
                                                        value={selectedND}
                                                        onChange={(e: any) => setSelectedND(e)}
                                                    />
                                                    <br />
                                                    <input
                                                        placeholder="New Destination Charges"
                                                        className="w-100 py-8 px-1 br-4 border-white mb-1"
                                                        value={NDAmount}
                                                        onChange={(e: any) => setNDAmount(e.target.value)}
                                                        // placeholder={`${BranchData?.docket.gross}`}
                                                        style={{ zIndex: 0 }}
                                                    />
                                                    <br />
                                                    <ImageInputPackgeWise
                                                        packages={imagePackages}
                                                        showLoader={newProps.data.showLoader}
                                                        hideLoader={newProps.data.hideLoader}
                                                        reqType="ND"
                                                        addImagedPackage={addImagedPackage}
                                                    />
                                                </DialogContent>
                                                <DialogActions>
                                                    <ButtonPrimary className={`button-primary-secondary fs-popup-btn`} onClick={() => handleSubmitND()}>Submit</ButtonPrimary>
                                                    <ButtonDefault className={`button-danger-secondary fs-popup-btn`} onClick={() => handleNDClose()}>Cancel</ButtonDefault>
                                                </DialogActions>
                                            </Dialog>
                                        </div>
                                    }
                                    <div>
                                        <Complaint
                                            chat={feedbackResponse}
                                            getGuestUpdatedData={getGuestUpdatedData}
                                            docketID={BranchData?.docket?._id}
                                            docketNumber={BranchData?.docket?.docketNumber}
                                            orderArrayOption={orderArrayOption}
                                            branchOptions={branchOptions}
                                            userOptions={userOptions}
                                        />
                                    </div>
                                    {notReceivedDisabled && BranchData?.docket?.originBranch?._id === newProps?.data?.userDetails?.opBranch?._id &&
                                        <div>
                                            <ButtonPrimary
                                                onClick={() => setOpenActionForRecieved(true)}
                                                style={{ margin: "0px 20px" }}
                                            >
                                                Recieved
                                            </ButtonPrimary>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        <Dialog
                            open={Boolean(openActionForRecieved)}
                            onClose={() => handleDialogClose()}
                            maxWidth="sm"
                            fullWidth={true}
                        >
                            <DialogContent>
                                <div>
                                    <Select
                                        value={packageOrderForRecieved}
                                        options={orderArrayOption}
                                        onChange={(e: any) => setPackageOrderForRecieved(e)}
                                        placeholder="Select Packages"
                                        isMulti={true}
                                    />
                                </div>
                                Image :
                                <ImageInput
                                    name="Upload Image"
                                    Icon={(props: any) => (
                                        <i {...props} className={"fas fa-id-card file_icon"} />
                                    )}
                                    onChange={setImage}
                                    value={Image}
                                    extras={<div></div>}
                                />
                                <br />
                                <Input12
                                    label="Remarks"
                                    value={reason}
                                    onChange={(e: any) => setReason(e.target.value)}
                                />
                            </DialogContent>
                            <DialogActions>
                                <ButtonPrimary
                                    onClick={() => handleReceived()}
                                >
                                    Submit
                                </ButtonPrimary>
                                <ButtonDefault onClick={() => handleDialogClose()}>
                                    Cancel
                                </ButtonDefault>
                            </DialogActions>
                        </Dialog>
                    </>

                }

                {SourceName === "Branch-Authenticated-BranchOrder" &&
                    <div className='my-1 paper'>
                        {mobileView ?
                            <div className='grid-center'>
                                <div className='flex ai-center gap-4'>
                                    <span> <DebugDisconnectIcon style={{ color: "var(--clr-primary-400)", width: "28px", height: "28px", marginRight: "1.5%" }} />{" "} </span>
                                    <span className="fs-link"> {BranchData?.docket?.crossingBranch ? BranchData?.docket?.crossingBranch.branchName : "No Crossing Branch"}{" "} </span>
                                </div>
                                <div className='flex ai-center gap-4'>
                                    <span>  <OrderPlayLineIcon style={{ color: "var(--clr-primary-400)", width: "28px", height: "28px", marginRight: "1.5%" }} /> </span>
                                    <span style={{ display: "flex" }}>
                                        {fleetOrderNumbers && fleetOrderNumbers.length === 0 ? "No Fleet Order No Generated"
                                            : fleetOrderNumbers.length > 0 ?
                                                <div style={{ display: "flex", width: "100%", justifyContent: "center" }} onClick={() => { setOpenFleetOrder(true) }}>{fleetOrderNumbers[0]}
                                                    <p className="infoIcon">... More</p>
                                                </div>
                                                : ""}
                                    </span>
                                </div>
                                <div className='flex ai-center gap-4'>
                                    <span> <TagsIcon style={{ color: "var(--clr-primary-400)", fontSize: "x-large", marginRight: "1.5%" }} /> </span>
                                    <span>
                                        {BranchData?.docket &&
                                            (BranchData?.docket?.destChng ||
                                                BranchData?.docket?.cancelRequestInfo ||
                                                BranchData?.docket?.demurrageReqInfo ||
                                                BranchData?.docket?.docketNumber.discountInfo) && (
                                                <div className='tagsDiv'>
                                                    {BranchData?.docket?.destChng && BranchData?.docket?.destChng.reqType === "ND" && (
                                                        <StatusHeader
                                                            title="New Destination"
                                                            info={BranchData?.docket?.destChng}
                                                        />
                                                    )}
                                                    {BranchData?.docket?.destChng && BranchData?.docket?.destChng.reqType === "BTO" && (
                                                        <StatusHeader
                                                            title="Back To Origin"
                                                            info={BranchData?.docket?.destChng}
                                                        />
                                                    )}
                                                    {BranchData?.docket?.demurrageReqInfo && (
                                                        <StatusHeader
                                                            title="Demurrage"
                                                            info={BranchData?.docket?.demurrageReqInfo}
                                                        />
                                                    )}
                                                    {BranchData?.docket?.discountInfo && (
                                                        <StatusHeader
                                                            title="Discount"
                                                            info={BranchData?.docket?.discountInfo}
                                                        />
                                                    )}
                                                    {BranchData?.docket?.cancelRequestInfo && (
                                                        <StatusHeader
                                                            title="Cancel Booking"
                                                            info={BranchData?.docket?.cancelRequestInfo}
                                                        />
                                                    )}
                                                </div>
                                            )
                                        }
                                    </span>
                                    {/* <InfoIcon className="infoIcon"></InfoIcon> */}
                                </div>
                            </div>
                            :
                            <div className='combinedContainer three-column m-one-column gap-1 ai-center'>
                                <div className='crossingBranchContainer flex gap-4 ai-center fs-link'><DebugDisconnectIcon style={{ color: "var(--clr-primary-400)", width: "28px", height: "28px", marginRight: "1.5%" }} />{" "}{BranchData?.docket?.crossingBranch ? BranchData?.docket?.crossingBranch.branchName : "No Crossing Branch"}{" "}</div>
                                <div className='fleetOrderContainer flex gap-4 ai-center fs-link'><OrderPlayLineIcon style={{ color: "var(--clr-primary-400)", fontSize: "x-large", marginRight: "1.5%" }} /> {fleetOrderNumbers && fleetOrderNumbers.length === 0 ? "No Fleet Order No Generated"
                                    : fleetOrderNumbers.length > 0 ? <div>{fleetOrderNumbers[0]} </div>
                                        : fleetOrderNumbers.length > 1 ? <div style={{ display: "flex", width: "80%", justifyContent: "center" }} onClick={() => { setOpenFleetOrder(true) }}>{fleetOrderNumbers[0]} <span className="infoIcon">... More</span> </div>
                                            : ""}
                                </div>
                                <div className='tagsContainer flex gap-4 ai-center fs-link'><TagsIcon style={{ color: "var(--clr-primary-400)", fontSize: "x-large", marginRight: "1.5%" }} />
                                    {BranchData?.docket &&
                                        (BranchData?.docket?.destChng ||
                                            BranchData?.docket?.cancelRequestInfo ||
                                            BranchData?.docket?.demurrageReqInfo ||
                                            BranchData?.docket?.docketNumber.discountInfo) && (
                                            <div className='tagsDiv'>
                                                {BranchData?.docket?.destChng && BranchData?.docket?.destChng.reqType === "ND" && (
                                                    <StatusHeader
                                                        title="New Destination"
                                                        info={BranchData?.docket?.destChng}
                                                    />
                                                )}
                                                {BranchData?.docket?.destChng && BranchData?.docket?.destChng.reqType === "BTO" && (
                                                    <StatusHeader
                                                        title="Back To Origin"
                                                        info={BranchData?.docket?.destChng}
                                                    />
                                                )}
                                                {BranchData?.docket?.demurrageReqInfo && (
                                                    <StatusHeader
                                                        title="Demurrage"
                                                        info={BranchData?.docket?.demurrageReqInfo}
                                                    />
                                                )}
                                                {BranchData?.docket?.discountInfo && (
                                                    <StatusHeader
                                                        title="Discount"
                                                        info={BranchData?.docket?.discountInfo}
                                                    />
                                                )}
                                                {BranchData?.docket?.cancelRequestInfo && (
                                                    <StatusHeader
                                                        title="Cancel Booking"
                                                        info={BranchData?.docket?.cancelRequestInfo}
                                                    />
                                                )}
                                            </div>
                                        )
                                    }
                                    {/* <InfoIcon className="infoIcon"></InfoIcon> */}
                                </div>
                            </div>
                        }
                        <Dialog
                            open={Boolean(openFleetOrder)}
                            maxWidth={"xl"}
                            onClose={() => { setOpenFleetOrder(false) }}
                        >
                            <div className='tac bold'>
                                <DialogTitle
                                    style={{ textAlign: "center" }}
                                    id="draggable-dialog-title"
                                >
                                    Fleet Order List
                                </DialogTitle>
                            </div>
                            <DialogContent>
                                <div className="popupForComplaint2">
                                    {fleetOrderNumbers && fleetOrderNumbers.map((ele: any) => {
                                        return (
                                            <h4>{ele}</h4>
                                        )
                                    })}
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <div className='onLinePkgbtnMobile'>
                                    <ButtonDefault
                                        className="button-danger-secondary fs-popup-btn"
                                        onClick={() => { setOpenFleetOrder(false) }}
                                    >
                                        close
                                    </ButtonDefault>
                                </div>
                            </DialogActions>
                        </Dialog>
                    </div>
                }

                {BranchData?.packages && BranchData?.packages?.length > 0 && ((BranchData?.docket?.deliveredAt === undefined && !BranchData?.docket?.isCancelRequired && !BranchData?.docket?.isBackToHub) && BranchData?.packages[0]?.expectedReachTime) ?
                    <div className='otpForDeliveryView my-1 paper text-center'>
                        {getFinalDeliveryTime(SourceName)}
                    </div>
                    : null}

                {SourceName === "Customer-Authenticated-BranchOrder" && BranchData?.docket?.deliveredAt === undefined && otpForDelivery &&
                    <div className='otpForDeliveryView'>
                        Otp For Delivery :
                        {otpForDelivery && otpForDelivery?.otp &&
                            new Date(otpForDelivery?.expireAt).getTime() > new Date().getTime() ? otpForDelivery?.otp : `Request for another otp by the provider`}

                    </div>
                }

                {!BranchData?.docket?.cancelRequestInfo &&
                    <>
                        {SourceName === "Branch-Authenticated-BranchOrder" || SourceName === "Customer-Authenticated-BranchOrder" ?
                            <div className='p-1 border-white shadow-small mt-1 br-4'>
                                <Button variant="secondary" action="secondary" style={mobileView ? { width: "100%" } : {}} onClick={() => { setInState(BranchData), setOpenDoorDelDialg(true) }}>
                                    {BranchData?.doorDeliveryDocket.doorDelDocketNumber ? `Door Delivery` : `Book Door Delivery`}
                                </Button>
                                <div className='flex-center flex-wrap gap-1'>
                                    {/* <p className={`${BranchData?.doorDeliveryDocket.doorDelDocketNumber ? 'doorDeliveryTextWithout' : `doorDeliveryText`} bg-secondary-50 text-secondary-900 border-white cursor-pointer p-1 br-4 fw-500 fs-link m-mx-auto`}>   </p> */}
                                    {BranchData?.doorDeliveryDocket.doorDelDocketNumber && <div className="flex ai-center jc-between flex-grow-1 flex-wrap">
                                        <p className='flex ai-center gap-4 fs-link'>
                                            <div className='flex ai-center gap-4 fs-link'>
                                                <CalendarIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                            </div>
                                            <div className='commonTextForDoorD'>
                                                {BranchData?.doorDeliveryDocket.doorDelDocketNumber ? BranchData?.doorDeliveryDocket.doorDelDocketNumber : "-"}
                                            </div>
                                        </p>
                                        <p className='flex ai-center gap-4 fs-link'>
                                            <div className='commonIconForDoorD'>
                                                <HoursIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                            </div>
                                            <div className='commonTextForDoorD'>
                                                {BranchData?.doorDeliveryDocket.createdAt ? (`${formatDate(BranchData?.doorDeliveryDocket.createdAt)} , ${timeConvert(BranchData?.doorDeliveryDocket.createdAt)}`) : "-"}
                                            </div>
                                        </p>
                                        <p className='flex ai-center gap-4 fs-link'>
                                            <div className='commonIconForDoorD' style={{ marginRight: "3.5%" }}>
                                                <RupeeIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                            </div>
                                            <div className='commonTextForDoorD'>
                                                {BranchData?.doorDeliveryDocket.doorDelCharges ? BranchData?.doorDeliveryDocket.doorDelCharges :
                                                    BranchData?.doorDeliveryDocket.extraDoorDelCharges ? BranchData?.doorDeliveryDocket.extraDoorDelCharges : "-"}
                                            </div>
                                        </p>
                                        <p className="flex ai-center gap-4 fs-link">
                                            <div className='commonIconForDoorD'>
                                                <TimelineIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                            </div>
                                            <div className='commonTextForDoorD'>
                                                {BranchData?.doorDeliveryDocket.status && BranchData?.doorDeliveryDocket.status === "B" ? "Booked" : BranchData?.doorDeliveryDocket.status === "O" ? "Out For Delivery" :
                                                    BranchData?.doorDeliveryDocket.status === "D" ? "Delivered" : BranchData?.doorDeliveryDocket.status === "P" ? "Payment settled" : "-"}
                                            </div>
                                        </p>
                                    </div>}
                                    {BranchData?.docket?.deliveredAt !== undefined &&
                                        <ToolTip title={`Print Reciept`}>
                                            <p onClick={() => { setPrintDoorDelVoucher(true) }}>
                                                <span style={{ fontSize: "x-large", padding: '1%' }}>
                                                    <i className="fas fa-print refresh_icon" style={{ backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                </span>
                                            </p>
                                        </ToolTip>
                                    }
                                    <DoorDeliveryDialog
                                        delCity={newProps.data?.bookingReducer?.doorDelivery?.delCity}
                                        fromPlaceId={doorDeliveryDocketData.fromPlaceId}
                                        serviceType={doorDeliveryDocketData?.serviceType}
                                        assetType={doorDeliveryDocketData?.assetType}
                                        toPlaceId={newProps.data?.doorDelivery?.place_id}
                                        packages={doorDeliveryDocketData.packages}
                                        open={openDoorDelDialog}
                                        setOpen={setOpenDoorDelDialg}
                                        update={!doorDeliveryDocketData?.doorDelDocketNumber}
                                        docket={BranchData?.docket.docketNumber}
                                        doorDeliveryDocketId={BranchData?.doorDeliveryDocket._id}
                                        doorDeliveryPaymentMode={doorDeliveryDocketData?.doorDeliveryPaymentMode}
                                        deliveryCharge={doorDeliveryDocketData.deliveryCharge}
                                        l1={newProps.data?.bookingReducer?.doorDelivery?.l1 || ""}
                                        l2={newProps.data?.bookingReducer?.doorDelivery?.l2 || ""}
                                        officeName={newProps.data?.bookingReducer?.doorDelivery?.officeName || ""}
                                        floor={newProps.data?.bookingReducer?.doorDelivery?.floor || ""}
                                        tower={newProps.data?.bookingReducer?.doorDelivery?.tower || ""}
                                        nearby_landmark={newProps.data?.bookingReducer?.doorDelivery?.nearby_landmark || ""}
                                        city={newProps.data?.bookingReducer?.doorDelivery?.city}
                                        state={newProps.data?.bookingReducer?.doorDelivery?.state}
                                        pincode={newProps.data?.bookingReducer?.doorDelivery?.pincode || ""}
                                        area={doorDeliveryDocketData.area}
                                        onChange={(what: any, val: any) => {
                                            setDoorDeliveryDocketData((doorDeliveryDocketData: any) => ({ ...doorDeliveryDocketData, [what]: val }))
                                            newProps?.data?.setDoorDelivery(what, val)
                                        }
                                        }
                                        onReset={() => newProps?.data?.resetDoorDelivery()}
                                        locationType={doorDeliveryDocketData.locationType}
                                        placeValue={newProps.data?.bookingReducer?.doorDelivery?.origin?.formatted_address}
                                        placeId={newProps.data?.bookingReducer?.doorDelivery?.origin?.place_id}
                                        name={newProps.data?.bookingReducer?.doorDelivery?.origin?.name}
                                        coordinate={newProps?.data?.bookingReducer?.doorDelivery?.origin?.geometry?.location}
                                        formatted_address={newProps.data?.bookingReducer?.doorDelivery?.origin?.formatted_address}
                                        paymentOptions={[{ label: "ToPay", value: "topay" }, { label: "Credit", value: "credit" }]}
                                        isCustomerTracking={SourceName === "Branch-Authenticated-BranchOrder" ? false : true}
                                        doorDelDocketNumber={doorDeliveryDocketData?.doorDelDocketNumber}
                                        whereFrom={"Customer"}
                                        receiver={BranchData?.docket?.receiver}
                                        searchLocation={doorDeliveryDocketData.searchLocation}
                                    >

                                        {/* {parseInt(doorDeliveryDocketData.doorDelCharges) > 0 && (
                    <ButtonDefault style={{ marginLeft: 25 }} onClick={() => setOpenDoorDelDialg(true)}>
                      Door Delivery Charge {doorDeliveryDocketData?.paymentMode?.value}{" "}
                      {doorDeliveryDocketData.doorDelCharges > 0 && doorDeliveryDocketData.doorDelCharges}
                    </ButtonDefault>
                  )}
                  {!(parseInt(doorDeliveryDocketData.doorDelCharges) > 0) && (
                    <ButtonPrimary style={{ marginLeft: 25 }} onClick={() => setOpenDoorDelDialg(true)}>
                        Door Delivery Charge {doorDeliveryDocketData?.paymentMode?.value}{" "}
                      {doorDeliveryDocketData.doorDelCharges > 0 && doorDeliveryDocketData.doorDelCharges}
                    </ButtonPrimary>
                  )} */}
                                    </DoorDeliveryDialog>
                                </div>
                            </div>
                            :
                            <div className='doorDeliveryPopupContainer p-1 br-4 shadow-small border-white flex-center'>
                                <button className="doorDeliveryText" onClick={() => { navigate("/login") }}> Book Door Delivery Service Now </button>
                            </div>
                        }
                    </>
                    // :
                    // <></>
                }
                {BranchData?.doorDeliveryDocket && BranchData?.driver && BranchData?.driver?.userProfile?.image &&
                    SourceName !== "Customer-NotAuthenticated-BranchOrder" && BranchData?.docket?.deliveredAt === undefined ?
                    <div className="p-1 border-white shadow-small br-4 mt-1">
                        {mobileView ?
                            <div className="flex-col gap-8 fs-link fw-500">
                                <span className="">Delivery Partner Details</span>
                                {BranchData?.doorDeliveryDocket &&
                                    <div className="">
                                        <p className="driverImage">
                                            <OnlyImage objectID={BranchData?.driver?.userProfile?.image} className={'driverImagecentralise'} />
                                        </p>
                                        <p className="driverNameCss">
                                            <span> <UserIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} /> </span>
                                            <span>
                                                {`${BranchData?.driver?.name?.fName} ${BranchData?.driver?.name?.lName}`}
                                            </span>
                                        </p>
                                        <p className="driverContactCss">
                                            <span> <CallIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} /> </span>
                                            <span>
                                                {BranchData?.doorDeliveryDocket && BranchData?.doorDeliveryDocket?.driverContact}
                                            </span>
                                        </p>
                                        <p className="driverFleetsRegNo">
                                            <span> <MovingTruckIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} /> </span>
                                            <span> {BranchData?.doorDeliveryDocket && BranchData?.doorDeliveryDocket?.regNumber} </span>
                                        </p>
                                    </div>
                                }
                            </div>
                            :
                            <div>
                                {BranchData?.doorDeliveryDocket && BranchData?.driver?.userProfile?.image &&
                                    <div className="flex jc-between ai-center gap-1 flex-wrap">
                                        <p className="driverImage">
                                            <OnlyImage objectID={BranchData?.driver?.userProfile?.image} className={'driverImagecentralise'} />
                                        </p>
                                        <p className="driverNameCss">
                                            <span> <UserIcon style={{ width: "28px", height: "28px", backgroundColor: "white", color: "var(--clr-primary-400)" }} /> </span>
                                            <span> {`${BranchData?.driver?.name?.fName} ${BranchData?.driver?.name?.lName}`}  </span>
                                        </p>
                                        <p className="driverContactCss">
                                            <span> <CallIcon style={{ width: "28px", height: "28px", backgroundColor: "white", color: "var(--clr-primary-400)" }} /> </span>
                                            <span> {BranchData?.doorDeliveryDocket && BranchData?.doorDeliveryDocket?.driverContact} </span>
                                        </p>
                                        <p className="driverFleetsRegNo">
                                            <span> <MovingTruckIcon style={{ width: "28px", height: "28px", backgroundColor: "white", color: "var(--clr-primary-400)" }} /> </span>
                                            <span> {BranchData?.doorDeliveryDocket && BranchData?.doorDeliveryDocket?.regNumber} </span>
                                        </p>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    // <></>
                    :
                    <></>
                }

                {/* Branch Address Details Commneted
       {!BranchData?.doorDeliveryDocket.doorDelDocketNumber && 
        <>
          {BranchData?.docket?.deliveredAt === undefined ?
            <div className='branchAddressContainer'>
              <div>{BranchData?.docket?.actDestBranch?.branchName}</div>
              {BranchData?.docket?.actDestBranch?.bLocation &&
                  <a
                  href={
                    iOS === true
                      ? `comgooglemapsurl://maps.google.com/maps?z=12&t=m&q=loc:${BranchData?.docket?.actDestBranch?.bLocation?.coordinates[1]}+${BranchData?.docket?.actDestBranch?.bLocation?.coordinates[0]}`
                      : `http://maps.google.com/maps?z=12&t=m&q=loc:${BranchData?.docket?.actDestBranch?.bLocation?.coordinates[1]}+${BranchData?.docket?.actDestBranch?.bLocation?.coordinates[0]}`
                  }
                  target="_blank"
                > 
                  <div className='addHyperLink'>{BranchData?.docket?.actDestBranch?.company?.companyName}</div>
                </a>
              }
              <div>{BranchData?.docket?.actDestBranch?.address?.l1}</div>
              <div>{BranchData?.docket?.actDestBranch?.address?.l2}</div>
              <div>{BranchData?.docket?.actDestBranch?.address?.pincode}</div>
              <div>{BranchData?.docket?.actDestBranch?.pContact}</div>
              <div>{BranchData?.docket?.actDestBranch?.address?.city?.name}</div>
              <div>{BranchData?.docket?.actDestBranch?.address?.city?.state?.name}</div>
              <div>{BranchData?.docket?.actDestBranch?.manager?.name?.fName} , {BranchData?.docket?.actDestBranch?.manager?.contact}</div>
            </div>
              :
            <></>
          }
        </>
      } */}

                <>
                    {BranchData?.docket?.deliveredAt === undefined ?
                        BranchData?.doorDeliveryDocket?.fleetOrder &&
                        BranchData?.doorDeliveryDocket?.Route?.length > 0 &&
                        <div className="mapAndLogsContainer">
                            <div className='maptagForBranchOrderOnly'>Door Delivery Fleet Order</div>
                            <div className='mapContainerForTracking'>
                                <div style={{ flex: "1", }} className='doorDeliveryLocationContainer'>
                                    <div className='doordeliveryView'>
                                        {newProps?.data?.customerTracking?.route?.map((e: any, i: any) => {
                                            return (
                                                <Accordion style={{ ...(activeDest === e.placeIndex ? { border: '1px solid var(--clr-primary-400)' } : activeDest < e.placeIndex ? {} : { border: '2px solid var(--clr-primary-400)' }) }} expanded={false} >
                                                    <AccordionSummary>
                                                        <div style={{ width: "100%" }}>
                                                            <div style={{ display: "flex", alignItems: 'center', gap: "5px", fontWeight: "bold" }}>
                                                                <div style={{ width: "7.5%", height: "20px", backgroundColor: "var(--clr-primary-400)", color: "var(--clr-white-900)", textAlign: "center", borderRadius: "50%", fontWeight: "bold" }}>{i + 1}</div>
                                                                <div style={{ width: "85%", height: "fit-content", textAlign: "left", fontWeight: "bold" }}>{e?.placeId[0]?.name}</div>
                                                                <div style={{ width: "7.5%", height: "20px", textAlign: "center", fontWeight: "bold" }} >
                                                                    <InfoIcon style={{ fontSize: "large", color: "var(--clr-primary-500)", cursor: "pointer" }} />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: "5px" }}>
                                                                <div><span><i className="fa fa-truck" style={{ marginRight: 5, color: 'var(--clr-primary-400)' }} ></i></span>{"  "}{meterTOkmChange(e?.distance)} km away from {i == 0 ? "Driver" : "Previous Location"}
                                                                    <div><span><i className="fa fa-clock" style={{ marginRight: 5, color: 'var(--clr-primary-400)' }} ></i></span> {" "}
                                                                        {secondTominConvert(e?.duration, i)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div><div style={{ margin: "5px 0 0 25px" }}><div>Km Total Trip</div></div></div>
                                                            {orderDetails?.packages && orderDetails?.packages.length == i + 1 ? <div style={{ margin: "5px 0 0 25px" }}><div>{orderDetails?.totalDistance / 1000}Km  Total Trip and {toHoursAndMinutes(orderDetails?.totalDuration)}  Time</div></div>
                                                                : <div></div>}

                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}><div><LocationIcon style={{ color: "green", fontSize: "19px" }} /></div>{e?.placeId[0]?.placeValue}</div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className='mapDiv'>
                                    {<CustomerTrackMapView isStarted={doorDeliveryDocketData.isOrderStarted} />}

                                </div>
                            </div>
                        </div>

                        :
                        <div className="p-1 shadow-small border-white br-4 mt-1">
                            <h1 className="fs-subheading text-center text-primary-400 mb-8">Your Order is Delivered Succesfully</h1>
                            <div className="flex-center gap-1 mt-1 text-primary-400">{`Your Order is Delivered to : ${BranchData?.docket?.deliveredTo}`}</div>
                        </div>
                    }
                </>
                {/* {!BranchData?.docket?.cancelRequestInfo && BranchData?.docket?.deliveredAt === undefined ?  */}
                <div className="paper my-1">
                    {BranchData?.docket &&
                        <div>
                            {SourceName === "Branch-Authenticated-BranchOrder" &&
                                <div className='shoePackagesSwitch'>
                                    Show Packages
                                    <Switch
                                        checked={showPackages}
                                        onChange={() => setShowPackages(!showPackages)}
                                    />
                                </div>
                            }
                            <div className="grid gap-1">
                                {/* <p className="br-4 p-1 bg-primary-400 text-white-900 fw-500 max-content">Last Activity</p> */}
                                {Object.keys(uniqueLogs).map((key, index) => {
                                    // if (index === 3) {
                                    // }
                                    let item = uniqueLogs[key];
                                    let arr = item.split(";")
                                    // let num = packageMap[key] ? packageMap[key].length : 0;
                                    arr.splice(arr.length - 1, 1);
                                    let totalPackagesArr: any = [];
                                    let finalAns = Array.from(recordsMap).map(([_, value]: any) => {
                                        let asnFind: any = [];
                                        {
                                            value?.length > 0 &&
                                                value?.map(
                                                    (i: any) => {
                                                        if (packageMap[key].includes(i.order)) {
                                                            asnFind?.push(i);
                                                        }
                                                    }
                                                )
                                        }
                                        totalPackagesArr?.push(asnFind)
                                        return asnFind;
                                    })
                                    return (
                                        <>
                                            <div className="">
                                                {finalAns?.map((f: any) => {
                                                    if (f?.length > 0) {
                                                        return (
                                                            <div className="">
                                                                <SingleStatus totalPkg={f?.length}
                                                                    allowedOrders={
                                                                        packageMap[key] && packageMap[key].length > 0
                                                                            ? packageMap[key]
                                                                            : []
                                                                    }
                                                                    materialType={f[0]?.materialType}
                                                                    packingType={f[0]?.packingType}
                                                                    statusArr={arr} PkgList={f} recordsMap={recordsMap} index={index} EachlogsArr={BranchData.packages[index].logs} />
                                                            </div>
                                                        )
                                                    }
                                                })}
                                                {/* <br />
                      <br />
                      <br />
                      <br />
                      <SingleStatus totalPkg={num}
                        allowedOrders={
                          packageMap[key] && packageMap[key].length > 0
                            ? packageMap[key]
                            : []
                        }
                        statusArr={arr} PkgList={NewAllowedArr} recordsMap={recordsMap} index={index} EachlogsArr={BranchData.packages[index].logs} /> */}
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    }
                </div>
                {/* :
        <></>
      } */}
                {!BranchData?.docket?.deliveredAt ? ((!BranchData?.docket?.cancelRequestInfo || BranchData?.docket?.cancelRequestInfo?.status === "REJECTED") ?
                    //  <>
                    //   {BranchData?.docket?.deliveredAt === undefined ? 
                    <div className="p-1 border-white shadow-small br-4">
                        {mobileView ? <>
                            <div className="grid gap-1">
                                <p className="br-4 flex-between gap-1 text-primary-900 p-8 fs-link" onClick={() => setIsComplaintOpen(prev => !prev)}>
                                    Complaints
                                    {isComplaintsOpen ? <UpIcon /> : <DownIcon />}
                                </p>
                                {isComplaintsOpen && <div className="grid gap-8">
                                    <Button variant="primary" action="secondary" fullWidth onClick={() => {
                                        if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                                            // setIsDialogOpen(1)
                                            setOutsideDialogOpen(true);
                                        }
                                        else {
                                            setIsDialogOpen(1), setOpenAction("M")
                                        }
                                    }}>Missing</Button>
                                    <Button variant="primary" action="secondary" fullWidth onClick={() => {
                                        if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                                            // setIsDialogOpen(2) 
                                            setOutsideDialogOpen(true);
                                        }
                                        else {
                                            setIsDialogOpen(2), setOpenAction("PF")
                                        }
                                    }}>Pilferage</Button>
                                    <Button variant="primary" action="secondary" fullWidth onClick={() => {
                                        if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                                            // setIsDialogOpen(3), 
                                            setOutsideDialogOpen(true);
                                        }
                                        else {
                                            setIsDialogOpen(3), setOpenAction("DM")
                                        }
                                    }}>Damage</Button>
                                    <Button variant="primary" action="secondary" fullWidth onClick={() => {
                                        if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                                            // setIsDialogOpen(4), 
                                            setOutsideDialogOpen(true);
                                        }
                                        else {
                                            setIsDialogOpen(4), setOpenAction("MB")
                                        }
                                    }}>Misbehaviour</Button>
                                    <Button variant="primary" action="secondary" fullWidth onClick={() => {
                                        if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                                            // setIsDialogOpen(5), 
                                            setOutsideDialogOpen(true);
                                        }
                                        else {
                                            setIsDialogOpen(5), setOpenAction("LD")
                                        }
                                    }}>Late delivery</Button>
                                </div>
                                }
                            </div>
                        </> :
                            <div className="flex ai-center gap-1 flex-wrap">
                                <div onClick={() => { }} className="p-8 br-4 fs-link bg-primary-400 text-white-900 max-content mx-auto">Complaints</div>
                                {/* <div className="flex-grow-1">
                        <div className="btnsForComplaints flex jc-between flex-wrap ai-center gap-1 w-100">
                          <button onClick={() => {
                            if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                              // setIsDialogOpen(1)
                              setOutsideDialogOpen(true);
                            }
                            else {
                              setIsDialogOpen(1), setOpenAction("M")
                            }
                          }}>Missing</button>
                          <button onClick={() => {
                            if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                              // setIsDialogOpen(2) 
                              setOutsideDialogOpen(true);
                            }
                            else {
                              setIsDialogOpen(2), setOpenAction("PF")
                            }
                          }}>Pilferage</button>
                          <button onClick={() => {
                            if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                              // setIsDialogOpen(3), 
                              setOutsideDialogOpen(true);
                            }
                            else {
                              setIsDialogOpen(3), setOpenAction("DM")
                            }
                          }}>Damage</button>
                          <button onClick={() => {
                            if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                              // setIsDialogOpen(4), 
                              setOutsideDialogOpen(true);
                            }
                            else {
                              setIsDialogOpen(4), setOpenAction("MB")
                            }
                          }}>Misbehaviour</button>
                          <button onClick={() => {
                            if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                              // setIsDialogOpen(5), 
                              setOutsideDialogOpen(true);
                            }
                            else {
                              setIsDialogOpen(5), setOpenAction("LD")
                            }
                          }}>Late delivery</button>
                        </div>
      
                      </div> */}
                                <ComplaintButtons setIsDialogOpenFunc={setIsDialogOpenFunc} numbers={5} />
                            </div>
                        }
                        {latComplaint !== undefined &&
                            <div className='lastComplaintcontainerForBranchOrder'>
                                {mobileView ?
                                    <div className='eachcomplaintContainerForBranchOrder'>
                                        <div className="middleDiv paper mt-1">
                                            <p className='mx-auto lastComplaint fs-link p-8 br-4 bg-primary-400 text-white-900 max-content'> Last Complaint </p>
                                            {/* <div className='firstDivForBranchcomplaints'>
                    </div> */}
                                            <div className="flex-col m-flex-col-center gap-8 mt-1 paper">
                                                <p className='flex-center gap-4 fs-link'>
                                                    <div>
                                                        <CalendarIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                    </div>
                                                    <div>
                                                        {formatDate(latComplaint?.createdAt)}
                                                    </div>
                                                </p>
                                                <p className='flex-center gap-4 fs-link'>
                                                    <div>
                                                        <HoursIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                    </div>
                                                    <div>
                                                        {timeConvert(latComplaint?.createdAt)}
                                                    </div>
                                                </p>
                                                {/* <div className='secondDivForBranchcomplaints'>
                      </div> */}
                                                <p className="flex-center gap-4 fs-link">
                                                    <div>
                                                        <TimelineIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                                    </div>
                                                    <div>
                                                        {latComplaint?.complaintStatus === "O" ? `Opened` : latComplaint?.complaintStatus === "I" ? `In progress` :
                                                            latComplaint?.complaintStatus === "CP" ? `Compensation Paid` : latComplaint?.complaintStatus === "CG" ? `Compensation Granted` :
                                                                latComplaint?.complaintStatus === "CU" ? `Closed By Executive` : latComplaint?.complaintStatus === "CC" ? `Closed By Customer` :
                                                                    latComplaint?.complaintStatus === "CS" ? `Closed By System` : latComplaint?.complaintStatus === "RE" ? `Re Opened` : ``}
                                                    </div>
                                                </p>
                                                <p className='complaintTypeText flex-center gap-4 fs-link'>
                                                    <div>
                                                        <UngroupByRefTypeIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                                    </div>
                                                    <div>
                                                        {latComplaint.complaintType === "LD" ? `Late Delivery` : latComplaint.complaintType === "PM" ? `Parcel Missing` :
                                                            latComplaint.complaintType === "OT" ? `Other` : latComplaint.complaintType === "DMG" ? `Parcel Damaged` :
                                                                latComplaint.complaintType === "PF" ? `Pilferege` : latComplaint.complaintType === "MB" ? `Misbehavior by Kabra Employees` : ``}
                                                    </div>
                                                </p>
                                                {/* <div className='thirdDivForBranchcomplaints'>
                      </div> */}
                                                <p className='textOverFlowed flex-center gap-4 fs-link'>
                                                    <div>
                                                        <MessageAltIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                                    </div>
                                                    <div>
                                                        {/* {latComplaint?.messages[latComplaint.messages?.length - 1]?.text} */}
                                                    </div>
                                                </p>
                                                <p className="goToChat flex-center gap-4 fs-link" onClick={() => {
                                                    handleIndividualChat()
                                                    //  SourceName === "Customer-Authenticated-BranchOrder" ? handleIndividualChat() : alert("This Feature Will be Live Soon !")
                                                }}>
                                                    <ChatIcon className="text-primary-400" style={{ fontSize: "x-large" }} />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='' style={{ display: "block" }}>
                                        {latComplaint && latComplaint?.length > 0 && latComplaint?.map((xy: any, index: any) => {
                                            return (<div className="middleDiv mt-1 flex ai-center gap-1 jc-between flex-wrap paper">
                                                <p className='lastComplaint'> {index + 1} </p>
                                                <p className='complaintNo flex-center gap-4'>
                                                    <div>
                                                        <CalendarIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                                    </div>
                                                    <div>
                                                        {formatDate(xy?.createdAt)}
                                                    </div>
                                                </p>
                                                <p className='comaplintTime flex-center gap-4'>
                                                    <div>
                                                        <HoursIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                                    </div>
                                                    <div>
                                                        {timeConvert(xy?.createdAt)}
                                                    </div>
                                                </p>
                                                <p className="complaintStatus flex-center gap-4">
                                                    <div>
                                                        <TimelineIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                                    </div>
                                                    <div>
                                                        {xy?.complaintStatus === "O" ? `Opened` : xy?.complaintStatus === "I" ? `In progress` :
                                                            xy?.complaintStatus === "CP" ? `Compensation Paid` : xy?.complaintStatus === "CG" ? `Compensation Granted` :
                                                                xy?.complaintStatus === "CU" ? `Closed By Executive` : xy?.complaintStatus === "CC" ? `Closed By Customer` :
                                                                    xy?.complaintStatus === "CS" ? `Closed By System` : xy?.complaintStatus === "RE" ? `Re Opened` : ``}
                                                    </div>
                                                </p>
                                                <p className='complaintTypeText flex-center gap-4'>
                                                    <div>
                                                        <UngroupByRefTypeIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                                    </div>
                                                    <div>
                                                        {xy.complaintType === "LD" ? `Late Delivery` : xy.complaintType === "PM" ? `Parcel Missing` :
                                                            xy.complaintType === "OT" ? `Other` : xy.complaintType === "DMG" ? `Parcel Damaged` :
                                                                xy.complaintType === "PF" ? `Pilferege` : xy.complaintType === "MB" ? `Misbehavior by Kabra Employees` : ``}
                                                    </div>
                                                </p>
                                                <p className='textOverFlowed flex-center gap-4'>
                                                    <div>
                                                        <MessageAltIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                                    </div>
                                                    <div>
                                                        {xy?.messages[xy.messages.length - 1]?.text}
                                                    </div>
                                                </p>
                                                <p className="goToChat flex-center gap-4">
                                                    <div onClick={() => {
                                                        if (SourceName === "Branch-Authenticated-BranchOrder") {
                                                            setChatPopup(true)
                                                            // handleIndividualChatBranchOrder(latComplaint,latComplaint?.feedbackNumber,"BranchBooking")
                                                            //  historyData.push({pathname:"individualChat",
                                                            //   state:{
                                                            //     from: "redirect-chat-from-customerchat-list",
                                                            //     data: latComplaint?.feedbackNumber,
                                                            //     anotherData : latComplaint,
                                                            //     bookingType : "BranchBooking"
                                                            //   }
                                                            // })
                                                        }
                                                        if (SourceName === "Customer-Authenticated-BranchOrder") {
                                                            handleIndividualBranchOrderChat(BranchData?.docket?.feedBacks?.complaints[index],
                                                                BranchData?.docket?.feedBacks?.complaints[index]?.feedbackNumber, "BranchBooking")
                                                        }
                                                        // handleIndividualChat()
                                                        // SourceName === "Customer-Authenticated-BranchOrder" ? handleIndividualChat() : alert("This Feature Will be Live Soon !")
                                                    }}>
                                                        <ChatIcon className="text-primary-400" />
                                                    </div>
                                                    {xy.complaintType === "LD" || xy.complaintType === "PM" ?
                                                        null
                                                        :
                                                        <div onClick={() => handleClose(index)}>
                                                            <CancelIcon />
                                                        </div>
                                                    }
                                                </p>
                                            </div>)
                                        })}
                                    </div>
                                }
                            </div>
                        }

                        <Dialog open={open} onClose={handleDialogClose} maxWidth="sm" fullWidth>
                            <DialogContent>
                                <div style={{ margin: "10px" }}>
                                    {
                                        "Your Complaint has been registered, we will get back to you shortly."
                                    }
                                    <br />
                                    Your Complaint Number is {feedBack}.
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <ButtonDefault onClick={handleDialogClose} style={{ fontSize: "12px" }}>
                                    Close
                                </ButtonDefault>{" "}
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={Boolean(isDialogOpen)}
                            maxWidth={"xl"}
                            onClose={() => { handleDialogClose() }}
                        >
                            <div className='tac bold'>
                                <DialogTitle
                                    style={{ textAlign: "center" }}
                                    id="draggable-dialog-title"
                                >
                                    <div className='commonTitleOfPopup'>
                                        Raise Your Concern
                                    </div>
                                </DialogTitle>
                            </div>
                            <DialogContent>
                                {isDialogOpen === 1 ?
                                    <div className='popupForComplaint'>
                                        {SourceName === "Customer-Authenticated-BranchOrder" ?
                                            <div className="flex-col gap-8">
                                                <span>Detailed Information :{" "}</span>
                                                <textarea
                                                    // multiline={true}
                                                    rows={3}
                                                    className="py-8 px-1 br-4 border-white"
                                                    value={details}
                                                    placeholder="Provide detail information regarding your issue"
                                                    onChange={(e: any) => setDetails(e.target.value)}
                                                />
                                            </div>
                                            :
                                            <div>
                                                <Input12
                                                    label="Remarks"
                                                    value={reason}
                                                    multiline={true}
                                                    onChange={(e: any) => setReason(e.target.value)}
                                                />
                                            </div>
                                        }
                                    </div>
                                    :
                                    isDialogOpen === 2 ?
                                        <div className='popupForComplaint'>
                                            {SourceName === "Customer-Authenticated-BranchOrder" ?
                                                <div className="flex-col gap-8">
                                                    <span>Detailed Information :{" "}</span>
                                                    <textarea
                                                        // multiline={true}
                                                        className="py-8 px-1 br-4 border-white"
                                                        rows={3}
                                                        value={details}
                                                        placeholder="Provide detail information regarding your issue"
                                                        onChange={(e: any) => setDetails(e.target.value)}
                                                    />
                                                </div>
                                                :
                                                <>
                                                    <div>
                                                        <Input12
                                                            label="Remarks"
                                                            value={reason}
                                                            multiline={true}
                                                            onChange={(e: any) => setReason(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className='imageForComplaint'>
                                                        <p> Image : </p>
                                                        <p>
                                                            <ImageInput
                                                                name="Upload Image"
                                                                Icon={(props: any) => (
                                                                    <i {...props} className={"fas fa-id-card file_icon"} />
                                                                )}
                                                                onChange={setImage}
                                                                value={Image}
                                                                extras={<div></div>}
                                                            />
                                                        </p>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        :
                                        isDialogOpen === 3 ?
                                            <div className='popupForComplaint'>
                                                {SourceName === "Customer-Authenticated-BranchOrder" ?
                                                    <div className="flex-col gap-8">
                                                        <span>Detailed Information :{" "}</span>
                                                        <textarea
                                                            // multiline={true}
                                                            className="py-8 px-1 border-white br-4"
                                                            rows={3}
                                                            value={details}
                                                            placeholder="Provide detail information regarding your issue"
                                                            onChange={(e: any) => setDetails(e.target.value)}
                                                        />
                                                    </div>
                                                    :
                                                    <>
                                                        <div>
                                                            <Input12
                                                                label="Remarks"
                                                                value={reason}
                                                                multiline={true}
                                                                onChange={(e: any) => setReason(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className='imageForComplaint'>
                                                            <p> Image : </p>
                                                            <p>
                                                                <ImageInput
                                                                    name="Upload Image"
                                                                    Icon={(props: any) => (
                                                                        <i {...props} className={"fas fa-id-card file_icon"} />
                                                                    )}
                                                                    onChange={setImage}
                                                                    value={Image}
                                                                    extras={<div></div>}
                                                                />
                                                            </p>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            :
                                            isDialogOpen === 4 ?
                                                <div className='popupForComplaint'>
                                                    {SourceName === "Customer-Authenticated-BranchOrder" ?
                                                        <div className="flex-col gap-8">
                                                            <span>Detailed Information :{" "}</span>
                                                            <textarea
                                                                // multiline={true}
                                                                className="py-8 px-1 border-white br-4"
                                                                rows={3}
                                                                value={details}
                                                                placeholder="Provide detail information regarding your issue"
                                                                onChange={(e: any) => setDetails(e.target.value)}
                                                            />
                                                        </div>
                                                        :
                                                        <>
                                                            <div>
                                                                <Select
                                                                    value={customerType}
                                                                    options={customerOptions}
                                                                    onChange={(e: any) => setCustomerType(e)}
                                                                    placeholder="Select Customer"
                                                                />
                                                            </div>
                                                            {customerType && customerType.value === "other" && (
                                                                <div>
                                                                    <Input
                                                                        value={name}
                                                                        placeholder="Enter Name"
                                                                        onChange={(e: any) => setName(e.target.value)}
                                                                    />
                                                                </div>
                                                            )}
                                                            {customerType && customerType.value === "other" && (
                                                                <div>
                                                                    <Input
                                                                        value={contact}
                                                                        placeholder="Enter Mobile Number"
                                                                        onChange={(e: any) => setContact(e.target.value)}
                                                                    />
                                                                </div>
                                                            )}
                                                            <div>
                                                                <textarea
                                                                    // multiline={true}
                                                                    className="py-8 px-1 border-white br-4 w-100"
                                                                    rows={3}
                                                                    value={details}
                                                                    placeholder="Provide detail information regarding your issue"
                                                                    onChange={(e: any) => setDetails(e.target.value)}
                                                                />
                                                            </div>
                                                            <div>
                                                                <Select
                                                                    value={misBehaveUser}
                                                                    placeholder="Select Branch"
                                                                    options={branchOptions}
                                                                    onChange={(e: any) => setMissBehaveUSer(e)}
                                                                />
                                                            </div>
                                                            <div>
                                                                <Select
                                                                    options={userOptions}
                                                                    placeholder="Select User"
                                                                    value={missBehaveBranch}
                                                                    onChange={(e: any) => setMissBehaveBranch(e)}
                                                                />
                                                            </div>
                                                            <div>
                                                                Attach Image :{" "}
                                                                <ImageInput
                                                                    name="Add Image"
                                                                    Icon={(props: any) => (
                                                                        <i {...props} className={"fas fa-id-card file_icon"} />
                                                                    )}
                                                                    onChange={(e: any) => setImageTwo(e)}
                                                                    value={ImageTwo}
                                                                    extras={<div></div>}
                                                                />
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                :
                                                isDialogOpen === 5 ?
                                                    <div className='popupForComplaint'>
                                                        {SourceName === "Customer-Authenticated-BranchOrder" ?
                                                            <div className="flex-col gap-8">
                                                                <span>Detailed Information :{" "}</span>
                                                                <textarea
                                                                    // multiline={true}
                                                                    className="py-8 px-1 border-white br-4"
                                                                    rows={3}
                                                                    value={details}
                                                                    placeholder="Provide detail information regarding your issue"
                                                                    onChange={(e: any) => setDetails(e.target.value)}
                                                                />
                                                            </div>
                                                            :
                                                            <>
                                                                <div>
                                                                    Contact Person :{" "}
                                                                    <Select
                                                                        value={customerType}
                                                                        options={customerOptions}
                                                                        onChange={(e: any) => setCustomerType(e)}
                                                                        placeholder="Select Customer"
                                                                    />
                                                                </div>
                                                                {customerType && customerType.value === "other" && (
                                                                    <div>
                                                                        Name :{" "}
                                                                        <Input
                                                                            value={name}
                                                                            placeholder="Name"
                                                                            onChange={(e: any) => setName(e.target.value)}
                                                                        />
                                                                    </div>
                                                                )}
                                                                {customerType && customerType.value === "other" && (
                                                                    <div>
                                                                        Mobile No. :{" "}
                                                                        <Input
                                                                            value={contact}
                                                                            placeholder="Mobile Number"
                                                                            onChange={(e: any) => setContact(e.target.value)}
                                                                        />
                                                                    </div>
                                                                )}
                                                                <div className="flex-col gap-8">
                                                                    <span>Detailed Information :{" "}</span>
                                                                    <textarea
                                                                        // multiline={true}
                                                                        className="py-8 px-1 border-white br-4"
                                                                        rows={3}
                                                                        value={details}
                                                                        placeholder="Provide detail information regarding your issue"
                                                                        onChange={(e: any) => setDetails(e.target.value)}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    Attach Image :{" "}
                                                                    <ImageInput
                                                                        name="Add Image"
                                                                        Icon={(props: any) => (
                                                                            <i {...props} className={"fas fa-id-card file_icon"} />
                                                                        )}
                                                                        onChange={(e: any) => setImageTwo(e)}
                                                                        value={ImageTwo}
                                                                        extras={<div></div>}
                                                                    />
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    :
                                                    isDialogOpen === 6 ?
                                                        <div className='popupForComplaint'>
                                                            <div>
                                                                <Input12
                                                                    label="Remarks"
                                                                    value={reason}
                                                                    onChange={(e: any) => setReason(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className='imageForComplaint'>
                                                                <p> Image : </p>
                                                                <p>
                                                                    <ImageInput
                                                                        name="Upload Image"
                                                                        Icon={(props: any) => (
                                                                            <i {...props} className={"fas fa-id-card file_icon"} />
                                                                        )}
                                                                        onChange={setImage}
                                                                        value={Image}
                                                                        extras={<div></div>}
                                                                    />
                                                                </p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                }
                            </DialogContent>
                            <DialogActions>
                                <div className='flex jc-end gap-8'>
                                    <ButtonPrimary className={`button-primary-secondary fs-popup-btn`} onClick={() => {
                                        if (SourceName === "Branch-Authenticated-BranchOrder") {
                                            if (isDialogOpen === 4) {
                                                handleMBandLDcomplaints("MB")
                                                handleDialogClose()
                                            }
                                            if (isDialogOpen === 5) {
                                                handleMBandLDcomplaints("LD")
                                                handleDialogClose()
                                            }
                                            else {
                                                handleSubmitRemarks()
                                                handleDialogClose()
                                            }
                                        }
                                        if (SourceName === "Customer-Authenticated-BranchOrder") {
                                            if (details?.length <= 0) {
                                                showHttpError(`Kindly Enter Remarks regarding Complaint.`);
                                                return;
                                            }
                                            else {
                                                if (isDialogOpen === 1) {
                                                    hanldeCustomerComaplints("PM")
                                                }
                                                if (isDialogOpen === 2) {
                                                    hanldeCustomerComaplints("PF")
                                                }
                                                if (isDialogOpen === 3) {
                                                    hanldeCustomerComaplints("DMG")
                                                }
                                                if (isDialogOpen === 4) {
                                                    hanldeCustomerComaplints("MB")
                                                }
                                                if (isDialogOpen === 5) {
                                                    hanldeCustomerComaplints("LD")
                                                }
                                            }
                                        }
                                        if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                                            if (details?.length <= 0) {
                                                return showHttpError(`Kindly Enter Remarks regarding Complaint.`)
                                            }
                                            else if (customerType === "") {
                                                return showHttpError(`Kindly Select Type Of Customer To Register Complaint.`)
                                            }
                                            else if (customerType?.value === "other") {
                                                if (contact === "" || contact?.length <= 9 || contact?.length > 10) {
                                                    return showHttpError(`Contact Number Should Not Be Empty Or Less / Greater Than 10.`)
                                                }
                                                if (name === "") {
                                                    return showHttpError(`Contact Name Should Not Be Empty.`)
                                                }
                                            }
                                            else {
                                                handleCustomerComplaintOutSide("")
                                            }
                                        }
                                        setIsDialogOpen(0)
                                    }}>
                                        Submit
                                    </ButtonPrimary>
                                    <ButtonDefault
                                        className="button-danger-secondary fs-popup-btn"
                                        onClick={() => { handleDialogClose() }}
                                    >
                                        close
                                    </ButtonDefault>
                                </div>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={Boolean(outsideDialogOpen)}
                            maxWidth={"xl"}
                            onClose={() => { handleDialogClose() }}
                        >
                            <div className='tac bold'>
                                <DialogTitle
                                    style={{ textAlign: "center" }}
                                    id="draggable-dialog-title"
                                >
                                    <div className='commonTitleOfPopup'>
                                        Raise Your Concern
                                    </div>
                                </DialogTitle>
                            </div>
                            <DialogContent>
                                <div className='popupForComplaintForOutside'>
                                    Contact Person :{" "}
                                    <Select
                                        value={customerType}
                                        options={customerOptions}
                                        onChange={(e: any) => setCustomerType(e)}
                                        placeholder="Select Customer"
                                    />
                                    {customerType && customerType.value === "other" &&
                                        <>
                                            Name :{" "}
                                            <Input
                                                value={name}
                                                placeholder="Name"
                                                onChange={(e: any) => setName(e.target.value)}
                                            />
                                            Mobile No. :{" "}
                                            <Input
                                                type={"number"}
                                                value={contact}
                                                placeholder="Mobile Number"
                                                onChange={(e: any) => setContact(e.target.value)}
                                            />
                                        </>
                                    }
                                    <div className="flex-col gap-8">
                                        <span>
                                            Detailed Information :{" "}
                                        </span>
                                        <textarea
                                            // multiline={true}
                                            rows={3}
                                            className="border-white py-8 px-1 br-4"
                                            value={details}
                                            placeholder="Provide detail information regarding your issue"
                                            onChange={(e: any) => setDetails(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <div className='flex jc-end gap-8'>
                                    <ButtonPrimary className={`button-primary-secondary fs-popup-btn`} onClick={() => {
                                        if (SourceName === "Customer-NotAuthenticated-BranchOrder") {
                                            if (details?.length <= 0) {
                                                return showHttpError(`Kindly Enter Remarks regarding Complaint.`)
                                            }
                                            else if (customerType === "") {
                                                return showHttpError(`Kindly Select Type Of Customer To Register Complaint.`)
                                            }
                                            else if (customerType?.value === "other") {
                                                if (contact === "" || contact?.length <= 9 || contact?.length > 10) {
                                                    return showHttpError(`Contact Number Should Not Be Empty Or Less / Greater Than 10.`)
                                                }
                                                if (name === "") {
                                                    return showHttpError(`Contact Name Should Not Be Empty.`)
                                                }
                                            }
                                            else {
                                                if (isDialogOpen === 1) {
                                                    handleCustomerComplaintOutSide("PM")
                                                }
                                                if (isDialogOpen === 2) {
                                                    handleCustomerComplaintOutSide("PF")
                                                }
                                                if (isDialogOpen === 3) {
                                                    handleCustomerComplaintOutSide("DMG")
                                                }
                                                if (isDialogOpen === 4) {
                                                    handleCustomerComplaintOutSide("MB")
                                                }
                                                if (isDialogOpen === 5) {
                                                    handleCustomerComplaintOutSide("LD")
                                                }
                                            }
                                        }
                                    }}>
                                        Submit
                                    </ButtonPrimary>
                                    <ButtonDefault
                                        className="button-danger-secondary fs-popup-btn"
                                        onClick={() => { handleDialogClose() }}
                                    >
                                        close
                                    </ButtonDefault>
                                </div>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={Boolean(outsidePaymentDialogOpen)}
                            maxWidth={"xl"}
                            onClose={() => { handleDialogClose() }}
                        >
                            <div className='tac bold'>
                                <DialogTitle
                                    style={{ textAlign: "center" }}
                                    id="draggable-dialog-title"
                                >
                                    <div className='commonTitleOfPopup'>
                                        Online Payment
                                    </div>
                                </DialogTitle>
                            </div>
                            <DialogContent>
                                <div className='popupForComplaintForOutside'>
                                    <div className="p-1 border-white shadow-small br-4">
                                        <div className='commonSelectSendReciever flex ai-center'>
                                            <FormControlLabel
                                                control={
                                                    <CheckBox
                                                        checked={senderOptInForPayment === "Sender" ? true : false}
                                                        onChange={() => { setSenderOptInForPayment("Sender"), setSelectedNumberForPayment(BranchData?.docket?.sender?.contact) }}
                                                        color="primary"
                                                    />
                                                }
                                                label={
                                                    <span style={{ fontSize: "16px" }}>
                                                        Sender
                                                    </span>
                                                }
                                            />
                                            <p className="m-0">{getPhoneNumberPrefix(BranchData?.docket?.sender?.contact)}</p>
                                        </div>
                                    </div>
                                    <div className="p-1 border-white shadow-small br-4 mt-1">
                                        <div className='commonSelectSendReciever flex ai-center'>
                                            <FormControlLabel
                                                control={
                                                    <CheckBox
                                                        checked={senderOptInForPayment === "Reciever" ? true : false}
                                                        onChange={() => { setSenderOptInForPayment("Reciever"), setSelectedNumberForPayment(BranchData?.docket?.receiver?.contact) }}
                                                        color="primary"
                                                    />
                                                }
                                                label={
                                                    <span style={{ fontSize: "16px" }}>
                                                        Reciever
                                                    </span>
                                                }
                                            />
                                            <p>{getPhoneNumberPrefix(BranchData?.docket?.receiver?.contact)}</p>
                                        </div>
                                    </div>
                                    <hr />
                                    {(BranchData.docket?.paymentMode === "topay" && !BranchData.docket?.settled) &&
                                        <div className='commonSelectSendReciever flex ai-center'>
                                            <FormControlLabel
                                                control={
                                                    <CheckBox
                                                        checked={mainBookingCheckBoxPart}
                                                        onChange={() => setMainBookingCheckBoxPart(!mainBookingCheckBoxPart)}
                                                        color="primary"
                                                        disabled={(BranchData.docket.settled || (BranchData?.docket?.paymentMode !== "topay")) ? true :
                                                            (!BranchData.docket.settled && TotalAmount - totalAmount > 0) ? true : false}
                                                    />
                                                }
                                                label={
                                                    <span style={{ fontSize: "16px" }}>
                                                        Normal Docket Payment
                                                    </span>
                                                }
                                            />
                                            {/* <p>{getPhoneNumberPrefix(BranchData?.docket?.sender?.contact)}</p> */}
                                        </div>
                                    }
                                    {mainBookingCheckBoxPart && <div className="text-black-400">If Demmurage And Gst Penalty Implemented, It will Be Taken At Branch ~!</div>}

                                    {(BranchData?.doorDeliveryDocket.paymentMode === "topay" && !BranchData?.doorDeliveryDocket.settled) &&
                                        <div className='commonSelectSendReciever flex ai-center'>
                                            <FormControlLabel
                                                control={
                                                    <CheckBox
                                                        checked={DoorDelBookingCheckBox}
                                                        onChange={() => setDoorDelBookingCheckBox(!DoorDelBookingCheckBox)}
                                                        disabled={(BranchData?.doorDeliveryDocket.settled || BranchData?.doorDeliveryDocket.paymentMode !== "topay") ? true : false}
                                                        color="primary"
                                                    />
                                                }
                                                label={
                                                    <span style={{ fontSize: "16px" }}>
                                                        Door Delivery Payment
                                                    </span>
                                                }
                                            />
                                            {/* <p>{getPhoneNumberPrefix(BranchData?.docket?.sender?.contact)}</p> */}
                                        </div>
                                    }

                                    <div className="two-column m-one-column m-one-column gap-1">
                                        <div>
                                            <div>
                                                Total
                                            </div>
                                            <div>
                                                <input
                                                    id="Total"
                                                    type="number"
                                                    className="py-8 px-1 br-4 border-white w-100"
                                                    disabled={true}
                                                    placeholder="Total"
                                                    value={showOnlyTotalAmount}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <div style={{}}>
                                                Pending
                                            </div>
                                            <div>
                                                <input
                                                    id="Total"
                                                    type="number"
                                                    className="py-8 px-1 br-4 border-white w-100"
                                                    disabled={true}
                                                    placeholder="Total"
                                                    value={(BranchData?.docket?.settled && BranchData?.doorDeliveryDocket?.settled) ? 0 :
                                                        (BranchData?.docket?.paymentMode === "topay" ?
                                                            (mainBookingCheckBoxPart && DoorDelBookingCheckBox) ? (totalNetCost + totalDoorDelCost) :
                                                                (mainBookingCheckBoxPart && !DoorDelBookingCheckBox) ? (totalNetCost) :
                                                                    (DoorDelBookingCheckBox && !mainBookingCheckBoxPart) ? (totalDoorDelCost) :
                                                                        0 :
                                                            BranchData?.docket?.paymentMode !== "topay" && !BranchData.doorDeliveryDocket?.settled &&
                                                                (mainBookingCheckBoxPart && DoorDelBookingCheckBox) ? (totalNetCost + totalDoorDelCost) :
                                                                (DoorDelBookingCheckBox && !mainBookingCheckBoxPart) ? (totalDoorDelCost) :
                                                                    totalAmountWithoutTopay)}
                                                />
                                            </div>
                                        </div>

                                        {/* <div style={{border : "0.2px solid transparent" , width : "30%" , padding : "1%" , display : "block" , marginLeft : "5%"}}>
                        <div style={{border : "0.2px solid transparent" , padding : "0.5%"}}>
                          {/* <TextField
                            inputProps={{
                              style: { height: "3px" },
                            }}
                            style={{ zIndex: 0 }}
                            fullWidth={true}
                            id="total"
                            placeholder="Total"
                            InputProps={{
                              readOnly: true,
                            }}
                            value={doorDeliveryDocket?.deliveryCharge === undefined || doorDeliveryDocket?.paymentMode === "paid" ? totalAmount : combinedTotalAmount}
                            margin="normal"
                          /> 
      
                          <input
                            id="Total"
                            type="number"
                            disabled={true}
                            placeholder="Total"
                            style={{border : "0.2px solid grey" , borderRadius : '2.5%' , padding : "7.5% 5%" , margin : "auto" , width : "100%"}}
                            value={(bookingData?.docket?.settled && doorDeliveryDocket?.settled) ? 0 : doorDeliveryDocket?.deliveryCharge === undefined ? 
                                    (bookingData?.docket?.paymentMode !== "topay" ? 0 : totalAmount) : 
                                    (DoorDelBookingCheckBox && !bookingData?.docket?.settled) ? combinedTotalAmount : 
                                    (!DoorDelBookingCheckBox && !bookingData?.docket?.settled) ? totalAmount : 
                                    (DoorDelBookingCheckBox && bookingData?.docket?.settled) ? onlyDoorDeliveryAmount : 0}
                          />
                        </div>
                      </div>*/}
                                    </div>

                                </div>
                            </DialogContent>
                            <DialogActions>
                                <div className='flex jc-end gap-8'>
                                    <ButtonPrimary className={`button-primary-secondary fs-popup-btn`} onClick={handleOutsidePayment} disabled={(DoorDelBookingCheckBox && mainBookingCheckBoxPart) ? true : false}>
                                        Pay Online
                                    </ButtonPrimary>
                                    <ButtonDefault
                                        className="button-danger-secondary fs-popup-btn"
                                        onClick={() => { handleDialogClose() }}
                                    >
                                        close
                                    </ButtonDefault>
                                </div>
                            </DialogActions>
                        </Dialog>

                    </div>
                    // :
                    // }
                    //  </>
                    :
                    null) : null
                }

                {newProps?.data?.userInfo?.loginType !== "B" && BranchData?.docket?.deliveredAt !== undefined &&
                    <div className="p-1 br-4 shadow-small border-white">
                        <div className="">
                            {mobileView ?
                                <div className="grid">
                                    <p className="text-left fs-link fw-600">Review & Rating</p>
                                    <div className="flex-center">
                                        <Rating
                                            max={3}
                                            style={{ fontSize: "xx-large" }}
                                            name="hover-feedback1"
                                            value={ratingFromUser}
                                            precision={1}
                                            disabled={ratingDone ? true : false}
                                            onChange={(e: any) => {
                                                setRatingFromUser(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <textarea
                                        style={{ width: "100%" }}
                                        value={reviewFromUser}
                                        disabled={ratingDone ? true : false}
                                        // multiline={true}
                                        // variant="outlined"
                                        placeholder="Write your review here..."
                                        className="p-10 border-white br-4"
                                        rows={5}
                                        onChange={(el: any) => { setReviewFromUser(el.target.value) }}
                                    />
                                    <Button variant="primary" action="primary" onClick={() => alert("Heloo 123")} className="my-8" Icon={<SendIcon />}>Send</Button>
                                </div>
                                :
                                <div className="reviewRatingDiv jc-between">
                                    <div className="flex gap-1 flex-wrap ai-center">
                                        <div className="ratingWebDiv">
                                            <div className="complaintsTitle">Rating</div>
                                            <div className="applyRating">
                                                <Rating
                                                    max={3}
                                                    style={{ fontSize: "xx-large", marginTop: "2.5%" }}
                                                    name="hover-feedback1"
                                                    value={ratingFromUser}
                                                    precision={1}
                                                    disabled={ratingDone ? true : false}
                                                    onChange={(e: any) => {
                                                        setRatingFromUser(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="reviewWebDiv">
                                            <div className="complaintsTitle fs-link">Review</div>
                                            <div className="applyReview w-100">
                                                {/* <TextField
                    style={{ width: "100%", marginTop: 7 , fontSize:"xx-large" }}
                    value={reviewFromUser}
                    disabled={BranchData?.docket?.deliveredAt ? false : true}
                    multiline={true}
                    variant="outlined"
                    placeholder="Give a Review"
                    rows={2}
                    onChange={(el:any)=>{setReviewFromUser(el.target.value)}}
                  /> */}

                                                <input
                                                    placeholder="Give a Review"
                                                    className="border-white br-4 outline-none py-8 px-1"
                                                    value={reviewFromUser}
                                                    disabled={ratingDone ? true : false}
                                                    onChange={(el: any) => { setReviewFromUser(el.target.value) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {ratingDone ?
                                        <div className="submitRating">
                                            <button className="suubmitIcon">
                                            </button>
                                        </div>
                                        :
                                        <div className="submitRating" onClick={() => {
                                            if (reviewFromUser.length < 2) {
                                                return showHttpError("Review Is Mandatory !")
                                            }
                                            else if (ratingFromUser === 0) {
                                                return showHttpError("Rating Is Mandatory !")
                                            }
                                            setOutsideRating(true)
                                        }}>
                                            <button className="suubmitIcon">
                                                <SendIcon className="text-primary-400" />
                                            </button>

                                        </div>
                                    }
                                </div>
                            }
                        </div>

                        <Dialog
                            open={Boolean(outsideRating)}
                            maxWidth={"xl"}
                            onClose={() => { handleDialogClose() }}
                        >
                            <div className='tac bold'>
                                <DialogTitle
                                    style={{ textAlign: "center" }}
                                    id="draggable-dialog-title"
                                >
                                    <div className='commonTitleOfPopup'>
                                        Review And Rating
                                    </div>
                                </DialogTitle>
                            </div>
                            <DialogContent>
                                <div style={{ border: "0.2px solid transparent", padding: "5%", width: "250px", height: "200px" }}>
                                    Rating Given By :{" "}
                                    <Select
                                        value={customerTypeForRating}
                                        options={customerOptions}
                                        onChange={(e: any) => setCustomerTypeForRating(e)}
                                        placeholder="Select Customer"
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <div className='onLinePkgbtnMobile'>
                                    <ButtonPrimary onClick={() => {
                                        handleRatingForBranchOrder();
                                    }}>
                                        Submit
                                    </ButtonPrimary>
                                    <ButtonDefault
                                        className=""
                                        onClick={() => { handleDialogClose() }}
                                    >
                                        close
                                    </ButtonDefault>
                                </div>
                            </DialogActions>
                        </Dialog>

                    </div>
                }

                {/* Builty Printing */}
                {/* {originalDocket &&
        <div
          id="track_builty_download"
          style={{ width: "auto", height: "auto", opacity: 0 }}
        >
          <BuiltyPrint
            docket={originalDocket}
            isDocket={BranchData?.docket?.docketNumber.includes("-") ? false : true}
          />
        </div>
      } */}

                {/* Outside Tracking First Popup Started */}
                <Dialog
                    open={firstPopup}
                    maxWidth={"xl"}
                    onClose={() => { setFirstPopup(false) }}
                >
                    <div className='tac bold'>
                        <DialogTitle
                            style={{ textAlign: "center" }}
                            id="draggable-dialog-title"
                        >
                            <div className='commonTitleOfPopup'>
                                Log In
                            </div>
                        </DialogTitle>
                    </div>
                    <DialogContent>
                        {firstPopupTabs === 0 &&
                            <div className="firstPopupFirstTab">
                                {/* Choose AnyOne */}
                                {/* <br /> */}
                                <div className='grid gap-1'>
                                    <div className='flex gap-1 ai-center fs-link p-1 border-white br-4'>
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    checked={senderOptIn === "Sender" ? true : false}
                                                    onChange={() => {
                                                        setSenderOptIn("Sender"), setSelectedNumber(BranchData?.docket?.sender?.contact),
                                                            ReactGA4.event({
                                                                category: 'Button',
                                                                action: `sender_check_box`,
                                                                label: 'Btn Clicked From Tracking Outside',
                                                                value: BranchData?.docket.trackingNumber
                                                            });
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <span className="fs-link">
                                                    Sender
                                                </span>
                                            }
                                        />
                                        <p className="flex ai-center gap-4"><CallIcon /> {getPhoneNumberPrefix(BranchData?.docket?.sender?.contact)}</p>
                                    </div>
                                    <div className='flex gap-1 ai-center fs-link p-1 border-white br-4'>
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    checked={senderOptIn === "Reciever" ? true : false}
                                                    onChange={() => {
                                                        setSenderOptIn("Reciever"), setSelectedNumber(BranchData?.docket?.receiver?.contact),
                                                            ReactGA4.event({
                                                                category: 'Button',
                                                                action: `reciever_check_box`,
                                                                label: 'Btn Clicked From Tracking Outside',
                                                                value: BranchData?.docket.trackingNumber
                                                            });
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <span className="fs-link">
                                                    Reciever
                                                </span>
                                            }
                                        />
                                        <p className="flex ai-center gap-4"><CallIcon />{getPhoneNumberPrefix(BranchData?.docket?.receiver?.contact)}</p>
                                    </div>
                                    <div className='flex gap-1 ai-center fs-link p-1 border-white br-4'>
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    checked={senderOptIn === "Other" ? true : false}
                                                    onChange={() => {
                                                        setSenderOptIn("Other"),
                                                            ReactGA4.event({
                                                                category: 'Button',
                                                                action: `new_customer_check_box`,
                                                                label: 'Btn Clicked From Tracking Outside',
                                                                value: BranchData?.docket.trackingNumber
                                                            });
                                                    }}
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <span className="fs-link">
                                                    New To TapTap
                                                </span>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {firstPopupTabs === 1 &&
                            <div className='firstPopupSecondTab'>
                                {userHasPass ?
                                    <div className=''>
                                        {/* <Input6
                        label="Enter OTP"
                        style={{width:"250px"}}
                        value={firstPopupOtp}
                        onChange={(e: any) => setFirstPopupOtp(e.target.value)}
                      />  */}
                                        {/* <Input6
                    label="Enter Password"
                    style={{ width: "250px" }}
                    className={`fs-link`}
                    value={firstPopupPassword}
                    onChange={(e: any) => setFirstPopupPassword(e.target.value)}
                    onFocus={() => {
                      return ReactGA4.event({
                        category: 'Button',
                        action: `password_entered`,
                        label: 'Btn Clicked From Tracking Outside',
                        value: BranchData?.docket.trackingNumber
                      })
                    }}
                  /> */}
                                        <input
                                            type="password"
                                            className="br-4 border-white py-10 px-1 w-100"
                                            placeholder="Enter password"
                                            value={firstPopupPassword}
                                            onChange={(e: any) => setFirstPopupPassword(e.target.value)}
                                            onFocus={() => {
                                                return ReactGA4.event({
                                                    category: 'Button',
                                                    action: `password_entered`,
                                                    label: 'Btn Clicked From Tracking Outside',
                                                    value: BranchData?.docket.trackingNumber
                                                })
                                            }}
                                        />
                                        <Link to="/login" className="fp-span">
                                            <p onClick={() => {
                                                ReactGA4.event({
                                                    category: 'Button',
                                                    action: `forget_pass_clicked`,
                                                    label: 'Btn Clicked From Tracking Outside',
                                                    value: BranchData?.docket.trackingNumber
                                                });
                                            }}>
                                                Forgot Password?
                                            </p>
                                        </Link>
                                    </div>
                                    :
                                    <div className='flex-col gap-1'>
                                        <input
                                            className="border-white br-4 w-100 px-1 py-8"
                                            placeholder="Enter OTP"
                                            value={firstPopupOtp}
                                            onChange={(e: any) => setFirstPopupOtp(e.target.value)}
                                        />
                                        <input
                                            className="border-white br-4 w-100 px-1 py-8"
                                            placeholder="New Password"
                                            value={firstPopupNewPassword}
                                            onChange={(e: any) => setFirstPopupNewPassword(e.target.value)}
                                        />
                                        <input
                                            className="border-white br-4 w-100 px-1 py-8"
                                            placeholder="Confirm Password"
                                            value={firstPopupConfirmPassword}
                                            onChange={(e: any) => setFirstPopupConfirmPassword(e.target.value)}
                                        />
                                        <Link to="/login" className="">
                                            <p className="text-primary-400">
                                                Forgot Password
                                            </p>
                                        </Link>
                                    </div>
                                }
                            </div>
                        }
                    </DialogContent>
                    <DialogActions>
                        <div className='flex gap-1 ai-center jc-end'>
                            {senderOptIn !== "" && firstPopupTabs === 0 &&
                                <ButtonPrimary
                                    className="button-primary-secondary fs-popup-btn fw-500"
                                    onClick={() => {
                                        ReactGA4.event({
                                            category: `Button`,
                                            action: `${senderOptIn === "Sender" || senderOptIn === "Reciever" ? "proceed_button_clicked" : "register_now_button_clicked"}`,
                                            label: 'Btn Clicked From Tracking Outside',
                                            value: BranchData?.docket.trackingNumber
                                        });
                                        if (senderOptIn === "Sender" || senderOptIn === "Reciever") {
                                            if (!userHasPass && senderOptIn === "Sender") {
                                                sendCustomerOTP({ contact: BranchData?.docket?.sender?.contact })
                                            }
                                            else if (!userHasPass && senderOptIn === "Reciever") {
                                                sendCustomerOTP({ contact: BranchData?.docket?.receiver?.contact })
                                            }
                                            setFirstPopupTabs(firstPopupTabs + 1)
                                        }
                                        else {
                                            openInNewTab('https://taptap.in/customer')
                                        }
                                    }}
                                >
                                    {senderOptIn === "Sender" || senderOptIn === "Reciever" ? "Proceed" : "Register Now"}
                                </ButtonPrimary>
                            }
                            {firstPopupTabs === 1 &&
                                <div className='flex gap-1 ai-center'>
                                    <ButtonDefault
                                        className="button-white-tertiary fs-popup-btn fw-500"
                                        onClick={() => {
                                            setFirstPopupTabs(0),
                                                ReactGA4.event({
                                                    category: 'Button',
                                                    action: `back_button_clicked`,
                                                    label: 'Btn Clicked From Tracking Outside',
                                                    value: BranchData?.docket.trackingNumber
                                                });
                                        }}
                                    >
                                        Back
                                    </ButtonDefault>
                                    {userHasPass ?
                                        <ButtonPrimary
                                            className="button-primary-secondary fs-popup-btn fw-500"
                                            onClick={() => {
                                                handleLogin(),
                                                    ReactGA4.event({
                                                        category: 'Button',
                                                        action: `sign_in_button_clicked`,
                                                        label: 'Btn Clicked From Tracking Outside',
                                                        value: BranchData?.docket.trackingNumber
                                                    });
                                            }}
                                        >
                                            Sign In
                                        </ButtonPrimary>
                                        :
                                        <ButtonPrimary
                                            className="button-primary-secondary fs-popup-btn fw-500"
                                            onClick={() => {
                                                handleSignUp(),
                                                    ReactGA4.event({
                                                        category: 'Button',
                                                        action: `sign_up_button_clicked`,
                                                        label: 'Btn Clicked From Tracking Outside',
                                                        value: BranchData?.docket.trackingNumber
                                                    });
                                            }}
                                        >
                                            Sign Up
                                        </ButtonPrimary>
                                    }
                                </div>
                            }
                            <ButtonDefault
                                className="button-danger-secondary fs-popup-btn fw-500"
                                onClick={() => {
                                    setFirstPopup(false),
                                        ReactGA4.event({
                                            category: 'Button',
                                            action: `close_button_clicked`,
                                            label: 'Btn Clicked From Tracking Outside',
                                            value: BranchData?.docket.trackingNumber
                                        });
                                }}
                            >
                                close
                            </ButtonDefault>
                        </div>
                    </DialogActions>
                </Dialog>
                {/* Outside Tracking First Popup Ended */}

                {/* Outside Tracking First Popup Started */}
                <Dialog
                    open={chatPopup}
                    maxWidth={"xl"}
                    onClose={() => { setChatPopup(false) }}
                >
                    <div className='tac bold'>
                        <DialogTitle
                            style={{ textAlign: "center" }}
                            id="draggable-dialog-title"
                        >
                            <div className='commonTitleOfPopup'>
                                Chat Container
                            </div>
                        </DialogTitle>
                    </div>
                    <DialogContent>
                        <div className='chatDivForBrachPerson'>
                            <ChatContainer
                                chatHistory={newProps?.data?.customerChatHistory && newProps?.data?.customerChatHistory?.chat}
                                value={guestMessage}
                                onChange={setGuestMessage}
                                onKeyDown={handleSend}
                                onClick={handleSend}
                                isClosedComplaint={true}
                            />
                        </div>
                        {/* {firstPopupTabs === 0 && 
              <div className="firstPopupFirstTab">
                Choose AnyOne
                <br />
                <div className='slectTypeOfUserCss'>
                  <div className='commonSelectSendReciever'>
                    <FormControlLabel
                      control={
                        <CheckBox
                          checked={senderOptIn === "Sender" ? true : false }
                          onChange={() => {setSenderOptIn("Sender"),setSelectedNumber(BranchData?.docket?.sender?.contact)}}
                          color="primary"
                        />
                      }
                      label={
                        <span style={{ fontSize: "16px" }}>
                          Sender
                        </span>
                      }
                    />
                    <p>{getPhoneNumberPrefix(BranchData?.docket?.sender?.contact)}</p>
                  </div>
                  <div className='commonSelectSendReciever'>
                    <FormControlLabel
                      control={
                        <CheckBox
                        checked={senderOptIn === "Reciever" ? true : false }
                        onChange={() => {setSenderOptIn("Reciever"),setSelectedNumber(BranchData?.docket?.receiver?.contact)}}
                        color="primary"
                        />
                      }
                      label={
                        <span style={{ fontSize: "16px" }}>
                          Reciever
                        </span>
                      }
                      />
                      <p>{getPhoneNumberPrefix(BranchData?.docket?.receiver?.contact)}</p>
                  </div>
                  <div className='otherSelectPopupcustomer'>
                    <FormControlLabel
                      control={
                        <CheckBox
                          checked={senderOptIn === "Other" ? true : false }
                          onChange={() => setSenderOptIn("Other")}
                          color="primary"
                        />
                      }
                      label={
                        <span style={{ fontSize: "16px" }}>
                          Other
                        </span>
                      }
                    />
                  </div>
                </div>
              </div>
            }
            {firstPopupTabs === 1 && 
              <div className='firstPopupSecondTab'>
                {userHasPass ?
                  <div className='inputcontainerForFirstPopup'>
                    <Input6
                      label="Enter Password"
                      style={{width:"250px"}}
                      value={firstPopupPassword}
                      onChange={(e: any) => setFirstPopupPassword(e.target.value)}
                    />
                    <Link to="/customerLogin/forgotPassword" className="fp-span">
                      <p>
                          Forgot Password
                      </p>
                    </Link>
                  </div>
                  :
                  <div className='inputcontainerForFirstPopup'>
                    <Input6
                        label="Enter OTP"
                        style={{width:"250px"}}
                        value={firstPopupOtp}
                        onChange={(e: any) => setFirstPopupOtp(e.target.value)}
                      /> 
                    <Input6
                      label="New Password"
                      style={{width:"250px"}}
                      value={firstPopupNewPassword}
                      onChange={(e: any) => setFirstPopupNewPassword(e.target.value)}
                    />
                    <Input6
                      label="Confirm Password"
                      style={{width:"250px"}}
                      value={firstPopupConfirmPassword}
                      onChange={(e: any) => setFirstPopupConfirmPassword(e.target.value)}
                    />
                    <Link to="/customerLogin/forgotPassword" className="fp-span">
                      <p>
                          Forgot Password
                      </p>
                    </Link>
                  </div>
                }
              </div>
            } */}
                    </DialogContent>
                    <DialogActions>
                        <div className='onLinePkgbtnMobile'>
                            {/* {senderOptIn !== "" && firstPopupTabs === 0 &&
                <ButtonPrimary
                className=""
                onClick={() => {
                  if(senderOptIn === "Sender" || senderOptIn === "Reciever"){
                    if(!userHasPass && senderOptIn === "Sender"){
                      sendCustomerOTP({contact : BranchData?.docket?.sender?.contact})
                    }
                    else if(!userHasPass && senderOptIn === "Reciever"){
                      sendCustomerOTP({contact : BranchData?.docket?.receiver?.contact})
                    }
                    setFirstPopupTabs(firstPopupTabs+1)
                  }
                  else{
                    openInNewTab('https://taptap.in/customer')
                  }
                }}
                >
                {senderOptIn === "Sender" || senderOptIn === "Reciever" ? "Proceed" : "Register Now"}
                </ButtonPrimary>
              }
              {firstPopupTabs === 1 &&
                <div className='firstPopupBtnsDiv'>
                  <ButtonDefault
                  className=""
                  onClick={() => {setFirstPopupTabs(0)}}
                  >                
                  Back
                  </ButtonDefault>
                  {userHasPass ? 
                    <ButtonPrimary
                    className=""
                    onClick={handleLogin}
                    >
                    Sign In
                    </ButtonPrimary>
                  :
                    <ButtonPrimary
                    className=""
                    onClick={handleSignUp}
                    >
                    Sign Up
                    </ButtonPrimary>
                  }
                </div>
              } */}
                            <ButtonDefault
                                className="button-danger-secondary fs-popup-btn"
                                onClick={() => { setChatPopup(false) }}
                            >
                                close
                            </ButtonDefault>
                        </div>
                    </DialogActions>
                </Dialog>
                {/* Outside Tracking First Popup Ended */}

                {/* Door Delivery Voucher Popup Started */}
                <Dialog
                    open={printDoorDelVoucher}
                    onClose={() => { setPrintDoorDelVoucher(false) }}
                >
                    <DialogContent>
                        {printDoorDelVoucher && (
                            <div id="door_delivery_voucher_print">
                                <DoorDeliveryVoucher
                                    data={BranchData}
                                />
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <ButtonPrimary style={{ height: 38 }} onClick={rePrintVoucher}>
                            Print
                        </ButtonPrimary>
                    </DialogActions>
                </Dialog>
                {/* Door Delivery Voucher Popup Ended */}

            </div>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    sub: state.user.loginType,
    userDetails: state.user,
    entity:
        state.user.loginType === "B"
            ? state.user.opBranch._id
            : state.user.opFleet._id,
    customerTracking: state.customerBooking.tracking,
    user: state.customerLoginReducer,
    userInfo: state.user,
    customerChatHistory: state.customerChatHistory,
    customerTrackingData: state.customerTracking,
    branchListData: state.branch.filteredBranches.data.Branches,
    bookingReducer: state.booking,
})

const mapDispatchToProps = {
    getCustomerDocketLocation,
    customerLogin,
    setCustomerBookingTracking,
    ResetCustomerTracking,
    showLoader,
    hideLoader,
    setDoorDelivery,
    resetDoorDelivery
}

export default connect(mapStateToProps, mapDispatchToProps)(BranchTrackingView)