import { Action } from "redux";
import { HIDE_LOADER, SHOW_LOADER } from "../constants/booking";

const globalInitialState = {
  loading: false,
};

export const globalReducer = (state = globalInitialState, action: Action) => {
  switch (action.type) {
    case SHOW_LOADER:
      return {
        ...state,
        loading: true,
      };
    case HIDE_LOADER:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
