import React from "react";
import { connect } from "react-redux";
import { handleSoundStop } from "../../actions/UserActions";
import PlaySound from "./sound";

const PlaySoundComp = (props) => {
  const handleSongFinishedPlaying = () => {
    props.handleSoundStop();
  };

  return (
    <div>
      <PlaySound
        handleSongFinishedPlaying={handleSongFinishedPlaying}
        soundStatus={props.soundStatus}
        // handleSongPlaying={this.handleSongPlaying}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    soundStatus: state.user.soundStatus,
  };
};

const mapDispatchToProps = {
  handleSoundStop,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaySoundComp);
