import { ServiceMaker } from "./index";

export type ChargeSlab = {
  min: number;
  max: number;
  intraState: number;
  interState: number;
};
export type DeliveryChargeSlab = {
  min: number;
  max: number;
  interState: number;
  intraState: number;
};
export type IInsuranceSettings = {
  valueChargeEnabled: boolean;
  minValueCharge: number;
  perPackageValueCharge: number;
  insuredCompanies: string[];
};
export interface ISettings extends IInsuranceSettings {
  handlingChargeSlab: ChargeSlab[];
  builtyChargeSlab: ChargeSlab[];
  deliveryChargesSlab: DeliveryChargeSlab[];
  smsOperator?: string;
  phoneVerificationSetting?:boolean
}
export const getSettingsService = () => {
  return ServiceMaker<ISettings>(`settings/all`, "GET");
};
export const updateSettingsService = (payload: ISettings) => {
  return ServiceMaker<ISettings>(`settings/all`, "POST", payload);
};
export const setSettingsSizes = (data: any) =>
  ServiceMaker(`settings/sizes`, "PATCH", data);
