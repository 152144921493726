import axios from "axios";
import { ServiceMaker } from ".";

export const getImageService = async (url: any) => {
  try {
    let result = await axios.get(url);
    return result;
  } catch (err : any) {
    console.log(err);
    throw err;
  }
};

export const getSignedUrl = async (url: string) =>
  ServiceMaker(`verification/signedURL/${url}`, "GET");

export const calculateIntermediateCharge = (
  qty: any,
  tAmount: any,
  tRate: any,
  fromBranch: any,
  toBranch: any
) => {
  console.log("qty", qty);
  console.log("tAmount", tAmount);
  console.log("tRate", tRate);
  console.log("fromBranch", fromBranch);
  console.log("toBranch", toBranch);
  if (
    !toBranch.intermediateCharges ||
    toBranch.intermediateCharges.length === 0 ||
    !toBranch.viaBranch ||
    !fromBranch.address ||
    !toBranch.address ||
    fromBranch.address.city.toString() === toBranch.address.city.toString() ||
    fromBranch._id.toString() === toBranch.viaBranch._id.toString()
  ) {
    return 0;
  }
  const intermediateSlab = toBranch.intermediateCharges;
  const onePkgAmt = tRate=== "Direct" ? tAmount / qty : tAmount;
  let result = 0;
  let slabFound = false;
  for (let i = intermediateSlab.length - 1; i >= 0; i--) {
    if (intermediateSlab[i].minAmount <= onePkgAmt) {
      result = intermediateSlab[i].charge;
      slabFound = true;
      break;
    }
  }
  if (!slabFound) {
    result = intermediateSlab[intermediateSlab.length - 1].charge;
  }
  return result * qty;
};

export type ChargeSlab = {
  min: number;
  max: number;
  intraState: number;
  interState: number;
}

export const calculateDeliveryCharge = (
  packages: {
    rate: "Per package" | "Direct" | "Per Kg";
    amount: number;
    qty: number;
    weight: number;
  }[],
  across: "intraState" | "interState",
  deliveryChargeSlab?: ChargeSlab[],
  
) => {
  if (!deliveryChargeSlab || deliveryChargeSlab.length === 0) {
    return 0
  }
  if (across !== "interState" && across !== "intraState") {
    return 0
  }
  const slabLen = deliveryChargeSlab.length
  let result = 0
  packages.forEach((p) => {
    let { rate, amount, qty, weight } = p
    let perPackAmt =
      rate === "Per package"
        ? amount
        : rate === "Per Kg"
        ? amount * weight
        : amount / qty
    let slabFound = false
    for (let i = slabLen - 1; i >= 0; i--) {
      if (deliveryChargeSlab[i].min <= perPackAmt) {
        result += deliveryChargeSlab[i][across] * qty
        slabFound = true
        break
      }
    }
    if (!slabFound ) {
      result += deliveryChargeSlab[slabLen - 1][across] * qty
    }
  })
  return result
}
export const calculateFixDeliveryCharge=(packages: {
  rate: "Per package" | "Direct" | "Per Kg";
  amount: number;
  qty: number;
  weight: number;
  fixRate:{
    _id:string;
    deliveryCharges:number;
  }
}[],deliveryCharge:number)=>{
  let result = 0
  packages.forEach((p) => {
    let {  qty } = p
        result += deliveryCharge * qty
  })
  return result
}