import { ServiceMaker,  } from "./index";

export const getShortageData = (data:any)=>
ServiceMaker("verification/shortage/unverified","POST",data)

export const getShortageCount = (data?:any)=>
ServiceMaker("verification/shortage/unverifiedcount","POST",data)

export const verifyShortageData = (data:any)=>
ServiceMaker("verification/verifySettledAmount","PATCH",data)

export const rejectShortageData = (data:any)=>
ServiceMaker("verification/refuteSettledAmount","PATCH",data)