import { TextField, FormHelperText } from "@mui/material";
import React from "react";
import Grid from "../Grid/Grid";
import "./Input.css";
interface IpropsInput {
  type?: any;
  placeholder?: any;
  value?: any;
  onChange?: any;
  label?: any;
  endAdornment?: any;
  disabled?: any;
  margin?: any;
  readOnly?: any;
  multiline?: boolean;
  rows?: number;
  noHeight?: boolean;
  style?: React.CSSProperties;
  error?: any;
  correct?: any;
  message?: any;
  message1?: any;
  width?: any;
  className?: any;
  autoComplete?: any;
  onBlur?: any;
  id?: any;
  onFocus?: any;
  list?: any;
  inputRef?: any;
  startAdornment?: any;
  helperText?: any;
  datatestid?: any;
  onKeyDown?: any;
  autoFocus?: any;
  onInput?: any;
  disableUnderline?: any;
  blurred?: any;
  role?: any;
  padding?: any;
  name ?: any;
  defaultValue?:any;
}

export function Input(props: IpropsInput) {
  return (
    <div style={{ padding: "5px" }}>
      <TextField
        id={props.id}
        onInput={props.onInput}
        autoFocus={props.autoComplete}
        variant="outlined"
        fullWidth
        defaultValue={props.defaultValue}
        label={props.label}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        inputProps={{
          style: { height: props.noHeight ? "16px" : "3px" },
        }}
        InputProps={{
          readOnly: props.readOnly ? true : false,
          endAdornment: props.endAdornment,
          startAdornment: props.startAdornment,
          //    disableUnderline: true,

          inputProps: {
            list: props.list,
            "data-testid": props.datatestid,
          },
        }}
        style={{ zIndex: 0, ...props.style }}
        margin={props.margin}
        disabled={props.disabled}
        multiline={props.multiline}
        rows={props.rows}
        autoComplete={props.autoComplete ? props.autoComplete : "nope"}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        inputRef={props.inputRef}
        helperText={props.helperText}
        onKeyDown={props.onKeyDown}
        role={props.role}
        name={props.name}
      />
      {props.error && props.message ? (
        <FormHelperText style={{ color: "#f44336" }}>
          {props.message}
        </FormHelperText>
      ) : null}
    </div>
  );
}
export function ExtraSmallInput(props: IpropsInput) {
  return (
    <div
      className="small-input"
      style={{ width: props.width ? props.width : "50px" }}
    >
      <TextField
        id={props.id}
        onInput={props.onInput}
        autoFocus={props.autoComplete}
        variant="outlined"
        fullWidth
        label={props.label}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        inputProps={{
          style: { height: props.noHeight ? "16px" : "3px" },
        }}
        InputProps={{
          readOnly: props.readOnly ? true : false,
          endAdornment: props.endAdornment,
          startAdornment: props.startAdornment,
          inputProps: {
            list: props.list,
            "data-testid": props.datatestid,
          },
        }}
        style={{ zIndex: 0, ...props.style }}
        margin={props.margin}
        disabled={props.disabled}
        multiline={props.multiline}
        rows={props.rows}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        inputRef={props.inputRef}
        helperText={props.helperText}
        onKeyDown={props.onKeyDown}
      />
      {props.error && props.message ? (
        <FormHelperText style={{ color: "#f44336" }}>
          {props.message}
        </FormHelperText>
      ) : null}
    </div>
  );
}

export function Input1(props: IpropsInput) {
  return (
    <Grid lg={1} md={1} sm={12} xs={12} style={{ padding: "5px" }}>
      <TextField
        id={props.id}
        onInput={props.onInput}
        autoFocus={props.autoComplete}
        variant="outlined"
        fullWidth
        label={props.label}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        inputProps={{
          style: { height: props.noHeight ? "16px" : "3px" },
        }}
        InputProps={{
          readOnly: props.readOnly ? true : false,
          endAdornment: props.endAdornment,
          startAdornment: props.startAdornment,
          inputProps: {
            list: props.list,
            "data-testid": props.datatestid,
          },
        }}
        style={{ zIndex: 0, ...props.style }}
        margin={props.margin}
        disabled={props.disabled}
        multiline={props.multiline}
        rows={props.rows}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        //rowsMax={props.rowsMax}
        inputRef={props.inputRef}
        helperText={props.helperText}
        onKeyDown={props.onKeyDown}
      />
      {props.error && props.message ? (
        <FormHelperText style={{ color: "#f44336" }}>
          {props.message}
        </FormHelperText>
      ) : null}
    </Grid>
  );
}

export function Input2(props: IpropsInput) {
  return (
    <Grid lg={2} md={2} sm={12} xs={12} style={{ padding: "5px" }}>
      <TextField
        id={props.id}
        onInput={props.onInput}
        autoFocus={props.autoComplete}
        variant="outlined"
        fullWidth
        label={props.label}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        inputProps={{
          style: { height: props.noHeight ? "16px" : "3px" },
        }}
        InputProps={{
          readOnly: props.readOnly ? true : false,
          endAdornment: props.endAdornment,
          startAdornment: props.startAdornment,
          inputProps: {
            ...(props.padding &&{style: {padding: props.padding}}),
            list: props.list,
            "data-testid": props.datatestid,
          },
        }}
        style={{ zIndex: 0, ...props.style }}
        margin={props.margin}
        disabled={props.disabled}
        multiline={props.multiline}
        rows={props.rows}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        inputRef={props.inputRef}
        helperText={props.helperText}
        onKeyDown={props.onKeyDown}
      />
      {props.error && props.message ? (
        <FormHelperText style={{ color: "#f44336" }}>
          {props.message}
        </FormHelperText>
      ) : null}
    </Grid>
  );
}
export function Input3(props: IpropsInput) {
  return (
    <Grid lg={3} md={3} sm={12} xs={12} style={{ padding: "5px" }}>
      <TextField
        id={props.id}
        onInput={props.onInput}
        autoFocus={props.autoComplete}
        variant="outlined"
        fullWidth
        label={props.label}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        inputProps={{
          style: { height: props.noHeight ? "16px" : "3px" },
        }}
        InputProps={{
          readOnly: props.readOnly ? true : false,
          endAdornment: props.endAdornment,
          startAdornment: props.startAdornment,
          inputProps: {
            list: props.list,
            "data-testid": props.datatestid,
          },
        }}
        style={{ zIndex: 0, ...props.style }}
        margin={props.margin}
        disabled={props.disabled}
        multiline={props.multiline}
        rows={props.rows}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        inputRef={props.inputRef}
        helperText={props.helperText}
        onKeyDown={props.onKeyDown}
        className={props.className}
      />
      {props.error && props.message ? (
        <FormHelperText style={{ color: "#f44336" }}>
          {props.message}
        </FormHelperText>
      ) : null}
    </Grid>
  );
}
export function Input4(props: IpropsInput) {
  return (
    <Grid lg={4} md={4} sm={12} xs={12} style={{ padding: "5px" }}>
      <TextField
        id={props.id}
        onInput={props.onInput}
        autoFocus={props.autoComplete}
        variant="outlined"
        fullWidth
        label={props.label}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        inputProps={{
          style: { height: props.noHeight ? "16px" : "3px" },
        }}
        InputProps={{
          readOnly: props.readOnly ? true : false,
          endAdornment: props.endAdornment,
          startAdornment: props.startAdornment,
          inputProps: {
            list: props.list,
            "data-testid": props.datatestid,
          },
        }}
        style={{ zIndex: 0, ...props.style }}
        margin={props.margin}
        disabled={props.disabled}
        multiline={props.multiline}
        rows={props.rows}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        inputRef={props.inputRef}
        helperText={props.helperText}
        onKeyDown={props.onKeyDown}
      />
      {props.error && props.message ? (
        <FormHelperText style={{ color: "#f44336" }}>
          {props.message}
        </FormHelperText>
      ) : null}
    </Grid>
  );
}
export function Input6(props: IpropsInput) {
  return (
    <Grid lg={6} md={6} sm={12} xs={12} style={{ padding: "5px" }}>
      <TextField
        id={props.id}
        onInput={props.onInput}
        autoFocus={props.autoComplete}
        variant="outlined"
        fullWidth
        label={props.label}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        inputProps={{
          style: { height: props.noHeight ? "16px" : "3px" },
        }}
        InputProps={{
          readOnly: props.readOnly ? true : false,
          endAdornment: props.endAdornment,
          startAdornment: props.startAdornment,
          inputProps: {
            list: props.list,
            "data-testid": props.datatestid,
          },
        }}
        style={{ zIndex: 0, ...props.style }}
        margin={props.margin}
        disabled={props.disabled}
        autoComplete={props.autoComplete}
        multiline={props.multiline}
        rows={props.rows}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        inputRef={props.inputRef}
        helperText={props.helperText}
        onKeyDown={props.onKeyDown}
        className={props.className}
      />
      {props.error && props.message ? (
        <FormHelperText style={{ color: "#f44336" }}>
          {props.message}
        </FormHelperText>
      ) : null}
      {props.blurred && props.error === null && props.correct ? (
        <FormHelperText style={{ color: "#8FBC8F" }}>
          {props.correct}
        </FormHelperText>
      ) : null}
    </Grid>
  );
}

export function Input12(props: IpropsInput) {
  return (
    <Grid lg={12} md={12} sm={12} xs={12} style={{ padding: "5px" }}>
      <TextField
        id={props.id}
        onInput={props.onInput}
        variant="outlined"
        fullWidth
        label={props.label}
        autoFocus={props.autoComplete}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        inputProps={{
          style: { height: props.noHeight ? "16px" : "3px" },
        }}
        InputProps={{
          readOnly: props.readOnly ? true : false,
          endAdornment: props.endAdornment,
          startAdornment: props.startAdornment,
          inputProps: {
            list: props.list,
            "data-testid": props.datatestid,
          },
        }}
        style={{ zIndex: 0, ...props.style }}
        margin={props.margin}
        disabled={props.disabled}
        autoComplete={props.autoComplete}
        multiline={props.multiline}
        rows={props.rows}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        inputRef={props.inputRef}
        helperText={props.helperText}
        onKeyDown={props.onKeyDown}
      />
      {props.error && props.message ? (
        <FormHelperText style={{ color: "#f44336" }}>
          {props.message}
        </FormHelperText>
      ) : null}
    </Grid>
  );
}

interface Ipropscomman {
  value: any;
  onChange: any;
  style?: React.CSSProperties;
}

export function Searchinput(props: Ipropscomman) {
  return (
    <Input1
      placeholder="Search"
      value={props.value}
      onChange={props.onChange}
    />
  );
}

interface IpropsInputOutlined {
  type?: any;
  placeholder?: any;
  value?: any;
  onChange?: any;
  label?: any;
  style?: React.CSSProperties;
}

export function InputOutlined(props: IpropsInputOutlined) {
  return (
    <Grid md={6} xs={6} lg={6} item style={props.style}>
      <TextField
        variant="outlined"
        placeholder={props.placeholder}
        type={props.type}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        style={{ float: "right" }}
      />
    </Grid>
  );
}
export function MaxamountInput(props: Ipropscomman) {
  return (
    <InputOutlined
      style={props.style}
      label="Max Amount"
      type="number"
      value={props.value}
      onChange={props.onChange}
    />
  );
}

export function MinamountInput(props: Ipropscomman) {
  return (
    <InputOutlined
      style={props.style}
      label="Min Amount"
      type="number"
      value={props.value}
      onChange={props.onChange}
    />
  );
}
