import React from "react";
import "./radio.css"

interface IRadio {
    value?: string;
    color?: string; // primary, secondary, success, default
    name?: string;
    checked?: boolean;
    onClick?: any;
    onChange?: any;
    style?: any;
}

export const Radio = (props : IRadio) => {
    return (
        <span className={`${props.color ? `${props.color} ` : "primary " }${props.checked ? "checked " : ""}radio`} style={{...props.style}}>
            <input className="radio-input" name={props.name} type="radio" value={props.value} onClick={(e:any) => props.onClick ? props.onClick(e) : {}} onChange={(e:any) => props.onChange ? props.onChange(e) : {}} style={{margin: "0"}} />
            <span className="radio-svg">
                <svg className="css-q8lw68" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="RadioButtonUncheckedIcon">
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
                </svg>
                <svg className={`${props.checked ? "css-1u5ei5s" : "css-1c4tzn"}`} focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="RadioButtonCheckedIcon">
                    <path d="M8.465 8.465C9.37 7.56 10.62 7 12 7C14.76 7 17 9.24 17 12C17 13.38 16.44 14.63 15.535 15.535C14.63 16.44 13.38 17 12 17C9.24 17 7 14.76 7 12C7 10.62 7.56 9.37 8.465 8.465Z"></path>
                </svg>
            </span>
            <span className="css-w0pj6f"></span>
        </span>
    )
}

interface IFormControlLabel {
    value?: string;
    label?: any;
    onClick?: any;
    onChange?: any;
    control?: any;
    labelPlacement?: string; // top, start, end, bottom
    className?: any;
    style?: any;
}

export const FormControlLabel = (props : IFormControlLabel) => {
    return (
        <label className={`${props.labelPlacement ? `labelPlacement${props.labelPlacement}` : "labelPlacementend"} ${props.className && props.className}`} onClick={(e:any) => props.onClick ? props.onClick(e) : {}} onChange={(e:any) => props.onChange ? props.onChange(e) : {}} style={{...props.style}}>
            {props.control}
            <span className="css-9l3uo3">{props.label}</span>
        </label>
    )
}

interface IRadioGroup {
    value?: string;
    name?: string;
    ariaLabel?: string;
    onClick?: any;
    onChange?: any;
    children?: any;
    row?: any;
    className?: any;
    style?: any;
    classes ?: any;
}

export const RadioGroup = (props : IRadioGroup) => { 
    return (
        <div className={`${props.row && `radioGroupRow `}radioGroup ${props.className && props.className}`} role="radiogroup" aria-label={props.ariaLabel} onClick={(e:any) => props.onClick ? props.onClick(e) : {}} onChange={(e:any) => props.onChange ? props.onChange(e) : {}} style={{...props.style}}>
            {props.children}
        </div>
    )
}