import { fromJS } from "immutable";
import { NEW_ROUTE_ADD_DESTINATION, NEW_ROUTE_CHANGE_DESTINATION, NEW_ROUTE_CHANGE_SIMPLE, NEW_ROUTE_CLEAR_ROUTE, NEW_ROUTE_REMOVE_DESTINATION, NEW_ROUTE_SET_ROUTE } from "../constants/newRoute";
import { newRouteValidation } from "./schemas/newRoute";

const initState : any = fromJS({
  isEditing: false,
  routeName: "",
  distance: "",
  active: true,
  departureTime : {
    h : 0,
    m : 0,
  },
  arrivalTime : {},
  originBranch : {},
  destBranch : {},
  updatedAt : "",
  createdAt : "",
  _id : "",
  updatedBy : {
    _id : ""
  },
  company : {},
  modeOfTransport: {},

//   serviceType: "",
//   subServiceType: "",
//   name: "",
//   modeOfPayment: [],
//   TransportSubType: "",
//   working: {
//     sun: false,
//     mon: false,
//     tue: false,
//     wed: false,
//     thu: false,
//     fri: false,
//     sat: false,
//   },
//   constraints: {
//     perPackageVolume: {
//       l: "",
//       b: "",
//       h: "",
//     },
//     perPackageWeight: "",
//   },
//   capacity: {
//     weight: "",
//     volume: "",
//   },
//   // packageRates:[],
//   insurance: false,
//   collections: false,
//   fragile: false,
//   unstackable: false,
//   hazardous: false,
//   denominator: "",
//   minChargeWeight: "",
  serviceSubType :null,
  stops: [
    {
      id: null,
      company: "",
      city: "",
      branch: "",
      arrivalTime: {
        h: 0,
        m: 0,
      },
      noOfDays: 0,
      departureTime: {
        h: 0,
        m: 0,
      },
      loading: false,
      unloading: false,
    },
  ],
  errors: {
    routeType: null,
    serviceType: null,
    subServiceType: null,
    name: null,
    modeOfTransfer: null,
    TransportSubType: null,
    modeOfPayment: null,
    working: {
      sun: null,
      mon: null,
      tue: null,
      wed: null,
      thu: null,
      fri: null,
      sat: null,
    },
    constraints: {
      perPackageVolume: {
        l: null,
        b: null,
        h: null,
      },
      perPackageWeight: null,
    },
    capacity: {
      weight: null,
      volume: null,
    },
    insurance: null,
    collections: null,
    fragile: null,
    unstackable: null,
    hazardous: null,
    denominator: null,
    minChargeWeight: null,

    destination: [
      {
        id: null,
        company: null,
        city: null,
        branch: null,
        cutOffTime: {
          h: null,
          m: null,
        },
        noOfDays: null,
        deliveryTime: {
          h: null,
          m: null,
        },
        loading: null,
        unloading: null,
      },
    ],
  },
});

const dest = fromJS({
  id: null,
  city: null,
  branch: null,
  company: null,
  arrivalTime: {
    h: 0,
    m: 0,
  },
  noOfDays: 0,
  departureTime: {
    h: 0,
    m: 0,
  },
  loading: false,
  unloading: false,
});

export default function(
  state = initState,
  action: { type: any; payload: any }
) {
  // console.log(`first attempt from Reducers Genreal ca;;ed`,action , `state` , state)
  switch (action.type) {
    case NEW_ROUTE_CHANGE_SIMPLE: {
      const { what, value } : any = action.payload;
      try {
        if (newRouteValidation.getIn([...what]))
          newRouteValidation.getIn([...what]).validateSync(value);
        if (what[0] == "name") {
          return initState.setIn([...what], value).setIn(["isEditing"], false);
        }
        return state.setIn([...what], value).setIn(["errors", ...what], null);
      } catch (err:any) {
        return state
          .setIn([...what], value)
          .setIn(["errors", ...what], err.errors[0]);
      }
    }

    case NEW_ROUTE_CHANGE_DESTINATION: {
      console.log(`first attempt from Reducers`,action.payload);
      const { index, what, value } = action.payload;
      console.log(`first attempt from Reducers`,index,what,value);
      console.log(`first attempt from Reducers NEW_ROUTE_CHANGE_DESTINATION ca;;ed`)
      const destination = state
        .get("stops")
        .update(index, (dest: any) => {
          return dest.setIn([...what], value);
        });
      //const destinationErrors =
      return state.setIn(["stops"], destination); //.setIn(['errors','destinations'],destinationErrors)
    }

    case NEW_ROUTE_ADD_DESTINATION: {
      const destination2 = state.get("stops").push(dest);
      return state.set("stops", destination2);
    }

    case NEW_ROUTE_REMOVE_DESTINATION: {
      return state.set(
        "stops",
        state.get("stops").delete(action.payload.index)
      );
    }

    case NEW_ROUTE_CLEAR_ROUTE: {
      return initState;
    }

    case NEW_ROUTE_SET_ROUTE: {
      return fromJS({ ...action.payload.route, isEditing: true });
    }

    // case ADD_PACK_SIZE: {
    //     return state.update('packageRates', packageRates => (packageRates || List()).push(fromJS({
    //       name: '',
    //       l: null,
    //       b: null,
    //       h: null,
    //       unit: 'cm',
    //       weight: null,
    //       rate: null
    //     })))
    // }
    // case DELETE_PACK_SIZE : {
    //     return state.update('packageRates', packageRates => packageRates.delete(action.payload))
    // }

    default:
      return state;
  }
}
