

import axios from "axios";
import { getKey, subscribeKey } from "./services/home";

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
}

export async function subscriptionNotification() {
  try {
    if ("serviceWorker" in navigator) {
      const registration = await navigator.serviceWorker.ready;

      if (!registration.pushManager) {
        console.log("Push manager unavailable.");
        return;
      }

      const existedSubscription = await registration.pushManager.getSubscription();
      let key = await getKey();
      console.log(key, "key");

      if (existedSubscription === null || key !== localStorage.getItem("pubKey")) {
        console.log("No subscription detected or key changed, make a request.");
        localStorage.removeItem("pubKey");
        console.log(key, "12345678");

        try {
          let newSubscription = null;
          localStorage.setItem("pubKey", key);
          if (existedSubscription) {
            console.log("Unsubscribing existing subscription...");
            await existedSubscription.unsubscribe();
          }

          newSubscription = await registration.pushManager.subscribe({
            applicationServerKey: urlBase64ToUint8Array(key),
            userVisibleOnly: true,
          });
          // localStorage.removeItem("deviceId");
          console.log("Calling subscribeKey API...");
          const payload = {
            subscription: newSubscription,
            deviceId: localStorage.getItem("deviceId"),
            publicKey: localStorage.getItem("pubKey")
          }

          const res = await subscribeKey(payload);
          if (!localStorage.getItem("deviceId")) {
            // payload.deviceId = localStorage.getItem("deviceId")
            localStorage.setItem("deviceId", res.deviceId);
          }
          console.log("subscribeKey API response:", res);
          return res;
        } catch (subscribeError) {
          console.error("Error subscribing:", subscribeError);
        }
      } else {
        console.log("Existed subscription detected. Calling subscribeKey API...");
        // localStorage.removeItem("pubKey");
        // localStorage.setItem("pubKey", key);
        const payload = {
          subscription: existedSubscription,
          deviceId: localStorage.getItem("deviceId"),
          publicKey: localStorage.getItem("pubKey")
        }
        const res = await subscribeKey(payload);
        if (!localStorage.getItem("deviceId")) {
          // payload.deviceId = localStorage.getItem("deviceId")
          localStorage.setItem("deviceId", res.deviceId);
        }
        console.log("subscribeKey API response:", res);
        return res;
      }
    }
  } catch (error) {
    console.error("An error occurred:", error);
  }
}

export async function updateSubscriptionNotification() {
  try {
    if ("serviceWorker" in navigator) {
      const registration = await navigator.serviceWorker.ready;

      if (!registration.pushManager) {
        console.log("Push manager unavailable.");
        return;
      }

      let key = await getKey();
      const existedSubscription = await registration.pushManager.getSubscription();
      if (existedSubscription) {
        console.log("Unsubscribing existing subscription...");
        await existedSubscription.unsubscribe();
      }
      else {
        if (key !== localStorage.getItem("pubKey")) {
          console.log("No subscription detected or key is missing, making a request.");

          try {
            // Fetch the key only if it's not present
            if (key === null) {
              localStorage.setItem("pubKey", key);
              console.log(key, "12345678");
            }
            let newSubscription = null;

            // Unsubscribe existing subscription if it exists

            // Subscribe with the new key
            newSubscription = await registration.pushManager.subscribe({
              applicationServerKey: urlBase64ToUint8Array(key),
              userVisibleOnly: true,
            });

            console.log("Calling subscribeKey API...");
            const payload = {
              subscription: newSubscription,
              deviceId: localStorage.getItem("deviceId"),
              publicKey: key,
            }
            // Call subscribeKey API
            const res = await subscribeKey(payload);
            // Update deviceId in localStorage
            if (!localStorage.getItem("deviceId")) {
              localStorage.setItem("deviceId", res.deviceId);
            }
            console.log("subscribeKey API response:", res);
            return res;
          } catch (subscribeError) {
            console.error("Error subscribing:", subscribeError);
          }
        }
        // Always make a request if there is no subscription detected or the key is not present
      }
    }
  }
  catch(e){
    console.error("Error subscribing:", subscribeError);
  }
}