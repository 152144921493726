export const mobileCarouselImages = [
    "/assets/mobile-carousel-images/1.jpg",
    "/assets/mobile-carousel-images/2.jpg",
    "/assets/mobile-carousel-images/3.jpg",
    "/assets/mobile-carousel-images/4.jpg",
    "/assets/mobile-carousel-images/5.jpg",
];

export const intarCityMobileCarouselImages = [
    "/assets/banners/intra-city/mobile-1.jpg",
    "/assets/banners/intra-city/mobile-2.jpg",
    "/assets/banners/intra-city/mobile-3.jpg",
];

export const partLoadMobileCarouselImages = [
    "/assets/banners/part-load/mobile-1.jpg",
    "/assets/banners/part-load/mobile-2.jpg",
    "/assets/banners/part-load/mobile-3.jpg",
];

export const fullLoadMobileCarouselImages = [
    "/assets/banners/full-load/mobile-1.jpg",
    "/assets/banners/full-load/mobile-2.jpg",
    "/assets/banners/full-load/mobile-3.jpg",
];

export const landingCarouselImages = [
    "https://i.ibb.co/30qV5pz/truck-delivery.gif",
    "https://i.ibb.co/DVf09ms/truck.gif",
    "https://i.ibb.co/pJchrgg/for-dribbble-v01.gif",
]