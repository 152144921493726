import chroma from "chroma-js";

const colorScheme = [
  { min: 95, color: "#61b33b" },
  { min: 90, max: 94, color: "orange" },
  { max: 89, color: "red" },
];
export const getAvgColor = (avg: any) => {
  const defaultColor = "#98FB3D";
  for (const scheme of colorScheme) {
    if (scheme.min && scheme.min <= avg && scheme.max && scheme.max >= avg) {
      return scheme.color;
    }
    if (!scheme.max && scheme.min && scheme.min <= avg) {
      return scheme.color;
    }
    if (!scheme.min && scheme.max && scheme.max >= avg) {
      return scheme.color;
    }
  }
  return defaultColor;
};

export const shimmerStyle = (color: any, direction: any) => ({
  display: "inline-block",
  animation: "shimmerAnim 2s infinite linear",
  background: String(color),
  backgroundSize: "800px 104px",
  backgroundImage: `linear-gradient(to ${direction},${String(
    color
  )} 50%,${chroma(color)
    .brighten()
    .hex()} 50%,${String(color)} 60%)`,
  backgroundRepeat: "no-repeat",
  "@keyframes shimmerAnim": {
    "0%": {
      backgroundPosition: "-468px 0",
    },
    "100%": {
      backgroundPosition: "468px 0",
    },
  },
});
