import React from 'react'
import { useState } from 'react';
import Loader from "@mui/material/CircularProgress";
import { useEffect } from 'react';
import PackageForm from './PackageForm';
import HowItWorks from '../../../Components/How It Works/HowItWorks';
import FAQ from '../../../Components/FAQ/FAQ';
import Testimonials from "../../../Components/Tetimonials/Testimonials";
import OurFeatures from "../../../Components/Features/OurFeatures";
import { useMediaQuery, useTheme } from "@mui/material";

const InterNationalForm = () => {

  const [serviceType, setServiceType] = useState("international courier");
  const [sourcePincode, setSourcePincode] = useState("");
  const [destinationPincode, setDestinationPincode] = useState("");
  const [loading] = useState(false);
  const [result] = useState([]);
  const [sourceCountry,setSourceCountry] = useState("")
  const [destinationCountry,setDestinationCountry] = useState("")
  const [internatoinalSourceCity,setInternatoinalSourceCity] = useState("")
  const [internatoinalDestinationCity,setInternatoinalDestinationCity] = useState("")
  const [formDetails,setFomrDetails] = useState(false)
  const [packageCount,setPackageCount] = useState([{}])
  const [date, setDate] = useState("");  
  const [tabActive,setTabActive] = useState(0);

  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2
  
  const topFunction2 = (props:any)=>{
    document.body.scrollTop = 0;
    {props === "book" ? document.documentElement.scrollTop = 0 : props === "feature" ? document.documentElement.scrollTop = 515 : props === "how" ? document.documentElement.scrollTop = 1040 :
    props === "test" ? document.documentElement.scrollTop = 1575 : props === "faq's" ? document.documentElement.scrollTop = 2100 : 0 }
  } 

  useEffect(() => {
    setPackageCount([{}])
  }, [setFomrDetails]);

  useEffect(() => {
    setDate("")
    setSourceCountry("")
    setInternatoinalSourceCity("")
    setSourcePincode("")
    setDestinationCountry("")
    setInternatoinalDestinationCity("")
    setDestinationPincode("")
    setFomrDetails(false)
  }, [serviceType])

  const handleResults = () => {
    if (serviceType === "international courier") {
      if (sourceCountry === "" || destinationCountry === "" || internatoinalSourceCity === "" || internatoinalDestinationCity === "" ||
        sourcePincode === "" || destinationPincode === "" || date === "") {
        alert("Kindly Fill all the Details !")
      }
      else {
        console.log({
          "Type of Form": `${serviceType} Service`,
          "Source Country": sourceCountry,
          "Destination Coutry": destinationCountry,
          "International Source City": internatoinalSourceCity,
          "International Destination City": internatoinalDestinationCity,
          "Source Pincode": sourcePincode,
          "Destination Pincode": destinationPincode,
          "Package Details": packageCount
        })
      }
      setSourceCountry("")
      setDestinationCountry("")
      setInternatoinalSourceCity("")
      setInternatoinalDestinationCity("")
      setSourcePincode("")
      setDestinationPincode("")
      setPackageCount([{}])
      setFomrDetails(false)
    }
    if (serviceType === "international shipping") {
      if (sourceCountry === "" || destinationCountry === "" || internatoinalSourceCity === "" || internatoinalDestinationCity === "" ||
        sourcePincode === "" || destinationPincode === "") {
        alert("Kindly Fill all the Details !")
      }
      else {
        console.log({
          "Type of Form": `${serviceType} Service`,
          "Source Country": sourceCountry,
          "Destination Coutry": destinationCountry,
          "International Source City": internatoinalSourceCity,
          "International Destination City": internatoinalDestinationCity,
          "Source Pincode": sourcePincode,
          "Destination Pincode": destinationPincode,
          "Package Details": packageCount
        })
      }
      setSourceCountry("")
      setDestinationCountry("")
      setInternatoinalSourceCity("")
      setInternatoinalDestinationCity("")
      setSourcePincode("")
      setDestinationPincode("")
      setPackageCount([{}])
      setFomrDetails(false)
    }
  }

  const International = {
    "HowItWorks": [{ headings: ["Fill in your data", "Get an instant quote", "Book a container"] },
    {
      img: ["how-it-works-img1.png",
        "how-it-works-img2.png",
        "how-it-works-img3.png"]
    }],
    "Features": [
      {
        img: ["https://img.icons8.com/bubbles/80/000000/cash-in-hand.png",
          "https://img.icons8.com/bubbles/80/000000/security-checked.png",
          "https://img.icons8.com/bubbles/80/000000/security-checked.png",
          "https://img.icons8.com/bubbles/80/000000/security-checked.png",
          "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
          "https://img.icons8.com/bubbles/80/000000/conference-call.png"]
      },
      { headings: ["Wide service offering", "Multiple quotations", "Flexible transportation", "Adjustable validity dates", "Email it to anyone", "Easy administration"] },
      {
        content: ["Choose from 140+ services and 600 ports worldwide.",
          "Submit up to 10 port-pair combinations at once with our multilane feature.",
          "Select port-to-port or door-to-door – or whatever combination you need.",
          "Choose your validity date for more security down the road with our valid-from option.",
          "Send an email copy to yourself, colleagues, or customers.",
          "Access all quotations via My Quotations."]
      }],
    "FAQ": [
      {
        question: ["Do I need to register to use Quick Quotes ?",
          "Is the quote binding ?",
          "What is the standard free-time allowance ?",
          "What is the difference between Quick Quotes and Quick Quotes Spot ?",
          "How long is the rate valid ?",
          "Is it possible to extend the validity of the quotation ?",
          "Can rates on Quick Quotes be negotiated ?",
          "Does any General Rate Increase (GRI) apply ?",
          "Are the local surcharges already included in the rate? And local inland costs ?",
          "What are the charges that need to be settled at the load port along with the freight ?",
          "Can I get a quotation for reefers or non-operating reefers ?",
          "Can I get Quick Quotes for reefers with the controlled atmosphere ?"]
      },
      {
        answer: ["Yes, simply complete the registration process. We will create an account that gives you access to our Quick Quotes tool.",
          "We provide you with a fixed-rated quote that is valid for the number of days that you see in your quote.",
          "The free-time allowance for Hapag-Lloyd depends on your routing. You can find details here. If you require more leeway, you can use our Additional Freetime option to buy up to 10 extra days when you place your booking.",
          "Please check the validity of the quotation, as it may differ depending on the origin and destination. Use our “valid-from” option to select the starting date for validity.",
          "No, the validity of the offer cannot be extended. You can, however, simply submit a new Quick Quotes request to get a valid quote at a later date.",
          "Quick Quotes is an online pricing tool that gives you container shipping rates based on which you can place a booking instantly. There is no option to negotiate these rates online.",
          "Your Quick Quotes contain a fixed rate. No GRI applies during the validity of the offer. Surcharges are subject to change and are those valid at the time of the shipment.",
          "Most but not all surcharges are included. Please see local surcharges and inland costs associated with your cargo for details.",
          "All charges are either export- (origin), import- (destination), or sea-freight-related. Depending on the agreement between the shipper and consignee, the charges either have to be prepaid or collected (there might also be legal requirements in some countries).",
          "Yes, simply select the equipment type “Reefer” or “Non-operating Reefer” to receive a Quick Quote for your shipment.",
          "At this time, the reefer feature is available for fresh and frozen cargo only. It is not possible to quote controlled-atmosphere cargo. Please check our FAK definition. If you would like an offer for controlled-atmosphere reefers, please contact your local sales office.",
        ]
      }],
    "Testimonials": [{ img: [] }, { name: [] }, { possition: [] }, { company: [] },
    {
      feedback: ["I was very pleased with the service I received from Kabra Express Packers and Movers. They were very professional and courteous throughout the entire process. I was also impressed with their knowledge and expertise. They were able to handle my large moving project with ease and everything arrived at my new home safely. I would highly recommend their services to anyone in need of a packer and movers.",
        "I have availed the services of Kabra Express Packers and Movers on two occasions, and I have been highly satisfied with their service on both occasions. They are a very professional outfit, and their staff is always polite and helpful. They have the knowledge and expertise to handle any size moving project, and they place a high priority on the safety of your belongings. I would recommend their.",
        "We used Kabra Express for our move from Ahmedabad to Mumbai and we were very pleased with their service. They were very professional and efficient in packing and moving our belongings. We were very happy with how safe and secure our belongings were during the move. We would highly recommend Kabra Express to anyone looking for professional and reliable packers and movers service."
      ]
    }]
  }

  return (

    <>
      {mobileView ? (
          <>
            <div className='international'>
              <section id="intro" className="intro">
                <div className="intro-content ">
            <h1 style={{textAlign: "center", fontSize: "24px", marginBottom: "0", paddingBlock: "1rem"}}>Get Estimate</h1>

                  <div className="container mt30 ml-25Mobile">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="logo_1 mt30 mt-35">
                          <img
                            src="https://i.pinimg.com/564x/46/7f/d4/467fd489422e345c357387cb068a9f14.jpg"
                            className="img-responsive mt-55"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="row form_page">
                            <div className="bttn">
                              <div className="ownBtnMobileVersion ml-3Mobile">
                                <button className={serviceType === "international courier" ? "activeButton" : ""} onClick={()=>{setServiceType("international courier")}}> International Courier </button>
                                <button className={serviceType === "international shipping" ? "activeButton" : ""} onClick={()=>{setServiceType("international shipping")}}> International Shipping </button>
                              </div>
                            </div>

                      <div className="tab-content">
                        {serviceType === "international courier" && (
                          <div className="tab-pane active" id="my_2">
                            <div className="row">
                              <div className="col-md-6 col-sm-6 ml80Mobile">
                                <input
                                  type="text"
                                  value={sourceCountry}
                                  onChange={(eve) => {
                                    setSourceCountry(eve.target.value);
                                  }}
                                  className="form-control form_one"
                                  id="inputZip"
                                  placeholder="Enter Source Country"
                                  required
                                />
                              </div>
                              <div className="col-md-6 ml80Mobile">
                                <input
                                  type="number"
                                  value={sourcePincode}
                                  onChange={(e) => {
                                    setSourcePincode(e.target.value);
                                  }}
                                  className="form-control form_one"
                                  id="inputZip"
                                  placeholder="Enter Source Pincode"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 col-sm-6 ml80Mobile">
                                <input
                                  type="text"
                                  value={internatoinalSourceCity}
                                  onChange={(eve) => {
                                    setDestinationCountry(eve.target.value);
                                  }}
                                  className="form-control form_one"
                                  id="inputZip"
                                  placeholder="Enter Destination Country"
                                  required
                                />
                              </div>
                              <div className="col-md-6 ml80Mobile">
                                <input
                                  type="text"
                                  value={internatoinalDestinationCity}
                                  onChange={(eve) => {
                                    setDestinationPincode(eve.target.value);
                                  }}
                                  className="form-control form_one"
                                  id="inputZip"
                                  placeholder="Enter Destination Pincode"
                                  required
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 col-sm-6 ml80Mobile">
                                <input
                                  type="date"
                                  onChange={(eve) => {
                                    setDate(eve.target.value);
                                  }}
                                  className="form-control form_one"
                                  id="inputZip"
                                  required
                                />
                              </div>

                              <div className="col-md-6 mt4 ml80Mobile">
                                <button
                                  className="btn btn-primary-one text-white form_one"
                                  onClick={handleResults}
                                >
                                  Get Details
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        {serviceType === "international shipping" && (
                          <div className="tab-pane active" id="my_2">
                            <div className="row">
                              <div className="col-md-6 ml80Mobile col-sm-6">
                                <input
                                  type="text"
                                  value={sourceCountry}
                                  onChange={(eve) => {
                                    setSourceCountry(eve.target.value);
                                  }}
                                  className="form-control form_one"
                                  id="inputZip"
                                  placeholder="Enter Source Country"
                                  required
                                />
                              </div>
                              <div className="col-md-6 ml80Mobile">
                                <input
                                  type="text"
                                  value={destinationCountry}
                                  onChange={(eve) => {
                                    setDestinationCountry(eve.target.value);
                                  }}
                                  className="form-control form_one"
                                  id="inputZip"
                                  placeholder="Enter Destination Country"
                                  required
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 ml80Mobile col-sm-6">
                                <input
                                  type="text"
                                  value={internatoinalSourceCity}
                                  onChange={(eve) => {
                                    setInternatoinalSourceCity(eve.target.value);
                                  }}
                                  className="form-control form_one"
                                  id="inputZip"
                                  placeholder="Enter Source City"
                                  required
                                />
                              </div>
                              <div className="col-md-6 ml80Mobile">
                                <input
                                  type="text"
                                  value={internatoinalDestinationCity}
                                  onChange={(eve) => {
                                    setInternatoinalDestinationCity(eve.target.value);
                                  }}
                                  className="form-control form_one"
                                  id="inputZip"
                                  placeholder="Enter Destination City"
                                  required
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 ml80Mobile col-sm-6">
                                <input
                                  type="number"
                                  value={sourcePincode}
                                  onChange={(e) => {
                                    setSourcePincode(e.target.value);
                                  }}
                                  className="form-control form_one"
                                  id="inputZip"
                                  placeholder="source Pincode"
                                />
                              </div>

                              <div className="col-md-6 ml80Mobile">
                                <input
                                  type="number"
                                  value={destinationPincode}
                                  onChange={(e) => {
                                    setDestinationPincode(e.target.value);
                                  }}
                                  className="form-control form_one"
                                  id="inputZip"
                                  placeholder="destination Pincode"
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 col-sm-6 ml80Mobile">
                                <input
                                  type="date"
                                  onChange={(eve) => {
                                    setDate(eve.target.value);
                                  }}
                                  className="form-control form_one"
                                  id="inputZip"
                                  required
                                />
                              </div>

                              <div className="col-md-6 mt4 ml80Mobile">
                                <button
                                  className="btn btn-primary-one text-white form_one mt10"
                                  onClick={handleResults}
                                >
                                  Get Details
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      {loading === true && (
                        <span className='tac'>
                          <Loader />
                        </span>
                      )}
                      {result && loading === false && (
                        // <div className="table-responsive">
                        //   <table className="table">
                        //     <thead>
                        //       <tr>
                        //         <th>Type</th>
                        //         <th>Estimated Time</th>
                        //         <th>Estimated Amount</th>
                        //       </tr>
                        //     </thead>
                        //     {result.length > 0 ? (
                        //       <tbody>
                        //         {result.map((r: any) => (
                        //           <tr key={r.type}>
                        //             <td>
                        //               <a onClick={() => {}}>{r.type}</a>
                        //             </td>
                        //             <td>
                        //               {new Date(
                        //                 r.estimatedDeliveryTime
                        //               ).toLocaleDateString()}
                        //             </td>
                        //             <td>{r.estimatedCost}</td>
                        //           </tr>
                        //         ))}
                        //       </tbody>
                        //     ) : (
                        //       <tbody>
                        //         <tr>
                        //           <td
                        //             colSpan={3} className='tac'
                        //           >
                        //             {/* No service found! */}
                        //             <button
                        //               className="btn btn-primary-one text-white form_one"
                        //               onClick={() => {
                        //                 handleResults();
                        //               }}
                        //             > 
                        //                 Get Details
                        //             </button>
                        //           </td>
                        //         </tr>
                        //       </tbody>
                        //     )}
                        //   </table>
                        // </div>
                        null
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="feturesTabs">
            <div className="features commonBoxShadow" onMouseOver={() => { setTabActive(1) }}>
              <div className="section-title">
                <h2>Our Features</h2>
              </div>
              <div className="centalisedFeatures">
                <OurFeatures data={International} />
              </div>
            </div>
            <div className="howItWorks commonBoxShadow" onMouseOver={() => { setTabActive(2) }}>
              <div className="section-title">
                <h2>How It Works</h2>
              </div>
              <div className="centalisedHowItWorks">
                <HowItWorks data={International} />
              </div>
            </div>
            <div className="trestimonials commonBoxShadow" onMouseOver={() => { setTabActive(3) }}>
              <div className="section-title">
                <h2>Testimonials</h2>
              </div>
              <div className="centalisedTestimonials">
                <Testimonials data={International} />
              </div>
            </div>
            <div className="faqIntracity commonBoxShadow" onMouseOver={() => { setTabActive(4) }}>
              <section className="faq">
                <div className="container">
                  <div className="section-title">
                    <h2>FAQ</h2>
                  </div>
                  <FAQ data={International} />
                </div>
              </section>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="intracityContainer">
            <div className="scrollableContainer">
              <div className="sideNav" onMouseEnter={() => document.body.style.overflowY = 'hidden'} onMouseLeave={() => document.body.style.overflowY = 'scroll'}>
                <div className={tabActive === 0 ? "activeNew" : ""} onClick={() => { topFunction2("book"), setTabActive(0) }}>Book Now</div>
                <div className={tabActive === 1 ? "activeNew" : ""} onClick={() => { topFunction2("feature"), setTabActive(1) }}>Features</div>
                <div className={tabActive === 2 ? "activeNew" : ""} onClick={() => { topFunction2("how"), setTabActive(2) }}>How It Works</div>
                <div className={tabActive === 3 ? "activeNew" : ""} onClick={() => { topFunction2("test"), setTabActive(3) }}>Testimonials</div>
                <div className={tabActive === 4 ? "activeNew" : ""} onClick={() => { topFunction2("faq's"), setTabActive(4) }}>FAQ's</div>
              </div>
              <div className='international'>
                <div className="intro-content formAndPhoto commonBoxShadow" onMouseOver={() => { setTabActive(0) }}>
                  <div className="col-lg-6 firm_1 wow mt-55" data-wow-delay="0.1s">
                    <div className="bttn">
                      <div className="ownBtn">
                        <button className={serviceType === "international courier" ? "activeButton" : ""} onClick={() => { setServiceType("international courier") }}> International Courier </button>
                        <button className={serviceType === "international shipping" ? "activeButton" : ""} onClick={() => { setServiceType("international shipping") }}> International Shipping </button>
                      </div>
                    </div>

                    {serviceType === "international courier" && (
                      <>
                        <div className="row">
                          <div className="col-md-6 col-sm-6">
                            <input
                              type="text"
                              value={sourceCountry}
                              onChange={(eve) => {
                                setSourceCountry(eve.target.value);
                              }}
                              className="form-control mt10"
                              id="inputZip"
                              placeholder="Enter Source Country"
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="number"
                              value={sourcePincode}
                              onChange={(e) => {
                                setSourcePincode(e.target.value);
                              }}
                              className="form-control ml-10 mt10"
                              id="inputZip"
                              placeholder="Enter Source Pincode"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-sm-6">
                            <input
                              type="text"
                              value={internatoinalSourceCity}
                              onChange={(eve) => {
                                setDestinationCountry(eve.target.value);
                              }}
                              className="form-control mt10"
                              id="inputZip"
                              placeholder="Enter Destination Country"
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              value={internatoinalDestinationCity}
                              onChange={(eve) => {
                                setDestinationPincode(eve.target.value);
                              }}
                              className="form-control ml-10 mt10"
                              id="inputZip"
                              placeholder="Enter Destination Pincode"
                              required
                            />
                          </div>
                        </div>

                        {formDetails === false ? (
                          <div className='addpkg'>
                            <PackageForm val={(res: any) => { setFomrDetails(res) }}
                              pacgeArr={(res: any) => { setPackageCount(res) }}
                              titleName={"Double Table Int"} className="" />
                          </div>
                        ) : (<div className="showPkg mt-10">{packageCount.length > 0 &&
                          <>
                            <h6>Package Details</h6>
                            <div className={(packageCount.length === 1 ? "result1" : packageCount.length === 2 ? "result2" : "result3")}>
                              <table>
                                <tr>
                                  <th>Qty</th>
                                  <th>Material</th>
                                  <th>Size</th>
                                </tr>
                                {packageCount.map((ele: any, i: number) => {
                                  return (
                                    <tr key={i}>
                                      <td>{ele.Qty ? ele.Qty : null}</td>
                                      <td>{ele.MaterialType ? ele.MaterialType : null}
                                        <br />
                                        Packing :- {ele.PackingType ? ele.PackingType : null}
                                      </td>
                                      <td>
                                        {ele.BoxesType ? ele.BoxesType : null}
                                        <br />
                                        {ele.Weight ? ele.Weight : null} KG
                                        <br />
                                        {ele.Length ? ele.Length : null} x {ele.Breadth ? ele.Breadth : null} x {ele.Height ? ele.Height : null} {ele.UnitType ? ele.UnitType : null}
                                      </td>
                                    </tr>
                                  )
                                })}
                              </table>
                            </div>
                          </>
                        }
                        </div>)}

                        <div className="row">
                          <div className="col-md-6 col-sm-6">
                            <input
                              type="date"
                              onChange={(eve) => {
                                setDate(eve.target.value);
                              }}
                              className="form-control form_one"
                              id="inputZip"
                              required
                            />
                          </div>

                          <div className="col-md-6 mt4 ml-22">
                            <button
                              className="btn btn-primary-one text-white form_one"
                              onClick={handleResults}
                            >
                              Get Details
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                    {serviceType === "international shipping" && (
                      <>
                        <div className="row">
                          <div className="col-md-6 col-sm-6">
                            <input
                              type="text"
                              value={sourceCountry}
                              onChange={(eve) => {
                                setSourceCountry(eve.target.value);
                              }}
                              className="form-control mt10"
                              id="inputZip"
                              placeholder="Enter Source Country"
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              value={destinationCountry}
                              onChange={(eve) => {
                                setDestinationCountry(eve.target.value);
                              }}
                              className="form-control mt10 ml-10"
                              id="inputZip"
                              placeholder="Enter Destination Country"
                              required
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-sm-6">
                            <input
                              type="text"
                              value={internatoinalSourceCity}
                              onChange={(eve) => {
                                setInternatoinalSourceCity(eve.target.value);
                              }}
                              className="form-control mt10"
                              id="inputZip"
                              placeholder="Enter Source City"
                              required
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              value={internatoinalDestinationCity}
                              onChange={(eve) => {
                                setInternatoinalDestinationCity(eve.target.value);
                              }}
                              className="form-control mt10 ml-10"
                              id="inputZip"
                              placeholder="Enter Destination City"
                              required
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-sm-6">
                            <input
                              type="number"
                              value={sourcePincode}
                              onChange={(e) => {
                                setSourcePincode(e.target.value);
                              }}
                              className="form-control mt10"
                              id="inputZip"
                              placeholder="source Pincode"
                            />
                          </div>

                          <div className="col-md-6">
                            <input
                              type="number"
                              value={destinationPincode}
                              onChange={(e) => {
                                setDestinationPincode(e.target.value);
                              }}
                              className="form-control mt10 ml-10"
                              id="inputZip"
                              placeholder="destination Pincode"
                            />
                          </div>
                        </div>

                        {formDetails === false ? (
                          <div className='addpkg'>
                            <PackageForm val={(res: any) => { setFomrDetails(res) }}
                              pacgeArr={(res: any) => { setPackageCount(res) }}
                              titleName={"Double Table Int"} />
                          </div>
                        ) : (<div className="showPkg">{packageCount.length > 0 &&
                          <>
                            <h6>Package Details</h6>
                            <div className={(packageCount.length === 1 ? "result1" : packageCount.length === 2 ? "result2" : "result3")}>
                              <table>
                                <tr>
                                  <th>Qty</th>
                                  <th>Material</th>
                                  <th>Size</th>
                                </tr>
                                {packageCount.map((ele: any, i: number) => {
                                  return (
                                    <tr key={i}>
                                      <td>{ele.Qty ? ele.Qty : null}</td>
                                      <td>{ele.MaterialType ? ele.MaterialType : null}
                                        <br />
                                        Packing :- {ele.PackingType ? ele.PackingType : null}
                                      </td>
                                      <td>
                                        {ele.BoxesType ? ele.BoxesType : null}
                                        <br />
                                        {ele.Weight ? ele.Weight : null} KG
                                        <br />
                                        {ele.Length ? ele.Length : null} x {ele.Breadth ? ele.Breadth : null} x {ele.Height ? ele.Height : null} {ele.UnitType ? ele.UnitType : null}
                                      </td>
                                    </tr>
                                  )
                                })}
                              </table>
                            </div>
                          </>
                        }
                        </div>)}

                        <div className="row">
                          <div className="col-md-6 col-sm-6">
                            <input
                              type="date"
                              onChange={(eve) => {
                                setDate(eve.target.value);
                              }}
                              className="form-control form_one"
                              id="inputZip"
                              required
                            />
                          </div>

                          <div className="col-md-6 mt4 ml-22">
                            <button
                              className="btn btn-primary-one text-white form_one mt10"
                              onClick={handleResults}
                            >
                              Get Details
                            </button>
                          </div>
                        </div>
                      </>
                    )}

                    {loading === true && (
                      <span className='tac'>
                        <Loader />
                      </span>
                    )}
                    {result && loading === false && (
                      // <div className="table-responsive">
                      //   <table className="table">
                      //     <thead>
                      //       <tr>
                      //         <th>Type</th>
                      //         <th>Estimated Time</th>
                      //         <th>Estimated Amount</th>
                      //       </tr>
                      //     </thead>
                      //     {result.length > 0 ? (
                      //       <tbody>
                      //         {result.map((r: any) => (
                      //           <tr key={r.type}>
                      //             <td>
                      //               <a onClick={() => {}}>{r.type}</a>
                      //             </td>
                      //             <td>
                      //               {new Date(
                      //                 r.estimatedDeliveryTime
                      //               ).toLocaleDateString()}
                      //             </td>
                      //             <td>{r.estimatedCost}</td>
                      //           </tr>
                      //         ))}
                      //       </tbody>
                      //     ) : (
                      //       <tbody>
                      //         <tr>
                      //           <td
                      //             colSpan={3} className='tac'
                      //           >
                      //             {/* No service found! */}
                      //             <button
                      //               className="btn btn-primary-one text-white form_one"
                      //               onClick={() => {
                      //                 handleResults();
                      //               }}
                      //             > 
                      //                 Get Details
                      //             </button>
                      //           </td>
                      //         </tr>
                      //       </tbody>
                      //     )}
                      //   </table>
                      // </div>
                      null
                    )}
                  </div>
                  <div className="col-lg-6 mt40" data-wow-delay="0.2s" data-wow-duration="2s">
                    <img src="port-image.jpg" alt="" />
                  </div>
                </div>
                <div className="feturesTabs">
                  <div className="features commonBoxShadow" onMouseOver={() => { setTabActive(1) }}>
                    <div className="section-title" data-wow-delay="0.1s">
                      <h2>Our Features</h2>
                    </div>
                    <div className="centalisedFeatures">
                      <OurFeatures data={International} />
                    </div>
                  </div>
                  <div className="howItWorks commonBoxShadow" onMouseOver={() => { setTabActive(2) }}>
                    <div className="section-title" data-wow-delay="0.1s">
                      <h2>How It Works</h2>
                    </div>
                    <div className="centalisedHowItWorks">
                      <HowItWorks data={International} />
                    </div>
                  </div>
                  <div className="trestimonials commonBoxShadow" onMouseOver={() => { setTabActive(3) }}>
                    <div className="section-title" data-wow-delay="0.1s">
                      <h2>Testimonials</h2>
                    </div>
                    <div className="centalisedTestimonials">
                      <Testimonials data={International} />
                    </div>
                  </div>
                  <div className="faqIntracity commonBoxShadow" onMouseOver={() => { setTabActive(4) }}>
                    <section className="faq">
                      <div className="container">
                        <div className="section-title wow" data-wow-delay="0.1s">
                          <h2>Frequently Asked Questions</h2>
                        </div>
                        <FAQ data={International} />
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default InterNationalForm