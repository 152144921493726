import { GENERAL_SETTINGS } from "../constants/alllGeneralSettings";

const initSDtate:any = {
    bannerImage : "",
    blockedUsers : [],
    builtyChargeSlab : [],
    deliveryChargesSlab : [],
    handlingChargeSlab : [],
    insuredCompanies : [],
    memoApiOn : false,
    minValueCharge : 0,
    perPackageValueCharge : 0,
    phoneVerificationSetting : false,
    settledCompany : "",
    sizes : [],
    smsOperator : "",
    valueChargeEnabled : false,
    version  : "",
}

const allGeneralSettings = (state = initSDtate , action: any) => {
    const {type , payload} = action;
    // console.log(`Settings Api Called From Reducer New Payload` , payload)
    switch (type) {
        case GENERAL_SETTINGS : {
            return {
                ...payload
            }
        }
        default:
        return state;
    }
}

export default allGeneralSettings;