import React, { Fragment } from "react";
import { createRoot } from "react-dom/client"; // Change import here
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import store from "./store";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga";
import "./console-disable";
import swDev from "./swDev";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material";
// import { PersistGate } from "redux-persist/integration/react";

const TRACKING_ID = "UA-257764354-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const rootElement: any = document.getElementById("root");
const theme = createTheme();

if (rootElement?.hasChildNodes()) {
  ReactDOM.hydrate(
    <Fragment>
      <ToastContainer style={{ zIndex: "10000000" }} />
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </StyledEngineProvider>
        {/* </PersistGate> */}
      </Provider>
    </Fragment>,
    rootElement
  );
} else {
  createRoot(rootElement).render(
    // Use createRoot instead of ReactDOM.render
    <Fragment>
      <ToastContainer style={{ zIndex: "10000000" }} />
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </StyledEngineProvider>
        {/* </PersistGate> */}
      </Provider>
    </Fragment>
  );
}

swDev();
