import { useState } from "react";

export function useOurNetwork() {
    const [branchName, setBranchName] = useState<string>("");
    const [state, setState] = useState<any>("");
    const [city, setCity] = useState<any>("");
    const [branch, setBranch] = useState<any>("");
    const [pincode, setPincode] = useState<any>("");
    const [cityOptions, setCityOptions] = useState<any>([]);
    const [stateOptions, setStateOptions] = useState<any>([]);
    const [branchOptions, setBranchOptions] = useState<any>([]);
    const [allCityList, setAllCityList] = useState<any>([]);
    const [allBranches, setAllBranches] = useState<any>([]);


    return { branchName, setBranchName, pincode, setPincode, cityOptions, setCityOptions, stateOptions, setStateOptions, branchOptions, setBranchOptions, state, setState, city, setCity, branch, setBranch, allCityList, setAllCityList, allBranches, setAllBranches }
}