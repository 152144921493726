import { Online_PAYMENT_TYPE_LIST, PAYMENT_TYPE_LIST } from "../constants/paymentTypeList";

const initState:any = {
    paymentTypeList:[],
    onlinePaymentTypeList:[],
};

const paymentTypeReducer = (state = initState , action: any) => {
    const {type , payload} = action;
    switch (type) {
        case PAYMENT_TYPE_LIST : {
            return {
                ...state,
                paymentTypeList:payload,
            }
        }
        case Online_PAYMENT_TYPE_LIST:{
            return {
                ...state,
                onlinePaymentTypeList:payload,
            }
        }
        default:
        return state;
    }
}

export default paymentTypeReducer;