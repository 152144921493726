// react
import React, { useState } from "react";
// ui components
import { Button, Select } from "../../../components/UI";
// constants (icon)
import { GetDetailsIcon } from "../../../constants/icons";
import { useWidth } from "../../../hooks/useMobile";


// type
type StateType = {
    value: string;
    error: true | false;
    errorMessage?: string;
}


// variables
const options = [
    { value: 'ahmedabad', label: 'Ahmedabad' },
    { value: 'surat', label: 'Surat' }
]


// FC: Full Load Get Estimate Component
export default function FullLoadForm() {
    // State
    const [quantity, setQuantity] = useState<StateType>(() => ({
        value: "",
        error: false,
        errorMessage: ""
    }));
    const [weight, setWeight] = useState<StateType>(() => ({
        value: "",
        error: false,
        errorMessage: ""
    }));
    const [vehicleNumber, setVehicleNumber] = useState<StateType>(() => ({
        value: "",
        error: false,
        errorMessage: ""
    }));
    const { isMobile } = useWidth();


    // does: validate quantity
    function quantityValidation(e: React.FocusEvent<HTMLInputElement, Element>) {
        const quantityValue = e.target.value;
        if (quantityValue === "0") {
            return setQuantity(prevQuantity => {
                return { ...prevQuantity, error: true, errorMessage: "Quantity should be greater than 0" }
            })
        }
        if (!quantityValue || quantityValue == null || quantityValue === " ") {
            return setQuantity(prevQuantity => {
                return { ...prevQuantity, error: true, errorMessage: "Quantity is required!" }
            })
        } else {
            return setQuantity(prevQuantity => {
                return { ...prevQuantity, error: false }
            })
        }
    }
    // does: validate weight
    function weightValidation(e: React.FocusEvent<HTMLInputElement, Element>) {
        const weightValue = e.target.value;
        if (weightValue === "0") {
            return setWeight(prevWeight => {
                return { ...prevWeight, error: true, errorMessage: "Weight should be greater than 0" }
            })
        }
        if (!weightValue || weightValue == null || weightValue === " ") {
            return setWeight(prevWeight => {
                return { ...prevWeight, error: true, errorMessage: "Weight is required!" }
            })
        } else {
            return setWeight(prevWeight => {
                return { ...prevWeight, error: false }
            })
        }
    }


    // component return
    return <div className={`| grid gap-1 ${isMobile ? "paper mt-1" : ""}`}>
        {isMobile && <div className="flex-col gap-8">
        <h1 className="fs-heading text-center fw-900 text-primary-400">Get an Estimate</h1>
        {/* <p className="text-center">Delivery your less than truck load to another cities with door pickup and door delivery service</p> */}
       </div> }
        {/* Source Location */}
        <Select isClearable={true} isSearchable={true} options={options} placeholder={"Source Location"} />
        {/* Destination Location */}
        <Select isClearable={true} isSearchable={true} options={options} placeholder={"Destination Location"} />
        {/* Vehicle Type & Name */}
        <div className="| two-column gap-1 t-one-column">
            <Select isClearable={true} isSearchable={true} options={options} placeholder={"Vehicle Type"} />
            <Select isClearable={true} isSearchable={true} options={options} placeholder={"Vehicle Name"} />
        </div>
        {/* Vehicle Model & Vehicle Number */}
        <div className="| two-column gap-1 t-one-column">
            <Select isClearable={true} isSearchable={true} options={options} placeholder={"Vehicle Model"} />
            <input type="number" placeholder="Vehicle Number" name="full-load-vehicle-number" id="full-load-vehicle-number" value={vehicleNumber.value} onChange={e => setVehicleNumber(prevVehicleNumber => {
                    return { ...prevVehicleNumber, value: e.target.value }
                })} className="| p-8 br-4 border-white outline-none" min={0} />
        </div>
        {/* Date & Material Type */}
        <div className="| two-column gap-1 t-one-column">
            <input type="date" name="full-load-date" id="full-load-date" className="| p-8 border-white outline-none br-4" />
            <Select isClearable={true} isSearchable={true} options={options} placeholder={"Select Material Type"} />
        </div>
        {/* Quantity & Weight */}
        <div className="| two-column gap-1 t-one-column">
            <div className="| flex-col">
                <input type="number" placeholder="Quantity" name="full-load-quantity" id="full-load-quantity" value={quantity.value} onChange={e => setQuantity(prevQuantity => {
                    return { ...prevQuantity, value: e.target.value }
                })} onBlur={quantityValidation} className="| p-8 br-4 border-white outline-none" min={0} />
                <p className="| text-danger-400 fs-extrasmall" style={{ marginTop: "2px" }}>{quantity.error && quantity.errorMessage}</p>
            </div>
            <div className="flex-col">
                <input type="number" placeholder="Weight(KG)" name="full-load-weight" id="full-load-weight" value={weight.value} onChange={e => setWeight(prevWeight => {
                    return { ...prevWeight, value: e.target.value }
                })} onBlur={weightValidation} className="| p-8 br-4 border-white outline-none" min={0} />
                <p className="| text-danger-400 fs-extrasmall" style={{ marginTop: "2px" }}>{weight.error && weight.errorMessage}</p>
            </div>
        </div>
        <Button variant="secondary" action="secondary" Icon={<GetDetailsIcon />} fullWidth={isMobile ? true : false} className={`| as-start fs-button ${isMobile ? "flex-center" : ""}`}>Get Estimated Rate</Button>
    </div>
}