import React, { FunctionComponent } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { RootState } from "./store";
import { Button, Dialog } from "./newSrc/components/UI";
import { CancelButton } from "./Components/Button/AllButton";

const connector = connect((state: RootState) => ({
  isDialogOpen: state.appActions.isReloadAppDialogOpen,
}));

interface Props extends ConnectedProps<typeof connector> {}

const ConfirmReloadDialog: FunctionComponent<Props> = (props) => {
  const dispatch : any = useDispatch();
  const handleClose = () => {
    dispatch({
      type: "SET_RELOAD_APP_DIALOG",
      payload: false,
    });
  };

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div>
      <Dialog

        state={props.isDialogOpen}
        onClose={() => { }}
        header=' New Version Available'
        body={
          <h5> Please reload to update the application version.</h5>
        }
        footer={
          <div className="flex gap-1">
            <CancelButton autoFocus onClick={handleClose} />
            <Button variant="secondary" action="secondary" onClick={handleReload}>Reload</Button>
          </div>
        }

      />

    </div>
  );
};
export default connector(ConfirmReloadDialog);
