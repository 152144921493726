import { Marker } from "@react-google-maps/api";
import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../store";

interface IProps {
  legPosition: any;
  driverLocation?: any;
}
const DirectionStartPosition = (props: IProps) => {

  const icon1 = {
    rotation: 215,
    //  url: "https://images.vexels.com/media/users/3/154573/isolated/preview/bd08e000a449288c914d851cb9dae110-hatchback-car-top-view-silhouette-by-vexels.png",
    // scaledSize: new window.google.maps.Size(40, 40),
    // anchor: new window.google.maps.Point(15, 15),
    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    scale: 4,
    title: "Your Driver",
    description: "Arriving in 2 mins",
    strokeColor: "#FFFFFF",
    fillColor: "#0000FF",
    fillOpacity: 1,
  };

  return (
    <>
      <Marker
        position={props.legPosition}
        title="Your Driver"
        icon={{ ...icon1, rotation: props?.driverLocation?.position }}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    driverLocation: state.liveLocation.customerDocketLocation,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DirectionStartPosition);
