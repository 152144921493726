import React, { Dispatch, SetStateAction } from "react";
import { Button } from "../UI";
import { FilterIcon } from "../../constants/icons";

type FilterButtonProps = {
  setShowModel?: Dispatch<SetStateAction<boolean>>;
  newButton?: boolean;
  onClick?: () => void;
  onOpen?: () => void;
};

const FilterButton = ({ setShowModel, onClick, onOpen }: FilterButtonProps) => {
  return (
    <Button
      variant="primary"
      action="primary"
      size="large"
      onClick={() => { 
        setShowModel?.(true);
        onClick?.();
        onOpen?.();
      }}
      Icon={<FilterIcon />}
      title="Filter"
    ></Button>
  );
};

export default FilterButton;
