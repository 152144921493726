// react
import React, {
  ComponentProps,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
} from "react";
// react-dom
import { createPortal } from "react-dom";
// css
import "./drawer.css";
// types
import type {
  CSSColorShadesType,
  CSSColorVariantType,
  CSSOverflowType,
} from "../../../types/common";
// constants (icon)
import { CloseIcon } from "../../../constants/icons";
import { useWindow } from "../../../hooks/useWindow";

// type
type Sizes =
  | "small"
  | "regular"
  | "medium"
  | "large"
  | "extra-large"
  | "auto"
  | "full";
export type DrawerPropsType = {
  state: boolean;
  setState?: Dispatch<SetStateAction<boolean>>;
  drawerHeading?: ReactNode;
  drawerBody?: ReactNode;
  position?: "top" | "right" | "bottom" | "left";
  className?: string;
  drawerBackground?: CSSColorVariantType;
  drawerBodyClassName?: string;
  drawerBackgroundShades?: CSSColorShadesType;
  drawerColor?: CSSColorVariantType;
  drawerColorShades?: CSSColorShadesType;
  maxWidth?: Sizes;
  maxHeight?: Sizes;
  overflow?: CSSOverflowType;
  isClickOutsideClose?: boolean;
  isEscapeKeyClose?: boolean;
  isDecoration?: boolean;
  overlayZIndex?: number;
  zIndex?: number;
  onCloseIconClick?: () => void;
  isHeader?: boolean;
  onClose?: () => void;
} & ComponentProps<"div">;

// Custom FC: Drawer
const Drawer = ({
  state = false,
  setState,
  drawerHeading,
  drawerBody,
  position = "right",
  className = "",
  drawerBackground = "white",
  drawerBackgroundShades = 900,
  drawerColor = "black",
  drawerColorShades = 900,
  maxWidth = "auto",
  maxHeight = "auto",
  overflow = "auto",
  isClickOutsideClose = false,
  isEscapeKeyClose = true,
  isDecoration = true,
  drawerBodyClassName = "",
  zIndex = 9999,
  overlayZIndex = 9998,
  isHeader = true,
  onCloseIconClick,
  onClose,
  children,
  ...props
}: DrawerPropsType) => {
  const drawerComponentRef = useRef<HTMLDivElement | null>(null);
  const { width, height } = useWindow();
  const customDrawerElement = document.querySelector("#custom-drawer-element")!;
  const isLeftOrRight = position === "left" || position === "right";
  const isTopOrBottom = position === "top" || position === "bottom";
  const drawerHeaderBackgroundShades =
    drawerBackgroundShades === 800 || drawerBackgroundShades === 900
      ? drawerBackgroundShades - 100
      : drawerBackgroundShades + 100;
  const drawerHeaderCloseIconBackgroundShades =
    drawerBackgroundShades === 800 || drawerBackgroundShades === 900
      ? drawerBackgroundShades - 200
      : drawerBackgroundShades + 100;

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key !== "Escape") return;
      setState?.(false);
      onClose?.();
    }
    if (state && isEscapeKeyClose) {
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (isEscapeKeyClose) {
        document.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [state, setState]);

  // return component
  return createPortal(
    <>
      <div
        ref={drawerComponentRef}
        style={{zIndex: state ? zIndex : "auto"}}
        className={`my-custom-drawer ${
          isLeftOrRight ? `max-width-${maxWidth}` : ""
        } ${
          isTopOrBottom ? `max-height-${maxHeight}` : ""
        } bg-${drawerBackground}-${drawerBackgroundShades} text-${drawerColor}-${drawerColorShades} ${position} ${className} ${
          state ? "active" : ""
        } overflow-${overflow}`}
        {...props}
      >
        {/* Drawer Header */}
        {drawerHeading && <div
          className={`${isHeader ? "" : "none"} my-custom-drawer-header | flex-between ai-center py-10 px-20 gap-2 bg-${drawerBackground}-${drawerHeaderBackgroundShades} flex-wrap`}
        >
          {/* Drawer Heading */}
          <section style={{ fontSize: "clamp(1.25rem, 3vw, 2rem)" }}>
            {drawerHeading}
          </section>
          {/* Drawer Close Icon */}
          <section>
            <button
              className={`my-custom-drawer-close-icon | flex-center border-none outline-none bg-${drawerBackground}-${drawerHeaderCloseIconBackgroundShades} p-8 br-4`}
              style={{ fontSize: "clamp(1.25rem, 3vw, 2rem)" }}
              onClick={() => {
                setState?.(false)
                if(onCloseIconClick) {
                  onCloseIconClick();
                }
                onClose?.();
              }}
            >
              <CloseIcon />
            </button>
          </section>
        </div>}
        {/* Drawer Body */}
        <section className={`${drawerBodyClassName} p-20 bg-${drawerBackground}-${drawerBackgroundShades} text-${drawerColor}-${drawerColorShades}`}>{drawerBody || children}</section>
        {isDecoration && width > 1024 && height > 768 && (position === "left" || position === "right") &&  <img src="/assets/filter/filter.svg" loading="lazy" alt="filter-decoration" className="my-custom-drawer-decoration-img" />}
      </div>
      <div
        style={{zIndex: state ? overlayZIndex : "auto"}}
        className={`my-custom-drawer-overlay ${state ? "active" : ""}`}
        onClick={() => {
          if(isClickOutsideClose) {
            setState?.(false);
          }
          onClose?.();
        }}
      ></div>
    </>,
    customDrawerElement
  );
};

export default Drawer;
