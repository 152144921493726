import { buildQueryString, ServiceMaker } from "../../../services";
import { CreateEditLocationType } from "../../pages/CustomerModule/Components/CustomerBooking/types/booking.type";

export const listAddressSuggetion = (data?: any) =>
  ServiceMaker(`address/listAllAddress?${buildQueryString(data)}`, "GET");

export const addAddressSuggetion = (data: CreateEditLocationType) =>
  ServiceMaker("address/create", "POST", data);

export const editAddressSuggetion = (
  id: string,
  data: CreateEditLocationType
) => ServiceMaker(`address/update/${id}`, "PATCH", data);

export const addAddressSuggetionByUser = (data: CreateEditLocationType) =>
  ServiceMaker("address/create/byUser", "POST", data);

export const editAddressSuggetionByUser = (
  id: string,
  data: CreateEditLocationType
) => ServiceMaker(`address/update/byUser/${id}`, "PATCH", data);

export const listAddressSuggetionByUser = (data?: any) =>
  ServiceMaker(`address/listAllAddress/byUser?${buildQueryString(data)}`, "GET");

export const getEwayBillDetailsBranchBooking = (data:any) => 
  ServiceMaker("ewaybill/india/details", "POST",data);

export const getEwayBillDetailsCustomerBooking = (data:any) => 
  ServiceMaker(`customerBooking/ewaybill/india/details?${buildQueryString(data)}`,"GET")

export const createPartLoadFullLoadBookingFromCustomer = (data:any) => ServiceMaker(`customerBooking/createFullPartLoadReq`, "POST", data);

export const createPartLoadFullLoadBookingFromBranch = (data:any) => ServiceMaker(`booking/createFullPartLoadReq`, "POST", data);