import { ServiceMaker, buildQueryString } from "./index";

export const getFeedBackList = () =>
  ServiceMaker("feedback/matrix", "GET");

export const getBranchwsieFeedbackList = (data:any) =>
  ServiceMaker(`feedback/branchMatrix?${buildQueryString(data)}`, "GET");

export const getBranchwsieRatingList = () =>
  ServiceMaker(`feedback/branchWiseRating`, "GET");

export const getWhatsappMessageContent = (id:string) =>
  ServiceMaker(`message/shareComplaint/${id}`, "GET");
  
export const addCustomerComplaint = (data:any) =>
  ServiceMaker(`feedback/addCustomerComplaint`, "POST",data);
  
export const addMessageToChatasCustomer = (data:any) =>
  ServiceMaker(`feedback/customerBooking/replayMessage/${data.feedbackNumber}`, "POST",data.msg);
  
export const addMessageToChatasCustomerForBranchOrder = (data:any) =>
  ServiceMaker(`feedback/replay/user/byCustomer/${data.feedbackNumber}`, "POST",data.msg);

export const driverCustBookingRating = (data:any) =>
  ServiceMaker(`feedback/driverCustBookingRating/${data.feedbackNumber}`, "POST",data.msg);

export const replyByDriver = (data:any) =>
  ServiceMaker(`feedback/customerBooking/replayMessage/byDriver/${data.feedbackNumber}`, "PATCH",data.msg);

export const toalMembersInvolvedCount = (data:any) =>
  ServiceMaker(`feedback/listMembers?${buildQueryString(data)}`, "GET");

