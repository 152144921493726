export const NEW_ROUTE_CHANGE_SIMPLE = "NEW_ROUTE_CHANGE_SIMPLE";

export const NEW_ROUTE_CHANGE_DESTINATION = "NEW_ROUTE_CHANGE_DESTINATION";

export const NEW_ROUTE_ADD_DESTINATION = "NEW_ROUTE_ADD_DESTINATION";

export const NEW_ROUTE_REMOVE_DESTINATION = "NEW_ROUTE_REMOVE_DESTINATION";

export const NEW_ROUTE_CLEAR_ROUTE = "NEW_ROUTE_CLEAR_ROUTE";

export const NEW_ROUTE_SET_ROUTE = "NEW_ROUTE_SET_ROUTE";

export const NEW_ROUTE_SET_COMPANY_ROUTES = "NEW_ROUTE_SET_COMPANY_ROUTES";

export const NEW_ROUTE_GET_ROUTES = "NEW_ROUTE_GET_ROUTES";

export const NEW_ROUTE_LIST_ROUTES = "NEW_ROUTE_LIST_ROUTES";

export const NEW_ROUTE_UPDATE_ROUTE = "NEW_ROUTE_UPDATE_ROUTE";

export const NEW_ROUTE_CREATE_ROUTE = "NEW_ROUTE_CREATE_ROUTE";

export const NEW_ROUTE_ADD_PACK_SIZE = "NEW_ROUTE_ADD_PACK_SIZE";

export const NEW_ROUTE_DELETE_PACK_SIZE = "NEW_ROUTE_DELETE_PACK_SIZE";
