import React, { ComponentProps } from "react";

// type
type HeadingProps = {
  className?: string;
} & ComponentProps<"h1">;

// Heading Component
const Heading = ({ children, className = "", ...props }: HeadingProps) => {
    return (
        <h1 className={`fs-heading text-center fw-700 m-0 ${className}`} {...props}>{children}</h1>
    )
}

export const SubHeading = ({ children, className = "", ...props }: HeadingProps) => {
    return (
        <h1 className={`fs-subheading text-center fw-700 m-0 ${className}`} {...props}>{children}</h1>
    )
}

export const NarrowHeading = ({ children, className = "", ...props }: HeadingProps) => {
    return (
        <h1 className={`fs-link text-center fw-700 m-0 ${className}`} {...props}>{children}</h1>
    )
}

export default Heading;