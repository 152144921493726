import { buildQueryString, ServiceMaker } from "./index";
import jsonToFormdata from "../utils/jsonToFormdata";



export const setDeliveryCommission = (data: Object) =>
  ServiceMaker("deliveryCommission", "POST", data);


export const viewFranchise = (data: { id: string }) =>
  ServiceMaker("franchise/view/" + data.id, "GET");

export const fetchHead = (parent: string, child: string) =>
  ServiceMaker(`franchise/head/${parent}/${child}`, "GET");

export const createHead = (data: Object) =>
  ServiceMaker(`franchise/head`, "POST", data);

export const checkDriverFranchise = (data: Object) =>
  ServiceMaker("franchise/head/check/driver", "POST", data);

export const reportFranchise = (data: Object) =>
  ServiceMaker("franchise/filter", "POST", data);

export const pendingApproval = (id: Object) =>
  ServiceMaker("franchise/pending/approval/list", "POST", {
    parentCompany: id,
  });



export const approveFranchise = (id: string) =>
  ServiceMaker("franchise/approve", "POST", { franchiseId: id });

export const rejectFranchise = (id: string) =>
  ServiceMaker("franchise/reject", "POST", { franchiseId: id });

export const listFranchiseSettleRequests = (uid: string) =>
  ServiceMaker("franchise/pending/settle", "POST", { user: uid });

export const approveFranchiseSettleRequests = (id: string) =>
  ServiceMaker("franchise/transfer/approve", "POST", { id: id });

export const getDeliveryCommission = (
  parentCompany: string,
  childCompany: string
) => ServiceMaker(`deliveryCommission/${parentCompany}/${childCompany}`, "GET");
// get all child companies( franchisees )
export const getAvailableFranchise = (company: string) =>
  ServiceMaker("franchise/available/" + company, "GET");





type Transaction = {
  from_company: string;
  from_user: string;
  from_sub: string;
  from_entity: string;
  to_company: string;
  to_user: string;
  to_sub: string;
  to_entity: string;
  amount: number;
  created_at: string;
};

type Payments = {
  paid: Array<Transaction>;
  received: Array<Transaction>;
};

type Commission = {
  from_company: string;
  to_company: string;
  amount: number;
  settled: boolean;
  action: string;
  docket: string;
  booked_amount: number;
  created_at: string;
};

type Bill = {
  from_company: string;
  to_company: string;
  amount: number;
  created_at: string;
  settled: boolean;
};

type FranchiseSummary = {
  bills: Array<Bill>;
  payments: Payments;
  commissions: Array<Commission>;
};

export const getFranchiseSummary = (data: FranchiseSummary) =>
  ServiceMaker("franchise/summary/by/company", "POST", data);

export const settleBill = (data: Object) =>
  ServiceMaker("franchise/settle/bill", "POST", data);

export const settleBillInitiate = (data: Object) =>
  ServiceMaker("franchise/settle/bill/i", "POST", data);

/**
 * @param {string} sub
 * @param {string} entity
 * @param {string} rUser
 * @param {string} rSub
 * @param {string} rEntity
 * @param {boolean} initiate
 * @param {number} amount
 * @param {boolean} send
 */
export const transferInitiate = (data: Object) =>
  ServiceMaker("franchise/transfer/initiate", "POST", data);

export const finalTransfer = (data: Object) =>
  ServiceMaker("franchise/transfer", "POST", data);

export const getUnapprovedNoncashFranchiseTransfers = () =>
  ServiceMaker("franchise/list/noncash/unapproved/transfers", "GET");

export const approveNoncashFranchise = (id: string) =>
  ServiceMaker(`franchise/approve/noncash/transfers/${id}`, "PUT", {});

export const listFranchiseReportsToSettle = (data?: any) =>
  ServiceMaker(
    `franchise/reports/pending/settle?${buildQueryString(data)}`,
    "GET"
  );

export const listFranchiseReportsToSetCommission = (data?: any) =>
  ServiceMaker(
    `franchise/reports/pending/commission?${buildQueryString(data)}`,
    "GET"
  );

export const setFranchiseReportCommission = (
  id: string,
  bookingCommission: number,
  deliveryCommission: number,
  bookingDockets: any[],
  deliveryDockets: any[],
  companyId?: string
) =>
  ServiceMaker("franchise/reports/set/commission", "PUT", {
    _id: id,
    bookingCommission,
    deliveryCommission,
    bookingDockets,
    deliveryDockets,
    companyId,
  });

type MemoPayload = {
  reports: string[];
  amount: number;
  remarks: string;
  companyId: string;
};

type PaymentPayload = {
  sub: "B" | "F";
  entity: string;
  amount: number;
  paymentType: any;
  nonCash?: boolean;
  remarks: string;
  rUser?: any;
  rSub?: any;
  rEntity?: any;
  txnId?: any;
  nonCashTxnImage?: any;
  reportTxnIds: string[];
};

export const franchisePaymentCreate = (data: PaymentPayload) =>
  ServiceMaker("franchise/payment/create", "POST", jsonToFormdata(data));

export const franchiseMemoCreate = (data: MemoPayload) =>
  ServiceMaker("franchise/memo/create", "POST", data);

export const franchiseMemoList = (data: any) =>
  ServiceMaker(`franchise/memo/list?${buildQueryString(data)}`, "GET");

type PaymentActionPayload = {
  paymentId: string;
  sub?: "B" | "F";
  entity?: string;
};

export const franchisePaymentApprove = (data: PaymentActionPayload) =>
  ServiceMaker("franchise/payment/approve", "POST", data);

export const franchisePaymentReject = (data: PaymentActionPayload) =>
  ServiceMaker("franchise/payment/reject", "POST", data);

export const franchisePaymentsToSettle = (company: string) =>
  ServiceMaker<any[]>(
    `franchise/paymentsToSettle?${buildQueryString({ company })}`,
    "GET"
  );

export const listAllRoutesWithName = () =>
  ServiceMaker<{ _id: string; name: string }[]>("route/allRoutes", "GET");

export const listAllPendingDockets = (data?: any) =>
  ServiceMaker<any>(
    `franchise/pendingDockets?${buildQueryString(data)}`,
    "GET"
  );

export const listCompanyService = () => ServiceMaker("company/list", "GET");

export const listReceiverDetails = (data:any) =>
  ServiceMaker(`user/taptapPaymentUser?${buildQueryString(data)}`, "GET")

export const franchisePaymentList = (data?: any) =>
  ServiceMaker(`franchise/payments/list?${buildQueryString(data)}`, "GET");

export const listPaymentDetails = (id: string) =>
  ServiceMaker(`franchise/reports/ByMemo/${id}`, "GET");

export const listByPayments = (id: string) =>
  ServiceMaker(`franchise/reports/ByPayment2/${id}`, "GET");

export const franchiseListNew = (id: any) =>
  ServiceMaker(`franchiseTxn/pendingReports?${buildQueryString(id)}`, "GET");

export const getTransactionDetail = (data: any) =>
  ServiceMaker("franchiseTxn/txnDetails", "POST", data);

export const getFranchiseMonthlyReport = (data: any) =>
  ServiceMaker(`franchise/monthlyReport?${buildQueryString(data)}`, "GET");

  export const getFranchiseDayWiseReport = (data: any) =>
  ServiceMaker(`franchise/getFranchiseDayWiseComission?${buildQueryString(data)}`, "GET");

export const getFranchiseTxnMonthlyReportDetails = (data: any) =>
  ServiceMaker(
    `franchiseTxn/monthlyReportDetails?${buildQueryString(data)}`,
    "GET"
  );

export const getFranchiseTxnPaymentReportDetails = (data: any) =>
  ServiceMaker(
    `franchiseTxn/paymentReportDetails?${buildQueryString(data)}`,
    "GET"
  );


export const getFranchiseMonthlyData = (data?:any) =>
 ServiceMaker(`franchise/doorDeliveryRevenue?${buildQueryString(data)}`,"GET")