import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from "../../store"
import ReactTable from "../ReactTable/ReactTable";
import { queryStringToJSON } from "../../services";

export const PaymentGateWaySuccess = (props : any) => {
    console.log(`props PaymentGateWaySuccess`,props);
    const [data, setData] = useState([])
    const [txnsStatus, setTxnsStatus] = useState("")
    
    useEffect(() => {

      const queryString = window.location.search;

      // Parse the query string into an object
     const querydata= queryStringToJSON(queryString)
     let array : any=[]
     for (let [key,value] of Object.entries(querydata)) {
      if(key=="order_status"){
        setTxnsStatus(value as any)
      }
      array.push({
        key,
        value
      })
     }
     setData(array)
      console.log("queryParams :  ",)
    } , [])
    const Columns : any = [
      {
        accessor: "key",
        Header: "Fields",
        className: "center",
      },
      {
        accessor: "value",
        Header: "Data ",
        className: "center",
      },
    ];

    return (
      <div style={{textAlign : "center"}}>
        <h1> {txnsStatus} Page </h1>
        
    <ReactTable
      data={data}
      columns={Columns}
      // hover={hover}
      // filter={filter}
      // wrapperClasses={wrapperClasses}
      // pagination={pagination}
      // defaultSorted={defaultSorted}
    />

      </div>
    )
}

const mapStateToProps = (state : RootState) => ({
    user : state?.user,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentGateWaySuccess)