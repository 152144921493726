import {LOCK_DRIVER_WHILE_LOGIN} from '../constants/driverLogin';

const initSDtate:any = {
    isLocked : false
}

const driverLogin = (state = initSDtate , action: { type: any; payload: any }) => {
  switch (action.type) {
    case LOCK_DRIVER_WHILE_LOGIN : {
        return {
            ...state,
            isLocked : action.payload
        }
    }
    default:
      return state;
  }
}

export default driverLogin;