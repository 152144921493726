import { fromJS } from "immutable";

import {
  SET_FINANCIALS_FOR_SYNC,
  SYNC_SUCCESS,
  SET_PAST_FINANCIALS,
} from "../constants/tally";

import { toast } from "react-toastify";

const initState = fromJS({
  expenses: [],
  incomes: [],
  transfers: [],
  pastFinancials: [],
});

export default (state = initState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case SET_FINANCIALS_FOR_SYNC: {
      return state.merge(action.payload);
    }

    case SYNC_SUCCESS: {
      toast.success("Financials synced successfuly!");
      return state.merge(initState);
    }

    case SET_PAST_FINANCIALS: {
      return state.set("pastFinancials", action.payload);
    }

    default: {
      return state;
    }
  }
};
