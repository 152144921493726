import { QR_SET_DIALOG, QR_SET_SCAN_CACHES, QR_SET_SCAN_LOGS } from "../constants/qr";

const initState:any = {
  logs: [],
  dialog: false,
  caches: {}
};

export default (state = initState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case QR_SET_SCAN_LOGS: {
      let logs: any = [...state.logs];
      if (logs.length === 10) {
        logs.shift();
        logs.push(action.payload);
      } else {
        logs.push(action.payload);
      }

      return {
        ...state,
        logs: [...logs],
      };
    }
    case QR_SET_DIALOG: {
      return {
        ...state,
        dialog: action.payload
      }
    }
    case QR_SET_SCAN_CACHES: {
      if(state.caches[action?.payload]){
        return {
          ...state,
          caches: {
            ...state.caches,
            [action?.payload]: state.caches[action?.payload] + 1
          }
        }
      }else{
        return {
          ...state,
          caches: {
            ...state.caches,
            [action?.payload]: 1
          }
        }
      }
    }
    default:
      return state;
  }
};
