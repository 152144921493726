import axios from "axios";
import Printd from "printd";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../../store";
import { AnyAction } from "redux";
import {
  builtyCSS,
  printBuilty,
  printQRDocket,
} from "../../containers/operations/bookutils/steps/builty/index";
import { url, updateBalance, resetEdit, showLoader, hideLoader } from "../UserActions";
import {
  showHttpError,
  showMessage,
  success,
  failed,
} from "../../utils/message";

import { bookingSchema } from "../../validators/booking";

import { bookFilterRoute } from "../../services/route";

// Services
import { getCustomerByGST } from "../../services/GST";

import {
  editDocketService,
  getBillDetails,
  cancelDocketService,
  resendSmsService,
  cancelDocketByCustomerService
} from "../../services/operations";

import { toFirstPage } from "../booking/helpers";

import {
  getAllBranchesByCompany,
  filterBookBranches,
} from "../../services/branch";

import {
  SET_SETTINGS,
  BOOK_BILL,
  BOOK_PURPOSE,
  BOOK_DOCKET,
  BOOK_EWB,
  BOOK_SENDER,
  BOOK_SET_SENDER,
  BOOK_SET_SENDER_VALIDATE,
  BOOK_SET_RECEIVER_VALIDATE,
  BOOK_RESET_SENDER,
  SET_RECEIVER_SUGGESTIONS,
  SET_RATE_SUGGESTIONS,
  SET_PACKAGE_SUGGESTIONS,
  BOOK_SET_RECEIVER,
  BOOK_RECEIVER,
  RESET_SENDER_NAME,
  RESET_REMARKS,
  RESET_RECEIVER_NAME,
  BOOK_COL_MONTH,
  // BOOK_RATE,
  BOOK_PACK,
  BOOK_HOW_COL,
  PICKUP_CHARGE_CHANGE,
  BOOK_RISK_CHANGE,
  BOOK_REMARKS_CHANGE,
  BOOK_PICKUP_CONTACT,
  BOOK_GOODS_CHANGE,
  // BOOK_INS_CHANGE,
  BOOK_VALUE_CHARGE_CHANGE,
  BOOK_COL_CHANGE,
  BOOKING_REFRESH,
  BOOKING_EDIT_PACKAGE,
  BOOK_ADD_PACKAGE,
  ADD_PACKAGE_SIZE,
  // DEL_PACKAGE_SIZE,
  BOOK_DEL_PACKAGE,
  SET_ACROSS,
  SET_LR,
  SET_ORANGE_LR,
  BOOK_BRANCHES,
  BOOK_CHANGE_DELIVERY_TYPE,
  BOOK_CHANGE_FEDEX_SERVICE_TYPE,
  SET_FEDEX_SERVICE_OPTIONS,
  BOOK_DEST_BRANCH,
  // BOOK_DEST_ROUTE,
  BOOK_PAY_MODE,
  NONCASH_PAYMENT_TYPE,
  NONCASH_TXN_ID,
  NONCASH_TXN_IMAGE,
  CREDIT_NUM_CHANGE,
  BOOK_CREDIT_SET,
  BOOK_CREDIT_RESET,
  SPECIFIC_DELIVERY,
  SET_SENDER_BY_GST,
  SET_RECEIVER_BY_GST,
  ValidationErrorAction,
  BookingActionTypes,
  RefreshBookingAction,
  BookBillAction,
  BookPaymentModeAction,
  BookPackAction,
  // BookRateAction,
  BookHowCollection,
  BookRiskChangeAction,
  BookGoodsChangeAction,
  BookColChangeAction,
  // BookInsChangeAction,
  BookValueChargeChangeAction,
  BookRemarkChangeAction,
  BookPickupContactAction,
  BookDelPackageAction,
  // BookDelSizeAction,
  BookColMonthAction,
  TouchSenderAction,
  TouchReceiverAction,
  UpdateBuiltyAction,
  SpecificDeliveryAction,
  OtherBookingActions,
  SetSenderAddressFromOpBranchAction,
  SetReceiverAddressFromOpBranchAction,
  SetPackageErrorFlagFalseAction,
  ResetReceverNameAction,
  ResetSenderNameAction,
  ResetRemarksAction,
  PickupChargeChangeAction,
  BOOK_SET_IS_SENDER_DISABLE,
  BOOK_SET_IS_RECEIVER_DISABLE,
  BOOK_RESET_RECEIVER,
  setFocVerificationAction,
  BOOK_SET_FOC_VERIFICATION,
  setSenderVerificationAction,
  BOOK_SET_SENDER_VERIFICATION,
  SET_UPDATE_PKG_FETCHDOCKET,
  BOOK_SET_VERIFICATION,
  SET_DOOR_DELIVERY,
  RESET_DOOR_DELIVERY,
  setDoorDeliveryAction,
  BOOK_RESET_FIXRATECUSTOMER,
  BOOK_SET_FIXRATECUSTOMER,
  RESET_EWAYBILL_DATA,
  BOOK_SET_INDIVIDUALFLEET,
  SET_RATE_ID,
  SET_LAST_BOOKING,
  SET_DOOR_DELIVERY_DATA,
  setDoorDeliveryActionData,
  NONCASH_TXNS_NUMBER,
  ADD_MULTIPLE_EWB,
  REMOVE_MULTIPLE_EWB,
  RESET_MULTIPLE_EWB,
  SET_MULTIPLE_EWAYBILL_DATA,
  RESET_PARTICULAR_EWAYBILL_DATA,
  CHANGE_MULTIPLE_EWAYBILL_MANUAL_DATA,
  SET_PARTICULAR_EWB,
  BOOK_CONTACT_DATA,
  BOOK_DEST_SERVICE,
  SET_HANDLING_CHARGE_SLAB,
  SET_ONLINE_PAYMENT_STATUS,
  SET_SENDER_FORCE_NAME_REMOVE_DUE_TO_GST,
  SET_RECEIVER_FORCE_NAME_REMOVE_DUE_TO_GST,
  SET_SENDER_GST_REMOVE_REQ_DATA,
  SET_RECEIVER_GST_REMOVE_REQ_DATA,
  SET_SENDER_GST_CONTACTS,
  SET_RECEIVER_GST_CONTACTS,
  SET_SENDER_SECONDARY_CONTACT_REQ_DATA,
  SET_RECEIVER_SECONDARY_CONTACT_REQ_DATA,
  SET_GENERAL_WARNING,
} from "../../constants/booking";

import {
  BUILTY_UPDATE_ACK,
  BUILTY_UPDATE_REQ,
  PACKAGE_UPDATE_ACK,
  PAYMENT_UPDATE_ACK,
  REFRESH_BOOKING,
  GET_BRANCHES_BY_COMPANY,
  RESEND_SMS,
  ID_FETCH_ACK,
  BILL_FETCH_ACK,
  DOCKETS_FETCH_ACK,
  SENDER_GST_INVALID,
  RECEIVER_GST_INVALID,
  RESET_SENDER_ADDRESS,
  RESET_RECEIVER_ADDRESS,
  SHOW_LOADER,
  HIDE_LOADER,
  SHOW_EWAYBILL_LOADER,
  HIDE_EWAYBILL_LOADER,
  SHOW_GST_LOADER,
  HIDE_GST_LOADER,
  CREDIT_NUMBER_SHOW_LOADER,
  CREDIT_NUMBER_HIDE_LOADER,
  SHOW_GST_LOADING_FOR_RECEIVER,
  HIDE_GST_LOADING_FOR_RECEIVER,
  SET_SENDER_ADDRESS_FROM_OPBRANCH,
  SET_SENDER_ADDRESS_FROM_OTHER,
  SET_RECEIVER_ADDRESS_FROM_OPBRANCH,
  SET_RECEIVER_ADDRESS_FROM_OTHER,
  SET_ROUTES,
  HIDE_LOADER_WITHOUT_BLUR,
  UPDATE_BUILTY,
  SET_PACKAGE_ERROR_FLAG_FALSE,
  TOUCH_SENDER,
  TOUCH_RECEIVER,
  VALIDATION_ERROR,
} from "../../constants/booking";

import { SET_DOCKET_FROM_EWAYBILL } from "../../constants/booking";
import { isArrayCheck } from "../../containers/generics/CheckArray";
import { getCityByPincode } from "../../services/area";
import { suggestReceiver, suggestPacking } from "../../services/suggest";
import { suggestRate } from "../../services/rate";
import jsonToFormdata from "../../utils/jsonToFormdata";
import {
  fetchCustomerByContact,
  fetchCustomerByContactGeneralService,
  fetchCustomerByCustomerCodeService,
  getConnectedContactsByGST,
  getConnectedCustomersByContact,
} from "../../services/customer";
import { getSettingsService } from "../../services/settings";
import { bookingMaxGstCount, bookingMaxNameCount, bookingMaxSecondaryContactCount, gstRegex } from "../../constant";
import { listHandlingChargeSlab } from "../../services/handlingChargeSlab";

type BookingAsyncActionType<R = Promise<void>> = ThunkAction<
  R,
  RootState,
  undefined,
  BookingActionTypes | AnyAction
>;

type SelectValue = { label: string; value: string };

export const handleValidationError = (
  path: string[],
  message: string | boolean
): ValidationErrorAction => ({
  type: VALIDATION_ERROR,
  payload: { path, message },
});

export const setBookBranches = (): BookingAsyncActionType => {
  return async (dispatch, getState) => {
    try {
      const { id: company } = getState().user.company;
      const originBranch = getState().user.opBranch._id;
      const branches = await filterBookBranches({ company, originBranch });
      dispatch({
        type: BOOK_BRANCHES,
        payload: branches || [],
      });
    } catch (err : any) {
      console.log(err);
    }
  };
};

export const cancelBooking = (
  id: string,
  cancelReason: string,
  newDocketNum: string,
  canceledName?: any,
  canceledNumber?: any,
  canceledOtp?: any,
): BookingAsyncActionType => {
  return async (dispatch, getState) => {
    try {
      await dispatch({ type: SHOW_LOADER });

      const { user, booking } = getState();
      const payload: any = newDocketNum
        ? {
          docket: id,
          cancelReason: cancelReason,
          newDocket: newDocketNum,
        }
        : {
          docket: id,
          cancelReason: cancelReason,
        };

      if (user.opBranch && user.opBranch._id) {
        payload.sub = "B";
        payload.entity = user.opBranch._id;
      } else if (user.opFleet && user.opFleet._id) {
        payload.sub = "F";
        payload.entity = user.opFleet._id;
      } else {
        throw "select entity first";
      }
      if (booking.canelReason) {
        payload.cancelReason = booking.cancelReason;
      }
      if (canceledName) {
        payload.reciverName = canceledName;
      }
      if (canceledNumber) {
        payload.reciverContact = canceledNumber;
      }
      if (canceledOtp) {
        payload.otp = canceledOtp;
      }

      const { balance } = await cancelDocketService(payload);
      showMessage("Booking Cancelled", success);
      dispatch(resetEdit());
      await dispatch({ type: HIDE_LOADER });

      if (Number(balance) >= 0) {
        dispatch(updateBalance(Number(balance)));
      }
    } catch (error) {
      await dispatch({ type: HIDE_LOADER });

      showHttpError(error);
    }
  };
};

export const cancelBookingByCustomer = (
  id: string,
  cancelReason: string,
  canceledName?: any,
  canceledNumber?: any,
  canceledOtp?: any,
): BookingAsyncActionType => {
  return async (dispatch, getState) => {
    try {
      await dispatch({ type: SHOW_LOADER });

      const { user } = getState();
      const payload: any = {
        docket: id,
        remark: cancelReason
      }

      if (user.opBranch && user.opBranch._id) {
        payload.sub = "B";
        payload.entity = user.opBranch._id;
      } else if (user.opFleet && user.opFleet._id) {
        payload.sub = "F";
        payload.entity = user.opFleet._id;
      } else {
        throw "select entity first";
      }

      if (canceledName) {
        payload.customerName = canceledName;
      }
      if (canceledNumber) {
        payload.customerContact = canceledNumber;
      }
      if (canceledOtp) {
        payload.otp = canceledOtp;
      }

      const { balance } = await cancelDocketByCustomerService(payload);
      showMessage("Booking Cancelled", success);
      dispatch(resetEdit());
      await dispatch({ type: HIDE_LOADER });

      if (Number(balance) >= 0) {
        dispatch(updateBalance(Number(balance)));
      }
    } catch (error) {
      await dispatch({ type: HIDE_LOADER });

      showHttpError(error);
    }
  };
};
export const resendSMS = (id: string): BookingAsyncActionType => {
  return async (dispatch) => {
    try {
      await resendSmsService(id);
      dispatch({ type: RESEND_SMS });
    } catch (error) {
      console.log("error: ", error);
    }
  };
};
export const getBranchesByCompany = (): BookingAsyncActionType => {
  return async (dispatch, getState) => {
    try {
      const response = await getAllBranchesByCompany(
        getState().user.company._id
      );
      dispatch({ type: GET_BRANCHES_BY_COMPANY, payload: response });
    } catch (error) {
      console.log("error: ", error);
    }
  };
};

export const changeDeliveryType =
  (payload: SelectValue): BookingAsyncActionType =>
    async (dispatch) => {
      await dispatch({
        type: BOOK_CHANGE_DELIVERY_TYPE,
        payload,
      });
    };

export const setFedexServiceType =
  (payload: SelectValue): BookingAsyncActionType =>
    async (dispatch) => {
      await dispatch({
        type: BOOK_CHANGE_FEDEX_SERVICE_TYPE,
        payload,
      });
    };

export const bookingDestChange =
  (payload: SelectValue, cb: () => void): BookingAsyncActionType =>
  async (dispatch, getState) => {
    try {
      await dispatch({ type: BOOK_DEST_BRANCH, payload: payload });
      await bookingSchema.bookdestbranch.validateSync(payload.value);
      await dispatch(handleValidationError(["bookdestbranch"], false));
      const { user, booking } = getState();
      await dispatch(
        setAcross({
          fromBranch: user.opBranch._id,
          toCity: booking.bookdestcity.value,
        })
      );
      cb();
    } catch (err:any) {
      if (err.name == "ValidationError") {
        dispatch(handleValidationError(["bookdestbranch"], err.message));
      }
      console.log(err);
    }
  };

export const bookPurpose =
  (choice: SelectValue): BookingAsyncActionType =>
  async (dispatch) => {
    try {
      await dispatch({ type: BOOK_PURPOSE, payload: choice });
      await bookingSchema.purpose.validate(choice.value);
      dispatch(handleValidationError(["purpose"], false));
    } catch (err:any) {
      if (err.name == "ValidationError") {
        dispatch(handleValidationError(["purpose"], err.message));
      }
      console.log(err);
    }
  };

export const refreshBooking = (): RefreshBookingAction => ({
  type: REFRESH_BOOKING,
});

export const bookBill = (val: string): BookBillAction => ({
  type: BOOK_BILL,
  payload: val,
});

export const creditNumberChange = (payload: {
  val: string;
  contact: string;
}): BookingAsyncActionType => {
  return async (dispatch, getState) => {
    try {
      const { val } = payload;
      dispatch({ type: CREDIT_NUM_CHANGE, payload: payload.val });
      dispatch({ type: BOOK_CREDIT_RESET });
      if (val.length == 10) {
        dispatch({ type: CREDIT_NUMBER_SHOW_LOADER });
        const credit = await axios.post(
          url + "/credit/customer/fetch",
          payload
        );
        if (credit.data.status != 1) throw "CreditAPIError";
        const { user } = getState();
        dispatch({
          type: BOOK_CREDIT_SET,
          payload: { data: credit.data.response, user },
        });
        dispatch({ type: CREDIT_NUMBER_HIDE_LOADER });
      }
    } catch (err : any) {
      dispatch({ type: CREDIT_NUMBER_HIDE_LOADER });
      console.log(err);
    }
  };
};

export function debounce(func: any, wait: number, immediate?: boolean): any {
  let timeout: any;
  return function(this: any) {
    const context = this;
    const args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}


const reset = (
  data:
    | {
      exclude: any;
    }
    | undefined,
  dispatch: ThunkDispatch<RootState, undefined, AnyAction>
) => {
  dispatch(resetEdit(data));
};

const optimizedReset = debounce(reset, 500);

export const bookChangeDocket =
  (val: string): BookingAsyncActionType =>
    async (dispatch) => {
      try {
        await dispatch({ type: BOOK_DOCKET, payload: val });
        optimizedReset({ exclude: { docketNumber: 1 } }, dispatch);
      } catch (err : any) {
        console.log(err);
      }
    };

export const bookChangeEWB = (val: string): BookingAsyncActionType => {
  return async (dispatch) => {
    try {
      await dispatch({ type: BOOK_EWB, payload: val });
      if (val.length == 12) {
        await dispatch({ type: SHOW_EWAYBILL_LOADER });
        const response = await getBillDetails({
          email: "chakrapratyush@gmail.com",
          eWayBillNumber: val,
        }) as any;
        if (response == "Could not retrieve data") {
          throw "ewaybilerror";
        } else {
          let regexGstin = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}Z[0-9A-Z]{1}$/;
          try {
          if (regexGstin.test(response?.gstin_of_consignor)) {
            const senderGstContacts = await getConnectedContactsByGST(response?.gstin_of_consignor)
            const senderPrimary = senderGstContacts?.primaryCustomer
            const senderSecondary = senderGstContacts?.secondaryContacts
        
            await dispatch({
              type: SET_SENDER_GST_CONTACTS,
              payload: [...(senderPrimary ? [senderPrimary] : []), ...(senderSecondary?.length ? senderSecondary : [])],
            });
          }
         } catch (error:any) {
          console.log("senderGstContacts error: ",error.message as any)
        }
        try {
          if(regexGstin.test(response?.gstin_of_consignee)){
            const receiverGstContacts = await getConnectedContactsByGST(response?.gstin_of_consignee)
            const receiverPrimary = receiverGstContacts?.primaryCustomer
            const receiverSecondary = receiverGstContacts?.secondaryContacts
  
            await dispatch({
              type: SET_RECEIVER_GST_CONTACTS,
              payload: [...(receiverPrimary ? [receiverPrimary]: []), ...(receiverSecondary?.length ? receiverSecondary : [])],
            });  
          }
        } catch (error:any) {
          console.log("receiverGstContacts error: ",error.message as any)
        }
          
          await dispatch({
            type: SET_DOCKET_FROM_EWAYBILL,
            payload: response,
          });
        }
        await dispatch({ type: HIDE_EWAYBILL_LOADER });
        if (response == "Could not retrieve data")
          showMessage("Please Check And Try Again", failed);
      } else {
        dispatch({
          type: RESET_EWAYBILL_DATA
        });
      }
    } catch (error) {
      await dispatch({ type: HIDE_EWAYBILL_LOADER });
      if (error == "ewaybilerror") {
        showMessage("Could not fetch details", failed);
      }
      dispatch({
        type: RESET_EWAYBILL_DATA
      });
      console.log("error: ", error);
    }
  };
};

export const ChangeMultipleEWB = (val: string, index: any): BookingAsyncActionType => {
  return async (dispatch, getState) => {
    try {
      let flag = false;
      let { multipleEwayBillData, bill, goods, ewb, sender, receiver } = getState().booking;
      console.log(`SET_MULTIPLE_EWAYBILL_DATA actions 600cb `, bill, goods, ewb, sender, receiver);
      let setOnlyEWayBillNo = multipleEwayBillData.map((item: any, index20: any) => {
        if (index === index20) {
          return {
            ...item,
            ewb: val,
          };
        }
        return item;
      });

      await dispatch({ type: SET_PARTICULAR_EWB, payload: setOnlyEWayBillNo });
      if (val.length == 12) {
        await dispatch({ type: SHOW_EWAYBILL_LOADER });
        const response: any = await getBillDetails({
          email: "chakrapratyush@gmail.com",
          eWayBillNumber: val,
        });
        if (sender?.name === "" && sender?.gst === "" && receiver?.name === "" && receiver?.gst === "") {
          flag = true;
        }
        console.log(`SET_MULTIPLE_EWAYBILL_DATA actions`, response)
        console.log(`SET_MULTIPLE_EWAYBILL_DATA actions 600`, val, index, multipleEwayBillData);
        let finalData = multipleEwayBillData.map((item: any, index20: any) => {
          if (index === index20) {
            return {
              ...item,
              ewb: val,
              bill: response?.document_number,
              goods: response?.total_invoice_value,
              isEwayBill: true,
            };
          }
          return item;
        });
        console.log(`SET_MULTIPLE_EWAYBILL_DATA actions 6004200`, val, index, finalData);
        if (response == "Could not retrieve data") {
          throw "ewaybilerror";
        } else {
          await dispatch({
            type: SET_MULTIPLE_EWAYBILL_DATA,
            payload: finalData,
            srData: { check: flag, arr: response, indexValue: index }
          });
        }
        await dispatch({ type: HIDE_EWAYBILL_LOADER });
        if (response == "Could not retrieve data")
          showMessage("Please Check And Try Again", failed);
      } else {
        let resetArr = multipleEwayBillData.map((item: any, index20: any) => {
          if (index === index20) {
            return {
              ...item,
              ewb: val,
              bill: '',
              goods: '',
            };
          }
          return item;
        });
        dispatch({
          type: RESET_PARTICULAR_EWAYBILL_DATA,
          payload: resetArr
        });
      }
    } catch (error) {
      // console.log(`error 20 error`,error)
      showHttpError(error);
      await dispatch({ type: HIDE_EWAYBILL_LOADER });
      // if (error == "ewaybilerror") {
      //   showMessage("Could not fetch details", failed);
      // }
      // console.log("error: ", error);
    }
  };
};

export const changeMultipleEWayBillManual = (data: any) => {
  console.log(`data changeMultipleEWayBillManual`, data);
  return async (dispatch: any, getState: any) => {
    let { multipleEwayBillData } = getState().booking;
    let finalData = multipleEwayBillData.map((item: any, index20: any) => {
      if (data?.indexNo === index20) {
        if (data?.contentType === "goods") {
          return {
            ...item,
            goods: data?.value,
          };
        }
        if (data?.contentType === "bill") {
          return {
            ...item,
            bill: data?.value,
          };
        }
      }
      return item;
    });
    console.log(`data changeMultipleEWayBillManual`, finalData);
    await dispatch({ type: CHANGE_MULTIPLE_EWAYBILL_MANUAL_DATA, payload: finalData, })
  }
};

export const addMultipleEwayBill: any = (val: any): BookingAsyncActionType => {
  console.log(`ADD_MULTIPLE_EWB action`, val)
  return async (dispatch: any) => {
    await dispatch({ type: ADD_MULTIPLE_EWB, payload: val });
  };
};

export const removeMultipleEwayBill: any = (val: any): BookingAsyncActionType => {
  console.log(`REMOVE_MULTIPLE_EWB action`, val)
  return async (dispatch: any) => {
    await dispatch({ type: REMOVE_MULTIPLE_EWB, payload: val });
  };
};

export const resetMultipleEwayBill: any = (): BookingAsyncActionType => {
  console.log(`RESET_MULTIPLE_EWB action`)
  return async (dispatch: any) => {
    await dispatch({ type: RESET_MULTIPLE_EWB });
  };
};

export const bookingMode = (val: SelectValue): BookPaymentModeAction => ({
  type: BOOK_PAY_MODE,
  payload: val,
});

export const nonCashPaymentTypeChange = (val: string) => ({
  type: NONCASH_PAYMENT_TYPE,
  payload: val,
});

export const nonCashTxnIDChange = (val: string) => ({
  type: NONCASH_TXN_ID,
  payload: val,
});

export const nonCashTxnImageChange = (val: any) => ({
  type: NONCASH_TXN_IMAGE,
  payload: val,
});

export const nonCashTxnsNumberChange = (val: string) => ({
  type: NONCASH_TXNS_NUMBER,
  payload: val,
});

export const setOnlinePaymentStatus = (val: string) => ({
  type: SET_ONLINE_PAYMENT_STATUS,
  payload: val,
});
// export const bookingRate = (data: string): BookRateAction => ({
//   type: BOOK_RATE,
//   payload: data,
// });

export const bookingPack = (data: string): BookPackAction => ({
  type: BOOK_PACK,
  payload: data,
});

export const bookingHowCollect = (
  data: string,
  companyData: any
): BookHowCollection => ({
  type: BOOK_HOW_COL,
  payload: { data, companyData },
});

export const bookingRiskChange = (
  risk: 0 | 1 | 2,
  companyData: any
): BookRiskChangeAction => ({
  type: BOOK_RISK_CHANGE,
  payload: { risk, companyData },
});

export const bookingGoodsChange = (data: string): BookGoodsChangeAction => ({
  type: BOOK_GOODS_CHANGE,
  payload: data,
});

export const bookingColChange = (data: string): BookColChangeAction => ({
  type: BOOK_COL_CHANGE,
  payload: data,
});

// export const bookingInsChange = (data: string): BookInsChangeAction => ({
//   type: BOOK_INS_CHANGE,
//   payload: data,
// });

export const bookingValueChargeChange = (
  data: number
): BookValueChargeChangeAction => ({
  type: BOOK_VALUE_CHARGE_CHANGE,
  payload: data,
});

export const bookingRemarksChange = (data: string): BookRemarkChangeAction => ({
  type: BOOK_REMARKS_CHANGE,
  payload: data,
});
export const bookingPickupContactChange = (
  data: string
): BookPickupContactAction => ({
  type: BOOK_PICKUP_CONTACT,
  payload: data,
});

export const bookingContactDataChange = (
  data: any
): any => ({
  type: BOOK_CONTACT_DATA,
  payload: data,
});

export const addPackage = (data: {
  qty: string | number;
  materialType: string | SelectValue;
  packingType: string | SelectValue;
  dimension: {
    l: string | number;
    b: string | number;
    h: string | number;
  };
  weight: string | number;
  size: string | SelectValue;
  stack: boolean;
  frag: boolean;
  haz: boolean;
  rate: string | SelectValue;
  amount: string | number;
  unit: string | SelectValue;
  id: number;
}): BookingAsyncActionType => {
  return async (dispatch) => {
    dispatch({ type: BOOK_ADD_PACKAGE, payload: data });
    dispatch({ type: BOOKING_REFRESH });
  };
};

export const addsize = (data: {
  name: string | number;
  l: string | number;
  b: string | number;
  h: string | number;
  unit: string | SelectValue;
  weight: number;
  _id: number;
}): BookingAsyncActionType => {
  return async (dispatch) => {
    dispatch({ type: ADD_PACKAGE_SIZE, payload: data });
  }
};
// export const delSize = (data: { _id: number }): BookDelSizeAction => ({
//   type: DEL_PACKAGE_SIZE,
//   payload: data,
// });

export const delPackage = (data: { id: number }): BookDelPackageAction => ({
  type: BOOK_DEL_PACKAGE,
  payload: data,
});

export const editPackage = (
  data: {
    id: number;
    name:
    | "stack"
    | "frag"
    | "haz"
    | "size"
    | "mat"
    | "pack"
    | "qty"
    | "l"
    | "b"
    | "h"
    | "u"
    | "w"
    | "rate"
    | "amount"
    | "fixRate"
    | "standardRate"
    | "standardRateAmount";
    val?: any;
  },
  cb?: (data: any) => void
): BookingAsyncActionType => {
  return async (dispatch) => {
    await dispatch({ type: BOOKING_EDIT_PACKAGE, payload: data });
    if (typeof cb == "function") {
      setTimeout(() => {
        cb(data);
      }, 100);
    }
  };
};

export const colMonthChange = (val: string): BookColMonthAction => ({
  type: BOOK_COL_MONTH,
  payload: val,
});
export const setAcross = (data: {
  fromBranch: string;
  toCityPincode?: string;
  toCity?: string;
}): BookingAsyncActionType => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        url +
        "/city/across/" +
        data.fromBranch +
        "/" +
        (data.toCity ? data.toCity : data.toCityPincode)
      );
      if (response.data.status != 1)
        throw "Could not decide inter or intra state";
      await dispatch({
        type: SET_ACROSS,
        payload: response.data.response,
      });
    } catch (err : any) {
      console.log(err);
    }
  };
};
export const validateSender =
  (options?: { pre: "name" }): BookingAsyncActionType =>
  async (dispatch, getState) => {
    try {
      let { sender, bookTouches, bookErrors } = getState().user;
      if (bookTouches && typeof bookTouches.toJS == "function") {
        const touched = bookTouches.toJS();
        const senderTouched = touched.sender;
        if (options && options.pre && typeof options.pre == "string") {
          senderTouched[options.pre] = true;
        }
        const payload: any = {};
        const schema: any = {};
        for (let key in senderTouched) {
          if (senderTouched[key] === true) {
            payload[key] = sender[key];
            const validator = bookingSchema.sender[key];
            schema[key] = validator;
            try {
              await validator.validate(sender[key]);
            } catch (err : any) {
              console.log(`validation err : ${key}`, err);
            }
            bookErrors = bookErrors.setIn(
              ["sender", key],
              !validator.isValidSync(sender[key])
            );
          }
        }
        dispatch(handleValidationError(["sender"], bookErrors.get("sender")));
      }
    } catch (err : any) {
      console.log(err);
      if (err.name == "ValidationError") {
        await dispatch(handleValidationError(["sender"], err.message));
      }
    }
  };
export const bookChangeSender =
  (
    what:
      | "contact"
      | "l1"
      | "l2"
      | "pin"
      | "city"
      | "name"
      | "gst"
      | "senderCode",
    val: any,
    reset?: boolean
  ): any =>//BookingAsyncActionType replace by any due to issue
    async (dispatch: any, getState: any) => {

      const { sender,isEwayBill, purpose, senderSecondaryContactReqData, senderGstContacts } = getState().booking;
      console.log(sender)
      try {
        if (what === "senderCode" && !isEwayBill) {
          await dispatch({
            type: BOOK_RESET_SENDER,
            payload: "",
          });
        }
        if (what === "contact") {
          await dispatch({
            type: BOOK_SENDER,
            payload: { what: "contactVerified", val: false },
          });


          if(reset){
            dispatch({
              type: BOOK_SENDER,
              payload: { what: "contact", val: "" },
            })
            dispatch({
              type: SET_SENDER_SECONDARY_CONTACT_REQ_DATA,
              payload: {
                dialog: false,
                step: 1,
                secondaryContactData: [],
                removedContact: "",
                newContact: ""
              },
            });
          }
        }
        if (what === "gst") {
          if (purpose?.value === "C" && isEwayBill === false) {
            let regexGstin = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}Z[0-9A-Z]{1}$/;
            if (!regexGstin.test(val?.value)) {
              if (val?.value?.trim() !== "URP") {
                showHttpError(`Gstin Number is Not in Valid Format ~!`);
                await dispatch({
                  type: BOOK_SENDER,
                  payload: { what, },
                });
                return;
              }
            }
            else if (regexGstin.test(val?.value)) {
              const gstNos = sender?.customerType === 'P' ? sender?.gstNos : sender?.connectedContacts?.gstNos
              const primaryContact = sender?.customerType === 'P' ? sender?.contact : sender?.connectedContacts?.contact

              if(val?.__isNew__){
                try{
                  await dispatch({ type: SHOW_LOADER });
                  const senderGstContacts = await getConnectedContactsByGST(val?.value)
                  const senderPrimary = senderGstContacts?.primaryCustomer

                  const allowToConnect = sender?.customerType === 'S' && !(sender?.connectedContacts?.contact)

                  await dispatch({ type: HIDE_LOADER });
                  if(senderPrimary && !allowToConnect){
                    await dispatch({ 
                      type: SET_GENERAL_WARNING, 
                      payload: `Sender GST ${val?.value} is already connected with other primary customer. Please contact admin.`
                    })
                    // showHttpError();
                    return
                  }
                }catch(err){
                  await dispatch({ type: HIDE_LOADER });
                  showHttpError(err)
                  return 
                }
              }
              if(val?.__isNew__ && gstNos?.length >= bookingMaxGstCount){
                await dispatch({
                  type: SET_SENDER_GST_REMOVE_REQ_DATA,
                  payload: { 
                    dialog: true,
                    step: 1,
                    removeIndex: null,
                    gstData: gstNos,
                    newGst: "",
                    primaryContact
                  },
                });
                return
              }
              if (sender && isArrayCheck(sender.gstNos)) {
                const concernedGst = sender.gstNos.find(
                  (g: { GSTIN: string }) => g.GSTIN == val.value
                );
                if (concernedGst && concernedGst.GSTIN) console.log(`djkvsdvnlovf new 20 Passed `, concernedGst);
                else {
                  await dispatch({ type: SHOW_LOADER });
                  try {
                    const customer = await getCustomerByGST({ gst: val.value });
                    const checkIsGstNameExists = sender?.names?.find((name:any) => customer?.trade_name?.toUpperCase() === name?.toUpperCase())
                    await dispatch({
                      type: SET_SENDER_BY_GST,
                      payload: { ...customer, senderForceNameRemoveDueToGst: sender?.names?.length >= bookingMaxNameCount && !checkIsGstNameExists, senderGstRelativeFieldDisable: true},
                    });
                    await dispatch(validateSender());
                    await dispatch({ type: HIDE_LOADER });
                  } catch (err : any) {
                    showMessage("Please Check And Try Again", failed);
                    console.log(err);
                    await dispatch({
                      type: SENDER_GST_INVALID,
                    });
                    await dispatch({
                      type: BOOK_SENDER,
                      payload: { what, },
                    });
                    await dispatch({ type: HIDE_LOADER });
                    return;
                  }
                }
              }
              else {
                await dispatch({ type: SHOW_LOADER });
                try {
                  const customer = await getCustomerByGST({ gst: val.value });
                  const checkIsGstNameExists = sender?.names?.find((name:any) => customer?.trade_name?.toUpperCase() === name?.toUpperCase())
                  await dispatch({
                    type: SET_SENDER_BY_GST,
                    payload: { ...customer, senderForceNameRemoveDueToGst: sender?.names?.length >= bookingMaxNameCount && !checkIsGstNameExists, senderGstRelativeFieldDisable: true},
                  });
                  await dispatch(validateSender());
                  await dispatch({ type: HIDE_LOADER });
                } catch (err : any) {
                  showMessage("Please Check And Try Again", failed);
                  console.log(err);
                  await dispatch({
                    type: SENDER_GST_INVALID,
                  });
                  await dispatch({
                    type: BOOK_SENDER,
                    payload: { what, },
                  });
                  await dispatch({ type: HIDE_LOADER });
                  return
                }
              }
            }
          }
          else {
            return showHttpError(`Write a Perfect Gstin Number ~!`)
          }
        }

      await dispatch({
        type: BOOK_SENDER,
        payload: { what, val },
      });
      const { bookroute, ewb, receiverBranchAddress } =
        getState().booking;
        const {phoneVerificationSetting}=getState().allGeneralSettings
        const {opBranch}=  getState().user;
      if (what == "contact" && val.length == 10) {
        let senderDoc:any ={}
        try {
          await dispatch({
            type: BOOK_SET_SENDER_VERIFICATION,
            payload: {
              what: "",
              val: "",
            },
          });

            senderDoc = await fetchCustomerByContactGeneralService({
              contact: val,
            });

            if(sender?.gst?.value){
              const checkIsGstNameExists = senderDoc?.names?.find((name:any) => sender?.name?.value?.toUpperCase() === name?.toUpperCase())
              const senderForceNameRemoveDueToGst = senderDoc?.names?.length >= bookingMaxNameCount && !checkIsGstNameExists
              await dispatch({
                type: SET_SENDER_FORCE_NAME_REMOVE_DUE_TO_GST,
                payload: senderForceNameRemoveDueToGst,
              });
            }
            
            if(isEwayBill){
              const res = await getConnectedContactsByGST(sender?.gst?.value)
              const senderPrimary = res?.primaryCustomer
              const senderSecondary = res?.secondaryContacts

              const senderGstContacts = [...(senderPrimary ? [senderPrimary] : []), ...(senderSecondary.length ? senderSecondary : [])]
          
              await dispatch({
                type: SET_SENDER_GST_CONTACTS,
                payload: senderGstContacts,
              });

              const isAlreadySecondary = senderGstContacts?.find((c:any) => c?.contact?.toString() === val.toString())
              if(!isAlreadySecondary){
                const secondaryContacts = senderGstContacts?.filter((c: any) => c.customerType === "S")
                const primaryContact = senderGstContacts?.find((c: any) => c.customerType === "P")?.contact
                
                if(senderDoc?.customerType === "P"){
                  showHttpError('Sender is already primary customer')
                }

                if(senderDoc?.customerType === "S" && senderDoc?.connectedContacts?.length){
                  showHttpError('Sender is already connected with other primary customer')
                }
                if(secondaryContacts.length >= bookingMaxSecondaryContactCount){
                  await dispatch({
                    type: BOOK_SENDER,
                    payload: { what, val: '' },
                  });

                  if(senderSecondaryContactReqData.step === 2 && senderSecondaryContactReqData.newContact.toString() === val.toString()){
                    showHttpError('Your request is not approved yet.')
                    return
                  }else{
                    await dispatch({
                      type: SET_SENDER_SECONDARY_CONTACT_REQ_DATA,
                      payload: { 
                        dialog: true,
                        step: 1,
                        secondaryContactData: secondaryContacts,
                        newContact: val.toString(),
                        primaryContact
                      },
                    });
                  }
                  return
                }
              }else{
                await dispatch({
                  type: SET_SENDER_SECONDARY_CONTACT_REQ_DATA,
                  payload: { 
                    dialog: false,
                    step: 1,
                    secondaryContactData: senderSecondary,
                    removedContact: "",
                    newContact: ""
                  },
                });
              }
            }else{
              const connectedContacts = await getConnectedCustomersByContact(val)
              const senderPrimary = connectedContacts?.primaryCustomer
              const senderSecondary = connectedContacts?.secondaryContacts
              const senderGstContacts = [...(senderPrimary ? [senderPrimary] : []), ...(senderSecondary.length ? senderSecondary : [])]

              await dispatch({
                type: SET_SENDER_GST_CONTACTS,
                payload: senderGstContacts,
              });
            }
        

            await dispatch({
              type: BOOK_SET_SENDER_VERIFICATION,
              payload: {
                what: "isSenderNew",
                val: false,
              },
            });

            await dispatch({
              type: BOOK_SET_SENDER,
              payload: senderDoc,
            });
            if (phoneVerificationSetting) {
              if (senderDoc && senderDoc.isContactValidated == false) {
                await dispatch(customerContactValidate("sender", {
                  name: senderDoc.name,
                  contact: senderDoc.contact
                }))
              }
            }
            // if (!ewb) {
            //   await dispatch({
            //     type: RESET_SENDER_NAME,
            //   });
            // }
          } catch (err : any) {
            console.log("showingggggerror", err);
            await dispatch({
              type: BOOK_SET_SENDER_VERIFICATION,
              payload: {
                what: "isSenderNew",
                val: true,
              },
            });
            const secondaryContacts = senderGstContacts?.filter((c: any) => c.customerType === "S")
            const primaryContact = senderGstContacts?.find((c: any) => c.customerType === "P")?.contact
            if(secondaryContacts.length >= bookingMaxSecondaryContactCount){
              await dispatch({
                type: SET_SENDER_SECONDARY_CONTACT_REQ_DATA,
                payload: { 
                  dialog: true,
                  step: 1,
                  secondaryContactData: secondaryContacts,
                  newContact: val.toString(),
                  primaryContact
                },
              });
            }
           
            if (!ewb) {
              await dispatch({
                type: RESET_SENDER_NAME,
              });
            }
            console.log(err);
          }

          try {
            if (receiverBranchAddress && receiverBranchAddress.pincode) {
              const receiverSuggestions = await suggestReceiver({
                senderContact: val,
                destination:
                  receiverBranchAddress && receiverBranchAddress.pincode
              });
              await dispatch({
                type: SET_RECEIVER_SUGGESTIONS,
                payload: receiverSuggestions,
              });
            }
          } catch (err : any) {
            await dispatch({ type: SET_RECEIVER_SUGGESTIONS, payload: [] });
            console.log(err);
          }

          if (bookroute && bookroute.value && bookroute.value.length == 24) {
            try {
              const rateSuggestions = await suggestRate({
                sender: val,
                route: bookroute.value,
              });
              await dispatch({
                type: SET_RATE_SUGGESTIONS,
                payload: rateSuggestions,
              });
            } catch (err : any) {
              await dispatch({ type: SET_RATE_SUGGESTIONS, payload: {} });
              console.log(err);
            }
          }

        } else if (what == "senderCode" && val.length >= 3) {
          let contact: any = "";
          try {
            const senderDoc = await fetchCustomerByCustomerCodeService({
              customerCode: val,
            });

            if (senderDoc) {
              if (phoneVerificationSetting) {
                if (senderDoc && senderDoc.isContactValidated == false) {
                  await dispatch(customerContactValidate("sender", {
                    name: senderDoc.name,
                    contact: senderDoc.contact
                  }))
                }
              }
              await dispatch({
                type: BOOK_SET_IS_SENDER_DISABLE,
                payload: true,
              });
              contact = senderDoc.contact;
              if(isEwayBill){
                await dispatch({
                  type: BOOK_SENDER,
                  payload: { what: "contact", val: senderDoc.contact },
                });
              }else{
                await dispatch({
                  type: BOOK_SET_SENDER,
                  payload: senderDoc,
                });
              }
            }
            // if (!ewb) {
            //   await dispatch({
            //     type: RESET_SENDER_NAME,
            //   });
            // }
          } catch (err : any) {
            // if (!ewb) {
            //   await dispatch({
            //     type: RESET_SENDER_NAME,
            //   });
            // }
            console.log(err);
          }

          try {
            const receiverSuggestions = await suggestReceiver({
              senderContact: contact,
              destination:
                receiverBranchAddress && receiverBranchAddress.pincode
                  ? receiverBranchAddress.pincode
                  : null,
            });
            await dispatch({
              type: SET_RECEIVER_SUGGESTIONS,
              payload: receiverSuggestions,
            });
          } catch (err : any) {
            await dispatch({ type: SET_RECEIVER_SUGGESTIONS, payload: [] });
            console.log(err);
          }

        if (bookroute && bookroute.value && bookroute.value.length == 24) {
          try {
            const rateSuggestions = await suggestRate({
              sender: contact,
              route: bookroute.value,
            });
            await dispatch({
              type: SET_RATE_SUGGESTIONS,
              payload: rateSuggestions,
            });
          } catch (err : any) {
            await dispatch({ type: SET_RATE_SUGGESTIONS, payload: {} });
            console.log(err);
          }
        }
      } else if (what == "gst" && val.value && val.value.length == 15) {
        if (sender && isArrayCheck(sender.gstNos)) {
          const concernedGst = sender.gstNos.find(
            (g: { GSTIN: string }) => g.GSTIN == val.value
          );
          if (concernedGst && concernedGst.GSTIN) return;
        }
        const payload = { gst: val.value, sender };
        await dispatch({ type: SHOW_GST_LOADER });
        await dispatch(getSenderByGST(payload));
        await dispatch({ type: HIDE_GST_LOADER });
      }
      if(what=="name" ||(what == "contact" && val.length == 10)||what=="gst"){
        if(phoneVerificationSetting){
        const {sender:newSender} = getState().booking; //newSender for access latest value from redux
        console.log("sender : ",newSender)
        if(newSender.isContactValidated==false){
          if(!opBranch.address){
            showMessage("Origin Branch Adress Not Exists",failed)
            return
          }
          if(what=="contact" &&!newSender.contact){
            showMessage("sender contact is Empty",failed)
            return
          }
          if(what=="name" &&!newSender.name){
            showMessage("sender contact is Empty",failed)
            return
          }
          if(!newSender.contact||!newSender.name){
          //  showMessage("receiver contact is Empty",failed)
            return
          }
          
         await dispatch(customerContactValidate("sender",  {name: newSender?.name?.value,
         ...(newSender.gst.value && gstRegex.test(newSender.gst.value)?{gst: newSender.gst.value}:{}),
         contact: newSender.contact,
         address:opBranch.address}))
        }
      }
      }
    } catch (err : any) {
      await dispatch({ type: HIDE_GST_LOADER });
      if (err.name == "ValidationError") {
        console.log("\n\nValidation error : \n\n", err);
        await dispatch(handleValidationError(["sender"], err.message));
      }
      console.log(err);
    }
  };
export const validateReceiver =
  (options?: { pre: "name" }): BookingAsyncActionType =>
  async (dispatch, getState) => {
    try {
      let { receiver, bookTouches, bookErrors } = getState().user;
      if (bookTouches && typeof bookTouches.toJS == "function") {
        const touched = bookTouches.toJS();
        const receiverTouched = touched.receiver;
        if (options && options.pre && typeof options.pre == "string") {
          receiverTouched[options.pre] = true;
        }
        const payload: any = {};
        const schema: any = {};
        for (let key in receiverTouched) {
          if (receiverTouched[key] === true) {
            payload[key] = receiver[key];
            let validator = bookingSchema.receiver[key];
            schema[key] = validator;
            try {
              await validator.validate(receiver[key]);
            } catch (err : any) {
              console.log("validate err : ", err);
            }
            bookErrors = bookErrors.setIn(
              ["receiver", key],
              !validator.isValidSync(receiver[key])
            );
          }
        }
        dispatch(
          handleValidationError(["receiver"], bookErrors.get("receiver"))
        );
      }
    } catch (err : any) {
      console.log(err);
      if (err.name == "ValidationError") {
        await dispatch(handleValidationError(["receiver"], err.message));
      }
    }
  };
export const bookChangeReceiver =
  (
    what:
      | "contact"
      | "l1"
      | "l2"
      | "pin"
      | "city"
      | "name"
      | "gst"
      | "receiverCode",
    val: any,
    reset?: boolean
  ): BookingAsyncActionType =>
    async (dispatch, getState) => {
      const { sender, receiver, deliveryType, ewb, receiverBranchAddress, purpose, isEwayBill, receiverSecondaryContactReqData, receiverGstContacts } = getState().booking;
      const { phoneVerificationSetting } = getState().allGeneralSettings
      try {
        if (what === "receiverCode" && !isEwayBill) {
          await dispatch({
            type: BOOK_RESET_RECEIVER,
            payload: "",
          });
        }
        if (what == "contact") {
          dispatch({
            type: BOOK_RECEIVER,
            payload: { what: "contactVerified", val: false },
          })

          if(reset){
            dispatch({
              type: BOOK_RECEIVER,
              payload: { what: "contact", val: "" },
            })
            dispatch({
              type: SET_RECEIVER_SECONDARY_CONTACT_REQ_DATA,
              payload: {
                dialog: false,
                step: 1,
                secondaryContactData: [],
                removedContact: "",
                newContact: ""
              },
            })
          }
        }


        if (what === "gst") {

          if(reset){
            dispatch({
              type: BOOK_RECEIVER,
              payload: { what: "gst", val: "" },
            })
            return
          }
          if (purpose?.value === "C" && isEwayBill === false) {
            let regexGstin = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}Z[0-9A-Z]{1}$/;
            console.log(`djkvsdvnlovf new 21 Passed FV`, `val?.value`, val?.value);
            if (!regexGstin.test(val?.value)) {
              if (val?.value?.trim() !== "URP") {
                showHttpError(`Gstin Number is Not in Valid Format ~!`);
                await dispatch({
                  type: BOOK_RECEIVER,
                  payload: { what, },
                });
                return;
              }
            }
            else if (regexGstin.test(val?.value)) {
              const gstNos = receiver?.customerType === 'P' ? receiver?.gstNos : receiver?.connectedContacts?.gstNos
              const primaryContact = receiver?.customerType === 'P' ? receiver?.contact : receiver?.connectedContacts?.contact

              if(val?.__isNew__){
                try{
                  await dispatch({ type: SHOW_LOADER });
                  const receiverGstContacts = await getConnectedContactsByGST(val?.value)
                  const receiverPrimary = receiverGstContacts?.primaryCustomer

                  const allowToConnect = receiver?.customerType === 'S' && !(receiver?.connectedContacts?.contact)

                  await dispatch({ type: HIDE_LOADER });
                  if(receiverPrimary && !allowToConnect){
                    await dispatch({ 
                      type: SET_GENERAL_WARNING, 
                      payload: `Receiver GST ${val?.value} is already connected with other primary customer. Please contact admin.`
                    })
                    return
                  }
                }catch(err){
                  await dispatch({ type: HIDE_LOADER });
                  showHttpError(err)
                  return 
                }
              }

              if(val?.__isNew__ && gstNos?.length >= bookingMaxGstCount){
                await dispatch({
                  type: SET_RECEIVER_GST_REMOVE_REQ_DATA,
                  payload: { 
                    dialog: true,
                    step: 1,
                    removeIndex: null,
                    gstData: gstNos,
                    newGst: "",
                    primaryContact
                  },
                });
                return
              }
              console.log(`djkvsdvnlovf new 21 Passed FV`, `val?.value`, val?.value);
              if (receiver && isArrayCheck(receiver.gstNos)) {
                const concernedGst = receiver.gstNos.find(
                  (g: { GSTIN: string }) => g.GSTIN == val.value
                );
                if (concernedGst && concernedGst.GSTIN) console.log(`djkvsdvnlovf new 21 Passed `, concernedGst, `val?.value`, val?.value);
                else {
                  await dispatch({ type: SHOW_LOADER });
                  try {
                    let customer = await getCustomerByGST({ gst: val.value });
                    const checkIsGstNameExists = receiver?.names?.find((name:any) => customer?.trade_name?.toUpperCase() === name?.toUpperCase())
                    await dispatch({
                      type: SET_RECEIVER_BY_GST,
                      payload: { ...customer, receiverForceNameRemoveDueToGst: receiver?.names?.length >= bookingMaxNameCount && !checkIsGstNameExists, receiverGstRelativeFieldDisable: true},
                    });
                    await dispatch(validateReceiver());
                    await dispatch({ type: HIDE_LOADER });
                  } catch (err : any) {
                    showMessage("Please Check And Try Again", failed);
                    console.log(err);
                    await dispatch({
                      type: RECEIVER_GST_INVALID,
                    });
                    await dispatch({
                      type: BOOK_RECEIVER,
                      payload: { what, },
                    });
                    await dispatch({ type: HIDE_LOADER });
                    return
                  }
                }
              }
              else {
                await dispatch({ type: SHOW_LOADER });
                try {
                  let customer = await getCustomerByGST({ gst: val.value });
                  const checkIsGstNameExists = receiver?.names?.find((name:any) => customer?.trade_name?.toUpperCase() === name?.toUpperCase())
                  await dispatch({
                    type: SET_RECEIVER_BY_GST,
                    payload: { ...customer, receiverForceNameRemoveDueToGst: receiver?.names?.length >= bookingMaxNameCount && !checkIsGstNameExists, receiverGstRelativeFieldDisable: true},
                  });
                  await dispatch(validateReceiver());
                  await dispatch({ type: HIDE_LOADER });
                } catch (err : any) {
                  showMessage("Please Check And Try Again", failed);
                  console.log(err);
                  await dispatch({
                    type: RECEIVER_GST_INVALID,
                  });
                  await dispatch({
                    type: BOOK_RECEIVER,
                    payload: { what, },
                  });
                  await dispatch({ type: HIDE_LOADER });
                  return
                }
              }
            }
          }
          else {
            return showHttpError(`Write a Perfect Gstin Number ~!`)
          }
        }

        dispatch({
          type: BOOK_RECEIVER,
          payload: { what, val },
        });
        if (
          what == "pin" &&
          deliveryType &&
          deliveryType.value == "Home Delivery"
        ) {
          if (val.length == 6) {
            try {
              dispatch({
                type: SET_FEDEX_SERVICE_OPTIONS,
                payload: "loading",
              });
              const payload = {
                sourcePincode: sender.pin,
                destinationPincode: val,
                packages: [
                  {
                    qty: 1,
                    weight: 10,
                  },
                ],
                useDimensions: false,
              };
              const [fedexResponse, city] = await Promise.all([
                axios.post(url + "/fedex/availableRate", payload),
                getCityByPincode(val),
              ]);

              if (fedexResponse.status == 200) {
                const fedexOptions = fedexResponse.data.response;

              dispatch({
                type: SET_FEDEX_SERVICE_OPTIONS,
                payload: fedexOptions.map((opt: any) => ({
                  value: opt.type,
                  label:
                    opt.type +
                    " | " +
                    opt.Currency +
                    " " +
                    Math.ceil(Number(opt.Amount)) +
                    " per 10KG" +
                    " | Delivery_Time: " +
                    new Date(opt.expectedDeliveryTime).toLocaleDateString(),
                })),
              });
              dispatch({
                type: BOOK_RECEIVER,
                payload: { what: "city", val: city.name },
              });
            } else {
              dispatch({
                type: SET_FEDEX_SERVICE_OPTIONS,
                payload: "unavailable",
              });
              showMessage(fedexResponse.data.message, "salmon");
            }
          } catch (err : any) {
            await dispatch({
              type: SET_FEDEX_SERVICE_OPTIONS,
              payload: "unavailable",
            });
            showMessage(err.data.message, "salmon");
          }
        } else {
          dispatch({
            type: SET_FEDEX_SERVICE_OPTIONS,
            payload: null,
          });
          dispatch({
            type: BOOK_CHANGE_FEDEX_SERVICE_TYPE,
            payload: null,
          });
          dispatch({
            type: BOOK_RECEIVER,
            payload: { what: "city", val: "" },
          });
        }
      }
      if (what == "contact" && val.length == 10) {
        let receiverDoc
        try {
           receiverDoc = await fetchCustomerByContactGeneralService({
            contact: val,
          });

          if(receiver?.gst?.value){
            const checkIsGstNameExists = receiverDoc?.names?.find((name:any) => receiver?.name?.value?.toUpperCase() === name?.toUpperCase())
            const receiverForceNameRemoveDueToGst = receiverDoc?.names?.length >= bookingMaxNameCount && !checkIsGstNameExists
            await dispatch({
              type: SET_RECEIVER_FORCE_NAME_REMOVE_DUE_TO_GST,
              payload: receiverForceNameRemoveDueToGst,
            });
          }

          if(isEwayBill){
            const res = await getConnectedContactsByGST(receiver?.gst?.value)
            const receiverPrimary = res?.primaryCustomer
            const receiverSecondary = res?.secondaryContacts

            const receiverGstContacts = [...(receiverPrimary ? [receiverPrimary]: []), ...(receiverSecondary.length ? receiverSecondary : [])]
        
            await dispatch({
              type: SET_RECEIVER_GST_CONTACTS,
              payload: receiverGstContacts,
            });

            const isAlreadySecondary = receiverGstContacts?.find((c:any) => c?.contact?.toString() === val.toString())
            if(!isAlreadySecondary){
              const secondaryContacts = receiverGstContacts?.filter((c: any) => c.customerType === "S")
              const primaryContact = receiverGstContacts?.find((c: any) => c.customerType === "P")?.contact
              
              if(receiverDoc?.customerType === "P"){
                showHttpError('Receiver is already primary customer')
              }

              if(receiverDoc?.customerType === "S" && receiverDoc?.connectedContacts?.length){
                showHttpError('Receiver is already connected with other primary customer')
              }
              if(secondaryContacts.length >= bookingMaxSecondaryContactCount){
                await dispatch({
                  type: BOOK_RECEIVER,
                  payload: { what, val: '' },
                });

                if(receiverSecondaryContactReqData.step === 2 && receiverSecondaryContactReqData.newContact.toString() === val.toString()){
                  showHttpError('Your request is not approved yet.')
                  return
                }else{
                  await dispatch({
                    type: SET_RECEIVER_SECONDARY_CONTACT_REQ_DATA,
                    payload: { 
                      dialog: true,
                      step: 1,
                      secondaryContactData: secondaryContacts,
                      newContact: val.toString(),
                      primaryContact
                    },
                  });
                }
                return
              }
            }else{
              await dispatch({
                type: SET_RECEIVER_SECONDARY_CONTACT_REQ_DATA,
                payload: { 
                  dialog: false,
                  step: 1,
                  secondaryContactData: receiverSecondary,
                  removedContact: "",
                  newContact: ""
                },
              });
            }
          }else{
            const connectedContacts = await getConnectedCustomersByContact(val)
            const receiverPrimary = connectedContacts?.primaryCustomer
            const receiverSecondary = connectedContacts?.secondaryContacts

            const receiverGstContacts = [...(receiverPrimary ? [receiverPrimary] : []), ...(receiverSecondary.length ? receiverSecondary : [])]
        
            await dispatch({
              type: SET_RECEIVER_GST_CONTACTS,
              payload: receiverGstContacts,
            });
          }

            await dispatch({
              type: BOOK_SET_RECEIVER,
              payload: receiverDoc,
            });
            if (phoneVerificationSetting) {
              if (receiverDoc && receiverDoc.isContactValidated == false) {
                await dispatch(customerContactValidate("receiver", {
                  name: receiverDoc.name,
                  contact: receiverDoc.contact
                }))
              }
            }
          } catch (err : any) {
            const secondaryContacts = receiverGstContacts?.filter((c: any) => c.customerType === "S")
            const primaryContact = receiverGstContacts?.find((c: any) => c.customerType === "P")?.contact
            if(secondaryContacts.length >= bookingMaxSecondaryContactCount){
              await dispatch({
                type: SET_RECEIVER_SECONDARY_CONTACT_REQ_DATA,
                payload: { 
                  dialog: true,
                  step: 1,
                  secondaryContactData: secondaryContacts,
                  newContact: val.toString(),
                  primaryContact
                },
              });
            }

            if (!ewb) {
              await dispatch({
                type: RESET_RECEIVER_NAME,
              });
            }

            console.log(err);
          }

        } else if (what == "receiverCode" && val.length >= 3) {
          try {
            const receiverDoc = await fetchCustomerByCustomerCodeService({
              customerCode: val,
            });

          if (receiverDoc) {
            if (phoneVerificationSetting) {
              if (receiverDoc && receiverDoc.isContactValidated == false) {
                await dispatch(customerContactValidate("receiver", {
                  name: receiverDoc.name,
                  contact: receiverDoc.contact
                }))
              }
            }
            await dispatch({
              type: BOOK_SET_IS_RECEIVER_DISABLE,
              payload: true,
            });
            if(isEwayBill){
              await dispatch({
                type: BOOK_RECEIVER,
                payload: { what: "contact", val: receiverDoc.contact },
              });
            }else{
              await dispatch({
                type: BOOK_SET_RECEIVER,
                payload: receiverDoc,
              });
            }
          }
          // if (!ewb) {
          //   await dispatch({
          //     type: RESET_SENDER_NAME,
          //   });
          // }
        } catch (err : any) {
          // if (!ewb) {
          //   await dispatch({
          //     type: RESET_SENDER_NAME,
          //   });
          // }
          console.log(err);
        }
      } else if (what == "gst" && val.value && val.value.length == 15) {
        if (receiver && isArrayCheck(receiver.gstNos)) {
          const concernedGst = receiver.gstNos.find(
            (g: { GSTIN: string }) => g.GSTIN == val.value
          );
          if (concernedGst && concernedGst.GSTIN) return;
        }
        const payload = { gst: val.value, receiver };
        dispatch({ type: SHOW_GST_LOADING_FOR_RECEIVER });
        await dispatch(getReceiverByGST(payload));
        dispatch({ type: HIDE_GST_LOADING_FOR_RECEIVER });
      } 
       if(what=="name" ||(what == "contact" && val.length == 10)||what=="gst"){
        if(phoneVerificationSetting){
        const {receiver:newReceiver} = getState().booking;//newreceiver for access latest value from redux
        if(newReceiver.isContactValidated==false){
          if(!receiverBranchAddress){
            showMessage("Dest Branch Adress Not Exists",failed)
            return
          }
          if(what=="contact" &&!newReceiver.contact){
            showMessage("receiver contact is Empty",failed)
            return
          }
          if(what=="name" &&!newReceiver.name){
            showMessage("receiver contact is Empty",failed)
            return
          }
          if(!newReceiver.contact||!newReceiver.name){
          //  showMessage("receiver contact is Empty",failed)
            return
          }
         await dispatch(customerContactValidate("receiver",  {name: newReceiver?.name?.value,
         ...(newReceiver.gst.value &&gstRegex.test(newReceiver.gst.value)?{gst: newReceiver.gst.value}:{}),
         contact: newReceiver.contact,
         address:{...receiverBranchAddress,city:receiverBranchAddress?.city?._id}}))
        }
      }
      }
    } catch (err : any) {
      dispatch({ type: HIDE_GST_LOADING_FOR_RECEIVER });
      if (err.name == "ValidationError") {
        console.log("\n\nValidation error : \n\n", err);
        await dispatch(handleValidationError(["receiver"], err.message));
      }
      console.log(err);
    }
  };

export const setPackageSuggestions =
  ({
    senderContact,
    receiverContact,
    destination,
  }: {
    senderContact: string;
    receiverContact: string;
    destination: string;
  }): BookingAsyncActionType =>
    async (dispatch) => {
      try {
        const packageSuggestions = await suggestPacking({
          senderContact,
          receiverContact,
          destination,
        });

        await dispatch({
          type: SET_PACKAGE_SUGGESTIONS,
          payload: packageSuggestions,
        });
      } catch (err : any) {
        await dispatch({ type: SET_PACKAGE_SUGGESTIONS, payload: null });
      }
    };
export const clearPackageSuggestions =
  (): BookingAsyncActionType => async (dispatch, getState) => {
    const { packageSuggestions } = getState().user;
    if (packageSuggestions) {
      await dispatch({ type: SET_PACKAGE_SUGGESTIONS, payload: null });
    }
  };

export const touchSender = (
  what: "name" | "gst" | "contact" | "l1" | "l2" | "pin" | "city"
): TouchSenderAction => ({ type: TOUCH_SENDER, payload: what });

export const touchReceiver = (
  what: "name" | "gst" | "contact" | "l1" | "l2" | "pin" | "city"
): TouchReceiverAction => ({
  type: TOUCH_RECEIVER,
  payload: what,
});

export const selectBookService: any = (route: SelectValue): BookingAsyncActionType => {
  return async (dispatch) => {
    try {
      console.log("route : ",route)
      await dispatch({
        type: BOOK_DEST_SERVICE,
        payload: route,
      });
      await bookingSchema.bookService.validateSync(route.value);
      await dispatch(handleValidationError(["bookService"], false));
    } catch (err : any) {
      if (err.name == "ValidationError") {
        dispatch(handleValidationError(["bookService"], err.message));
      }
      console.log(err);
    }
  };
};

export const updateBuilty = (): UpdateBuiltyAction => ({ type: UPDATE_BUILTY });

export const book: any = (
  json: any,
  callbacks?: Function[]
): BookingAsyncActionType => {
  return async (dispatch, getState) => {
    try {
      await dispatch({ type: SHOW_LOADER });
      let response = await axios.post(
        url + "/booking/create",
        jsonToFormdata(json)
      );
      // if (response.data.status != 1) throw "booking failed";
      const { balance, booking, docket, packages,
        //  CCAvenueTxnData
      } = response.data.response;
      // if(CCAvenueTxnData.encRequest){
      //   const a = document.createElement("a");
      //   a.setAttribute("target", "_blank");
      //   a.href = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${CCAvenueTxnData.encRequest}&access_code=${CCAvenueTxnData.accessCode}`;
      //   document.body.appendChild(a);
      //   a.click();
      // }
      await dispatch({
        type: SET_LR,
        payload: booking.docketNumber,
      });
      showMessage("Booking Successfull", success);

      await dispatch({
        type: SET_ORANGE_LR,
        payload: booking.isOrangeBooking
          ? booking.orangeDetails.lrNumber
          : null,
      });

      if (balance && !isNaN(balance.balance)) {
        try {
          await dispatch(updateBalance(balance.balance, balance));
        } catch (err : any) {
          console.log(err);
        }
      }
      try {
        printBuilty(docket, true, false, false, false, getState().user.opBranch);
        if (getState().user.opBranch.qrPrinter) {
          printQRDocket(docket, packages);
        }
      } catch (err : any) {
        showMessage("Builty print failed!", failed);
      }

      // dispatch(resetEdit());
      if (Array.isArray(callbacks) && callbacks.length > 0) {
        callbacks.forEach((cb) => {
          if (typeof cb == "function") {
            cb();
          }
        });
      }
      await dispatch({ type: HIDE_LOADER });
    } catch (err : any) {
      showHttpError(err);
      // showMessage(err || "something went wrong", failed, 5000);
      console.log("got error : ", err);
      dispatch({ type: HIDE_LOADER });
      throw err;
    }
  };
};

export const editDocket =
  (
    id: string,
    json: {
      sender: any;
      receiver: any;
      idImage: any;
      docketImages: any;
      billImage: any;
      delSpecific: any;
    }
  ): BookingAsyncActionType =>
    async (dispatch, getState) => {
      try {
        const {
          sender,
          receiver,
          idImage,
          docketImages,
          billImage,
          delSpecific,
        } = json;
        const payload = {
          fromBranch: (getState().user.opBranch || {})._id,
          sender,
          receiver,
          idImage,
          docketImages,
          billImage,
          delSpecific,
        };
        dispatch({ type: SHOW_LOADER });
        try {
          await editDocketService(id, jsonToFormdata(payload));
          showMessage("Docket edited successfully", success);
          dispatch({ type: HIDE_LOADER });
          toFirstPage();
        } catch (err : any) {
          dispatch({ type: HIDE_LOADER_WITHOUT_BLUR });
          dispatch({ type: HIDE_LOADER });
          toFirstPage();
        }
        // const step3 = document.getElementById("Builty_Print");
        // if (step3) {
        //   step3.style.display = "block";
        // }
        // const step1 = document.getElementById("step1");
        // if (step3) {
        //   step3.style.display = "none";
        // }
        // if (step1) {
        //   step1.style.display = "block";
        // }
      } catch (err : any) {
        console.log(err);
        dispatch({ type: HIDE_LOADER });
        // const step3 = document.getElementById("Builty_Print");
        // const step1 = document.getElementById("step1");
        // if (step3) {
        //   step3.style.display = "none";
        // }
        // if (step1) {
        //   step1.style.display = "block";
        // }
      }
    };
export const toggleSpecificDelivery = (): SpecificDeliveryAction => ({
  type: SPECIFIC_DELIVERY,
});

export const getSenderByGST =
  (data: { gst: string, sender:any }): any =>
    async (dispatch: any) => {
      try {
        await dispatch({ type: SHOW_LOADER });
        const customer = await getCustomerByGST(data);
        const checkIsGstNameExists = data?.sender?.names?.find((name:any) => customer?.trade_name?.toUpperCase() === name?.toUpperCase())
        await dispatch({
          type: SET_SENDER_BY_GST,
          payload: { ...customer, senderForceNameRemoveDueToGst: data?.sender?.names?.length >= bookingMaxNameCount && !checkIsGstNameExists, senderGstRelativeFieldDisable: true},
        });
        await dispatch({ type: HIDE_LOADER });
        await dispatch(validateSender());
        return true;
      } catch (err : any) {
        showMessage("Please Check And Try Again", failed);
        console.log(err);
        dispatch({
          type: SENDER_GST_INVALID,
        });
        await dispatch({ type: HIDE_LOADER });

        return false;
      }
    };
export const getReceiverByGST =
  (data: { gst: string, receiver: any }): BookingAsyncActionType =>
  async (dispatch) => {
    try {
      await dispatch({ type: SHOW_LOADER });
      let customer = await getCustomerByGST(data);
      const checkIsGstNameExists = data?.receiver?.names?.find((name:any) => customer.trade_name.toUpperCase() === name.toUpperCase())
      await dispatch({
        type: SET_RECEIVER_BY_GST,
        payload: { ...customer, receiverForceNameRemoveDueToGst: data?.receiver?.names.length >= bookingMaxNameCount && !checkIsGstNameExists, receiverGstRelativeFieldDisable: true},
      });
      await dispatch({ type: HIDE_LOADER });
      await dispatch(validateReceiver());
    } catch (err : any) {
      console.log(err);
      dispatch({
        type: RECEIVER_GST_INVALID,
      });
      await dispatch({ type: HIDE_LOADER });
    }
  };
  export function builtyUpdateAck ():OtherBookingActions{
    return {
      type: BUILTY_UPDATE_ACK
    }
  };
  
  export const builtyUpdateReq = (): OtherBookingActions => ({
    type: BUILTY_UPDATE_REQ,
  });

export const printTrackBuilty =
  (): BookingAsyncActionType => async (dispatch) => {
    try {
      await dispatch(builtyUpdateReq());
      const elem = document.getElementById("step3");
      if (!elem) return;
      const d = new Printd();
      setTimeout(() => {
        d.print(elem, builtyCSS);
      }, 1000);
    } catch (err : any) {
      console.log(err);
    }
  };

export const packageUpdateAck = (): OtherBookingActions => ({
  type: PACKAGE_UPDATE_ACK,
});

export const paymentUpdateAck = (): OtherBookingActions => ({
  type: PAYMENT_UPDATE_ACK,
});

export const idFetchAck = (): OtherBookingActions => ({ type: ID_FETCH_ACK });

export const billFetchAck = (): OtherBookingActions => ({
  type: BILL_FETCH_ACK,
});

export const docketsFetchAck = (): OtherBookingActions => ({
  type: DOCKETS_FETCH_ACK,
});

export const resetSenderAddress = (): OtherBookingActions => ({
  type: RESET_SENDER_ADDRESS,
});

export const resetReceiverAddress = (): OtherBookingActions => ({
  type: RESET_RECEIVER_ADDRESS,
});

export const setSenderAddressFromBranch =
  (): SetSenderAddressFromOpBranchAction => ({
    type: SET_SENDER_ADDRESS_FROM_OPBRANCH,
  });

export const setReceiverAddressFromBranch =
  (): SetReceiverAddressFromOpBranchAction => ({
    type: SET_RECEIVER_ADDRESS_FROM_OPBRANCH,
  });

export const setSenderAddress = (index: number): BookingAsyncActionType => {
  return async (dispatch, getState) => {
    const { otherAddr } = getState().booking.sender;
    dispatch({
      type: SET_SENDER_ADDRESS_FROM_OTHER,
      payload: otherAddr[index],
    });
  };
};
export const setReceiverAddress = (index: number): BookingAsyncActionType => {
  return async (dispatch, getState) => {
    const { otherAddr } = getState().booking.receiver;
    dispatch({
      type: SET_RECEIVER_ADDRESS_FROM_OTHER,
      payload: otherAddr[index],
    });
  };
};
export const setRoutes =
  (): BookingAsyncActionType => async (dispatch, getState) => {
    try {
      const { user } = getState();
      const { id: company } = user.company;
      const result = await bookFilterRoute({ company });
      await dispatch({
        type: SET_ROUTES,
        payload: result,
      });
    } catch (err : any) {
      console.log("got err:", err);
    }
  };
export const setSettings = (): BookingAsyncActionType => async (dispatch) => {
  try {
    // console.log(`Settings Api Called From action booking index.tsx Entry`)
    const result = await getSettingsService();
    // console.log(`Settings Api Called From action booking index.tsx Eexit`,result)
    await dispatch({
      type: SET_SETTINGS,
      payload: result,
    });
  } catch (err : any) {
    console.log("err", err);
  }
};
export const setHandlingChargeSlab = (): BookingAsyncActionType => async (dispatch) => {
  try {
    // console.log(`Settings Api Called From action booking index.tsx Entry`)
    const result = await listHandlingChargeSlab();
    // console.log(`Settings Api Called From action booking index.tsx Eexit`,result)
    await dispatch({
      type: SET_HANDLING_CHARGE_SLAB,
      payload: result,
    });
  } catch (err : any) {
    console.log("err", err);
  }
};
export const setPackageErrorFlagFalse = (): SetPackageErrorFlagFalseAction => ({
  type: SET_PACKAGE_ERROR_FLAG_FALSE,
});
export const pickUpChargeChange = (
  what: "pickupCharge" | "deliveryCharge",
  val: string
): PickupChargeChangeAction => ({
  type: PICKUP_CHARGE_CHANGE,
  payload: {
    what,
    val,
  },
});
export const resetReceiverName = (): ResetReceverNameAction => ({
  type: RESET_RECEIVER_NAME,
});

export const resetSenderName = (): ResetSenderNameAction => ({
  type: RESET_SENDER_NAME,
});
export const resetRemarks = (): ResetRemarksAction => ({ type: RESET_REMARKS });

export const setFocVerification = (
  what: any,
  val: any
): setFocVerificationAction => ({
  type: BOOK_SET_FOC_VERIFICATION,
  payload: {
    what,
    val,
  },
});

export const setSenderVerification = (
  what: any,
  val: any
): setSenderVerificationAction => ({
  type: BOOK_SET_SENDER_VERIFICATION,
  payload: {
    what,
    val,
  },
});

export const setFetchDocketPkgData = (val: any) => ({
  type: SET_UPDATE_PKG_FETCHDOCKET,
  payload: val,
});

export const setRateId = (val: any) => ({
  type: SET_RATE_ID,
  payload: val,
});


export const setBookVerification = (what: any, val: any) => ({
  type: BOOK_SET_VERIFICATION,
  payload: {
    what,
    val,
  },
});

export const setEmployeeVerification = (what: any, val: any) => ({
  type: BOOK_SET_VERIFICATION,
  payload: {
    what,
    val,
  },
});
export const setDoorDeliveryData = (
  val: any
): setDoorDeliveryActionData => ({
  type: SET_DOOR_DELIVERY_DATA,
  payload: val,
});

export const setDoorDelivery = (
  what: any,
  val: any
): setDoorDeliveryAction => ({
  type: SET_DOOR_DELIVERY,
  payload: {
    what,
    val,
  },
});
export const resetDoorDelivery = (
) => ({
  type: RESET_DOOR_DELIVERY,
});
export const resetCustomerValidate = (what: "sender" | "receiver") => ({
  type: (what == "sender" ? BOOK_SET_SENDER_VALIDATE : BOOK_SET_RECEIVER_VALIDATE),
  payload: { isContactValidated: false }
})
export const customerContactValidate =
(
  what: "sender"|"receiver", val: any
): BookingAsyncActionType =>
async (dispatch) =>
{
  try {
    dispatch(showLoader())
  const response= await fetchCustomerByContact(val)
    dispatch({
    type:(what=="sender" ?BOOK_SET_SENDER_VALIDATE:BOOK_SET_RECEIVER_VALIDATE),
    payload:{isContactValidated:response.isContactValidated,message:response.message}
    })
    dispatch(hideLoader())
  } catch (err : any) {
    dispatch(hideLoader())
    showHttpError(err)
    dispatch({
      type:(what=="sender" ?BOOK_SET_SENDER_VALIDATE:BOOK_SET_RECEIVER_VALIDATE),
      payload:{isContactValidated:false}
      })
  }
}
export const resetPackagesAmount=(): BookingAsyncActionType =>async (dispatch, getState) =>{
  const {packages}=getState().booking
  packages.map((pkg:any)=>{
  dispatch({type:BOOKING_EDIT_PACKAGE,payload: {
    id: pkg.id,
    name: "amount",
    val: "",
  }})
  })
  // type:BOOK_SET_FIXRATECUSTOMER,
}
export const setFixRateCustomer = (data: { name: string, contact: number, customerType: "sender" | "receiver" }) => ({
  type: BOOK_SET_FIXRATECUSTOMER,
  payload: data
})
export const resetFixRateCustomer = () => ({
  type: BOOK_RESET_FIXRATECUSTOMER,
})
export const setIndividualFleet = (what: string, val: any) => ({
  type: BOOK_SET_INDIVIDUALFLEET,
  payload: { what, val }
})
export const SetLastBookingDetails = (val: any) => ({
  type: SET_LAST_BOOKING,
  payload: val
}) 

export const setSenderForceNameRemoveDueToGst = (val: any) => ({
  type: SET_SENDER_FORCE_NAME_REMOVE_DUE_TO_GST,
  payload: val
}) 

export const setReceiverForceNameRemoveDueToGst = (val: any) => ({
  type: SET_RECEIVER_FORCE_NAME_REMOVE_DUE_TO_GST,
  payload: val
}) 

export const setCustomerContactValidate =(what: "sender"|"receiver", val: any): BookingAsyncActionType =>
async (dispatch) =>
{
  dispatch({
    type:(what=="sender"? BOOK_SET_SENDER_VALIDATE : BOOK_SET_RECEIVER_VALIDATE),
    payload:{isContactValidated: val}
  })
}

export const setSenderGstRemoveReqData = (val: any) => ({
  type: SET_SENDER_GST_REMOVE_REQ_DATA,
  payload: val
})

export const setReceiverGstRemoveReqData = (val: any) => ({
  type: SET_RECEIVER_GST_REMOVE_REQ_DATA,
  payload: val
})

export const setSenderSecondaryContactReqData = (val: any) => ({
  type: SET_SENDER_SECONDARY_CONTACT_REQ_DATA,
  payload: val
})

export const setReceiverSecondaryContactReqData = (val: any) => ({
  type: SET_RECEIVER_SECONDARY_CONTACT_REQ_DATA,
  payload: val
})

export const setGeneralWarning = (val: any) => ({
  type: SET_GENERAL_WARNING,
  payload: val
})