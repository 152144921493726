import React from "react";
import formatDate from "../../../services/FormatDate";

const Report = (props: any) => {
  const { records } = props;
  let totalPackages = 0;
  let totalDockets = 0;
  let paid = 0;
  let topay = 0;
  let foc = 0;
  let credit = 0;
  return (
    <div id="customer_report_actual">
      {records.length > 0 ? (
        <div>
          <div className="book_report_heading">
            <div>Customer Report</div>
          </div>
          <table>
            <tbody>
              <tr className="book_report_row_heading">
                <th>Date</th>
                <th>Docket</th>
                <th>Qty</th>
                <th>Sender</th>
                <th>Receiver</th>
                <th>Credit Customer</th>
                <th>Payment Mode</th>
                <th>Goods Value</th>
                <th>Builty Charges</th>
                <th>Handling Charges</th>
                <th>Gross Value</th>
                <th>Settled</th>
                <th>Credit Settled</th>
              </tr>
            </tbody>
            {records &&
              records.length > 0 &&
              records.map((item: any, i: any) => {
                console.log("item is :", item);
                totalDockets += 1;
                totalPackages += item.packages.reduce(
                  (a: any, b: any) => a + b.qty,
                  0
                );
                switch (item.paymentMode) {
                  case "paid":
                    paid += item.gross;
                    break;
                  case "topay":
                    topay += item.gross;
                    break;
                  case "foc":
                    foc += item.gross;
                    break;
                  case "credit":
                    credit += item.gross;
                    break;
                  default:
                    console.log(
                      "unhandled payment mode in report print:-- ",
                      item.paymentMode
                    );
                }
                return (
                  <tbody>
                    <tr
                      key={i}
                      className="book_report_row"
                      style={{ pageBreakInside: "avoid" }}
                    >
                      <td className="date">{formatDate(item.createdAt)}</td>
                      <td>
                        <div>{item.docketNumber}</div>
                      </td>
                      <td>
                        <div>
                          {item.packages.reduce(
                            (a: any, b: any) => a + b.qty,
                            0
                          )}
                        </div>
                      </td>
                      <td className="qty">
                        {item.senderName} - {item.senderContact}
                      </td>

                      <td className="qty">
                        {item.receiverName} - {item.receiverContact}
                      </td>
                      <td className="qty">
                        {item.creditCustomer
                          ? item.creditCustomer.name +
                            "-" +
                            item.creditCustomer.contact
                          : "--"}
                      </td>
                      <td className="qty">{item.paymentMode}</td>
                      <td className="goods">{item.goods}</td>
                      <td className="goods">{item.bilty}</td>
                      <td className="goods">{item.handling}</td>

                      <td className="qty">{item.gross}</td>
                      <td className="qty">{item.settled ? "true" : "false"}</td>
                      <td className="qty">
                        {item.creditSettled ? "true" : "false"}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
          </table>
          <br />
          <table style={{ float: "right", pageBreakInside: "avoid" }}>
            <tbody>
              {/* <tr>
            <th colSpan={6}>Total</th>
            </tr> */}
              <tr>
                <th>Dockets</th>
                <th>Packages</th>
                <th>Paid</th>
                <th>Topay</th>
                <th>foc</th>
                <th>credit</th>
                <th>totalAmount</th>
              </tr>
              <tr>
                <td>{totalDockets}</td>
                <td>{totalPackages}</td>
                <td>{paid}</td>
                <td>{topay}</td>
                <td>{foc}</td>
                <td>{credit}</td>
                <td>{paid + topay + foc + credit}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div>hello</div>
      )}
    </div>
  );
};

export default Report;
