import React from 'react'
import "../Page_404/page_404.css"
import { useLocation, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "../../../store"
// import { useHistory } from "react-router-dom"

export const Page_404 = () => {
    // const history = useHistory()
    const user = useSelector((state: RootState) => state.user)
    const customerReducer = useSelector((state: RootState) => state.customerLoginReducer)
    const locationDetails : any = useLocation()

    const isAuthenticated = () => {

        if (user.token && user.userName && sessionStorage.getItem("kabra:token")) {
          if (user.branches.length === 0 && user.fleets.length === 0) {
            return true
          }
          else {
            return true
          }
        };
        return false
      }
    
      const isAuthenticatedCustomer = () => {
        if (customerReducer && customerReducer?.token && customerReducer?.name && localStorage.getItem("kabra:customertoken")) {
          if (locationDetails && locationDetails?.state?.from === "Payment_Success") {
            return true
          }
          else {
           return true
          }
        }
        return false
      }

    const navigate = useNavigate()
    return (
        <div className="container1">
            <div className="container404"></div>
            <button className="btn_home_404" onClick={() => {
                // localStorage.removeItem("navigate_404")
                if(isAuthenticated() || isAuthenticatedCustomer()){
                    navigate(-1)
                }
                else{
                    navigate("/")
                }
            }}>Go to Home</button>
        </div>
    )
}
