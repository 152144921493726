import React from "react";
import Drawer, { DrawerPropsType } from "../UI/drawer/drawer";
import FilterButton from "./filter-button";
import ApplyButton from "./apply-button";

type FilterDrawerProps = {
  onApplyClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  hideApplyButton?: boolean;
  newFilterButton?: boolean;
  onClick?: () => void;
  onOpen?: () => void;
} & DrawerPropsType;

const FilterDrawer = ({
  state,
  setState,
  drawerBody,
  onApplyClick,
  maxWidth = "auto",
  hideApplyButton = false,
  drawerHeading,
  newFilterButton = false,
  onClick,
  onOpen,
  ...props
}: FilterDrawerProps) => {
  return (
    <>
      <FilterButton onClick={onClick} onOpen={onOpen} setShowModel={setState} newButton={newFilterButton} />
      <Drawer
        maxWidth={maxWidth}
        drawerBackground="white"
        drawerBackgroundShades={900}
        drawerColor="black"
        drawerColorShades={900}
        state={state}
        position="right"
        setState={setState}
        drawerHeading={drawerHeading ?? "Filter"}
        isClickOutsideClose={true}
        drawerBody={
          <>
            {drawerBody}
            {!hideApplyButton && (
              <div className="mt-1">
                <ApplyButton
                  onClick={(e) => {
                    if (onApplyClick) {
                      onApplyClick(e);
                    }
                  }}
                />
              </div>
            )}
          </>
        }
        {...props}
      />
    </>
  );
};

export default FilterDrawer;
