const prefix = "CUSTOMER:";

export const CHANGE_PERMITTED_BRANCH = prefix + "CHANGE_PERMITTED_BRANCH";
export const CHANGE_CONNECTED_CUSTOMERS = prefix + "CHANGE_CONNECTED_CUSTOMERS";
export const CREDIT_FIELD_CHANGE = prefix + "CREDIT_FIELD_CHANGE";
export const RATES_ADD = prefix + "RATES_ADD";
export const RATES_DEL = prefix + "RATES_DEL";
export const RATES_EDIT = prefix + "RATES_EDIT";
export const GET_MATERIAL_TYPES = prefix + "GET_MATERIAL_TYPES";
export const NEW_CREDIT_SUBMIT = prefix + "NEW_CREDIT_SUBMIT";
export const FETCH_DUE_CREDIT = prefix + "FETCH_DUE_CREDIT";
export const SETTLE_DUES_SUCCESS = prefix + "SETTLE_DUES_SUCCESS";
// export const CHANGE_PACKAGE_AMOUNT_SETTLEMENT =
//   prefix + "CHANGE_PACKAGE_AMOUNT_SETTLEMENT";
export const CHANGE_PACKAGE_AMOUNT = prefix + "CHANGE_PACKAGE_AMOUNT";
export const CHANGE_PACKAGE_AMOUNT_BULK = prefix + "CHANGE_PACKAGE_AMOUNT_BULK";
export const SET_EFFECTIVE_PACKAGE_AMOUNT_BULK =
  prefix + "SET_EFFECTIVE_PACKAGE_AMOUNT_BULK";
export const RESET_PACKAGE_AMOUNTS = prefix + "RESET_PACKAGE_AMOUNTS";
export const RESET_ALL_PACKAGE_AMOUNTS = prefix + "RESET_ALL_PACKAGE_AMOUNTS";
export const GET_INVOICE_DETAILS = prefix + "GET_INVOICE_DETAILS";
export const CLEAR_RECEIPT_NUMBER = prefix + "CLEAR_RECEIPT_NUMBER";
export const TOGGLE_CREDIT_ROW = prefix + "TOGGLE_CREDIT_ROW";
export const SELECT_ALL_CREDIT_DOCKET = prefix + "SELECT_ALL_CREDIT_DOCKET";
export const CREDIT_UPDATED_SUCCESS = prefix + "CREDIT_UPDATED_SUCCESS";
export const CLOSE_CREDIT_PRINT = prefix + "CLOSE_CREDIT_PRINT";
export const USER_ALL_BALANCES = prefix + "USER_ALL_BALANCES";

export enum Potentiality {
  High = "High",
  Medium = "Medium",
  Low = "Low",
}

export enum CustomerUpdateRequestType {
  NameUpdate = "NameUpdate",
  GstUpdate = "GstUpdate",
  SecondaryContactUpdate = "SecondaryContactUpdate"
}
