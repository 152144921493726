export function acrossFun(val?: "interState" | "intraState" | "International") {
  switch (val) {
    case "interState": {
      return "Inter-State";
    }
    case "intraState": {
      return "Intra-State";
    }
    case "International": {
      return "International";
    }
    default: {
      return 0;
    }
  }
}
