import React, { type ComponentProps } from "react";
import { CSSColorShadesType, CSSColorVariantType } from "../../../types/common";
import "./pill.css";

type PillProps = {
  bgColorVariant?: CSSColorVariantType;
  bgColorShade?: CSSColorShadesType;
  textColorVariant?: CSSColorVariantType;
  textColorShade?: CSSColorShadesType;
  shape?: "oval" | "normal";
  size?: "extra-small" | "small" | "regular" | "medium" | "large";
  className?: string;
} & ComponentProps<"span">;

export function Pill({
  bgColorVariant = "primary",
  bgColorShade = 50,
  textColorVariant = "primary",
  textColorShade = 900,
  className = "",
  children,
  shape = "oval",
  size = "small",
  ...props
}: PillProps) {
  const pillClassName =
    className +
    " " +
    `fw-500 taptap-pill taptap-pill-fs-${size} ${
      shape === "oval" ? "oval" : "br-4"
    } bg-${bgColorVariant}-${bgColorShade} text-${textColorVariant}-${textColorShade}`;

  // return component
  return (
    <span className={pillClassName} {...props}>
      {children}
    </span>
  );
}

export function SuccessPill({ ...props }: PillProps) {
  return (
    <Pill bgColorVariant="success" textColorVariant="success" {...props} />
  );
}

export function WarningPill({ ...props }: PillProps) {
  return (
    <Pill bgColorVariant="warning" textColorVariant="warning" {...props} />
  );
}

export function InfoPill({ size = "small", ...props }: PillProps) {
  return <Pill size={size} bgColorVariant="info" textColorVariant="info" {...props} />;
}

export function SecondaryPill({ size = "small", ...props }: PillProps) {
  return <Pill size={size} bgColorVariant="secondary" textColorVariant="secondary" {...props} />;
}

export function PrimaryPill({ size = "small", ...props }: PillProps) {
  return <Pill size={size} bgColorVariant="primary" textColorVariant="primary" {...props} />;
}

export function DangerPill({ ...props }: PillProps) {
  return <Pill bgColorVariant="danger" textColorVariant="danger" {...props} />;
}

export function NormalPill({ className = "", bgColorShade = 900, shape = "normal", ...props }: PillProps) {
  return <Pill className={`border-white shadow-small ${className}`} bgColorVariant="white" bgColorShade={bgColorShade} shape={shape} textColorVariant="black" textColorShade={900} {...props} />;
}