import { ChargeSlab } from "../../services/settings";
import { showHttpError } from "../../utils/message";

export const countHandling = (packages: any[], slab: any[], across: any) => {
  console.log("packages : ", packages, "slab : ", slab, " across : ", across);
  let result = 0;
  switch (across) {
    case "interState": {
      packages.forEach((p) => {
        let { rate, amount, qty } = p;
        rate = rate.value;
        let gotAny = false;
        qty = parseInt(qty, 10);
        let flag = false,
          perPackAmt = rate != "Per package" ? amount : amount / qty;
        slab.forEach(({ min, max, interState }) => {
          if (!flag && min <= perPackAmt && perPackAmt <= max && !isNaN(qty)) {
            result += interState * qty;
            flag = true;
            gotAny = true;
          }
        });
        if (!gotAny && !isNaN(qty)) {
          result += slab[slab.length - 1].interState * qty;
        }
      });
      break;
    }
    case "intraState": {
      packages.forEach((p) => {
        let gotAny = false;
        let { rate, amount, qty } = p;
        rate = rate.value;
        qty = parseInt(qty, 10);
        let flag = false,
          perPackAmt = rate != "Per package" ? amount : amount / qty;
        slab.forEach(({ min, max, intraState }) => {
          if (!flag && min <= perPackAmt && perPackAmt <= max && !isNaN(qty)) {
            console.log("inside if");
            result += intraState * qty;
            flag = true;
            gotAny = true;
          }
        });
        if (!gotAny && !isNaN(qty)) {
          result += slab[slab.length - 1].intraState * qty;
        }
      });
      break;
    }
    default: {
      console.log("error: got no across value");

      throw "AcrossError";
    }
  }
  return result;
};

export const countBuilty = (builty: any, across: any) => {
  console.log("bilty is : ", builty, "\nacross : ", across);
  if (!across) throw "AcrossError";
  const result = builty[across];
  if (!(result >= 0))
    throw "BuiltyError:- interState, intrastate or international rate missing";
  return result;
};

export const countHandlingCharges = (
  packages: any[],
  handlingChargesSlab: ChargeSlab[],
  across?: "intraState" | "interState"
) => {
  if (across !== "interState" && across !== "intraState") {
    throw "AcrossError";
  }

  let result = 0;
  packages.forEach((p) => {
    let { rate, amount, qty } = p;
    rate = rate.value;
    qty = parseInt(qty, 10);
    let perPackAmt = rate === "Per package" ? amount : amount / qty;
    let flag = false;
    for (let i = handlingChargesSlab.length - 1; i >= 0; i--) {
      if (handlingChargesSlab[i] && handlingChargesSlab[i].min <= perPackAmt) {
        result += handlingChargesSlab[i][across] * (qty || 0);
        flag = true;
        break;
      }
    }
    if (!flag &&  handlingChargesSlab[handlingChargesSlab.length - 1]) {
      result +=
        handlingChargesSlab[handlingChargesSlab.length - 1][across] *
        (qty || 0);
    }
  });
  return result;
};






export const countNewHandlingCharges = (
  packages: any[],
  handlingChargesSlab: any[],
  numOfActivity?:number
) => {
 
  if(!handlingChargesSlab.length){
    showHttpError("handling Charge Slab Not exists")
  }

  let result = 0;
  packages.forEach((p) => {
    let { rate, qty ,weight} = p;
    rate = rate.value;
    qty = parseInt(qty, 10);
    // let perPackAmt = rate === "Per package" ? amount : amount / qty;
    let flag = false;
    let maxWeight=0
    if(p.fixRate?._id){
      result+=p.fixRate.handlingCharges* (qty || 0);
    }else{
    for (let i = handlingChargesSlab.length - 1; i >= 0; i--) {
      if (handlingChargesSlab[i] && handlingChargesSlab[i].minWeight <= weight &&handlingChargesSlab[i].maxWeight >= weight) {
        result +=(numOfActivity ||2)* handlingChargesSlab[i].amount * (qty || 0);
        flag = true;
        break;
      }
      if(handlingChargesSlab[i].maxWeight >maxWeight){
        maxWeight=  handlingChargesSlab[i].maxWeight
      }
    }
    if (!flag && handlingChargesSlab.find((hSlab)=>hSlab.maxWeight==maxWeight)) {
      result +=
      (numOfActivity ||2)* handlingChargesSlab.find((hSlab)=>hSlab.maxWeight==maxWeight).amount *
        (qty || 0);
    }
  }
  });
  result=result
  return result;
};
export const countBuiltyCharges = (
  packages: any[],
  builtyChargesSlab: ChargeSlab[],
  across?: "intraState" | "interState"
) => {
  if (across !== "interState" && across !== "intraState") {
    throw "AcrossError";
  }
  let total = 0;
  let isBiltyDiscount=false
  packages.forEach((p) => {
    let { rate, amount, qty } = p;
    rate = rate.value;
    qty = parseInt(qty, 10);
    total += rate === "Per package" ? amount * (qty || 0) : amount;
    if(p.fixRate?.biltyDiscount){
      isBiltyDiscount=true
    }
  });
  if(isBiltyDiscount){
    return 0
  }
  for (let i = builtyChargesSlab.length - 1; i >= 0; i--) {
    if (builtyChargesSlab[i] && builtyChargesSlab[i].min <= total) {
      return builtyChargesSlab[i][across];
    }
  }
  return builtyChargesSlab[builtyChargesSlab.length - 1] && builtyChargesSlab[builtyChargesSlab.length - 1][across];
};
