import { useState } from "react";

export type StateObjType = {
    value: string;
    error: true | false;
    errorMessage: string;
}
export type StateType = string | StateObjType;

export function useDirectSalesAgentForm() {
    const [firstName, setFirstName] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "First name is required!"
        }
    });
    const [lastName, setLastName] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Last name is required!"
        }
    });
    const [email, setEmail] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Email is required!"
        }
    });
    const [password, setPassword] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Password is required!"
        }
    });
    const [contactNumber, setContactNumber] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Contact number is required!"
        }
    });
    const [addressLine1, setAddressLine1] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Address is required!"
        }
    });
    const [addressLine2, setAddressLine2] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Address is required!"
        }
    });
    const [pincode, setPincode] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Pincode is required!"
        }
    });
    const [city, setCity] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "City is required!"
        }
    });

    const [otp, setOtp] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "OTP is required!"
        }
    });
    const [isOtp, setIsOtp] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<1 | 2>(1);
    const [currentPageOfIndividualWithFleet, setCurrentPageOfIndividualWithFleet] = useState<1 | 2 | 3>(1);
    const [vehicleNumber, setVehicleNumber] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Vehicle number is required!"
        }
    });

    const [cities, setCities] = useState<any>(null);
    const [typesOfFleets, setTypesOfFleets] = useState<any>(null);
    const [typesOfSubFleets, setTypesOfSubFleets] = useState<any>(null);
    const [selectedFleetType, setSelectedFleetType] = useState<any>(null);
    const [selectedSubFleetType, setSelectedSubFleetType] = useState<any>(null);
    const [selectedCity, setSelectedCity] = useState<any>(null);
    const [selectedBodytype, setSelectedBodytype] = useState<any>(null);


    return { firstName, setFirstName, lastName, setLastName, email, setEmail, password, setPassword, contactNumber, setContactNumber, addressLine1, setAddressLine1, addressLine2, setAddressLine2, pincode, setPincode, city, setCity, otp, setOtp, isOtp, setIsOtp, currentPage, setCurrentPage, currentPageOfIndividualWithFleet, setCurrentPageOfIndividualWithFleet, cities, setCities, typesOfFleets, setTypesOfFleets, vehicleNumber, setVehicleNumber, selectedFleetType, selectedSubFleetType, setSelectedSubFleetType, setSelectedFleetType, selectedCity, setSelectedCity, selectedBodytype, setSelectedBodytype, typesOfSubFleets, setTypesOfSubFleets }
}

export function useTransportCompany() {
    const [companyName, setCompanyName] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Company name is required!"
        }
    });
    const [companyType, setCompanyType] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Company type is required!"
        }
    });
    const [companyEmail, setCompanyEmail] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Company email is required!"
        }
    });
    const [companyWebsite, setCompanyWebsite] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Company name is required!"
        }
    });
    const [firstName, setFirstName] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "First name is required!"
        }
    });
    const [lastName, setLastName] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Last name is required!"
        }
    });
    const [email, setEmail] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Email is required!"
        }
    });
    const [password, setPassword] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Password is required!"
        }
    });
    const [contactNumber, setContactNumber] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Contact number is required!"
        }
    });
    const [addressLine1, setAddressLine1] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Address required!"
        }
    });
    const [addressLine2, setAddressLine2] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Address required!"
        }
    });
    const [pincode, setPincode] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Pincode is required!"
        }
    });
    const [city, setCity] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "City is required!"
        }
    });
    const [state, setState] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "State is required!"
        }
    });
    const [branchName, setBranchName] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Branch name is required!"
        }
    });
    const [shortName, setShortName] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Short Name name is required!"
        }
    });
    const [otp, setOtp] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "OTP is required!"
        }
    });
    const [isOtp, setIsOtp] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<1 | 2 | 3 | 4>(1);

    return { companyName, setCompanyName, companyType, setCompanyType, companyEmail, setCompanyEmail, companyWebsite, setCompanyWebsite, firstName, setFirstName, lastName, setLastName, email, setEmail, contactNumber, setContactNumber, password, setPassword, addressLine1, setAddressLine1, addressLine2, setAddressLine2, city, setCity, pincode, setPincode, state, setState, branchName, setBranchName, shortName, setShortName, currentPage, setCurrentPage, otp, setOtp, isOtp, setIsOtp }
}

export function useCustomer() {
    const [fullName, setFullName] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Name is required!"
        }
    })
    const [email, setEmail] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Email is required!"
        }
    });
    const [password, setPassword] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Password is required!"
        }
    });
    const [contactNumber, setContactNumber] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Contact number is required!"
        }
    });
    const [otp, setOtp] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "OTP is required!"
        }
    });
    const [isOtp, setIsOtp] = useState<boolean>(false);
    const [isOtpVerified, setIsOtpVerified] = useState<boolean>(false);
    return { fullName, setFullName, email, setEmail, password, setPassword, contactNumber, setContactNumber, otp, setOtp, isOtp, setIsOtp, isOtpVerified, setIsOtpVerified }
}