import React, {
  memo,
  forwardRef,
  type ReactNode,
  useEffect,
  useState,
  type ComponentPropsWithoutRef,
  useRef,
} from "react";
import "./menu.css";

type MenuProps = {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  keepMounted?: boolean;
  containerClassName?: string;
} & ComponentPropsWithoutRef<"div">;

const Menu = forwardRef<HTMLDivElement, MenuProps>(
  (
    {
      anchorEl,
      open,
      onClose,
      children,
      keepMounted = false,
      className = "",
      containerClassName = "",
      ...rest
    },
    ref
  ) => {
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const dropdownMenuRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (anchorEl) {
        const rect = anchorEl.getBoundingClientRect();
        const menuWidth = dropdownMenuRef.current?.offsetWidth || 0;
        const menuHeight = dropdownMenuRef.current?.offsetHeight || 0;
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        let calculatedTop = rect.bottom;
        let calculatedLeft = rect.left;

        // Adjust for overflow on the right side
        if (rect.left + menuWidth > viewportWidth) {
          calculatedLeft = viewportWidth - menuWidth - 20; // 10px margin
        }

        // Adjust for overflow on the bottom side
        if (rect.bottom + menuHeight > viewportHeight) {
          calculatedTop = rect.top - menuHeight; // Position above the anchor element
        }

        // Ensure that we don't go outside the left side of the screen
        if (calculatedLeft < 0) {
          calculatedLeft = 10; // 10px margin
        }

        // Ensure that we don't go above the top side of the screen
        if (calculatedTop < 0) {
          calculatedTop = 10; // 10px margin
        }

        setPosition({ top: calculatedTop, left: calculatedLeft });
      }
    }, [anchorEl]);

    useEffect(() => {
      // Close the menu if the click happens outside the dropdown or the anchor element
      function handleDocumentClick(e: MouseEvent) {
        if (
          dropdownMenuRef.current &&
          !dropdownMenuRef.current.contains(e.target as Node) && // If click is outside dropdown
          anchorEl &&
          !anchorEl.contains(e.target as Node) // If click is also outside the anchor element
        ) {
          onClose();
        }
      }

      if (open) {
        document.addEventListener("mousedown", handleDocumentClick);
      }

      return () => {
        document.removeEventListener("mousedown", handleDocumentClick);
      };
    }, [open, anchorEl, onClose]);

    const handleMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation(); // Prevent closing when clicking inside the menu
    };

    if (!open || !anchorEl) return null;

    return (
      <div
        ref={ref}
        className={`taptap-menu-container absolute ${containerClassName}`}
        style={{
          top: position.top,
          left: position.left,
          display: open ? "block" : "none",
        }}
      >
        <div
          ref={dropdownMenuRef}
          className={`taptap-dropdown-menu-container bg-white-900 br-4 shadow-small ${className}`}
          onClick={handleMenuClick}
          {...rest}
        >
          {children}
        </div>
      </div>
    );
  }
);

Menu.displayName = "Menu";
export default memo(Menu);
