import { COMPLAINT_LISTING_CHAT, UPDATE_LISTING_CHAT } from "../constants/complaintListing";

const initSDtate:any = [];

export default (state = initSDtate , action: { type: any; payload: any }) => {
  switch (action.type) {
    case COMPLAINT_LISTING_CHAT : {
        return {
            ...state,
            complaintChat : action.payload
        }
    }
    case UPDATE_LISTING_CHAT : {
        return {
            ...state,
            complaintChat : state.complaintChat.map(
                (content : any) => content?._id === action?.payload ? {...content, readByCount : content.totalCount} : content
            ),
        }
        return state;
    }
    default:
      return state;
  }
}