import { showMessage, failed } from "../utils/message";

export const errorHandler = (data: any) => {
  const { reasons, message } = data;
  if (reasons) {
    showMessage(reasons[0].message, failed);
  } else if (message) {
    showMessage(message, failed);
  }
};
