import React, { useEffect, useMemo } from "react";
import { useTable,  Column, useResizeColumns, useFlexLayout, usePagination, useFilters, useGlobalFilter, useSortBy, FilterType, Row, IdType, FilterValue, useRowSelect } from "react-table";
import styled from "styled-components";
import CheckBox from "../CheckBox/CheckBox";
import { isArrayCheck } from "../../containers/generics/CheckArray";
import {  DefaultColumnFilter, GlobalFilter } from "./ReactTableFilterUtils";
import { Button, TextField } from "../../newSrc/components/UI";

const Styles = styled.div`
  overflow-x: auto;
  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    text-align: center;
    // min-width: max-content;
  }
  th.desc {
    border-bottom: 5px solid rgb(180, 180, 180);
  }

  th.asc {
    border-top: 5px solid rgb(180, 180, 180);
  }

  // tr:nth-child(odd) {
  //   background: #f3f3f3;
  //   color: #000000;
  // }

  // tr:nth-child(even) {
  //   background: #ffffff;
  //   color: #000000;
  // }
  .resizer {
    display: inline-block;
    // background: blue;
    width: 10px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    ${"" /* prevents from scrolling while dragging on touch devices */}
    touch-action:none;
  }

  .pagination {
    padding: 0.5rem;
    display: flex;
    left: 0px;
    position: sticky;
    // z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 3px;
  }
  .next {
    flex: 1 1;
    text-align: center;
  }
  .previous {
    flex: 1 1;
    text-align: center;
  }
  .center {
    flex: 1.5 1;
    text-align: center;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }
  .totalpage {
    text-align: center;
  }
  .pageSizeOption {
    margin: 3px 10px;
  }
  .paginationBtn {
    appearance: none;
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 3px;
    padding: 6px;
    font-size: 1em;
    color: rgba(0, 0, 0, 0.6);
    background: rgba(0, 0, 0, 0.1);
    transition: all 0.1s ease;
    cursor: pointer;
    outline-width: 0;

    &:hover:not(:disabled) {
      background: rgba(0, 0, 0, 0.3);
      color: white;
    }
    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
  .pageJump {
    display: inline-block;
  }
  .pagination input,
  .pagination select {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline-width: 0;
  }
`;
export interface CallbackFilterFun {
  (row: Row<any>, columnIds: IdType<any>, filterValue: FilterValue): boolean;
}

interface Props {
  columns: Array<Column<object>>;
  data: Array<object>;
  queryPageIndex?: number;
  queryPageSize?: number;
  count?: number;
  setPage?: any;
  setpageSize?: any;
  manualPagination?: boolean;
  //use filterable when Add Filter in column
  filterable?:boolean;
  defaultFilterMethod?:any;
  onFilteredChange?: any;
  checkBox?:boolean;
  handleCheckAll?:any;
  handleCheck?:any;
  selectedAllRow?:boolean;
  setSelectedAllRow?:any;
  clearRow?: boolean;
  setClearRow?: any;
  filterData?:any;
  showPagination?:boolean;
  handleHeaderClick?: any
  handleNextClick?: () => void;
  handlePreviousClick?: () => void;
  handleShowItemsChange?: (num: number) => void;
  handlePageIndexChange?: (num: number) => void;
}

const ReactTable:React.FC<Props> = ({ columns, data,filterData ,
  showPagination = true ,clearRow,setClearRow,onFilteredChange = false, checkBox, handleCheckAll, handleCheck, selectedAllRow,setSelectedAllRow, queryPageIndex = 0 ,queryPageSize=10,count ,setPage,setpageSize,manualPagination=false ,filterable = false ,defaultFilterMethod = null, handleNextClick, handlePreviousClick, handleShowItemsChange, handlePageIndexChange }) => {
  const columnData = useMemo(() => columns, [columns]);
  const rowData = useMemo(() => data, [data]);
  // const filterTypes = useMemo(()=>defaultFilterMethod,[])
  const filterableProps: any = {};
  if (filterable) {
    filterableProps.Filter = DefaultColumnFilter;
  }

  if (defaultFilterMethod) {
    const generalFilterFunction: FilterType<any> = (rows, id, filterValue) => {
      return rows.filter((row) => {
        return defaultFilterMethod(row, id[0], filterValue);
      });
    };
    filterableProps.filter = generalFilterFunction;
  }
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 50,
      width: 150,
      maxWidth: 400,
      Filter: "",
      
      // filterMethod:defaultFilterMethod,
      ...filterableProps,
    }),
    []
  );

  let paginationProps: any = {};

  if (manualPagination) {
    paginationProps.manualPagination = manualPagination;
    paginationProps.pageCount = Math.ceil((count || 1) / queryPageSize);
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canNextPage,
    canPreviousPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    toggleRowSelected,
    state: { pageIndex, pageSize, globalFilter, selectedRowIds, filters },
  } = useTable(
    {
      columns: columnData,
      data: rowData,
      defaultColumn,
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        hiddenColumns: columns
          .filter((col: any) => col.show === false)
          .map((col) => col.id || col.accessor),
          filters : (filterData && filterData.current)? filterData.current:isArrayCheck(filterData)?filterData:[]
      },
      autoResetFilters:false,
      ...paginationProps,
    },
  useResizeColumns,
  useFlexLayout,
  useFilters, 
  useGlobalFilter ,
  useSortBy,
  usePagination,
  useRowSelect,
  (hooks) => {
      checkBox && hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps, selectedFlatRows }) => {
            useEffect(() => {
              if (selectedAllRow) {
                const rows = selectedFlatRows.map(({ original }: any) => original);
                // console.log(rows, selectedFlatRows);
                handleCheckAll(rows);
                // if (!isEqual(rows, selectedData)) {
                //   setSelectedData(rows);
                //   onRowSelection(rows);
                // }
              }
            }, [selectedFlatRows]);

            return (
            <div>
              <CheckBox
                color="primary"
                onClick={() =>
                  {
                    if(!selectedAllRow){
                      setSelectedAllRow(true)
                    }else{
                      setSelectedAllRow(false);
                      handleCheckAll([])
                    }
                  }
                }
                {...getToggleAllPageRowsSelectedProps()}
              />
            </div>
          )},
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }: any) => (
            <div>
              <CheckBox
                color="primary"
                onClick={() => handleCheck(row.original)}
                {...row.getToggleRowSelectedProps()}
              />
            </div>
          )
        },
        ...columns
      ]);
    },
  );
  useEffect(() => {
    setPage && setPage(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    setpageSize && setpageSize(pageSize);
  }, [pageSize]);

  useEffect(() => {
    clearRow && deselectRows();
  }, [clearRow]);
  // const generateSortingIndicator = (column:any) => {
  //   return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  // };
  useEffect(() => {
    // && prevDataLength !== preGlobalFilteredRows
    if(onFilteredChange ){
      onFilteredChange && onFilteredChange(preGlobalFilteredRows)
  }
  }, [preGlobalFilteredRows.length])

  const deselectRows = () => {
    let ids = Object.keys(selectedRowIds)
    ids.forEach((id) => toggleRowSelected(id, false));
    setClearRow(false)
  };
  
   
   useEffect(() => {
    if( filterData ){
      filterData?.current &&(
        filterData.current = filters
      )
    }
   }, [JSON.stringify(filters)])
  return (
    <Styles>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "desc"
                        : "asc"
                      : ""
                  }
                      //  onClick={() => handleHeaderClick?.(column.id)}
                >
                  {column.render("Header")}
                  {/* {generateSortingIndicator(column)}    */}
                  <div {...column.getResizerProps()} className="resizer" />
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
          {filterable && (
            <tr>
              <th
                colSpan={visibleColumns.length}
                style={{
                  textAlign: "left",
                }}
              >
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </th>
            </tr>
          )}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {showPagination && <div className="flex-between flex-wrap gap-1 p-1 bg-white-800" style={{position: "sticky", top: 0, left: 0}}>
            <div className="flex ai-baseline gap-1">
              <div className="flex ai-center gap-4">
                <span>Page</span>
                <TextField
                  type="number"
                  value={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(page);
                    handlePageIndexChange?.(page);
                  }}
                  style={{width: "50px"}}
                />
                <span>of <strong>{pageCount}</strong></span>
              </div>
              <select
                className="p-10 br-4 border-white"
                value={pageSize}
                onChange={(e) => {
                  const num = Number(e.target.value);
                  setPageSize(num);
                  handleShowItemsChange?.(num);
                }}
              >
                {[5, 10, 20, 30, 40, 50 ,100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize} Rows
                  </option>
                ))}
              </select>
            </div>
            <div className="flex ai-center gap-1">
              <Button
                variant="primary"
                action="secondary"
                onClick={() => {previousPage(); handlePreviousClick?.();}}
                disabled={!canPreviousPage}
                >
                Previous
              </Button>
              <Button
                variant="primary"
                action="primary"
                onClick={() => {nextPage(); handleNextClick?.();}}
                disabled={!canNextPage}
              >
                Next
              </Button>
            </div>
          </div>}
    </Styles>
  );
};

export default ReactTable;
