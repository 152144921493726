import { showMessage, failed } from "../utils/message";

const initState = {
  addressSuggetionData: [],
  friendAddressSuggetionData: []
};

const customerLoginReducer = (
  state: any = initState,
  action: { [k: string]: any }
) => {
  switch (action.type) {
    case "CUSTOMER_LOGIN": {
      const { customer, accessToken, refreshToken, refreshTokenExpiresAt } = action.payload;
      console.log("customerLoginWithToken action.payload 56000", action)
      // sessionStorage.setItem("kabra:token", accessToken);
      localStorage.setItem("kabra:customertoken", accessToken);
      // sessionStorage.setItem("kabra:user", customer._id);
      localStorage.setItem("kabra:customer", customer?._id);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("refreshTokenExpiresAt", refreshTokenExpiresAt);
      // socket.emit('customerLogin', customer._id)s
      return {
        ...state,
        ...customer,
        token: "5b0ce34h6j",
      };
    }
    case "SET_CUSTOMER__ADDRESS_SUGGESTION_DETAILS": {
      return {
        ...state,
        addressSuggetionData: action.data
      }
    }
    case "SET_CUSTOMER__FRIEND_ADDRESS_SUGGESTION_DETAILS": {
      return {
        ...state,
        friendAddressSuggetionData: action.data
      }
    }
    case "CUSTOMER_LOGIN_FAIL": {
      showMessage(action.payload || "Invalid credentials!", failed);
      return state;
    }
    case "CUSTOMER_LOGOUT": {
      console.log("CUSTOMER_LOGOUT 54542584561425")
      return {...initState};
    }
    case "SET_CUSTOMER_DETAILS": {
      console.log("SET_CUSTOMER_DETAILS 54542584561425",action.payload)
      const customer = action.payload;
      return {
        ...state,
        ...customer,
      }
    }
    default: {
      return state;
    }
  }
};

export default customerLoginReducer;
