import { ServiceMaker } from "./index";
const qs = (obj:any) => {
  return Object.entries(obj)
    .map(([key, val]:any) => `${key}=${encodeURIComponent(val)}`)
    .join("&");
};
export const fetchBranch = (data:any) =>
  ServiceMaker(`inquiry/availableBranches?${qs(data)}`, "GET");

export const fetchAvailabeCompanies = () =>
  ServiceMaker("inquiry/companies", "GET");

export const fetchStates = () => ServiceMaker("state/list", "GET");

// export default fetchBranch;
