import { BookingError } from "../err";

import * as Yup from "yup";

const getOrder: any = {
  "0": "1st",
  "1": "2nd",
  "2": "3rd",
  "3": "4th",
};

export const formatMessage = (err: Error) => {
  const testRegex = /(\S*)\[(\d*)\](\S*)/;
  let result: any = err.message.match(testRegex);
  if (!result) return err.message;
  else
    return (
      "Invalid value at " +
      result[3].substring(1) +
      " in " +
      getOrder[result[2]] +
      " row of " +
      result[1]
    );
};

export const bookingSchema : any = {
  bookdestcity: Yup.string().required("Destination City is required"),
  bookdestbranch: Yup.string().required("Destination Branch is required"),
  // bookroute: Yup.string().required("Destination Route is required"),
  bookService: Yup.string().required("Service is required"),
  purpose: Yup.string().required("Purpose is required"),
  sender: {
    name: Yup.object({ value: Yup.string(), label: Yup.string() })
      .nullable()
      .required("Sender name is required"),
    contact: Yup.string()
      .required("Sender contact is required")
      .matches(/\d{10}/, "Invalid contact number"),
    gst: Yup.object({
      label: Yup.string()
        .notRequired()
        .matches(/(\d{2}.{10}\dZ.)|^$|(URP)/, "Invalid GST number"),
      value: Yup.string()
        .notRequired()
        .matches(/(\d{2}.{10}\dZ.)|^$|(URP)/, "Invalid GST number"),
    }),
  },
  receiver: {
    name: Yup.object({ value: Yup.string(), label: Yup.string() })
      .nullable()
      .required("Receiver name is required"),
    contact: Yup.string()
      .required("Receiver contact is required")
      .matches(/\d{10}/, "Invalid contact number"),
    gst: Yup.object({
      label: Yup.string()
        .notRequired()
        .matches(/(\d{2}.{10}\dZ.)|^$|(URP)/, "Invalid GST number"),
      value: Yup.string()
        .notRequired()
        .matches(/(\d{2}.{10}\dZ.)|^$|(URP)/, "Invalid GST number"),
    }),
  },
};

export const finalBookingSchema = Yup.object({
  fromBranch: Yup.string()
    .required()
    .matches(/\w{24}/, "Enter a valid destination branch"),
  packages: Yup.array().of(
    Yup.object({
      amount: Yup.number().min(0),
      dimension: Yup.object({
        l: Yup.number()
          .required()
          .min(0),
        b: Yup.number()
          .required()
          .min(0),
        h: Yup.number()
          .required()
          .min(0),
      }),
      frag: Yup.boolean().required(),
      stack: Yup.boolean().required(),
      haz: Yup.boolean().required(),
      rate: Yup.object({
        value: Yup.string()
          .required()
          .oneOf(["Direct", "Per package"]), //, 'Per Kg']),
        label: Yup.string(),
      }),
      materialType: Yup.object({
        label: Yup.string(),
        value: Yup.string()
          .required()
          .min(3),
      }),
      packingType: Yup.object({
        label: Yup.string(),
        value: Yup.string()
          .required()
          .min(3),
      }),
      qty: Yup.number()
        .required()
        .min(1),
      unit: Yup.object({
        label: Yup.string().required(),
        value: Yup.string(),
      }),
      weight: Yup.number().required(),
    })
  ),
  risk: Yup.number().oneOf(
    [0, 1, 2],
    "Please select a valid insurance service"
  ),
});

export const biltyGenerationSchema = Yup.object({
  packages: Yup.array().of(
    Yup.object({
      amount: Yup.number().min(0),
      dimension: Yup.object({
        l: Yup.number()
          .typeError("Length should be a number")
          .required("Length is required")
          .min(0),
        b: Yup.number()
          .typeError("Breadth should be a number")
          .required("Breadth is required")
          .min(0),
        h: Yup.number()
          .typeError("length should be a number")
          .required("Height is required")
          .min(0),
      }),
      frag: Yup.boolean().required(),
      stack: Yup.boolean().required(),
      haz: Yup.boolean().required(),
      rate: Yup.object({
        value: Yup.string()
          .required()
          .oneOf(["Direct", "Per package"]), // 'Per Kg']),
        label: Yup.string(),
      }),
      materialType: Yup.object({
        label: Yup.string(),
        value: Yup.string()
          .required()
          .min(3),
      }),
      packingType: Yup.object({
        label: Yup.string(),
        value: Yup.string()
          .required()
          .min(3),
      }),
      qty: Yup.number()
        .typeError("Quantity should be number")
        .required()
        .min(1, "Quantity cannot be 0"),
      unit: Yup.object({
        label: Yup.string().required(),
        value: Yup.string(),
      }),
      weight: Yup.number()
        .typeError("Weight should be number")
        .required("Weight is required"),
    })
  ),
  // bf: Yup.number('Bilty cost should be number').required('Bilty cost is invalid'),
  // hf: Yup.number('Handling should be number').required('Handling cost is invalid'),
  sender: Yup.object({
    name: Yup.string()
      .required("Sender name is required")
      .min(5, "Sender name is too short"),
    contact: Yup.string()
      .required("Sender contact is required")
      .matches(/\d{10}/),
    l1: Yup.string().required("sender address l1 required"),
    l2: Yup.string().required("sender address l2 required"),
    pin: Yup.string().required("sender address pincode required"),
    // city: Yup.string().required("sender address city required."),
  }),
  receiver: Yup.object({
    name: Yup.string()
      .required("Receiver name is required")
      .min(5, "Receiver name is too short"),
    contact: Yup.string()
      .required("Receiver contact is required")
      .matches(/\d{10}/),
    l1: Yup.string().required("receiver address l1 required"),
    l2: Yup.string().required("receiver address l2 required"),
    pin: Yup.string().required("receiver address pincode required"),
    city: Yup.string().required("receiver address city required."),
  }),
  paymentMode: Yup.object()
    .shape({
      label: Yup.string().nullable(),
      value: Yup.string().nullable(),
    })
    .typeError("Invalid payment mode!"),
});

export const packagesSchema = Yup.object({
  packages: 
    Yup.object({
      amount: Yup.number().min(0),
      dimension: Yup.object({
        l: Yup.number()
          .typeError("Length should be a number")
          .required("Length is required")
          .min(0),
        b: Yup.number()
          .typeError("Breadth should be a number")
          .required("Breadth is required")
          .min(0),
        h: Yup.number()
          .typeError("length should be a number")
          .required("Height is required")
          .min(0),
      }),
      // frag: Yup.boolean().required(),
      // stack: Yup.boolean().required(),
      // haz: Yup.boolean().required(),
      // rate:Yup.string()
      //     .required()
      //     .oneOf(["Direct", "Per package"]), // 'Per Kg']),
       
      // materialType: Yup.object({
      //   label: Yup.string(),
      //   value: Yup.string()
      //     .required()
      //     .min(3),
      // }),
      // packingType: Yup.object({
      //   label: Yup.string(),
      //   value: Yup.string()
      //     .required()
      //     .min(3),
      // }),
      // qty: Yup.number()
      //   .typeError("Quantity should be number")
      //   .required()
      //   .min(1, "Quantity cannot be 0"),
      unit: Yup.string().required("unit is required"),
      size: Yup.string().required("size is required"),
      weight: Yup.number()
      .typeError("weight should be a number")
      .required("weight is required")
      .min(1),
      }),
      // id:Yup.number(),
     
    })




export default async (data: any) => {
  try {
    await finalBookingSchema.validate(data);
    return null;
  } catch (err : any) {
    return new BookingError(err.message, "test");
  }
};
