import React from "react";
import { FormHelperText } from "@mui/material";
import SelectField from "react-select";

export interface Ipropsselect {
  value?: any;
  options?: any;
  onChange?: any;
  label?: any;
  placeholder?: any;
  isMulti?: any;
  isDisabled?: any;
  width?: any;
  styles?: any;
  error?: any;
  message?: any;
  openMenuOnFocus?: any;
  theme?: any;
  onFocus?: any;
  isOptionDisabled?: any;
  closeMenuOnSelect?: any;
  onBlur?: any;
  id?: any;
  autoComplete?: any;
  isClearable?: any;
  isSearchable?: any;
  ref?: any;
  components?: any;
  isLoading?: any;
  menuContainerStyle?: any;
  inputId?: any;
  onKeyDown?: any;
  className?: any;
  name?: any;
}
export default function Select(props: Ipropsselect) {
  return (
    <div>
      <SelectField
        ref={props.ref}
        onKeyDown={props.onKeyDown}
        isSearchable={props.isSearchable}
        id={props.id}
        inputId={props.inputId}
        name={props.name}
        //  autoComplete={props.autoComplete?props.autoComplete: "nope"}
        closeMenuOnSelect={props.closeMenuOnSelect}
        value={props.value}
        placeholder={props.placeholder}
        options={props.options}
        onChange={props.onChange}
        className={"heighten " + `${props.className}`}
        isMulti={props.isMulti ? props.isMulti : false}
        isDisabled={props.isDisabled ? props.isDisabled : false}
        styles={props.styles}
        openMenuOnFocus={props.openMenuOnFocus}
        theme={props.theme}
        onFocus={props.onFocus}
        isOptionDisabled={props.isOptionDisabled}
        onBlur={props.onBlur}
        isClearable={props.isClearable}
        components={props.components}
        isLoading={props.isLoading}
      // menuContainerStyle={props.menuContainerStyle}
      />
      {(props.error && props.message) ? (
        <FormHelperText style={{ color: "#f44336" }}>
          {props.message}
        </FormHelperText>
      ) : null}
    </div>

  );
}
