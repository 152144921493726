import React, { Fragment, useState } from "react";
// import Sound from "react-sound";
import { Button } from "@mui/material";
import notificationSound from "./mixkit-modern-classic-door-bell-sound-113.wav";

const PlaySound = (props) => {
  console.log(props.soundStatus);

  return (
    <Fragment>
      {/* <Sound
        url={notificationSound}
        playStatus={props.soundStatus}
        playFromPosition={300}
        onPlaying={props.handleSongPlaying}
        onFinishedPlaying={props.handleSongFinishedPlaying}
      /> */}
    </Fragment>
  );
};

export default PlaySound;
