import {
  SET_COMPANIES,
  SET_BRANCHES,
  CHANGE_MANAGE_SIMPLE,
  CHANGE_RATE_SLAB,
  ADD_RATE_SLAB,
  DEL_RATE_SLAB,
  SET_FRANCHISE_TO_EDIT,
  RESET_MANAGE,
  SET_ROUTES,
  SET_HEAD,
  FRANCHISE_HEAD_CREATED,
  SET_DELIVERY_COMMISSION_SUCCESS,
  SET_DELIVERY_COMMISSION,
  SET_FRANCHISE_COMPANIES,
} from "../../constants/franchise";

import { listCompanyService, fetchCompanies } from "../../services/company";
import { bookFilterRoute } from "../../services/route";

import {

  fetchHead as fetchHeadService,
  createHead as createHeadService,
  setDeliveryCommission,
  getDeliveryCommission,
  getAvailableFranchise,
} from "../../services/franchise";

import { filterBookBranches, getAllBranchesByCompany } from "../../services/branch";

import { showLoader, hideLoader } from "../UserActions";
import { showMessage } from "../../utils/message";
import { Dispatch } from "redux";
import { RootState } from "../../store";

export const listCompanies = () => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: SET_COMPANIES,
      payload: await listCompanyService(),
    });
  } catch (err : any) {
    console.log(err);
  }
};

export const listFranchiseCompanies = (company: string) => async (
  dispatch: Dispatch
) => {
  try {
    const companies = await getAvailableFranchise(company);
    const franchiseCompanies = await fetchCompanies({
      companies,
    });

    await dispatch({
      type: SET_FRANCHISE_COMPANIES,
      payload: franchiseCompanies,
    });
  } catch (err : any) {
    console.log(err);
  }
};

export const listBranches = (company: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: SET_BRANCHES,
      payload: await getAllBranchesByCompany(company),
    });
  } catch (err : any) {
    console.log(err);
  }
};

export const changeManageSimple = (what: any, val: any) => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  try {
    await dispatch({
      type: CHANGE_MANAGE_SIMPLE,
      payload: { what, val },
    });

    if (what == "company") {
      dispatch(showLoader());
      const { id: parent } = getState().user.company;
      let result = await fetchHeadService(parent, val.value);
      await dispatch({
        type: SET_HEAD,
        payload: result,
      });
      dispatch(hideLoader());
    }
  } catch (err : any) {
    dispatch(hideLoader());
    console.log(err);
  }
};

export const changeRateSlab = (idx: any, where: any, what: any, val: any) => ({
  type: CHANGE_RATE_SLAB,
  payload: { idx, where, what, val },
});

export const addRateSlab = (what: any) => ({
  type: ADD_RATE_SLAB,
  payload: { what },
});

export const delRateSlab = (idx: any, what: any) => ({
  type: DEL_RATE_SLAB,
  payload: { idx, what },
});



export const setDeliveryCommissionAction = (data: any) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch(showLoader());
    await setDeliveryCommission(data);
    await dispatch({
      type: SET_DELIVERY_COMMISSION_SUCCESS,
    });
    dispatch(hideLoader());
  } catch (err : any) {
    showMessage(err.message || "Couldn't set delivery commission");
    dispatch(hideLoader());
  }
};





export const getDeliveryCommissionAction = () => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  try {
    const result = await getDeliveryCommission(
      getState().user.company.id,
      getState().franchise.get("company").value
    );
    dispatch({
      type: SET_DELIVERY_COMMISSION,
      payload: result,
    });
  } catch (err : any) {
    console.log(err);
  }
};
export const setFranchiseToEdit = (data: any) => ({
  type: SET_FRANCHISE_TO_EDIT,
  payload: data,
});

export const resetManage = () => ({ type: RESET_MANAGE });

export const filterRoute = (data: any) => async (dispatch: Dispatch) => {
  try {
    let result = await bookFilterRoute(data);
    await dispatch({
      type: SET_ROUTES,
      payload: result,
    });
  } catch (err : any) {
    console.log(err);
  }
};



export const handleDeliverySubmit = () => async (
  dispatch: any,
  getState: () => RootState
) => {
  try {
    dispatch(showLoader());
    const { franchise, user } = getState();
    // const isBooking = franchise.getIn(['isBooking']) === true,
    //     isDelivery = franchise.getIn(['isDelivery']) === true
    const commissionType = franchise.getIn(["delivery", "rateType"]);
    let payload: { [k: string]: any } = {
      parentCompany: user.company.id,
      childCompany: franchise.get("company").value,
      commissionType: commissionType,

      // route: franchise.get('route').value,
      // bookingComm: isBooking,
      // deliveryComm: isDelivery
    };
    if (commissionType === "slab") {
      payload.slab = franchise.getIn(["delivery", "slab"]);
    }
    if (commissionType === "flat") {
      payload.flat = franchise.getIn(["delivery", "flat"]);
    }

    // if (isBooking) {
    // rateType.bookingCommission = {}
    // if (franchise.getIn(['booking', 'flat'])) {
    //     rateType.bookingCommission.flat = franchise.getIn(['booking', 'flat'])
    // }
    // else {
    //     rateType.bookingCommission.slab = franchise.getIn(['booking', 'slab'])
    // }
    // }
    // if (isDelivery) {
    // rateType.deliveryCommission = {}
    // if (franchise.getIn(['delivery', 'flat'])) {
    //     rateType.deliveryCommission.flat = franchise.getIn(['delivery', 'flat'])
    // }
    // else {
    //     rateType.deliveryCommission.slab = franchise.getIn(['delivery', 'slab'])
    // }
    // }
    // payload.rateType = rateType
    await dispatch(setDeliveryCommissionAction(payload));
    dispatch(hideLoader());
  } catch (err : any) {
    dispatch(hideLoader());
    console.log(err);
  }
};
export const reset = () => ({ type: RESET_MANAGE });

export const createHead = () => async (
  dispatch: any,
  getState: () => RootState
) => {
  try {
    dispatch(showLoader());
    const { franchise, user } = getState();
    const head = franchise.get("head");
    if (!head) {
      const payload = {
        parent_company: user.company.id,
        child_company: franchise.get("company").value,
        limit: franchise.get("limit"),
        amount: 0,
      };

      await createHeadService(payload);

      await dispatch({
        type: FRANCHISE_HEAD_CREATED,
      });
      const companies = await getAvailableFranchise(user.company.id);
      const franchiseCompanies = await fetchCompanies({
        companies,
      });

      await dispatch({
        type: SET_FRANCHISE_COMPANIES,
        payload: franchiseCompanies,
      });
    }
    dispatch(hideLoader());
  } catch (err : any) {
    dispatch(hideLoader());
    showMessage(err.message || "Couldn't create franchise");
  }
};
export const listFilteredBranches = (company: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: "SET_FILTERED_BRANCHES",
      payload: {data: await filterBookBranches({company}), query: company},
    });
  } catch (err : any) {
    console.log(err);
  }
};