import React, { ComponentProps, ReactNode } from "react";

type InputContainerProps = { label?: ReactNode } & ComponentProps<"div">;

export default function InputContainer({
  label,
  children,
  className = "",
  ...props
}: InputContainerProps) {
  return (
    <div className={`${label ? "flex-col gap-4" : ""} ${className}`} {...props}>
      {label && (
        <label htmlFor="" className="m-0 fw-500">
          {label}
        </label>
      )}
      {children}
    </div>
  );
}
