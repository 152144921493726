export enum OtpTypes {
 VerifyDocketReceiver = "VR",
 VerifyDocketCustomer = "VDC",
 Registration = "Reg",
 IndividualVerification = 'IndividualVerification',
 IndividualWithFleetVerification = 'IndividualWithFleetVerification',
 CustomerConnectPrimaryVerification = 'CustomerConnectPrimaryVerification',
 CustomerConnectSecondaryVerification = 'CustomerConnectSecondaryVerification',
 CustomerLogin = 'CustomerLogin',
 CancelBookingRequest = 'CancelBookingRequest',
 CustomerRegister = 'CustomerRegister',
 CustomerForgotPassword = 'CustomerForgotPassword',
 UserForgotPassword = 'UserForgotPassword',
 BookingSenderVerification = 'BookingSenderVerification',
 CompanyVerification = 'CompanyVerification',
 VendorVerification = 'VendorVerification',
 CustomerWalletCashOutRequested = 'CustomerWalletCashOutRequested',
 CustomerCancelOrderRequest = 'CustomerCancelOrderRequest',
 DriverCancelOrderRequest = 'DriverCancelOrderRequest',
 DriverCheckInVerification = 'DriverCheckInVerification'
}