import { List } from "immutable";
export const isArrayCheck = (arr: any) => {
  return (Array.isArray(arr) && arr.length > 0) || List.isList(arr) || false;
};
export const sortBranchArray = (
  a: { branchName: string },
  b: { branchName: string }
) => {
  return a.branchName.toUpperCase() > b.branchName.toUpperCase() ? 1 : -1;
};

export const time_convert = (num: number) => {
  const hours = Math.floor(num / 3600000);
  const minutes = Math.floor((num / 3600000 - hours) * 60);
  return `${hours}:${minutes}`;
};
export const convertMS = (millisec: number) => {
  let seconds: any = (millisec / 1000).toFixed(0);
  let minutes: any = Math.floor(seconds / 60);
  let hours: any = "";
  if (minutes > 59) {
    hours = Math.floor(minutes / 60);
    hours = hours >= 10 ? hours : "0" + hours;
    minutes = minutes - hours * 60;
    minutes = minutes >= 10 ? minutes : "0" + minutes;
  }

  seconds = Math.floor(seconds % 60);
  seconds = seconds >= 10 ? seconds : "0" + seconds;
  if (hours != "") {
    return hours + ":" + minutes + ":" + seconds;
  }
  return minutes + ":" + seconds;
};
export const FindDate = (date: Date) => {
  return new Date(
    new Date(new Date(date.setHours(0)).setMinutes(0)).setSeconds(0)
  );
};

export const notificationDateFormat = (date: Date | string) => {
  const dateObj = new Date(date);

  const now = new Date();

  let diff = (now.getTime() - dateObj.getTime()) / 1000;

  diff = Math.floor(diff / 60);

  if (diff === 0) return "Now";
  if (diff < 60) return `${diff} minutes ago`;

  diff = Math.floor(diff / 60);

  if (diff < 24) return `${diff} hours ago`;

  diff = Math.floor(diff / 24);

  if (diff < 7) return `${diff} days ago`;

  //let options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
  const options : any = { day: "numeric", month: "short" };
  return (
    dateObj.toLocaleDateString(undefined, options) +
    " at " +
    dateObj.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })
  );
};
