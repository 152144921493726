import React from "react";
import { Button } from "@mui/material";
import Grid from "../Grid/Grid";

interface IProps {
  onClick?: any;
  children: any;
  style?: React.CSSProperties;
  disabled?: boolean;
  autoFocus?: boolean;
  className?: any;
  classes?: any;
  size?: any;
  value?: any;
  dataTestid?: any;
  id?: string;
  fullWidth?: boolean;
  endIcon?:React.ReactNode;
  color?: any;
  type?: any;
}

export function Buttoncontainer(props: any) {
  return (
    <Grid container lg={12} sm={12} xs={12} alignItems="center">
      {props.children}
    </Grid>
  );
}

export function FilterButtoncontainer(props: any) {
  return (
    <Grid container lg={12} sm={12} xs={12} alignItems="center">
      <Grid lg={5} sm={4} xs={4} alignItems="center">
        {null}
      </Grid>
      {props.children}
    </Grid>
  );
}

export const ButtonPrimary = (props: IProps) => {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled}
      className={props.className}
      size={props.size}
      value={props.value}
      data-testid={props.dataTestid}
      id={props.id}
      type={props.type}
      fullWidth={props.fullWidth}
    endIcon={props.endIcon}
    >
      {" "}
      {props.children}{" "}
    </Button>
  );
};

export const ButtonSecondary = (props: IProps) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled}
      className={props.className}
      size={props.size}
      value={props.value}
      data-testid={props.dataTestid}
    >
      {" "}
      {props.children}{" "}
    </Button>
  );
};

export const ButtonDefault = (props: IProps) => {
  return (
    <Button
      variant="contained"
      color="inherit"
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled}
      className={props.className}
    >
      {" "}
      {props.children}{" "}
    </Button>
  );
};

export const ButtonLight = (props: IProps) => {
  return (
    <Button
      color="primary"
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled}
      autoFocus={props.autoFocus}
    >
      {" "}
      {props.children}{" "}
    </Button>
  );
};

export const ButtonSimple = (props: IProps) => {
  return (
    <Button
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled}
      autoFocus={props.autoFocus}
      className={props.className}
      classes={props.classes}
    >
      {" "}
      {props.children}{" "}
    </Button>
  );
};

export const ButtonOutlined = (props: IProps) => {
  return (
    <Button
      variant="outlined"
      onClick={props.onClick}
      style={props.style}
      disabled={props.disabled}
      autoFocus={props.autoFocus}
      className={props.className}
    >
      {" "}
      {props.children}{" "}
    </Button>
  );
};

export const ButtonPrimaryBold = (props: IProps) => {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={props.onClick}
      style={{
        fontSize: "12px",
        marginRight: "5px",
      }}
      disabled={props.disabled}
      className={props.className}
      size={props.size}
      value={props.value}
      data-testid={props.dataTestid}
      id={props.id}
      fullWidth={props.fullWidth}
    >
      {" "}
      {props.children}{" "}
    </Button>
  );
};

export const ButtonSecondaryBold = (props: IProps) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={props.onClick}
      style={{
        fontSize: "12px",
        marginRight: "5px",
      }}
      disabled={props.disabled}
      className={props.className}
      size={props.size}
      value={props.value}
      data-testid={props.dataTestid}
    >
      {" "}
      {props.children}{" "}
    </Button>
  );
};
