import { useEffect, useState } from "react";
import { throttle } from "lodash";

/**
 * A custom React hook that provides the dimensions of the browser window,
 * with throttled resize event handling.
 *
 * @returns An object containing the width and height of the window.
 *
 * @example
 * ```typescript
 * import React from 'react';
 * import { useWindow } from './useWindow';
 *
 * const MyComponent: React.FC = () => {
 *   const { width, height } = useWindow();
 *
 *   return (
 *     <div>
 *       <p>Window dimensions: {width}px x {height}px</p>
 *     </div>
 *   );
 * };
 * ```
 */
export function useWindow() {
    // Initialize state with the current window dimensions
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        // Throttled resize handler to limit the rate of state updates
        const handleResize = throttle(() => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }, 1000); // Adjust the delay (in ms) as per your requirement

        window.addEventListener("resize", handleResize);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Return the current window dimensions
    return { width, height };
}
