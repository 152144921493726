import React, { ReactNode } from "react";
import Header from "../newSrc/pages/Landing/components/EstimatedListHeader/Header";
// import Sidebar from "../newSrc/pages/Landing/components/EstimatedListSidebar/Sidebar";
import { Outlet } from "react-router-dom";
// import "../../src/newSrc/pages/Landing/components/EstimatedListSidebar/sidebar.css";


type EstimateType = {
  children: ReactNode;
  data : any;
};

export const EstimatedRateLayout = ({ children }: EstimateType) => {
  return (
    <div className="layout-container p-10">
      <Header/>
      <div className="content-container mt-1">
        {/* <Sidebar /> */}
        <div className="children-container">
          {children}
        </div>
      </div>
      <Outlet />
    </div>
  );
};
