// react
import React, { Dispatch, SetStateAction } from "react";
// Forms (get estimate forms | partner forms)
import {
  FullLoadForm,
  IntraCityForm,
  PartLoadForm,
} from "../../get-estimate-forms";
import {
  DirectSalesAgentForm,
  IndividualWithFleet,
  Investors,
  TransportCompany,
  Warehouse,
} from "../../partner-forms";
// css
import "../../get-estimate-forms/styles.css";
// context
import { useLandingPageContext } from "../../../../hooks/useLandingPageContext";
// components
import { OTPInput } from "../../partner-forms/OTPInput";
// ui components
import { Button, Dialog, Input } from "../../../../components/UI";
// type and custom state
import { useCustomer } from "../../partner-forms/useCustomState";
// utils (validation | message | helper | toast)
import {
  validateContactNumber,
  validateEmail,
  validateFullName,
  validatePassword,
} from "../../../../utils/validation";
import showMessage, {
  failed,
  showHttpError,
  success,
} from "../../../../../utils/message";
import { resetState } from "../../../../utils/helper";
import { toast } from "react-toastify";
// services
import {
  customerData,
  getOtp,
  verifyOtpForReg,
} from "../../../../../services/area";
// constants (data)
import { GetDetailsIcon } from "../../../../constants/icons";
// import { ReactComponent as PartLoadSvg } from "../../assets/part-load.svg";
// import { ReactComponent as FullLoadSvg } from "../../assets/full-load.svg";
// css
import "./main.css";
import {
  LandingPageMainNavItem,
  mobileLandingPageCustomerListItem,
  mobileLandingPagePartnerListItem,
} from "../../../../constants/data";
import ExpressParcel from "../../get-estimate-forms/ExpressParcel";
import { LoadType } from "../../../../context/LandingPageContextProvider";
import { fullLoadWebBanner, intraCityWebBanner, partLoadWebBanner } from "../../../../constants/images";
import { useWidth } from "../../../../hooks/useMobile";
import { OtpTypes } from "../../../../../utils/otp";

// FC: Main Component
const Main = () => {
  // State
  // from Landing Page Context
  const {
    activeService,
    activePage,
    activePartner,
    activeLoadType,
    setActiveLoadType
  } = useLandingPageContext();

  // variables
  let ActiveGetEstimateForm = <IntraCityForm />;
  let ActivePartnerRegisterForm = <DirectSalesAgentForm />;
  let ActiveServiceTagLine = "Transport anything within city";
  let ActivePartnerTagLine = "Transport anything within city";
  let ActiveBanner = intraCityWebBanner;
  let ActiveMobileBanner = intraCityWebBanner;

  // does: finds active service form
  switch (activeService) {
    case "Intra City":
      ActiveGetEstimateForm = <IntraCityForm />;
      ActiveServiceTagLine = mobileLandingPageCustomerListItem[0].description;
      ActiveBanner = intraCityWebBanner;
      ActiveMobileBanner = intraCityWebBanner;
      break;
    case "Express Parcel":
      ActiveGetEstimateForm = <ExpressParcel />;
      ActiveServiceTagLine = mobileLandingPageCustomerListItem[1].description;
      break;
    case "Part Load & Full Load":
      ActiveGetEstimateForm =
        activeLoadType === "Part Load" ? <PartLoadForm /> : <FullLoadForm />;
      ActiveServiceTagLine = mobileLandingPageCustomerListItem[2].description;
      ActiveBanner = activeLoadType === "Part Load" ? partLoadWebBanner :  fullLoadWebBanner;
      ActiveMobileBanner = activeLoadType === "Part Load" ? partLoadWebBanner : fullLoadWebBanner;
      break;
    // case "Full Load":
    //   ActiveGetEstimateForm = <FullLoadForm />;
    //   ActiveServiceTagLine = mobileLandingPageCustomerListItem[3].description;
    //   break;
    case "Packers & Movers":
      // ActiveGetEstimateForm = <PackersAndMoversForm />;
      ActiveGetEstimateForm = (
        <p className="| text-white-900 fw-600 fs-medium text-center">
          Coming Soon😊!
        </p>
      );
      ActiveServiceTagLine = mobileLandingPageCustomerListItem[3].description;
      break;
    case "International":
      // ActiveGetEstimateForm = <InternationalForm />;
      ActiveGetEstimateForm = (
        <p className="| text-white-900 fw-600 fs-medium text-center">
          Coming Soon😊!
        </p>
      );
      ActiveServiceTagLine = mobileLandingPageCustomerListItem[4].description;
      break;
    default:
      console.log("Default Case: Main Component inside Landing Page | Switch");
  }
  // does: finds active partner form
  switch (activePartner) {
    case "Direct Sales Agent":
      ActivePartnerRegisterForm = <DirectSalesAgentForm />;
      ActivePartnerTagLine = mobileLandingPagePartnerListItem[0].description;
      break;
    case "Individual With Fleet":
      ActivePartnerRegisterForm = <IndividualWithFleet />;
      ActivePartnerTagLine = mobileLandingPagePartnerListItem[1].description;
      break;
    case "Transport Company":
      ActivePartnerRegisterForm = <TransportCompany />;
      ActivePartnerTagLine = mobileLandingPagePartnerListItem[2].description;
      break;
    case "Warehouse":
      ActivePartnerRegisterForm = <Warehouse />;
      ActivePartnerTagLine = mobileLandingPagePartnerListItem[3].description;
      break;
    case "Investors":
      ActivePartnerRegisterForm = <Investors />;
      ActivePartnerTagLine = mobileLandingPagePartnerListItem[4].description;
      break;
    default:
      console.log("Default Case: Main Component inside Landing Page | Switch");
  }

  // component returns
  // Services
  if (activePage === "services") {
    return (
      <Form1
        activeHeading={activeService}
        activeTagLine={ActiveServiceTagLine}
        formHeading="Get an Estimate"
        form={ActiveGetEstimateForm}
        activeLoadType={activeLoadType}
        setActiveLoadType={setActiveLoadType}
        activeBanner={ActiveBanner}
        activeMobileBanner={ActiveMobileBanner}
        activeService={activeService}
      />
    );
  }
  // Partners
  else if (activePage === "partners") {
    return (
      <Form
        activeHeading={activePartner}
        activeTagLine={ActivePartnerTagLine}
        formHeading="Register"
        form={ActivePartnerRegisterForm}
        activeBanner={ActiveBanner}
      />
    );
  }
  // Customer
  else {
    return (
      <Form
        activeHeading={activePage}
        activeTagLine={ActiveServiceTagLine}
        formHeading="Register"
        form={<CustomerForm />}
        activeBanner={ActiveBanner}
      />
    );
  }
};

// type
type FormType = {
  activeHeading: string;
  activeTagLine: string;
  formHeading: string;
  form: any;
  isMobile?:boolean;
  activeLoadType?: LoadType;
  setActiveLoadType?: Dispatch<SetStateAction<LoadType>>;
  activeBanner?: string;
  activeMobileBanner?: string;
  activeService?: LandingPageMainNavItem
};

// Form
function Form({
  activeHeading,
  activeTagLine,
  formHeading,
  form,
  // activeLoadType
}: FormType) {
  // const { activeService, activeExpressParcelDeliveryType } =
  //   useLandingPageContext();
  // const loadImage = (activeItem?: LoadType, index?: number) => {
  //   const naviStyle = { width: "30px", height: "30px" };
  //   if (activeItem === "Part Load" && index === 0) {
  //     return <PartLoadSvg className="text-white-900" style={naviStyle} />;
  //   } else if (activeItem === "Part Load" && index === 1) {
  //     return <FullLoadSvg className="text-black-900" style={naviStyle} />;
  //   } else if (activeItem === "Full Load" && index === 0) {
  //     return <PartLoadSvg className="text-black-900" style={naviStyle} />;
  //   } else {
  //     return <FullLoadSvg className="text-white-900" style={naviStyle} />;
  //   }
  // };

  return (
    <main className="main | relative p-1">
      <div className="get-estimate-card | flex-center gap-2 h-100">
        {/* Active Get Estimate Form (Service Form) */}
        <div className="two-column gap-1 m-one-column max-w-1200">
          <div className="flex-col get-estimate-card-column gap-1">
            <h1 className="| fs-heading fw-900 text-primary-400 text-left m-text-center m-0 capitalize">
              {activeHeading}
            </h1>
            <p
              className="| fs-subheading text-primary-400 fw-900 m-0 m-text-center"
              style={{ lineHeight: "1.1" }}
            >
              {activeTagLine}
            </p>
          </div>
          <div className="main-formm flex-col get-estimate-card-column get-estimate-second-column br-1 shadow-large jc-center gap-1 as-center">
            <h1 className="| fs-subheading fw-900 text-primary-400 m-0 text-left"> {formHeading} </h1>
            {/* {activeService === "Part Load & Full Load" &&
              activePage === "services" && (
                <ul className="| two-column t-one-column m-0">
                  {loadTypes.length > 0 &&
                    loadTypes.map((item, index) => (
                      <li
                        key={item}
                        className={`domestic-form-delivery-item flex-col-center text-center fw-500 px-1 py-8 pointer fs-link ${
                          item === activeLoadType ? "active" : ""
                        }`}
                        onClick={() => {
                          if (setActiveLoadType) {
                            setActiveLoadType(item);
                          }
                        }}
                      >
                        <span
                          className="load-type-img"
                          style={{ width: "18px" }}
                        >
                          {loadImage(activeLoadType, index)} */}
            {/* <img loading="lazy" src={second[index]} alt="img" className="custom-img" /> */}
            {/* </span>
                        <span className="user-select-none">{item}</span>
                      </li>
                    ))}
                </ul>
              )} */}
            {form}
          </div>
        </div>
      </div>
    </main>
  );
}

function Form1({
  // formHeading,
  form,
  activeMobileBanner,
  activeBanner,
  activeLoadType,
  activeTagLine
}: FormType) {
  const { activeService, activeExpressParcelDeliveryType } =
    useLandingPageContext();
  const { isMobile } = useWidth()

  // const loadImage = (activeItem?: LoadType, index?: number) => {
  //   const naviStyle = { width: "30px", height: "30px" };
  //   if (activeItem === "Part Load" && index === 0) {
  //     return <PartLoadSvg className="text-white-900" style={naviStyle} />;
  //   } else if (activeItem === "Part Load" && index === 1) {
  //     return <FullLoadSvg className="text-black-900" style={naviStyle} />;
  //   } else if (activeItem === "Full Load" && index === 0) {
  //     return <PartLoadSvg className="text-black-900" style={naviStyle} />;
  //   } else {
  //     return <FullLoadSvg className="text-white-900" style={naviStyle} />;
  //   }
  // };
  let activeTitle = "Intracity";
  switch(activeService) {
    case "Intra City":
      activeTitle = "Intracity";
      break;
    case "Express Parcel":
      activeTitle = activeExpressParcelDeliveryType === "Branch to Branch" ? "Branch to Branch" : "Door Delivery";
      break;
    case "Part Load & Full Load":
      activeTitle = activeLoadType === "Part Load" ? "Part Load" : "Full Load";
      break;
    default:
      activeTitle = "Intracity";
  }

  return (
    <main className="main | relative">
      {isMobile && activeMobileBanner
        ? <div className="grid gap-1 p-1">
            <h1 className="| fs-heading fw-900 text-primary-400 m-0 text-center">
              {activeTitle}
            </h1>
            <p
              className="| fs-medium text-black-400 fw-400 m-0 m-text-center"
              style={{ lineHeight: "1.1" }}
            >
              {activeTagLine}
            </p>
            <div className="" style={{ height: "200px" }}>
              <img className="main-mobile-banner br-4" src={activeMobileBanner} alt="banner" />
            </div>
            <div className="">
              {form}
            </div>
          </div>
        : <div className="main-banner-container">
            <img className="main-banner" src={activeBanner} alt="banner" />
        </div>
      }
      {isMobile 
        ? <></> 
        : <div className="get-estimate-card | flex-center gap-2 relative">
        {/* Active Get Estimate Form (Service Form) */}
        <div className="gap-1 m-one-column w-100">
        {/* <div className="floating-label">
          <h1 className="| fs-innerheading fw-900 text-primary-400 m-0 text-left">
              {formHeading}
            </h1>
        </div>
          {/* <div className="flex-col get-estimate-card-column gap-1">
            <h1 className="| fs-heading fw-900 text-primary-400 text-left m-text-center m-0 capitalize">
              {activeService === "Express Parcel"
                ? activeExpressParcelDeliveryType
                : activeService === "Part Load & Full Load"
                ? activeLoadType
                : activeHeading}
            </h1> */}
          {/* </div> */}
          <div className="main-formm flex flex-column get-estimate-card-column get-estimate-second-column br-1 shadow-large jc-center gap-1 as-center">
            <p
              className="| fs-medium text-black-400 fw-600 m-0 m-text-center"
              style={{ lineHeight: "1.1" }}
            >
              {activeService === "Express Parcel"
                ? <> <span className="headeing-activeLoadType">{activeExpressParcelDeliveryType}</span> <span>{activeTagLine}</span></>
                : activeService === "Part Load & Full Load"
                ? <> <span className="headeing-activeLoadType">{activeLoadType}</span> <span>{activeTagLine}</span></>
                : activeTagLine}
              {/* {activeService === "Part Load & Full Load" && activeLoadType ?  <><span className="headeing-activeLoadType">{activeLoadType}</span><span>{activeTagLine}</span></> : activeTagLine} */}
            </p>
            {/* <h1 className="| fs-subheading fw-900 text-primary-400 m-0 text-left">
              {formHeading}
            </h1> */}
            {/* {activeService === "Part Load & Full Load" &&
              activePage === "services" && (
                <ul className="| two-column t-one-column m-0">
                  {loadTypes.length > 0 &&
                    loadTypes.map((item, index) => (
                      <li
                        key={item}
                        className={`domestic-form-delivery-item flex-col-center text-center fw-500 px-1 py-8 pointer fs-link ${
                          item === activeLoadType ? "active" : ""
                        }`}
                        onClick={() => {
                          if (setActiveLoadType) {
                            setActiveLoadType(item);
                          }
                        }}
                      >
                        <span
                          className="load-type-img"
                          style={{ width: "18px" }}
                        >
                          {loadImage(activeLoadType, index)} */}
            {/* <img loading="lazy" src={second[index]} alt="img" className="custom-img" /> */}
            {/* </span>
                        <span className="user-select-none">{item}</span>
                      </li>
                    ))}
                </ul>
              )} */}
            {form}
          </div>
        </div>
      </div>
      }
    </main>
  );
}

// CustomerForm Component
function CustomerForm() {
  // State
  const {
    fullName,
    setFullName,
    email,
    setEmail,
    password,
    setPassword,
    contactNumber,
    setContactNumber,
    otp,
    setOtp,
    isOtp,
    setIsOtp,
    isOtpVerified,
    setIsOtpVerified,
  } = useCustomer();

  // does: submit the create customer form
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (
      typeof fullName !== "object" ||
      typeof email !== "object" ||
      typeof contactNumber !== "object" ||
      typeof password !== "object" ||
      typeof otp !== "object"
    ) {
      return;
    }
    try {
      let payload = {
        contact: Number(contactNumber.value),
        otp: otp.value,
        // email: email,
        name: fullName.value,
        password: password.value,
      };
      customerData(payload)
        .then(() => {
          toast.success("Customer created successfully");
        })
        .catch((error: any) => {
          return showMessage(`${error?.message}`, failed, 2000);
        });
      // reset state value to empty
      resetState(setFullName, setEmail, setOtp, setPassword, setContactNumber);
      // history.push("/customerLogin")
    } catch (error) {
      showMessage(`${error}`, failed, 2000);
    }
    // reset state value to empty
    resetState(setFullName, setEmail, setOtp, setPassword, setContactNumber);
    setIsOtpVerified(false);
  }
  // does: handle the customer OTP verification
  async function handleOtp() {
    if (typeof otp !== "object") return;
    if (!otp.value) {
      alert("Please enter OTP");
      return;
    } else if (otp.error) {
      alert(otp.errorMessage);
      return;
    } else {
      if (typeof contactNumber !== "object") return;
      const payload = { contact: +(contactNumber.value), otp: +(otp.value), otpType: OtpTypes.CustomerRegister };
      const response: any = await verifyOtpForReg(payload);
      if (response) {
        showMessage("OTP verfied successfully!", success, 2000);
        showMessage("Customer registered successfully!", success, 2000);
        setIsOtp(false);
        setIsOtpVerified(true);
      } else {
        showHttpError("OTP MissMatched , Kindly Resend Otp Or Enter Valid Otp");
      }
    }
    // setCurrentPage(2);
  }
  // does: get the otp for customer creation
  async function handleGetOtp() {
    if (
      typeof fullName !== "object" ||
      typeof email === "string" ||
      typeof password === "string" ||
      typeof contactNumber === "string"
    )
      return;
    if (
      !contactNumber.value ||
      !fullName.value ||
      !email.value ||
      !password.value
    ) {
      alert("Please provie all fields!");
      return;
    }
    let flag =
      validateFullName(fullName, setFullName) &&
      validateEmail(email, setEmail) &&
      validatePassword(password, setPassword) &&
      validateContactNumber(contactNumber, setContactNumber);
    if (
      contactNumber.error ||
      fullName.error ||
      email.error ||
      password.error
    ) {
      if (!flag) {
        return;
      }
      alert("Please provide all field correctly!");
      return;
    }
    if (!flag) {
      return;
    }
    setIsOtp(true);
    if (typeof contactNumber !== "object") return;
    await getOtp({
      contact: +contactNumber.value,
      otpType: OtpTypes.CustomerRegister
    });
    showMessage("Otp sent successfully!", success, 2000);
  }

  // component return
  return (
    <>
      {/* Dialog for OTP */}
      <Dialog
        header={<>Enter your OTP</>}
        body={
          <>
            <OTPInput otp={otp} setOtp={setOtp} />
          </>
        }
        footer={
          <>
            <Button variant="primary" action="secondary" onClick={handleOtp}>
              Enter
            </Button>
          </>
        }
        state={isOtp}
        setState={setIsOtp}
        variant="primary"
        size="small"
        isOutsideClickOff={false}
      />
      {/* Form for Direct Sales Agent */}
      <form className="| flex-col gap-1 m-gap-20 w-100" onSubmit={handleSubmit}>
        {/* <div className="| fs-link text-white-700 fw-700">
        <span>General Details</span>
      </div> */}
        {/* Full Name */}
        <Input
          title="Name"
          variant="white"
          weight={400}
          width="1px"
          state={{ value: fullName, setValue: setFullName }}
          onBlur={() => validateFullName(fullName, setFullName)}
          warningWeight={400}
          required
        />
        {/* Email */}
        <Input
          title="Email"
          type="email"
          variant="white"
          weight={400}
          width="1px"
          state={{ value: email, setValue: setEmail }}
          onBlur={() => validateEmail(email, setEmail)}
          warningWeight={400}
          required
        />
        {/* Password */}
        <Input
          title="Password"
          type="password"
          variant="white"
          weight={400}
          width="1px"
          state={{ value: password, setValue: setPassword }}
          onBlur={() => validatePassword(password, setPassword)}
          warningWeight={400}
          required
        />
        {/* Contact Number */}
        <Input
          title="Contact Number"
          variant="white"
          weight={400}
          width="1px"
          state={{ value: contactNumber, setValue: setContactNumber }}
          onBlur={() => validateContactNumber(contactNumber, setContactNumber)}
          warningWeight={400}
          required
          type="tel"
          maxLength={10}
        />
        {/* Get OTP Button */}
        {isOtpVerified ? (
          <Button
            variant="primary"
            action="primary"
            Icon={<GetDetailsIcon />}
            className="as-start fs-button"
          >
            Submit
          </Button>
        ) : (
          <Button
            variant="secondary"
            action="secondary"
            Icon={<GetDetailsIcon />}
            className="as-start fs-button"
            type="button"
            onClick={handleGetOtp}
          >
            Get OTP
          </Button>
        )}
      </form>
    </>
  );
}

export default Main;
