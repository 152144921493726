import React, { ComponentProps, ReactNode } from "react";

type PaperCardProps = { label?: string | ReactNode, labelClassName?: string } & ComponentProps<"div">;

export default function PaperCard({ label, labelClassName = "", className = "", children, ...props }: PaperCardProps) {
  return (
    <div className={`paper ${label ? "flex-col gap-1" : ""}  ${className}`} {...props}>
      {label && <span className={`${labelClassName ? "" : "fw-500 fs-link"}`}>{label}</span>}
      {children}
    </div>
  );
}
