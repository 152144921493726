import axios from "axios";
import {
  RESET_INCUR_EXPENSE,
  DELETE_EXPENSE_RECORD,
  EDIT_EXPENSE_RECORD,
  SET_EXPENSE_INCUR,
  FUEL_IN_EXPENSE_ON_CHANGE,
  SET_FUEL_VALUES,
  DELETE_FUEL_EXPENSE,
  DELETE_EXPENSE_RECORD_FAILED,
  EDIT_EXPENSE_RECORD_FAILED,
  COULD_NOT_FETCH_CONTACT,
  NON_CASH_CHANGE,
  FIXED_EXPENSE_TYPE,
  SET_PAST_EXPENSES,
  SHOW_LOADER_IN_AMOUNT,
  HIDE_LOADER_IN_AMOUNT,
  AVAILABLE_BALANCE,
  DISABLE_OP_KMS,
  ENABLE_OP_KMS,
  PRINT_EXPENSE_VOUCHER,
} from "../../constants/expense";
import { SHOW_LOADER, HIDE_LOADER } from "../../constants/booking";
import {
  deleteIncurExpense,
  editIncurExpense,
  createFuelExpense,
  editFuelExpense,
  deleteFuelExpense,
  getFixedExpenseTypeById,
  getPastExpenses as pastExpenseService,
  incurExpenseService,
  getFixedExpenseLimit,
  setAutoExpense,
  AutoIncentiveExpense,
  editAutoExpense,
  editAutoIncentive,
  deleteAutoExpense,
} from "../../services/expenses";
import { fetchFleetByNumber } from "../../services/fleet";
import { listUsersService } from "../../services/user";
import { url, updateBalance } from "../UserActions";
import { listAssetType, viewAssetBalanceDetails } from "../../services/asset";
import { getUserByContact } from "../../services/user";
import {
  validateExpense,
  finalExpenseValidation,
} from "../../reducers/schemas/expense";
import { showMessage, failed, success } from "../../utils/message";
import { isArrayCheck } from "../../containers/generics/CheckArray";
import { months } from "../../containers/expense/components/Dates";
import showErrorMessage from "../../containers/generics/showErrorMessage";
import jsonToFormdata from "../../utils/jsonToFormdata";
import { showHttpError } from "../../utils/message";
// import PrintVoucher from '../../containers/expense/components/PrintVoucher';
import {
  handelCharge,
  editChargeExpense,
  deleteChargeExpense,
} from "../../services/expenses";
import { Dispatch } from "redux";
import { RootState } from "../../store";
import formatDate from "../../services/FormatDate";

export const fetchExpenseTypes = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({ type: SHOW_LOADER });
      let response = await axios.post(url + "/expense/list", data);
      dispatch({
        type: "SET_EXPENSE_TYPES",
        payload: response.data.response,
      });
      dispatch({ type: HIDE_LOADER });
    } catch (err : any) {
      console.log(err);
      dispatch({ type: HIDE_LOADER });
    }
  };
};

const dumbSelect = (val: any, expenseData: any) => ({
  type: "SELECT_EXPENSE_TYPE",
  payload: { val, expenseData },
});

export const selectExpenseType = (
  val: any,
  expenseData: any,
  userData: any
) => {
  return async (dispatch: any, getState: () => RootState) => {
    dispatch({ type: SHOW_LOADER });
    await dispatch(dumbSelect(val, expenseData));
    const { user } = getState();
    if (expenseData.variance == "F") {
      let entities;
      try {
        const response: any = await getFixedExpenseTypeById({
          id: val.value,
          company: userData.companyId,
        });
        entities = response.entities;
      } catch (error) {
        showMessage("No Fixed Expenses Added!", failed);
      }
      dispatch({ type: FIXED_EXPENSE_TYPE, payload: true });
      switch (expenseData.sub) {
        case "U":
          const selectUsers =
            Array.isArray(entities) && entities.length > 0
              ? entities.map((u) => ({
                  label:
                    u.name && u.name.fName
                      ? u.name.fName + " " + u.name.lName
                      : "N/A",
                  value: u._id ? u._id : "",
                }))
              : [];
          dispatch({ type: "SET_SUB_EXPENSES", payload: selectUsers });
          break;
        case "A":
          {
            dispatch({
              type: "SET_ASSET_BAL",
              payload: entities,
            });
          }
          break;
        case "B": {
          let assignedBranches = entities
            ? entities.filter((e: { _id: string }) =>
                user.branches.map((b: { _id: string }) => b._id).includes(e._id)
              )
            : [];
          const selectBranches = isArrayCheck(assignedBranches)
            ? assignedBranches.map(
                (e: { branchName: string; _id: string }) => ({
                  label: e.branchName ? e.branchName : "N/A",
                  value: e._id ? e._id : "",
                })
              )
            : [];
          dispatch({ type: "SET_SUB_EXPENSES", payload: selectBranches });
          break;
        }
        case "G": {
          dispatch(fetchFixedLimitBalance());
          break;
        }
        default:
          showMessage("No Such sub", failed);
          break;
      }
    } else {
      dispatch({ type: FIXED_EXPENSE_TYPE, payload: false });
      try {
        let subTypes;
        switch (expenseData.sub) {
          case "G": {
            break;
          }
          case "B": {
            subTypes = user.branches;
            const selectBranches = isArrayCheck(subTypes)
              ? subTypes.map((e: { branchName: string; _id: string }) => ({
                  label: e.branchName ? e.branchName : "N/A",
                  value: e._id ? e._id : "",
                }))
              : [];
            dispatch({ type: "SET_SUB_EXPENSES", payload: selectBranches });
            break;
          }
          case "U": {
            let postData = {
              branch: userData.branchId,
              companyId: userData.companyId,
            };

            let response = await listUsersService(postData);
            const selectUsers =
              Array.isArray(response) && response.length > 0
                ? response.map((u) => ({
                    label:
                      u.name && u.name.fName
                        ? u.name.fName + " " + u.name.lName
                        : "N/A",
                    value: u._id ? u._id : "",
                  }))
                : [];
            dispatch({ type: "SET_SUB_EXPENSES", payload: selectUsers });
            break;
          }
          case "A": {
            let body = {
              categoryId: expenseData.category,
              company: getState().user.company._id,
            };
            let response = await listAssetType(body);
            const mapAssetTypes = isArrayCheck(response)
              ? response.map((t: { assetName: string; _id: string }) => ({
                  label: t.assetName,
                  value: t._id,
                }))
              : [];
            await dispatch({ type: "EXP_ASSET_SET", payload: mapAssetTypes });

            break;
          }
          case "F": {
            let body = {
              categoryId: "vehicle",
              company: getState().user.company._id,
            };
            let response = await listAssetType(body);
            const mapAssetTypes = isArrayCheck(response)
              ? response.map((t: { assetName: string; _id: string }) => ({
                  label: t.assetName,
                  value: t._id,
                }))
              : [];
            await dispatch({
              type: "EXP_ASSET_SET",
              payload: mapAssetTypes,
            });
            break;
          }
        }
      } catch (err : any) {
        console.log(err);
        showMessage("Something Went Wrong !", failed);
      }
    }
    dispatch({ type: HIDE_LOADER });
  };
};

export const fuelInExpenseOnChange = (what: any, val: any) => ({
  type: FUEL_IN_EXPENSE_ON_CHANGE,
  payload: { what, val },
});

export const selectAssetType = (data: any) => {
  return async (dispatch: any) => {
    try {
      await dispatch({ type: "EXP_SEL_ASSET_TYPE", payload: data });
      await dispatch(getAsset(data.value));
    } catch (error) {
      console.log("error: ", error);
    }
  };
};

export const getAsset = (payload: any) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { user } = getState();
      let response = await axios.post(url + "/asset/balance/list", {
        assetType: payload,
        company: user.company.id,
      });
      await dispatch({
        type: "SET_ASSET_BAL",
        payload: response.data.response,
      });
    } catch (err : any) {
      showMessage("Could not fetch asset !", failed);
      console.log(err);
    }
  };
};

export const selectAssetBalance = (val: any) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch({ type: "SELECT_ASSET_BAL", payload: val });
      let { expense } = getState().expense;
      if (expense.sub == "F") {
        let response = await fetchFleetByNumber({ regNumber: val.label });
        if (response && response.distanceCovered) {
          dispatch({
            type: FUEL_IN_EXPENSE_ON_CHANGE,
            payload: { what: ["op"], val: response.distanceCovered },
          });
          dispatch({ type: DISABLE_OP_KMS });
        } else {
          dispatch({ type: ENABLE_OP_KMS });
          dispatch({
            type: FUEL_IN_EXPENSE_ON_CHANGE,
            payload: { what: ["op"], val: "" },
          });
        }
        dispatch({
          type: FUEL_IN_EXPENSE_ON_CHANGE,
          payload: { what: ["avgIdeal"], val: response.idealFuel },
        });
      }
      const { variance, ref, start_date } = getState().expense.expense,
        month = new Date(start_date).getMonth() + 1,
        year = new Date(start_date).getFullYear(),
        date = `${year}/${month}/1`;
      if (variance == "F") {
        try {
          dispatch({ type: SHOW_LOADER_IN_AMOUNT });
          const response = await getFixedExpenseLimit(
            ref.value,
            date,
            val.value
          );
          dispatch({ type: AVAILABLE_BALANCE, payload: response });

          dispatch({ type: HIDE_LOADER_IN_AMOUNT });
        } catch (error) {
          dispatch({ type: HIDE_LOADER_IN_AMOUNT });
          console.log("error: ", error);
        }
      }
    } catch (error) {
      console.log("error: ", error);
      showMessage("Could not get closing kilometres !", failed);
    }
  };
};
export const dumbSubSelect = (id: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    await dispatch({ type: "SELECT_SUB_EXPENSE_TYPE", payload: id });
    const { expense } = getState().expense,
      { variance, assetBalance, sub_val } = expense;
    if (variance == "F" && (assetBalance || sub_val)) {
      if (expense.monthly_duration) {
        if (expense.month && expense.year) {
          dispatch(fetchFixedLimitBalance());
        }
      } else if (!expense.monthly_duration) {
        dispatch(fetchFixedLimitBalance());
      }
    }
  };
};

export const changeExpenseAmount = (val: any) => ({
  type: "EXPENSE_AMT",
  payload: val,
});

export const toggleAttach = (attach: any, company: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({ type: "TOGGLE_ATTACH_ROUTE" });
      if (attach == false) {
        let response = await axios.post(url + "/route/company", {
          companyId: company,
        });
        if (response.data.status != 1) throw "RouteError";
        dispatch({ type: "SET_EXP_ROUTES", payload: response.data.response });
      }
    } catch (err : any) {
      console.log(err);
    }
  };
};

export const selectExpenseRoute = (val: any) => ({
  type: "SEL_EXP_ROUTE",
  payload: val,
});

export const expStartDate = (val: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    await dispatch({ type: "EXP_START_DATE", payload: val });
    const { expense } = getState().expense,
      { variance, assetBalance, sub_val, start_date } = expense;

    if (variance == "F" && (assetBalance || sub_val) && start_date) {
      dispatch(fetchFixedLimitBalance());
    }
  };
};
export const fetchFixedLimitBalance = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { expense } = getState().expense,
      {
        assetBalance,
        sub_val,
        start_date,
        monthly_duration,
        ref,
        sub,
      } = expense;
    let date;
    const month = new Date(start_date).getMonth() + 1,
      year = new Date(start_date).getFullYear();
    if (monthly_duration) {
      date = `${expense.year.value}/${parseInt(expense.month.value, 10) + 1}/1`;
    } else {
      let day = new Date(start_date).getDate();
      date = `${year}/${month}/${day}`;
    }
    dispatch({ type: SHOW_LOADER_IN_AMOUNT });
    try {
      let response;
      if (sub != "A") {
        response = await getFixedExpenseLimit(ref.value, date, sub_val.value);
      } else {
        response = await getFixedExpenseLimit(
          ref.value,
          date,
          assetBalance.value
        );
      }
      dispatch({ type: AVAILABLE_BALANCE, payload: response });
      dispatch({ type: HIDE_LOADER_IN_AMOUNT });
      showMessage("Fixed Limit Fetched !", success);
    } catch (error) {
      dispatch({ type: AVAILABLE_BALANCE, payload: {} });
      dispatch({ type: HIDE_LOADER_IN_AMOUNT });
      showMessage("Could Not Fetch Fixed Limit !");
    }
  };
};
export const expEndDate = (val: any) => ({
  type: "EXP_END_DATE",
  payload: val,
});

export const setVoucherImage = (val: any) => ({
  type: "SET_VOUCHER_IMAGE",
  payload: val,
});

export const setBillImage = (val: any) => ({
  type: "SET_BILL_IMAGE",
  payload: val,
});

export const incurExpense = (payload: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { uid: user, opBranch, loginType, opFleet } = getState().user;
      let isValid;
      dispatch({ type: SHOW_LOADER });
      try {
        isValid = validateExpense(finalExpenseValidation, payload);
        if (typeof isValid == "string") throw isValid;
      } catch (error) {
        dispatch({ type: HIDE_LOADER });
        dispatch({ type: "INCUR_EXP_FAILED", payload: isValid });
        throw isValid;
      }
      if (typeof isValid == "string") throw isValid;
      delete payload.is_route;

      const response = await incurExpenseService(jsonToFormdata(payload));
      let { balance } = response;
      await dispatch(updateBalance(balance.balance, balance));
      if (loginType == "F") {
        dispatch(setPastExpenses(user, opFleet._id, loginType, 1));
      } else {
        dispatch(setPastExpenses(user, opBranch._id, loginType, 1));
      }
      dispatch({ type: HIDE_LOADER });
      dispatch({
        type: "INCUR_EXP_SUCCESS",
        payload: response.expense.voucherNumber,
      });
      return response
      // dispatch(resetExpenseIncur())
    } catch (error : any) {
      await dispatch({ type: HIDE_LOADER });
      if (error && error.message && typeof error.message == "object") {
        let { message: err } = error;
        let temp = Object.keys(err),
          key = temp[temp.length - 1];
        showMessage(err[key], failed);
      } else if (error && error.message) {
        showMessage(error.message, failed);
      } else {
        showMessage(error, failed);
      }
    }
  };
};

export const incurSetAutoExpense = (payload: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { uid: user, opBranch, loginType, opFleet } = getState().user;
      dispatch({ type: SHOW_LOADER });

      const response = await setAutoExpense(jsonToFormdata(payload));
      // dispatch({ type: SET_EXPENSE_INCUR, payload: response });
      dispatch({
        type: "INCUR_EXP_SUCCESS",
        payload: response.expense.voucherNumber,
      });
      let { balance } = response;
      await dispatch(updateBalance(balance.balance, response));
      if (loginType == "F") {
        dispatch(setPastExpenses(user, opFleet._id, loginType, 1));
      } else {
        dispatch(setPastExpenses(user, opBranch._id, loginType, 1));
      }
      dispatch({ type: HIDE_LOADER });

      // dispatch(resetExpenseIncur())
    } catch (error : any) {
      await dispatch({ type: HIDE_LOADER });
      if (error && error.message && typeof error.message == "object") {
        let { message: err } = error;
        let temp = Object.keys(err),
          key = temp[temp.length - 1];
        showMessage(err[key], failed);
      } else if (error && error.message) {
        showMessage(error.message, failed);
      } else {
        showMessage(error, failed);
      }
    }
  };
};

export const setAutoIncentiveExpense = (payload: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { uid: user, opBranch, loginType, opFleet } = getState().user;
      dispatch({ type: SHOW_LOADER });

      const response = await AutoIncentiveExpense(jsonToFormdata(payload));
      // dispatch({ type: SET_EXPENSE_INCUR, payload: response });
      dispatch({
        type: "INCUR_EXP_SUCCESS",
        payload: response.expense.voucherNumber,
      });
      let { balance } = response;
      await dispatch(updateBalance(balance.balance, response));
      if (loginType == "F") {
        dispatch(setPastExpenses(user, opFleet._id, loginType, 1));
      } else {
        dispatch(setPastExpenses(user, opBranch._id, loginType, 1));
      }
      dispatch({ type: HIDE_LOADER });

      // dispatch(resetExpenseIncur())
    } catch (error : any) {
      await dispatch({ type: HIDE_LOADER });
      if (error && error.message && typeof error.message == "object") {
        let { message: err } = error;
        let temp = Object.keys(err),
          key = temp[temp.length - 1];
        showMessage(err[key], failed);
      } else if (error && error.message) {
        showMessage(error.message, failed);
      } else {
        showMessage(error, failed);
      }
    }
  };
};

export const incurincentiveExpense = (data: any, id: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch({ type: SHOW_LOADER });
      await editAutoIncentive(jsonToFormdata(data), id);
      // dispatch({ type: SET_EXPENSE_INCUR, payload: response });

      dispatch({ type: EDIT_EXPENSE_RECORD });
      const { uid: user, opBranch, loginType, opFleet } = getState().user;
      if (loginType == "F") {
        dispatch(setPastExpenses(user, opFleet._id, loginType, 1));
      } else {
        dispatch(setPastExpenses(user, opBranch._id, loginType, 1));
      }
      dispatch({ type: HIDE_LOADER });
    } catch (error : any) {
      console.log("error: ", error);
      await dispatch({ type: HIDE_LOADER });
      showMessage(error.message || "Can not edit expense!", failed);
    }
  };
};

export const incurEditAutoExpense = (data: any, id: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      dispatch({ type: SHOW_LOADER });

      const response = await editAutoExpense(jsonToFormdata(data), id);
      // dispatch({ type: SET_EXPENSE_INCUR, payload: response });

      if (getState().user.uid == response.user) {
        dispatch(updateBalance(response.balance, response));
      }
      dispatch({ type: EDIT_EXPENSE_RECORD });
      const { uid: user, opBranch, loginType, opFleet } = getState().user;
      if (loginType == "F") {
        dispatch(setPastExpenses(user, opFleet._id, loginType, 1));
      } else {
        dispatch(setPastExpenses(user, opBranch._id, loginType, 1));
      }
      dispatch({ type: HIDE_LOADER });
    } catch (error : any) {
      await dispatch({ type: HIDE_LOADER });
      showMessage(error.message || "Can not edit expense!", failed);
    }
  };
};

export const incurDeleteAutoExpense = (id: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      let { uid, opBranch, loginType, opFleet } = getState().user;

      let response = await deleteAutoExpense(id);

      dispatch({ type: DELETE_EXPENSE_RECORD });
      if (loginType == "F") {
        dispatch(setPastExpenses(uid, opFleet._id, loginType, 1));
      } else {
        dispatch(setPastExpenses(uid, opBranch._id, loginType, 1));
      }
      let { balance, user } = response;
      if (getState().user.uid == user) {
        dispatch(updateBalance(balance, response));
      }
    } catch (error : any) {
      dispatch({ type: DELETE_EXPENSE_RECORD_FAILED, payload: error.message });
    }
  };
};

// {start}
// export const incurExpenseData = (payload) => {
//   return async (dispatch, getState) => {
//     try {
//       const { uid: user, opBranch, loginType, opFleet } = getState().user;
//       let isValid;
//       dispatch({ type: SHOW_LOADER });
//       try {
//         isValid = validateExpense(finalExpenseValidation, payload);
//         if (typeof isValid == "string") throw isValid;
//       } catch (error) {
//         dispatch({ type: HIDE_LOADER });
//         dispatch({ type: "INCUR_EXP_FAILED", payload: isValid });
//         throw isValid;
//       }
//       if (typeof isValid == "string") throw isValid;
//       delete payload.is_route;

//       const response = await incurExpenseService(jsonToFormdata(payload));
//       let { balance } = response;
//       await dispatch(updateBalance(balance.balance, balance));
//       if (loginType == "F") {
//         dispatch(setPastExpenses(user, opFleet._id, loginType));
//       } else {
//         dispatch(setPastExpenses(user, opBranch._id, loginType));
//       }
//       dispatch({ type: HIDE_LOADER });
//       dispatch({
//         type: "INCUR_EXP_SUCCESS",
//         payload: response.expense.voucherNumber,
//       });
//       // dispatch(resetExpenseIncur())
//     } catch (error) {
//       await dispatch({ type: HIDE_LOADER });
//       if (error && error.message && typeof error.message == "object") {
//         let { message: err } = error;
//         let temp = Object.keys(err),
//           key = temp[temp.length - 1];
//         showMessage(err[key], failed);
//       } else if (error && error.message) {
//         showMessage(error.message, failed);
//       } else {
//         showMessage(error, failed);
//       }
//     }
//   };
// };
// {end}
export const setExpenseIncur : any = (data: any, cb: any, args: any) => async (
  dispatch: any,
  getState: () => RootState
) => {
  try {
    let subTypes: any = {},
      expenseTypes = getState().expense.expenseTypes,
      foundExpense = expenseTypes[data.expenseTypeId];
    if (foundExpense) {
      data = {
        ...data,
        monthly_duration: foundExpense.monthly_duration,
        variance: foundExpense.variance,
        duration: foundExpense.duration,
        is_route: foundExpense.is_route,
        ref: { label: foundExpense.name, value: foundExpense.id },
        month: foundExpense.monthly_duration
          ? months[new Date(data.start_date).getMonth()]
          : "",
        year: foundExpense.monthly_duration
          ? {
              label: new Date(data.start_date).getFullYear(),
              value: new Date(data.start_date).getFullYear(),
            }
          : "",
      };
    }
    let forRoute = Object.assign({}, data);
    if (!["", null].includes(data.route)) {
      let fetchedRoutes = getState().expense.routes;
      if (isArrayCheck(fetchedRoutes)) {
        let foundRoute = fetchedRoutes.find(
          (r: { _id: string }) => r._id == data.route
        );
        forRoute.route = {
          label: foundRoute && foundRoute.name ? foundRoute.name : "N/A",
          value: foundRoute._id ? foundRoute._id : "",
        };
      }
    }
    data = Object.assign({}, forRoute);
    if (foundExpense.variance == "F") {
      let entities;
      try {
        const response: any = await getFixedExpenseTypeById({
          id: data.expenseTypeId,
          company: getState().user.company._id,
        });
        entities = response.entities;
      } catch (error) {
        showMessage("Could not fetch fixed expense data!", failed);
      }
      switch (data.sub) {
        case "B": {
          if (isArrayCheck(entities)) {
            let foundBranch = entities.find(
              (s: { _id: string }) => data.sub_val == s._id
            );
            data["sub_val"] = {
              label:
                foundBranch && foundBranch.branchName
                  ? foundBranch.branchName
                  : "N/A",
              value: foundBranch && foundBranch._id ? foundBranch._id : "",
            };
          }
          const selectBranches = isArrayCheck(entities)
            ? entities.map((e: { branchName: string; _id: string }) => ({
                label: e.branchName ? e.branchName : "N/A",
                value: e._id ? e._id : "",
              }))
            : [];
          await dispatch({ type: "SET_SUB_EXPENSES", payload: selectBranches });
          // await dispatch({ type: SET_EXPENSE_INCUR, payload: data })
          break;
        }
        case "U": {
          let temp = entities.find(
            (s: { _id: string }) => s._id == data.sub_val
          );
          if (temp) {
            data["sub_val"] = {
              label: temp.name
                ? temp.name.fName +
                  " " +
                  temp.name.mName +
                  " " +
                  temp.name.lName
                : "N/A",
              value: temp._id ? temp._id : "",
            };
          }
          const selectUsers = isArrayCheck(entities)
            ? entities.map((u: any) => ({
                label: u.name
                  ? u.name.fName + " " + u.name.mName + " " + u.name.lName
                  : "N/A",
                value: u._id ? u._id : "",
              }))
            : [];
          await dispatch({ type: "SET_SUB_EXPENSES", payload: selectUsers });
          // await dispatch({ type: SET_EXPENSE_INCUR, payload: data })
          break;
        }
        case "A": {
          {
            await dispatch({
              type: "SET_ASSET_BAL",
              payload: entities,
            });
          }

          let assetBalances = getState().expense.assetBalances;
          let balanceKeys = Object.keys(assetBalances);

          for (let idx = 0; idx < balanceKeys.length; idx++) {
            let key = balanceKeys[idx];
            if (assetBalances[key]._id == data.sub_val) {
              let temp = assetBalances[key];
              data["assetBalance"] = {
                label: temp.uniqueId
                  ? temp.uniqueId
                  : temp.qty + " " + temp.type.assetName,
                value: temp && temp._id ? temp._id : "",
              };
              break;
            }
          }
          // await dispatch({ type: SET_EXPENSE_INCUR, payload: data })
          break;
        }
      }
      await dispatch({ type: SET_EXPENSE_INCUR, payload: data });
      dispatch(fetchFixedLimitBalance());
    } else {
      switch (data.sub) {
        case "B": {
          subTypes = getState().user.branches;
          if (isArrayCheck(subTypes)) {
            let foundBranch = subTypes.find(
              (s: { _id: string }) => data.sub_val == s._id
            );
            data["sub_val"] = {
              label:
                foundBranch && foundBranch.branchName
                  ? foundBranch.branchName
                  : "N/A",
              value: foundBranch && foundBranch._id ? foundBranch._id : "",
            };
          }
          const selectBranches = isArrayCheck(subTypes)
            ? subTypes.map((e: { branchName: string; _id: string }) => ({
                label: e.branchName ? e.branchName : "N/A",
                value: e._id ? e._id : "",
              }))
            : [];

          // dispatch({ type: 'SET_SUB_EXPENSES', payload: subTypes })
          dispatch({ type: "SET_SUB_EXPENSES", payload: selectBranches });
          // await Promise.all[(
          // dispatch({ type: SET_EXPENSE_INCUR, payload: data })
          // )]
          break;
        }
        case "U": {
          let postData: any = {};
          if (!getState().rights.wallet.incurExpense.adminLevelIncur) {
            postData["branch"] = (getState().user.opBranch || {})._id;
          }
          let subTypes = await listUsersService(postData);
          let temp = subTypes.find(
            (s: { _id: string }) => s._id == data.sub_val
          );
          if (temp) {
            data["sub_val"] = {
              label: temp.name
                ? temp.name.fName +
                  " " +
                  temp.name.mName +
                  " " +
                  temp.name.lName
                : "N/A",
              value: temp._id ? temp._id : "",
            };
          }
          const selectUsers = isArrayCheck(subTypes)
            ? subTypes.map((u: any) => ({
                label: u.name
                  ? u.name.fName + " " + u.name.mName + " " + u.name.lName
                  : "N/A",
                value: u._id ? u._id : "",
              }))
            : [];
          dispatch({ type: "SET_SUB_EXPENSES", payload: selectUsers });
          // await Promise.all[(
          // dispatch({ type: SET_EXPENSE_INCUR, payload: data })
          // )]
          break;
        }
        case "A": {
          let fetchAssetDetails = await viewAssetBalanceDetails(data.sub_val);
          let { _id, category, assetName } = fetchAssetDetails.type;
          let assetType = { label: assetName, value: _id };
          data["assetType"] = assetType;
          let body = {
            categoryId: category,
            company: getState().user.company._id,
          };
          let response = await listAssetType(body);
          const mapAssetTypes = isArrayCheck(response)
            ? response.map((t: { assetName: string; _id: string }) => ({
                label: t.assetName,
                value: t._id,
              }))
            : [];
          await Promise.all([
            dispatch({ type: "EXP_ASSET_SET", payload: mapAssetTypes }),
            // dispatch({ type: 'EXP_SEL_ASSET_TYPE', payload: assetType }),
            dispatch(getAsset(_id)),
          ]);
          let assetBalances = getState().expense.assetBalances;
          for (let key in assetBalances) {
            if (assetBalances[key]._id == data.sub_val) {
              let temp = assetBalances[key];
              data.assetBalance = {
                label: temp.uniqueId
                  ? temp.uniqueId
                  : temp.qty + " " + temp.type.assetName,
                value: temp && temp._id ? temp._id : "",
              };
              break;
            }
          }
          // dispatch({ type: SET_EXPENSE_INCUR, payload: data })
          break;
        }
        case "F": {
          let fetchAssetDetails = await viewAssetBalanceDetails(data.sub_val);
          let { _id, category, assetName } = fetchAssetDetails.type;
          let assetType = { label: assetName, value: _id };
          data["assetType"] = assetType;
          await dispatch({ type: "EXP_SEL_ASSET_TYPE", payload: assetType });
          await dispatch(getAsset(_id));

          let body = {
            categoryId: category,
            company: getState().user.company._id,
          };
          let response = await listAssetType(body);
          const mapAssetTypes = isArrayCheck(response)
            ? response.map((t: { assetName: string; _id: string }) => ({
                label: t.assetName,
                value: t._id,
              }))
            : [];
          await dispatch({ type: "EXP_ASSET_SET", payload: mapAssetTypes });

          let assetBalances = getState().expense.assetBalances;
          if (data.fuelData.vehicle in assetBalances) {
            let temp = assetBalances[data.fuelData.vehicle];
            data["assetBalance"] = {
              label: temp.uniqueId
                ? temp.uniqueId
                : temp.qty + " " + temp.type.assetName,
              value: temp && temp._id ? temp._id : "",
            };
          }
          let fuelData = data.fuelData;
          fuelData["op"] = fuelData.open;
          delete fuelData.open;
          fuelData["cl"] = fuelData.close;
          delete fuelData.close;
          fuelData["rnum"] = fuelData.vehicle;
          delete fuelData.vehicle;
          dispatch({ type: SET_FUEL_VALUES, payload: fuelData });
          // await Promise.all[(
          //     dispatch({ type: SET_EXPENSE_INCUR, payload: data })
          // )]
          break;
        }
      }
      dispatch({ type: SET_EXPENSE_INCUR, payload: data });
    }
    if (typeof cb === "function") {
      cb(args);
    }
    // dispatch({ type: HIDE_LOADER })
  } catch (err : any) {
    dispatch({ type: HIDE_LOADER });
    showErrorMessage(err, "reasons", "message");
  }
};
// export const closePrintVoucher = () => ({ type: CLOSE_VOUCHER_MODAL })

export const rePrintVoucher = (data: any, cb: any, afterExpense: any) => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  try {
    let payload = { ...data },
      { branches, opBranch } = getState().user;
    payload["start_date"] = formatDate(data.start_date);
    payload["end_date"] = formatDate(data.end_date);
    if (!afterExpense) {
      switch (data.sub) {
        case "B": {
          let allBranches = branches,
            branch = allBranches.filter(
              (b: { _id: string }) => b._id == data.sub_val
            );

          if (branch) payload["sub_val"] = branch.branchName;
          break;
        }
        case "U": {
          let postData: any = {};
          if (!getState().rights.wallet.incurExpense.adminLevelIncur) {
            postData["branch"] = (opBranch || {})._id;
          }
          let subTypes = await listUsersService(postData);
          let user = subTypes.find(
            (s: { _id: string }) => s._id == data.sub_val
          );
          if (user) payload["sub_val"] = user.name.fName;
          break;
        }
        case "A": {
          let assetBal = await viewAssetBalanceDetails(data.sub_val);
          if (assetBal)
            payload["sub_val"] = assetBal.uniqueId
              ? assetBal.uniqueId
              : assetBal.qty + " " + assetBal.type.assetName;
          break;
        }
        case "F": {
          let assetBal = await viewAssetBalanceDetails(data.sub_val);
          if (assetBal)
            payload["sub_val"] = assetBal.uniqueId
              ? assetBal.uniqueId
              : assetBal.qty + " " + assetBal.type.assetName;
          break;
        }
        case "G": {
          payload["sub_val"] = getState().user.company.name;
          break;
        }
        default: {
          showMessage("Error in Sub Value", failed);
          break;
        }
      }
      // dispatch({ type: PRINT_EXPENSE_VOUCHER, payload })
    }
    // else {
    dispatch({ type: PRINT_EXPENSE_VOUCHER, payload });
    // }

    if (typeof cb == "function") {
      cb();
    }
  } catch (err : any) {
    console.log("err: ", err);
  }
};
export const resetExpenseIncur = () => ({ type: RESET_INCUR_EXPENSE });

export const deleteExpenseRecord = (id: any, company: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      let { uid, opBranch, loginType, opFleet } = getState().user;
      let payload = {
        id,
        company,
        uid,
      };
      let response: any = await deleteIncurExpense(payload);
      if (response.status == 1) {
        dispatch({ type: DELETE_EXPENSE_RECORD });
        if (loginType == "F") {
          dispatch(setPastExpenses(uid, opFleet._id, loginType, 1));
        } else {
          dispatch(setPastExpenses(uid, opBranch._id, loginType, 1));
        }
        let { balance } = response.response;
        if (getState().user.uid == balance.user) {
          dispatch(updateBalance(balance.balance, balance));
        }
      } else {
        await dispatch({ type: DELETE_EXPENSE_RECORD_FAILED });
        if (loginType == "F") {
          dispatch(setPastExpenses(uid, opFleet._id, loginType, 1));
        } else {
          dispatch(setPastExpenses(uid, opBranch._id, loginType, 1));
        }
      }
    } catch (error : any) {
      dispatch({ type: DELETE_EXPENSE_RECORD_FAILED, payload: error.message });
    }
  };
};

export const editExpenseRecord = (data: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      let isValid;
      dispatch({ type: SHOW_LOADER });
      try {
        isValid = validateExpense(finalExpenseValidation, data);
        if (typeof isValid == "string") throw "Validation Error";
      } catch (error) {
        dispatch({ type: EDIT_EXPENSE_RECORD_FAILED, payload: isValid });
      }
      if (typeof isValid == "string") throw isValid;

      const response = await editIncurExpense(jsonToFormdata(data));
      if (getState().user.uid == response.user) {
        dispatch(updateBalance(response.balance, response));
      }
      dispatch({ type: EDIT_EXPENSE_RECORD });
      const { uid: user, opBranch, loginType, opFleet } = getState().user;
      if (loginType == "F") {
        dispatch(setPastExpenses(user, opFleet._id, loginType, 1));
      } else {
        dispatch(setPastExpenses(user, opBranch._id, loginType, 1));
      }
      dispatch({ type: HIDE_LOADER });
    } catch (error : any) {
      await dispatch({ type: HIDE_LOADER });
      showMessage(error.message || "Can not edit expense!", failed);
      // if (error && error.message) {
      //     let { message: err } = error
      //     let temp = Object.keys(err),
      //         key = temp[temp.length - 1]
      //     showMessage(err[key], failed)
      // }
      // else if (error) {
      //     showMessage(error, failed)
      // }
    }
  };
};
export const getReport = (payload: any) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await axios.post(url + "/report/create", payload);
      if (response.data.status != 1) throw "CollReportError";

      const { response: report } = response.data;

      dispatch({
        type: "SET_COL_REPORT",
        payload: report,
      });
    } catch (err : any) {
      console.log(err);
    }
  };
};
export const getNameByContact = (contact: any) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await getUserByContact(contact);
      let data = response.name.fName;
      // response.status == 1 ?
      dispatch(fuelInExpenseOnChange(["dName"], data));
      // dispatch({ type: 'GET_NAME_BY_CONTACT', payload: data })
      // dispatch({ type: COULD_NOT_FETCH_CONTACT })
    } catch (error) {
      dispatch({ type: COULD_NOT_FETCH_CONTACT });
      console.log("error: ", error);
    }
  };
};

export const handleTempoData = (data: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    let { uid, opBranch, loginType, opFleet } = getState().user;
    let res = await handelCharge(jsonToFormdata(data));
    dispatch({
      type: "INCUR_EXP_SUCCESS",
      payload: res.expense.voucherNumber,
      handlingLogs: res && res.handlingLogs,
    });
    let { balance } = res;

    dispatch(updateBalance(balance.balance, res));

    if (loginType == "L" || loginType == "N" || loginType == "T") {
      dispatch(setPastExpenses(uid, opFleet._id, loginType));
    } else {
      dispatch(setPastExpenses(uid, opBranch._id, loginType));
    }
  };
};

export const handleEditTempoData = (id: any, data: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    let { uid, opBranch, loginType, opFleet } = getState().user;
    let response = await editChargeExpense(id, jsonToFormdata(data));
    dispatch({ type: EDIT_EXPENSE_RECORD });
    if (response.user == getState().user.uid) {
      dispatch(updateBalance(response.balance, response));
    }

    if (loginType == "L" || loginType == "N" || loginType == "T") {
      dispatch(setPastExpenses(uid, opFleet._id, loginType));
    } else {
      dispatch(setPastExpenses(uid, opBranch._id, loginType));
    }
  };
};

export const handleDeleteTempoData = (id: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    let { uid, opBranch, loginType, opFleet } = getState().user;
    let response = await deleteChargeExpense(id);
    // dispatch({
    //   type: "INCUR_EXP_SUCCESS",
    //   payload: res.expense.voucherNumber,
    // });
    if (response.user == getState().user.uid) {
      dispatch(updateBalance(response.balance, response));
    }
    if (loginType == "L" || loginType == "N" || loginType == "T") {
      dispatch(setPastExpenses(uid, opFleet._id, loginType));
    } else {
      dispatch(setPastExpenses(uid, opBranch._id, loginType));
    }
  };
};

export const createFuel = (data: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      let isValid;
      dispatch({ type: SHOW_LOADER });
      let { uid, opBranch, loginType, opFleet } = getState().user;
      try {
        delete data.fleet;
        isValid = validateExpense(finalExpenseValidation, data);
        if (typeof isValid == "string") throw "Validation Error";
      } catch (error) {
        dispatch({ type: "INCUR_EXP_FAILED", payload: isValid });
      }

      console.log(data, "data1111")
      // dispatch({
      //   type : "REDUCE_AMOUNT",
      //   payload : 
      // })
      if (typeof isValid == "string") throw isValid;
      let response = await createFuelExpense(jsonToFormdata(data));

      // if (response && response.status == 0) {
      //     await dispatch({ type: HIDE_LOADER })
      //     dispatch({ type: 'INCUR_EXP_FAILED' })
      // }
      // else {
      await dispatch({ type: HIDE_LOADER });
      dispatch({
        type: "INCUR_EXP_SUCCESS",
        payload: response.expense.voucherNumber,
      });
      // dispatch(resetExpenseIncur())
      let { balance } = response;
      dispatch(updateBalance(balance?.[0]?.balance, {balance : balance[0]}));
      if (loginType == "F") {
        dispatch(setPastExpenses(uid, opFleet._id, loginType, 1));
      } else {
        dispatch(setPastExpenses(uid, opBranch._id, loginType, 1));
      }
      // }
    } catch (error : any) {
      dispatch({ type: HIDE_LOADER });
      // if (error.message) {
      //     showErrorMessage(error, 'message', 'message')
      // }
      // else {
      //     showMessage(error, failed)
      // }
      if (error && error.message && typeof error.message == "object") {
        let { message: err } = error;
        let temp = Object.keys(err),
          key = temp[temp.length - 1];
        showMessage(err[key], failed);
      } else if (error && error.message) {
        showMessage(error.message, failed);
      } else {
        showMessage(error, failed);
      }
    }
  };
};
export const editFuel = (data: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      let isValid;
      const { uid, opBranch, loginType, opFleet } = getState().user;
      dispatch({ type: SHOW_LOADER });
      try {
        delete data.fleet;
        isValid = validateExpense(finalExpenseValidation, data);
        if (typeof isValid == "string") throw "Validation Error";
      } catch (error) {
        dispatch({ type: "INCUR_EXP_FAILED", payload: isValid });
      }
      if (typeof isValid == "string") throw isValid;
      const response = await editFuelExpense(jsonToFormdata(data));

      if (response && response.status == 0) {
        await dispatch({ type: HIDE_LOADER });
        dispatch({ type: "" });
      } else {
        await dispatch({ type: HIDE_LOADER });
        dispatch({ type: EDIT_EXPENSE_RECORD });
        if (response.user == getState().user.uid) {
          dispatch(updateBalance(response.balance, response));
        }
        if (loginType == "F") {
          dispatch(setPastExpenses(uid, opFleet._id, loginType, 1));
        } else {
          dispatch(setPastExpenses(uid, opBranch._id, loginType, 1));
        }
      }
    } catch (error) {
      dispatch({ type: HIDE_LOADER });
      showHttpError(error);
    }
  };
};

export const deleteFuel = (expenseId: any, userId: any, fuelId: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      let company = getState().user.company._id;
      let response = await deleteFuelExpense(
        expenseId,
        userId,
        fuelId,
        company
      );
      // if (response.status == 0)
      //     dispatch({ type: DELETE_EXPENSE_RECORD_FAILED })
      // else {
      if (response.user == getState().user.uid) {
        dispatch(updateBalance(response.balance, response));
      }
      await dispatch({ type: DELETE_FUEL_EXPENSE });
      const { opBranch, loginType, opFleet } = getState().user;
      if (loginType == "F") {
        dispatch(setPastExpenses(userId, opFleet._id, loginType, 1));
      } else {
        dispatch(setPastExpenses(userId, opBranch._id, loginType, 1));
      }
      // }
    } catch (error) {
      dispatch({ type: DELETE_EXPENSE_RECORD_FAILED });
      console.log("error: ", error);
    }
  };
};
export const onNonCashChange = (what: any, val: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    await dispatch({
      type: NON_CASH_CHANGE,
      payload: { what, val },
    });
    let { sub_val, month, year } = getState().expense.expense;
    if (what == "month" || what == "year") {
      if (sub_val && month && year) {
        dispatch(fetchFixedLimitBalance());
      }
    }
  };
};
export const setPastExpenses = (
  user: any,
  entity: any,
  loginType: any,
  page?: any,
  disallowedVoucherImageOnly?: any,
  pendingVoucher?:any,
  pendingVerification?:any,
  pendingApproval?:any
) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: SET_PAST_EXPENSES,
      payload: await pastExpenseService(
        user,
        entity,
        loginType,
        page || 1,
        disallowedVoucherImageOnly,
        pendingVoucher,
        pendingVerification,
        pendingApproval
      ),
    });
  } catch (err : any) {
    console.log(err);
  }
};
