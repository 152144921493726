const prefix = 'ASSET:'

export const CREATE_ASSET_TYPE_SUCCESS = prefix + 'CREATE_ASSET_TYPE_SUCCESS'
export const CREATE_ASSET_TYPE_FAILED = prefix + 'CREATE_ASSET_TYPE_FAILED'
export const EDIT_ASSET_TYPE_SUCCESS = prefix + 'EDIT_ASSET_TYPE_SUCCESS'
export const EDIT_ASSET_TYPE_FAILED = prefix + 'EDIT_ASSET_TYPE_FAILED'
export const SET_CATEGORY_LIST = prefix + 'SET_CATEGORY_LIST'
export const SET_CATEGORY_LIST_FAIL = prefix + 'SET_CATEGORY_LIST_FAIL'
export const CHANGE_SIMPLE = prefix + 'CHANGE_SIMPLE'
export const SET_ASSET_TYPES = prefix + 'SET_ASSET_TYPES'
export const CREATE_ASSET_BALANCE_SUCCESS = prefix + 'CREATE_ASSET_BALANCE_SUCCESS'
export const CREATE_ASSET_BALANCE_FAILED = prefix + 'CREATE_ASSET_BALANCE_FAILED'
export const SET_ASSET_TYPE_FOR_EDIT = prefix + 'SET_ASSET_TYPE_FOR_EDIT'
export const SET_ASSET_BALANCES = prefix + 'SET_ASSET_BALANCES'
export const RESET = prefix + 'RESET'