import Axios from "axios";
import { Dispatch } from "redux";
import API, { baseURL } from "../axios";
import {
  CONFIRMED_INTRACITY_BOOKING,
  RESET_INTERCITY,
  SET_INTRACITY_BOOKING_RESET_TRACKING,
  SET_INTRACITY_BOOKING_TRACKING,
  SET_INTRACITY,
  SET_INTRACITY_PACKAGES,
  SET_INTRACITY_PICKUP_DROP_LOCATION,
  SET_PARTLOAD,
  RESET_PARTLOAD,
  SET_PARTLOAD_PICKUP_DROP_LOCATION,
  SET_PARTLOAD_PACKAGES,
  SET_PARTLOAD_BOOKING_RESET_TRACKING,
  SET_PARTLOAD_BOOKING_TRACKING,
  CONFIRMED_PARTLOAD_BOOKING,
  SET_PARTLOAD_SHOW_EWAYBILL_LOADER,
  SET_PARTLOAD_HIDE_EWAYBILL_LOADER,
  RESET_PARTLOAD_EWAYBILL_DATA,
  SET_DOCKET_FROM_PARTLOAD_EWAYBILL,
  ADD_PARTLOAD_EWAYBILL,
  SET_MANUAL_PARTLOAD_EWAYBILL,
  SET_PARTLOAD_BOOKING_TYPE,
  SET_PARTLOAD_GROSS,
  SET_PARTLOAD_PAYMENT_MODE,
  SET_FULL_LOAD_VEHICLE_DETAILS,
  SET_OUTSIDE_FORM_FILLED,
  INTRACITY_BOOKING_REQ_ID,
} from "../constants/customerBooking";
import { RootState } from "../store";
import showMessage, { failed, showHttpError, success } from "../utils/message";
import { buildQueryString } from "../services";

const url = baseURL;

const secondsToHms = (d: any) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  // var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " Hour, " : " Hours, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " Minute " : " Minutes ") : "";
  // var sDisplay = s > 0 ? s + (s == 1 ? " Second" : " Seconds") : "";
  return hDisplay + mDisplay;
};

const setDeep = (obj: any, path: any, value: any) => {
  const pList = path.split(".");
  const key = pList.pop();
  const pointer = pList.reduce((accumulator: any, currentValue: any) => {
    if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
    return accumulator[currentValue];
  }, obj);
  pointer[key] = value;
  return obj;
};

// Intracity Code

export const setIntraCity = (what: any, val: any) => {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: SET_INTRACITY,
      payload: {
        what,
        val,
      },
    });
  };
};

export const setIntraCityPickupDropLocation = (
  what: any,
  val: any,
  index: any
) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let { customerBooking } = getState();

    let intraCity =
      typeof customerBooking.intraCity === "object"
        ? customerBooking.intraCity
        : {};
    let pickDropLocation =
      typeof intraCity.pickDropLocation === "object"
        ? intraCity.pickDropLocation
        : [];

    let newData = pickDropLocation.map((x: any, i: any) => {
      if (index === i) {
        return setDeep(x, what, val);
      } else {
        return x;
      }
    });


    await dispatch({
      type: SET_INTRACITY_PICKUP_DROP_LOCATION,
      payload: newData,
    });
  };
};

export const addIntraCityPickupDropLocation = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let { customerBooking } = getState();

    let intraCity =
      typeof customerBooking.intraCity === "object"
        ? customerBooking.intraCity
        : {};
    let pickDropLocation =
      typeof intraCity.pickDropLocation === "object"
        ? intraCity.pickDropLocation
        : [];

    pickDropLocation.push({
      id: pickDropLocation.length,
      place: {
        placeId: null,
        coordinate: {
          latitude: null,
          longitude: null,
        },
        placeValue: "",
        city: "",
        area: "",
        name: "",
        pincode: "",
        formatted_address: "",
      },
      contactPerson: {
        name: "",
        contact: "",
        l1: "",
        l2: "",
        floor: "",
        officeName: "",
        nearby_landmark: "",
        pincode: "",
        city: "",
        isDisabled: "",
        isSelfSelected: false
      },
      isSubmited: false,
    });

    await dispatch({
      type: SET_INTRACITY_PICKUP_DROP_LOCATION,
      payload: pickDropLocation,
    });
  };
};

export const removeIntraCityPickupDropLocation = (index: number) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let { customerBooking } = getState();

    let intraCity =
      typeof customerBooking.intraCity === "object"
        ? customerBooking.intraCity
        : {};
    let pickDropLocation =
      typeof intraCity.pickDropLocation === "object"
        ? intraCity.pickDropLocation
        : [];
    let packages =
      typeof intraCity.packages === "object" ? intraCity.packages : [];

    pickDropLocation.splice(index, 1);

    let newPickDropLocation = pickDropLocation.map((x: any, i: any) => {
      return { ...x, id: i };
    });

    let newPackageData = packages
      .filter((x: any) => x.fromPlaceRef !== index)
      .map((x: any) => {
        let pkg = { ...x };

        if (x.toPlaceRef === index) {
          pkg.toPlaceRef = null;
        }
        return pkg;
      });
    await dispatch({
      type: SET_INTRACITY_PICKUP_DROP_LOCATION,
      payload: newPickDropLocation,
    });

    await dispatch({
      type: SET_INTRACITY_PACKAGES,
      payload: newPackageData,
    });
  };
};

export const setIntraCityPackages = (what: any, val: any, index: any) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let { customerBooking } = getState();

    let intraCity =
      typeof customerBooking.intraCity === "object"
        ? customerBooking.intraCity
        : {};
    let packages =
      typeof intraCity.packages === "object" ? intraCity.packages : [];

    let newData = packages.map((x: any, i: any) => {
      if (index === i) {
        if (typeof what === "string") {
          return { ...x, [what]: val };
        } else {
          return setDeep(x, what, val);
        }
      } else {
        return x;
      }
    });

    await dispatch({
      type: SET_INTRACITY_PACKAGES,
      payload: newData,
    });
  };
};

export const addIntraCityPickupPackages = (setOnIndex: number) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let { customerBooking } = getState();

    let intraCity =
      typeof customerBooking.intraCity === "object"
        ? customerBooking.intraCity
        : {};
    let packages = intraCity.packages ?? [];

    packages.push({
      id: packages.length,
      materialType: null,
      qty: 0,
      codAmount: 0,
      orderNumber: "",
      // dimension: {
      //   l: 0,
      //   b: 0,
      //   h: 0,
      // },
      // weight: 0,
      // stack: false,
      // haz: false,
      // frag: false,
      fromPlaceRef: setOnIndex,
      toPlaceRef: null,
    });

    await dispatch({
      type: SET_INTRACITY_PACKAGES,
      payload: packages,
    });
  };
};

export const removeIntraCityPickupDropPackages = (index: number) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let { customerBooking } = getState();

    let intraCity =
      typeof customerBooking.intraCity === "object"
        ? customerBooking.intraCity
        : {};
    let packages =
      typeof intraCity.packages === "object" ? intraCity.packages : [];

    packages.splice(index, 1);

    let newData = packages.map((x: any, i: any) => {
      return { ...x, id: i };
    });
    await dispatch({
      type: SET_INTRACITY_PACKAGES,
      payload: newData,
    });
  };
};

export const createCustomerIntraCityBookingRequest = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      let { customerBooking } = getState();
      let intraCity =
        typeof customerBooking.intraCity === "object"
          ? customerBooking.intraCity
          : {};

      let pickDropLocation =
        typeof intraCity.pickDropLocation === "object"
          ? intraCity.pickDropLocation
          : [];
      let packages =
        typeof intraCity.packages === "object" ? intraCity.packages : [];
      let assetType = intraCity.assetSubType?.assetType?._id;
      let assetSubType = intraCity.assetSubType._id;
      let city = pickDropLocation[0]?.place?.city;
      let gross = (intraCity.gross * +intraCity.totalDistanceMeter).toFixed(0);
      let paymentMode = intraCity.paymentMode;
      let paymentType = "cash";
      let intraCityRateType = intraCity.intraCityRateType;
      let points = intraCity.googleMapPolyLine?.points;

      dispatch({
        type: "START_LOADER",
      });

      let payload = processPayload(
        pickDropLocation,
        packages,
        city,
        gross,
        assetType,
        assetSubType
      );
      if (!payload) {
        return showHttpError(`Kindly Fill Up all details !`);
      }
      let response = await Axios({
        method: "post",
        url: url + "/customerBooking/createCustomerBookingRequestReq",
        data: {...payload,paymentMode,intraCityRateType,paymentType,points},
      });
      // showMessage("Booking Request Created", success);
      if(response?.status === 200){
        dispatch({
          type: CONFIRMED_INTRACITY_BOOKING,
          payload: {
            what: "booked",
            val: true,
          },
        })
      }
      if(response?.status !== 200){
        // alert("I am Doing response Well !")
        dispatch({
          type: CONFIRMED_INTRACITY_BOOKING,
          payload: {
            what: "booked",
            val: false,
          },
        })
      }
      dispatch({
        type: INTRACITY_BOOKING_REQ_ID,
        payload: {
          what: "intraCityBookingReqId",
          val: response?.data?.response?._id || "",
        },
      })
      // if(response?.status !== 200){
        // alert("I am Doing response Well !")
      // }
      // await dispatch({
      //   type: SET_INTRACITY_PICKUP_DROP_LOCATION,
      //   payload: [],
      // });

      // await dispatch({
      //   type: SET_INTRACITY_PACKAGES,
      //   payload: [],
      // });
      return { data: response?.data?.response, status: response?.status === 200 };
    } catch (err : any) {
      showHttpError(err);
      dispatch({
        type: "END_LOADER",
      });
    }
  };
};

export const getIntraCityPrice = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      let { customerBooking } = getState();
      let intraCity =
        typeof customerBooking.intraCity === "object"
          ? customerBooking.intraCity
          : {};

      let pickDropLocation =
        typeof intraCity.pickDropLocation === "object"
          ? intraCity.pickDropLocation
          : [];
      let packages =
        typeof intraCity.packages === "object" ? intraCity.packages : [];
      // let assetType = intraCity.assetType;
      let city = intraCity.city;
      let gross = intraCity.gross;

      dispatch({
        type: "START_LOADER",
      });

      let payload = processPayload(
        pickDropLocation,
        packages,
        city,
        gross
      );
      if (!payload) {
        return showHttpError(`Kindly Fill Up all details !`);
      }
      let response = await Axios({
        method: "post",
        url: url + `/customerBooking/getAvailableServicesIntraCityPrice`,
        data: { ...payload, type: "EXPRESS" },
      });

      console.log("response", response);

      if (response?.data?.response?.length > 0) {
        await dispatch({
          type: SET_INTRACITY,
          payload: {
            what: "gross",
            val: response?.data?.response[0]?.price,
          },
        });
        await dispatch({
          type: SET_INTRACITY,
          payload: {
            what: "totalDistanceMeter",
            val: response?.data?.response[0]?.totalDistanceMeter / 1000,
          },
        });
        await dispatch({
          type: SET_INTRACITY,
          payload: {
            what: "totalDuration",
            val: secondsToHms(response?.data?.response[0]?.totalDuration),
          },
        });
        console.log("res", response);
        showMessage("Cost Fetched", success);
      } else {
        showMessage("No Intra City Rate Available", failed);
      }
    } catch (err : any) {
      console.log(err);
      showHttpError(err);
      dispatch({
        type: "END_LOADER",
      });
      await dispatch({
        type: SET_INTRACITY,
        payload: {
          what: "gross",
          val: 0,
        },
      });
    }
  };
};

export const actualgetIntraCityPrice = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      let { customerBooking } = getState();
      let intraCity =
        typeof customerBooking.intraCity === "object"
          ? customerBooking.intraCity
          : {};

      let pickDropLocation =
        typeof intraCity.pickDropLocation === "object"
          ? intraCity.pickDropLocation
          : [];
      let packages =
        typeof intraCity.packages === "object" ? intraCity.packages : [];
      // let assetType = intraCity.assetType.value;
      let city = pickDropLocation[0]?.place?.city;
      let gross = intraCity.gross;

      dispatch({
        type: "START_LOADER",
      });

      let payload = processPayload(
        pickDropLocation,
        packages,
        city,
        gross
      );
      if (!payload) {
        return showHttpError(`Kindly Fill Up all details !`);
      }
      let response = await Axios({
        method: "post",
        url: url + `/customerBooking/getIntraCityPrice`,
        data: { ...payload, type: "EXPRESS" },
      });

      console.log("response 1212", response?.data?.response?.price);

      if (response?.data?.response) {
        await dispatch({
          type: SET_INTRACITY,
          payload: {
            what: "vehicleList",
            val: response?.data?.response?.price,
          },
        });
        await dispatch({
          type: SET_INTRACITY,
          payload: {
            what: "totalDistanceMeter",
            val: (response?.data?.response?.distance / 1000).toFixed(2),
          },
        });
        await dispatch({
          type: SET_INTRACITY,
          payload: {
            what: "totalDuration",
            val: secondsToHms(response?.data?.response?.duration),
          },
        });
        await dispatch({
          type: SET_INTRACITY,
          payload: {
            what: "showFleets",
            val: true,
          },
        });
        await dispatch({
          type: SET_INTRACITY,
          payload: {
            what: "getPolyLine",
            val: true,
          },
        });
        console.log("res", response);
        showMessage("Cost Fetched", success);
      } else {
        showMessage("No Intra City Rate Available", failed);
      }
    } catch (err : any) {
      console.log(err);
      showHttpError(err);
      showMessage(`Intra City Rate Error :${err}`, failed);
      dispatch({
        type: "END_LOADER",
      });
      await dispatch({
        type: SET_INTRACITY,
        payload: {
          what: "gross",
          val: 0,
        },
      });
    }
  };
};

const processPayload = (
  pickupDropLocation: any,
  packages: any,
  city: any,
  gross: any,
  assetType? : any,
  assetSubType?:any
) => {
  let errorMsg = "";
  try {
    let newData = packages.map((x: any) => {
      if (x.fromPlaceRef === null) {
        errorMsg = `${x.id}, Order Number: ${x.orderNumber} Has Not Pickup Location`;
      }

      if (x.toPlaceRef === null) {
        errorMsg = `Drop Location Required For Package No: ${x.id}, Order Number: ${x.orderNumber}`;
      }

      let from = pickupDropLocation[x.fromPlaceRef];
      let to = pickupDropLocation[x.toPlaceRef];

      return {
        id: x.id,
        weight : 2,
        materialType: x?.materialType?.value,
        qty: 1,
        codAmount: x.codAmount,
        customerPkgIdentifier: x.orderNumber,
        fromPlace: {
          placeId: from?.place?.placeId,
          coordinate: {
            latitude: from?.place?.coordinate.latitude,
            longitude: from?.place?.coordinate.longitude,
          },
          placeValue: from?.place?.placeValue,
          city: from?.place?.city,
          area: from?.place?.area,
          name: from?.place?.name,
          pincode: from?.place?.pincode,
          formatted_address: from?.place?.formatted_address,
          state: "Gujarat",
        },
        toPlace: {
          placeId: to?.place?.placeId,
          coordinate: {
            latitude: to?.place?.coordinate.latitude,
            longitude: to?.place?.coordinate.longitude,
          },
          placeValue: to?.place?.placeValue,
          city: to?.place?.city,
          area: to?.place?.area,
          name: to?.place?.name,
          pincode: to?.place?.pincode,
          formatted_address: to?.place?.formatted_address,
          state: "Gujarat",
        },
        sender: {
          name: from?.contactPerson?.name,
          contact: from?.contactPerson?.contact,
          // floor: from?.contactPerson?.floor,
          // officeName: from?.contactPerson?.l1,
          // officeName: from?.contactPerson?.l2,
          // officeName: from?.contactPerson?.officeName,
          // nearby_landmark: from?.contactPerson?.nearby_landmark,
          // pincode: from?.contactPerson?.pincode,
          // city: from?.contactPerson?.city
        },
        receiver: {
          name: to?.contactPerson?.name,
          contact: to?.contactPerson?.contact,
          // floor: to?.contactPerson?.floor,
          // l1: to?.contactPerson?.l1,
          // l2: to?.contactPerson?.l2,
          // officeName: to?.contactPerson?.officeName,
          // nearby_landmark: to?.contactPerson?.nearby_landmark,
          // pincode: to?.contactPerson?.pincode,
          // city: to?.contactPerson?.city
        },
        sAddress: {
          pincode: from?.contactPerson?.pincode,
          city: from?.contactPerson?.city,
          placeId: from?.place?.placeId,
          l1: from?.contactPerson?.l1,
          l2: from?.contactPerson?.l2,
          officeName: from?.contactPerson?.officeName,
          // floor: from?.contactPerson?.floor,
          nearby_landmark: from?.contactPerson?.nearby_landmark,
        },
        rAddress: {
          pincode: to?.contactPerson?.pincode,
          city: to?.contactPerson?.city,
          placeId: to?.place?.placeId,
          l1: to?.contactPerson?.l1,
          l2: to?.contactPerson?.l2,
          officeName: to?.contactPerson?.officeName,
          // floor: to?.contactPerson?.floor,
          nearby_landmark: to?.contactPerson?.nearby_landmark,
        },
        fromPlaceRemarks: x?.fromPlaceRemarks,
        toPlaceRemarks: x?.toPlaceRemarks
      };
    });

    if (errorMsg) {
      showMessage(errorMsg, failed);
      return false;
    }

    return {
      type: "IntraCity",
      packages: newData,
      places: newData.map((x: any) => {
        return { fromPlace: x.fromPlace.placeId, toPlace: x.toPlace.placeId };
      }),
      city: city,
      gross: gross,
      goods: 20,
      assetType : assetType,
      assetSubType
    };
  } catch (err : any) {
    showHttpError(err);
  }
};

export const setCustomerBookingTracking = (data:any) => {
    
  return (dispatch:any)=>{
      dispatch({
          type:SET_INTRACITY_BOOKING_TRACKING,
          payload:data
      })

  }
}

export const ResetCustomerBookingTracking = () => {
    
  return (dispatch:any)=>{
    dispatch({
      type:SET_INTRACITY_BOOKING_RESET_TRACKING
    })
    
  }
}   

export const resetAllDataIntracityForm = () => {
  return (dispatch:any)=>{
    dispatch({
      type: RESET_INTERCITY,
      payload: [],
    });
    
    dispatch({
      type: SET_INTRACITY_PICKUP_DROP_LOCATION,
      payload: [],
    });

    dispatch({
      type: SET_INTRACITY_PACKAGES,
      payload: null,
    });

  }
}

// Partload Code

export const setPartLoad = (what: any, val: any) => {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: SET_PARTLOAD,
      payload: {
        what,
        val,
      },
    });
  };
};

export const setPartLoadPickupDropLocation = (
  what: any,
  val: any,
  index: any
) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let { customerBooking } = getState();

    let partLoad =
      typeof customerBooking.partLoad === "object"
        ? customerBooking.partLoad
        : {};
    let pickDropLocation =
      typeof partLoad.pickDropLocation === "object"
        ? partLoad.pickDropLocation
        : [];
    console.log("in---", pickDropLocation);

    let newData = pickDropLocation.map((x: any, i: any) => {
      if (index === i) {
        console.log(setDeep(x, what, val));
        return setDeep(x, what, val);
      } else {
        return x;
      }
    });

    console.log("in---", newData);

    await dispatch({
      type: SET_PARTLOAD_PICKUP_DROP_LOCATION,
      payload: newData,
    });
  };
};

export const addPartLoadPickupDropLocation = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let { customerBooking } = getState();

    let partLoad =
      typeof customerBooking.partLoad === "object"
        ? customerBooking.partLoad
        : {};
    let pickDropLocation =
      typeof partLoad.pickDropLocation === "object"
        ? partLoad.pickDropLocation
        : [];

    pickDropLocation.push({
      id: pickDropLocation.length,
      place: {
        placeId: null,
        coordinate: {
          latitude: null,
          longitude: null,
        },
        placeValue: "",
        city: "",
        area: "",
        name: "",
        pincode: "",
        formatted_address: "",
      },
      contactPerson: {
        name: "",
        contact: "",
        floor: "",
        l1: "",
        l2: "",
        officeName: "",
        nearby_landmark: "",
        pincode: "",
        city: "",
        isDisabled: "",
        isSelfSelected: false
      },
      isSubmited: false,
    });

    await dispatch({
      type: SET_PARTLOAD_PICKUP_DROP_LOCATION,
      payload: pickDropLocation,
    });
  };
};

export const removePartLoadPickupDropLocation = (index: number) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let { customerBooking } = getState();

    let partLoad =
      typeof customerBooking.partLoad === "object"
        ? customerBooking.partLoad
        : {};
    let pickDropLocation =
      typeof partLoad.pickDropLocation === "object"
        ? partLoad.pickDropLocation
        : [];
    let packages =
      typeof partLoad.packages === "object" ? partLoad.packages : [];

    pickDropLocation.splice(index, 1);

    let newPickDropLocation = pickDropLocation.map((x: any, i: any) => {
      return { ...x, id: i };
    });

    let newPackageData = packages
      .filter((x: any) => x.fromPlaceRef !== index)
      .map((x: any) => {
        let pkg = { ...x };

        if (x.toPlaceRef === index) {
          pkg.toPlaceRef = null;
        }
        return pkg;
      });
    await dispatch({
      type: SET_PARTLOAD_PICKUP_DROP_LOCATION,
      payload: newPickDropLocation,
    });

    console.log("going data from here", newPackageData);
    await dispatch({
      type: SET_PARTLOAD_PACKAGES,
      payload: newPackageData,
    });
  };
};

export const setPartLoadPackages = (what: any, val: any, index: any) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let { customerBooking } = getState();

    let partLoad =
      typeof customerBooking.partLoad === "object"
        ? customerBooking.partLoad
        : {};
    let packages =
      typeof partLoad.packages === "object" ? partLoad.packages : [];

    let newData = packages.map((x: any, i: any) => {
      if (index === i) {
        if (typeof what === "string") {
          return { ...x, [what]: val };
        } else {
          return setDeep(x, what, val);
        }
      } else {
        return x;
      }
    });

    await dispatch({
      type: SET_PARTLOAD_PACKAGES,
      payload: newData,
    });
  };
};

export const addPartLoadPickupPackages = (setOnIndex: number) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let { customerBooking } = getState();

    let partLoad =
      typeof customerBooking.partLoad === "object"
        ? customerBooking.partLoad
        : {};
    let packages =
      typeof partLoad.packages === "object" ? partLoad.packages : [];

    packages.push({
      id: packages.length,
      materialType: null,
      qty: 0,
      codAmount: 0,
      orderNumber: "",
      // dimension: {
      //   l: 0,
      //   b: 0,
      //   h: 0,
      // },
      // weight: 0,
      // stack: false,
      // haz: false,
      // frag: false,
      fromPlaceRef: setOnIndex,
      toPlaceRef: null,
    });

    await dispatch({
      type: SET_PARTLOAD_PACKAGES,
      payload: packages,
    });
  };
};

export const removePartLoadPickupDropPackages = (index: number) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    let { customerBooking } = getState();

    let partLoad =
      typeof customerBooking.partLoad === "object"
        ? customerBooking.partLoad
        : {};
    let packages =
      typeof partLoad.packages === "object" ? partLoad.packages : [];

    packages.splice(index, 1);

    let newData = packages.map((x: any, i: any) => {
      return { ...x, id: i };
    });
    await dispatch({
      type: SET_PARTLOAD_PACKAGES,
      payload: newData,
    });
  };
};

export const createCustomerPartLoadBookingRequest = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      let { customerBooking } = getState();
      let partLoad =
        typeof customerBooking.partLoad === "object"
          ? customerBooking.partLoad
          : {};

      let pickDropLocation =
        typeof partLoad.pickDropLocation === "object"
          ? partLoad.pickDropLocation
          : [];
      let packages =
        typeof partLoad.packages === "object" ? partLoad.packages : [];
      let assetType = partLoad.assetType.value;
      let city = pickDropLocation[0]?.place?.city;
      let gross = (partLoad.gross * +partLoad.totalDistanceMeter).toFixed(0);
      let paymentMode = partLoad.paymentMode;
      let paymentType = "cash";
      let intraCityRateType = partLoad.intraCityRateType;
      let points = partLoad.googleMapPolyLine?.points;

      dispatch({
        type: "START_LOADER",
      });

      let payload = processPartLoadPayload(
        pickDropLocation,
        packages,
        city,
        gross,
        assetType
      );
      if (!payload) {
        return showHttpError(`Kindly Fill Up all details !`);
      }
      let response = await Axios({
        method: "post",
        url: url + "/customerBooking/createCustomerBookingRequestReq",
        data: {...payload,paymentMode,intraCityRateType,paymentType,points},
      });

      console.log("res", response);
      // showMessage("Booking Request Created", success);
      if(response?.status === 200){
        dispatch({
          type: CONFIRMED_PARTLOAD_BOOKING,
          payload: {
            what: "booked",
            val: true,
          },
        })
      }
      if(response?.status !== 200){
        // alert("I am Doing response Well !")
        dispatch({
          type: CONFIRMED_PARTLOAD_BOOKING,
          payload: {
            what: "booked",
            val: false,
          },
        })
      }
      // await dispatch({
      //   type: SET_INTRACITY_PICKUP_DROP_LOCATION,
      //   payload: [],
      // });

      // await dispatch({
      //   type: SET_INTRACITY_PACKAGES,
      //   payload: [],
      // });
    } catch (err : any) {
      console.log(err);
      showHttpError(err);
      dispatch({
        type: "END_LOADER",
      });
    }
  };
};


type actualBookingType = "PartLoad" | "FullLoad";
export const actualgetPartLoadPrice = (from : any , extraFields?: any, actualBookingType?: actualBookingType ) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      console.log("Booking Typeee", actualBookingType);
      let { customerBooking , customerLoginReducer , user } = getState();
      console.log("Customer Booking State:", customerBooking);
      let partLoad =
        typeof customerBooking.partLoad === "object"
          ? customerBooking.partLoad
          : {};

      let pickDropLocation =
        typeof partLoad.pickDropLocation === "object"
          ? partLoad.pickDropLocation
          : [];
      let packages =
      typeof partLoad.packages === "object" ? partLoad.packages : [];
      let city = pickDropLocation[0]?.place?.city;
      let purpose = typeof partLoad?.bookingType === "object" ? partLoad?.bookingType : {};
      let eWayBillDetails : any = partLoad?.eWayBillData;
      let isMultipleEWayBill : any = partLoad?.isMultipleEWayBill;
      let payMentModeVal : any = partLoad?.paymentMode?.value;
      let grossVal : any = partLoad?.gross;
      dispatch({
        type: "START_LOADER",
      });

      let payload = processPartLoadPayload(
        pickDropLocation,
        packages,
        city,
        "",
        "",
        purpose,
        eWayBillDetails,
        isMultipleEWayBill,
        payMentModeVal,
        grossVal
      );
      console.log("payloadd:", payload);
      console.log(`partLoad from Boking 3.0` , payload)
      if (!payload) {
        return showHttpError(`Kindly Fill Up all details !`);
      }
      var endPoint : any;
      var additionalPayload : any;
      var vehiclePayload : any;
      if(from === "CustomerSide") {
        endPoint = `/customerBooking/createFullPartLoadReq`;
        additionalPayload = {
          bookedBy : customerLoginReducer?._id,
        }
        vehiclePayload = {
          assetType : extraFields?.assetType,
          assetSubType: extraFields?.assetSubType,
          bodyType: extraFields?.bodyType,
          vehicleNos: extraFields?.vehicleNos
        }
      }
      if(from === "BranchSide"){
        console.log("Branch side called")
        endPoint = `/booking/createFullPartLoadReq`;
        additionalPayload = {
          bookedBy : user?.uid , 
          entity : user.opBranch._id,
        }
        vehiclePayload = {
          assetType : extraFields?.assetType,
          assetSubType: extraFields?.assetSubType,
          bodyType: extraFields?.bodyType,
          vehicleNos: extraFields?.vehicleNos,
          advanceAmount: extraFields?.advanceAmount,
          paymentMode: extraFields?.paymentMode || partLoad?.paymentMode?.value,
        }
      }
      console.log(`payload, ...additionalPayload , ...vehiclePayload, type` , payload, additionalPayload, vehiclePayload, actualBookingType)
      let response = await API.post(url + endPoint,
        { ...payload, ...additionalPayload , ...vehiclePayload, type: actualBookingType });

      console.log("response 1212", response?.data);

      if (response?.data?.price) {
        await dispatch({
          type: SET_PARTLOAD,
          payload: {
            what: "vehicleList",
            val: response?.data?.price?.price,
          },
        });
        await dispatch({
          type: SET_PARTLOAD,
          payload: {
            what: "totalDistanceMeter",
            val: (response?.data?.price?.distance / 1000).toFixed(2),
          },
        });
        await dispatch({
          type: SET_PARTLOAD,
          payload: {
            what: "totalDuration",
            val: secondsToHms(response?.data?.price?.duration),
          },
        });
        await dispatch({
          type: SET_PARTLOAD,
          payload: {
            what: "showFleets",
            val: true,
          },
        });
        await dispatch({
          type: SET_PARTLOAD,
          payload: {
            what: "getPolyLine",
            val: true,
          },
        });
        console.log("res", response);
        showMessage("Cost Fetched", success);
      } else {
        // showMessage("No Part Load Rate Available", failed);
      }
      return {isDocketBooked : true, response: response?.data?.response};
    } catch (err : any) {
      console.log(err);
      showHttpError(err);
      showMessage(`Part Load Rate Error :${err}`, failed);
      dispatch({
        type: "END_LOADER",
      });
      await dispatch({
        type: SET_PARTLOAD,
        payload: {
          what: "gross",
          val: 0,
        },
      });
    }
  };
};

const processPartLoadPayload = (
  pickupDropLocation: any,
  packages: any,
  city: any,
  gross: any,
  assetType? : any,
  purpose? : any,
  eWayBillDetails? : any,
  isMultipleEWayBill? : any,
  payMentModeVal? : any,
  grossVal? : any,
) => {
  let errorMsg = "";
  try {
    let newData = packages.map((x: any) => {
      if (x.fromPlaceRef === null) {
        errorMsg = `${x.id}, Order Number: ${x.orderNumber} Has Not Pickup Location`;
      }
      console.log(gross)
      if (x.toPlaceRef === null) {
        errorMsg = `Drop Location Required For Package No: ${x.id}, Order Number: ${x.orderNumber}`;
      }

      if (x?.qty <= 0) {
        errorMsg = `Quantity must be larger than or equal to 1`;
      }

      if (!x?.weight) {
        errorMsg = `Weight must be larger than or equal to 1`;
      }

      if (x?.weight <= 0) {
        errorMsg = `Weight must be larger than or equal to 1`;
      }

      let from = pickupDropLocation[x.fromPlaceRef];
      let to = pickupDropLocation[x.toPlaceRef];
      console.log(`eWayBillDetails 20` , eWayBillDetails , `eWayBillDetails 20` , x)
      return {
        id: x.id,
        materialType: x?.materialType?.value,
        qty: x?.qty,
        weight: x?.weight,
        codAmount: x.codAmount,
        // customerPkgIdentifier: '30',
        fromPlace: {
          placeId: from?.place?.placeId,
          coordinate: {
            latitude: from?.place?.coordinate.latitude,
            longitude: from?.place?.coordinate.longitude,
          },
          placeValue: from?.place?.placeValue,
          city: from?.place?.city,
          area: from?.place?.area,
          name: from?.place?.name,
          pincode: from?.place?.pincode,
          formatted_address: from?.place?.formatted_address,
          state: "Gujarat",
        },
        toPlace: {
          placeId: to?.place?.placeId,
          coordinate: {
            latitude: to?.place?.coordinate.latitude,
            longitude: to?.place?.coordinate.longitude,
          },
          placeValue: to?.place?.placeValue,
          city: to?.place?.city,
          area: to?.place?.area,
          name: to?.place?.name,
          pincode: to?.place?.pincode,
          formatted_address: to?.place?.formatted_address,
          state: "Gujarat",
        },
        sender: {
          name: from?.contactPerson?.name,
          contact: from?.contactPerson?.contact,
          senderGst : from?.contactPerson?.gstin,
          // floor: from?.contactPerson?.floor,
          // l1: from?.contactPerson?.l1,
          // l2: from?.contactPerson?.l2,
          // officeName: from?.contactPerson?.officeName,
          // nearby_landmark: from?.contactPerson?.nearby_landmark,
          // pincode: from?.contactPerson?.pincode,
          // city: from?.contactPerson?.city
        },
        receiver: {
          name: to?.contactPerson?.name,
          contact: to?.contactPerson?.contact,
          receiverGst : to?.contactPerson?.gstin,
          // floor: to?.contactPerson?.floor,
          // l1: to?.contactPerson?.l1,
          // l2: to?.contactPerson?.l2,
          // officeName: to?.contactPerson?.officeName,
          // nearby_landmark: to?.contactPerson?.nearby_landmark,
          // pincode: to?.contactPerson?.pincode,
          // city: to?.contactPerson?.city
        },
        sAddress: {
          pincode: from?.contactPerson?.pincode,
          city: from?.contactPerson?.city,
          placeId: from?.place?.placeId,
          l1: from?.contactPerson?.l1,
          l2: from?.contactPerson?.l2,
          officeName: from?.contactPerson?.officeName,
          // floor: from?.contactPerson?.floor,
          nearby_landmark: from?.contactPerson?.nearby_landmark,
        },
        rAddress: {
          pincode: to?.contactPerson?.pincode,
          city: to?.contactPerson?.city,
          placeId: to?.place?.placeId,
          l1: to?.contactPerson?.l1,
          l2: to?.contactPerson?.l2,
          officeName: to?.contactPerson?.officeName,
          // floor: to?.contactPerson?.floor,
          nearby_landmark: to?.contactPerson?.nearby_landmark,
        },
      };
    });

    if (errorMsg) {
      showMessage(errorMsg, failed);
      return false;
    }

    if(purpose?.value === "P"){
      return {
        type: "IntraCity",
        packages: newData,
        places: newData.map((x: any) => {
          return { fromPlace: x.fromPlace.placeId, toPlace: x.toPlace.placeId };
        }),
        city: city,
        assetType : assetType,
        purpose : purpose?.value,
        goods: eWayBillDetails[0]?.partyGoodsVal,
        paymentMode : payMentModeVal, 
        gross : grossVal,
      };
    }
    if(purpose?.value === "C"){
      return {
        type: "IntraCity",
        packages: newData,
        places: newData.map((x: any) => {
          return { fromPlace: x.fromPlace.placeId, toPlace: x.toPlace.placeId };
        }),
        city: city,
        assetType : assetType,
        purpose : purpose?.value,
        eWayBillInfo : [{
          goods: isMultipleEWayBill ? eWayBillDetails?.map((x : any) => x?.partyGoodsVal) : eWayBillDetails[0]?.partyGoodsVal,
          eWayBill : isMultipleEWayBill ? eWayBillDetails?.map((x : any) => x?.eWbillNumber) : eWayBillDetails[0]?.eWbillNumber,
          billNo : isMultipleEWayBill ? eWayBillDetails?.map((x : any) => x?.partyBillNumber) : eWayBillDetails[0]?.partyBillNumber,
        }],
        goods: isMultipleEWayBill ? eWayBillDetails?.map((x : any) => x?.partyGoodsVal) : eWayBillDetails[0]?.partyGoodsVal,
        paymentMode : payMentModeVal, 
        gross : grossVal,
      };
    }
  } catch (err : any) {
    showHttpError(err);
  }
};

export const setCustomerBookingPartLoadTracking = (data:any) => {
    
  return (dispatch:any)=>{
      dispatch({
          type:SET_PARTLOAD_BOOKING_TRACKING,
          payload:data
      })

  }
}

export const setFullLoadVehicleDetails = (data:any) => {
    console.log(`setFullLoadVehicleDetails data 1 ` , data);
    
  return (dispatch:any)=>{
      dispatch({
          type:SET_FULL_LOAD_VEHICLE_DETAILS,
          payload:data
      })

  }
}

export const ResetCustomerBookingPartLoadTracking = () => {
    
  return (dispatch:any)=>{
    dispatch({
      type:SET_PARTLOAD_BOOKING_RESET_TRACKING
    })
    
  }
}   

export const resetAllDataPartLoadForm = () => {
  return (dispatch:any)=>{
    dispatch({
      type: RESET_PARTLOAD,
      payload: [],
    });
    
    dispatch({
      type: SET_PARTLOAD_PICKUP_DROP_LOCATION,
      payload: [],
    });

    dispatch({
      type: SET_PARTLOAD_PACKAGES,
      payload: [],
    });
    dispatch({
      type: SET_PARTLOAD_GROSS,
      payload: [],
    });
    dispatch({
      type: SET_PARTLOAD_PAYMENT_MODE,
      payload: [],
    });

    dispatch({
      type: RESET_PARTLOAD_EWAYBILL_DATA
    });

    dispatch(setFullLoadVehicleDetails({ "vehicleModel": {}}));
    dispatch(setFullLoadVehicleDetails({ "assetType": {}}));
    dispatch(setFullLoadVehicleDetails({ "assetSubType": {}}));
    dispatch(setFullLoadVehicleDetails({ "truckNos": {}}));
  
  }
}

export const setPartloadEWBdetails = (val: any , from : any) => {
  return async (dispatch: Dispatch , getState: () => RootState) => {
    let { customerBooking } = getState();

    let partLoad =
      typeof customerBooking.partLoad === "object"
        ? customerBooking.partLoad
        : {};
    let pickDropLocation =
      typeof partLoad.pickDropLocation === "object"
        ? partLoad.pickDropLocation
        : [];
    let eWayBillData =
      typeof partLoad.eWayBillData === "object"
        ? partLoad.eWayBillData
        : [];
    console.log("in--- 1", pickDropLocation , `eWayBillData` , eWayBillData , `isMultipleEWayBill` , partLoad?.isMultipleEWayBill);

    console.log(`641665988645 Entry First` , val?.length , from)
    try {
      if (val.length == 12) {
      await dispatch({ type: SET_PARTLOAD_SHOW_EWAYBILL_LOADER });
      let responseData : any;  
        if(from === "Branch Booking"){
          responseData = await Axios({
            method: "post",
            url: url + `/ewaybill/india/details`,
            data : {eWayBillNumber: val}
          });
        }
        else if(from === "Customer Booking"){
          responseData = await Axios({
            method: "get",
            url: url + `/customerBooking/ewaybill/india/details?${buildQueryString({eWayBillNumber: val})}`,
          });
        }
      let response : any = responseData?.data?.response?.message
        console.log(`641665988645 Entry First` , response , `responseData` , responseData , customerBooking?.partLoad?.pickDropLocation)

        const newEwayBillData = [
          {
            eWbillNumber : response?.eway_bill_number,
            partyBillNumber : response?.document_number,
            partyGoodsVal : response?.total_invoice_value,
            isDataFromApiCall : true,
            
          }
        ];

        let newData = pickDropLocation.map((x: any, i: any) => {
          if (i === 0) {
            return setDeep(x, "contactPerson.name", response?.sender?.name) , setDeep(x, "contactPerson.contact", response?.sender?.contact) , setDeep(x, "contactPerson.gstin", response?.gstin_of_consignor);
          } 
          if (i === 1) {
            return setDeep(x, "contactPerson.name", response?.reciever?.name) , setDeep(x, "contactPerson.contact", response?.reciever?.contact) , setDeep(x, "contactPerson.gstin", response?.gstin_of_consignee);
          } 
          else {
            return x;
          }
        });
        console.log(`newData 6.2.0` , newData , `newEwayBillData` , newEwayBillData)
        if (response == "Could not retrieve data") {
          throw "ewaybilerror";
        } else {
          await dispatch({
            type: SET_DOCKET_FROM_PARTLOAD_EWAYBILL,
            payload: {pickDropLocation : newData , eWayBillData : newEwayBillData},
          });
        }
        await dispatch({ type: SET_PARTLOAD_HIDE_EWAYBILL_LOADER });
        if (response == "Could not retrieve data")
          showMessage("Please Check And Try Again", failed);
      }else{
        showHttpError("Check the E-Way Bill No.")
      }
    } catch (error) {
      await dispatch({ type: SET_PARTLOAD_HIDE_EWAYBILL_LOADER });
      if (error == "ewaybilerror") {
        showMessage("Could not fetch details", failed);
      }
      console.log("error: ", error);
      return "Server Error";
    }
  };
};

export const setPartloadManualEWBdetails = (
  what: any,
  val: any,
  index: any
) => {
  console.log(`641665988645 Entry` , val?.length)
  return async (dispatch: Dispatch , getState: () => RootState) => {
    let { customerBooking } = getState();

    let partLoad =
      typeof customerBooking.partLoad === "object"
        ? customerBooking.partLoad
        : {};
    let eWayBillData =
      typeof partLoad.eWayBillData === "object"
        ? partLoad.eWayBillData
        : [];
    console.log("in--- 1",`eWayBillData` , eWayBillData , `isMultipleEWayBill` , partLoad?.isMultipleEWayBill);

    try {
        const newEwayBillData = eWayBillData?.map((x : any , i : any) => {
          if(index === i){
            console.log(`eWbillNumber` , x , `what` , what , `val` , val)
            return setDeep(x, what, val);
          }
          else{
            return x;
          }
        });
        console.log(`641665988645 Entry First` , newEwayBillData)
        await dispatch({
          type: SET_MANUAL_PARTLOAD_EWAYBILL,
          payload : newEwayBillData
        });
    } catch (error) {
      await dispatch({
        type: RESET_PARTLOAD_EWAYBILL_DATA
      });
      console.log("error: ", error);
    }
  };
};

export const setOutSideFormFilled = (type : any) => {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: SET_OUTSIDE_FORM_FILLED,
      payload: type,
    });
  };
}

export const setBookingType = (type : any) => {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: SET_PARTLOAD_BOOKING_TYPE,
      payload: type,
    });
  };
}

export const setPaymentModeFromBranch = (type : any) => {
  console.log(`dfrgsgsefv payment 01` , type)
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: SET_PARTLOAD_PAYMENT_MODE,
      payload: type,
    });
  };
}

export const setGross = (type : any) => {
  console.log(`dfrgsgsefv gross 01` , type)
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: SET_PARTLOAD_GROSS,
      payload: type,
    });
  };
}

export const addPartLoadEwayBill = (setOnIndex: number) => {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: ADD_PARTLOAD_EWAYBILL,
      payload: [{
        id : setOnIndex,
        eWbillNumber : "",
        partyBillNumber : "",
        partyGoodsVal : 0,
        isDataFromApiCall : false,
    }],
    });
  };
};