const prefix = 'FRANCHISE:'

export const SET_COMPANIES = prefix + 'SET_COMPANIES'

export const SET_BRANCHES = prefix + 'SET_BRANCHES'

export const CHANGE_MANAGE_SIMPLE = prefix + 'CHANGE_MANAGE_SIMPLE'

export const CHANGE_RATE_SLAB = prefix + 'CHANGE_RATE_SLAB'

export const ADD_RATE_SLAB = prefix + 'ADD_RATE_SLAB'

export const DEL_RATE_SLAB = prefix + 'DEL_RATE_SLAB'

export const FRANCHISE_ADDED_SUCCESS = prefix + 'FRANCHISE_ADDED_SUCCESS'

export const SET_FRANCHISES = prefix + 'SET_FRANCHISES'

export const REMOVE_FRANCHISEE = prefix + 'REMOVE_FRANCHISEE'

export const ACTIVATE_OR_DEACTIVATE_FRANCHISEE = prefix + 'ACTIVATE_OR_DEACTIVATE_FRANCHISEE'

export const SET_FRANCHISE_TO_EDIT = prefix + 'SET_FRANCHISE_TO_EDIT'

export const RESET_MANAGE = prefix + 'RESET_MANAGE'

export const SET_ROUTES = prefix + 'SET_ROUTES'

export const RESET = prefix + 'RESET'

export const SET_HEAD = prefix + 'SET_HEAD'

export const FRANCHISE_HEAD_CREATED = prefix + 'FRANCHISE_HEAD_CREATED'

export const SET_DELIVERY_COMMISSION_SUCCESS = prefix + 'SET_DELIVERY_COMMISSION_SUCCESS'
 
export const SET_DELIVERY_COMMISSION = prefix + 'SET_DELIVERY_COMMISSION'

export const SET_FRANCHISE_COMPANIES = prefix + 'SET_FRANCHISE_COMPANIES'