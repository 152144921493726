import React, { Dispatch, SetStateAction } from "react";
import { StateObjType, StateType } from "./useCustomState";
import { Input } from "../../../components/UI";

type OTPInputProps = {
    otp: StateType;
    setOtp: Dispatch<SetStateAction<StateType>>;
}

export function OTPInput({ otp, setOtp }: OTPInputProps) {
    // does: validate city
    function validateOtp(e: React.FocusEvent<HTMLInputElement, Element>) {
        if (!e.target.value) {
            setOtp(prevOtp => {
                const prev = prevOtp as StateObjType;
                return { ...prev, error: true }
            })
        } else if (!/^[0-9].{5,5}$/i.test(e.target.value)) {
            setOtp(prevOtp => {
                const prev = prevOtp as StateObjType;
                return { ...prev, error: true, errorMessage: "OTP must be of 6 digit!" }
            })
        }
    }
    return <Input title="OTP" variant="primary" type="tel" maxLength={6} state={{ value: otp, setValue: setOtp }} onBlur={validateOtp} />
}