//Intra City Types
export const SET_INTRACITY = "SET_INTRACITY";
export const RESET_INTERCITY = "RESET_INTERCITY";
export const SET_QUICK_ORDER = "SET_QUICK_ORDER";
export const SET_INTRACITY_PACKAGES = "SET_INTRACITY_PACKAGES";
export const SET_INTRACITY_BOOKING_RESET_TRACKING = "SET_INTRACITY_BOOKING_RESET_TRACKING";
export const CONFIRMED_INTRACITY_BOOKING = "CONFIRMED_INTRACITY_BOOKING";
export const INTRACITY_BOOKING_REQ_ID = "INTRACITY_BOOKING_REQ_ID";
export const CONFIRMED_INTRAcITY_BOOKING_ID = "CONFIRMED_INTRAcITY_BOOKING_ID";
export const ACCEPT_INTRACITY_BOOKING = "ACCEPT_INTRACITY_BOOKING";
export const SET_INTRACITY_BOOKING_TRACKING = "SET_INTRACITY_BOOKING_TRACKING";
export const ADD_INTRACITY_PICKUP_DROP_LOCATION = "ADD_INTRACITY_PICKUP_DROP_LOCATION";
export const SET_INTRACITY_PICKUP_DROP_LOCATION = "SET_INTRACITY_PICKUP_DROP_LOCATION";

export const SET_CUSTOMER_BOOKING_TYPE = "SET_CUSTOMER_BOOKING_TYPE";
export const SET_FULL_LOAD_VEHICLE_DETAILS = "SET_FULL_LOAD_VEHICLE_DETAILS";
//Part Load Types
export const SET_OUTSIDE_FORM_FILLED = "SET_OUTSIDE_FORM_FILLED";

export const SET_PARTLOAD = "SET_PARTLOAD";
export const RESET_PARTLOAD = "RESET_PARTLOAD";
export const SET_PARTLOAD_PACKAGES = "SET_PARTLOAD_PACKAGES";
export const CONFIRMED_PARTLOAD_BOOKING = "CONFIRMED_PARTLOAD_BOOKING";
export const SET_PARTLOAD_BOOKING_TRACKING = "SET_PARTLOAD_BOOKING_TRACKING";
export const SET_PARTLOAD_PICKUP_DROP_LOCATION = "SET_PARTLOAD_PICKUP_DROP_LOCATION";
export const ADD_PARTLOAD_PICKUP_DROP_LOCATION = "ADD_PARTLOAD_PICKUP_DROP_LOCATION";
export const SET_PARTLOAD_SHOW_EWAYBILL_LOADER = "SET_PARTLOAD_SHOW_EWAYBILL_LOADER";
export const SET_PARTLOAD_HIDE_EWAYBILL_LOADER = "SET_PARTLOAD_HIDE_EWAYBILL_LOADER";
export const RESET_PARTLOAD_EWAYBILL_DATA = "RESET_PARTLOAD_EWAYBILL_DATA";
export const SET_PARTLOAD_BOOKING_RESET_TRACKING = "SET_PARTLOAD_BOOKING_RESET_TRACKING";
export const SET_DOCKET_FROM_PARTLOAD_EWAYBILL = "SET_DOCKET_FROM_PARTLOAD_EWAYBILL";
export const ADD_PARTLOAD_EWAYBILL = "ADD_PARTLOAD_EWAYBILL";
export const SET_MANUAL_PARTLOAD_EWAYBILL = "SET_MANUAL_PARTLOAD_EWAYBILL";
export const SET_PARTLOAD_GROSS = "SET_PARTLOAD_GROSS";
export const SET_PARTLOAD_BOOKING_TYPE = "SET_PARTLOAD_BOOKING_TYPE";
export const SET_PARTLOAD_PAYMENT_MODE = "SET_PARTLOAD_PAYMENT_MODE";

export interface setIntraCityPickupDropLocations {
    type: typeof SET_INTRACITY_PICKUP_DROP_LOCATION;
    payload: {
        what: any,
        val: any,
        index: number
    };
}

export interface setPartLoadPickupDropLocations {
    type: typeof SET_PARTLOAD_PICKUP_DROP_LOCATION;
    payload: {
        what: any,
        val: any,
        index: number
    };
}

export interface setIntraCityPackages {
    type: typeof SET_INTRACITY_PACKAGES;
    payload: {
        what: any,
        val: any,
        index: number
    };
}

export interface setPartLoadPackages {
    type: typeof SET_PARTLOAD_PACKAGES;
    payload: {
        what: any,
        val: any,
        index: number
    };
}