import { showMessage, failed } from "../../utils/message";

const showErrorMessage = (error: any, field: any, inner: any) => {
  if (error && typeof error == "object" && error[field]) {
    let err = error[field];
    let temp = Object.keys(err),
      key = temp[temp.length - 1];
    console.log("err[key]: ", err[key], err[key]["message"]);
    showMessage(err[key][inner], failed);
  } else showMessage("Something went wrong!", failed);
};
export default showErrorMessage;
