import React, { Fragment, useState, useEffect } from "react";
import {
  // Grid,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../actions/UserActions";
import { RootState } from "../../store";
import { customerReviewRating } from "../../services/complaint";
import MoodBadIcon from "@mui/icons-material/MoodBad";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import MoodIcon from "@mui/icons-material/Mood";
import { showHttpError } from "../../utils/message";
import { ButtonPrimary } from "../../Components/Button/Buttoncontainer";
import { Input6 } from "../../Components/Input/Inputcomponents";
import Grid from "../../Components/Grid/Grid";

const RatingReview = (props: any) => {
  const [rating, setRating] = useState<any>(0);
  const [review, setReview] = useState<any>("");

  useEffect(() => {
    if (props.chat && props.chat.RAT) {
      setRating(props.chat.RAT.rating);
    }
    if (props.chat && props.chat.REV) {
      setReview(props.chat.REV.feedbackTitle);
    }
  });

  const handleSubmitRating = async () => {
    try {
      let payload: any = {
        feedbackType: "RAT",
        docketNumber: props.docketNumber,
        rating: rating,
      };
      await customerReviewRating(payload);
      props.getGuestUpdatedData(props.docketID);
    } catch (err : any) {
      showHttpError(err);
    }
  };
  const handleSubmitReview = async () => {
    try {
      let payload: any = {
        feedbackType: "REV",
        docketNumber: props.docketNumber,
        feedbackTitle: review,
      };
      await customerReviewRating(payload);
      props.getGuestUpdatedData(props.docketID);
    } catch (err : any) {
      showHttpError(err);
    }
  };

  return (
    <Fragment>
      {props.isDelivered && (
        <div style={{ marginTop: "10px" }}>
          {props.user.uid ? (
            props.chat &&
            (props.chat.RAT || props.chat.REV) && (
              <Accordion expanded={false}>
                <AccordionSummary>
                  <Grid container spacing={0}>
                    <Grid
                      lg={3}
                      md={3}
                      sm={12}
                      xs={12}
                      style={{ textAlign: "center", alignSelf: "center" }}
                    >
                      <h5> Rating and Review</h5>
                    </Grid>
                    <Grid
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{ textAlign: "center" }}
                    >
                      <Grid container spacing={0}>
                        <Grid lg={4} md={4} sm={4} xs={4}>
                          <p style={{ marginBottom: "0px" }}>
                            <MoodBadIcon
                              style={{
                                color: rating === 1 ? "red" : "grey",
                                fontSize: "48px",
                              }}
                            />
                          </p>
                        </Grid>
                        <Grid lg={4} md={4} sm={4} xs={4}>
                          <p style={{ marginBottom: "0px" }}>
                            <SentimentSatisfiedIcon
                              style={{
                                color: rating === 2 ? "orange" : "grey",
                                fontSize: "48px",
                              }}
                            />
                          </p>
                        </Grid>
                        <Grid lg={4} md={4} sm={4} xs={4}>
                          <p style={{ marginBottom: "0px" }}>
                            <MoodIcon
                              style={{
                                color: rating === 3 ? "green" : "grey",
                                fontSize: "48px",
                              }}
                            />
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid lg={1} md={1} sm={12} xs={12}></Grid>
                    <Grid
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{ border: "1px solid grey", padding: "4px" }}
                    >
                      {review}
                    </Grid>
                  </Grid>
                </AccordionSummary>
              </Accordion>
            )
          ) : (
            <Grid container spacing={0} style={{ marginTop: "10px" }}>
              <Grid lg={6} md={6} sm={12} xs={12} style={{ padding: "4px" }}>
                <Accordion expanded={false}>
                  <AccordionSummary>
                    <Grid container spacing={0}>
                      <Grid
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ textAlign: "center", alignSelf: "center" }}
                      >
                        Rate Your Experience
                      </Grid>
                      <Grid
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        style={{ textAlign: "center" }}
                      >
                        <Grid container spacing={0}>
                          <Grid lg={4} md={4} sm={4} xs={4}>
                            <p style={{ marginBottom: "0px" }}>
                              <MoodBadIcon
                                style={{
                                  color: rating === 1 ? "red" : "grey",
                                  fontSize: "48px",
                                }}
                                onClick={() => setRating(1)}
                              />
                            </p>
                          </Grid>
                          <Grid lg={4} md={4} sm={4} xs={4}>
                            <p style={{ marginBottom: "0px" }}>
                              <SentimentSatisfiedIcon
                                style={{
                                  color: rating === 2 ? "orange" : "grey",
                                  fontSize: "48px",
                                }}
                                onClick={() => setRating(2)}
                              />
                            </p>
                          </Grid>
                          <Grid lg={4} md={4} sm={4} xs={4}>
                            <p style={{ marginBottom: "0px" }}>
                              <MoodIcon
                                style={{
                                  color: rating === 3 ? "green" : "grey",
                                  fontSize: "48px",
                                }}
                                onClick={() => setRating(3)}
                              />
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        lg={2}
                        md={2}
                        sm={12}
                        xs={12}
                        style={{ textAlign: "center" }}
                      >
                        {props.user.uid ? (
                          ""
                        ) : (
                          <ButtonPrimary
                            onClick={() => handleSubmitRating()}
                            disabled={props.chat && props.chat.RAT}
                          >
                            Submit
                          </ButtonPrimary>
                        )}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                </Accordion>
              </Grid>

              <Grid lg={6} md={6} sm={12} xs={12} style={{ padding: "4px" }}>
                <Accordion expanded={false}>
                  <AccordionSummary>
                    <Grid container spacing={0}>
                      <Grid
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ textAlign: "center", alignSelf: "center" }}
                      >
                        Add Your Review
                      </Grid>
                      
                        <Input6
                          placeholder="Add Review"
                          value={review}
                          onChange={(e: any) => setReview(e.target.value)}
                          multiline={true}
                        />
                      <Grid
                        lg={2}
                        md={2}
                        sm={12}
                        xs={12}
                        style={{ textAlign: "center" }}
                      >
                        {props.user.uid ? (
                          ""
                        ) : (
                          <ButtonPrimary
                            onClick={() => handleSubmitReview()}
                            disabled={props.chat && props.chat.REV}
                          >
                            Submit
                          </ButtonPrimary>
                        )}
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                </Accordion>
              </Grid>
            </Grid>
          )}
        </div>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state: RootState) => ({
  user: state.user,
});
const mapDispatchToProps = {
  showLoader,
  hideLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(RatingReview);
