import React, { Fragment, useState, useEffect } from "react";
import MyImage from "../Image/MyImage";
import { connect } from "react-redux";
import { showHttpError } from "../../utils/message";
import { showLoader, hideLoader } from "../../actions/UserActions";
import { RootState } from "../../store";
import { sendGuestMessage, sendUserMessage } from "../../services/complaint";
import jsonToFormdata from "../../utils/jsonToFormdata";
import "./guestReply.css";
import ComplaintTrack from "./complaintTrack";
import ChatContainer from "../../Components/ChatModule/ChatContainer";
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, Select, TextArea } from "../../newSrc/components/UI";
import { CloseButton } from "../../Components/Button/AllButton";
import { InfoIcon } from "../../newSrc/constants/icons";

const GuestReply = (props: any) => {

  const [guestMessage, setGuestMessage] = useState("");
  const [replyImage, setReplyImage] = useState("");
  const [imageDialog, setImageDialog] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [complaintType, setComplaintType] = useState<any>("");
  const [remarks, setRemarks] = useState<any>("");

  useEffect(() => {
    if (props.chat && props.chat.COMP) {
      // scrollToMyRef();
    }
  }, [props.chat]);

  const handleSend = async () => {
    try {
      let payload: any = {
        messageText: guestMessage,
      };
      if (replyImage) {
        payload.media = replyImage;
      }
      const response = props.user.uid
        ? await sendUserMessage(
            props.chat.COMP.feedbackNumber,
            jsonToFormdata(payload)
          )
        : await sendGuestMessage(
            props.chat.COMP.feedbackNumber,
            jsonToFormdata(payload)
          );
      setGuestMessage("");
      setReplyImage("");

      await props.getGuestUpdatedData(props.docketID);
      console.log(response);
    } catch (err : any) {
      showHttpError(err);
    }
  };

  const handleImageClose = () => {
    setImageDialog(null);
  };

  const complaintOptions = (type: any) => {
    if (type === "LD") {
      return "Late delivery";
    } else if (type === "DMG") {
      return "Damage";
    } else if (type === "RM") {
      return "Receipt Missing";
    } else if (type === "PM") {
      return "Missing";
    } else if (type === "MB") {
      return "Misbehavior";
    } else if (type === "PF") {
      return "Pilferage";
    } else if (type === "OT") {
      return "Other";
    }
  };

  const statusOptions = (type: any) => {
    if (type === "O") {
      return "Open";
    } else if (type === "I") {
      return "In Progress";
    } else if (type === "CG") {
      return "Compensation Granted";
    } else if (type === "CP") {
      return "Compensation Paid";
    } else if (type === "CU") {
      return "Closed by user";
    } else if (type === "CC") {
      return "Closed by Customer";
    } else if (type === "CO") {
      return "Closed by Cron";
    } else if (type === "RE") {
      return " Re-opened";
    } else if (type === "CS") {
      return " Closed by system";
    }
  };
  // const scrollToMyRef = () => {
  //   const scrollHeight = chatContainer.current.scrollHeight;
  //   const height = chatContainer.current.clientHeight;
  //   const maxScrollTop = scrollHeight - height;
  //   chatContainer.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  // };

  const complaintOptionsList = [
    { label: "Late delivery", value: "LD" },
    { label: "Damage", value: "DMG" },
    // { label: "Receipt Missing", value: "RM" },
    { label: "Missing", value: "PM" },
    {
      label: "Pilferage",
      value: "PF",
    },
    {
      label: "Misbehavior",
      value: "MB",
    },
    // { label: "Late Pickup ", value: "LP" },
    // { label: "Other ", value: "OT" },
  ];

  const handleComplaintOpen = (e: string) => {
    const res = complaintOptionsList.find((r: any) => r.value === e);
    setComplaintType(res);
    setOpen(true);
  };

  const handleComplaintClose = () => {
    setOpen(false);
    setComplaintType("");
    setRemarks("");
  };

  const handleSubmit = async () => {
    const payload = {
      messageText: remarks,
      complaintType: complaintType.value,
    };
    await sendUserMessage(
      props.chat.COMP.feedbackNumber,
      jsonToFormdata(payload)
    );
    await props.getGuestUpdatedData(props.docketID);
    handleComplaintClose();
  };

  return (
    <Fragment>
      {props.chat && props.chat.COMP && (
        <div className="row" style={{ padding: "14px" }}>
          {/* <div className="col-md-8 col-xs-12"> */}
          <Accordion>
            <AccordionSummary>

                <div>
                  FeedBack Number: {props.chat.COMP.feedbackNumber}
                </div>
                <div className="flex ai-center gap-1">
                  Complaint Type:{" "}
                  {complaintOptions(props.chat.COMP.complaintType)}{" "}
                  {props.user.uid && (
                    <InfoIcon onClick={() =>
                      handleComplaintOpen(props.chat.COMP.complaintType)
                    } />
                  )}
                </div>
                <div>
                  Status: {statusOptions(props.chat.COMP.complaintStatus)}
                </div>
            </AccordionSummary>
            <AccordionDetails
              style={{ flexDirection: "column", width: "100%" , height:"750px"}}
            >
              <ComplaintTrack chat={props.chat} />{" "}
              {/* <div
                ref={chatContainer}
                style={{ height: "400px", overflowY: "scroll" }}
              >
                {props.chat.COMP.messages.length > 0 &&
                  props.chat.COMP.messages.map((i: any) => (
                    <>
                      <div
                        style={{
                          width: "80%",
                          float: props.user.uid
                            ? i.createdByType === "G"
                              ? "left"
                              : "right"
                            : i.createdByType === "G"
                            ? "right"
                            : "left",
                          margin: "6px",
                        }}
                      >
                        {i.text && (
                          <Avatar
                            style={{
                              float: props.user.uid
                                ? i.createdByType === "G"
                                  ? "left"
                                  : "right"
                                : i.createdByType === "G"
                                ? "right"
                                : "left",
                              height: "40px",
                              width: "40px",
                              margin: "4px",
                            }}
                          >
                            <PersonIcon />
                          </Avatar>
                        )}
                        {i.text && (
                          <div style={{ display: "flow-root" }}>
                            <p
                              style={{
                                fontSize: "10px",
                                margin: "0px",
                                textAlign: props.user.uid
                                  ? i.createdByType === "G"
                                    ? "left"
                                    : "right"
                                  : i.createdByType === "G"
                                  ? "right"
                                  : "left",
                              }}
                            >
                              {i.createdBy
                                ? i.createdBy.name && i.createdBy.name.fName
                                : props.chat.COMP.contactName}{" "}
                              {i.createdBy &&
                                i.createdBy.name &&
                                i.createdBy.name.lName}
                              , {formatDate(i.createdAt)},{" "}
                              {setTimeFormat(i.createdAt)}
                            </p>

                            <p
                              style={{
                                backgroundColor: props.user.uid
                                  ? i.createdByType === "G"
                                    ? "lightGrey"
                                    : "#acefac"
                                  : i.createdByType === "G"
                                  ? "#acefac"
                                  : "lightGrey",
                                borderRadius: "5px",
                                padding: "6px",
                              }}
                            >
                              {i.text}
                            </p>
                          </div>
                        )}
                        {i.media.length > 0 && (
                          <Avatar
                            style={{
                              float: props.user.uid
                                ? i.createdByType === "G"
                                  ? "left"
                                  : "right"
                                : i.createdByType === "G"
                                ? "right"
                                : "left",
                              height: "40px",
                              width: "40px",
                              margin: "4px",
                            }}
                          >
                            <PersonIcon />
                          </Avatar>
                        )}{" "}
                        {i.media.length > 0 &&
                          i.media.map((e: any) => (
                            <div>
                              <p
                                style={{
                                  fontSize: "10px",
                                  margin: "0px",
                                  textAlign: props.user.uid
                                    ? i.createdByType === "G"
                                      ? "left"
                                      : "right"
                                    : i.createdByType === "G"
                                    ? "right"
                                    : "left",
                                }}
                              >
                                {i.createdBy
                                  ? i.createdBy.name && i.createdBy.name.fName
                                  : props.chat.COMP.contactName}{" "}
                                {i.createdBy &&
                                  i.createdBy.name &&
                                  i.createdBy.name.lName}
                                , , {formatDate(i.createdAt)},{" "}
                                {setTimeFormat(i.createdAt)}
                              </p>
                              <p
                                style={{
                                  textAlign: props.user.uid
                                    ? i.createdByType === "G"
                                      ? "left"
                                      : "right"
                                    : i.createdByType === "G"
                                    ? "right"
                                    : "left",
                                }}
                              >
                                {" "}
                                <img src={e.url} height="150px" width="auto" />
                              </p>
                            </div>
                          ))}
                      </div>
                    </>
                  ))}

                <br />
              </div>{" "}
              {props.user.uid ? (
                props.chat.COMP.complaintStatus === "CC" ||
                props.chat.COMP.complaintStatus === "CU" ? (
                  ""
                ) : (
                  <div className="fixedArea" style={{ padding: "10px" }}>
                    <div
                      className="col-md-12 col-xs-12"
                      style={{ textAlign: "center" }}
                    >
                      <ButtonLight
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        Close
                      </ButtonLight>
                    </div>
                  </div>
                )
              ) : props.chat.COMP.complaintStatus === "CC" ? (
                ""
              ) : (
                <div className="fixedArea" style={{ padding: "10px" }}>
                  <div
                    className="col-md-12 col-xs-12"
                    style={{ textAlign: "center" }}
                  >
                    <ButtonLight
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Close
                    </ButtonLight>
                  </div>
                </div>
              )}
              <div className="fixedArea" style={{ padding: "10px" }}>
                <div className="col-md-10 col-xs-8">
                  <Input
                    value={guestMessage}
                    placeholder="Type a message"
                    onChange={(e: any) => setGuestMessage(e.target.value)}
                    onKeyDown={(e: any) => handleKeyDown(e)}
                  />
                </div>
                <div
                  className="col-md-1 col-xs-2"
                  style={{ marginTop: "10px", fontSize: "20px" }}
                >
                  <ImageInput
                    name="Add Image Here"
                    Icon={(props: any) => (
                      <i {...props} className={"fas fa-id-card file_icon"} />
                    )}
                    onChange={(e: any) => setReplyImage(e)}
                    value={replyImage}
                    extras={<div></div>}
                  />
                </div>
                <div
                  className="col-md-1 col-xs-2"
                  style={{ marginTop: "10px" }}
                >
                  <SendIcon
                    onClick={() => handleSend()}
                    style={{ float: "right", color: "blue" }}
                  />
                </div>
              </div> */}

              <br/> 
              <div className="ChatContainer">
                <ChatContainer 
                    chatHistory={props?.chat?.COMP?.messages?.length > 0 && props?.chat?.COMP?.messages} 
                    value={guestMessage}
                    onChange={setGuestMessage}
                    onKeyDown={handleSend}
                    onClick={handleSend}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          {/* </div> */}
        </div>
      )}
      <Dialog state={Boolean(imageDialog)} setState={setImageDialog} onClose={handleImageClose} 
      body={
        <MyImage objectID={imageDialog} />
      }
      footer={
        <CloseButton onClick={handleImageClose}/>
      }
      />

      <Dialog
        state={open}
        setState={setOpen}
        onClose={handleComplaintClose}
        body=
        {
          <div>

            <Select
              value={complaintType}
              options={complaintOptionsList}
              onChange={(e: any) => setComplaintType(e)}
              label="Select Complaint"
              placeholder="Select Complaint"
            />

            <TextArea
              rows={3}
              value={remarks}
              label="Detailed Information"
              placeholder="Provide detail information here"
              onChange={(e: any) => setRemarks(e.target.value)}
            />
          </div>
        }
        footer={
          <div>
            <Button variant='primary' action='secondary' onClick={handleSubmit}>Subimt</Button>
            <CloseButton onClick={handleComplaintClose} />
          </div>
        }
      />

     
    </Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});
const mapDispatchToProps = {
  showLoader,
  hideLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestReply);
