const initState = {
  missingList: [],
};

export default (state = initState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case "GET_MISSING_LIST":
      return {
        ...state,
        missingList: action.payload,
      };
    default:
      return state;
  }
};
