import React from "react";
import { FaTruck, FaUser } from "react-icons/fa";
import { MdCall } from "react-icons/md";

const DoorDeliveryVoucher = (props: any) => {
  const { data } = props;
  const value = data?.doorDeliveryDocket
  console.log("Props of Voucher" , value)
  console.log("Props of data Voucher" , data)

  var tempDate = new Date(value?.createdAt);
  const styles: any = {
    border: "1px solid black",
    width: "100%",
    borderCollapse: "collapse",
    borderSpacing: "5px",
    marginBottom: "2.5%",
  };
  const tr_td: any = {
    border: "1px solid black",
    borderCollapse: "collapse",
    textAlign: `center`,
  };
  const tr_th: any = {
    border: "1px solid black",
    borderCollapse: "collapse",
    textAlign: `center`,
    justifyContent: `end`,
  };
  const headingSmallTitle: any = {
    width: `100%`,
    textAlign: `center`,
    fontSize: `small`,
  };
  const dateInvoiceNumber: any = {
    display: `block`,
    marginTop: `2.5%`,
    marginBottom: `2.5%`,
  };
  const driverDetailsContainer: any = {
    width: `100%`,
    display: `flex`,
    textAlign: `center`,
    justifyContent: `space-evenly`,
    marginTop: `2.5%`,
    marginBottom: `2.5%`,
  };
  const driverDetailsContainerDiv: any = {
    width: `33%`,
  };
  const textAlign : any = {
    textAlign: `end`,
  };
  const marginBotton2 : any = {
    marginBottom: `2.5%`,
  };
  const fronAndToDetailsContainer : any = {
    dispay: "flex",
    marginBottom: `2.5%`,
  };

  const date =
    tempDate.getDate() +
    "-" +
    (tempDate.getMonth() + 1) +
    "-" +
    tempDate.getFullYear() +
    " " +
    tempDate.getHours() +
    ":" +
    tempDate.getMinutes() +
    ":" +
    tempDate.getSeconds();

    const TotalKg = () => {
      let total = 0;
      value?.packages && value?.packages?.length > 0 && value?.packages?.map(( ele : any ) => {
        total = total + ele?.weight;
      }) 
      return total;
    }

  return (
    <div id="door_delivery_voucher">
      <div style={headingSmallTitle}>
        Invoice / Consignment Note
      </div>
      <div style={driverDetailsContainer}>
        <div style={driverDetailsContainerDiv}> <FaUser /> {`${value?.driver?.name?.fName} ${value?.driver?.name?.lName}`} </div>
        <div style={driverDetailsContainerDiv}>  <MdCall /> {value?.driverContact} </div>
        <div style={driverDetailsContainerDiv}> <FaTruck /> {value?.regNumber} </div>
      </div>
      <div style={dateInvoiceNumber}>
        <div style={textAlign}> Ref Number : {value?.doorDelDocketNumber} </div>
        <div style={textAlign}> Date & Time : {date} </div>
      </div>
      <div style={marginBotton2}>
        <div> Customer Name: {data?.docket?.receiver?.name} </div>
      </div>
      <div style={fronAndToDetailsContainer}>
          <p>From : {data?.docket?.destBranch?.address?.l1} </p>
          <p>To : {value?.doorDelAddress?.place?.placeValue}</p>
      </div>
      <table style={styles}>
        <thead>
          <tr>
            <th style={tr_th}>Particulars</th>
            <th style={tr_th}>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={tr_td}>{`${value?.serviceType} ( ${value?.assetType && value?.assetType?.assetName} ) / Packages = ${value?.packages?.length} / Kg = ${TotalKg()}`}</td>
            <td style={tr_td}>{value?.totalDoorDelCharges}</td>
          </tr>
          <tr>
            <td style={tr_td} colSpan={1}> Total</td>
            <td style={tr_td}>{value?.totalDoorDelCharges}</td>
          </tr>
        </tbody>
      </table>
      <p style={{ textAlign: "right", alignSelf: "stretch" }}>
        For,
        {`${value?.driver?.name?.fName} ${value?.driver?.name?.lName}`}
      </p>
    </div>
  );
};

export default DoorDeliveryVoucher;
