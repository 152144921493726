import React, { useEffect } from 'react'
import { connect } from "react-redux";
import { RootState } from "../../store";
import { useNavigate, } from 'react-router-dom';
import "./CustomerTrackView.css";
import { customerLogin } from '../../actions/customerLogin';
import { Footer } from "../../newSrc/pages/Landing/components";
import BranchTrackingView from "./Components/BranchTrackingView";
import { showLoader, hideLoader } from "../../actions/UserActions";
import { ResetCustomerTracking } from '../../actions/trackingModule';
import CustomerTrackingView from "./Components/CustomerTrackingView";
import { setDoorDelivery, resetDoorDelivery } from "../../actions/booking";
import { setCustomerBookingTracking } from './../../actions/customerBooking';
import { getCustomerDocketLocation } from "../../actions/liveLocation";

const CustomerTrackView = (props: any) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (props?.customerTrackingData?.source === undefined || props?.customerTrackingData?.source === null) {
      navigate("/")
    }
  }, []);

  return (
    <div className="">
      <div className="p-1">
        {props?.customerTrackingData?.source === "Customer-Authenticated-CustomerOrder"
          || props?.customerTrackingData?.source === "Customer-NotAuthenticated-CustomerOrder"
          || props?.customerTrackingData?.source === "Branch-Authenticated-CustomerOrder" ? <CustomerTrackingView data={props} /> : <></>}
        {props?.customerTrackingData?.source === "Customer-Authenticated-BranchOrder"
          || props?.customerTrackingData?.source === "Customer-NotAuthenticated-BranchOrder"
          || props?.customerTrackingData?.source === "Branch-Authenticated-BranchOrder" ? <BranchTrackingView data={props} /> : <></>}
      </div>
      <br />
      {(props?.customerTrackingData?.source === "Customer-NotAuthenticated-BranchOrder" || props?.customerTrackingData?.source === "Customer-NotAuthenticated-CustomerOrder") &&
        <div>
          <Footer />
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  customerTrackingData: state.customerTracking,
});

const mapDispatchToProps = {
  customerLogin,
  setCustomerBookingTracking,
  ResetCustomerTracking,
  showLoader,
  hideLoader,
  setDoorDelivery,
  resetDoorDelivery,
  getCustomerDocketLocation
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTrackView);
