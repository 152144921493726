export const faqs = [
  {
    id: 0,
    question: "How early should I book a courier?",
    answer:
      "You only need to book 30 minutes in advance if the first delivery is within travel zone 1, one hour in advance if in travel zone 2 and 1.5–2 hours if further out.",
  },
  {
    id: 1,
    question: "Can I choose a specific courier for my delivery?",
    answer:
      "When you fill in the form, you can use the free Preferred Courier service in your online account. You can choose couriers you have used before and whom you have given a 5-star rating. Your chosen courier will receive a message that you would like them to deliver your order. If the courier wants to take the order, he/she clicks on it.",
  },
  {
    id: 2,
    question: "Can I order a specific vehicle?",
    answer:
      "We don’t know in advance what car the courier is driving. To make sure you get a courier whose car is big enough, specify the dimensions and weight of the item. You will get responses from couriers who have cars big enough to fulfill your order.",
  },
  {
    id: 3,
    question: "Can I cancel or change my order?",
    answer:
      "If you change the order after the courier has set off, you will be charged ₹ 200. You can make changes through your online account or by phone, but these changes have to be agreed upon with the courier.",
  },
  {
    id: 4,
    question: "Why hasn't anyone been assigned to my order yet?",
    answer:
      "Our system works based on responses. So far, no couriers have responded to your order. Depending on the day and the route, it can take from one minute to an hour to find a courier.",
  },
  {
    id: 5,
    question: "How do I know if the courier has delivered the parcel?",
    answer:
      "You will receive a text message or email. The recipient will also receive a text message notifying them that the courier is coming (unless the delivery is a surprise).",
  },
];

export const individualWithFleetFaqs = [
  {
    id: 1,
    question: "How do I attach my vehicle with TapTap?",
    answer:
      "Becoming a TapTap partner and attaching your vehicle is a simple 3-step process: Download the TapTap App. Register and submit required documents through the app. Once verified, you'll receive confirmation and can start receiving orders immediately.",
  },
  {
    id: 2,
    question: "What documents and permits are needed to onboard?",
    answer:
      "For commercial vehicle attachments, you'll need an Aadhar Card, PAN Card, Driving License, RC Book, and Passport Size Photo.",
  },
  {
    id: 3,
    question: "What types of vehicles can be attached to TapTap?",
    answer:
      "You can attach 2-wheelers like bikes and scooters, and commercial vehicles with capacities of 1 ton, 2 tons, and 3 tons for our On-Demand Services.",
  },
  {
    id: 4,
    question: "Can I join as a delivery partner if I don't own a vehicle?",
    answer:
      "Yes, you can join by renting a bike, e-bike, or cycle specifically for deliveries. TapTap can assist you in connecting with vendors for these vehicles.",
  },
  {
    id: 5,
    question: "How do I register as a certified TapTap partner?",
    answer:
      "To register, fill in your details, upload identity proof, and bank details, and select your serviceable locality. Once verified, you can start delivering within two days.",
  },
  {
    id: 6,
    question: "Is overloading allowed and what are the regulations?",
    answer:
      "Overloading is prohibited. Please load your vehicle according to RTO-approved capacity to ensure safety, avoid damage, and comply with the law.",
  },
  {
    id: 7,
    question: "How early should I book a courier?",
    answer:
      "You need to book 30 minutes in advance for travel zone 1, one hour for zone 2, and 1.5–2 hours for further zones.",
  },
  {
    id: 8,
    question: "How quickly can I get paid for my deliveries?",
    answer:
      "Payments are made several times a week. Cash orders are paid immediately; non-cash orders are settled on the next payment day.",
  },
  {
    id: 9,
    question: "Can I combine TapTap with other work or studies?",
    answer:
      "Yes, you can plan deliveries around your schedule ideal for students, taxi drivers, and part-time job seekers.",
  },
  {
    id: 10,
    question: "How often do I need to work as a courier?",
    answer:
      "You can choose your schedule to deliver full-time or part-time, with orders available daily, including evenings and weekends.",
  },
  {
    id: 11,
    question: "What do I need to deliver orders?",
    answer: "Couriers at TapTap use motorcycles for delivering packages.",
  },
  {
    id: 12,
    question: "When do I start delivering orders?",
    answer:
      "Once your registration is complete and the background check is cleared, you can start delivering orders.",
  },
  {
    id: 13,
    question:
      "Is there any current vacancy available for delivery drivers at TapTap?",
    answer:
      "Yes, TapTap always has demand for delivery drivers. Whether you're looking for part-time or full-time opportunities, download the TapTap app to start earning conveniently on the move.",
  },
  {
    id: 14,
    question:
      "In which cities does TapTap offer delivery driver opportunities?",
    answer:
      "TapTap offers delivery driver jobs in cities like Ahmedabad, Vadodara, Surat, Mumbai, Pune, Jaipur, Bangalore, Hyderabad, and more. These cities provide convenient routes and plenty of delivery orders for drivers.",
  },
  {
    id: 15,
    question: "Why should I join TapTap as a delivery driver?",
    answer:
      "Join TapTap for daily bulk door deliveries, intra-city transport, door pickup, monthly prizes, referral rewards, branch promotion incentives, and vehicle ad earnings!",
  },
  {
    id: 16,
    question:
      "What are the benefits of joining as a Delivery Partner other than earnings?",
    answer:
      "As a TapTap Delivery Partner, you will be provided with personal accidental life and health insurance. This provides insurance coverage against injuries and protects you against financial costs in the unfortunate event of an accident.",
  },
  {
    id: 17,
    question: "Will I get any manpower for loading, unloading, and packing?",
    answer:
      "All services confirmed through TapTap only include the transportation of goods and any manpower help that has been requested by the customer can be accepted or rejected by you. However, the amount of booking of the trip that has been processed does not include the other services of loading and unloading.",
  },
  {
    id: 18,
    question: "How can I find out how much money I will receive for an order?",
    answer:
      "You can review each order's details in the app before accepting it. You'll never earn less than the stated amount, and tips from recipients are a pleasant bonus.",
  },
  {
    id: 19,
    question: "How do I receive payments?",
    answer:
      "We operate on a daily payout system for our riders, ensuring you receive your earnings promptly each day.",
  },
  {
    id: 20,
    question:
      "What should I do if I've forgotten my password or need to reset it?",
    answer:
      'If you\'ve forgotten your password, click "Forgot password" on the login screen. Enter your registered phone number and verify via SMS or call. To change your password, navigate to Settings and follow the prompts.',
  },
  {
    id: 21,
    question: "I didn't receive the OTP. What should I do?",
    answer:
      "Please ensure your app is up-to-date. If the issue persists, contact support@taptap.in with specific details.",
  },
  {
    id: 22,
    question: "How can I resolve account or app issues?",
    answer:
      "For account or app problems, verify your details, reset your password if necessary, and ensure your app is updated or restarted. Contact support with detailed information if issues persist.",
  },
  {
    id: 23,
    question: "How do I delete my account?",
    answer:
      "To request account deletion, email dpsupport@taptap.com. Include your phone number for verification purposes.",
  },
  {
    id: 24,
    question: "How do I contact TapTap support?",
    answer: "Go to Profile > Help Center > General Support.",
  },
  {
    id: 25,
    question: "How can I refer friends to TapTap?",
    answer:
      "In the app, go to Profile > Refer friend, get rewards > Share the unique link with your friends.",
  },
  {
    id: 26,
    question: "What sets TapTap apart from other courier services?",
    answer:
      "TapTap stands out with flexible opportunities for bike riders and tempo drivers of all experience levels, allowing drivers to set their schedules.",
  },
  {
    id: 27,
    question: "What is TapTap Money?",
    answer:
      "TapTap Money is a secure digital wallet for storing and using digital currency, ensuring smooth transactions and hassle-free refunds.",
  },
  {
    id: 28,
    question: "What are the work timings?",
    answer:
      "Work timings are flexible, and you can choose your slots. More details will be provided during your interview.",
  },
  {
    id: 29,
    question: "What are login hours?",
    answer:
      "Login hours refer to the times you're available on the app to accept orders, with the flexibility to log in based on your availability.",
  },
  {
    id: 30,
    question: "Can you explain Type 2 and Type 3 riders?",
    answer:
      "Type 2 riders use two-wheelers, while Type 3 riders use three-wheelers or four-wheelers.",
  },
  {
    id: 31,
    question: "How can I cancel a booking?",
    answer:
      "You can raise a cancellation request through the web by logging in to your account or Phone using the application at least 30 minutes before the pickup time. We will proceed with the booking cancellation based on the provided information and onward procedure for the requester. The driver will not be charged for the timely cancellation of the booking.",
  },
  {
    id: 32,
    question: "How will I receive the bills?",
    answer:
      "TapTap will process service bills on request and they will be delivered via E-mail. All the bills for current and previous rides are always available with one click and are accessible through the website and the mobile app in trip history.",
  },
];

export const intraCityFaqs = [
  {
    id: 1,
    question:
      "Does TapTap offer labor services, support multi-point pick-up and drop, and what is their maximum service distance?",
    answer:
      "TapTap does offer labor services, supports multi-point pick-up and drop with additional costs, and operates within city limits only.",
  },
  {
    id: 2,
    question:
      "Are there restrictions on the types of products I can ship with TapTap, and do they provide Proof of Delivery (POD)?",
    answer:
      "TapTap restricts items like alcohol and pets and does not provide Proof of Delivery (POD).",
  },
  {
    id: 3,
    question:
      "What kind of items can I deliver using TapTap for Business, and can it be used for intracity deliveries? How much does TapTap for Business cost, and are there any additional charges?",
    answer:
      "TapTap for Business delivers items under 15 kg, supports intracity deliveries, and charges vary based on distance and order volumes. No signup charges.",
  },
  {
    id: 4,
    question:
      "How can I pay for my deliveries on TapTap for Business, and will using this service get me listed on the TapTap app for more orders?",
    answer:
      "Payments for TapTap for Business can be made via COD, Digital Payments, or TapTap Wallet. Registering your business can get you listed on the TapTap app for more orders.",
  },
  {
    id: 5,
    question:
      "What is TapTap, what do I need to do to send a package, and what are the restrictions on what I can send and where I can send my package?",
    answer:
      "TapTap allows local delivery of packages fitting vehicle-specific limits and excluding prohibited items like alcohol and drugs. Designed for intracity use.",
  },
  {
    id: 6,
    question:
      "What does the recipient need to do with TapTap, and can I send a package as a surprise?",
    answer:
      "Recipients should meet TapTap drivers for drop-off. Surprises can be arranged with driver instructions, though drivers may decline.",
  },
  {
    id: 7,
    question: "Are toll charges and parking charges included in the fare?",
    answer:
      "Toll and parking charges are not included in the fare shown on the app.",
  },
  {
    id: 8,
    question:
      "What happens if a trip is canceled? What are the cancellation charges and policy?",
    answer: "There are no cancellation charges or penalties.",
  },
  {
    id: 9,
    question:
      "What are the charges for a TapTap truck and a TapTap bike delivery service?",
    answer:
      "Charges for TapTap truck and bike services vary based on distance and vehicle type, shown on the app or website when booking.",
  },
  {
    id: 10,
    question:
      "Who is responsible for the generation and maintenance of E-way bills during transit?",
    answer:
      "It is the responsibility of the customer to generate the E-way bill and provide a copy to the TapTap Driver Partner.",
  },
  {
    id: 11,
    question: "How do I contact the TapTap support team?",
    answer: "Contact TapTap support: Click on Help Center > General Support.",
  },
  {
    id: 12,
    question: "How would I know my fare charges & bill amount?",
    answer: "Enter Pickup and Drop details for the instant fare calculation.",
  },
  {
    id: 13,
    question: "Can I track my vehicle status?",
    answer: "Yes, track in real-time via our app.",
  },
  {
    id: 14,
    question: "How does an API Integration with TapTap work?",
    answer:
      "TapTap's APIs are easy to integrate with your order management system, allowing seamless order creation.",
  },
  {
    id: 15,
    question: "How does an API integration solve business problems?",
    answer:
      "API integration with TapTap enables webhook updates, live tracking, and secure delivery confirmation across 16 cities, enhancing operational efficiency.",
  },
  {
    id: 16,
    question: "What vehicle categories are available via the APIs?",
    answer:
      "Currently, TapTap's APIs support 2-wheeler vehicles; integration for other vehicle categories is coming soon.",
  },
];

export const partLoadAndFullLoadFaqs = [
  {
    id: 1,
    question: "How to book a Truck online with TapTap?",
    answer:
      "Download the App, Sign Up, Enter Pickup and Delivery Details, select the truck type, customize services, get quotes, confirm booking, and track shipment.",
  },
  {
    id: 2,
    question: "Why should I choose TapTap for transport service?",
    answer:
      "TapTap provides safe, convenient, and affordable transportation Part Load services across India with features like real-time pricing, easy payments, tracking, and 24/7 support.",
  },
  {
    id: 3,
    question: "What type of Services does TapTap provide?",
    answer:
      "TapTap offers Part Load, and Full Load services nationwide, with over 1000+ Vehicles serving in 25+ cities. Options include containers and various truck types.",
  },
  {
    id: 4,
    question: "How will I pay for Truck booking online?",
    answer:
      "Payments can be made partially or fully at the time of loading and unloading via secure online methods.",
  },
  {
    id: 5,
    question: "How can I insure my goods during the trip?",
    answer:
      "TapTap provides insurance assistance at competitive rates to ensure the safety of transported goods.",
  },
  {
    id: 6,
    question: "How do I book logistics services on TapTap?",
    answer:
      "Register on www.TapTap.in, Attach your Load details, and select logistics services under the Services tab.",
  },
  {
    id: 7,
    question: "What is the cost of Booking logistics services on TapTap?",
    answer:
      "TapTap offers free booking services without any commission or hidden charges.",
  },
  {
    id: 8,
    question: "How do I attach my loads on TapTap to book logistics services?",
    answer:
      "Register, go to My Loads, and click on Post Load to provide load details, destination, and truck type required.",
  },
  {
    id: 9,
    question: "Can I track my logistics services booking status?",
    answer:
      "Yes, you can track your booking status through the Trip Details dashboard on TapTap's website.",
  },
  {
    id: 10,
    question: "What are the benefits of Networking on TapTap?",
    answer:
      "TapTap provides a secure payment gateway for hassle-free transactions using Cash, Digital Payments, and Debit Card.",
  },
  {
    id: 11,
    question: "How can I contact the driver with TapTap?",
    answer:
      "Use the App to directly contact the driver once the booking is confirmed.",
  },
  {
    id: 12,
    question: "What types of trucks does TapTap offer?",
    answer:
      "TapTap offers various truck types including container trucks and open trucks for transportation across India.",
  },
];

export const expressParcelBranchToBranchFaqs = [
  {
    id: 1,
    question: "What areas does TapTap Parcel Service cover?",
    answer:
      "TapTap Parcel Service operates across 4 states and covers 30+ major cities and 70+ Branches.",
  },
  {
    id: 2,
    question: "What types of parcels can I send with TapTap?",
    answer:
      "You can send consignments of varying sizes and weights with TapTap, utilizing our Part Truck Load (PTL) and Full Truck Load (FTL) services for efficient transport.",
  },
  {
    id: 3,
    question: "Does TapTap offer door-to-door delivery?",
    answer:
      "Yes, TapTap provides door-to-door delivery services, including door collection and delivery for your convenience.",
  },
  {
    id: 4,
    question: "How can I track my parcel with TapTap?",
    answer:
      "You can easily track your parcel through our load tracking service, ensuring you stay updated on your shipment's status.",
  },
  {
    id: 5,
    question: "What measures does TapTap take to ensure parcel safety?",
    answer:
      "TapTap uses company-owned vehicles for transport, minimizing theft, pilferage, and damage. Our dedicated team ensures the safest possible movement of your consignments.",
  },
  {
    id: 6,
    question: "How do I get started with TapTap Parcel Service?",
    answer:
      "To start using TapTap Parcel Service, you can contact our customer support or visit our website to register and schedule your first shipment.",
  },
  {
    id: 7,
    question: "What makes TapTap different from other parcel service providers?",
    answer:
      "TapTap stands out due to our wide service network, comprehensive logistics solutions, and commitment to safety and reliability. Our company-owned vehicles and extensive branch network ensure efficient and secure parcel delivery.",
  }
];

export const expressParcelDoorDeliveryFaqs = [
  {
    id: 1,
    question: "How do I check the status of my parcel?",
    answer:
      "To check the status of your parcel, visit our website's Quick Links section and enter the Docket No. in the “Track your cargo” box.",
  },
  {
    id: 2,
    question: "How do I find out if there is a service available for a particular location?",
    answer:
      "Use the Branch Locator link on our website to search for branches based on city, state, region, or Pincode.",
  },
  {
    id: 3,
    question: "How can I become a business partner with TapTap Courier?",
    answer:
      "For partnership opportunities as a franchisee, vendor, or associate, please email us at franchise@taptapcourier.in.",
  },
  {
    id: 4,
    question: "How can I contact TapTap Courier? What are the contact options?",
    answer:
      "You can reach us through the toll-free number: 1800-XXX-XXXX. 'Email: customercare@taptapcourier.com' Visit the Branch Locator on our website for phone numbers and addresses.",
  },
  {
    id: 5,
    question: "Can I change the phone number or address of my order after booking?",
    answer:
      "You can update the receiver’s phone number and address from the My Orders section on our website or app. Changes to the sender's details are not allowed after booking.",
  },
  {
    id: 6,
    question: "How can I book a commercial shipment with TapTap Courier?",
    answer:
      "For commercial shipments, please contact our business services team through our website for tailored solutions.",
  },
  {
    id: 7,
    question: "What is the refund policy if my pickup is not completed?",
    answer:
      "If your pickup request is canceled, charges will be refunded to your original payment method within 7 working days.",
  }
];

export const faqsData = [
  {
    id: 0,
    page: "partners",
    partner: "Individual With Fleet",
    faqs: individualWithFleetFaqs,
  },
  {
    id: 1,
    page: "services",
    service: "Intra City",
    faqs: intraCityFaqs,
  },
  {
    id: 2,
    page: "services",
    service: "Part Load & Full Load",
    faqs: partLoadAndFullLoadFaqs,
  },
  {
    id: 3,
    page: "services",
    service: "Express Parcel",
    expressParcelType: "Branch to Branch",
    faqs: expressParcelBranchToBranchFaqs,
  },
  {
    id: 4,
    page: "services",
    service: "Express Parcel",
    expressParcelType: "Door Delivery",
    faqs: expressParcelDoorDeliveryFaqs,
  },
];
