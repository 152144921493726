import {
  postIncomeRecord,
  deletedIncomeRecord,
  editIncomeRecordService,
  getIncomeTypesService,
  getPastIncomes as getPastIncomesService,
} from "../../services/income";

import { AgentList } from "../../services/branch";
import { extractHandler } from "../../services/helpers/error";
import { getAssetBalance } from "../../services/asset";
import {
  GET_INCOME_TYPES,
  GET_INCOME_TYPES_FAILED,
  CHANGE_ADD_INCOME,
  GET_SUB_TYPES,
  POST_INCOME_RECORD_SUCCESS,
  CLEAR_ADD_INCOME,
  SET_INCOME_RECORD,
  RESET_INCOME_RECORD,
  EDIT_INCOME_RECORD_FAILED,
  SET_ASSET_TYPES,
  POST_INCOME_RECORD_FAILED,
  EDIT_INCOME_RECORD_SUCCESS,
  REACT_SELECT_ERROR,
  CHECK_INCUR_INCOME_VALIDATION,
  SET_PAST_INCOMES,
  UPDATE_PAST_INCOMES,
  GET_ASSET_BAL,
} from "../../constants/income";
import { updateBalance, hideLoader } from "../UserActions";
import { viewAssetBalanceDetails, listAssetType } from "../../services/asset";
import {
  validateIncome,
  final_Incur_Income_Validation,
} from "../../reducers/schemas/addIncome";
import { isArrayCheck } from "../../containers/generics/CheckArray";
import { showMessage, failed } from "../../utils/message";
import { SHOW_LOADER, HIDE_LOADER } from "../../constants/booking";
import { toast } from "react-toastify";
import jsonToFormdata from "../../utils/jsonToFormdata";
import { Dispatch } from "redux";
import { RootState } from "../../store";

export const setIncomeRecord = (data: any, cb: any) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const fetchedInputTypes = getState().income.get("fetchedInputTypes");
      if (isArrayCheck(fetchedInputTypes)) {
        let ref = fetchedInputTypes.find((f: { id: any }) => f.id == data.ref);
        if (!ref)
          throw new Error("Income type of selected record does not exists.");
        data = {
          ...data,
          ref: {
            label: ref.name,
            value: ref.id,
          },
        };
      }
      if (data.route) {
        let fetchedRoutes = getState().user.routes;
        if (isArrayCheck(fetchedRoutes)) {
          let foundRoute = fetchedRoutes.find(
            (r: { _id: string }) => r._id == data.route
          );
          data = {
            ...data,
            is_route: true,
            route: {
              label: foundRoute && foundRoute.name ? foundRoute.name : "N/A",
              value: foundRoute._id ? foundRoute._id : "",
            },
          };
        }
      }
      let sub_val = data.sub_val;
      switch (data.sub) {
        case "A": {
          let fetchAssetDetails = await viewAssetBalanceDetails(sub_val);
          let { _id, category, assetName } = fetchAssetDetails.type;
          let assetType = { label: assetName, value: _id };
          data = {
            ...data,
            assetType,
            // category
          };
          let body = {
            categoryId: category,
            company: getState().user.company._id,
          };
          let assetTypes = await listAssetType(body);
          dispatch({ type: SET_ASSET_TYPES, payload: assetTypes });
          let assetData = {
            assetType: _id,
            company: getState().user.company._id,
          };
          const temp = await getAssetBalance(assetData);
          dispatch({ type: GET_ASSET_BAL, payload: temp });
          if (isArrayCheck(temp)) {
            sub_val = temp.find((s: { _id: string }) => s._id == sub_val);
            data = {
              ...data,
              sub_val: {
                label: sub_val.uniqueId
                  ? sub_val.uniqueId
                  : sub_val.qty + " " + sub_val.type.assetName,
                value: sub_val._id,
              },
            };
          }
          break;
        }
        case "C": {
          let fetchAssetDetails = await viewAssetBalanceDetails(sub_val);
          let { _id, category, assetName } = fetchAssetDetails.type;
          let assetType = { label: assetName, value: _id };
          data = {
            ...data,
            assetType,
            // category
          };
          let body = {
            categoryId: category,
            company: getState().user.company._id,
          };
          let assetTypes = await listAssetType(body);
          dispatch({ type: SET_ASSET_TYPES, payload: assetTypes });
          let assetData = {
            assetType: _id,
            company: getState().user.company._id,
          };
          const temp = await getAssetBalance(assetData);
          dispatch({ type: GET_ASSET_BAL, payload: temp });
          if (isArrayCheck(temp)) {
            sub_val = temp.find((s: { _id: string }) => s._id == sub_val);
            data = {
              ...data,
              sub_val: {
                label: sub_val.uniqueId
                  ? sub_val.uniqueId
                  : sub_val.qty + " " + sub_val.type.assetName,
                value: sub_val._id,
              },
            };
          }
          break;
        }
        case "B": {
          let branches = getState().user.branches;
          sub_val = branches.find((b: { _id: string }) => b._id == sub_val);
          data = {
            ...data,
            sub_val: { label: sub_val.branchName, value: sub_val._id },
          };
          dispatch({ type: GET_SUB_TYPES, payload: branches });
          break;
        }
        case "G": {
          break;
        }
        default: {
          showMessage("Unhandled Sub Type", failed);
          break;
        }
      }
      if (typeof cb === "function") {
        cb();
      }
      dispatch({ type: SET_INCOME_RECORD, payload: data });
    } catch (error) {
      dispatch({ type: HIDE_LOADER });
      dispatch({
        type: EDIT_INCOME_RECORD_FAILED,
        payload: error || "Could Not Fetch Income Record !",
      });
    }
  };
};
export const getIncomeTypes = (data: any) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      let { adminLevelIncur } = getState().rights.wallet.incurIncome;
      dispatch({ type: SHOW_LOADER });
      let response = await getIncomeTypesService(data);
      let result = [];
      if (adminLevelIncur === false) {
        for (let i = 0; i < response.length; i++) {
          if (response[i].general == true) result.push(response[i]);
        }
      } else result = response;
      dispatch({
        type: GET_INCOME_TYPES,
        payload: result,
      });
      dispatch({ type: HIDE_LOADER });
    } catch (error) {
      dispatch({ type: GET_INCOME_TYPES_FAILED });
      dispatch({ type: HIDE_LOADER });
      console.log(error);
    }
  };
};
export const onChangeIncome = (what: any, val: any) => {
  let data = { what, val };
  return async (dispatch: Dispatch) => {
    try {
      let valType = typeof val;
      switch (valType) {
        case "object": {
          await dispatch({
            type: CHANGE_ADD_INCOME,
            payload: data,
          });
          if (val.value == "")
            await dispatch({
              type: REACT_SELECT_ERROR,
              payload: data,
            });
          break;
        }
        default: {
          dispatch({
            type: CHANGE_ADD_INCOME,
            payload: data,
          });
          break;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const getSubIncomeType = (incomeData: any) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      await dispatch({ type: CLEAR_ADD_INCOME });
      const { user } = getState();
      switch (incomeData.sub) {
        case "B": {
          dispatch({ type: GET_SUB_TYPES, payload: user.branches });
          break;
        }
        case "A": {
          let body = {
            categoryId: incomeData.category,
            company: user.company._id,
          };

          let response = await listAssetType(body);
          dispatch({ type: SET_ASSET_TYPES, payload: response });
          break;
        }
        case "C": {
          let body = {
            categoryId: incomeData.category,
            company: user.company._id,
          };

          let response = await listAssetType(body);
          dispatch({ type: SET_ASSET_TYPES, payload: response });
          break;
        }
        default:
          break;
      }
    } catch (error) {
      showMessage("Could not fetch Sub Types", failed);
      console.log("error: ", error);
    }
  };
};
export const fetchAssetTypes = (id: any) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      let data: { [k: string]: any } = {};
      data["assetType"] = id;
      data["company"] = getState().user.company._id;
      let response = await getAssetBalance(data);
      dispatch({ type: GET_ASSET_BAL, payload: response });
    } catch (error) {
      showMessage("Could not fetch Asset", failed);
      console.log("error: ", error);
    }
  };
};
export const resetIncurIncome = () => ({
  type: RESET_INCOME_RECORD,
});
export const deleteIncomeRecord = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch({ type: SHOW_LOADER });
      let id = getState().income.getIn(["postIncomeRecord", "id"]);
      let company = getState().user.company._id;
      let { balance } = await deletedIncomeRecord(id, company);
      if (getState().user.uid == balance.user) {
        dispatch(updateBalance(balance.balance, balance));
      }
      await dispatch(updatePastIncomes(null, { remove: true, id }));
      dispatch({ type: HIDE_LOADER });
    } catch (error) {
      dispatch({ type: HIDE_LOADER });
      console.log("error: ", error);
    }
  };
};
export const editIncomeRecord = (data: any) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      let isValid;
      dispatch({ type: SHOW_LOADER });
      try {
        isValid = validateIncome(final_Incur_Income_Validation, data);
        if (typeof isValid == "string") throw "Validation Error";
      } catch (error) {
        dispatch({ type: EDIT_INCOME_RECORD_FAILED, payload: isValid });
      }
      if (typeof isValid == "string") throw "Escaped Error";
      let response = await editIncomeRecordService(
        jsonToFormdata(data),
        data.id
      );
      await dispatch(hideLoader());
      let { balance, record } = response;
      if (!isNaN(balance.balance)) {
        const { user, sub, entity } = balance;

        if (user == getState().user.uid) {
          await dispatch(updateBalance(balance.balance, { sub, entity }));
        }
      }
      await dispatch(
        updatePastIncomes(record, {
          id: record.id,
          update: true,
        })
      );
      await dispatch({ type: EDIT_INCOME_RECORD_SUCCESS });
    } catch (error:any) {
      dispatch({ type: HIDE_LOADER });
      if (error && error.message && error.message) {
        let { message: err } = error;
        let temp = Object.keys(err),
          key = temp[temp.length - 1];
        showMessage(err[key], failed);
      } else {
        showMessage(error, failed);
      }
    }
  };
};
export const IncurIncomeValdiation = (data: any) => ({
  type: CHECK_INCUR_INCOME_VALIDATION,
  payload: data,
});
export const updatePastIncomes = (data: any, options?: any) => ({
  type: UPDATE_PAST_INCOMES,
  payload: data,
  options: options,
});
export const sendIncomeRecord = (data: any) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch({ type: SHOW_LOADER });
      let isValid;
      let income = getState().income;
      try {
        isValid = validateIncome(final_Incur_Income_Validation, data);
        if (typeof isValid == "string") {
          throw "Validation Error";
        }
      } catch (error) {
        dispatch({
          type: POST_INCOME_RECORD_FAILED,
          payload: isValid,
        });
      }
      if (typeof isValid == "string") throw isValid;
      try {
        let { balance, income: freshRecord } = await postIncomeRecord(
          jsonToFormdata(data)
        );
        let res = await AgentList();
        console.log("res", res);
        try {
          if (getState().user.uid == balance.user) {
            dispatch(updateBalance(balance.balance, balance));
          }
        } catch (err : any) {
          console.log("err", err);
        }
        dispatch(
          updatePastIncomes({
            ...freshRecord,
            name: income.getIn(["postIncomeRecord", "ref", "label"]),
          })
        );
      } catch (err:any) {
        throw extractHandler(err);
      }
      dispatch({ type: HIDE_LOADER });
      dispatch({
        type: POST_INCOME_RECORD_SUCCESS,
      });
    } catch (error) {
      if (typeof error === "string") toast.error(error);
      dispatch({ type: HIDE_LOADER });
    }
  };
};
export const setPastIncomes = (
  user: any,
  entity: string,
  loginType: string
) => async (dispatch: Dispatch) => {
  try {
    await dispatch({
      type: SET_PAST_INCOMES,
      payload: await getPastIncomesService(user, entity, loginType),
    });
  } catch (err:any) {
    showMessage(extractHandler(err), failed);
    console.log(err);
  }
};
