import React from "react";
import { Alert } from "../../../../components/UI";
import { AlertProps } from "../../../../components/UI/Feedback/alert";

type NoDataFoundProps = AlertProps & {
  content?: string;
};

const NoDataFound = ({ content, severity = "danger" }: NoDataFoundProps) => {
  return (
    // <div className={`text-center fs-link fw-600 p-1 shadow-small border-white | mt-8 ${props?.className}`}>
    //   {" "}
    //   {props?.content ? `${props?.content}` : `No Data Found`}
    // </div>
    <Alert severity={severity} maxWidth="max-content">
      {content ? `${content}` : `No Data Found`}
    </Alert>
  );
};

export const NoDataFoundContainer = ({
  content,
  severity = "danger",
  children,
  ...props
}: NoDataFoundProps) => {
  return (
    <div className="p-1 flex-center">
      <Alert severity={severity} maxWidth="max-content" {...props}>
        {content ? `${content}` : `No Data Found`}
      </Alert>
      {children}
    </div>
  );
};

export default NoDataFound;
