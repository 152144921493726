import React from "react";
import "./static/track.css";

const TrackBar = (props: any) => {
  try {
    console.log("track bar props : ", props);
    let { statuses, active, canceled, cancelPending ,unique } = props;
    let classFlag = `trackbar_done ${props.mobileView ?"Mob_View":""}`;
    return canceled ? (
      <div className={props.mobileView ? "trackbar_mobile" : "trackbar_main"}>
        <div key={0} className={classFlag}>
          Booked
        </div>
        <div key={1} className="trackbar_cancel">
          Canceled
        </div>
      </div>
    ) : cancelPending ? (
      <div className={props.mobileView ? "trackbar_mobile" : "trackbar_main"}>
        <div key={0} className={classFlag}>
          Booked
        </div>
        <div key={1} className="trackbar_cancelPending">
          Waiting for Cancellation
        </div>
      </div>
    ) : active === "Missing Request" ? (
      <div className={props.mobileView ? "trackbar_mobile" : "trackbar_main"}>
        <div key={0} className={classFlag}>
          Booked
        </div>
        <div key={1} className="trackbar_cancelPending">
          Missing Request
        </div>
      </div>
    ) : active === "Missing" ? (
      <div className={props.mobileView ? "trackbar_mobile" : "trackbar_main"}>
        <div key={0} className={classFlag}>
          Booked
        </div>
        <div key={1} className="trackbar_cancel">
          Missing
        </div>
      </div>
    ) : (
      <div className={props.mobileView ? "trackbar_mobile" : "trackbar_main"}>
        {statuses.map((item: any, i: number) => {
          let cl = classFlag;
          if (item == active) classFlag = `trackbar_todo ${props.mobileView ?"Mob_View":""}`;
          return (
            <div key={i} className={cl}>
              {unique === "uniqueDesign" ? 
              "" : 
                item
              }
            </div>
          );
        })}
      </div>
    );
  } catch (err : any) {
    console.log(err);
    return null;
  }
};

export default TrackBar;
