import React from "react";
import {
  Dialog as DialogConitner,
  DialogContent as DialogContentContainer,
  DialogTitle as DialogTitleContainer,
  DialogActions as DialogActionsContainer,
} from "@mui/material";
// import DialogConitner from "../ReactModel/Dialog"

interface IProps {
  open: boolean;
  onClose?: () => void;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  children?: any;
  fullWidth?: boolean;
  style?: any;
  TransitionComponent?: any;
  fullScreen?: any;
  PaperProps?: any;
}
interface DialogTitleProps {
  children?: any;
  style?: any;
  id?: any;
}
interface DialogContentProps {
  children?: any;
  style?: any;
  className?: any;
}

interface DialogActionProps {
  children?: any;
  style?: any;
}

export const Dialog = (props: IProps) => {
  return (
    <DialogConitner
      open={props.open}
      onClose={props.onClose}
      maxWidth={props.maxWidth}
      fullWidth={props.fullWidth}
      style={props.style}
      TransitionComponent={props.TransitionComponent}
      // PaperProps={props.PaperProps}
      fullScreen={props.fullScreen}
    >
      {props.children}
    </DialogConitner>
  );
};

export const DialogTitle = (props: DialogTitleProps) => {
  return (
    <DialogTitleContainer style={{ ...props.style, backgroundColor: 'var(--clr-primary-400)', color: 'white', textAlign: "center" }} id={props.id}>
      <div style={{ color: 'white' }} className="fs-popup-heading">{props.children}</div>
    </DialogTitleContainer>
  );
};

export const DialogContent = (props: DialogContentProps) => {
  return (
    <DialogContentContainer className={`${props.className} bg-white-800 border-bottom-white`} style={props.style}>
      {props.children}
    </DialogContentContainer>
  );
};

export const DialogActions = (props: DialogActionProps) => {
  return (
    <DialogActionsContainer style={props.style} className="bg-primary-400">
      {props.children}
    </DialogActionsContainer>
  );
};
