import React, {
  type ComponentProps,
  createContext,
  useContext,
  Children,
  isValidElement,
  cloneElement,
} from "react";
import { CSSPositions } from "../../../types/common";
import "./radio.css";

type RadioGroupProps = {
  name: string;
} & ComponentProps<"div">;

type RadioGroupContextType = {
  name: string;
};

const RadioGroupContext = createContext<RadioGroupContextType | null>(null);

export function RadioGroup({ name, children, ...props }: RadioGroupProps) {
  return (
    <RadioGroupContext.Provider value={{ name }}>
      <div {...props}>
        {Children.map(children, (child) => {
          if (isValidElement(child)) {
            return cloneElement(child);
          }
          return null;
        })}
      </div>
    </RadioGroupContext.Provider>
  );
}

type RadioProps = {
  label?: string;
  labelPosition?: CSSPositions;
} & ComponentProps<"input">;
export function Radio({
  label,
  labelPosition = "left",
  id,
  ...props
}: RadioProps) {
  const context = useContext(RadioGroupContext);

  if (!context) {
    throw new Error("Radio must be used within a RadioGroup");
  }
  const { name } = context;

  return (
    <div
      className={`taptap-radio-container taptap-radio-container-${labelPosition}`}
    >
      {label && <label htmlFor={`taptap-radio-label-${id}`} className="user-select-none">{label}</label>}
      <input
        name={name}
        type="radio"
        id={`taptap-radio-label-${id}`}
        {...props}
      />
    </div>
  );
}

Radio.displayName = "Radio";
