const prefix = "ROUTE:";

export const CHANGE_SIMPLE = prefix + "CHANGE_SIMPLE";
export const CHANGE_DESTINATION = prefix + "CHANGE_DESTINATION";
export const ADD_DESTINATION = prefix + "ADD_DESTINATION";
export const REMOVE_DESTINATION = prefix + "REMOVE_DESTINATION";
export const CLEAR_ROUTE = prefix + "CLEAR_ROUTE";
export const SET_ROUTE = prefix + "SET_ROUTE";
export const SET_COMPANY_ROUTES = prefix + "SET_COMPANY_ROUTES";

export const GET_ROUTES = prefix + "GET_ROUTES";

export const LIST_ROUTES = prefix + "LIST_ROUTES";
export const UPDATE_ROUTE = prefix + "UPDATE_ROUTE";
export const CREATE_ROUTE = prefix + "CREATE_ROUTE";

export const ADD_PACK_SIZE = prefix + "ADD_PACK_SIZE";
export const DELETE_PACK_SIZE = prefix + "DELETE_PACK_SIZE";
