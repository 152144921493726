import React, { useState , useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from "../../../Components/Dialog/DialogComponent";
import { ButtonDefault } from "../../../Components/Button/Buttoncontainer";
import { ButtonPrimary } from '../../../Components/Button/Buttoncontainer';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { useMediaQuery, useTheme } from "@mui/material";

const PackageForm = (props:any) => {
  const [isDialogOpen,setIsDialogOpen] = useState(false);
  const [materialTypeList, setMaterialTypeList] = useState([]) 
  const [packingTypeList,setPackingTypeList] = useState([])
  const [boxSizeTypeList,setBoxSizeTypeList] = useState([])
  const [unitTypeList,setUnitTypeList] = useState([])
  const [formDetails] = useState(false)
  const [packageCount,setPackageCount] = useState([{}])
  const [formTitle,setFormTitle] = useState("") 
  const [residentialType,setResidentialType] = useState("");
  const [couriertype,setCouriertype] = useState([]);
  const [QtyTotal,setQtyTotal] = useState(0);
  const [WeightTotal,setWeightTotal] = useState(0);
  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2

  useEffect(()=>{
    props.titleName? setFormTitle(props.titleName) : null
  },[formTitle])

  useEffect(() => {
    setPackageCount([{}])
    defineMaterialType();
    definePackingType();
    defineBoxSizwType();
    defineUnitType();
    definingResidentialType()
  }, [formDetails]);

  const defineMaterialType = ()=>{
    const MaterialTypesArr:any = [{id:1,name:"Concrete Blocks"},{id:2,name:"Steel Material"},{id:3,name:"Cloth Material"},{id:4,name:"Others"}]
    setMaterialTypeList(MaterialTypesArr)
  }

  const definingResidentialType = ()=>{
    const ResidentialTypesArr:any = [{id:1,name:"1 BHK"},{id:2,name:"2 BHK"},{id:3,name:"3 BHK"},{id:4,name:"Others"}]
    setCouriertype(ResidentialTypesArr)
  }

  const definePackingType = ()=>{
    const PackingTypesArr:any = [{id:1,name:"Black Wire"},{id:2,name:"Bag"},{id:3,name:"Coffe Bag"},{id:4,name:"Bora Packing"},{id:5,name:"Others"}]
    setPackingTypeList(PackingTypesArr)
  }

  const defineBoxSizwType = ()=>{
    const BoxSizeTypesArr:any = [{id:1,name:"Small"},{id:2,name:"Medium"},{id:3,name:"Large"},{id:4,name:"Extra Large"},{id:5,name:"Others"}]
    setBoxSizeTypeList(BoxSizeTypesArr)
  }

  const defineUnitType = ()=>{
    const UnitTypesArr:any = [{id:1,name:"Meter"},{id:2,name:"Centi Meter"},{id:3,name:"Inches"},{id:4,name:"Others"}]
    setUnitTypeList(UnitTypesArr)
  }

  const handlePackageAdd = ()=>{
      setPackageCount([...packageCount,{}])
  }

  const handlePackageRemove = (i:any)=>{
    const newPackage = [...packageCount]
    newPackage.splice(i,1)
    setPackageCount(newPackage)
  }

  const handleChange = (key:string, val:any, i: number) => {
      if(key === "Qty"){
        const count:number = 0
          const summation = packageCount.reduce(
            (accumulator:any, current:any , index:any) => {
                if(index === i){
                    return accumulator + +(val);
                }
                else{
                    return accumulator + +(current.Qty) 
                }
            },count
           );
          setQtyTotal(summation)
      }
      if(key === "Weight"){
        const count:number = 0
          const summation = packageCount.reduce(
            (accumulator:any, current:any , index:any) => {
                if(index === i){
                    return accumulator + +(val);
                }
                else{
                    return accumulator + +(current.Weight) 
                }
            },count
           );
           setWeightTotal(summation)
      }
    let newPackage = packageCount.map((pkg:any, ii: number) => {
        if(i === ii){
        return {...pkg, [key]: val}
      }else{
        return pkg  
      }
    })
    setPackageCount(newPackage)
  }

  return (
    <>
        {mobileView ? 
            <div className={formTitle === "intracity" || formTitle === "Residential P&M" ? "row commonPkg mt10Mobile ml15Mobile" : formTitle === "Double Table Int" ? "row mt10Mobile w89 ml-15Mobile" : "row w85 ml-15Mobile mt10Mobile"}>
                <button 
                    className={(formTitle === "intracity" || formTitle === "Residential P&M" ? "form-control form_one mt0" : "form-control ml15 w94")}
                    onClick={()=>{setIsDialogOpen(!isDialogOpen)}}
                    > 
                        Add Package Details <span className='pkgbtn'> {" "}+ </span>
                </button>
                {/* Popup content Started */}
                <>
                    <Dialog
                        open={isDialogOpen}
                        onClose={() => setIsDialogOpen(false)}
                        maxWidth={"lg"}
                        >
                        <div className='tac bold'>
                            <DialogTitle
                            id="draggable-dialog-title"
                            >
                            Add Package Info
                            </DialogTitle>
                        </div>
                        {formTitle === "Residential P&M" && (
                            <>
                            <div className="row residentType" >
                                <select
                                id="inputState"
                                className="form-control form_one"
                                value={residentialType}
                                onChange={(e) => {
                                setResidentialType(e.target.value);
                                }}
                            >
                                <option label="Select any one type" value="" />
                                {couriertype.map((c: any) => (
                                <option
                                    key={c.id}
                                    label={c.name}
                                    value={c.name}
                                />
                                ))}
                            </select>

                            </div>
                            <hr className='hr'/>
                            </>
                        )}
                        {packageCount.map((val:any,i:any)=>{
                            return(
                            <DialogContent
                                key={i}
                                >
                                <div className='pkgdetails'>
                                <input
                                    type="number"
                                    value={val.quantity}
                                    onChange={(e) => {
                                    handleChange("Qty",e.target.value,i)
                                    }}
                                    className="form-control form_one qty"
                                    id="inputZip"
                                    placeholder="Qty"
                                    required
                                    />
                                <select
                                    id="inputState"
                                    className="form-control form_one material"
                                    value={val.material}
                                    onChange={(e) => {
                                        handleChange("MaterialType",e.target.value,i)
                                    }}
                                    >
                                    <option label="Material type" value="" />
                                    {materialTypeList.map((c: any) => (
                                        <option
                                        key={c.id}
                                        label={c.name}
                                        value={c.name}
                                        />
                                        ))}
                                </select> 
                                <select
                                    id="inputState"
                                    className="form-control form_one material"
                                    value={val.packing}
                                    onChange={(e) => {
                                        handleChange("PackingType",e.target.value,i)
                                    }}
                                    >
                                    <option label="Packing type" value="" />
                                    {packingTypeList.map((c: any) => (
                                        <option
                                        key={c.id}
                                        label={c.name}
                                        value={c.name}
                                        />
                                        ))}
                                </select>
                                <select
                                    id="inputState"
                                    className="form-control form_one material"
                                    value={val.sizeofBoxes}
                                    onChange={(e) => {
                                        handleChange("BoxesType",e.target.value,i)
                                    }}
                                    >
                                    <option label="Boxes type" value="" />
                                    {boxSizeTypeList.map((c: any) => (
                                        <option
                                        key={c.id}
                                        label={c.name}
                                        value={c.name}
                                        />
                                        ))}
                                </select>
                                <input
                                    type="number"
                                    value={val.weight}
                                    onChange={(e) => {
                                    handleChange("Weight",e.target.value,i)
                                    }}
                                    className="form-control form_one weight"
                                    id="inputZip"
                                    placeholder="Weight (KG)"
                                    required
                                />
                                <select
                                    id="inputState"
                                    className="form-control form_one unit"
                                    value={val.unit}
                                    onChange={(e) => {
                                        handleChange("UnitType",e.target.value,i)
                                    }}
                                    >
                                    <option label="Unit" value="" />
                                    {unitTypeList.map((c: any) => (
                                        <option
                                        key={c.id}
                                        label={c.name}
                                        value={c.name}
                                        />
                                        ))}
                                </select>
                                <div className='LBHBox'>
                                    <ul
                                        className="nav nav-tabs nav-justified flexWrap lbhMobileVersion"
                                        role="tablist"
                                        >
                                        <li>
                                        <input
                                            type="number"
                                            value={val.Length}
                                            onChange={(e) => {
                                            handleChange("Length",e.target.value,i)
                                            }}
                                            id="inputZip"
                                            placeholder="L"
                                            className='lbh'
                                            required
                                            />
                                        </li>
                                        <li className='x'>
                                        X
                                        </li>
                                        <li>
                                        <input
                                            type="number"
                                            value={val.breadth}
                                            onChange={(e) => {
                                                handleChange("Breadth",e.target.value,i)
                                            }}
                                            id="inputZip"
                                            placeholder="B"
                                            className='lbh'
                                            required
                                            />
                                        </li>
                                        <li className='x'>
                                            X
                                        </li>
                                        <li>
                                        <input
                                            type="number"
                                            value={val.height}
                                            onChange={(e) => {
                                            handleChange("Height",e.target.value,i)
                                            }}
                                            id="inputZip"
                                            placeholder="H"
                                            className='lbh'
                                            required
                                            />
                                        </li>
                                    </ul>
                                </div>
                                <hr className='hr'/>
                                <div className='pkgIcon'>
                                    {i === packageCount.length - 1 && i < 2 &&
                                    <div className='ml5'>
                                        <AiOutlinePlus onClick={()=>{handlePackageAdd()}} className="mt22 mt0Mobile"/>
                                    </div>
                                    }
                                    {i !== 0 && 
                                    <div className='ml5'>
                                        <AiOutlineMinus onClick={()=>{handlePackageRemove(i)}} className="mt22 mt0Mobile ml10Mobile"/>
                                    </div>
                                    }
                                </div>
                                </div>
                                <hr className='hrEnd'/>
                            </DialogContent>
                            )
                        })}
                        
                        <DialogActions>
                                <div className='flexMobile'>
                                    <div className='ml1'>
                                        Total Quantity :- {QtyTotal}
                                    </div>
                                    <div className='ml1'>
                                        Total Weight :- {WeightTotal} KG
                                    </div>
                                </div>
                                <div className='pkgbtnMobile'>
                                    <ButtonPrimary
                                        onClick={() => {
                                            props.val(true)
                                            props.pacgeArr(packageCount)
                                        }
                                    }
                                    className=""
                                    >
                                        Submit
                                    </ButtonPrimary>
                                    {/* )} */}
                                    <ButtonDefault
                                    className=""
                                    onClick={() => setIsDialogOpen(false)}
                                    >
                                    close
                                    </ButtonDefault>
                                </div>
                        </DialogActions>
                    </Dialog>
                </>
                    {/* Popup content Ended */}   
            </div>
            :
            <div className={formTitle === "intracity" || formTitle === "Residential P&M" ? "row commonPkg mt10Mobile ml0Mobile" : formTitle === "Double Table Int" ? "row mt10Mobile w89 ml-15Mobile" : "row w85 ml-15Mobile mt10Mobile"}>
                    <button 
                        className={(formTitle === "intracity" || formTitle === "Residential P&M" ? "form-control form_one mt0" : "form-control ml15 w94")}
                        onClick={()=>{setIsDialogOpen(!isDialogOpen)}}
                        > 
                            Add Package Details <span className='pkgbtn'> {" "}+ </span>
                    </button>
                {/* Popup content Started */}
                <>
                    <Dialog
                        open={isDialogOpen}
                        onClose={() => setIsDialogOpen(false)}
                        maxWidth={"lg"}
                        >
                        <div className='tac bold extraDesign'>
                            <DialogTitle
                            id="draggable-dialog-title"
                            >
                            Add Package Info
                            </DialogTitle>
                        </div>
                        {formTitle === "Residential P&M" && (
                            <>
                            <div className="row residentType" >
                                <select
                                id="inputState"
                                className="form-control form_one"
                                value={residentialType}
                                onChange={(e) => {
                                setResidentialType(e.target.value);
                                }}
                            >
                                <option label="Select any one type" value="" />
                                {couriertype.map((c: any) => (
                                <option
                                    key={c.id}
                                    label={c.name}
                                    value={c.name}
                                />
                                ))}
                            </select>

                            </div>
                            <hr className='hr'/>
                            </>
                        )}
                        {packageCount.map((val:any,i:any)=>{
                            return(
                            <DialogContent
                                key={i}
                                >
                                <div className='pkgdetails'>
                                <input
                                    type="number"
                                    value={val.quantity}
                                    onChange={(e) => {
                                    handleChange("Qty",e.target.value,i)
                                    }}
                                    className="form-control form_one qty"
                                    id="inputZip"
                                    placeholder="Qty"
                                    required
                                    />
                                <select
                                    id="inputState"
                                    className="form-control form_one material"
                                    value={val.material}
                                    onChange={(e) => {
                                        handleChange("MaterialType",e.target.value,i)
                                    }}
                                    >
                                    <option label="Material type" value="" />
                                    {materialTypeList.map((c: any) => (
                                        <option
                                        key={c.id}
                                        label={c.name}
                                        value={c.name}
                                        />
                                        ))}
                                </select> 
                                <select
                                    id="inputState"
                                    className="form-control form_one material"
                                    value={val.packing}
                                    onChange={(e) => {
                                        handleChange("PackingType",e.target.value,i)
                                    }}
                                    >
                                    <option label="Packing type" value="" />
                                    {packingTypeList.map((c: any) => (
                                        <option
                                        key={c.id}
                                        label={c.name}
                                        value={c.name}
                                        />
                                        ))}
                                </select>
                                <select
                                    id="inputState"
                                    className="form-control form_one material"
                                    value={val.sizeofBoxes}
                                    onChange={(e) => {
                                        handleChange("BoxesType",e.target.value,i)
                                    }}
                                    >
                                    <option label="Boxes type" value="" />
                                    {boxSizeTypeList.map((c: any) => (
                                        <option
                                        key={c.id}
                                        label={c.name}
                                        value={c.name}
                                        />
                                        ))}
                                </select>
                                <input
                                    type="number"
                                    value={val.weight}
                                    onChange={(e) => {
                                    handleChange("Weight",e.target.value,i)
                                    }}
                                    className="form-control form_one weight"
                                    id="inputZip"
                                    placeholder="Weight (KG)"
                                    required
                                />
                                <select
                                    id="inputState"
                                    className="form-control form_one unit"
                                    value={val.unit}
                                    onChange={(e) => {
                                        handleChange("UnitType",e.target.value,i)
                                    }}
                                    >
                                    <option label="Unit" value="" />
                                    {unitTypeList.map((c: any) => (
                                        <option
                                        key={c.id}
                                        label={c.name}
                                        value={c.name}
                                        />
                                        ))}
                                </select>
                                <div className='LBHBox'>
                                    <ul
                                        className="nav nav-tabs nav-justified flexWrap"
                                        role="tablist"
                                        >
                                        <li>
                                        <input
                                            type="number"
                                            value={val.Length}
                                            onChange={(e) => {
                                            handleChange("Length",e.target.value,i)
                                            }}
                                            id="inputZip"
                                            placeholder="L"
                                            className='lbh'
                                            required
                                            />
                                        </li>
                                        <li>
                                        X
                                        </li>
                                        <li>
                                        <input
                                            type="number"
                                            value={val.breadth}
                                            onChange={(e) => {
                                                handleChange("Breadth",e.target.value,i)
                                            }}
                                            id="inputZip"
                                            placeholder="B"
                                            className='lbh'
                                            required
                                            />
                                        </li>
                                        <li>
                                            X
                                        </li>
                                        <li>
                                        <input
                                            type="number"
                                            value={val.height}
                                            onChange={(e) => {
                                            handleChange("Height",e.target.value,i)
                                            }}
                                            id="inputZip"
                                            placeholder="H"
                                            className='lbh'
                                            required
                                            />
                                        </li>
                                    </ul>
                                </div>
                                {i === packageCount.length - 1 && i < 2 &&
                                <div className='ml5'>
                                    <AiOutlinePlus onClick={()=>{handlePackageAdd()}} className="mt22"/>
                                </div>
                                }
                                {i !== 0 && 
                                <div className='ml5'>
                                    <AiOutlineMinus onClick={()=>{handlePackageRemove(i)}} className="mt22"/>
                                </div>
                                }
                                </div>
                            </DialogContent>
                            )
                        })}
                        <DialogActions>
                            <div className='dflex w100'>
                                <div className='ml1'>
                                    Total Quantity :- {QtyTotal}
                                </div>
                                <div className='ml1'>
                                    Total Weight :- {WeightTotal} KG
                                </div>
                                <ButtonPrimary
                                    onClick={() => {
                                        props.val(true)
                                        props.pacgeArr(packageCount)
                                    }
                                    }
                                    className="mr5 ml50"
                                >
                                    Submit
                                </ButtonPrimary>
                                {/* )} */}
                                <ButtonDefault
                                className="mr5"
                                onClick={() => setIsDialogOpen(false)}
                                >
                                close
                                </ButtonDefault>
                            </div>
                        </DialogActions>
                    </Dialog>
                </>
                    {/* Popup content Ended */}   
            </div>
        }
    </>
  )
}

export default PackageForm