import React, { Fragment } from "react";
import { 
    // withRouter, 
    Link 
} from "react-router-dom";
import "./../css/footer.css"
import { FaFacebookF,FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { useMediaQuery, useTheme } from "@mui/material";

const Footer = () => {
    
    const theme = useTheme();
    const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
    const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
    const mobileView = isMobile1 || isMobile2

    return (
        <Fragment>
            {mobileView ?
                <div className="mobileViewContainer">

                    <div className="socialLinksHeadContainer">
                        <div>
                            <a href="https://www.facebook.com/KabraExpressLogistics">
                                <FaFacebookF className="faceBookDiv" />
                            </a>
                        </div>
                        <div>
                            <a href="https://www.linkedin.com/company/kabra-express-logistics-private-limited/">
                                <FaLinkedinIn className="linkedInDiv" />
                            </a>
                        </div>
                        <div>
                            <a href="https://www.instagram.com/kabra.express/">
                                <img
                                    src="Instagram-logo-transparent-PNG.png"
                                    alt=""
                                    className="instagramDiv"
                                />
                            </a>
                        </div>
                        <div>
                            <a href="https://www.youtube.com/channel/UCt4wx_zJAyQ-el--Jy3iurQ">
                                <FaYoutube className="youtubeDiv" />
                            </a>
                        </div>
                    </div>

                    <div className="appLinksContainer">
                        <a href="https://play.google.com/store/apps/details?id=in.taptap.twa&pcampaignid=web_share" target={!mobileView ? "_blank" : ""}>
                            <img
                                src="google_play_store_logo.png"
                                alt=""
                                className="appIconImageCss"
                            />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=in.taptap.twa&pcampaignid=web_share" target={!mobileView ? "_blank" : ""}>
                            <img
                                src="apple_store_logo.png"
                                alt=""
                                className="appIconImageCss"
                            />
                        </a>
                    </div>

                    <div className="poweredByText">
                        <strong>
                            {/* Powered by{" "} */}
                            <img src="kabraExpress_logo.png" className="kabraExpresslogo" />{" "}
                            {/* <br/> */}
                            {/* Kabra Express Logistics Pvt. Ltd. */}
                        </strong>
                    </div>
                </div>
                :
                <div>
                    {/* <footer id="footer" className="footerNew">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-6 col-md-2">
                                    <div>
                                        <div className="widget">
                                            <h5>TapTap</h5>
                                            <ul>
                                                <li>
                                                    <Link to="/aboutUs">About Us</Link>
                                                </li>
                                                <li>
                                                    <Link to="/contact-us">
                                                        Contact Us
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/careers">Careers</Link>
                                                </li>
                                                <li>
                                                    <Link to="/companyDetails">
                                                        Company Details
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-2">
                                    <div>
                                        <div className="widget">
                                            <h5>Resources</h5>
                                            <ul>
                                                <li>
                                                    <Link to="/privacy-and-policy">
                                                        Privacy Policy
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/useragreement">
                                                        User Agreement
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/terms-and-condition">
                                                        T & C
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/faq">FAQ</Link>
                                                </li>
                                                <li>
                                                    <Link to="/blog">Blog</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-2">
                                    <div>
                                        <div className="widget">
                                            <h5>Others</h5>
                                            <ul>
                                                <li>
                                                    <Link to="/customer">
                                                        Join Us as a Customer
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/partners">
                                                        Became a Partner
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/kabra-express/our-networks">
                                                        Our Networks
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-6">
                                    <div>
                                        <div className="widget">
                                            <img
                                                src="../img/tap8.png"
                                                alt=""
                                                width="120"
                                                height="30"
                                            />
                                            <p style={{ paddingTop: "15px" }}>
                                                Transport management software for
                                                increasing efficiency and effectiveness
                                                by digitizing various business
                                                functions. Additionally Inter-company
                                                transaction management system for
                                                participating stake-holders to expand
                                                our scope and coverage.
                                            </p>
                                            <div style={{border:"0.2px solid transparent",marginTop:"10%" , display : "flex" , justifyContent : "space-evenly"}}>
                                                <div style={{border : "0.2px solid transparent" , width : "40%"}}>
                                                    <h5>Follow us</h5>
                                                    <ul className="company-social">
                                                        <li className="social-facebook">
                                                            <a href="https://www.facebook.com/KabraExpressLogistics">
                                                                <i className="fa fa-facebook"></i>
                                                            </a>
                                                        </li>
                                                        <li className="social-twitter">
                                                            <a href="https://www.linkedin.com/company/kabra-express-logistics-private-limited/">
                                                                <i className="fa fa-linkedin"></i>
                                                            </a>
                                                        </li>
                                                        <li className="social-youtube">
                                                            <a href="https://www.instagram.com/kabra.express/">
                                                                <i className="fa fa-instagram"></i>
                                                            </a>
                                                        </li>
                                                        <li className="social-youtube">
                                                            <a href="https://www.youtube.com/channel/UCt4wx_zJAyQ-el--Jy3iurQ">
                                                                <i className="fab fa-youtube"></i>
                                                            </a>
                                                        </li>
                                                        <li className="social-twitter">
                                                            <a href="#">
                                                                <i className="fa fa-twitter"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div style={{border : "0.2px solid transparent" , width : "60%" , display : "flex" , justifyContent : "space-evenly" }}>
                                                    <p style={{width : "45%" , margin : "auto" , border : "0.2px solid transparent" , padding : "1%"}}>
                                                        <a href="https://play.google.com/store/apps/details?id=in.taptap.twa&pcampaignid=web_share" target={!mobileView ? "_blank" : ""}>
                                                            <img
                                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1yBMWWIz5S5ERBdXK0Rt4ol8j60fBGDZ_otPHPUD60J6POiK5uRKk-FX-707fcRWcbA&usqp=CAU"
                                                                alt=""
                                                                width="100%"
                                                                height="50"
                                                            />
                                                        </a>
                                                    </p>
                                                    <p style={{width : "45%" , margin : "auto" , border : "0.2px solid transparent" , padding : "1%"}}>
                                                        <a href="https://play.google.com/store/apps/details?id=in.taptap.twa&pcampaignid=web_share" target={!mobileView ? "_blank" : ""}>
                                                            <img
                                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHv8tvpDNzj-ypdph5NmAvGitQ_dij2WfyB56K6_r_QzhOfLk6VN3BD6DH8c7qDRJJSo8&usqp=CAU"
                                                                alt=""
                                                                width="100%"
                                                                height="50"
                                                            />
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 text-center poweredByText">
                                    <strong>
                                        Powered by{" "}
                                        Kabra Express Logistics Private Limited
                                    </strong>
                                </div>
                            </div>
                        </div>
                    </footer> */}

                    <div id="newfooter">
                        <div className="headcontainer">
                            <div className="containerWritten">
                                <div className="taptapIntroDiv commonClassCss">
                                    <img
                                        src="../img/tap8.png"
                                        alt=""
                                        className="imageOfTapTap"
                                    />
                                    <div className="tapatpintroContent">
                                        Transport management software for
                                        increasing efficiency and effectiveness
                                        by digitizing various business
                                        functions. Additionally Inter-company
                                        transaction management system for
                                        participating stake-holders to expand
                                        our scope and coverage.
                                    </div>
                                </div>

                                <div className="resourcesDiv commonClassCss">
                                    <div className="resourcesTitle">Resources</div>
                                    <div className="resourcesListHeadDiv">
                                        <div>
                                            <Link to="/privacy-and-policy" className="linkCommon">
                                                Privacy Policy
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="/useragreement" className="linkCommon">
                                                User Agreement
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="/terms-and-condition" className="linkCommon">
                                                T & C
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="/faq" className="linkCommon">FAQ</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="othersDiv commonClassCss">
                                    <div className="othersTitle">Others</div>
                                    <div className="othersListHeadDiv">
                                        <div>
                                            <Link to="/contact-us" className="linkCommon">
                                                Contact Us
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="/careers" className="linkCommon">Careers</Link>
                                        </div>
                                        <div>
                                            <Link to="/companyDetails" className="linkCommon">
                                                Company Details
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="/kabra-express/our-networks" className="linkCommon">
                                                Our Networks
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                    <div className="appLinksDiv commonClassCss">
                                        <div className="socialLinksHeadDiv">
                                            <div>
                                                <a target="_blank" href="https://www.facebook.com/KabraExpressLogistics">
                                                    <FaFacebookF className="faceBookDiv"/>
                                                </a>
                                            </div>
                                            <div>
                                                <a target="_blank" href="https://www.linkedin.com/company/kabra-express-logistics-private-limited/">
                                                    <FaLinkedinIn className="linkedInDiv" />
                                                </a>
                                            </div>
                                            <div>
                                                <a target="_blank" href="https://www.instagram.com/kabra.express/">
                                                    <img 
                                                        src="https://image.similarpng.com/very-thumbnail/2020/06/Instagram-logo-transparent-PNG.png"
                                                        alt =""
                                                        className="instagramDiv"
                                                    />
                                                </a>
                                            </div>
                                            <div>
                                                <a target="_blank" href="https://www.youtube.com/channel/UCt4wx_zJAyQ-el--Jy3iurQ">
                                                    <FaYoutube className="youtubeDiv" />
                                                </a>
                                            </div>
                                            {/* <ul className="company-social socialLinksHeadDiv">
                                                <li className="social-facebook">
                                                    <a href="https://www.facebook.com/KabraExpressLogistics">
                                                        <i className="fa fa-facebook"></i>
                                                    </a>
                                                </li>
                                                <li className="social-twitter">
                                                    <a href="https://www.linkedin.com/company/kabra-express-logistics-private-limited/">
                                                        <i className="fa fa-linkedin"></i>
                                                    </a>
                                                </li>
                                                <li className="social-youtube">
                                                    <a href="https://www.instagram.com/kabra.express/">
                                                        <i className="fa fa-instagram"></i>
                                                    </a>
                                                </li>
                                                <li className="social-youtube">
                                                    <a href="https://www.youtube.com/channel/UCt4wx_zJAyQ-el--Jy3iurQ">
                                                        <i className="fab fa-youtube"></i>
                                                    </a>
                                                </li>
                                                <li className="social-twitter">
                                                    <a href="#">
                                                        <i className="fa fa-twitter"></i>
                                                    </a>
                                                </li>
                                            </ul> */}
                                    </div>
                                    <div className="appLinksContainer">
                                        <a href="https://play.google.com/store/apps/details?id=in.taptap.twa&pcampaignid=web_share" target={!mobileView ? "_blank" : ""}>
                                            <img
                                                src="google_play_store_logo.png"
                                                alt=""
                                                className="appIconImageCss"
                                            />
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=in.taptap.twa&pcampaignid=web_share" target={!mobileView ? "_blank" : ""}>
                                            <img
                                                src="apple_store_logo.png"
                                                alt=""
                                                className="appIconImageCss"
                                            />
                                        </a>
                                    </div>
                                    <div className="poweredByDiv">
                                        <img src="kabraExpress_logo.png" className="kabraExpresslogo" />{" "}
                                    </div>
                                </div>
                            </div>


                            {/* Tap Tap Row */}
                            {/* <div className="">
                                    <div>
                                        <div className="">
                                            <h5>TapTap</h5>
                                            <ul>
                                                <li>
                                                    <Link to="/aboutUs">About Us</Link>
                                                </li>
                                                <li>
                                                    <Link to="/contact-us">
                                                        Contact Us
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/careers">Careers</Link>
                                                </li>
                                                <li>
                                                    <Link to="/companyDetails">
                                                        Company Details
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}

                            {/* Resources Row */}
                            {/* <div className="">
                                    <div>
                                        <div className="">
                                            <h5>Resources</h5>
                                            <ul>
                                                <li>
                                                    <Link to="/privacy-and-policy">
                                                        Privacy Policy
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/useragreement">
                                                        User Agreement
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/terms-and-condition">
                                                        T & C
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/faq">FAQ</Link>
                                                </li>
                                                <li>
                                                    <Link to="/blog">Blog</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}

                            {/* Others Row */}
                            {/* <div className="">
                                    <div>
                                        <div className="">
                                            <h5>Others</h5>
                                            <ul>
                                                <li>
                                                    <Link to="/customer">
                                                        Join Us as a Customer
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/partners">
                                                        Became a Partner
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/kabra-express/our-networks">
                                                        Our Networks
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}

                            {/* Follow Us */}
                            {/* <div className="">
                                    <div style={{border:"0.2px solid transparent",marginTop:"10%" , display : "flex" , justifyContent : "space-evenly"}}>
                                        <div style={{border : "0.2px solid transparent" , width : "40%"}}>
                                            <h5>Follow us</h5>
                                            <ul className="company-social">
                                                <li className="social-facebook">
                                                    <a href="https://www.facebook.com/KabraExpressLogistics">
                                                        <i className="fa fa-facebook"></i>
                                                    </a>
                                                </li>
                                                <li className="social-twitter">
                                                    <a href="https://www.linkedin.com/company/kabra-express-logistics-private-limited/">
                                                        <i className="fa fa-linkedin"></i>
                                                    </a>
                                                </li>
                                                <li className="social-youtube">
                                                    <a href="https://www.instagram.com/kabra.express/">
                                                        <i className="fa fa-instagram"></i>
                                                    </a>
                                                </li>
                                                <li className="social-youtube">
                                                    <a href="https://www.youtube.com/channel/UCt4wx_zJAyQ-el--Jy3iurQ">
                                                        <i className="fab fa-youtube"></i>
                                                    </a>
                                                </li>
                                                <li className="social-twitter">
                                                    <a href="#">
                                                        <i className="fa fa-twitter"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div style={{border : "0.2px solid transparent" , width : "60%" , display : "flex" , justifyContent : "space-evenly" }}>
                                            <p style={{width : "45%" , margin : "auto" , border : "0.2px solid transparent" , padding : "1%"}}>
                                                <a href="https://play.google.com/store/apps/details?id=in.taptap.twa&pcampaignid=web_share" target={!mobileView ? "_blank" : ""}>
                                                    <img
                                                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1yBMWWIz5S5ERBdXK0Rt4ol8j60fBGDZ_otPHPUD60J6POiK5uRKk-FX-707fcRWcbA&usqp=CAU"
                                                        alt=""
                                                        width="100%"
                                                        height="50"
                                                    />
                                                </a>
                                            </p>
                                            <p style={{width : "45%" , margin : "auto" , border : "0.2px solid transparent" , padding : "1%"}}>
                                                <a href="https://play.google.com/store/apps/details?id=in.taptap.twa&pcampaignid=web_share" target={!mobileView ? "_blank" : ""}>
                                                    <img
                                                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHv8tvpDNzj-ypdph5NmAvGitQ_dij2WfyB56K6_r_QzhOfLk6VN3BD6DH8c7qDRJJSo8&usqp=CAU"
                                                        alt=""
                                                        width="100%"
                                                        height="50"
                                                    />
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div> */}

                            {/* Powered By Row */}
                            {/* <div className="">
                                    <strong>
                                        Powered by{" "}
                                        Kabra Express Logistics Private Limited
                                    </strong>
                                </div> */}
                        </div>
                    </div>

                    <a href="#" className="scrollup">
                        <i className="fa fa-angle-up active"></i>
                    </a>
                </div>
            }

            {/* As we have added a mobile menu in the bottom so that we are adding just height so that it can be scroll down perfectly */}
        <div style={{height: '56px'}}></div>

        </Fragment>
    );
};

export default
//  withRouter(
    Footer
// );
