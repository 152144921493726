import { ServiceMaker } from ".";

export const createPaymentTransaction = (data: any) =>
  ServiceMaker("CCAvenueTxns/user/create", "POST", data);

  export const listCCAvenueTxns = (data: any) =>
  ServiceMaker(`CCAvenueTxns/listTxns`, "POST",data);
  

  export const checkCCAvenueTxnsStatus = (data: any) =>
  ServiceMaker(`CCAvenueTxns/status`, "POST",data);
  
  export const countsCCAvenueTxns = (data: any) =>
  ServiceMaker(`CCAvenueTxns/countsTxns`, "POST",data);

  export const createCustomerCCAvenueTxn = (data: any) =>
  ServiceMaker("CCAvenueTxns/customer/create", "POST", data);
  
  export const settleOnlineTxnByPhoto = (data: any) =>
  ServiceMaker("delivery/settleCustomerPayment", "PATCH", data);
  
  export const settleDoorDeliveryOnlineTxnByPhoto = (data: any) =>
  ServiceMaker("delivery/settleCustomerPayment/DoorDelivery", "PATCH", data);
  
  export const BranchBookingMakePayment = (data: any) =>
  ServiceMaker("customerBooking/BranchBookingMakePayment","POST",data)

  export const refreshAllCCAvenueTxns = (data:any) =>
  ServiceMaker("CCAvenueTxns/refreshAllCCAvenueTxns","POST",data)

  
  export const uploadImageForPendingTxns = (data:any) =>
  ServiceMaker("CCAvenueTxns/uploadImageForPendingTxns","PATCH",data)

  export const verifyImageForPendingTxns = (data:any) =>
  ServiceMaker("CCAvenueTxns/verifyImageForPendingTxns","PATCH",data)