import {
    CALL_DOOR_DELIVERY_ACCEPTED_RECORDS_WITHOUT_OPTION_PRESSED,
    CALL_DOOR_DELIVERY_ACCEPTED_RECORDS_WITH_OPTION_PRESSED,
    CALL_DOOR_DELIVERY_PENDING_RECORDS,
    CALL_DOOR_DELIVERY_REJECTED_RECORDS,
    CALL_NORMAL_DELIVERY_ACCEPTED_RECORDS,
    CALL_NORMAL_DELIVERY_PENDING_RECORDS,
    CALL_NORMAL_DELIVERY_REJECTED_RECORDS,
    SMS_ACCEPTED_RECORDS,
    SMS_REJECTED_RECORDS
} from "../constants/calllAndSmsRecords";

const initSDtate: any = {
    smsAccepted: [],
    smsRejected: [],
    normalAcceptedCalls: {
        // timeStamp: null,
        data : []
    },
    normalPendingCalls: {
        // timeStamp: null,
        data : []
    },
    normalRejectedCalls: {
        // timeStamp: null,
        data : []
    },
    doorDelAcceptedWithOptionPressed: {
        // timeStamp: null,
        data : []
    },
    doorDelAcceptedWithoutOptionPressed: {
        // timeStamp: null,
        data : []
    },
    doorDelPendingCalls: {
        // timeStamp: null,
        data : []
    },
    doorDelRejectedCalls: {
        // timeStamp: null,
        data : []
    },
    totalCallSmsCount: {
        deliveryAcceptCount: 0,
        deliveryCount: 0,
        deliveryPendingCount: 0,
        deliveryRejectCount: 0,
        doorDeliveryAcceptCount: 0,
        doorDeliveryAcceptNOpCount: 0,
        doorDeliveryAcceptOpCount: 0,
        doorDeliveryCount: 0,
        doorDeliveryPendingtCount: 0,
        doorDeliveryRejectCount : 0,
        smsCount : 0,
        smsRejectCount : 0,
        smsSendCount : 0,
        voiceCount : 0,
        // countTimeStamp: null
    }
}

const smsAndCallRecords = (state = initSDtate, action: { type: any; payload: any }) => {
    switch (action.type) {
        case SMS_ACCEPTED_RECORDS: {
            return {
                ...state,
                smsAccepted: action.payload
            }
        }
        case SMS_REJECTED_RECORDS: {
            return {
                ...state,
                smsRejected: action.payload
            }
        }
        case CALL_NORMAL_DELIVERY_ACCEPTED_RECORDS: {
            return {
                ...state,
                normalAcceptedCalls: action.payload
            }
        }
        case CALL_NORMAL_DELIVERY_PENDING_RECORDS: {
            return {
                ...state,
                normalPendingCalls: action.payload
            }
        }
        case CALL_NORMAL_DELIVERY_REJECTED_RECORDS: {
            return {
                ...state,
                normalRejectedCalls: action.payload
            }
        }
        case CALL_DOOR_DELIVERY_ACCEPTED_RECORDS_WITH_OPTION_PRESSED: {
            return {
                ...state,
                doorDelAcceptedWithOptionPressed: action.payload
            }
        }
        case CALL_DOOR_DELIVERY_ACCEPTED_RECORDS_WITHOUT_OPTION_PRESSED: {
            return {
                ...state,
                doorDelAcceptedWithoutOptionPressed: action.payload
            }
        }
        case CALL_DOOR_DELIVERY_PENDING_RECORDS: {
            return {
                ...state,
                doorDelPendingCalls: action.payload
            }
        }
        case CALL_DOOR_DELIVERY_REJECTED_RECORDS: {
            return {
                ...state,
                doorDelRejectedCalls: action.payload
            }
        }
        case "TOTAL_CALL_SMS_COUNT": {
            return {
                ...state,
                totalCallSmsCount: action.payload
            }
        }
        default:
            return state;
    }
}

export default smsAndCallRecords;