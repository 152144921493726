// react
import React from 'react'
// ui components
import { Button, Heading, Input, PageHeader } from "../UI"
// custom state
import { useContactUs } from "./useContactUs"
// constants (icon)
import { CallIcon, GetDetailsIcon, LocationIcon, MailIcon } from "../../constants/icons";
// css
import './contact-us.css';

// variables
const linkIcons = [<LocationIcon />, <MailIcon />, <CallIcon />];

// FC: Contact Us Component
const ContactUs = () => {
  // State
  const { name, setName, email, setEmail, subject, setSubject, message, setMessage } = useContactUs();

  // component return
  return (
    <main className="contact-us-container contact-us | bg-white-800">
      <section className="page-container-grid page-container-auto-grid">
        {/* Contact Us Header */}
        <PageHeader title="Contact Us" imgSrc="contact-us/bg.jpg" />
        <div className="flex-center py-2">
          {/* Contact Us Form */}
          <form action="" className="| small-container flex-col gap-1 br-1 bg-primary-400" style={{padding: "4rem 4.5rem"}}>
            <h1 className="| m-0 text-center mx-auto text-white-900 fs-subheading fw-600">Having Any Query! Or Book an appointment</h1>
            {/* Name */}
            <Input variant="neutral" title="Your Name" state={{ value: name, setValue: setName }} required />
            {/* Email */}
            <Input variant="neutral" type="email" title="Your Email" state={{ value: email, setValue: setEmail }} required />
            {/* Subject */}
            <Input variant="neutral" title="Your Subject" state={{ value: subject, setValue: setSubject }} required />
            {/* Message */}
            <textarea name="Your Message" id="contact-us-your-message" cols={30} className="| w-100 outline-none" placeholder="Your Message"
              value={message}
              onChange={e => setMessage(e.target.value)}
              style={{
                padding: '1rem 0.75rem',
                border: "1px solid var(--clr-neutral-400)",
                borderRadius: "4px"
              }}
              onFocusCapture={e => {
                e.target.style.border = `1px solid var(--clr-accent-400)`;
              }}
              onBlur={e => {
                e.target.style.border = `1px solid var(--clr-neutral-400)`;
              }}
              required />
            {/* Submit Button */}
            <Button variant="primary" action="secondary" Icon={<GetDetailsIcon />} className="| fs-button as-start">Submit</Button>
          </form>
        </div>
      </section>
      {/* Contact Us Information */}
      <section className="| flex-col gap-20 bg-primary-400 p-3 text-white-900">
        {/* Contact Us Header */}
        <Heading className="text-white-900">Contact Info</Heading>
        {/* Contact Us Information List */}
        <ul className="| three-column t-one-column m-one-column gap-1 contact-info-list">
          {/* Location */}
          <li className="| flex-col-center gap-1 p-1 br-4 bg-primary-500">
            <span className="| fs-icon">{linkIcons[0]}</span>
            <p className="| m-0 fs-link text-center fw-400">02/13 Vallabh Flats, Vijay Chowk, NR Sardar Patel Diamond Market, Bapunagar, Ahmedabad, Gujarat 380024</p>
          </li>
          {/* Mail */}
          <li className="| flex-col-center gap-1 p-1 br-4 bg-primary-500">
            <span className="| fs-icon">{linkIcons[1]}</span>
            <a href="mailto:customersupport@kabraexpress.com" className="| m-0 fs-link text-center text-white-900 fw-400">customersupport@kabraexpress.com</a>
          </li>
          {/* Contact Number */}
          <li className="| flex-col-center gap-1 p-1 br-4 bg-primary-500">
            <span className="| fs-icon">{linkIcons[2]}</span>
            <div className="| flex-col gap-4">
              <a href="tel:7575857777" className="m-0 fs-medium text-center text-white-900 fw-400">+917575857777</a>
              <a href="tel:6358880246" className="m-0 fs-medium text-center text-white-900 fw-400">+916358880246</a>
            </div>
          </li>
        </ul>
      </section>
    </main>
  )
}

export default ContactUs