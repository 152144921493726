import { OtpTypes } from "../utils/otp";
import { buildQueryString, ServiceMaker } from "./index";

export const createOrUpdateCustomer = (data: any) =>
  ServiceMaker("customer/create", "POST", data);

export const searchCustomerByRegex = (
  q: any,
  sub: string,
  entity: string,
  allCreditCustomers: any,
  company: any,
  page: number,
  pageSize: number,
  isGstSearch?: boolean
) =>
  ServiceMaker(
    `customer/search?q=${q +
      (sub && (sub == "B" || sub === "F") ? "&sub=" + sub : "") +
      (entity && entity.length === 24 ? "&entity=" + entity : "") +
      (allCreditCustomers ? "&allCreditCustomers=" + true : "") +
      (company ? "&company=" + company : "") +
      (page && page > 0 ? "&page=" + page : "") +
      (pageSize && pageSize > 0 ? "&pageSize=" + pageSize : "") +
      (isGstSearch ? "&isGstSearch=" + true : "")
    }`,
    "GET"
  );

export const createCustomerCreditService = (data: {
  company?: string;
  customer: string;
  limit: number;
  // permissions: [string];
  fromBranches: [string];
  toBranches: [string];
  fleetPermissions: [string];
  includeDeliveryCharge: boolean
}) => ServiceMaker("credit/customer/createrequest", "POST", data);

export const fetchCustomerByContactService = (data: any) =>
  ServiceMaker("customer/by/contact", "POST", data);

export const fetchCustomerByContactGeneralService = (data: any) => 
  ServiceMaker(`customer/by/contact/general?${buildQueryString(data)}`, "GET", data);

export const fetchCustomerByContact = (data: any) =>
  ServiceMaker("customer/verifyPhoneNumbers", "POST", data);

export const fetchIndividualFleetByContact = (data: any) =>
  ServiceMaker("customer/verifyPhoneNumbersFleet", "POST", data);

export const fetchCustomerByCustomerCodeService = (data: any) =>
  ServiceMaker(`customer/getBy/customerCode?${buildQueryString(data)}`, "GET");
                         
export const fetchCustomerCreditService = (data: { contact: number }) =>
  ServiceMaker("credit/customer/fetch", "POST", data);

export const fetchDueCreditService = (data: {
  customer: string;
  sub: string;
  entity: string;
}) => ServiceMaker("credit/customer/due", "POST", data);

export const getCreditDataForCustomer = ()=>
  ServiceMaker(`customerCredit/customer/due`, "POST");

export const getGenerateCreditBil = (id:string)=>
  ServiceMaker(`credit/generateCreditBill/${id}`, "POST");

export const listCreditDueByCustomer = (customer: string) =>
  ServiceMaker(`credit/due/${customer}`, "GET");

// export const settleDuesService = (data) =>
//   ServiceMaker("credit/settle/docket", "POST", data);

export const billGenerateService = (data: any) =>
  ServiceMaker("credit/bill/generate", "POST", data);

export const billCancelService = (billId: string) =>
  ServiceMaker(`credit/bill/cancel/${billId}`, "DELETE");

export const cancelIntracityOrder = (data:any) => 
  ServiceMaker(`customerBooking/cancelOrderByCustomer`,"PATCH",data)

export const billSettleService = (data: any) =>
  ServiceMaker("credit/bill/settle", "POST", data);

export const billViewService = (id: string) =>
  ServiceMaker(`credit/bill/details/${id}`, "GET");

export const getInvoiceService = (data: { receiptNo: string }) =>
  ServiceMaker("credit/invoice/details", "POST", data);
// export const getInvoiceService = (data) => {
//   return new Promise((resolve, reject) => {
//     API.post("credit/invoice/details", data)
//       .then((res) => resolve(res.data))
//       .catch((err) => reject(err.response ? err.response.data : err));
//   });
// };
export const sendConnectedCustomerOTP = (data: {
  customer: string;
  contact: number;
}) => ServiceMaker("customer/connectcontact/sendotp", "POST", data);

export const verifyConnectedCustomerOTP = (data: {
  customer: string;
  contact: number;
  customerOtp: string;
  contactOtp: string;
}) => ServiceMaker("customer/connectcontact/verifyotp", "POST", data);

export const deleteConnectedCustomer = (data: {
  customer: string;
  contact: number;
}) => ServiceMaker("customer/connectedcontact", "DELETE", data);

export const saveCreditService = (data: {
  company?: string;
  credit: 0;
  customer: string;
  limit: 0;
  // permissions: [string];
  fromBranches: [string];
  toBranches: [string];  
  fleetPermissions: [string];
  includeDeliveryCharge:boolean
}) => ServiceMaker("credit/customer/updaterequest", "POST", data);

export const getUnapprovedCredit = () =>
  ServiceMaker("credit/noncash/unapproved", "GET");

export const approveNoncashCredit = (id: any) =>
  ServiceMaker("credit/noncash/approve", "PUT", { id });

export const listCreditCustomers = () =>
  ServiceMaker("customer/credit/list", "GET");

export const creditTransferDocketCredit = (data:any) =>
  ServiceMaker("credit/transferDocketCredit", "POST",data);

export const suggestCreditCustomers = (payload: any) =>
  ServiceMaker(
    `customer/searchCreditCustomer?${buildQueryString(payload)}`,
    "GET"
  );

export const addNewCustomerRate = (
  id: string,
  data: {
    originBranch: string;
    destBranch: string;
    sizes: string[];
    price: number;
    deliveryCharges: number;
    doorDelCharges: number;
  }
) => ServiceMaker(`rate/setByCustomer/${id}`, "PUT", data);

export const showCustomerRateList = (id: string, data?: any) =>
  ServiceMaker(`rate/getByCustomer/${id}?${buildQueryString(data)}`, "GET");

export const sendCustomerSummary = (data: any) =>
  ServiceMaker("customer/remarks", "POST", data);

export const getCustomerSummary = (data?: any) =>
  ServiceMaker(`customer/remarks?${buildQueryString(data)}`, "GET");

export const getdistanceFromBranches = (data?: any) =>
  ServiceMaker(`branch/distance?${buildQueryString(data)}`, "GET");

export const getFixedCustomerDiscountList = (data?: any) =>
  ServiceMaker(`report/getDiscount?${buildQueryString(data)}`, "GET");

export const getFixedCustomerspecificData = (data: any) =>
  ServiceMaker(
    `report/getDiscount/by/fixRateCustomer?${buildQueryString(data)}`,
    "GET"
  );

export const getCustomerUpdateList = (data?: any) =>
  ServiceMaker(
    `customer/customerUpdateReqList?${buildQueryString(data)}`,
    "GET"
  );

export const customerUpdateAction = (id:any,data: any) =>
  ServiceMaker(`customer/ProcessCustomerUpdateReq/${id}`, "PATCH", data);

export const getSecondaryContactDetails = (data: any) =>
  ServiceMaker("customer/getSecondaryCustomer", "POST", data);

export const deleteCustomerRate = (id: string) =>
  ServiceMaker(`rate/${id}`, "DELETE");

export const generateCodeSendOTP = (data: { contact: number, otpType: OtpTypes, amount?: number, smsOperator?: string, retryCount?: number, shipmentNumber?: string }) =>
  ServiceMaker("customer/sendOtp", "PATCH", data);

export const generateCodeVerifyOTP = (data: any) =>
  ServiceMaker("customer/verifyOtp", "PATCH", data);

export const senderVerification = (data: any) =>
  ServiceMaker("customer/senderVerification", "PATCH", data);

export const sendCustomerOTP = (data: any) =>
  ServiceMaker("otp/operations/generate", "POST", data);

export const sendOTPFocAuthenticate = (data: any) =>
  ServiceMaker("booking/focAuthenticate/sendOTP", "POST", data);

export const customerBookingMonthWise = (data: any) =>
ServiceMaker(
  `customer/customerBooking/monthWise?${buildQueryString(data)}`,
  "GET"
);

export const CustomerMonthWiseHistory = (data:any) =>
ServiceMaker(`customerBooking/getCustomerBookings?${buildQueryString(data)}`, "GET")

export const customerHistoryCount = (data:any) =>
ServiceMaker(`customerBooking/customerWise/countCustomerBooking?${buildQueryString(data)}`, "GET")

export const expressParcelBookingCountForCustomer = (data: any) => 
  ServiceMaker(`customerreport/booking/counts?${buildQueryString(data)}`, "GET")

export const addCustomerRequirement = (data:any) =>
ServiceMaker("customer/createCustomerRateRequirement", "POST",data)

export const addCustomerRequirementFromCustomerMode = (data:any) =>
ServiceMaker("customerRate/createCustomerRateRequirement", "POST",data)

export const listCustomerRequirementFromCustomerMode = (data?:any) =>
ServiceMaker(`customerRate/listCustomerRateRequirement?${buildQueryString(data)}`, "GET")

export const customerRequirementList = (data?:any)=>
ServiceMaker(`customer/listCustomerRateRequirement?${buildQueryString(data)}`,"GET")

export const customerRateList = ()=>
ServiceMaker(`customerRate/listCustomerRate`,"GET")

export const customerBranchList = ()=>
ServiceMaker(`branch/list/for/customer`,"GET")

export const customerLogout = ()=>
ServiceMaker(`customerToken/customerLogout`,"DELETE")

export const getCustomerDataWithRefreshToken = (data:any)=>
ServiceMaker(`customerToken/getIdToken`,"POST",data)

export const getDoorDeliveryBookedByAddresses = (data:any)=>
ServiceMaker(`customerProfile/listPreferenceDoorDeliveryAddr?${buildQueryString(data)}`,"GET")

export const customerhandlingcharges = ()=>
ServiceMaker(`settings/for/customer`,"GET")

export const getExtraChargesInYourRates = (data:any)=>
ServiceMaker(`customerBooking/getExtraChargesDetails?${buildQueryString(data)}`,"GET",)

export const processCustomerRequirement = (data:any) =>
ServiceMaker("customer/processCustomerRateRequirement","POST",data)

export const customerCreditDetails = (data:any) =>
ServiceMaker("customerCredit/fetchCustomerCreditDetails","POST",data)

// export const verifyCustomer = (data?:any) =>
// ServiceMaker("customer/customerContactVerification","PATCH",data)

export const GetRatingFromCustomer = (data?:any) =>
ServiceMaker("customerBooking/addCustomerBookingRatingReview","POST",data)

export const verifyCustomer = (data?:any) =>
ServiceMaker("customerBooking/customerVerification","PATCH",data)

export const GetCustomerCredit = (data?:any) =>
ServiceMaker("/customerCredit/viewCustomerInvoiceDetail","POST",data)

export const PrintCustomerCreditBills = (id?:any) =>
ServiceMaker(`customerCredit/credit/bill/details/${id}`,"GET")

export const GetCustomerBillView = (data?:any) =>
ServiceMaker("/customerCredit/fetchCustomerCreditDetails","POST",data)

export const addCustomerAddress = (data?:any) =>
ServiceMaker("/address/create/byUser","POST",data)

export const updateCustomerAddress = (data?:any) =>
ServiceMaker(`/address/update/byUser/${data.Id}`,"PATCH",data)

export const removeDoorDeliveryAddr = (data?:any) =>
ServiceMaker(`address/delete/byUser`,"DELETE" , data)

export const getCustomerAddress = (data?:any) =>
ServiceMaker(`customer/getDetails?${buildQueryString(data)}`,"GET")

export const generateCodeDirect = (data: any) =>
  ServiceMaker("customer/generateCode/direct", "PATCH", data);

export const deleteCustomerGst = (id: string, data: any) =>
  ServiceMaker(`customer/gst/${id}`, "DELETE", data);

export const deleteCustomerName = (id: string, data: any) =>
  ServiceMaker(`customer/name/${id}`, "DELETE", data);


export const PrintCustomerCreditBills2 = (id?:any) =>
  ServiceMaker(`customerCredit/generateCreditBill/${id}`,"POST")

export const customerContactVerificationSendOtp = (data:any) =>
  ServiceMaker(`customer/customerContactVerification/sendOtp`,"POST", data)

export const customerContactVerificationVerifyOtp = (data:any) =>
  ServiceMaker(`customer/customerContactVerification`,"PATCH", data)

export const getConnectedContactsByGST = (gst:any) =>
  ServiceMaker(`customer/connectedContactList/by/gst/${gst}`, 'GET')

export const getConnectedCustomersByContact = (contact: any) => 
  ServiceMaker(`customer/connectedContactList/by/contact/${contact}`, 'GET')

export const getCustomerUpdateReqCount = (data?: any) =>
  ServiceMaker(
    `customer/customerUpdateReqCount?${buildQueryString(data)}`,
    "GET"
  );