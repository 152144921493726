import {ServiceMaker, buildQueryString} from "./index"

export const getTableCashList = (data:any)=>
ServiceMaker(`transactions/tableCash?${buildQueryString(data)}`,"GET");

export const tableCashAction = (id:string,data:any)=>
ServiceMaker(`transactions/tableCash/${id}`,"PATCH",data);

export const getTableCashCount = () =>
 ServiceMaker("transactions/tableCashCount","GET");
