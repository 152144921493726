import API from "../axios";
import { ServiceMaker } from "./index";

export const incomeSubmitHandler = (data:any) =>
  ServiceMaker("income/type", "POST", data);

export const editIncomeType = (data:any) => {
  return new Promise((resolve, reject) => {
    API.put("income/edit/" + data.id, data)
      .then((res) => resolve(res.data.response))
      .catch((err) => reject(err));
  });
};

export const deleteIncomeTypes = (id:any, data:any) =>
  ServiceMaker(`income/delete/${id}`, "POST", data);

export const getSubTypes = () => {
  return new Promise((resolve, reject) => {
    API.get("income/sub")
      .then((res) => resolve(res.data.response))
      .catch((err) => reject(err.response));
  });
};

export const putSubIncome = (data:any) => {
  return new Promise((resolve, reject) => {
    API.put("income/sub/edit", data)
      .then((res) => resolve(res.data.response))
      .catch((err) => reject(err.response));
  });
};

export const getIncomeTypesService = (data:any) =>
  ServiceMaker("income/types", "POST", data);

export const fetchIncomeTypesById = (id:any) => {
  return new Promise((resolve, reject) => {
    API.post("income/record/" + id)
      .then((res) => {
        resolve(res.data.response);
      })
      .catch((err) => reject(err.response));
  });
};

export const incomeSubTypeSubmitHandler = (data:any) => {
  return new Promise((resolve, reject) => {
    API.post("subincome/create", data)
      .then((res) => resolve(res.data.response))
      .catch((err) => reject(err));
  });
};

export const deletedIncomeRecord = (id:any, company:any) =>
  ServiceMaker("income/record/delete/" + id, "POST", { company });
// {
//     return new Promise((resolve, reject) => {
//         API.post('expense/income/record/delete/' + id, { company })
//             .then(res => resolve(res.data.response))
//             .catch(err => reject(err.response))
//     })
// }

export const editIncomeRecordService = (data:any, id:any) =>
  ServiceMaker(`income/record/edit/${id}`, "POST", data);

export const fetchSubIncomeTypes = (data:any) => {
  return new Promise((resolve, reject) => {
    API.get("income/subincome/" + data)
      .then((res) => resolve(res.data.response))
      .catch((err) => reject(err));
  });
};

export const deleteSubIncomeTypes = (id:any, data:any) => {
  return new Promise((resolve, reject) => {
    API.post("income/sub/delete/" + id, { company: data })
      .then((res) => resolve(res.data.response))
      .catch((err) => reject(err));
  });
};

export const postIncomeRecord = (data:any) =>
  ServiceMaker("income/addrecord", "POST", data);
//  {
//     return new Promise((resolve, reject) => {
//         API.post('income/addrecord', data)
//             .then(res => resolve(res.data))
//             .catch(err => reject(err && err.response ? err.response.data : null))
//     })
// }

// export const postIncomeRecord = data => ServiceMaker('income/addrecord', 'POST', data)
export const filterIncomeRecords = (data:any) =>
  ServiceMaker("income/record/filter", "POST", data);

export const getPastIncomes = (user:any, entity:string, loginType:string) => {
  let url = "income/past/?";
  if (loginType === "F") {
    return ServiceMaker(`${url}user=${user}&fleet=${entity}`, "GET");
  } else {
    return ServiceMaker(`${url}user=${user}&branch=${entity}`, "GET");
  }
};

export const submitImportedRecords = (data:any) =>
  ServiceMaker("income/importRecord", "POST", data);
