// react
import React, { useEffect } from 'react'
// ui components
import { Heading, Select } from "../UI"
// css
import './our-networks.css';
// custom state
import { useOurNetwork } from "./useOurNetwork";
// actions
import { debounce } from "../../../actions/booking";
// services
import { fetchBranch } from "../../../services/Network";
// constants (icon)
import { CallIcon, CheckIcon, HoursIcon, ManagerIcon, NavigateIcon, PincodeIcon, SharpLocationIcon, TransportCompanyIcon } from "../../constants/icons";
// utils (helper)
import { formatTimeToHHMM } from "../../utils/helper";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { hideLoader, showLoader } from "../../../actions/UserActions";
import { useAppSelector } from "../../../hooks/useAppSelector";


// FC: Our Network Component
const OurNetworks = () => {
  const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

  // State
  const { branchName,
    // setBranchName
    pincode, setPincode, cityOptions, setCityOptions, stateOptions, setStateOptions, branchOptions, setBranchOptions, state, setState, city, setCity, branch, setBranch, allCityList, setAllCityList, allBranches, setAllBranches } = useOurNetwork();
    const dispatch = useAppDispatch();
    const loading = useAppSelector(state => state.user.loading);

  // does: display branches
  const displayBranches = branch === "" || branch.label === "All"
    ? allBranches
    : allBranches?.map((ele: any) => ({
      cityName: ele?.cityName,
      _id: ele?._id,
      branchCount: ele?.branchCount, // Ensure branchCount is included if needed
      branches: ele?.branches.filter((b: any) =>
        b?.branchName.toUpperCase().includes(branch?.label?.toUpperCase() || branchName?.toUpperCase())
      ),
    }));
  // does: setup all the data
  const setupData = () => {
    getCity();
    getState();
  };
  // does: get all branches
  const getBranches = debounce(() => {
    dispatch(showLoader());
    let payload = {
      state: state.value ? state.value : "",
      city: city.value ? city.value : "",
      pincode: pincode ? pincode : "",
    };
    fetchBranch(payload)
      .then((response) => {
        setAllBranches(response);
        dispatch(hideLoader());
        let branchList = [
          {
            label: "All",
            value: "",
            state: state,
            city: city,
          },
        ];
        response.map((ele: any) =>
          ele.branches.map((options: any) =>
            branchList.push({
              label: options.branchName,
              value: options._id,
              state: {
                label: options.address.stateName,
                value: options.address.stateId,
              },
              city: {
                label: options.address.cityName,
                value: options.address.cityId,
              },
            })
          )
        );
        setBranchOptions(branchList);
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoader());
      });
  }, 300);
  // does: get the cities
  const getCity = () => {
    let payload = {
      state: state.value ? state.value : "",
      city: city.value ? city.value : "",
      pincode: pincode ? pincode : "",
    };
    fetchBranch(payload).then(async (response) => {
      let completeCityList = [
        {
          label: "All",
          value: "",
          state: {
            label: "",
            value: "",
          },
        },
      ];
      response.map((ele: any) =>
        ele.branches.map((options: any) =>
          completeCityList.push({
            label: options.address.cityName,
            value: options.address.cityId,
            state: {
              label: options.address.stateName,
              value: options.address.stateId,
            },
          })
        )
      );
      let uniqueArray = await removeDuplicates(completeCityList, "value");
      setCityOptions(uniqueArray);
      setAllCityList(uniqueArray);
    });
  };
  // does: get all states
  const getState = async () => {
    let payload = {
      state: state.value ? state.value : "",
      city: city.value ? city.value : "",
      pincode: pincode ? pincode : "",
    };
    fetchBranch(payload).then(async (response) => {
      let allStateList = [{ label: "All", value: "" }];

      response.map((ele: any) =>
        ele.branches.map((options: any) =>
          allStateList.push({
            label: options.address.stateName,
            value: options.address.stateId,
          })
        )
      );

      let uniqueArray = await removeDuplicates(allStateList, "value");

      setStateOptions(uniqueArray);
    });
  };
  // does: handle state
  const handleState = (e: any) => {
    setState(e);
    setCityOptions([
      { label: "All", value: "", state: e },
      ...allCityList.filter((b: any) => b.state.value.includes(e.value)),
    ]);
  };
  // does: handle city
  const handleCity = (e: any) => {
    setCity(e);
  };
  // does: handle branch
  const handleBranch = (e: any) => {
    setBranch(e);
  };
  // does: remove duplicates from array
  const removeDuplicates = async (originalArray: any, prop: any) => {
    let newArray: any = [];
    let lookupObject: any = {};

    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  };

  // useEffect
  // does: on the first render setup the data
  useEffect(() => {
    setupData();
  }, []);
  // does: whenever city, state, pincode and branch changes it re feteches the branches
  useEffect(() => {
    getBranches();
  }, [city, state, pincode, branch]);

  // component return
  return (
    <main className="our-networks | bg-white-900">
      <section className="our-networks-header | relative p-3 flex-col gap-8">
        <img loading="lazy" src="/assets/our-network/bg.jpeg" alt="Background" />
        <Heading className="text-primary-400">Our Network</Heading>
        {/* <p className="m-0 mx-auto text-center text-primary-900 fs-body fw-500">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors.</p> */}
      </section>
      <section className="| my-container p-3 py-2 flex-col gap-1">
        {/* Search by branch name */}
        <h1 className="m-0 text-center fs-innerheading fw-600">Find a location</h1>
        {/* <Input variant="white" title="Search branch by name" state={{ value: branchName, setValue: setBranchName }} />
      <div className="mx-auto fw-900">OR</div> */}
        {/* Search by filters */}
        <div className="four-column t-two-column m-one-column gap-2">
          <Select isSearchable={true} options={stateOptions} value={state.value && state} onChange={handleState} placeholder={"State"} />
          <Select isSearchable={true} options={cityOptions} value={city.value && city} onChange={handleCity} placeholder={"City"} />
          <Select isSearchable={true} options={branchOptions} value={branch.value && branch} onChange={handleBranch} placeholder={"Branch"} />
          {/* Search by pincode */}
          <input type="tel" maxLength={6} style={{ border: "1px solid var(--clr-white-400)", borderRadius: "4px" }} placeholder="Pincode" className="py-10 px-1" onChange={e => setPincode(e.target.value)} />
        </div>
        {/* <div className="mx-auto fw-900">OR</div>
      <input type="text" value={branchName} style={{ border: "1px solid var(--clr-white-400)", borderRadius: "4px" }} placeholder="Search by branch name" className="p-1" onChange={e => setBranchName(e.target.value)} /> */}
      </section>
      {!loading && <section className="| flex-col gap-2">
        {displayBranches && Array.isArray(displayBranches) && displayBranches.length > 0 ? displayBranches.map((item: any) => {
          return <div className="| flex-col" key={item._id}>
            <h1 className="| m-0 text-center fs-subheading fw-600 p-1 bg-primary-400 text-white-900">
              {item.cityName}
            </h1>
            <ul className="| my-container p-3 py-1 our-networks-grid gap-2">
              {item.branches && Array.isArray(item.branches) && item.branches.length > 0 ? item.branches.map((item: any) => {
                return <li key={item._id} className="br-4 shadow-small">
                  <h1 className="| fs-medium fw-500 uppercase bg-primary-300 text-white-900 m-0 br-4 p-10">{item.branchName}</h1>
                  <div className="| p-1 flex-col gap-8">
                    {/* Location | Address Info */}
                    <p className="| border-bottom-white m-0 fw-400 capitalize" style={{ display: "grid", gridTemplateColumns: "25px 1fr" }}>
                      <SharpLocationIcon className="| fs-medium" />{" "}{item.address.l1}, {item.address.l1} 
                    </p>
                    {/* Pincode & Navigate button for directions to location */}
                    <div className="flex ai-center gap-1 border-bottom-white pb-4">
                      <p className="| flex ai-center gap-4 m-0 fw-400">
                        <PincodeIcon className="| fs-medium" />{item.address.pincode}
                      </p>
                      {item.bLocation && <a href={
                            iOS === true
                              ? `comgooglemapsurl://maps.google.com/maps?z=12&t=m&q=loc:${item.bLocation.coordinates[1]}+${item.bLocation.coordinates[0]}`
                              : `http://maps.google.com/maps?z=12&t=m&q=loc:${item.bLocation.coordinates[1]}+${item.bLocation.coordinates[0]}`
                          }
                          target="_blank" className="max-content fs-extrasmall fw-500 flex ai-center gap-4 bg-primary-50 text-primary-900 px-8 border-bottom-white pointer py-4 oval"><NavigateIcon className="flex-shrink-0" />Navigate</a>}
                    </div>
                    {/* Contact Information */}
                    <p className="| flex ai-center border-bottom-white m-0 fw-400">
                      {item.pContact && <span className="| flex ai-center gap-4"><CallIcon className="fs-medium" />{item.pContact}</span>},
                      {item.pContact && <span className="| flex ai-center gap-4"><CallIcon className="fs-medium" />{item.pContact}</span>}
                    </p>
                    {/* Transportation Company Information */}
                    <p className="| border-bottom-white flex ai-center gap-4 m-0 fw-400 capitalize">
                      <TransportCompanyIcon className="| fs-medium" />{item.company.companyName}
                    </p>
                    {/* Manager Information */}
                    <p className="| border-bottom-white flex ai-center gap-4 m-0 fw-400 capitalize">
                      <ManagerIcon className="| fs-medium" />{item.manager?.name?.fName ? item.manager.name.fName : "-"}{" "}{item.manager?.name?.lName}
                    </p>
                    {/* Open-Close Time Information */}
                    <p className="| border-bottom-white flex ai-center gap-4 m-0 fw-400">
                      <HoursIcon className="| fs-medium" />
                      <span className="| text-success-900">Open:</span> {formatTimeToHHMM(item.working?.regular?.regStart) !== "NaN:NaN" ? formatTimeToHHMM(item.working?.regular?.regStart) : ""}
                      {" - "}
                      <span className="| text-danger-900">Closes:</span> {formatTimeToHHMM(item.working?.regular?.regEnd) !== "NaN:NaN" ? formatTimeToHHMM(item.working?.regular?.regEnd) : ""}</p>
                    {/* Status for Booking & Delivery */}
                    <div className="| flex gap-4 flex-wrap">
                      {item.operation?.booking && <span className="flex gap-4 ai-center p-8 br-4 capitalize" style={{border: "1px solid var(--clr-white-600)"}}><CheckIcon />Booking</span>}
                      {item.operation?.delivery && <span className="flex gap-4 ai-center p-8 br-4 capitalize" style={{border: "1px solid var(--clr-white-600)"}}><CheckIcon />Delivery</span>}
                    </div></div>
                </li>
              }) : <h1 className="| fs-subheading fw-600">No branches for this city</h1>}
            </ul>
          </div>
        }) : <Heading>No Branch Found</Heading>}
      </section>}
    </main>
  )
}

export default OurNetworks