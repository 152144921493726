import React, { ComponentProps } from "react";

type ErrorMessageProps = ComponentProps<"span">;

const ErrorMessage = ({
  className = "",
  children,
  ...props
}: ErrorMessageProps) => {
  if (!children) return null;
  return (
    <span className={`${className} text-danger-400 fs-small`} {...props} />
  );
};

export default ErrorMessage;
