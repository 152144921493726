import React, { FunctionComponent } from "react";
import { QRCodeSVG } from "qrcode.react";

interface QrGeneratorProps {
  value: any;
  renderAs?: string;
  size: number;
  bgColor?: string;
  fgColor?: string;
  level?: string;
  includeMargin?: boolean;
  imageSettings?: ImageSettings;
  style?: any;
  qrStyle?: any;
}

interface ImageSettings {
  src?: string;
  x?: number;
  y?: number;
  height?: number;
  width?: number;
  excavate?: boolean;
}

const QrGenerator: FunctionComponent<QrGeneratorProps> = (props) => {
  return (
    <div style={props.style}>
      <QRCodeSVG value={props.value} size={props.size} style={props.qrStyle} />
    </div>
  );
};

export default QrGenerator;
