export const GetDdMmYyyyFormatDate = (datestr:string|Date)=>{
  if(!datestr) return ""
    const date =new Date(datestr)
    let dd:any = date.getDate();
    let mm:any = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    } 
    if (mm < 10) {
      mm = '0' + mm;
    }
    return dd + '/' + mm + '/' + yyyy;
}