import React from "react";
import {
 Tabs as TabsContainer,
 Tab as TabContainer,
 AppBar,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";

interface IpropsTabs {
 value?: any;
 children?: any;
 onChange?: any;
 href?: any;
 style?: any;
}
export function Tabs(props: IpropsTabs) {
  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2

  return (
    <AppBar position="relative" color="inherit" style={{ zIndex: 0 }}>
      <TabsContainer
        value={props.value}
        onChange={props.onChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        style={{ alignSelf: mobileView ? "auto" : props.style ? props.style : "center" }}
      >
        {props.children}
      </TabsContainer>
    </AppBar>
  );
}
interface IpropsTab {
 label?: any;
 children?: any;
 onClick?: any;
 id?: any;
 href?: any;
 value?:any;
 className?: any;
 style?: any;
 icon?: any;
 classes?: any;
}

export function Tab(props: IpropsTab) {
 const { children, ...other } = props;

 return (
  <TabContainer
   className={props.className}
   id={props.id}
   href={props.href}
   label={props.label}
   onClick={props.onClick}
   style={props.style}
   icon={props.icon}
   classes={props.classes}
   {...other}
  />
 );
}

interface IpropsTabLink {
 to?: any;
 label?: any;
 children?: any;
 onClick?: any;
 href?: any;
}
export function TabLink(props: IpropsTabLink) {
 return (
  <Link to={props.to} style={{ textDecoration: "none", color: "var(--clr-primary-900 )" }}>
   {" "}
   <TabContainer
    href={props.href}
    label={props.label}
    onClick={props.onClick}
   />{" "}
  </Link>
 );
}
interface TabPanelProps {
 children?: React.ReactNode;
 index: any;
 value: any;
 style?: any;
}
export function TabPanel(props: TabPanelProps) {
 const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    // id={props.id}
    >
      {value === index && <div> {children} </div>}
    </div>
  );
}
