import { fromJS, Map as immutableMap } from "immutable";
import {
  CHANGE_PERMITTED_BRANCH,
  CHANGE_CONNECTED_CUSTOMERS,
  CREDIT_FIELD_CHANGE,
  NEW_CREDIT_SUBMIT,
  FETCH_DUE_CREDIT,
  SETTLE_DUES_SUCCESS,
  // CHANGE_PACKAGE_AMOUNT_SETTLEMENT,
  CHANGE_PACKAGE_AMOUNT,
  CHANGE_PACKAGE_AMOUNT_BULK,
  SET_EFFECTIVE_PACKAGE_AMOUNT_BULK,
  RESET_PACKAGE_AMOUNTS,
  RESET_ALL_PACKAGE_AMOUNTS,
  GET_INVOICE_DETAILS,
  CLEAR_RECEIPT_NUMBER,
  TOGGLE_CREDIT_ROW,
  SELECT_ALL_CREDIT_DOCKET,
  CLOSE_CREDIT_PRINT,
} from "../constants/customer";
import {
  Customer_boolean,
  validationSchema,
  validate,
} from "./schemas/customer";
import { showMessage, success } from "../utils/message";

const initCredit = JSON.stringify({
  limit: "",
  balance: "",
  company: "",
  customer: "",
  // permissions: [],
  fromBranches: [],
  toBranches: [],
  fleetPermissions: [],
  includeDeliveryCharge: false
});

const initCustomer = JSON.stringify({
  _id: "",
  name: "",
  contact: "",
  gst: "",
  connected: [],
  connectedContacts: [],
  referenceUser: null,
  address: {
    l1: "",
    l2: "",
    city: "",
    pincode: "",
  },
  panCardImage: "",
  panCardImageVerified: false,
  adhaarCardImage: "",
  adhaarCardImageVerified: false,
  codeGenerateOTP: "",
  customerVerifyOTP: "",
  adminRemarks: "",
});

const initState = {
  customer: immutableMap(JSON.parse(initCustomer)),
  credit: fromJS(JSON.parse(initCredit)),
  customerList: [],
  customer_errors: fromJS({ ...Customer_boolean(true) }),
  customer_touched: fromJS({ ...Customer_boolean(false) }),
  originalCreditDueList: [],
  creditDueListUnchanged: [],
  allCredit: [],
  bills: [],
  receipts: [],
  creditDocketMap: new Map(),
  invoiceDetails: [],
  invoiceParty: new Map(),
  invoiceCustomer: {},
  docketRecords: [],
  receiptNo: null,
  printCredit: false,
  newCredit: fromJS(JSON.parse(initCredit)),
};
export default (state = initState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case "SET_CUST": {
      const { credit, customer, allbranches, allfleets } = action.payload;
      const customerDoc = Object.assign({}, customer);
      customerDoc.connected =
        Array.isArray(customer.connected) && customer.connected.length > 0
          ? customer.connected.map((c: { contact: any; _id: string }) => ({
            label: c.contact,
            value: c._id,
          }))
          : [];
      customerDoc.connectedContacts =
        Array.isArray(customer.connectedContacts) ? (
          customer.connectedContacts.length > 0
            ? customer.connectedContacts.map((c: any) => c)
            : []) : (
          customer.connectedContacts
        );

      let newCreditDoc;
      if (credit) {
        const newCredit = { ...credit.credit };
        newCredit["fromBranches"] = credit.fromBranch_permissions
          .map((o: any) => {
            const branch = allbranches.find(
              (b: { _id: string }) => b._id == o.branch._id
            );
            if (branch)
              return {
                label: branch.branchName,
                value: o.branch._id,
              };
          })
          .filter((cp: any) => cp != null && cp != undefined);
        newCredit["toBranches"] = credit.toBranch_permissions
          .map((o: any) => {
            const branch = allbranches.find(
              (b: { _id: string }) => b._id == o.branch._id
            );
            if (branch)
              return {
                label: branch.branchName,
                value: o.branch._id,
              };
          })
          .filter((cp: any) => cp != null && cp != undefined);

        newCredit["fleetPermissions"] = credit.fleet_permissions
          .map((f: any) => {
            const fleet = allfleets.find(
              (fleet: { _id: string }) => fleet._id == f.fleet
            );
            if (fleet) {
              return {
                label: fleet.regNumber,
                value: f.fleet,
              };
            }
          })
          .filter((cp: any) => cp != null && cp != undefined);

        newCredit["includeDeliveryCharge"] = credit?.includeDeliveryCharge?.includeDeliveryCharge || false

        newCreditDoc = fromJS(JSON.parse(initCredit)).merge(newCredit);
      } else {
        newCreditDoc = fromJS(JSON.parse(initCredit));
      }

      return {
        ...state,
        customer: fromJS(JSON.parse(initCustomer)).merge(customerDoc),
        credit: newCreditDoc,
        allCredit: credit?.allCredit ? credit?.allCredit : [],
        customer_errors: fromJS({ ...Customer_boolean(true) }),
        customer_touched: fromJS({ ...Customer_boolean(false) }),
      };
    }
    case "CLEAR_CUST": {
      return {
        ...state,
        customer: immutableMap(JSON.parse(initCustomer)),
        credit: fromJS(JSON.parse(initCredit)),
        allCredit: [],
        customer_errors: fromJS({ ...Customer_boolean(true) }),
        customer_touched: fromJS({ ...Customer_boolean(false) }),
      };
    }
    case FETCH_DUE_CREDIT: {
      const { creditDetails, bills, receipts } = action.payload;

      const creditList = creditDetails.map((row: any) => {
        let fixedAmount = 0;
        let PackagesAmount = row.packages.reduce(
          (pv: number, cv: any) =>
            pv + (cv.actAmount ? cv.actAmount : cv.amount),
          0
        );
        // let packages=row.packages.map((pkg:any)=>{
        //   return {...pkg,amount:pkg.actAmount?pkg.actAmount:pkg.amount}
        // })
        fixedAmount = row.amount - PackagesAmount;

        return { ...row, fixed: fixedAmount };
      });
      return {
        ...state,
        originalCreditDueList: creditList,
        creditDueListUnchanged: creditList,
        bills,
        receipts: receipts.map((r: { receiptNo: any }) => ({
          value: r.receiptNo,
          label: r.receiptNo,
        })),
        invoiceDetails: [],
        invoiceParty: new Map(),
        invoiceCustomer: {},
      };
    }
    case GET_INVOICE_DETAILS: {
      const { creditRecords, docketRecords, customer } = action.payload;
      const recordsMap = new Map(
        Array.isArray(docketRecords)
          ? docketRecords.map((r) => [r.docketNumber, r])
          : []
      );
      return {
        ...state,
        invoiceDetails: creditRecords,
        invoiceParty: recordsMap,
        invoiceCustomer: customer,
        docketRecords: docketRecords,
      };
    }
    case NEW_CREDIT_SUBMIT: {
      showMessage("Successfull !", success);
      return state;
    }
    case SETTLE_DUES_SUCCESS: {
      showMessage("Credit Is Settled", success);
      const receiptNo = action.payload.receiptNo;
      return {
        ...state,
        receiptNo: receiptNo,
        printCredit: true,
      };
    }
    case "CHANGE_CUST_DET": {
      let { what, val } = action.payload;

      return {
        ...state,
        customer: state.customer.setIn(what, val),
        customer_errors: state.customer_errors.setIn(
          what,
          validate(validationSchema.getIn(what), val)
        ),
        customer_touched: state.customer_touched.setIn(what, true),
      };
    }
    case "SET_CUST_ERROR": {
      let { what } = action.payload;
      return {
        ...state,
        customer_errors: state.customer_errors.setIn(
          what,
          validate(validationSchema.getIn(what), "")
        ),
        customer_touched: state.customer_touched.setIn(what, true),
      };
    }

    case "CHANGE_CREDIT": {
      const { what, val } = action.payload;
      return {
        ...state,
        credit: state.credit.setIn(what, val),
      };
    }

    // rest credit reducer case, might not be used,verify
    // case CHANGE_PACKAGE_AMOUNT_SETTLEMENT: {
    //   const { row, val } = action.payload;
    //   const { docket, received: amount, material, packing, dimension } = row;
    //
    //   const makeUID = (p) => [p.material, p.packing, p.dimension].join("@");
    //   const uid = makeUID({ material, packing, dimension });
    //   let { creditDocketMap } = state;
    //   let temp;
    //   let flag = true;
    //   if (creditDocketMap.has(docket)) {
    //     let { packages } = creditDocketMap.get(docket);
    //     temp = packages.map((p) => {
    //       console.log("paclkage: ", p);
    //       let new_uid = [
    //         p.materialType.name,
    //         p.packingType.name,
    //         Object.keys(p.dimension).map((k) => p.dimension[k]),
    //       ].join("@");
    //       console.log("new uid: ", new_uid);
    //       console.log("uid: ", uid);
    //       if (new_uid == uid) {
    //         if (parseInt(val, 10) > parseInt(p.amount, 10)) {
    //           flag = false;
    //         }
    //         console.log("i aM HERE", { ...p }, val);
    //         return { ...p, received: val };
    //       } else return p;
    //     });
    //     creditDocketMap.set(docket, {
    //       ...creditDocketMap.get(docket),
    //       packages: temp,
    //     });
    //   }
    //   if (!flag) {
    //     return state;
    //   }
    //   return {
    //     ...state,
    //     creditDocketMap: creditDocketMap,
    //   };
    // }
    case CHANGE_PACKAGE_AMOUNT: {
      const { docketNumber, packageId, amount } = action.payload;
      if (amount < 0) return state;
      const { originalCreditDueList } = state;
      const creditList = originalCreditDueList.map((row: any) =>
        row._id == docketNumber
          ? {
            ...row,
            packages: row.packages.map((pck: any) =>
              packageId === pck._id
                ? {
                  ...pck,
                  amount: amount || 0,
                }
                : pck
            ),
          }
          : row
      );
      // if (creditDocketMap.has(docketNumber)) {
      //   const docketDoc = creditDocketMap.get(docketNumber);
      //   creditDocketMap.set(docketNumber, {
      //     ...docketDoc,
      //     packages: docketDoc.packages.map((pck: any) =>
      //       packageId === pck._id
      //         ? {
      //             ...pck,
      //             received: amount || 0,
      //           }
      //         : pck
      //     ),
      //   });
      // }
      return {
        ...state,
        originalCreditDueList: creditList,
      };
    }
    case CHANGE_PACKAGE_AMOUNT_BULK: {
      const { docketNumber, amount } = action.payload;
      if (amount < 0) return state;
      const { originalCreditDueList } = state;
      // if (creditDocketMap.has(docketNumber)) {
      //   const docketDoc = creditDocketMap.get(docketNumber);
      //   creditDocketMap.set(docketNumber, {
      //     ...docketDoc,
      //     packages: docketDoc.packages.map((pck: any) => ({
      //       ...pck,
      //       received: amount || 0,
      //     })),
      //   });
      // }
      const creditList = originalCreditDueList.map((row: any) =>
        row._id == docketNumber
          ? {
            ...row,
            packages: row.packages.map((pck: any) => ({
              ...pck,
              amount: amount || 0,
              actAmount: amount || 0,
            })),
          }
          : row
      );

      return {
        ...state,
        originalCreditDueList: creditList,
      };
    }
    case SET_EFFECTIVE_PACKAGE_AMOUNT_BULK: {
      const amount = action.payload;
      if (typeof amount !== "number" || amount < 0) return state;
      const { originalCreditDueList } = state;
      const creditList = originalCreditDueList.map((row: any) => ({
        ...row,
        packages: row.packages.map((pck: any) => ({
          ...pck,
          amount: amount || 0,
          ...(pck.actAmount ? { actAmount: amount || 0, } : {}),
        })),
      }))
      return {
        ...state,
        originalCreditDueList: creditList,
      };
    }
    case RESET_PACKAGE_AMOUNTS: {
      const docketNumber = action.payload;

      const { originalCreditDueList, creditDueListUnchanged } = state;

      const data = creditDueListUnchanged.find(
        (ele: any) => ele._id === docketNumber
      );

      const creditList = originalCreditDueList.map((r: any) =>
        r._id === docketNumber ? data : r
      );

      // if (creditDocketMap.has(docketNumber)) {
      //   const docketDoc = creditDocketMap.get(docketNumber);
      //   creditDocketMap.set(docketNumber, {
      //     ...docketDoc,
      //     packages: docketDoc.packages.map((pck: any) => ({
      //       ...pck,
      //       received: pck.amount,
      //     })),
      //   });
      // }
      return {
        ...state,
        originalCreditDueList: creditList,
      };
    }
    case RESET_ALL_PACKAGE_AMOUNTS: {
      const { creditDueListUnchanged } = state;
      return {
        ...state,
        originalCreditDueList: creditDueListUnchanged,
      };
    }
    case CLEAR_RECEIPT_NUMBER: {
      return {
        ...state,
        receiptNo: null,
      };
    }
    case TOGGLE_CREDIT_ROW: {
      const { originalCreditDueList } = state;
      const id = action.payload;
      const creditList = originalCreditDueList.map((row: any) =>
        row._id == id ? { ...row, selected: !row.selected } : row
      );
      return {
        ...state,
        originalCreditDueList: creditList,
      };
    }
    case SELECT_ALL_CREDIT_DOCKET: {
      const { originalCreditDueList } = state;
      const creditList = originalCreditDueList.map((row: any) => ({
        ...row,
        selected: action.payload,
      }));
      return {
        ...state,
        originalCreditDueList: creditList,
      };
    }
    case CREDIT_FIELD_CHANGE: {
      let { what, val } = action.payload;
      return {
        ...state,
        newCredit: state.newCredit.setIn(what, val),
      };
    }

    case CHANGE_CONNECTED_CUSTOMERS: {
      return {
        ...state,
        customer: state.customer.set("connected", action.payload),
      };
    }
    case CHANGE_PERMITTED_BRANCH: {
      return {
        ...state,
        credit: state.credit.set("permissions", action.payload),
      };
    }
    case CLOSE_CREDIT_PRINT: {
      return {
        ...state,
        printCredit: false,
      };
    }
    default: {
      return state;
    }
  }
};
