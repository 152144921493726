import React from 'react'
import "./Testimonials.css"
import { useMediaQuery, useTheme } from "@mui/material";

const Testimonials = (props:any) => {
    
  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2

  return (
    <section className="pn_reviews my-5">
      <div className="container">
          <div className="row">
              <div className="col-md-12 col-lg-4">
                  <div className="pn_testimonial_box mt-4">
                      <p className="text-justify">{props.data.Testimonials[4].feedback[0]}</p>
                      <div className="row pt-3">
                          <div className="col-md-6 pn_review_profile"><img src="https://desklib.com/static/src/assets/images/v2/profile_1.svg" className="border-radius-12 float-right" alt="client image" /> <span> Charles Boyle </span> </div>
                          {mobileView ? <br/> : ""}
                          <div className="col-md-6 pn_review_rating d-flex justify-content-end"> 5 &nbsp; <img className="image-radius aligncenter" src="https://desklib.com/static/src/assets/images/v2/star_rating.svg" alt="client stars image" /> </div>
                      </div>
                  </div>
              </div>
              <div className="col-md-12 col-lg-4">
                  <div className="pn_testimonial_box mt-4">
                      <p className="text-justify">{props.data.Testimonials[4].feedback[1]}</p>
                      <div className="row pt-3">
                          <div className="col-md-6 pn_review_profile"><img src="https://desklib.com/static/src/assets/images/v2/profile_2.svg" className="border-radius-12 float-right" alt="client image" /> <span> Gina Lannety </span> </div>
                          {mobileView ? <br/> : ""}
                          <div className="col-md-6 pn_review_rating d-flex justify-content-end"> 5 &nbsp; <img className="image-radius aligncenter" src="https://desklib.com/static/src/assets/images/v2/star_rating.svg" alt="client stars image" /> </div>
                      </div>
                  </div>
              </div>
              <div className="col-md-12 col-lg-4">
                  <div className="pn_testimonial_box mt-4">
                      <p className="text-justify">{props.data.Testimonials[4].feedback[2]}</p>
                      <div className="row pt-3">
                          <div className="col-md-6 pn_review_profile"><img src="https://desklib.com/static/src/assets/images/v2/profile_3.svg" className="border-radius-12 float-right" alt="client image" /> <span> Dug Judey </span> </div>
                          {mobileView ? <br/> : ""}
                          <div className="col-md-6 pn_review_rating d-flex justify-content-end"> 5 &nbsp; <img className="image-radius aligncenter" src="https://desklib.com/static/src/assets/images/v2/star_rating.svg" alt="stars image" /> </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </section>
  )
}

export default Testimonials 