import React, { useEffect, useState } from 'react';
import { withFormik, FormikProps, Form, Field } from 'formik';
import "../DirectSalesPerson/directSales.css"
import showMessage, { failed, showHttpError, success } from '../../../../../utils/message';
import { getOtp, individualData, verifyOtpForReg } from '../../../../../services/area';
// import ImageInput from '../../../../../Components/ImageInput';
import CustomToolTip from '../../../../../Components/CustomToolTip/CustomToolTip';
import { getCityByPincode } from '../../../../../services/area';
// import { validateIfsc } from '../../../../../services/ifsc';
import { Select } from '../../../../../Components/Selectcomponents/Select';
import { getIntraCityRates } from '../../../../../services/intraCityRate';
import { assetTypeListForOutSide } from '../../../../../services/area';
import { OtpTypes } from '../../../../../utils/otp';
// import { listAssetType } from '../../../../../services/asset';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  contact: string;
  otp: string;
  address1: string;
  address2: string;
  pincode: number;
  city: string;
  servicablecity: string;
  bankACno: number;
  ifscCode: string;
  rcbook: any;
  allindiapermit: any;
  userProfile: any;
  regNo: any;
  fleettype: any;
  bodytype: any;
  pancard: any;
}

interface OtherProps {
  message: string;
  className?: string;
  emailClass?: string;
  passClass?: string;
  firstnameClass?: string;
  lastnameClass?: string;
  contactClass?: string;
  otpClass?: string;
  commonInputFieldCss?: string;
  messageClass?: string;
  commonUsedForm?: boolean
}

const InnerForm: any = (props: OtherProps & FormikProps<FormValues>) => {
  const [slideNo, setSlideNo] = useState(0);
  const [updatedSlideNo, setUpdatedSlideNo] = useState<any>(null);
  const [cities, setCities] = useState<any>(null);
  const [selectedFleetType, setSelectedFleetType] = useState<any>(null);
  const [selectedCity, setSelectedCity] = useState<any>(null);
  const [selectedBodytype, setSelectedBodytype] = useState<any>(null);
  const [typesOfFleets, setTypesOfFleets] = useState<any>(null);
  const [cityId, setCityId] = useState("");
  const { touched, errors, message,
    emailClass, passClass,
    firstnameClass, lastnameClass,
    contactClass, otpClass,
    commonUsedForm, messageClass,
    commonInputFieldCss, resetForm, initialValues
  } : any = props;
  // const [imageOfPan , setImageOfPan] = useState<any>(null);
  // const [imageOfRcBook  , setImageOfRcBook] = useState<any>(null);
  // const [imageOfAllIndiaPermit  , setImageOfAllIndiaPermit] = useState<any>(null);

  const checkValues: any = props?.values;

  const fetchCityByPin = async () => {
    try {
      const response = await getCityByPincode(checkValues?.pincode);
      setCityId(response?._id)
      return [checkValues?.city == response?.name, response?.name]
    } catch (error) {
      showHttpError(error);
    }
  }

  // const verifyIfscCode = async (value:any) => {
  //   try {
  //     const response = await validateIfsc(value);
  //     return response
  //   } catch (error) {
  //     showHttpError(error);
  //   }
  // }

  const isValidEmail = (email: any) => {
    let error;
    if (!email) {
      error = "Required";
    }
    else if (!/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/i.test(email)) {
      error = "Invalid email address";
    }
    return error;
  }

  const isValidPassword = (pass: any) => {
    let error;
    if (!pass) {
      error = "Required";
    }
    else if (pass?.length < 6) {
      error = "Min Length 6 Is Required";
    }
    else if (!/^(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{6,16}$/.test(pass)) {
      error = "1 Uppercase AlphaBet & 1 Special Letter Is Required";
    }
    else if (pass?.length > 16) {
      error = "Pass Length Should Not Exceed 16 Letters";
    }
    return error;
  }

  const isValidName = (name: any) => {
    let error;
    if (!name) {
      error = "Required";
    }
    else if (!/^[A-Za-z]+$/i.test(name)) {
      error = "Name Should Not Contain Numericals";
    }
    return error;
  }

  const isValidContact = (contact: any) => {
    let error;
    if (!contact) {
      error = "Required";
    }
    else if (!/^[0-9].{9,9}$/i.test(contact)) {
      error = "Number Should Not be Less Then / Greater Then 10 Nos."
    }
    return error;
  }

  const isValidOtp = (otps: any) => {
    let error;
    if (!otps) {
      error = "Required";
    }
    else if (!/^[0-9].{5,5}$/i.test(otps)) {
      error = "Number Should Not be Less Then / Greater Then 6 Nos."
    }
    return error;
  }

  const isValidPincode = (pincode: any) => {
    let error;
    if (!pincode) {
      error = "Required";
    }
    else if (!/^[0-9].{5,5}$/i.test(pincode)) {
      error = "Pincode Should Not be Less Then / Greater Then 6 Nos."
    }
    return error;
  }

  const isValidRegNo = (regNumber: any) => {
    let error;
    if (!regNumber) {
      error = "Required";
    }
    else if (!/^[A-Z]{2}[0-9]{1,2}(?:[A-Z])?(?:[A-Z]*)?[0-9]{4}$/i.test(regNumber)) {
      error = "Vehicle Number is not in Valid Format ! Ex : AB01FX1234"
    }
    return error;
  }

  const isValidAddress = (address: any) => {
    let error;
    if (!address) {
      error = "Required";
    }
    else if (!/^[a-zA-Z3-9\s,'-]*.{3,}$/i.test(address)) {
      error = "Address is not valid format Or Minimum Length Of Address Must be 3"
    }
    return error;
  }

  // const isValidBanAcNo = (accountNo:any) => {
  //     let error;
  //     if(!accountNo){
  //       error = "Required";
  //     }
  //     else if (!/[0-9]{9,18}$/i.test(accountNo)){
  //       error = "Kindly enter valid account number"
  //     }
  //     return error;
  // }

  // const isValidIfscCode = (code:any) => {
  //     let error;
  //     if(!code){
  //       error = "Required";
  //     }
  //     else if (!/^[A-Z]{4}0[A-Z0-9]{6}$/i.test(code)){
  //       error = "Kindly enter valid ifsc code"
  //     }
  //     return error;
  // }

  // const isValidAadharCard = (files:any) => {
  //   let error;
  //   if(!files){
  //     error = "Required";
  //   }
  //   else if (!/[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/i.test(files)){
  //     error = "Kindly Upload Valid Document Format"
  //   }
  //   return error;
  // }

  // const isValidPanCard = (files:any) => {
  //   let error;
  //   if(!files){
  //     error = "Required";
  //   }
  //   else if (!/[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/i.test(files)){
  //     error = "Kindly Upload Valid Document Format"
  //   }
  //   return error;
  // }

  const handleNextBtnClick = async () => {
    if (slideNo === 0 && updatedSlideNo <= 0) {
      if (isValidEmail(checkValues?.email) === undefined &&
        isValidPassword(checkValues?.password) === undefined &&
        isValidName(checkValues?.firstName) === undefined &&
        isValidName(checkValues?.lastName) === undefined &&
        isValidContact(checkValues?.contact) === undefined &&
        isValidOtp(checkValues?.otp) === undefined
      ) {
        {
          let payload = { contact: +(checkValues?.contact), otp: +(checkValues?.otp), otpType: OtpTypes.IndividualWithFleetVerification }
          let reasponse: any = await verifyOtpForReg(payload);
          if (reasponse) {
            setSlideNo(slideNo + 1)
            setUpdatedSlideNo(0);
          }
          else {
            showHttpError("Otp MissMatched , Kindly Resend Otp Or Enter Valid Otp")
          }
        }
      }
      else {
        return showMessage("Kindly Fill Up All The Deatils", failed, 2000);
      }
    }
    if (slideNo === 1 && updatedSlideNo === 0) {
      if (isValidAddress(checkValues?.address1) === undefined &&
        isValidAddress(checkValues?.address2) === undefined &&
        isValidPincode(checkValues?.pincode) === undefined
      ) {
        let res: any = await fetchCityByPin();
        if (res[0] === true) {
          setSlideNo(slideNo + 1)
          setUpdatedSlideNo(1)
        }
        else {
          return showHttpError(`Kindly Enter City Name "${res[1]}"`)
        }
      }
      else {
        return showMessage("Kindly Fill Up All The Deatils", failed, 2000);
      }
    }
  }

  const RegisterIndividualWithFleet = async () => {
    try {
      let payload: any = {
        contactPerson: {
          name: {
            fName: checkValues?.firstName,
            lName: checkValues?.lastName
          },
          contact: checkValues?.contact,
          password: checkValues?.password,
          email: checkValues?.email,
        },
        address: {
          l1: checkValues?.address1,
          l2: checkValues?.address2,
          pincode: checkValues?.pincode,
          city: cityId,
        },
        otp: checkValues?.otp,
        individual: false,
        bodyType: selectedBodytype?.value,
        fleetType: selectedFleetType?.value,
        regNumber: checkValues?.regNo
      }
      await individualData(payload);
      showMessage("Individual With Fleet Registered Succesfully", success, 2000);
      setSlideNo(0);
      resetForm(initialValues);
    } catch (error) {
      showHttpError(error);
    }
  }

  // const getAssetType = async () => {
  //   try {
  //     let payload = {
  //       categoryId : `Vehicle`
  //     }
  //     let res = await listAssetType(payload);
  //     console.log("ans 2030",res)
  //   } catch (error) {
  //     showHttpError(error);
  //   }
  // }

  const getCityList = async () => {
    try {
      let payload = { type: "EXPRESS" }
      const basicCityList: any = [];
      const responseForCity = await getIntraCityRates(payload);
      responseForCity.map((ele: any) => {
        basicCityList.push(ele.city)
      })
      const FinalCityList: any = [...new Map(basicCityList.map((x: any) => [x["name"], x])).values()]
      setCities(FinalCityList);
    } catch (error) {
      showHttpError(error);
    }
  }

  const citiesOption = cities?.map((e: any) => {
    return {
      label: e.name,
      value: e._id
    }
  });

  const vehicleOption = typesOfFleets?.map((e: any) => {
    return {
      label: e.assetName,
      value: e._id
    }
  });

  const handleSubmit = () => {
    RegisterIndividualWithFleet()
  }

  // const getFleetTypeList = async () => {
  //   try {
  //     let payload = {
  //       categoryId: "vehicle",
  //     }
  //     let respponse  = await listAssetType(payload);
  //     console.log("Fonal Ans",respponse)
  //     showMessage("Listing Of Vehicles Called",success,2000)
  //   } catch (error) {
  //     showHttpError(error);
  //   }
  // }

  // useEffect(() => {
  // getAssetType();
  //   getFleetTypeList();
  // } ,[])

  const assetTypeList = async () => {
    try {
      let payload = {
        categoryId: "Vehicle"
      }
      let response = await assetTypeListForOutSide(payload);
      setTypesOfFleets(response);
    } catch (error) {
      showHttpError(error);
    }
  }

  const typesOfBody = [
    { label: "Select Body Type", value: "" },
    { label: "Flat", value: "Flat" },
    { label: "Open", value: "Open" },
    { label: "Full Covered", value: "Full Covered" },
    { label: "Half Covered", value: "Half Covered" },
  ];

  useEffect(() => {
    getCityList();
    assetTypeList();
  }, [])

  return (
    <Form id={"directSalesAgent"}>
      {message && <div className={messageClass}>{message}</div>}
      <div className='dsaTitle'> Individual With Fleet </div>
      {slideNo === 0 &&
        <div className='formDetailsForDSA'>

          <div className='commonTitleForHeading'>
            <span> General Info </span>
            <span> {slideNo + 1} / 3 </span>
          </div>

          <div className={commonUsedForm ? "commonEmailCss" : emailClass}>
            <Field type="email" name="email" placeholder="Email" className={commonUsedForm ? "commonEmailInputCss" : commonInputFieldCss} validate={isValidEmail} />
            {touched.email && errors.email &&
              <div className='commonErrorCss'>
                <CustomToolTip title={errors && errors.email && errors?.email} ><i className='fa fa-info largeScal' /></CustomToolTip>
              </div>
            }
          </div>

          <div className={commonUsedForm ? "commonPassCss" : passClass}>
            <Field type="password" name="password" placeholder="Password" className={commonUsedForm ? "commonPassInputCss" : commonInputFieldCss} validate={isValidPassword} />
            {touched.password && errors.password &&
              <div className='commonErrorCss'>
                <CustomToolTip title={errors && errors.password && errors?.password} ><i className='fa fa-info largeScal' /></CustomToolTip>
              </div>
            }
          </div>

          <div className={commonUsedForm ? "commonFirstNameCss" : firstnameClass}>
            <Field type="text" name="firstName" placeholder="First Name" className={commonUsedForm ? "commonFirstNameInputCss" : commonInputFieldCss} validate={isValidName} />
            {touched.firstName && errors.firstName &&
              <div className='commonErrorCss'>
                <CustomToolTip title={errors && errors.firstName && errors?.firstName} ><i className='fa fa-info largeScal' /></CustomToolTip>
              </div>
            }
          </div>

          <div className={commonUsedForm ? "commonLastNameCss" : lastnameClass}>
            <Field type="text" name="lastName" placeholder="Last Name" className={commonUsedForm ? "commonLastNameInputCss" : commonInputFieldCss} validate={isValidName} />
            {touched.lastName && errors.lastName &&
              <div className='commonErrorCss'>
                <CustomToolTip title={errors && errors.lastName && errors?.lastName} ><i className='fa fa-info largeScal' /></CustomToolTip>
              </div>
            }
          </div>

          <div className={commonUsedForm ? "commonContactCss" : contactClass}>
            <Field type="number" name="contact" placeholder="Contact No" className={commonUsedForm ? "commonContactInputCss" : commonInputFieldCss} validate={isValidContact} />
            {touched.contact && errors.contact &&
              <div className='commonErrorCss'>
                <CustomToolTip title={errors && errors.contact && errors?.contact} ><i className='fa fa-info largeScal' /></CustomToolTip>
              </div>
            }
          </div>

          <div className='commonOtpCss'>
            <button className='commonOtpBtnCss' onClick={async () => {
              console.log("From OTP", props)
              if (touched.contact && errors.contact) {
                showMessage("Kindly Enter Valid Number", failed, 2000)
              }
              if (!errors.email && !errors.password && !errors.firstName && !errors.lastName && !errors.contact) {
                await getOtp({
                  contact: +(props.values.contact),
                  otpType: OtpTypes.IndividualWithFleetVerification
                });
                showMessage("Otp sent SuccesFully !", success, 2000)
              }
            }}>Get Otp</button>
          </div>

          <div className={commonUsedForm ? "commonOTPCss" : otpClass}>
            <Field type="number" name="otp" placeholder="Enter OTP" className={commonUsedForm ? "commonOTPInputCss" : commonInputFieldCss} validate={isValidOtp} />
            {touched.otp && errors.otp &&
              <div className='commonErrorCss'>
                <CustomToolTip title={errors && errors.otp && errors?.otp} ><i className='fa fa-info largeScal' /></CustomToolTip>
              </div>
            }
          </div>
        </div>
      }
      {slideNo === 1 &&
        <div className='formDetailsForDSA'>

          <div className='commonTitleForHeading'>
            <span> Address Details </span>
            <span> {slideNo + 1} / 3 </span>
          </div>

          <div className={commonUsedForm ? "commonEmailCss" : emailClass}>
            <Field type="text" name="address1" placeholder="Enter Address Line 1" className={commonUsedForm ? "commonEmailInputCss" : commonInputFieldCss} validate={isValidAddress} />
            {touched.address1 && errors.address1 &&
              <div className='commonErrorCss'>
                <CustomToolTip title={errors && errors.address1 && errors?.address1} ><i className='fa fa-info largeScal' /></CustomToolTip>
              </div>
            }
          </div>

          <div className={commonUsedForm ? "commonPassCss" : passClass}>
            <Field type="text" name="address2" placeholder="Enter Address Line 2" className={commonUsedForm ? "commonPassInputCss" : commonInputFieldCss} validate={isValidAddress} />
            {touched.address2 && errors.address2 &&
              <div className='commonErrorCss'>
                <CustomToolTip title={errors && errors.address2 && errors?.address2} ><i className='fa fa-info largeScal' /></CustomToolTip>
              </div>
            }
          </div>

          <div className={commonUsedForm ? "commonFirstNameCss" : firstnameClass}>
            <Field type="number" name="pincode" placeholder="Pincode" className={commonUsedForm ? "commonFirstNameInputCss" : commonInputFieldCss} validate={isValidPincode} />
            {touched.pincode && errors.pincode &&
              <div className='commonErrorCss'>
                <CustomToolTip title={errors && errors.pincode && errors?.pincode} ><i className='fa fa-info largeScal' /></CustomToolTip>
              </div>
            }
          </div>

          <div className={commonUsedForm ? "commonLastNameCss" : lastnameClass}>
            <Field type="text" name="city" placeholder="City" className={commonUsedForm ? "commonLastNameInputCss" : commonInputFieldCss} />
            {touched.city && errors.city &&
              <div className='commonErrorCss'>
                <CustomToolTip title={errors && errors.city && errors?.city} ><i className='fa fa-info largeScal' /></CustomToolTip>
              </div>
            }
          </div>
        </div>
      }
      {slideNo === 2 &&
        <div className='formDetailsForDSA'>

          <div className='commonTitleForHeading'>
            <span> Vehicle Info </span>
            <span> {slideNo + 1} / 3 </span>
          </div>

          <div className={commonUsedForm ? "commonLastNameCss" : lastnameClass}>
            <Field type="text" name="regNo" placeholder="Vehicle Number" className={commonUsedForm ? "commonLastNameInputCss" : commonInputFieldCss} validate={isValidRegNo} />
            {touched.regNo && errors.regNo &&
              <div className='commonErrorCss'>
                <CustomToolTip title={errors && errors.regNo && errors?.regNo} ><i className='fa fa-info largeScal' /></CustomToolTip>
              </div>
            }
          </div>

          <div className={commonUsedForm ? "commonLastNameCss" : lastnameClass}>
            <div className='selectCompanyTypeforTCC'>
              <Select
                placeholder="Select Your Availability service"
                value={selectedCity}
                options={citiesOption}
                onChange={(e: any) => setSelectedCity(e)}
                className="selectForTCCompany"
              />
            </div>
            {touched.servicablecity && errors.servicablecity &&
              <div className='commonErrorCss'>
                <CustomToolTip title={errors && errors.servicablecity && errors?.servicablecity} ><i className='fa fa-info largeScal' /></CustomToolTip>
              </div>
            }
          </div>

          <div className={commonUsedForm ? "commonPassCss" : passClass}>
            <div className='selectCompanyTypeforTCC'>
              <Select
                placeholder="Select Fleet Type"
                value={selectedFleetType}
                options={vehicleOption}
                onChange={(e: any) => setSelectedFleetType(e)}
                className="selectForTCCompany"
              />
            </div>
            {touched.fleettype && errors.fleettype &&
              <div className='commonErrorCss'>
                <CustomToolTip title={errors && errors.fleettype && errors?.fleettype} ><i className='fa fa-info largeScal' /></CustomToolTip>
              </div>
            }
          </div>

          <div className={commonUsedForm ? "commonPassCss" : passClass}>
            <div className='selectCompanyTypeforTCC'>
              <Select
                placeholder="Select Body Type"
                value={selectedBodytype}
                options={typesOfBody}
                onChange={(e: any) => setSelectedBodytype(e)}
                className="selectForTCCompany"
              />
            </div>
            {touched.bodytype && errors.bodytype &&
              <div className='commonErrorCss'>
                <CustomToolTip title={errors && errors.bodytype && errors?.bodytype} ><i className='fa fa-info largeScal' /></CustomToolTip>
              </div>
            }
          </div>
        </div>
      }
      <div className='btnsForSlider'>
        {slideNo > 0 && <button className='nextBtnsCSS' onClick={() => { setSlideNo(slideNo - 1), setUpdatedSlideNo(updatedSlideNo - 1) }}> Previous </button>}
        {slideNo <= 1 &&
          <button
            type='submit'
            className='prevBtnsCSS'
            onClick={() => handleNextBtnClick()}
          >
            Next
          </button>
        }
        {slideNo > 1 && <button type='submit' className='prevBtnsCSS' onClick={() => handleSubmit()}> Submit </button>}
      </div>
    </Form>
  );
};

interface MyFormProps {
  initialEmail?: string;
  className?: string;
  emailClass?: string;
  passClass?: string;
  firstnameClass?: string;
  lastnameClass?: string;
  contactClass?: string;
  otpClass?: string;
  commonInputFieldCss?: string;
  messageClass?: string;
  commonUsedForm?: boolean;
  message?: string;
  submitFunc?: Function;
}

const IndividualWithFleet = withFormik<MyFormProps, FormValues, OtherProps>({
  mapPropsToValues: (props: any) : any => {
    return {
      firstName: '',
      lastName: '',
      email: props.initialEmail || '',
      password: '',
      contact: '',
      otp: '',
      address1: '',
      address2: '',
      pincode: null,
      city: '',
      bankACno: '',
      ifscCode: '',
      allindiapermit: '',
      rcbook: '',
      regNo: '',
      fleettype: '',
      bodytype: '',
      servicablecity: '',
      pancard: '',
      userProfile : ''
    };
  },
  handleSubmit : (values:any) => {
    console.log("Final Submit Ans",values)
  } 
})(InnerForm);

export default IndividualWithFleet;
