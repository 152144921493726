import React, {
  Children,
  cloneElement,
  ComponentProps,
  createContext,
  isValidElement,
  ReactNode,
  useContext,
  useState,
} from "react";
import { DownIcon, UpIcon } from "../../../constants/icons";
import "./accordion.css";

// Define AccordionContext Type
type AccordionContextType = {
  isAccordionOpen: boolean;
  toggleAccordion: () => void;
};

// Define AccordionProps Type
type AccordionProps = {
  children: ReactNode;
} & ComponentProps<"div">;

// Create Context with default value null
const AccordionContext = createContext<AccordionContextType | null>(null);

// Accordion Component
export function Accordion({ children, className = "", ...props }: AccordionProps) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  // Function to toggle accordion state
  const toggleAccordion = () => {
    setIsAccordionOpen((prev) => !prev);
  };

  return (
    <AccordionContext.Provider value={{ isAccordionOpen, toggleAccordion }}>
      <div className={`taptap-accordion flex-col border-white br-4 ${className}`} {...props}>
        {/* Map through children */}
        {Children.map(children, (child) => {
          if (isValidElement(child)) {
            return cloneElement(child);
          }
          return null;
        })}
      </div>
    </AccordionContext.Provider>
  );
}

// AccordionSummary Component
type AccordionSummaryProps = {
  children: ReactNode;
} & ComponentProps<"div">;

// AccordionSummary Component
export function AccordionSummary({
  children,
  className = "",
  onClick,
  ...props
}: AccordionSummaryProps) {
  const context = useContext(AccordionContext);

  if (!context) {
    throw new Error("AccordionSummary must be used within an Accordion");
  }

  const { isAccordionOpen, toggleAccordion } = context;

  return (
    <div
      className={`taptap-accordion-summary flex-between gap-1 cursor-pointer p-1 ${className}`}
      onClick={(e) => {
        toggleAccordion();
        onClick?.(e);
      }}
      {...props}
    >
      {children}
      <span className="ml-auto flex-center pointer user-select-none">
        {isAccordionOpen ? <UpIcon /> : <DownIcon />}
      </span>
    </div>
  );
}

// AccordionDetails Component
type AccordionDetailsProps = {
  children: ReactNode;
} & ComponentProps<"div">;

// AccordionDetails Component
export function AccordionDetails({
  children,
  className = "",
  ...props
}: AccordionDetailsProps) {
  const context = useContext(AccordionContext);

  if (!context) {
    throw new Error("AccordionDetails must be used within an Accordion");
  }

  const { isAccordionOpen } = context;

  if (!isAccordionOpen) return null;

  return (
    <div className={`${className}taptap-accordion-details | pt-0 p-1`} {...props}>
      {children}
    </div>
  );
}

AccordionSummary.displayName = "AccordionSummary";
AccordionDetails.displayName = "AccordionDetails";
