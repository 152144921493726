import { buildQueryString, ServiceMaker } from "./index";

export const getAutoCompleteOptions = (data:any) =>
  ServiceMaker(`googleMap/autocomplete?${buildQueryString(data)}`, "GET");

export const getAutoCompleteGeneralUsage = (data:any) =>
  ServiceMaker(`googleMap/autocomplete/general?${buildQueryString(data)}`, "GET");

export const getPlaceData = (data:any) =>
  ServiceMaker(`googleMap/getPlaceData?${buildQueryString(data)}`, "GET");

export const getPlaceDataFromLatLng = (data:any) =>
  ServiceMaker(`googleMap/getPlaceData?${buildQueryString(data)}`, "GET");

export const getPlaceDataFromLatLngDetail = (data:any) =>
  ServiceMaker(`googleMap/reverseGeoCode?${buildQueryString(data)}`, "GET");