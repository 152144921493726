const prefix = 'USER:';

export const GET_CITY = prefix + 'GET_CITY'
export const GETDETAILS = prefix + 'GETDETAILS'
export const CHANGE_INPUT = prefix + 'CHANGE_FLAT'
export const USER_CREATE_SUCCESS = prefix + 'USER_CREATE_SUCCESS'
export const USER_CREATE_FAILED = prefix + 'USER_CREATE_FAILED'
export const USER_EDIT_SUCCESS = prefix + 'USER_EDIT_SUCCESS'
export const USER_EDIT_FAILED = prefix + 'USER_EDIT_FAILED'
export const RESET_USER_FORM = prefix + 'RESET_USER_FORM'
export const RESET_USER_FORM_NEW_USER = prefix + 'RESET_USER_FORM_NEW_USER'
export const ASSIGN_BRANCH = prefix + 'ASSIGN_BRANCH'
export const ASSIGN_FLEET = prefix + 'ASSIGN_FLEET'
export const CHANGE_ASSIGNED_INFO = prefix + 'CHANGE_ASSIGNED_INFO'
export const ASSIGN_BRANCH_FAILED = prefix + 'ASSIGN_BRANCH_FAILED'
export const ASSIGN_FLEET_FAILED = prefix + 'ASSIGN_FLEET_FAILED'
export const FETCH_DEPARTMENT_LIST = prefix + 'FETCH_DEPARTMENT_LIST'
export const GET_BRANCHES_BY_COMPANY = prefix + 'GET_BRANCHES_BY_COMPANY'


export const USER_ATTENDANCE_CREATE_SUCCESS = prefix + 'USER_ATTENDANCE_CREATE_SUCCESS'
export const USER_ATTENDANCE_CREATE_FAILED = prefix + 'USER_ATTENDANCE_CREATE_FAILED'

