const prefix = 'EXPENSE: '


export const RESET_INCUR_EXPENSE = prefix + 'RESET_INCUR_EXPENSE'
export const DELETE_EXPENSE_RECORD = prefix + 'DELETE_EXPENSE_RECORD'
export const EDIT_EXPENSE_RECORD = prefix + 'EDIT_EXPENSE_RECORD'
export const EDIT_EXPENSE_RECORD_FAILED = prefix + 'EDIT_EXPENSE_RECORD_FAILED'
export const SET_EXPENSE_INCUR = prefix + 'SET_EXPENSE_INCUR'
export const NON_CASH_CHANGE = prefix + 'NON_CASH_CHANGE'
export const FIXED_EXPENSE_TYPE = prefix + 'FIXED_EXPENSE_TYPE'

export const POST_INCUR_EXPENSE_FAILED = prefix + 'POST_INCUR_EXPENSE_FAILED'

//fuel related constants
export const FUEL_IN_EXPENSE_ON_CHANGE = prefix + 'FUEL_IN_EXPENSE_ON_CHANGE'
export const COULD_NOT_FETCH_CONTACT = prefix + 'COULD_NOT_FETCH_CONTACT'
export const FUEL_EXPENSE_CREATE_SUCCESS = prefix + 'FUEL_EXPENSE_CREATE_SUCCESS'
export const SET_FUEL_VALUES = prefix + 'SET_FUEL_VALUES'
export const DELETE_FUEL_EXPENSE = prefix + 'DELETE_FUEL_EXPENSE'
export const DELETE_EXPENSE_RECORD_FAILED = prefix + 'DELETE_EXPENSE_RECORD_FAILED'

export const SET_PAST_EXPENSES = prefix + 'SET_PAST_EXPENSES'
export const AVAILABLE_BALANCE = prefix + 'AVAILABLE_BALANCE'
export const DISABLE_OP_KMS = prefix + 'DISABLE_OP_KMS'
export const ENABLE_OP_KMS = prefix + 'ENABLE_OP_KMS'
export const SHOW_LOADER_IN_AMOUNT = prefix + 'SHOW_LOADER_IN_AMOUNT'
export const HIDE_LOADER_IN_AMOUNT = prefix + 'HIDE_LOADER_IN_AMOUNT'
export const PRINT_EXPENSE_VOUCHER = prefix + 'PRINT_EXPENSE_VOUCHER'
export const CLOSE_VOUCHER_MODAL = prefix + 'CLOSE_VOUCHER_MODAL'