import React, { CSSProperties, forwardRef } from "react";
import ReactSelect, { Props as SelectProps } from "react-select";
import makeAnimated from "react-select/animated";
import "./react-select.css";

// Define the props type using generics with a default value for OptionType
export type TSelectProps = SelectProps & {
  error?: boolean;
  errorMessage?: string;
  label?: string;
  containerClassName?: string;
  containerStyle?: CSSProperties;
};

// ForwardRef function component
const Select = forwardRef<HTMLDivElement, TSelectProps>(
  (
    {
      isMulti,
      components,
      error,
      errorMessage,
      label,
      className = "",
      isDisabled,
      containerClassName = "",
      containerStyle,
      ...props
    },
    ref
  ) => {
    // Handle animated components based on isMulti and custom components
    const animatedComponents =
      components ?? (isMulti ? makeAnimated() : undefined);

    const content = (
      <ReactSelect
        className={`taptap-react-select-input fs-small ${className} ${
          isDisabled ? "cursor-not-allowed" : ""
        }`}
        isDisabled={isDisabled}
        isMulti={isMulti}
        components={animatedComponents}
        {...props}
        ref={ref as any} // Forward the ref to the ReactSelect component
      />
    );

    // Conditionally render label and error message
    if (label || errorMessage) {
      return (
        <div className={`${label ? "flex-col gap-4" : ""} ${containerClassName}`} style={containerStyle}>
          {label && (
            <label
              className={`taptap-react-select-label fw-400 m-0 ${
                isDisabled ? "text-white-300 cursor-not-allowed" : "text-black-700"
              }`}
            >
              {label}
            </label>
          )}
          {content}
          {error && errorMessage && (
            <span className="text-danger-400 text-xs">{errorMessage}</span>
          )}
        </div>
      );
    }

    return content;
  }
);

// Provide display name for debugging
Select.displayName = "Select";

export default Select;
