import { RightsActionTypes, SET_RIGHTS } from "../constants/rights";
import { IDepartmentRights, processRights } from "./utils/ProcessRights";

export interface RightsState extends IDepartmentRights<boolean> {
  role?: "S" | "A" | "O";
}

const initState: RightsState = processRights([]);

export default (state = initState, action: RightsActionTypes): RightsState => {
  switch (action.type) {
    case SET_RIGHTS: {
      const rights = processRights(action.payload);
      return { ...state, ...rights, role: action.role };
    }

    default: {
      return state;
    }
  }
};
