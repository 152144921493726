import { AppRightsActionTypes, SET_APPRIGHTS } from "../constants/appRights";
import { processAppRights } from "./utils/ProcessRights";

const initState = processAppRights([]);

type AppRightsState = typeof initState;

export default (
  state = initState,
  action: AppRightsActionTypes
): AppRightsState => {
  switch (action.type) {
    case SET_APPRIGHTS: {
      const rights = processAppRights(action.payload);
      return { ...state, ...rights };
    }

    default: {
      return state;
    }
  }
};
