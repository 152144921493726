import React, { PropsWithChildren } from "react";
import { CSSTransition } from "react-transition-group";
import "./fade.css"; // Import the CSS for fade transitions

type FadeProps = {
  in: boolean;
  timeout?: number;
  unmountOnExit?: boolean;
} & PropsWithChildren;

const Fade = ({ in: inProp, children, unmountOnExit, timeout = 300, ...rest }: FadeProps) => (
  <CSSTransition
    in={inProp}
    timeout={timeout}
    classNames="taptap-custom-fade"
    unmountOnExit={unmountOnExit}
    {...rest}
  >
    {children}
  </CSSTransition>
);

export default Fade;
