import { ServiceMaker,buildQueryString } from "./index";


export const getPaymentList = (data?:any) =>
  ServiceMaker(`company/paymenttype?${buildQueryString(data)}`, "GET");

export const getCustomerPaymentList = (data:any) =>
  ServiceMaker(`customerBooking/paymenttype?${buildQueryString(data)}`, "GET");

export const savePaymentType = (data: any) =>
  ServiceMaker("company/paymenttype", "POST", data);

export const editPaymentType = (data: any) =>
  ServiceMaker("company/paymenttype", "PATCH", data);

export const listUsersService = () =>
  ServiceMaker("user/list", "POST");

export const listUserEntity = (userId: string) =>
  ServiceMaker(`user/assignedEntities/${userId}`, "GET");