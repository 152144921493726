import React from "react";
// import BootstrapTable from "../../Components/BootStrapTable/BootStrapTable";
import { Slide } from "@mui/material";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "../../Components/Dialog/DialogComponent";
import { ButtonLight } from "../../Components/Button/Buttoncontainer";
import ReactTable from "../../Components/ReactTable/ReactTable";

function Transition(props: any) {
  return <Slide direction="up" {...props} />;
}
const PackageDescriptionDialog = (props: any) => {
  let { arr, showDialog, columns, closeDialog } = props;

  return (
    <Dialog
      open={showDialog}
      onClose={closeDialog}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogTitle id="alert-dialog-title">
          {"Package Description"}
        </DialogTitle>
        <ReactTable data={arr} columns={columns} />
      </DialogContent>
      {/* <DialogContent><DialogTitle id="alert-dialog-title">{"Cancelation Info"}</DialogTitle>
				{(canceledInfo && canceledInfo.user) ?
					<BootstrapTable
						keyField='canceldetails'
						data={[canceledInfo]}
						columns={[cancelcolumns]}
					/>
					:
					null
				}
			</DialogContent> */}
      <DialogActions>
        <ButtonLight onClick={closeDialog}>Close</ButtonLight>
      </DialogActions>
    </Dialog>
  );
};
export default PackageDescriptionDialog;
