import { ServiceMaker, buildQueryString } from "./index";

import API from "../axios";
import { AxiosResponse, AxiosError } from "axios";

export interface GraceTowerBranchSummaryReport {
  month: number
  year: number
  branch: string
}

export interface GraceTowerUserSummaryReport {
  month: number
  year: number
  user: string
}

export interface GraceTowerBranchSummaryReportResp {
  _id: string;
  locked: number;
  lockedWithGrace: number;
  independentlyUnlocked: number;
  reason: number;
}

export interface GraceTowerUserSummaryReportResp {
  _id: string;
  locked: number;
  lockedWithGrace: number;
  independentlyUnlocked: number;
  reason: number;
}

export interface LateCheckInEarlyCheckOutReportReq {
  company: string;
  month: number;
  year: number
}

export interface PeerReviewReportReq {
  month: number;
  year: number
}

export interface BranchActivityMonthWiseReportReq {
  month: number;
  year: number
}

export const viewDailyReport = (data: Object) =>
  ServiceMaker("report/view", "POST", data);

export const listPendingReports = (data: Object) =>
  ServiceMaker("report/pending", "POST", data);

export const listPendingTransactionReports = (data: Object) =>
  ServiceMaker(`transactions/pendingReport?${buildQueryString(data)}`, "GET");

export const clearenceReport = (data: Object) =>
  ServiceMaker("report/clearence", "POST", data);

export const clearenceReportD2 = (data: Object) =>
  ServiceMaker("report/clearence/d2", "POST", data);

export const getReportByNumber = (data: Object) =>
  new Promise((resolve, reject) => {
    API.post("report/fetch", data)
      .then((res: AxiosResponse) => resolve(res.data.response))
      .catch((err: AxiosError) => reject(err.response));
  });

export const fetchBookingReportSummary = (data: Object) =>
  ServiceMaker("report/booking/summary", "POST", data);

export const fetchPartLoadFullLoadBookingReport = (data: Object) =>
  ServiceMaker("report/partLoadFullLoadBookingReport", "POST", data);

export const fetchPartLoadFullLoadBookingReportSummary = (data: Object) =>
  ServiceMaker(`report/partLoadFullLoad/summary`, "POST", data);

export const fetchPartLoadFullLoadBookingReportMonthWise = (data: Object) =>
  ServiceMaker(`report/partLoadFullLoadMonthWiseReport?${buildQueryString(data)}`, "GET", data);

export const getFleetwiseExpenseService = (data: Object) =>
  ServiceMaker("expense/filter", "POST", data);

export const getDeliveryReport = (data: Object) =>
  ServiceMaker("report/delivery", "POST", data);

export const fetchSettlementRecords = (data: Object) =>
  ServiceMaker("report/settlement", "POST", data);

export const getLoadingReport = (data: Object) =>
  ServiceMaker("report/loading/view", "POST", data);

export const getLoadingReportList = (data: Object) =>
  ServiceMaker("report/loading/list", "POST", data);

export const getRecentLoadingReportList = (data: Object) =>
  ServiceMaker("report/recent/loading/view", "POST", data);

export const userBalanceByCompany = (data: Object) =>
  ServiceMaker(`expense/balance/user/list`, "POST", data);

export const userBalanceReport = (data: Object) =>
  ServiceMaker(`expense/balanceReport/user?${buildQueryString(data)}`, "GET");

export const getRemarks = (company: string) =>
  ServiceMaker(`issues/list/${company}`, "GET");

export const getParcel = (fleetNumber: string, data: any) =>
  ServiceMaker(
    `package/getFromFleet/${fleetNumber}?${buildQueryString(data)}`,
    "POST",
    data
  );

export const changeUrgencyService = (data: any) => {
  return new Promise((resolve, reject) => {
    API.put("issues/update/urgency/" + data.id, data)
      .then((res: AxiosResponse) => resolve(res.data.response))
      .catch((err: AxiosError) => reject(err.response ? err.response : err));
  });
};

export const listCreditCustomersByCompany = (company: string) =>
  ServiceMaker(`credit/list/customers/${company}`, "GET");

export const listCreditCustomersByCompanyOrBranch = (data: any) =>
  ServiceMaker(
    `credit/pending/monthwisereport?${buildQueryString(data)}`,
    "GET"
  );

export const resolveIssueService = (data: any) => {
  return new Promise((resolve, reject) => {
    API.put("issues/resolve/" + data.id, data)
      .then((res: AxiosResponse) => resolve(res.data.response))
      .catch((err: AxiosError) => reject(err.response ? err.response : err));
  });
};
export const shortageReportAmtService = (data: Object) => {
  return new Promise((resolve, reject) => {
    API.post("report/shortage/amtwise", data)
      .then((res: AxiosResponse) => resolve(res.data.response))
      .catch((err: AxiosError) => reject(err.response ? err.response : err));
  });
};
export const verifyShortageReportAmt = (data: Object) =>
  ServiceMaker("verification/verifySettledAmount", "PATCH", data);

export const shortageReportPackService = (data: Object) => {
  return new Promise((resolve, reject) => {
    API.post("report/shortage/packwise", data)
      .then((res: AxiosResponse) => resolve(res.data.response))
      .catch((err: AxiosError) => reject(err.response ? err.response : err));
  });
};

export const listUngeneratedMemos = (company: string) =>
  ServiceMaker("report/ungeneratedmemo/companywise", "POST", {
    company: company,
  });

export const listUserbalanceLimitExceed = (company: string) =>
  ServiceMaker("report/userbalance/limit/exceed/companywise", "POST", {
    company: company,
  });

type MISPayload = {
  company?: string;
  sub: string;
  entity: string;
  month: any;
  year: any;
  route: string;
};

export const getMISService = (data: MISPayload) =>
  ServiceMaker(`report/mis?${buildQueryString(data)}`, "GET");
export const getRouteMISService = (data: MISPayload) =>
  ServiceMaker(`report/routeMIS?${buildQueryString(data)}`, "GET");

export type BranchMIS = {
  expenses: Array<{
    ref_id: number;
    branch: string;
    total_amount: number;
    itemCount: number;
    expenseType: {
      name: string;
      variance: string;
    };
  }>;
  BC: Array<{
    _id: string;
    grossSum: number;
    amount: number;
    bCount: number;
  }>;
  DC: Array<{
    _id: string;
    amount: number;
    dCount: number;
  }>;
};

export const getBranchMISService = (data: any): Promise<BranchMIS> =>
  ServiceMaker(`report/branchMIS`, "POST", data);

type OccupancyPayload = {
  start_date: String;
  end_date: String;
};
export const occupancyReportService = (data: OccupancyPayload) =>
  ServiceMaker("report/occupancy", "POST", data);

type IncentivePayload = {
  startDate: String;
  endDate: String;
};
export const incentiveReportService = (data: IncentivePayload) =>
  ServiceMaker("report/incentive", "POST", data);

export const fetchFuelEfficiencyRank = (data: Object) =>
  ServiceMaker("report/fuelrank", "POST", data);

export const listEwayBillDockets = (data?: any) => ServiceMaker(`report/ewaybill?${buildQueryString(data)}`, "POST", data);

export const filteredListEwayBillDockets = (data?: any) => ServiceMaker(`report/ewaybill?${buildQueryString(data)}`, "POST", data);

export const verifyPartBService = (docketNumber: string, eWayBill: number) =>
  ServiceMaker("report/verify/partb", "PUT", { docketNumber, eWayBill });

export const retryPartBService = (docketNumber: string) =>
  ServiceMaker("report/retry/partb", "PUT", { docketNumber });

export const getUserDashboardData = (data: Object) =>
  ServiceMaker(`report/userdashboardData?${buildQueryString(data)}`, "GET");

export const getUserDashboardDataD2 = (data: Object) =>
  ServiceMaker(`report/userdashboardData/d2?${buildQueryString(data)}`, "GET");

export const getCustomerCreditData = (data: Object) =>
  ServiceMaker(`credit/creditReport?${buildQueryString(data)}`, "GET");

export const unverifiedPackagesCount = (data: Object) =>
  ServiceMaker(
    `package/unverifiedPackagesCount?${buildQueryString(data)}`,
    "GET"
  );
export const pendingCount = (data: any) =>
  ServiceMaker(`dashboard/pendingActionCounts?${buildQueryString(data)}`, "GET");

export const getFleetUtilizationReport = (startDate: Date, endDate: Date) =>
  ServiceMaker(
    `report/fleet/utilization?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
    "GET"
  );

export const getEWaybillCountReport = () =>
  ServiceMaker(`report/ewaybill/unverifiedcount`, "GET");

export const getIncentiveUserList = (data: any) =>
  ServiceMaker(`incentive/log/user?${buildQueryString(data)}`, "GET");

export const getParcelTransferList = (data: any) =>
  ServiceMaker(
    `package/loading/listParcelTransferLogs?${buildQueryString(data)}`,
    "GET"
  );

export const sendParcelTransfer = (data: any) =>
  ServiceMaker("package/loading/parcelTransferLogs", "POST", data);

export const getFleetActivityLogs = (data: any) =>
  ServiceMaker(`fleet/fleetActivityLogs`, "POST", data);

export const getFleetSummary = (data: any) =>
  ServiceMaker(`fleet/fleetSummary?${buildQueryString(data)}`, "GET");


export const getDayWiseFleetSummary = (data: any) =>
  ServiceMaker(`report/dayWiseFleetReport?${buildQueryString(data)}`, "GET")


export const doorDeliveryRevenueReport = (data: any) =>
  ServiceMaker(`report/doorDeliveryRevenueReport?${buildQueryString(data)}`, "GET")

export const incidentalDocketsBranchReport = (data: any) =>
  ServiceMaker(`report/incidentalDockets/branch?${buildQueryString(data)}`, "GET")

export const incidentalDocketsFleetReport = (data: any) =>
  ServiceMaker(`report/incidentalDockets/fleet?${buildQueryString(data)}`, "GET")

export const incidentalDocketsCount = (data: any) =>
  ServiceMaker(`report/incidentalDockets/counts?${buildQueryString(data)}`, "GET")

export const getPendingActionCnt = (data: any) =>
  ServiceMaker(`dashboard/pendingActionCounts/fleet?${buildQueryString(data)}`, "GET")

export const getDoorDeliveryDocketWise = (data: any) =>
  ServiceMaker(`report/doorDelivery?${buildQueryString(data)}`, "GET")

export const getCallRecordForDoorDelivery = (data: any) =>
  ServiceMaker(`doorDelivery/voiceCallRecords?${buildQueryString(data)}`, "GET")

export const getVoiceCallRecordsCount = (data: any) =>
  ServiceMaker(`doorDelivery/voiceCallRecordsCount?${buildQueryString(data)}`, "GET")

export const getVoiceCallRecordsCountMonthWise = (data: any) =>
  ServiceMaker(`doorDelivery/voiceCallRecordsCountMonthWise?${buildQueryString(data)}`, "GET")

export const setOfRulesBasedOnUser = (data: any) =>
  ServiceMaker(`user/setOfRulesBasedOnUser`, "POST", data)

export const doorDeliveryTelecallerSummeryMonthly = (data: any) =>
  ServiceMaker(`report/telecallerSummaryMonthly?${buildQueryString(data)}`, "GET")

export const setCustomerDetails = () =>
  ServiceMaker(`customer/customerDetails`, "GET")

export const setAddRemarkBasedOnCustomer = (data: any) =>
  ServiceMaker(`customer/remarks`, "POST", data)

export const listOfTelecaller = () =>
  ServiceMaker(`user/listOfTelecaller`, "GET")

export const getSalaryReport = (data: any) =>
  ServiceMaker(`report/salary?${buildQueryString(data)}`, "GET")

export const getDayWiseCCAvenueTxnsReport = (data: any) =>
  ServiceMaker(`report/getDayWiseCCAvenueTxns?${buildQueryString(data)}`, "GET")

export const referenceModuleApi = (data: any) =>
  ServiceMaker(`report/referenceApi?${buildQueryString(data)}`, "GET")

export const graceTowerBranchSummary = (query?: GraceTowerBranchSummaryReport) =>
  ServiceMaker<GraceTowerBranchSummaryReportResp[]>(`report/graceTowerBranchSummary?${buildQueryString(query || {})}`, "GET");

export const graceTowerUserSummary = (query?: GraceTowerUserSummaryReport) =>
  ServiceMaker<GraceTowerUserSummaryReportResp[]>(`report/graceTowerUserSummary?${buildQueryString(query || {})}`, "GET");

export const getBranchWiseGiverAndTakerReport = (data: any) =>
  ServiceMaker<any>(`report/BranchWiseGiverAndTaker`, "POST", data);

export const getAttendanceSummaryReport = (query: LateCheckInEarlyCheckOutReportReq) =>
  ServiceMaker<any>(`report/attendanceSummaryReport?${buildQueryString(query || {})}`, "GET");

export const getPeerReviewReport = (query: PeerReviewReportReq) =>
  ServiceMaker<any>(`report/peerReviewReport?${buildQueryString(query || {})}`, "GET");

export const companyBalanceDetails = (data: any) => {
    return ServiceMaker(
      `dashboard/companyBalanceDetails?${buildQueryString(data)}`,
      "GET"
    );
  };

  export const getBranchTimingAndCameraStatusMonthWiseReport = (query: BranchActivityMonthWiseReportReq) =>
  ServiceMaker<any>(`report/branchTimingAndCameraStatusMonthWiseReport?${buildQueryString(query || {})}`, "GET");

export const getGstCustodyPenaltyMonthWiseReport = (query: any) =>
  ServiceMaker<any>(`report/gstCustodyPenaltyMonthWiseReport?${buildQueryString(query)}`, "GET");