import { ALL_PLATFORM_DRIVERS_LIST } from "../constants/allPlatformDriverList";

const initSDtate:any = [];

const allPlatformDriverList = (state = initSDtate , action: any) => {
    const {type , payload} = action;
    // console.log(`Settings Api Called From Reducer New Payload` , payload)
    switch (type) {
        case ALL_PLATFORM_DRIVERS_LIST : {
            return [
                ...payload
            ]
        }
        default:
        return state;
    }
}

export default allPlatformDriverList;