import React, {
  ComponentProps,
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef
} from "react";
// css
import "./dialog.css";
// icons
import { createPortal } from "react-dom";
import type  { CSSColorVariantType } from "../../../types/common";

export type DialogProps = {
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  className?: string;
  variant?: CSSColorVariantType;
  isBlur?: boolean;
  size?: "auto" | "small" | "regular" | "medium" | "large" | "extraLarge";
  state?: boolean;
  open?: boolean;
  setState?: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
  closeIcon?: boolean;
  isOverlay?: boolean;
  isOutsideClickOff?: boolean;
  activeZIndex?: number | string;
  isFullscreen?: boolean;
} & ComponentProps<"main">;

type DialogContextType = Partial<DialogProps>;

const DialogContext = createContext<DialogContextType | null>(null);

function useDialogContext() {
  const context = useContext(DialogContext);

  if (!context)
    throw new Error(`useDialogContext must be used within DialogContext`);

  return context;
}

// Dialog component
const Dialog = ({
  header,
  body,
  footer,
  className = "",
  variant = "primary",
  isBlur = false,
  size = "regular",
  state,
  open,
  setState,
  closeIcon = true,
  isOverlay = false,
  isOutsideClickOff = true,
  activeZIndex,
  isFullscreen = false,
  onClose,
  children,
  ...props
}: DialogProps) => {
  let dialogSize;
  // creating a dialogRef to close it when you click outside
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const dialogContainer = document.getElementById("custom-dialog-portal")!;
  // const dialogZIndex = activeZIndex || 1000 + counter++;
  // const [zIndex, setZIndex] = useState();

  // creating className according to dialog size
  switch (size) {
    case "auto":
      dialogSize = "auto-dialog";
      break;
    case "small":
      dialogSize = "small-dialog";
      break;
    case "regular":
      dialogSize = "regular-dialog";
      break;
    case "medium":
      dialogSize = "medium-dialog";
      break;
    case "large":
      dialogSize = "large-dialog";
      break;
    case "extraLarge":
      dialogSize = "extralarge-dialog";
      break;
    default:
      console.log("Default Case: Custom Dialog Component | switch-case");
      break;
  }

  // to close the dialog whenever user click outside the dialog box
  useEffect(() => {
    const handleEvents = (event: MouseEvent | KeyboardEvent) => {
      if (
        event.type === "mousedown" &&
        isOutsideClickOff &&
        dialogRef.current &&
        !dialogRef.current.contains(event.target as Node)
      ) {
        setState?.(false);
        onClose?.();
      }
      if (
        event.type === "keydown" &&
        (event as KeyboardEvent).key === "Escape"
      ) {
        setState?.(false);
        onClose?.();
      }
    }; 

    return () => {
      document.removeEventListener("mousedown", handleEvents);
      document.removeEventListener("keydown", handleEvents);
    };
  }, [state, isOutsideClickOff, setState]);

  // does: to add more accessibility inside the popup window
  useEffect(() => {
    if (state) {
      const focusableElements =
        dialogRef.current?.querySelectorAll<HTMLElement>(
          'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
        );

      const firstFocusableElement = focusableElements
        ? focusableElements[0]
        : null;
      firstFocusableElement?.focus();

      const handleTabKey = (e: KeyboardEvent) => {
        if (!focusableElements || focusableElements.length === 0) return;

        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        if (e.key === "Tab") {
          if (e.shiftKey) {
            if (document.activeElement === firstElement) {
              e.preventDefault();
              lastElement.focus();
            }
          } else {
            if (document.activeElement === lastElement) {
              e.preventDefault();
              firstElement.focus();
            }
          }
        }
      };

      // setZIndex(prev => prev + 5);

      document.addEventListener("keydown", handleTabKey);

      return () => {
        document.removeEventListener("keydown", handleTabKey);
      };
    }
  }, [state]);

  // return dialog of state of dialog is true
  if (state || open) {
    return createPortal(
      <DialogContext.Provider
        value={{
          header,
          body,
          footer,
          className,
          variant,
          isBlur,
          size,
          state,
          open,
          setState,
          closeIcon,
          isOverlay,
          isOutsideClickOff,
          activeZIndex,
          isFullscreen,
          onClose,
        }}
      >
        <main
          style={{ zIndex: activeZIndex ?? "99998" }}
          className={`custom-dialog-container taptap-custom-dialog-container | flex-center ${isBlur ? "custom-overlay-blur" : ""} ${className}`}
          onClick={(e) => {
            // e.preventDefault();
            // e.stopPropagation();
            // console.log({ renderedEvent: e, target: e.target, nativeEvent: e.nativeEvent, dialogRef: dialogRef.current })
            // console.log(e.target === dialogRef?.current)
            // console.dir(dialogRef.current);
            // console.log(dialogRef.current?.contains(e.target as Node));
            e.stopPropagation();
            if(!dialogRef.current?.contains(e.target as Node)) {
              setState?.(false);
              onClose?.();
            }
          }}
          {...props}
        >
          <div
            className={`custom-dialog-wrapper | ${dialogSize} ${
              isFullscreen ? "full-screen" : ""
            }`}
            ref={dialogRef}
            role="dialog"
            aria-labelledby="custom-dialog-header"
            aria-describedby="customer-dialog-body"
          >
            {header && (
              <header
                id="custom-dialog-header"
                className={`custom-dialog-header | none-if-empty relative bg-${variant}-400 p-1 text-center text-white-900 fw-500`}
              >
                <p
                  className={`capitalize fs-medium text-center mx-auto none-if-empty`}
                  style={{ marginBlock: "0" }}
                >
                  {header}
                </p>
                {/* {closeIcon && (
                  <button
                    className={`custom-dialog-header-close-icon | none-if-empty flex-center border-none outline-none bg-${variant}-600 text-white-900`}
                    onClick={() => {
                      setState?.(false);
                      onClose?.();
                    }}
                  >
                    {<IoMdClose />}
                  </button>
                )} */}
              </header>
            )}
            {body && (
              <section
                id="customer-dialog-body"
                className={`custom-dialog-body | none-if-empty bg-white-900 p-1 fs-small ${
                  !header && "btl-4 btr-4"
                }`}
              >
                {body}
              </section>
            )}
            {footer && (
              <footer
                className={`custom-dialog-footer | none-if-empty bg-${variant}-400 px-1 py-8 flex jc-end ai-center fs-small`}
              >
                {footer}
              </footer>
            )}
            {children}
          </div>
        </main>
      </DialogContext.Provider>,
      dialogContainer
    );
  } else return null;
};

type DialogHeaderProps = ComponentProps<"header">;

export const DialogHeader = ({
  children,
  className = "",
  ...props
}: DialogHeaderProps) => {
  const { variant } = useDialogContext();
  return (
    <header
      id="custom-dialog-header"
      className={`${className} custom-dialog-header | none-if-empty relative bg-${variant}-400 p-1 text-center text-white-900 fw-500`}
      {...props}
    >
      <p
        className={`capitalize fs-medium text-center mx-auto none-if-empty`}
        style={{ marginBlock: "0" }}
      >
        {children}
      </p>
      {/* {closeIcon && (
        <button
          className={`custom-dialog-header-close-icon | none-if-empty flex-center border-none outline-none bg-${variant}-600 text-white-900`}
          onClick={() => {
            setState?.(false);
            console.log("first click");
            onClose?.();
          }}
        >
          {<IoMdClose />}
        </button>
      )} */}
    </header>
  );
};

type DialogBodyProps = ComponentProps<"section">;

export const DialogBody = ({
  children,
  className = "",
  ...props
}: DialogBodyProps) => {
  const { header } = useDialogContext();
  return (
    <section
      id="customer-dialog-body"
      className={`${className} custom-dialog-body | none-if-empty bg-white-900 p-1 fs-small ${
        !header && "btl-4 btr-4"
      }`}
      {...props}
    >
      {children}
    </section>
  );
};

type DialogFooterProps = ComponentProps<"footer">;

export const DialogFooter = ({
  children,
  className = "",
  ...props
}: DialogFooterProps) => {
  const { variant } = useDialogContext();
  return (
    <footer
      className={`${className} custom-dialog-footer | none-if-empty bg-${variant}-400 px-1 py-8 flex jc-end ai-center fs-small`}
      {...props}
    >
      {children}
    </footer>
  );
};

Dialog.displayName = "Dialog";
DialogHeader.displayName = "DialogHeader";
DialogBody.displayName = "DialogBody";
DialogFooter.displayName = "DialogFooter";

export default Dialog;
