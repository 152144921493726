import { useMediaQuery, useTheme } from "@mui/material";
import React, { Fragment } from "react";

const Blog = () => {
  
  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2

  let ourNetworks : any = {
    background: "linear-gradient(to right, #2126ff 0%, #0066ff 100%)",
    color: "white",
    margin: "auto",
    width: "50%",
    padding: "0.25%",
    marginBottom: mobileView ? "5%" : "1.5%",
    marginTop: mobileView ? "15%" : "5%",
    textAlign: "center",
    lineHeight: "30px",
    fontWeight: "700",
    fontSize: `x-large`,
  }

  return (
    <Fragment>

      <h5 style={ourNetworks}>Blog</h5>

      <section className="blog" style={{ marginTop: "20px" }}>
        <div className="container">
          <div className="row">
            <div className="section-title wow fadeInUp" data-wow-delay="0.1s">
              <h2>Our Blog</h2>
            </div>
            <div className="col-md-9">
              <div className="blog-list-section blog-content-right row">
                <div className="col-md-9 blog-content-area">
                  <div className="blog-img wow fadeInUp" data-wow-delay="0.1s">
                    <img
                      className="img-responsive"
                      src="img/blog/single_blog_1.jpg"
                      alt=""
                    />
                  </div>
                  <div
                    className="blog-content wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <a href="#blog-single.html">
                      <h4 className="blog-title">
                        Google inks pact for new 35-storey office
                      </h4>
                    </a>
                    <div className="meta">
                      <div className="date">
                        <p>
                          22<sup>nd</sup>Jan 2016
                        </p>
                      </div>
                      <div className="author">
                        <p>By Michal Lomans</p>
                      </div>
                    </div>
                    <p className="blog-decisions">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonum euismod tincidunt ut laoreet dolore magna
                      autem vel eum iriure dolor in.
                    </p>
                    <a
                      className="btn get-started"
                      href="#blog-single.html"
                      role="button"
                    >
                      Read More{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </div>
                </div>

                <div
                  className="col-md-9 blog-content-area"
                  style={{ marginTop: 50 }}
                >
                  <div className="blog-img wow fadeInUp" data-wow-delay="0.1s">
                    <img
                      className="img-responsive"
                      src="img/blog/single_blog_2.jpg"
                      alt=""
                    />
                  </div>
                  <div
                    className="blog-content wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <a href="#blog-single.html">
                      <h4 className="blog-title">
                        A Complete, Ranke Destinat Moines’ <br /> Good
                        Bars,Benefit of the t Media Elite
                      </h4>
                    </a>
                    <div className="meta">
                      <div className="date">
                        <p>
                          22<sup>nd</sup>Jan 2016
                        </p>
                      </div>
                      <div className="author">
                        <p>By Michal Lomans</p>
                      </div>
                    </div>
                    <p className="blog-decisions">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonum euismod tincidunt ut laoreet dolore magna
                      autem vel eum iriure dolor in.
                    </p>
                    <a
                      className="btn get-started"
                      href="#blog-single.html"
                      role="button"
                    >
                      Read More{" "}
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </div>
                </div>
              </div>

              {/* <!-- See All Post --> */}
            </div>
            <div className="col-md-3">
              {/* <!-- Blog Right Sections 
          =========================--> */}
              <div className="blog-sidbar">
                <div className="search widgets">
                  <form action="#">
                    <div
                      className="form-group wow fadeInUp"
                      data-wow-delay="0.1s"
                    >
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control frmg"
                          placeholder="Search Keyword"
                          // onFocus="this.placeholder = ''"
                          // onblur="this.placeholder = 'Search Keyword'"
                          // Download
                        />
                      </div>
                    </div>
                    <button className="btn get-started" type="submit">
                      Search
                    </button>
                  </form>
                </div>
                <div className="categories widgets">
                  <div
                    className="list-group text-center wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <div className="list-group-item active">
                      {" "}
                      Blog Categories{" "}
                    </div>
                    <a
                      href="#"
                      className="list-group-item wow fadeInUp"
                      data-wow-delay="0.2s"
                    >
                      Web Design
                    </a>
                    <a
                      href="#"
                      className="list-group-item wow fadeInUp"
                      data-wow-delay="0.3s"
                    >
                      User Interface
                    </a>
                    <a
                      href="#"
                      className="list-group-item wow fadeInUp"
                      data-wow-delay="0.4s"
                    >
                      User Experience
                    </a>
                    <a
                      href="#"
                      className="list-group-item wow fadeInUp"
                      data-wow-delay="0.5s"
                    >
                      Typography
                    </a>
                    <a
                      href="#"
                      className="list-group-item wow fadeInUp"
                      data-wow-delay="0.6s"
                    >
                      Color Sense
                    </a>
                    <a
                      href="#"
                      className="list-group-item wow fadeInUp"
                      data-wow-delay="0.7s"
                    >
                      Future Trend
                    </a>
                    <a
                      href="#"
                      className="list-group-item wow fadeInUp"
                      data-wow-delay="0.8s"
                    >
                      Modern Design
                    </a>
                  </div>
                </div>
                <div className="related-post widgets">
                  <div
                    className="list-group wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <div
                      className="list-group-item active text-center wow fadeInUp"
                      data-wow-delay="0.1s"
                    >
                      Related Post
                    </div>
                    <a
                      href="#"
                      className="list-group-item wow fadeInUp"
                      data-wow-delay="0.2s"
                    >
                      <div className="media">
                        <div className="media-left media-middle">
                          <p className="post-count">1</p>
                        </div>
                        <div className="media-body">
                          <p>
                            Typography is important fact for liusto odio dolore.
                          </p>
                        </div>
                      </div>
                    </a>
                    <a
                      href="#"
                      className="list-group-item wow fadeInUp"
                      data-wow-delay="0.4s"
                    >
                      <div className="media">
                        <div className="media-left media-middle">
                          <p className="post-count">2</p>
                        </div>
                        <div className="media-body">
                          <p>
                            Typography is important fact for liusto odio dolore.
                          </p>
                        </div>
                      </div>
                    </a>
                    <a
                      href="#"
                      className="list-group-item wow fadeInUp"
                      data-wow-delay="0.6s"
                    >
                      <div className="media">
                        <div className="media-left media-middle">
                          <p className="post-count">3</p>
                        </div>
                        <div className="media-body">
                          <p>
                            Typography is important fact for liusto odio dolore.
                          </p>
                        </div>
                      </div>
                    </a>
                    <a
                      href="#"
                      className="list-group-item wow fadeInUp"
                      data-wow-delay="0.8s"
                    >
                      <div className="media">
                        <div className="media-left media-middle">
                          <p className="post-count">4</p>
                        </div>
                        <div className="media-body">
                          <p>
                            Typography is important fact for liusto odio dolore.
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div
              className="see-all-post text-center wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <a className="btn get-started" href="#" role="button">
                See All Posts{" "}
                <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

    </Fragment>
  );
};

export default Blog;
