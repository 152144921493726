import * as Yup from "yup";
import { fromJS } from "immutable";

Yup.setLocale({
  mixed: {
    default: "mixed default",
    required: "mixed required",
  },
  number: {
    min: "number min",
  },
});

const newRouteValidationObject = {
  modeOfTransport: Yup.string().required("modeOfTransport is a required field."),
  stops: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        company: Yup.string()
          .min(24)
          .max(24)
          .required("company is a required field"),
        city: Yup.string()
          .min(24)
          .max(24)
          .required("city is a required field"),
        branch: Yup.string()
          .min(24)
          .max(24)
          .required("branch is a required field"),
        arrivalTime: Yup.object().shape({
          h: Yup.number()
            .required()
            .positive()
            .integer()
            .min(0)
            .max(23),
          m: Yup.number()
            .required()
            .positive()
            .integer()
            .min(0)
            .max(59),
        }),
        noOfDays: Yup.number()
          .required()
          .integer()
          .min(0),
        departureTime: Yup.object().shape({
          h: Yup.number()
            .required()
            .positive()
            .integer()
            .min(0)
            .max(23),
          m: Yup.number()
            .required()
            .positive()
            .integer()
            .min(0)
            .max(59),
        }),
        loading: Yup.boolean(),
        unloading: Yup.boolean(),
      })
    )
    .min(2, "Minimum 2 destination required"),
};

export const newRouteValidation : any = fromJS(newRouteValidationObject);

export const wholeNewRouteValidation = Yup.object().shape(newRouteValidationObject);
