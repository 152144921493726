import React, { ComponentProps } from 'react'
import Heading from "../heading/heading";
import './page-header.css';

// type
type PageHeaderProps = {
    title: string;
    imgSrc: string;
} & ComponentProps<"section">;

const PageHeader = ({ title, imgSrc }: PageHeaderProps) => {
    return (
        <section className="page-header | p-3 relative m-0">
            <Heading className="| text-primary-400">{title}</Heading>
            {/* About Us Background Image */}
            <img loading="lazy" src={`/assets/${imgSrc}`} alt="background" className="page-header-img" />
        </section>
    )
}

export default PageHeader