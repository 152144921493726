import { ServiceMaker, buildQueryString } from "./index";

export const customerWalletBalanceReport = () =>
  ServiceMaker(`customerCredit/getCustomerBalanceList`, "GET");

export const manageIntraCityRates = (data: any) =>
  ServiceMaker(`intraCityRate/addIntraCityRate`, "POST", data);

export const getIntraCityRates = (data: any) =>
  ServiceMaker(
    `intraCityRate/listIntraCityRate?${buildQueryString(data)}`,
    "GET"
  );

export const getIntraCityBookingPrice = (data: any) =>
  ServiceMaker(`customerBooking/getIntraCityPrice`, "POST", data);

export const getIntraCityBookingPriceEstimated = (data: any) =>
  ServiceMaker(`customerBooking/estimated/getIntraCityPrice`, "POST", data);

export const getAvailableServicesIntraCityPrice = (data: any) =>
  ServiceMaker(
    `customerBooking/getAvailableServicesIntraCityPrice`,
    "POST",
    data
  );
  export const getMaterialList = (startsWith?: string, data?: any) =>
  ServiceMaker(`material/type/list/${startsWith?startsWith:""}?${buildQueryString(data)}`, "GET");

  
export const getPackingListForBranchOrder = () =>
  ServiceMaker(`packing/type/list`, "GET");
  
export const getPackingListForBranchOrderByName = (data:any) =>
  ServiceMaker(`packing/type/list/${data}`, "GET");
  
export const addMaterialType = (data: any) =>
  ServiceMaker(`material/type/create`, "POST" , data);

export const updateMaterialType = (id:any,data: any) =>
  ServiceMaker(`material/type/update/${id}`, "PUT" , data);
  
export const deleteMaterialType = (data: any) =>
  ServiceMaker(`material/type/delete`, "DELETE" , data);
  
export const addPackingType = (data: any) =>
  ServiceMaker(`packing/type/create`, "POST" , data);
  
export const updatePackingType = (id:any,data: any) =>
  ServiceMaker(`packing/type/update/${id}`, "PUT" , data);
  
export const deletePackingType = (data: any) =>
  ServiceMaker(`packing/type/delete`, "DELETE" , data);