// react
import React from "react";
// components
import { Heading } from "../../../../components/UI";
// context
import { useLandingPageContext } from "../../../../hooks/useLandingPageContext";
// constants (data)
import {
  moreInformationData,
  moreInformationIndividualWithFleet,
} from "./data";

// FC: MoreInformation Component
const MoreInformation = () => {
  const { activePage, activeService, activePartner } = useLandingPageContext();
  let title;
  if (activePage === "services") {
    title = activeService;
  } else {
    title = activePartner;
  }

  const data =
    moreInformationData.find(
      (data) =>
        (data.page === activePage && data.partner === activePartner) ||
        (data.page === activePage && data.service === activeService)
    )?.moreInformation || moreInformationIndividualWithFleet;

  if (
    (activePartner === "Individual With Fleet" && activePage === "partners") ||
    (activeService === "Part Load & Full Load" && activePage === "services")
  ) {
    return (
      <div className="bg-white-800 p-3 grid gap-2">
        <Heading className="text-black-900">
          About{" "}
          <span
            className="text-primary-400"
            style={{ borderBottom: "4px solid var(--clr-primary-400)" }}
          >
            {title}
          </span>
        </Heading>
        <div className="flex-col-center gap-2 fs-medium">
          {data &&
            data.length > 0 &&
            data.map((ele) => (
              <p key={ele.id} className="text-center">
                {ele.description}
              </p>
            ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default MoreInformation;
