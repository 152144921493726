import React, { useEffect, useRef } from 'react'
import formatDate from '../../services/FormatDate';
import "./ChatContainer.css"
import { Input12 } from '../Input/Inputcomponents';
import { ImAttachment } from 'react-icons/im';
import { FaCamera } from 'react-icons/fa';
import { SendIcon } from '../../newSrc/constants/icons';
import { Button } from '../../newSrc/components/UI';

const ChatContainer = (props : any) => {
    const {value , chatHistory , onChange , onClick , onKeyDown , isClosedComplaint} = props;
    const bottomRef = useRef<any>(null); 
    const fileInputRef = useRef(null);

    const timeConvert = (time: any) => {
        const timeString12hr = new Date(time).toLocaleTimeString("en-US", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
        });
        return timeString12hr;
    };
    
    const handleFileInput = (event:any) => {
        const selectedFile = event.target.files[0];
        const formData = new FormData();
        formData.append('file', selectedFile);
    
        // Add any additional form data or headers as needed
        // For example: 
        // formData.append('name', 'My File');
        // const headers = { 'Authorization': 'Bearer myToken' };
    
        // Send the file to the chat API
      //   sendMessage({
      //     type: 'file',
      //     file: formData,
      //     // headers: headers,
      //   })
      //     .then((response:any) => {
      //       // Handle the response as needed
      //     })
      //     .catch((error:any) => {
      //       console.error('Error sending file: ', error);
      //     });
    }

    useEffect(()=>{
        bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
        console.log(`chatHistory`,chatHistory)
    },[chatHistory])

  return (
    <div id="chatContainer" className={props?.customerClass ? props?.customerClass : `fixedClass`}>
        <div className='chatcontainerMap' ref={bottomRef}>
            {chatHistory && chatHistory?.length > 0 && chatHistory?.map((ele:any)=>{
                return(
                    <div>
                        {ele?.createdByType === "C" && 
                            <div className='singleMassage'>
                                <div className='textChatDiv'>
                                    {ele?.text && (
                                        <div className='actualText' style={{ display: "flow-root" }}>
                                            <p className="senderMsgContainer">
                                                <div className='textStamp'>
                                                    {ele?.text}
                                                    <div className='timeStamp'>
                                                        {`${formatDate(ele?.createdAt)} at ${timeConvert(ele?.createdAt)}`}
                                                    </div>
                                                </div>
                                            </p>
                                        </div>
                                    )}
                                    {ele?.media?.length > 0 &&
                                    ele?.media?.map((e: any) => (
                                        <div className='imnageDivChat'>
                                            <p
                                                style={{
                                                textAlign: "right",
                                                }}
                                            >
                                                {" "}
                                                <img src={e.url} height="150px" width="auto" />
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>   
                        }
                        {ele?.createdByType === "U" && 
                            <div className='branchSingleMassage'>
                                <div className='textChatDiv'>
                                    {ele?.text && (
                                        <div className='actualText' style={{ display: "flow-root" }}>
                                            <p className="recieverMsgContainer">
                                                <div className='textStamp'>
                                                    {ele?.text}
                                                    <div className='timeStamp'>
                                                        {`${formatDate(ele?.createdAt)} at ${timeConvert(ele?.createdAt)}`}
                                                    </div>
                                                </div>
                                            </p>
                                        </div>
                                    )}
                                    {ele?.media?.length > 0 &&
                                    ele?.media?.map((e: any) => (
                                        <div className='imnageDivChat'>
                                            <p
                                                style={{
                                                textAlign: "right",
                                                }}
                                            >
                                                {" "}
                                                <img src={e.url} height="150px" width="auto" />
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>   
                        } 
                        {ele?.createdByType === "G" && 
                            <div className='branchSingleMassage'>
                                <div className='textChatDiv'>
                                    {ele?.text && (
                                        <div className='actualText' style={{ display: "flow-root" }}>
                                            <p className="recieverMsgContainer">
                                                <div className='textStamp'>
                                                    {ele?.text}
                                                    <div className='timeStamp'>
                                                        {`${formatDate(ele?.createdAt)} at ${timeConvert(ele?.createdAt)}`}
                                                    </div>
                                                </div>
                                            </p>
                                        </div>
                                    )}
                                    {ele?.media?.length > 0 &&
                                    ele?.media?.map((e: any) => (
                                        <div className='imnageDivChat'>
                                            <p
                                                style={{
                                                textAlign: "right",
                                                }}
                                            >
                                                {" "}
                                                <img src={e.url} height="150px" width="auto" />
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>   
                        } 
                        {ele?.createdByType === "D" && 
                            <div className='branchSingleMassage'>
                                <div className='textChatDiv'>
                                    {ele?.text && (
                                        <div className='actualText' style={{ display: "flow-root" }}>
                                            <p className="recieverMsgContainer">
                                                <div className='textStamp'>
                                                    {ele?.text}
                                                    <div className='timeStamp'>
                                                        {`${formatDate(ele?.createdAt)} at ${timeConvert(ele?.createdAt)}`}
                                                    </div>
                                                </div>
                                            </p>
                                        </div>
                                    )}
                                    {ele?.media?.length > 0 &&
                                    ele?.media?.map((e: any) => (
                                        <div className='imnageDivChat'>
                                            <p
                                                style={{
                                                textAlign: "right",
                                                }}
                                            >
                                                {" "}
                                                <img src={e.url} height="150px" width="auto" />
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>   
                        } 
                    </div>
                )
            })}
        </div>
        {isClosedComplaint ? 
            <div className='sendMsgContainerCentralised'>
                <div className="textInputChatDiv">
                    <Input12
                        value={value}
                        placeholder="Type a message"
                        onChange={(e: any) => {
                            onChange(e.target.value);
                        }}
                        onKeyDown={(e: any) => {
                            if (e.key == "Enter"){
                                onKeyDown();
                            }
                        }}
                        multiline={true}
                        rows={3}
                    />
                </div>
                <div className="imageInputChatDiv">
                    <div className="uploadFileContainer" 
                        // onClick={() => {handleFileAttechments()}}
                    >
                    <input
                        type="file"
                        accept="image/*, application/pdf"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileInput}
                    />
                        <ImAttachment className="commonIcon" />
                    </div>
                    <div className="uploadImageContainer" 
                        // onClick={() => {handleImageAttechments()}}
                    >
                        <FaCamera className="commonIcon" />
                    </div>
                    <div className="sendBtnChatDiv">
                        <SendIcon
                            onClick={onClick}
                            className="commonIcon  text-primary-500"
                            style={{fontSize:"x-large"}}
                        />
                    </div>
                </div>
            </div>
            :
            <div className='sendMsgContainerCentralised'>
                <Button variant='primary' action='primary'>Re - Open Complaint</Button>
            </div>
        }
    </div>
  )
}

export default ChatContainer