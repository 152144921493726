import React, { useState, useEffect } from "react";
import { getCityList , getCityByPincode } from "../../../services/area";
import { listSourceBranches , listDestinationBranches} from "../../../services/inquiry";
import Loader from "@mui/material/CircularProgress";
import HowItWorks from '../../../Components/How It Works/HowItWorks';
import FAQ from '../../../Components/FAQ/FAQ';
import Testimonials from "../../../Components/Tetimonials/Testimonials";
import OurFeatures from "../../../Components/Features/OurFeatures";
import { useMediaQuery, useTheme } from "@mui/material";

const DomesticForm = () => {

  const [serviceType, setServiceType] = useState("B2B");
  const [cities, setCities] = useState([]);
  const [sourceCity, setSourceCity] = useState("");
  const [sourceBranchList, setSourceBranchList] = useState([]);
  const [sourceBranch, setSourceBranch] = useState("");
  const [destinationCity, setDestinationCity] = useState("");
  const [destinationBranchList, setDestinationBranchList] = useState([]);
  const [destinationBranch, setDestinationBranch] = useState("");
  const [sourcePincode, setSourcePincode] = useState("");
  const [destinationPincode, setDestinationPincode] = useState("");
  const [packageCount, setPackageCount] = useState([{}])
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [tabActive,setTabActive] = useState(0)
  
  const topFunction2 = (props:any)=>{
    document.body.scrollTop = 0;
    {props === "book" ? document.documentElement.scrollTop = 0 : props === "feature" ? document.documentElement.scrollTop = 515 : props === "how" ? document.documentElement.scrollTop = 1040 :
    props === "test" ? document.documentElement.scrollTop = 1575 : props === "faq's" ? document.documentElement.scrollTop = 2100 : 0 }
  } 

  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2
    
  useEffect(() => {
    setUpData();
    setPackageCount([{}])
    setSourceCity("")
    setDestinationCity("")
  }, [serviceType]);

  useEffect(() => {
    fetchDestinationBranchList();
  }, [sourceBranch]);

  useEffect(() => {
    try {
      if (sourcePincode.length === 6) {
        let GetCity = async () => {
          let SCity = await getCityByPincode(sourcePincode);
          cities.find((e: any) => {
            if (e._id === SCity._id) {
              setSourceCity(e._id)
            }
          })
        }
        GetCity()
      }
    }
    catch (err : any) {
      console.log(err)
    }
  }, [sourcePincode])

  useEffect(() => {
    try {
      if (destinationPincode.length === 6) {
        let GetCity = async () => {
          let SCity = await getCityByPincode(destinationPincode);
          cities.find((e: any) => {
            if (e._id === SCity._id) {
              setDestinationCity(e._id)
            }
          })
        }
        GetCity()
      }
    }
    catch (err : any) {
      console.log(err)
    }
  }, [destinationPincode])

  const setUpData = async () => {
    try {
      const [cityList, branchesList] = await Promise.all([
        getCityList(),
        listSourceBranches(),
      ]);
      setCities(cityList);
      setSourceBranchList(branchesList);
    } catch (err : any) { }
  };

  const fetchDestinationBranchList = async () => {
    try {
      if (sourceBranch && sourceBranch.length == 24) {
        const res = await listDestinationBranches(sourceBranch);
        setDestinationBranchList(res);
      }
    } catch (err : any) { }
  };

  const handleResults = async () => {
    setLoading(true);
    try {
      if (serviceType === "B2B") {
        if (sourceCity === "" || sourceBranch === "" || destinationCity === "" || destinationBranch === "") {
          alert("Kindly Fill All the Details !")
        }
        else {
          console.log({
            ServiceType: serviceType,
            From: sourceCity,
            FromBranch: sourceBranch,
            To: destinationCity,
            ToBranch: destinationBranch,
            PackageDetails: packageCount
          })
          setSourceCity("")
          setSourceBranch("")
          setDestinationBranch("")
          setDestinationCity("")
          setPackageCount([{}])
        }
      }
      if (serviceType === "D2D") {
        if (sourceCity === "" || sourcePincode === "" || destinationCity === "" || destinationPincode === "") {
          alert("Kindly Fill All the Details !")
        }
        else {
          console.log({
            ServiceType: serviceType,
            From: sourceCity,
            FromBranch: sourcePincode,
            To: destinationCity,
            ToBranch: destinationPincode,
            PackageDetails: packageCount
          })
          setSourceCity("")
          setSourcePincode("")
          setDestinationPincode("")
          setDestinationCity("")
          setPackageCount([{}])
        }
      }
    } catch (err : any) {
      setResult([]);
    }
    setLoading(false);
  };

  const domestic = {
    "HowItWorks": [{ headings: ["Fill in your data", "Get an instant quote", "Book a container"] },
    {
      img: ["how-it-works-img1.png",
        "how-it-works-img2.png",
        "how-it-works-img3.png"]
    }],
    "Features": [
      {
        img: ["https://img.icons8.com/bubbles/80/000000/cash-in-hand.png",
          "https://img.icons8.com/bubbles/80/000000/security-checked.png",
          "https://img.icons8.com/bubbles/80/000000/security-checked.png",
          "https://img.icons8.com/bubbles/80/000000/security-checked.png",
          "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
          "https://img.icons8.com/bubbles/80/000000/conference-call.png"]
      },
      { headings: ["On-road safety", "Careful handling", "State-of-the-art infrastructure", "Claims settlement", "Email it to anyone", "Easy administration"] },
      {
        content: ["Our custom-built containerized vehicles offer absolute on-road safety for your precious household goods.",
          "We take utmost care in handling each one of your personal belongings – be it your favorite car, your delicate cookware, or your child’s favorite toy.",
          "We have invested in state-of-the-art infrastructure which helps us consistently be among the top parcel service in India.",
          "We provide a unique settlement of claims for any unforeseen contingencies, which we ensure to consciously evade.",
          "Send an email copy to yourself, colleagues, or customers.",
          "Access all quotations via My Quotations."]
      }],
    "FAQ": [
      {
        question: ["What is Domestic Parcel Service ?",
          "What are the capabilities of Domestic Parcel Service ?",
          "What states does Kabra Express Domestic Parcel Service deliver to ?",
          "How long does Kabra Express Domestic Parcel Service take to deliver orders ?"]
      },
      {
        answer: ["Our domestic parcel service is an express parcel delivery network that serves over 4 states in India. We can handle consignments of up to 10 kilograms and offer next-day capabilities and 48 hrs.",
          "The capabilities of Domestic Parcel Service include handling consignments of up to 10 kilograms and next-day capabilities.",
          "Kabra Express delivers in states like Gujarat, Maharashtra, Madhya Pradesh, and Rajasthan.",
          "Next-day capabilities and 48-96 hour deliveries for long-distance orders."]
      }],
    "Testimonials": [{ img: [] }, { name: [] }, { possition: [] }, { company: [] },
    {
      feedback: ["I was very pleased with the service I received from Kabra Express Packers and Movers. They were very professional and courteous throughout the entire process. I was also impressed with their knowledge and expertise. They were able to handle my large moving project with ease and everything arrived at my new home safely. I would highly recommend their services to anyone in need of a packer and movers.",
        "I have availed the services of Kabra Express Packers and Movers on two occasions, and I have been highly satisfied with their service on both occasions. They are a very professional outfit, and their staff is always polite and helpful. They have the knowledge and expertise to handle any size moving project, and they place a high priority on the safety of your belongings. I would recommend their.",
        "We used Kabra Express for our move from Ahmedabad to Mumbai and we were very pleased with their service. They were very professional and efficient in packing and moving our belongings. We were very happy with how safe and secure our belongings were during the move. We would highly recommend Kabra Express to anyone looking for professional and reliable packers and movers service."
      ]
    }]
  }
  return (
    <>
    {mobileView ? (
        <div className="">
          <div className="domestic">
            <section id="intro" className="intro">
              <div className="intro-content">
              <div style={{height: "50px"}}></div>
              <h1 style={{textAlign: "center", fontSize: "24px", marginBottom: "0", paddingBlock: "1rem"}}>Get Estimate</h1>

                <div className="container ml-25Mobile">
                  <div className="row mt95Mobile">
                    <div className="col-lg-6">
                      <div className="logo_1">
                        <img src="img/21.png" className="img-responsive mt-55" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="row form_page">
                        <div className="ownBtnMobileVersion ml-3Mobile">
                          <button className={serviceType === "B2B" ? "activeButton" : ""} onClick={() => { setServiceType("B2B") }}> Branch to branch Delivery </button>
                          <button className={serviceType === "D2D" ? "activeButton" : ""} onClick={() => { setServiceType("D2D") }}> Door Delivery Services </button>
                        </div>

                        <div className="tab-content">
                          {serviceType === "B2B" && (
                            <div className="tab-pane active" id="my_1">
                              <div className="col-md-6 ml55Mobile col-sm-6">
                                <select
                                  id="inputState"
                                  className="form-control form_one"
                                  value={sourceCity}
                                  onChange={(e) => {
                                    setSourceCity(e.target.value);
                                    setSourceBranch("");
                                  }}
                                >
                                  <option label="From City" value="" />
                                  {cities.map((c: any) => (
                                    <option
                                      key={c._id}
                                      label={c.name}
                                      value={c._id}
                                    />
                                  ))}
                                </select>
                              </div>

                              <div className="col-md-6 ml55Mobile">
                                <select
                                  id="inputState"
                                  className="form-control form_one"
                                  value={sourceBranch}
                                  onChange={(e) => {
                                    setSourceBranch(e.target.value);
                                  }}
                                >
                                  <option label="Select Branch" value="" />
                                  {sourceBranchList
                                    .filter(
                                      (b: any) =>
                                        sourceCity.length !== 24 ||
                                        b.city === sourceCity
                                    )
                                    .map((b: any) => (
                                      <option
                                        key={b._id}
                                        label={b.name}
                                        value={b._id}
                                      />
                                    ))}
                                </select>
                              </div>
                              <div className="col-md-6 ml55Mobile col-sm-6">
                                <select
                                  id="inputState"
                                  className="form-control form_one"
                                  value={destinationCity}
                                  onChange={(e) => {
                                    setDestinationCity(e.target.value);
                                    setDestinationBranch("");
                                  }}
                                >
                                  <option label="To City" value="" />
                                  {cities.map((c: any) => (
                                    <option
                                      key={c._id}
                                      label={c.name}
                                      value={c._id}
                                    />
                                  ))}
                                </select>
                              </div>

                              <div className="col-md-6 ml55Mobile">
                                <select
                                  id="inputState"
                                  className="form-control form_one"
                                  value={destinationBranch}
                                  onChange={(e) => {
                                    setDestinationBranch(e.target.value);
                                  }}
                                >
                                  <option label="Select Branch" value="" />
                                  {destinationBranchList
                                    .filter(
                                      (b: any) =>
                                        destinationCity.length !== 24 ||
                                        b.city === destinationCity
                                    )
                                    .map((b: any) => (
                                      <option
                                        key={b._id}
                                        label={b.name}
                                        value={b._id}
                                      />
                                    ))}
                                </select>
                              </div>

                              <div className="row ml155 mt10 ml70Mobile">
                                <button
                                  className="btn btn-primary-one text-white form_one"
                                  onClick={() => {
                                    handleResults();
                                  }}
                                >
                                  Get Details
                                </button>
                              </div>
                            </div>
                          )}
                          {serviceType === "D2D" && (
                            <div className="tab-pane active" id="my_1">
                              <div className="col-md-6 ml55Mobile col-sm-6">
                                <input
                                  type="number"
                                  value={sourcePincode}
                                  onChange={(e) => {
                                    setSourcePincode(e.target.value);
                                  }}
                                  className="form-control form_one"
                                  id="inputZip"
                                  placeholder="Source Pincode"
                                  required
                                />
                              </div>
                              <div className="col-md-6 ml55Mobile col-sm-6">
                                <select
                                  id="inputState"
                                  className="form-control form_one"
                                  value={sourceCity}
                                  onChange={(e) => {
                                    setSourceCity(e.target.value);
                                  }}
                                >
                                  <option label="From City" value="" />
                                  {cities.map((c: any) => (
                                    <option
                                      key={c._id}
                                      label={c.name}
                                      value={c._id}
                                    />
                                  ))}
                                </select>
                              </div>
                              <div className="col-md-6 ml55Mobile">
                                <input
                                  type="number"
                                  value={destinationPincode}
                                  onChange={(e) => {
                                    setDestinationPincode(e.target.value);
                                  }}
                                  className="form-control form_one"
                                  id="inputZip"
                                  placeholder="Destination Pincode"
                                  required
                                />
                              </div>
                              <div className="col-md-6 ml55Mobile col-sm-6">
                                <select
                                  id="inputState"
                                  className="form-control form_one"
                                  value={destinationCity}
                                  onChange={(e) => {
                                    setDestinationCity(e.target.value);
                                  }}
                                >
                                  <option label="To City" value="" />
                                  {cities.map((c: any) => (
                                    <option
                                      key={c._id}
                                      label={c.name}
                                      value={c._id}
                                    />
                                  ))}
                                </select>
                              </div>
                              <div className="row ml155 mt10 ml0Mobile ml70Mobile">
                                <button
                                  className="btn btn-primary-one text-white form_one"
                                  onClick={() => {
                                    handleResults();
                                  }}
                                >
                                  Get Details
                                </button>
                              </div>
                            </div>
                          )}
                        </div>

                        {loading === true && (
                          <span className="tac">
                            <Loader />
                          </span>
                        )}
                        {result && loading === false && (
                          // <div className="table-responsive">
                          //   <table className="table">
                          //     <thead>
                          //       <tr>
                          //         <th>Type</th>
                          //         <th>Estimated Time</th>
                          //         <th>Estimated Amount</th>
                          //       </tr>
                          //     </thead>
                          //       <tbody>
                          //         {result.map((r: any) => (
                          //           <tr key={r.type}>
                          //             <td>
                          //               <a onClick={() => {}}>{r.type}</a>
                          //             </td>
                          //             <td>
                          //               {new Date(
                          //                 r.estimatedDeliveryTime
                          //               ).toLocaleDateString()}
                          //             </td>
                          //             <td>{r.estimatedCost}</td>
                          //           </tr>
                          //         ))}
                          //       </tbody>
                          //   </table>
                          // </div>
                          null
                        )}
                      </div>

                      <div data-aos="fade-up" data-aos-delay="800"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="feturesTabs">
            <div className="features commonBoxShadow" onMouseOver={() => { setTabActive(1) }}>
              <div className="section-title" data-wow-delay="0.1s">
                <h2>Our Features</h2>
              </div>
              <div className="centalisedFeatures">
                <OurFeatures data={domestic} />
              </div>
            </div>
            <div className="howItWorks commonBoxShadow" onMouseOver={() => { setTabActive(2) }}>
              <div className="section-title" data-wow-delay="0.1s">
                <h2>How It Works</h2>
              </div>
              <div className="centalisedHowItWorks">
                <HowItWorks data={domestic} />
              </div>
            </div>
            <div className="trestimonials commonBoxShadow" onMouseOver={() => { setTabActive(3) }}>
              <div className="section-title" data-wow-delay="0.1s">
                <h2>Testimonials</h2>
              </div>
              <div className="centalisedTestimonials">
                <Testimonials data={domestic} />
              </div>
            </div>
            <div className="faqIntracity commonBoxShadow" onMouseOver={() => { setTabActive(4) }}>
              <section className="faq">
                <div className="container">
                  <div className="section-title" data-wow-delay="0.1s">
                    <h2>FAQ</h2>
                  </div>
                  <FAQ data={domestic} />
                </div>
              </section>
            </div>
          </div>
        </div>
      ) : (
        <div className="domesticContainer">
          <div className="scrollableContainer">
            <div className="sideNav" onMouseEnter={() => document.body.style.overflowY = 'hidden'} onMouseLeave={() => document.body.style.overflowY = 'scroll'}>
              <div className={tabActive === 0 ? "activeNew" : ""} onClick={() => { topFunction2("book"), setTabActive(0) }}>Book Now</div>
              <div className={tabActive === 1 ? "activeNew" : ""} onClick={() => { topFunction2("feature"), setTabActive(1) }}>Features</div>
              <div className={tabActive === 2 ? "activeNew" : ""} onClick={() => { topFunction2("how"), setTabActive(2) }}>How It Works</div>
              <div className={tabActive === 3 ? "activeNew" : ""} onClick={() => { topFunction2("test"), setTabActive(3) }}>Testimonials</div>
              <div className={tabActive === 4 ? "activeNew" : ""} onClick={() => { topFunction2("faq's"), setTabActive(4) }}>FAQ's</div>
            </div>
            <div className="domestic">
              <div className="intro-content formAndPhoto commonBoxShadow" onMouseOver={() => { setTabActive(0) }}>
                <div className="col-lg-6 firm_1 wow commonPaddingHeight" data-wow-delay="0.1s">
                  <div className="bttn mt0">
                    <div className="ownBtn">
                      <button className={serviceType === "B2B" ? "activeButton" : ""} onClick={() => { setServiceType("B2B") }}> Branch to branch Delivery </button>
                      <button className={serviceType === "D2D" ? "activeButton" : ""} onClick={() => { setServiceType("D2D") }}> Door Delivery Services </button>
                    </div>
                  </div>
                  {serviceType === "B2B" && (
                    <>
                      <div className="row myOwnRow">
                        <div className="col-md-6 col-sm-6">
                          <select
                            id="inputState"
                            className="form-control form_one"
                            value={sourceCity}
                            onChange={(e) => {
                              setSourceCity(e.target.value);
                              setSourceBranch("");
                            }}
                          >
                            <option label="From City" value="" />
                            {cities.map((c: any) => (
                              <option
                                key={c._id}
                                label={c.name}
                                value={c._id}
                              />
                            ))}
                          </select>
                        </div>

                        <div className="col-md-6">
                          <select
                            id="inputState"
                            className="form-control form_one"
                            value={sourceBranch}
                            onChange={(e) => {
                              setSourceBranch(e.target.value);
                            }}
                          >
                            <option label="Select Branch" value="" />
                            {sourceBranchList
                              .filter(
                                (b: any) =>
                                  sourceCity.length !== 24 ||
                                  b.city === sourceCity
                              )
                              .map((b: any) => (
                                <option
                                  key={b._id}
                                  label={b.name}
                                  value={b._id}
                                />
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="row myOwnRow">
                        <div className="col-md-6 col-sm-6 mt-10">
                          <select
                            id="inputState"
                            className="form-control form_one"
                            value={destinationCity}
                            onChange={(e) => {
                              setDestinationCity(e.target.value);
                              setDestinationBranch("");
                            }}
                          >
                            <option label="To City" value="" />
                            {cities.map((c: any) => (
                              <option
                                key={c._id}
                                label={c.name}
                                value={c._id}
                              />
                            ))}
                          </select>
                        </div>

                        <div className="col-md-6 mt-10">
                          <select
                            id="inputState"
                            className="form-control form_one"
                            value={destinationBranch}
                            onChange={(e) => {
                              setDestinationBranch(e.target.value);
                            }}
                          >
                            <option label="Select Branch" value="" />
                            {destinationBranchList
                              .filter(
                                (b: any) =>
                                  destinationCity.length !== 24 ||
                                  b.city === destinationCity
                              )
                              .map((b: any) => (
                                <option
                                  key={b._id}
                                  label={b.name}
                                  value={b._id}
                                />
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="row ml155 mt10 ml0Mobile">
                        <button
                          className="btn btn-primary-one text-white form_one puBtn"
                          onClick={() => {
                            handleResults();
                          }}
                        >
                          Book Now
                        </button>
                      </div>
                    </>
                  )}
                  {serviceType === "D2D" && (
                    <>
                      <div className="row myOwnRow">
                        <div className="col-md-6 col-sm-6">
                          <input
                            type="number"
                            value={sourcePincode}
                            onChange={(e) => {
                              setSourcePincode(e.target.value);
                            }}
                            className="form-control form_one"
                            id="inputZip"
                            placeholder="Source Pincode"
                            required
                          />
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <select
                            id="inputState"
                            className="form-control form_one"
                            value={sourceCity}
                            onChange={(e) => {
                              setSourceCity(e.target.value);
                            }}
                          >
                            <option label="From City" value="" />
                            {cities.map((c: any) => (
                              <option
                                key={c._id}
                                label={c.name}
                                value={c._id}
                              />
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="row myOwnRow">
                        <div className="col-md-6 mt-10">
                          <input
                            type="number"
                            value={destinationPincode}
                            onChange={(e) => {
                              setDestinationPincode(e.target.value);
                            }}
                            className="form-control form_one"
                            id="inputZip"
                            placeholder="Destination Pincode"
                            required
                          />
                        </div>
                        <div className="col-md-6 col-sm-6 mt-10">
                          <select
                            id="inputState"
                            className="form-control form_one"
                            value={destinationCity}
                            onChange={(e) => {
                              setDestinationCity(e.target.value);
                            }}
                          >
                            <option label="To City" value="" />
                            {cities.map((c: any) => (
                              <option
                                key={c._id}
                                label={c.name}
                                value={c._id}
                              />
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="row ml155 mt10 ml0Mobile">
                        <button
                          className="btn btn-primary-one text-white form_one puBtn"
                          onClick={() => {
                            handleResults();
                          }}
                        >
                          Book Now
                        </button>
                      </div>
                    </>
                  )}

                  {loading === true && (
                    <span className="tac">
                      <Loader />
                    </span>
                  )}
                  {result && loading === false && (
                    // <div className="table-responsive">
                    //   <table className="table">
                    //     <thead>
                    //       <tr>
                    //         <th>Type</th>
                    //         <th>Estimated Time</th>
                    //         <th>Estimated Amount</th>
                    //       </tr>
                    //     </thead>
                    //       <tbody>
                    //         {result.map((r: any) => (
                    //           <tr key={r.type}>
                    //             <td>
                    //               <a onClick={() => {}}>{r.type}</a>
                    //             </td>
                    //             <td>
                    //               {new Date(
                    //                 r.estimatedDeliveryTime
                    //               ).toLocaleDateString()}
                    //             </td>
                    //             <td>{r.estimatedCost}</td>
                    //           </tr>
                    //         ))}
                    //       </tbody>
                    //   </table>
                    // </div>
                    null
                  )}

                  <div data-aos="fade-up" data-aos-delay="800"></div>
                </div>
                <div className="col-lg-6 commonPaddingHeight" data-wow-duration="2s" data-wow-delay="0.2s">
                  <img src="img/21.png" alt="" />
                </div>
              </div>
              <div className="feturesTabs">
                <div className="features commonBoxShadow" onMouseOver={() => { setTabActive(1) }}>
                  <div className="section-title" data-wow-delay="0.1s">
                    <h2>Our Features</h2>
                  </div>
                  <div className="centalisedFeatures">
                    <OurFeatures data={domestic} />
                  </div>
                </div>
                <div className="howItWorks commonBoxShadow" onMouseOver={() => { setTabActive(2) }}>
                  <div className="section-title" data-wow-delay="0.1s">
                    <h2>How It Works</h2>
                  </div>
                  <div className="centalisedHowItWorks">
                    <HowItWorks data={domestic} />
                  </div>
                </div>
                <div className="trestimonials commonBoxShadow" onMouseOver={() => { setTabActive(3) }}>
                  <div className="section-title" data-wow-delay="0.1s">
                    <h2>Testimonials</h2>
                  </div>
                  <div className="centalisedTestimonials">
                    <Testimonials data={domestic} />
                  </div>
                </div>
                <div className="faqIntracity commonBoxShadow" onMouseOver={() => { setTabActive(4) }}>
                  <section className="faq">
                    <div className="container">
                      <div className="section-title wow" data-wow-delay="0.1s">
                        <h2>Frequently Asked Questions</h2>
                      </div>
                      <FAQ data={domestic} />
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DomesticForm