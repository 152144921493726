import { RESET_FEEDBACK_FILTERS, SET_FEEDBACK_BULK_FILTERS, SET_FEEDBACK_FILTERS } from "../constants/feedbackFilters";

const initState:any = {
  startDate : null,
  endDate : null,
  originBranch : "",
  destBranch : "",
  complaintStatus : [
    {
      label: "In Progress",
      value: "I",
    },
    {
      label: "Open",
      value: "O",
    },
    {
      label: "Re-opened",
      value: "RE",
    },
  ],
  serviceType : "",
  user : "",
  complaintType : "",
  isAllComplaints : true,
  onlyPendingVerify : false,
  stepWiseUpdater: {
    startDate: null,
    endDate: null
  },
  stepWiseFilterStatus : null,
  sortingAction : ""
};

export default (state = initState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case SET_FEEDBACK_FILTERS: {
    let {what, val} = action.payload
      return {
        ...state,
        [what]: val,
      };
    }
    case SET_FEEDBACK_BULK_FILTERS: {
      return {
        ...state,
        ...action.payload
      }
    }
    case RESET_FEEDBACK_FILTERS: {
      return {
        ...initState
      };
    }
    default:
      return state;
  }
};
