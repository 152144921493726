'use strict'
export default function(msg, field){
    const errObj = new Error()
    errObj.message = msg
    errObj.field = field
    return errObj
}

export class BookingError extends Error {
    constructor(message, helper){
        super(message, helper)
        this.constructor = BookingError
        this.__proto__ = BookingError.prototype
        this.helper = helper
    }
}