import { TextField } from "@mui/material";
import React, { useState } from "react";
import { FilterType, useAsyncDebounce } from "react-table";
// import { CallbackFilterFun } from "./ReactTable";

interface FilterUi {
    column:  {
    filterValue:string|"",
    preFilteredRows:any[],
    setFilter:(data:string|undefined)=>void
    }
}
interface GlobelFilterUi {
    preGlobalFilteredRows:any[];
    globalFilter:any;
    setGlobalFilter:(data:any|undefined)=>void;

}

export const DefaultColumnFilter =({
    column: { filterValue, preFilteredRows, setFilter } 
  }:FilterUi) => {
    const count = preFilteredRows.length;
  
    return (
   <div style={{margin:"3px"}}>

      <TextField
      value={filterValue || ""}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
    }}
    placeholder={`Search ${count} records...`}
      size="small"
      variant="outlined"
    />
    </div>
    );
  }


  // Define a default UI for filtering
export const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter
  } : GlobelFilterUi) => {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);
  
    return (
      <span>
        Search:{" "}
        <input
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} records...`}
          style={{
            fontSize: "1.1rem",
            border: "0"
          }}
        />
      </span>
    );
  }


  // export const caseInsensitiveSearch :CallbackFilterFun= (row ,id,filtervalue) =>{
  //   console.log(row,id,filtervalue)
  //   return row[id]! !== undefined
  //     ? String(row[id])
  //         .toLowerCase()
  //         .includes(filtervalue.toLowerCase())
  //     : true;
  // }

 export const multiFilter:FilterType<any> =(rows, columnIds, filterValue) => {
    return filterValue.length === 0 ? rows : rows.filter((row:any) => {
      let match = String(row.original[columnIds[0]])
      return typeof filterValue === 'object'
        ? filterValue.includes(match) // Check to see if match is in array
        : match.includes(filterValue) // do sub string match for strings
      })
  }