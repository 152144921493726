import React from 'react'
import { EstimatedRateLayout } from "../../../../Layout/EstimatedRateLayout"
import { EstimatedCard } from "../components/Card/EstimatedCard"
import { useLandingPageContext } from "../../../hooks/useLandingPageContext";

const ExpressParcelRate = () => {

  const {
    activeSubService
  } = useLandingPageContext();

  console.log(activeSubService,"activeSubServiceagdvhadgvahsv")

  return (
    <div>
        <EstimatedRateLayout data={activeSubService}>
            <EstimatedCard/>
        </EstimatedRateLayout>
    </div>
  )
}

export default ExpressParcelRate