import React from "react";
import { Button } from "../UI";

type ApplyButtonProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const ApplyButton = ({ onClick }: ApplyButtonProps) => {
  return (
    <Button
      variant="primary"
      action="primary"
      className="fs-small"
      onClick={(e) => {
        onClick(e);
      }}
    >
      Apply
    </Button>
  );
};

export default ApplyButton;
