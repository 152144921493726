// react
import React from "react";
// ui components
import { Button } from "../../../../components/UI";
// context
import { useLandingPageContext } from "../../../../hooks/useLandingPageContext";

// CTA Component
const CTA = () => {
  const { mainRef, mobileLandingPageRef, mobileFormPageRef } = useLandingPageContext();

  // component return
  return (
    <div className="| bg-primary-400 p-3 flex-col-center gap-8">
      <p className="| m-0 fs-subheading fw-600 text-white-900 text-center">
        Got interested in using our services?
      </p>
      {/* <p className="| m-0 fs-medium fw-600">Join us now!</p> */}
      <Button
        variant="secondary"
        action="secondary"
        className="| fs-button"
        onClick={() => {
          mainRef.current?.scroll({ top: 0, behavior: "smooth" });
          mobileLandingPageRef.current?.scroll({ top: 0, behavior: "smooth" });
          mobileFormPageRef.current?.scroll({ top: 0, behavior: "smooth" });
          // console.log(mainRef.current);
          // setActivePage("partners")
        }}
      >
        Book Now
      </Button>
    </div>
  );
};

export default CTA;
