import { IAppDepartmentRights } from "../reducers/utils/ProcessRights";

export const SET_APPRIGHTS = "APPRIGHTS:SET_APPRIGHTS";

interface SetAppRightsAction {
  type: typeof SET_APPRIGHTS;
  payload: {
    rights: IAppDepartmentRights<boolean>;
  }[];
}

export type AppRightsActionTypes = SetAppRightsAction;
