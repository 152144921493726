import { useState } from "react";
import { StateType } from "../partner-forms/useCustomState";

export function useExpressParcel() {
    // State:
    const [cities, setCities] = useState<any>();
    const [sourceBranchList, setSourceBranchList] = useState([]);
    const [destinationBranchList, setDestinationBranchList] = useState([]);
    const [sourcePincode, setSourcePincode] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Pincode is required!"
        }
    });
    const [destinationPincode, setDestinationPincode] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Pincode is required!"
        }
    });
    const [sourceCity, setSourceCity] = useState({ value: "", label: "" });
    const [destinationCity, setDestinationCity] = useState({ value: "", label: "" });
    const [b2bSourceCity, setB2bSourceCity] = useState({ value: "", label: "" });
    const [b2bDestinationCity, setB2bDestinationCity] = useState({ value: "", label: "" });
    const [sourceBranch, setSourceBranch] = useState({ value: "", label: "" });
    const [destinationBranch, setDestinationBranch] = useState({ value: "", label: "" });


    return { cities, setCities, sourceBranchList, setSourceBranchList, destinationBranchList, setDestinationBranchList, sourcePincode, setSourcePincode, destinationPincode, setDestinationPincode, sourceCity, setSourceCity, destinationCity, setDestinationCity, sourceBranch, setSourceBranch, destinationBranch, setDestinationBranch, b2bSourceCity, setB2bSourceCity, b2bDestinationCity, setB2bDestinationCity }
}