// react
import React, {
  ComponentProps,
  Dispatch,
  SetStateAction,
  useState,
} from "react";
// css
import "./input.css";
import { EyeIcon, EyeSlashIcon } from "../../../constants/icons";

// type
type Shades = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
type StateObjType = {
  value: string;
  error: boolean;
  errorMessage: string;
};
type StateType = string | StateObjType;
type InputProps = {
    title: string;
    className?: string;
    state: {
        value: StateType;
        setValue: Dispatch<SetStateAction<StateType>>
    };
    variant: "primary" | "secondary" | "accent" | "danger" | "warning" | "info" | "success" | "white" | "black" | "neutral";
    weight?: Shades;
    warningWeight?: Shades;
    width?: string;
    onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & ComponentProps<"input">;

// Input Component
const Input = ({
  title,
  className = "",
  state,
  variant = "primary",
  weight = 400,
  warningWeight = 400,
  width = "1px",
  onBlur,
  onChange,
  type = "text",
  ...props
}: InputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [inputType, setInputType] = useState(type);
  if (typeof state.value === "string") {
    return (
      <div className="custom-input-container">
        <div className="custom-input-inside-container">
          <input
            type={inputType}
            className={`custom-input ${className} ${
              state.value ? "active" : ""
            } w-100`}
            {...props}
            value={state.value}
            onChange={(e) => {
              if (type === "tel") {
                const isOnlyDigits = /^\d*$/.test(e.target.value);
                // If the value includes non-digit characters, return and do not update
                if (!isOnlyDigits) return;
              }
              if (onChange) onChange(e);
              state.setValue(e.target.value);
            }}
            onFocusCapture={(e) => {
              e.target.style.border = `${width} solid var(--clr-accent-${weight})`;
            }}
            onBlur={(e) => {
              if (onBlur) {
                onBlur(e);
              }
              e.target.style.border = `${width} solid var(--clr-${variant}-${weight})`;
            }}
            style={{ border: `${width} solid var(--clr-${variant}-${weight})` }}
          />
          <span className={`custom-span`}>{title}</span>
        </div>
      </div>
    );
  } else {
    const { value, error, errorMessage } = state.value;
    return (
      <div className="custom-input-container">
        <div className="custom-input-inside-container">
          <input
            type={inputType}
            className={`custom-input ${className} ${value ? "active" : ""}  ${
              error ? `text-danger-400` : ""
            } w-100`}
            {...props}
            value={value}
            onChange={(e) => {
              if (type === "tel") {
                const isOnlyDigits = /^\d*$/.test(e.target.value);
                // If the value includes non-digit characters, return and do not update
                if (!isOnlyDigits) return;
              }
              if (onChange) onChange(e);
              state.setValue((prevValue) => {
                const prev = prevValue as StateObjType;
                return { ...prev, value: e.target.value }; // Always return an updated StateType object
              });
            }}
            onFocusCapture={(e) => {
              e.target.style.border = `${width} solid var(--clr-accent-${weight})`;
              state.setValue((prevValue) => {
                const prev = prevValue as StateObjType;
                return { ...prev, error: false };
              });
            }}
            onBlur={(e) => {
              if (onBlur) {
                onBlur(e);
              }
              if (error) {
                e.target.style.border = `1px solid var(--clr-danger-400})`;
              } else {
                e.target.style.border = `${width} solid var(--clr-${variant}-${weight})`;
              }
            }}
            style={{
              border: error
                ? `1px solid var(--clr-danger-400)`
                : `${width} solid var(--clr-${variant}-${weight})`,
              width: "100%",
            }}
          />
          <span className={`custom-span fw-400 ${error && `text-danger-400`}`}>
            {title}
          </span>
          {type === "password" && (
            <div className="custom-input-icon-container">
              <span
                className="custom-input-icon"
                onClick={() => {
                  setIsPasswordVisible((prev) => !prev);
                  setInputType(isPasswordVisible ? "password" : "text");
                }}
              >
                {isPasswordVisible ? <EyeSlashIcon /> : <EyeIcon />}
              </span>
            </div>
          )}
        </div>
        {error && (
          <p
            style={{
              fontSize: "small",
              margin: 0,
              marginTop: "2px",
              color: `var(--clr-danger-${warningWeight})`,
            }}
          >
            {errorMessage}
          </p>
        )}
      </div>
    );
  }
};

export default Input;
