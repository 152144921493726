import { SET_CUSTOMER_DOCKET_LOCATION, SET_DRIVER_LOCATION } from "../constants/liveLocation";

const initState = {
    driverLocation: {
      isStarted: false
    },
    customerDocketLocation: {
      isStarted: false
    }
  };
  
  export default (state = initState, action: { type: any; payload: any }) => {
    switch (action.type) {
      case SET_DRIVER_LOCATION: {

        // let [what, val] = action.payload
        return {
            ...state,
            driverLocation: {
                ...state.driverLocation,
                ...action.payload
            },
          };
      }
      case SET_CUSTOMER_DOCKET_LOCATION: {
        // console.log("receiveLiveDocketTracking :", action.payload)
        // let [what, val] = action.payload
        return {
            ...state,
            customerDocketLocation: {
                ...state.customerDocketLocation,
                ...action.payload
            },
          };
      }
      default:
        return state;
    }
  };
  