export const BUILTY_UPDATE_ACK = "BOOK:BUILTY_UPDATE_ACK";
export const BUILTY_UPDATE_REQ = "BOOK:BUILTY_UPDATE_REQ";
export const PACKAGE_UPDATE_ACK = "BOOK:PACKAGE_UPDATE_ACK";
export const PAYMENT_UPDATE_ACK = "BOOK:PAYMENT_UPDATE_ACK";
export const SET_DOCKET_FROM_EWAYBILL = "BOOK:SET_DOCKET_FROM_EWAYBILL";
export const SET_MULTIPLE_EWAYBILL_DATA = "BOOK:SET_MULTIPLE_EWAYBILL_DATA";
export const CHANGE_MULTIPLE_EWAYBILL_MANUAL_DATA = "BOOK:CHANGE_MULTIPLE_EWAYBILL_MANUAL_DATA";
export const RESET_PARTICULAR_EWAYBILL_DATA = "BOOK:RESET_PARTICULAR_EWAYBILL_DATA";
export const RESET_EWAYBILL_DATA="BOOK:RESET_EWAYBILL_DATA";
export const REFRESH_BOOKING = "BOOK:REFRESH_BOOKING";
export const GET_BRANCHES_BY_COMPANY = "BOOK:GET_BRANCHES_BY_COMPANY";
export const RESEND_SMS = "BOOK:RESEND_SMS";
export const RESEND_SMS_FAILED = "BOOK:RESEND_SMS_FAILED";
export const ID_FETCH_ACK = "BOOK:ID_FETCH_ACK";
export const BILL_FETCH_ACK = "BOOK:BILL_FETCH_ACK";
export const DOCKETS_FETCH_ACK = "BOOK:DOCKETS_FETCH_ACK";
export const SENDER_GST_INVALID = "BOOK:SENDER_GST_INVALID";
export const RECEIVER_GST_INVALID = "BOOK:RECEIVER_GST_INVALID";
export const RESET_SENDER_ADDRESS = "BOOK:RESET_SENDER_ADDRESS";
export const RESET_RECEIVER_ADDRESS = "BOOK:RESET_RECEIVER_ADDRESS";
export const SHOW_LOADER = "BOOK:SHOW_LOADER";
export const HIDE_LOADER = "BOOK:HIDE_LOADER";
export const HIDE_EWAYBILL_LOADER = "BOOK:HIDE_EWAYBILL_LOADER";
export const SHOW_EWAYBILL_LOADER = "BOOK:SHOW_EWAYBILL_LOADER";
export const SHOW_GST_LOADER = "BOOK:SHOW_GST_LOADER";
export const HIDE_GST_LOADER = "BOOK:HIDE_GST_LOADER";
export const CREDIT_NUMBER_HIDE_LOADER = "BOOK:CREDIT_NUMBER_HIDE_LOADER";
export const CREDIT_NUMBER_SHOW_LOADER = "BOOK:CREDIT_NUMBER_SHOW_LOADER";
export const SHOW_GST_LOADING_FOR_RECEIVER =
  "BOOK:SHOW_GST_LOADING_FOR_RECEIVER";
export const HIDE_GST_LOADING_FOR_RECEIVER =
  "BOOK:HIDE_GST_LOADING_FOR_RECEIVER";
export const SET_SENDER_ADDRESS_FROM_OPBRANCH =
  "BOOK:SET_SENDER_ADDRESS_FROM_OPBRANCH";
export const SET_SENDER_ADDRESS_FROM_OTHER =
  "BOOK:SET_SENDER_ADDRESS_FROM_OTHER";
export const SET_RECEIVER_ADDRESS_FROM_OPBRANCH =
  "BOOK:SET_RECEIVER_ADDRESS_FROM_OPBRANCH";
export const SET_RECEIVER_ADDRESS_FROM_OTHER =
  "BOOK:SET_RECEIVER_ADDRESS_FROM_OTHER";
export const SET_ROUTES = "BOOK:SET_ROUTES";
export const SET_SETTINGS = "BOOK:SET_SETTINGS";
export const SET_HANDLING_CHARGE_SLAB = "BOOK:SET_HANDLING_CHARGE_SLAB";
export const HIDE_LOADER_WITHOUT_BLUR = "BOOK:HIDE_LOADER_WITHOUT_BLUR";
export const UPDATE_BUILTY = "BOOK:UPDATE_BUILTY";
export const SET_PACKAGE_ERROR_FLAG_FALSE = "BOOK:SET_PACKAGE_ERROR_FLAG_FALSE";
export const TOUCH_SENDER = "BOOK:TOUCH_SENDER";
export const TOUCH_RECEIVER = "BOOK:TOUCH_RECEIVER";

// new Actions
export const BOOK_BILL = "BOOK:BOOK_BILL";
export const BOOK_PURPOSE = "BOOK:BOOK_PURPOSE";
export const BOOK_DOCKET = "BOOK:BOOK_DOCKET";
export const BOOK_EWB = "BOOK:BOOK_EWB";
export const SET_PARTICULAR_EWB = "BOOK:SET_PARTICULAR_EWB";
export const ADD_MULTIPLE_EWB = "BOOK:ADD_MULTIPLE_EWB";
export const REMOVE_MULTIPLE_EWB = "BOOK:REMOVE_MULTIPLE_EWB";
export const RESET_MULTIPLE_EWB = "BOOK:RESET_MULTIPLE_EWB";
export const BOOK_SENDER = "BOOK:BOOK_SENDER";
export const BOOK_SET_SENDER = "BOOK:BOOK_SET_SENDER";
export const BOOK_SET_SENDER_VALIDATE = "BOOK:BOOK_SET_SENDER_VALIDATE";
export const BOOK_SET_RECEIVER_VALIDATE = "BOOK:BOOK_SET_RECEIVER_VALIDATE";
export const BOOK_RESET_SENDER = "BOOK:BOOK_RESET_SENDER";
export const BOOK_RESET_RECEIVER = "BOOK:BOOK_RESET_RECEIVER";
export const BOOK_SET_IS_SENDER_DISABLE = "BOOK:BOOK_SET_IS_SENDER_DISABLE";
export const BOOK_SET_IS_RECEIVER_DISABLE = "BOOK:BOOK_SET_IS_RECEIVER_DISABLE";
export const BOOK_SET_FOC_VERIFICATION = "BOOK:BOOK_SET_FOC_VERIFICATION";
export const BOOK_SET_SENDER_VERIFICATION = "BOOK:BOOK_SET_SENDER_VERIFICATION";
export const SET_RECEIVER_SUGGESTIONS = "BOOK:SET_RECEIVER_SUGGESTIONS";
export const SET_RATE_SUGGESTIONS = "BOOK:SET_RATE_SUGGESTIONS";
export const SET_PACKAGE_SUGGESTIONS = "BOOK:SET_PACKAGE_SUGGESTIONS";
export const BOOK_SET_RECEIVER = "BOOK:BOOK_SET_RECEIVER";
export const BOOK_RECEIVER = "BOOK:BOOK_RECEIVER";
export const BOOK_SET_FIXRATECUSTOMER = "BOOK:BOOK_SET_FIXRATECUSTOMER";
export const BOOK_RESET_FIXRATECUSTOMER = "BOOK:BOOK_RESET_FIXRATECUSTOMER";
export const SET_DOCKET = "BOOK:SET_DOCKET";
export const RESET_SENDER_NAME = "BOOK:RESET_SENDER_NAME";
export const RESET_REMARKS = "BOOK:RESET_REMARKS";
export const RESET_RECEIVER_NAME = "BOOK:RESET_RECEIVER_NAME";
export const BOOK_COL_MONTH = "BOOK:BOOK_COL_MONTH";
// export const BOOK_RATE = "BOOK:BOOK_RATE";
export const BOOK_PACK = "BOOK:BOOK_PACK";
export const BOOK_HOW_COL = "BOOK:BOOK_HOW_COL";
export const PICKUP_CHARGE_CHANGE = "BOOK:PICKUP_CHARGE_CHANGE";
export const BOOK_RISK_CHANGE = "BOOK:BOOK_RISK_CHANGE";
export const BOOK_REMARKS_CHANGE = "BOOK:BOOK_REMARKS_CHANGE";
export const BOOK_PICKUP_CONTACT = "BOOK:BOOK_PICKUP_CONTACT";
export const BOOK_CONTACT_DATA = "BOOK:BOOK_CONTACT_DATA"
export const BOOK_GOODS_CHANGE = "BOOK:BOOK_GOODS_CHANGE";
// export const BOOK_INS_CHANGE = "BOOK:BOOK_INS_CHANGE";
export const BOOK_VALUE_CHARGE_CHANGE = "BOOK:BOOK_VALUE_CHARGE_CHANGE";
export const BOOK_COL_CHANGE = "BOOK:BOOK_COL_CHANGE";
export const BOOKING_REFRESH = "BOOK:BOOKING_REFRESH";
export const BOOKING_EDIT_PACKAGE = "BOOK:BOOKING_EDIT_PACKAGE";
export const BOOK_ADD_PACKAGE = "BOOK:BOOK_ADD_PACKAGE";
export const BOOK_DEL_PACKAGE = "BOOK:BOOK_DEL_PACKAGE";
export const ADD_PACKAGE_SIZE = "BOOK:ADD_PACKAGE_SIZE";
// export const DEL_PACKAGE_SIZE = "BOOK:DEL_PACKAGE_SIZE";
export const SET_ACROSS = "BOOK:SET_ACROSS";
export const SET_LR = "BOOK:SET_LR";
export const SET_ORANGE_LR = "BOOK:SET_ORANGE_LR";
export const BOOK_BRANCHES = "BOOK:BOOK_BRANCHES";
export const BOOK_CHANGE_DELIVERY_TYPE = "BOOK:BOOK_CHANGE_DELIVERY_TYPE";
export const BOOK_CHANGE_FEDEX_SERVICE_TYPE =
  "BOOK:BOOK_CHANGE_FEDEX_SERVICE_TYPE";
export const SET_FEDEX_SERVICE_OPTIONS = "BOOK:SET_FEDEX_SERVICE_OPTIONS";
export const BOOK_DEST_BRANCH = "BOOK:BOOK_DEST_BRANCH";
export const BOOK_DEST_ROUTE = "BOOK:BOOK_DEST_ROUTE";
export const BOOK_DEST_SERVICE = "BOOK:BOOK_DEST_SERVICE";
export const RESET_EDIT = "BOOK:RESET_EDIT";
export const BOOK_PAY_MODE = "BOOK:BOOK_PAY_MODE";
export const NONCASH_PAYMENT_TYPE = "BOOK:NONCASH_PAYMENT_TYPE";
export const NONCASH_TXN_ID = "BOOK:NONCASH_TXN_ID";
export const NONCASH_TXN_IMAGE = "BOOK:NONCASH_TXN_IMAGE";
export const NONCASH_TXNS_NUMBER = "BOOK:NONCASH_TXNS_NUMBER";
export const SET_ONLINE_PAYMENT_STATUS = "BOOK:SET_ONLINE_PAYMENT_STATUS";
export const CREDIT_NUM_CHANGE = "BOOK:CREDIT_NUM_CHANGE";
export const BOOK_CREDIT_SET = "BOOK:BOOK_CREDIT_SET";
export const BOOK_CREDIT_RESET = "BOOK:BOOK_CREDIT_RESET";
export const CHANGE_TO_CREDIT_SUCCESS = "BOOK:CHANGE_TO_CREDIT_SUCCESS";
export const VALIDATION_ERROR = "BOOK:VALIDATION_ERROR";
export const SPECIFIC_DELIVERY = "BOOK:SPECIFIC_DELIVERY";
export const SET_SENDER_BY_GST = "BOOK:SET_SENDER_BY_GST";
export const SET_RECEIVER_BY_GST = "BOOK:SET_RECEIVER_BY_GST";
export const BOOK_SET_VERIFICATION = "BOOK:BOOK_SET_VERIFICATION";
export const BOOK_SET_EMPLOYEE_VERIFICATION =
  "BOOK:BOOK_SET_EMPLOYEE_VERIFICATION";
  export const  BOOK_SET_INDIVIDUALFLEET="BOOK:BOOK_SET_INDIVIDUALFLEET"
//fetchDocket pkgupdate
export const SET_UPDATE_PKG_FETCHDOCKET = "SET_UPDATE_PKG_FETCHDOCKET";
export const SET_RATE_ID = "SET_RATE_ID";
export const SET_LAST_BOOKING = "BOOK:SET_LAST_BOOKING";
export const SET_DOOR_DELIVERY = "BOOK:SET_DOOR_DELIVERY"
export const SET_DOOR_DELIVERY_DATA = "BOOK:SET_DOOR_DELIVERY_DATA"
export const RESET_DOOR_DELIVERY = "BOOK:RESET_DOOR_DELIVERY"
export const SET_SENDER_FORCE_NAME_REMOVE_DUE_TO_GST = "BOOK:SET_SENDER_FORCE_NAME_REMOVE_DUE_TO_GST"
export const SET_RECEIVER_FORCE_NAME_REMOVE_DUE_TO_GST = "BOOK:SET_RECEIVER_FORCE_NAME_REMOVE_DUE_TO_GST"
export const SET_SENDER_GST_REMOVE_REQ_DATA = 'BOOK:SET_SENDER_GST_REMOVE_REQ_DATA'
export const SET_RECEIVER_GST_REMOVE_REQ_DATA = 'BOOK:SET_RECEIVER_GST_REMOVE_REQ_DATA'
export const SET_SENDER_GST_CONTACTS = 'BOOK:SET_SENDER_GST_CONTACTS'
export const SET_RECEIVER_GST_CONTACTS = 'BOOK:SET_RECEIVER_GST_CONTACTS'
export const SET_SENDER_SECONDARY_CONTACT_REQ_DATA = 'BOOK:SET_SENDER_SECONDARY_CONTACT_REQ_DATA'
export const SET_RECEIVER_SECONDARY_CONTACT_REQ_DATA = 'BOOK:SET_RECEIVER_SECONDARY_CONTACT_REQ_DATA'
export const SET_GENERAL_WARNING = 'BOOK:SET_GENERAL_WARNING'


export interface BookBillAction {
  type: typeof BOOK_BILL;
  payload: string;
}
export interface BookPurposeAction {
  type: typeof BOOK_PURPOSE;
  payload: {
    value: string;
    label: string;
  };
}
export interface BookDocketAction {
  type: typeof BOOK_DOCKET;
  payload: string;
}
export interface BookEWBAction {
  type: typeof BOOK_EWB;
  payload: string;
}
export interface SetParticularEWBAction {
  type: typeof SET_PARTICULAR_EWB;
  payload: string;
}
export interface AddMultipleEWBAction {
  type: typeof ADD_MULTIPLE_EWB;
  payload: string;
}
export interface RemoveMultipleEWBAction {
  type: typeof REMOVE_MULTIPLE_EWB;
  payload: string;
}
export interface ResetMultipleEWBAction {
  type: typeof RESET_MULTIPLE_EWB;
  payload: string;
}
export interface BookSenderAction {
  type: typeof BOOK_SENDER;
  payload:
    | {
        what: "contact" | "l1" | "l2" | "pin" | "city";
        val: string;
      }
    | {
        what: "name" | "gst";
        val: {
          label: string;
          value: string;
        };
      };
}
export interface BookReceiverAction {
  type: typeof BOOK_RECEIVER;
  payload:
    | {
        what: "contact" | "l1" | "l2" | "pin" | "city";
        val: string;
      }
    | {
        what: "name" | "gst";
        val: {
          label: string;
          value: string;
        };
      };
}

export interface TouchSenderAction {
  type: typeof TOUCH_SENDER;
  payload: "name" | "gst" | "contact" | "l1" | "l2" | "pin" | "city";
}
export interface TouchReceiverAction {
  type: typeof TOUCH_RECEIVER;
  payload: "name" | "gst" | "contact" | "l1" | "l2" | "pin" | "city";
}
export interface CustomerDoc {
  address: {
    l1: string;
    l2: string;
    city: string;
    pincode: string;
  };
  gst: string;
  otherAddr: {
    l1: string;
    l2: string;
    city: string;
    pincode: string;
  }[];
  name: string;
  gstNos: {
    GSTIN: String;
    name: String;
  }[];
  names: string[];
}
export interface BookSetSenderAction {
  type: typeof BOOK_SET_SENDER;
  payload: CustomerDoc;
}
// export interface BookSetSenderVerify {
//   type: typeof BOOK_SET_SENDER_VERIFY;
//   payload: CustomerDoc;
// }
// export interface BookSetReceiverVerify {
//   type: typeof BOOK_SET_RECEIVER_VERIFY;
//   payload: CustomerDoc;
// }
export interface ReceiverSuggestion {
  sameDestCount: 1;
  totalCount: 1;
  receiver: {
    contact: number;
    name: string;
    address: {
      l1: string;
      l2: string;
      city: string;
      pincode: string;
    };
    gst: string;
  };
}
export interface SetReceiverSuggestionAction {
  type: typeof SET_RECEIVER_SUGGESTIONS;
  payload: ReceiverSuggestion[];
}
export interface RateSuggestion {
  _id: string;
  pck: {
    size: string;
    amount: number;
    weight: number;
  };
}
export interface SetRateSuggestionAction {
  type: typeof SET_RATE_SUGGESTIONS;
  payload: RateSuggestion[];
}
export interface PackageSuggestion {
  _id: {
    materialType: string;
    packingType: string;
    size: string;
  };
  packageDoc: {
    materialType: string;
    packingType: string;
    size: string;
    rate: "Direct" | "Per package";
    unit: string;
    id: number;
    qty: number;
    dimension: {
      l: number;
      b: number;
      h: number;
    };
    weight: number;
    amount: number;
    stack: boolean;
    haz: boolean;
    frag: boolean;
  };
  sameReceiverCount: number;
  sameDestCount: number;
  totalCount: number;
}
export interface SetPackageSuggestionAction {
  type: typeof SET_PACKAGE_SUGGESTIONS;
  payload: PackageSuggestion[];
}
export interface BookSetReceiverAction {
  type: typeof BOOK_SET_RECEIVER;
  payload: CustomerDoc;
}
export interface SetDocketAction {
  type: typeof SET_DOCKET;
  payload: any;
}
export interface UpdateBuiltyAction {
  type: typeof UPDATE_BUILTY;
}
export interface SetDocketFromEwaybillAction {
  type: typeof SET_DOCKET_FROM_EWAYBILL;
  payload: {
    legal_name_of_consignor: string;
    gstin_of_consignor: string;
    legal_name_of_consignee: string;
    gstin_of_consignee: string;
    document_number: string;
    total_invoice_value: string;
  };
}
export interface SetMultipleDataEwaybillAction {
  type: typeof SET_MULTIPLE_EWAYBILL_DATA;
  payload: [];
}
export interface ChangeMultipleDataEwaybillAction {
  type: typeof CHANGE_MULTIPLE_EWAYBILL_MANUAL_DATA;
  payload: any;
}
export interface ReSetMultipleDataEwaybillAction {
  type: typeof RESET_PARTICULAR_EWAYBILL_DATA;
  payload: any;
}
export interface ResetSenderNameAction {
  type: typeof RESET_SENDER_NAME;
}
export interface ResetRemarksAction {
  type: typeof RESET_REMARKS;
}
export interface ResetReceverNameAction {
  type: typeof RESET_RECEIVER_NAME;
}
export interface RefreshBookingAction {
  type: typeof REFRESH_BOOKING;
}
export interface GetBranchesByCompanyAction {
  type: typeof GET_BRANCHES_BY_COMPANY;
  payload: any[];
}
export interface BookColMonthAction {
  type: typeof BOOK_COL_MONTH;
  payload: string;
}
// export interface BookRateAction {
//   type: typeof BOOK_RATE;
//   payload: string;
// }
export interface BookPackAction {
  type: typeof BOOK_PACK;
  payload: string;
}
export interface BookHowCollection {
  type: typeof BOOK_HOW_COL;
  payload: { data: any; companyData: any };
}
export interface PickupChargeChangeAction {
  type: typeof PICKUP_CHARGE_CHANGE;
  payload: {
    what: "pickupCharge" | "deliveryCharge";
    val: string;
  };
}
export interface BookRiskChangeAction {
  type: typeof BOOK_RISK_CHANGE;
  payload: { risk: 0 | 1 | 2; companyData: any };
}
export interface BookRemarkChangeAction {
  type: typeof BOOK_REMARKS_CHANGE;
  payload: string;
}
export interface BookPickupContactAction {
  type: typeof BOOK_PICKUP_CONTACT;
  payload: string;
}
export interface BookGoodsChangeAction {
  type: typeof BOOK_GOODS_CHANGE;
  payload: string;
}
// export interface BookInsChangeAction {
//   type: typeof BOOK_INS_CHANGE;
//   payload: string;
// }
export interface BookValueChargeChangeAction {
  type: typeof BOOK_VALUE_CHARGE_CHANGE;
  payload: number;
}
export interface BookColChangeAction {
  type: typeof BOOK_COL_CHANGE;
  payload: string;
}
export interface BookingRefreshAction {
  type: typeof BOOKING_REFRESH;
}
export interface BookingEditPackageAction {
  type: typeof BOOKING_EDIT_PACKAGE;
  payload: {
    id: number;
    name:
      | "stack"
      | "frag"
      | "haz"
      | "size"
      | "mat"
      | "pack"
      | "qty"
      | "l"
      | "b"
      | "h"
      | "u"
      | "w"
      | "rate"
      | "amount";
    val: any;
  };
}
export interface BookAddPackageAction {
  type: typeof BOOK_ADD_PACKAGE;
  payload: any;
}
export interface BookAddSizeAction {
  type: typeof ADD_PACKAGE_SIZE;
  payload: any;
}
// export interface BookDelSizeAction {
//   type: typeof DEL_PACKAGE_SIZE;
//   payload: {
//     _id: number;
//   };
// }
export interface BookDelPackageAction {
  type: typeof BOOK_DEL_PACKAGE;
  payload: {
    id: number;
  };
}
export interface SetAcrossAction {
  type: typeof SET_ACROSS;
  payload: "interState" | "intraState";
}
export interface SetLrAction {
  type: typeof SET_LR;
  payload: string;
}
export interface SetOrangeLrAction {
  type: typeof SET_ORANGE_LR;
  payload: string;
}
export interface BookBranchesAction {
  type: typeof BOOK_BRANCHES;
  payload: any[];
}
export interface BookChangeDeliveryTypeAction {
  type: typeof BOOK_CHANGE_DELIVERY_TYPE;
  payload: { label: string; value: string };
}
export interface BookChangeFedexServiceTypeAction {
  type: typeof BOOK_CHANGE_FEDEX_SERVICE_TYPE;
  payload: { label: string; value: string };
}
export interface BookChangeFedexServiceTypeOptionsAction {
  type: typeof SET_FEDEX_SERVICE_OPTIONS;
  payload: { label: string; value: string }[];
}
export interface BookDestBranchAction {
  type: typeof BOOK_DEST_BRANCH;
  payload: {
    value: string;
    company: any;
    distance?: any;
  };
}
export interface BookDestRouteAction {
  type: typeof BOOK_DEST_ROUTE;
  payload: {
    label: string;
    value: string;
  };
}
export interface ResetEditAction {
  type: typeof RESET_EDIT;
  payload?: {
    exclude: any;
  };
}

export interface NonCashPaymentType {
  type: typeof NONCASH_PAYMENT_TYPE;
  payload: any;
}

export interface NonCashTxnId {
  type: typeof NONCASH_TXN_ID;
  payload: any;
}

export interface NonCashTxnImage {
  type: typeof NONCASH_TXN_IMAGE;
  payload: any;
}

export interface SetPackageErrorFlagFalseAction {
  type: typeof SET_PACKAGE_ERROR_FLAG_FALSE;
}
export interface BookPaymentModeAction {
  type: typeof BOOK_PAY_MODE;
  payload: { label: string; value: string };
}
export interface CreditNumberChangeAction {
  type: typeof CREDIT_NUM_CHANGE;
  payload: string;
}
export interface BookCreditSetAction {
  type: typeof BOOK_CREDIT_SET;
  payload: {
    [k: string]: any;
    // branch_permissions: any[];
    fromBranch_permissions: any[];
    toBranch_permissions: any[];
    fleet_permissions: any[];
  };
}
export interface BookCreditResetAction {
  type: typeof BOOK_CREDIT_RESET;
}
export interface SetSenderAddressFromOpBranchAction {
  type: typeof SET_SENDER_ADDRESS_FROM_OPBRANCH;
}
export interface SetReceiverAddressFromOpBranchAction {
  type: typeof SET_RECEIVER_ADDRESS_FROM_OPBRANCH;
}
export interface SetSenderAddressFromOther {
  type: typeof SET_SENDER_ADDRESS_FROM_OTHER;
  payload: any;
}
export interface SetReceiverAddressFromOther {
  type: typeof SET_RECEIVER_ADDRESS_FROM_OTHER;
  payload: any;
}
export interface ChangeToCreditSuccessAction {
  type: typeof CHANGE_TO_CREDIT_SUCCESS;
  payload: {
    credit: any;
    docket: any;
  };
}
export interface ValidationErrorAction {
  type: typeof VALIDATION_ERROR;
  payload: {
    path: string[];
    message: string | boolean;
  };
}
export interface SpecificDeliveryAction {
  type: typeof SPECIFIC_DELIVERY;
}
export interface SetSenderOrReceiverByGstAction {
  type: typeof SET_SENDER_BY_GST | typeof SET_RECEIVER_BY_GST;
  payload: {
    trade_name: string;
    address1: string;
    address2: string;
    pincode: string;
  };
}
export interface SetRoutesAction {
  type: typeof SET_ROUTES;
  payload: {
    franchise: any[];
    own: any[];
  };
}
export interface SetSettingAction {
  type: typeof SET_SETTINGS;
  payload: {
    valueChargeEnabled: boolean;
    minValueCharge: number;
    perPackageValueCharge: number;
    insuredCompanies: string[];
    deliveryChargesSlab: any[];
    builtyChargeSlab: any[];
    sizes: any[];
  };
}
export interface setHandlingChargeSlabAction {
  type: typeof SET_HANDLING_CHARGE_SLAB;
  payload: any[]
}


export interface setFocVerificationAction {
  type: typeof BOOK_SET_FOC_VERIFICATION;
  payload: {
    what: any;
    val: any;
  };
}

export interface setSenderVerificationAction {
  type: typeof BOOK_SET_SENDER_VERIFICATION;
  payload: {
    what: any;
    val: any;
  };
}

export interface setBookVerificationAction {
  type: typeof BOOK_SET_VERIFICATION;
  payload: {
    what: any;
    val: any;
  };
}

export interface setEmployeeVerificationAction {
  type: typeof BOOK_SET_EMPLOYEE_VERIFICATION;
  payload: {
    what: any;
    val: any;
  };
}

export interface setDoorDeliveryAction {
  type: typeof SET_DOOR_DELIVERY;
  payload: {
    what: any;
    val: any;
  }
}
export interface setDoorDeliveryActionData {
  type: typeof SET_DOOR_DELIVERY_DATA;
  payload: {
    what: any;
    val: any;
  }
}

export interface setSenderForceNameRemoveDueToGst {
  type: typeof SET_SENDER_FORCE_NAME_REMOVE_DUE_TO_GST;
  payload: {
    what: any;
    val: any;
  }
}

export interface setReceiverForceNameRemoveDueToGst {
  type: typeof SET_RECEIVER_FORCE_NAME_REMOVE_DUE_TO_GST;
  payload: {
    what: any;
    val: any;
  }
}

export interface setSenderGstRemoveReqData {
  type: typeof SET_SENDER_GST_REMOVE_REQ_DATA;
  payload: {
    dialog?: boolean;
    step?: number;
    gstData?: [];
    removedGst?: string;
    newGst?: string;
    primaryContact: string
  }
}

export interface setReceiverGstRemoveReqData {
  type: typeof SET_RECEIVER_GST_REMOVE_REQ_DATA;
  payload: {
    dialog?: boolean;
    step?: number;
    gstData?: [];
    removedGst?: string;
    newGst?: string;
    primaryContact: string
  }
}

export interface OtherBookingActions {
  type:
    | typeof BUILTY_UPDATE_ACK
    | typeof BUILTY_UPDATE_REQ
    | typeof PACKAGE_UPDATE_ACK
    | typeof PAYMENT_UPDATE_ACK
    | typeof ID_FETCH_ACK
    | typeof BILL_FETCH_ACK
    | typeof DOCKETS_FETCH_ACK
    | typeof SENDER_GST_INVALID
    | typeof RECEIVER_GST_INVALID
    | typeof RESET_RECEIVER_ADDRESS
    | typeof SHOW_LOADER
    | typeof HIDE_LOADER_WITHOUT_BLUR
    | typeof HIDE_LOADER
    | typeof SHOW_EWAYBILL_LOADER
    | typeof HIDE_EWAYBILL_LOADER
    | typeof SHOW_GST_LOADER
    | typeof HIDE_GST_LOADER
    | typeof CREDIT_NUMBER_HIDE_LOADER
    | typeof CREDIT_NUMBER_SHOW_LOADER
    | typeof SHOW_GST_LOADING_FOR_RECEIVER
    | typeof HIDE_GST_LOADING_FOR_RECEIVER
    | typeof BOOK_SET_FOC_VERIFICATION
    | typeof BOOK_SET_SENDER_VERIFICATION
    | typeof BOOK_SET_EMPLOYEE_VERIFICATION
    | typeof RESET_SENDER_ADDRESS
    | typeof BOOK_SET_VERIFICATION
    | typeof SET_DOOR_DELIVERY
    | typeof SET_SENDER_FORCE_NAME_REMOVE_DUE_TO_GST
    | typeof SET_RECEIVER_FORCE_NAME_REMOVE_DUE_TO_GST
    | typeof SET_SENDER_FORCE_NAME_REMOVE_DUE_TO_GST
    | typeof SET_RECEIVER_FORCE_NAME_REMOVE_DUE_TO_GST;
}
export type BookingActionTypes =
  | BookBillAction
  | BookPurposeAction
  | BookDocketAction
  | BookEWBAction
  | SetParticularEWBAction
  | AddMultipleEWBAction
  | RemoveMultipleEWBAction
  | ResetMultipleEWBAction
  | BookSenderAction
  | BookReceiverAction
  | TouchSenderAction
  | TouchReceiverAction
  | BookSetSenderAction
  // | BookSetSenderVerify
  // | BookSetReceiverVerify
  | SetReceiverSuggestionAction
  | SetRateSuggestionAction
  | SetPackageSuggestionAction
  | BookSetReceiverAction
  | SetDocketAction
  | UpdateBuiltyAction
  | SetDocketFromEwaybillAction
  | SetMultipleDataEwaybillAction
  | ChangeMultipleDataEwaybillAction
  | ReSetMultipleDataEwaybillAction
  | ResetSenderNameAction
  | ResetRemarksAction
  | ResetReceverNameAction
  | RefreshBookingAction
  | GetBranchesByCompanyAction
  | BookColMonthAction
  // | BookRateAction
  | BookPackAction
  | BookHowCollection
  | PickupChargeChangeAction
  | BookRiskChangeAction
  | BookRiskChangeAction
  | BookRemarkChangeAction
  | BookPickupContactAction
  | BookGoodsChangeAction
  // | BookInsChangeAction
  | BookValueChargeChangeAction
  | BookColChangeAction
  | BookingRefreshAction
  | BookingEditPackageAction
  | BookAddPackageAction
  | BookAddSizeAction
  | BookDelPackageAction
  // | BookDelSizeAction
  | SetAcrossAction
  | SetLrAction
  | SetOrangeLrAction
  | BookBranchesAction
  | BookChangeDeliveryTypeAction
  | BookChangeFedexServiceTypeAction
  | BookChangeFedexServiceTypeOptionsAction
  | BookDestBranchAction
  | BookDestRouteAction
  | ResetEditAction
  | SetPackageErrorFlagFalseAction
  | BookPaymentModeAction
  | CreditNumberChangeAction
  | BookCreditSetAction
  | BookCreditResetAction
  | SetSenderAddressFromOpBranchAction
  | SetReceiverAddressFromOpBranchAction
  | SetSenderAddressFromOther
  | SetReceiverAddressFromOther
  | ChangeToCreditSuccessAction
  | ValidationErrorAction
  | SpecificDeliveryAction
  | SetSenderOrReceiverByGstAction
  | OtherBookingActions
  | SetRoutesAction
  | SetSettingAction
  | NonCashPaymentType
  | NonCashTxnId
  | setFocVerificationAction
  | setSenderVerificationAction
  | NonCashTxnImage
  | setBookVerificationAction
  | setEmployeeVerificationAction
  | setDoorDeliveryAction
  | setDoorDeliveryActionData
  | setHandlingChargeSlabAction
  | setReceiverForceNameRemoveDueToGst
  | setSenderForceNameRemoveDueToGst
  | setSenderGstRemoveReqData
  | setReceiverGstRemoveReqData
