import React from "react";
import { FormHelperText } from "@mui/material";
// import SelectField from "react-select";
import Grid from "../Grid/Grid";
import ReactSelect, {
  TSelectProps,
} from "../../newSrc/components/UI/select/react-select";

type CustomSelectProps = TSelectProps & { error?: any; message?: any };

export function Select({ className, ...props }: CustomSelectProps) {
  return (
    <div style={{ padding: "5px" }}>
      <ReactSelect
        //  ref={props.ref}
        className={"heighten " + `${className}`}
        {...props}
      />
      {props.error && props.message ? (
        <FormHelperText style={{ color: "#f44336" }}>
          {props.message}
        </FormHelperText>
      ) : null}
    </div>
  );
}

export function Select2({ className, ...props }: CustomSelectProps) {
  return (
    <Grid lg={2} md={2} sm={12} xs={12} style={{ padding: "5px" }}>
      <ReactSelect className={"heighten " + `${className}`} {...props} />
      {props.error && props.message ? (
        <FormHelperText style={{ color: "#f44336" }}>
          {props.message}
        </FormHelperText>
      ) : null}
    </Grid>
  );
}

export function Select3({ className, ...props }: CustomSelectProps) {
  return (
    <Grid lg={3} md={3} sm={12} xs={12} style={{ padding: "5px" }}>
      <ReactSelect className={"heighten " + `${className}`} {...props} />
      {props.error && props.message ? (
        <FormHelperText style={{ color: "#f44336" }}>
          {props.message}
        </FormHelperText>
      ) : null}
    </Grid>
  );
}

export function Select6({ className, ...props }: CustomSelectProps) {
  return (
    <Grid lg={6} md={6} sm={12} xs={12} style={{ padding: "5px" }}>
      <ReactSelect className={"heighten " + `${className}`} {...props} />
      {props.error && props.message ? (
        <FormHelperText style={{ color: "#f44336" }}>
          {props.message}
        </FormHelperText>
      ) : null}
    </Grid>
  );
}

export function Select12({ className, ...props }: CustomSelectProps) {
  return (
    <Grid lg={12} md={12} sm={12} xs={12} style={{ padding: "5px" }}>
      <ReactSelect className={"heighten " + `${className}`} {...props} />
      {props.error && props.message ? (
        <FormHelperText style={{ color: "#f44336" }}>
          {props.message}
        </FormHelperText>
      ) : null}
    </Grid>
  );
}
