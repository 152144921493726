import imageCompression from "browser-image-compression";

const options = {
  maxSizeMB: 0.6, // (default: Number.POSITIVE_INFINITY)
  maxWidthOrHeight: 1280, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
  //   useWebWorker: boolean, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
  //   maxIteration: number, // optional, max number of iteration to compress the image (default: 10)
  //   exifOrientation: number, // optional, see https://stackoverflow.com/a/32490603/10395024
  //   onProgress: Function, // optional, a function takes one progress argument (percentage from 0 to 100)
  //   fileType: string, // optional, fileType override
};

export const compressImageFile = async (file: File | Blob) =>
  imageCompression(file as File, options);

export const compressImageFileFromUrl = async (
  url: string,
  filename: string
) => {
  const file = await imageCompression.getFilefromDataUrl(url, filename);
  return compressImageFile(file);
};

export const compressImageUrl = async (url: string) => {
  const file = await imageCompression.getFilefromDataUrl(url, "temp");
  const img = await compressImageFile(file);
  return imageCompression.getDataUrlFromFile(img);
};
