export const setTimeFormat = (date: any) => {
  if (date) {
    let FormattedTime =
      new Date(date).getHours() +
      ":" +
      new Date(date).getMinutes() +
      ":" +
      new Date(date).getSeconds();

    return FormattedTime;
  } else return "--";
};

export default setTimeFormat;
