import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { hideLoader, showLoader } from "../../actions/UserActions";
import { createCustomerCCAvenueTxn } from "../../services/paymentGateWay";
import { showHttpError } from "../../utils/message";
import { ButtonPrimary } from "../../Components/Button/Buttoncontainer";
import { queryStringToJSON } from "../../services";

interface IProps {

    showLoader: () => {};
    hideLoader: () => {};
  }

 function CreditBillSettle(props:IProps) {
  // const [creditBillId, setCreditBillId] = useState("")
  // const [contact, setContact] = useState("")
  // const [amount, setAmount] = useState(0)

  useEffect(() => {

    const queryString = window.location.search;

    // Parse the query string into an object
   const querydata= queryStringToJSON(queryString.split("?")[1])
   let creditData:any={

   }
   for (let  [key,value] of Object.entries(querydata) ) {
    if(key=="contact"){
      // setContact(value)
      creditData.contact=value
    }
   if(key=="creditBillId"){
    // setCreditBillId(value)
    creditData.creditBillId=value

   }
   if(key=="amount"){
    creditData.amount=value
    // setAmount(value)
   }
   console.log("key : ",key)
   }
   handleOutsidePayment(creditData)
    console.log("queryParams :  ",)
  } , [])
    const handleOutsidePayment = async (creditData:any) => {
        console.log(`process.env.NODE_ENV` , process.env.NODE_ENV);
        props.showLoader();
        try {
          const currentURLWithPort = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
          let body : any = {
            "merchant_id": 174716,
            "amount":creditData.amount,
            "frontend_redirect_url":`${currentURLWithPort}/paymentSuccess`,
            nonCashPaymentId: process.env.NODE_ENV != "development"?"655dabeb1baae4150e9ad853":"654dde778196ec1f9c6af36e",// for testing need to call api
            "txnsType":"CreditBill",
            "contact":creditData.contact,
            "creditBillId":creditData.creditBillId
          }
          let response = await createCustomerCCAvenueTxn(body);
          console.log(`response payment`,response)
          const encRequest = response && response?.encRequest; 
          const accessCode20 = response && response?.accessCode; 
          // setTxnsNumber(response.CCAvenueTxns.txnsNumber)
          if(encRequest){
            // const redirectURL = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${encRequest}&access_code=${accessCode}`;
            // window.location.href = redirectURL;
            const a = document.createElement("a");
            // a.setAttribute("target", "_blank");
            a.href = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=${encRequest}&access_code=${accessCode20}`;
          // a.setAttribute("target", "_blank");

            document.body.appendChild(a);
            a.click();
          }
    
          props.hideLoader();
        } catch (error) {
            props.hideLoader();
          showHttpError(error)
          console.log('common Error initiating payment:', error);
        }
      };
//this module for redirecting
  return (
    <div>
        <div>CreditBillSettle</div>
        <div>
            <ButtonPrimary onClick={handleOutsidePayment}>Pay Now</ButtonPrimary>
        </div>
    </div>
  )
}


const mapStateToProps = (state : any) => ({
    user : state?.user,
})

const mapDispatchToProps = {
hideLoader,
showLoader
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditBillSettle)