import { REMOVE_ALL_VERIFY_STOCKS_IMAGES, REMOVE_VERIFY_STOCKS_IMAGES, SET_VERIFY_STOCKS_IMAGES } from "../constants/verifyStocks";

const initState: any = {}

export const verifyStocksReducer = (state: any = initState, action: any) => {
    
    switch (action.type) {
        case SET_VERIFY_STOCKS_IMAGES: {
            const existingImages = state[action.payload.docketNumber]?.packagesImage?.images || [];
            const newImages = action.payload?.packages?.pckData?.pck?.filter((res: any) => res.img) || [];
            const uniqueImages = [...existingImages, ...newImages].filter((image, index, self) =>
                index === self.findIndex((img) => img._id === image._id)
            );
            return {
                ...state,
                [action.payload.docketNumber]: {
                    ...state[action.payload.docketNumber],
                    packagesImage: {
                        ...state[action.payload.docketNumber]?.packagesImage,
                        images: uniqueImages
                    }
                }
            };
        }
        case REMOVE_VERIFY_STOCKS_IMAGES: {
            return {
                ...state,
                [action.payload.docketNumber]: {
                    ...state[action.payload.docketNumber],
                    packagesImage: {
                        ...state[action.payload.docketNumber]?.packagesImage,
                        images: (state[action.payload.docketNumber]?.packagesImage?.images || []).filter(
                            (res: any) => res._id !== action.payload?.packages._id
                        )
                    }
                }
            };
        }
        case REMOVE_ALL_VERIFY_STOCKS_IMAGES: {
            return {
                ...state,
                [action.payload.docketNumber]: undefined
            };
        }
        default: {
            return state;
        }
    }
};