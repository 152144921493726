import { buildQueryString, ServiceMaker } from "./index";

export interface ListBranchServiceQuery {
  company?: string
  searchName: string
}

export const getAllBranchesByCompany = (id: string) =>
  ServiceMaker("branch/list/by/company/" + id, "GET");

export const updateTableCashBranchwise = (
  id: string,
  data: { tableCash: number }
) => ServiceMaker(`branch/tableCash/${id}`, "PATCH", data);

export const viewBranch = (id: string) =>
  ServiceMaker("branch/view/" + id, "GET");

export const filterBookBranches = (data?: {
  company: string;
  originBranch?: string;
}) => ServiceMaker("branch/book/filter", "POST", data);

export const AgentList = () => ServiceMaker("income/listBookingAgents", "GET");

export const fetchBranchesList = (data: any) =>
  ServiceMaker("branch/fetch", "POST", data);

export const deleteBranchService = (id: string, data: any) =>
  ServiceMaker(`branch/delete/${id}`, "DELETE", data);

export const createBranchService = (data: any) =>
  ServiceMaker("branch/manage", "POST", data, 0);

export interface IBranchInfo {
  _id: string;
  branchName: string;
  shortName: string;
  company: {
    _id: string;
    companyName: string;
  };
}

export const getAllBranches = (data: any): Promise<IBranchInfo[]> =>
  ServiceMaker("branch/all", "POST", data);

export const originBranchList = () => ServiceMaker("branch/originList", "GET");

export const getActiveInactiveBranch = (company: string) =>
  ServiceMaker(`branch/listbranch/${company}`, "GET");

export const getDetailedBranchList = (data: any) =>
  ServiceMaker("branch/detailedList", "POST", data);
  
  export const getUserActivityUpdate = (data: any) =>
    ServiceMaker("branch/addBranchActivity", "POST", data);
  

    export const listBranchRanking = (data?:any) =>
    ServiceMaker(`branch/listBranchRanking?${buildQueryString(data)}`,"GET")

    export const branchGraceService = (data: any) =>
  ServiceMaker(`branch/addGracePeriod/${data.branchId}`, "POST", data);

  export const qrRelaxationFromPackages = (data: any) =>
  ServiceMaker(`package/packagesQrRelaxation`, "PATCH", data);

  
  export const listBranch = (query?: ListBranchServiceQuery) =>
    ServiceMaker<{ _id: string; branchName: string }[]>(`branch/list/all?${buildQueryString(query || {})}`, "GET");