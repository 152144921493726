import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_URL, REACT_APP_VERSION } from "./config";
import store from "./store";
import { getCustomerDataWithRefreshToken } from "./services/customer";
export const endPoint =
  process.env.REACT_APP_API_URL || API_URL[process.env.NODE_ENV as any];
export const baseURL = endPoint + "/api";

const test = process.env.NODE_ENV == "test";
const attachJWT : any = (config: AxiosRequestConfig) => {
  const { url } = config;
  const oldHeaders : any = { ...config.headers };
  if (url && !url.includes("taptapassets")) {
    const role = sessionStorage.getItem("role");
    const loginFromForm = sessionStorage.getItem("loginFromForm");
    oldHeaders.authorization = (!loginFromForm && role === "transporter") ? sessionStorage.getItem("kabra:token") : localStorage.getItem("kabra:customertoken")
  }
  return {
    ...config,
    headers: {
      ...oldHeaders,
    },
  };
};

let reloadNotified = false;
const reactVersionRegex = /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/;
const checkReactVersion = (response: AxiosResponse<any>) => {
  try {
    if (reloadNotified === false) {
      let newVersionRequired = false;
      const version = response.headers && response.headers["x-rv"];
      if (
        REACT_APP_VERSION &&
        typeof version === "string" &&
        reactVersionRegex.test(version) &&
        reactVersionRegex.test(REACT_APP_VERSION)
      ) {
        const reactVArray = REACT_APP_VERSION.split(".");
        const requiredVArray = version.split(".");
        for (let i = 0; i < 3; i++) {
          if (Number(reactVArray[i]) < Number(requiredVArray[i])) {
            newVersionRequired = true;
          } else if (Number(reactVArray[i]) > Number(requiredVArray[i])) {
            break;
          }
        }
      }
      if (newVersionRequired) {
        reloadNotified = true;
        store.dispatch({
          type: "SET_RELOAD_APP_DIALOG",
          payload: true,
        });
      }
    }
  } catch (e) {}
  return response;
};
// const role1 = sessionStorage.getItem("role");
const API = axios.create({
  baseURL: baseURL + "/",
  headers: {
    authorization: sessionStorage.getItem("kabra:token")
  },
});
// API.defaults.withCredentials = true;

// if (!test) {
//   API.interceptors.request.use(attachJWT);
//   axios.interceptors.request.use(attachJWT);
//   API.interceptors.response.use(checkReactVersion);
//   axios.interceptors.response.use(checkReactVersion);
// }

if (!test) {
  API.interceptors.request.use(attachJWT);
  axios.interceptors.request.use(attachJWT);
  axios.interceptors.response.use(checkReactVersion);
  // Add a response interceptor
  API.interceptors.response.use(
    (response) => {
      checkReactVersion(response);
      return response;
    },
    async (error) => {
      console.log(error, "Error in response interceptor");
      const originalRequest = error.config;
      const role = sessionStorage.getItem("role");

      if (error.response && error.response.status === 401 && !originalRequest._retry && role === "customer") {
        originalRequest._retry = true;
        
        try {
          const payload = {
            rt: localStorage.getItem("refreshToken")
          };
          const newToken = await getCustomerDataWithRefreshToken(payload);
          console.log(newToken, "New token received");

          if (newToken) {
            // Update the authorization header in the original request
              originalRequest.headers['Authorization'] = `Bearer ${newToken.accessToken}`;
              localStorage.setItem("kabra:customertoken", newToken.accessToken);
              localStorage.setItem("kabra:customer", newToken.customer._id);
              localStorage.setItem("refreshToken", newToken.refreshToken);
              localStorage.setItem("refreshTokenExpiresAt", newToken.refreshTokenExpiresAt);
            // Update tokens in storage
            // Retry the original request with the new token
            return axios(originalRequest);
          }
        } catch (tokenRefreshError) {
          console.error("Token refresh failed", tokenRefreshError);
          localStorage.removeItem("kabra:customertoken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("refreshTokenExpiresAt");
          return Promise.reject(tokenRefreshError);
        }
      }

      return Promise.reject(error);
    }
  );
}


export default API;
