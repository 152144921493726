const initState = {
  isReloadAppDialogOpen: false,
};

type AppActionsState = typeof initState;

export default function(
  state = initState,
  action: { type: "SET_RELOAD_APP_DIALOG"; payload: boolean }
): AppActionsState {
  switch (action.type) {
    case "SET_RELOAD_APP_DIALOG": {
      return {
        ...state,
        isReloadAppDialogOpen: action.payload,
      };
    }

    default:
      return state;
  }
}
