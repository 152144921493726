import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { hideLoader } from "../../../actions/UserActions";
import { showLoader } from "./../../../actions/UserActions";
import { connect, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../../../Components/Dialog/DialogComponent";
import { Input, Input12 } from "../../../Components/Input/Inputcomponents";
import {
  ButtonDefault,
  ButtonPrimary,
} from "../../../Components/Button/Buttoncontainer";
import { Select, Select12 } from "../../../Components/Selectcomponents/Select";
import { getCity } from "../../../actions/UserActions";

// import { createDoorFleetOrder } from "../../actions/fleet";
import Grid from "../../../Components/Grid/Grid";
import { TextField, useMediaQuery, useTheme } from "@mui/material";
import {
  setDoorDelivery,
} from "../../../services/operations";
import showMessage, {
  failed,
  showHttpError,
  // showHttpError,
  success,
} from "../../../utils/message";
// import { getCityByPincode } from "../../../services/area";
import { getAvailableServicesIntraCityPrice } from "../../../services/intraCityRate";
import { setCustomerDoorDelivery } from "../../../services/customersection";
import { reqForcancelDoorDelivery, reqForcancelDoorDeliveryFromCustomer } from "../../../services/doorDelivery";
import { reqForDoorDeliveryDiscount } from "../../../services/discountRequest";
import { getDoorDeliveryRatesByCustomer } from "../../../services/rate";
// import {Paper} from "../../../Components/Paper/Paper";
import { BiRefresh } from 'react-icons/bi';
import { FiPackage } from 'react-icons/fi';
import { GiWeight } from 'react-icons/gi';
import MovableMap from '../../CustomerSection/MovableMap/MovableMap';
// import {AiOutlineClose} from 'react-icons/ai'
import { useGoogleMapJsLoader } from "../../../hooks/useGoogleMapJsLoader";
import { HoursIcon, InfoIcon, PackageIcon, VehicleIcon, WeightIcon } from "../../../newSrc/constants/icons";
import GoogleAutoComplete from "../../../newSrc/components/GoogleAutoComplete/GoogleAutoComplete";
import { CreateEditLocationType, LocationType } from "../../../newSrc/pages/CustomerModule/Components/CustomerBooking/types/booking.type";
import { processLocationPayload } from "../../../newSrc/pages/CustomerModule/Components/CustomerBooking/utils/booking.utils";
import { Button } from "../../../newSrc/components/UI";
import { addAddressSuggetion, addAddressSuggetionByUser, editAddressSuggetion, editAddressSuggetionByUser, listAddressSuggetion, listAddressSuggetionByUser } from "../../../newSrc/components/GoogleAutoComplete/api";
import { LocationtypeEnums } from "../../../newSrc/pages/CustomerModule/Components/CustomerBooking/enums/booking.enums";
// import { CloseIcon } from "../../../newSrc/constants/icons";

interface IProps {
  doorDeliveryPaymentMode: any;
  deliveryCharge: any;
  officeName: any;
  floor: any;
  tower: any;
  nearby_landmark: any;
  city: any;
  pincode: any;
  onChange: any;
  cityValue: any;
  citylist: any;
  locationType: any;
  isDeliveryModule?: any;
  extraDeliveryCharge?: any;
  showLoader?: any;
  hideLoader?: any;
  user?: any;
  docket?: any;
  origin?: any;
  paymentOptions?: any;
  update?: any;
  placeId?: any;
  coordinate?: any;
  placeValue?: any;
  name?: any;
  formatted_address?: any;
  area?: any;
  isSettled?: any;
  isBookingModule?: any;
  onMakePaymentSuccess?: any;
  width?: any;
  state?: any;
  shouldDoorDeliveryBook?: any;
  children?: any;
  open?: any;
  setOpen?: any;
  disablePayment?: any;
  disableAddress?: any;
  showPaymentInfo?: boolean;
  fromPlaceId?: any;
  toPlaceId?: any;
  delCity?: any;
  packages?: any;
  serviceType?: any;
  assetType?: any;
  isCustomerTracking?: any;
  doorDeliveryDocketId?: any;
  doorDeliveryDiscount?: any;
  receiverId?: any;
  onReset?: any;
  doorDelDocketNumber?: any;
  whereFrom?: any;
  handleAfterBooking?: any;
  receiver?: any;
  searchLocation?: any
  l1: any;
  l2: any;
}

// type PaymentMode = {
//   label: string;
//   value: string;
// };

const DoorDelivery = forwardRef(
  ({ showPaymentInfo = true, ...props }: IProps, ref: any) => {
    // let {open, setOpen} = props
    // const { isLoaded } = useJsApiLoader({
    //   googleMapsApiKey: "AIzaSyA5kw1jdPoWOGfUDL0o2vW0Ezf2Cvq12OU",
    //   libraries: ["places"],
    //   nonce: "nonce",
    // });
    const { isLoaded } = useGoogleMapJsLoader()
    console.log(`from Main 2029`, props);
    const [extraDoorDeliveryCharge, setExtraDoorDeliveryCharge] = useState(0);
    const [newExtraDoorDeliveryCharge, setNewExtraDoorDeliveryCharge] =
      useState(0);
    const [extraDoorDeliveryDialog, setExtraDoorDeliveryDialog] =
      useState(false);
    const [doorDeliveryCharge, setDoorDeliveryCharge] = useState<any>(props?.deliveryCharge);
    const [doorDeliveryDiscount, setDoorDeliveryDiscount] = useState<any>(0);
    const [city, setCity] = useState<any>("");
    const [paymentMode, setPaymentMode] = useState<any>("");
    const [state, setState] = useState<any>("");
    const [servicesOptions, setServicesOptions] = useState<any>("");
    const [expressServicesOptions, setExpressServicesOptions] = useState<any>("");
    const [service, setService] = useState<any>("");
    const [fleetTypeWiseRate, setFleetTypeWiseRate] = useState<any>("");
    const [totalDuration, setTotalDuration] = useState(0);
    const [totalDistance, setTotalDistance] = useState<any>(0);
    // const [totalKms, setTotalKms] = useState<any>(0);
    const [totalKg, setTotalKg] = useState<any>(0);
    const [totalPkg, setTotalPkg] = useState<any>(0);
    const [cancelOrderRemarks, setCancelOrderRemarks] = useState<any>("");
    const [discountRemarks, setDiscountRemarks] = useState<any>("");
    const [cancelOrderDialog, setCancelOrderDialog] = useState<any>("");
    const [discountDialog, setDiscountDialog] = useState<any>("");
    const [discountAmount, setDiscountAmount] = useState<any>("");
    const [customerPrice, setCustomerPrice] = useState<any>([]);
    // const [paymentModes, setPaymentModes] = useState<PaymentMode[]>([]);
    const [showDoorDelCharge, setShowDoorDelCharge] = useState<any>(false);
    const [showExtra, setShowExtra] = useState<any>(true);
    const [openPkgDetail, setOpenPkgDetail] = useState<any>(false);
    const [savedAddresses, setSavedAddresses] = useState<any[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<any>();
    const [activeLocationType, setActiveLocationType] = useState<LocationType | null>(null);
    const [googleAutoComplete, setGoogleAutoComplete] = useState<any>(null);

    const paymentRights: any = useSelector<
      RootState,
      {
        credit: boolean;
        foc: boolean;
        paid: boolean;
        toPay: boolean;
        nonCash: boolean;
      }
    >((state) => state.user.opBranch.booking);

    useEffect(() => {
      setPaymentModeOptions();
    }, [paymentRights]);

    useEffect(() => {
      handleChargesDisable("charge");
      handleChargesDisable("extra");
    }, [paymentMode]);

    const setPaymentModeOptions = () => {

      // const paymentModesTemp: any = paymentRights
      //   ? Object.keys(paymentRights)
      //       .map((r: any) => {
      //         if (r != "credit" && r!="foc" && r!="toPay") return null;
      //         return  { label: r, value: r.toLowerCase() }
      //       })
      //       .filter((p) => !!p)
      //       .sort((a: any, b: any) => b.label.localeCompare(a.label))
      //   : [];
      // setPaymentModes(paymentModesTemp);
    };

    const handleDialogClose = () => {
      props.setOpen(false);
      if (!props.isBookingModule && !props.doorDelDocketNumber && !props.isCustomerTracking) props.onReset();
    };

    useImperativeHandle(ref, () => ({
      handleOpen() {
        props.setOpen(true);
      },
    }));

    useEffect(() => {
      if (props.open === false && !props.isCustomerTracking && !props?.isDeliveryModule && !props?.isBookingModule) {
        setService("");
        setTotalDuration(0);
        setTotalDistance(0);
        setTotalKg(0);
        setTotalPkg(0);
      }
    }, [props.open]);

    useEffect(() => {
      props.onChange("city", props.delCity);
    }, [props.doorDelDocketNumber])

    const getcustomerPrice = async () => {
      const payload: any = {
        fromPlace: props?.fromPlaceId,
        toPlace: props?.toPlaceId,
        receiver: props?.receiverId
      }

      try {
        const response = await getDoorDeliveryRatesByCustomer(payload);
        let charges: any = [];
        response.map((e: any) => {
          charges.push({ label: e?.totalDoorDelCharges, value: e?.totalDoorDelCharges })
        })
        setCustomerPrice(charges);
      }
      catch (err: any) {
        showHttpError(err.message);
      }
    }

    useEffect(() => {
      if (props.fromPlaceId && props.toPlaceId && props.receiverId) getcustomerPrice();
    }, [props.fromPlaceId, props.toPlaceId, props.receiverId]);

    useEffect(() => {
      if (props.serviceType) {
        setService({
          label: props.serviceType,
          value: doorDeliveryCharge,
          serviceType: props.serviceType,
          assetType: props.assetType?._id,
        });

        if (props.serviceType === 'EXPRESS' && props.assetType?.assetName) {
          setFleetTypeWiseRate({
            label: `${props.assetType?.assetName} , ₹${Number(doorDeliveryCharge)} `,
            value: doorDeliveryCharge,
            serviceType: "EXPRESS",
            assetType: props.assetType?._id
          })
        }
      }
    }, [props.serviceType, doorDeliveryCharge]);

    useEffect(() => {
      setExtraDoorDeliveryCharge(
        parseInt(props.extraDeliveryCharge)
          ? parseInt(props.extraDeliveryCharge)
          : 0
      );


      console.log("checking 21212", props.deliveryCharge, props.doorDeliveryDiscount)
      setDoorDeliveryCharge(
        parseInt(props.deliveryCharge) ? parseInt(props.deliveryCharge) : 0
      );
      setDoorDeliveryDiscount(
        parseInt(props.doorDeliveryDiscount) ? parseInt(props.doorDeliveryDiscount) : 0
      );
    }, [props.extraDeliveryCharge, props.deliveryCharge, props.doorDeliveryDiscount]);

    console.log("totalDistanceMeter", totalDistance, totalDuration);
    // const getCityFromPincode = async (pin: any) => {
    //   try {
    //     if (props.pincode && props.pincode.toString().length === 6) {
    //       let data = await getCityByPincode(pin);
    //       props.onChange("city", {
    //         label: data.name,
    //         value: data._id,
    //       });
    //     }
    //   } catch (err : any) {
    //     showHttpError(err);
    //   }
    // };
    // // useEffect(() => {
    // //   getCityFromPincode(props.pincode);
    // // }, [props.pincode]);

    useEffect(() => {
      if (props.receiver?.contact) {
        if (props.isCustomerTracking) {
          getSuggestionsForCustomers()
        }
        else {
          getSuggestions()
        }
      }
    }, [props.receiver])

    useEffect(() => {
      props.city && typeof props.city === "string"
        ? // ? setCity(allCities.find((x: any) => x.value === props.city))
        setCity({ value: props.city, label: props.city })
        : setCity(props.city);
    }, [props.city, props.open]);

    console.log("set city", city);
    useEffect(() => {
      props.state && typeof props.state === "string"
        ? // ? setCity(allCities.find((x: any) => x.value === props.city))
        setState({ value: props.state, label: props.state })
        : setState(props.state);
    }, [props.state, props.open]);

    useEffect(() => {
      props.doorDeliveryPaymentMode &&
        typeof props.doorDeliveryPaymentMode === "string"
        ? setPaymentMode({
          value: props.doorDeliveryPaymentMode,
          label: props.doorDeliveryPaymentMode,
        })
        : setPaymentMode(props.doorDeliveryPaymentMode);
    }, [props.doorDeliveryPaymentMode]);

    console.log(isLoaded, origin);

    // useEffect(() => {
    //   if(parseInt(doorDeliveryCharge) <= 0){
    //     props.onChange('doorDeliveryPaymentMode', {value: 'topay', label: 'topay'})
    //   }
    // }, [doorDeliveryCharge])
    const paymentDiv = () => {
      return (
        <div>
          {props.isSettled && (
            <p style={{ textAlign: "center", fontSize: 17 }}>
              Door Delivery Payment Is Already Settled
            </p>
          )}
          <Grid container spacing={0} style={{ padding: "4px" }}>
            <Grid
              md={6}
              xs={9}
              item
              style={{
                paddingTop: "10px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Particulars
            </Grid>
            <Grid
              md={3}
              xs={3}
              item
              style={{
                paddingTop: "10px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Amount
            </Grid>
          </Grid>

          <Grid container spacing={0} style={{ padding: "4px" }}>
            <Grid md={3} xs={1} item></Grid>
          </Grid>
          <Grid container spacing={0} style={{ padding: "4px" }}>
            <Grid md={6} xs={9} item style={{ paddingTop: "10px" }}>
              Door Delivery Charge{" "}
            </Grid>
            <Grid md={3} xs={2} item>
              <TextField
                variant="standard"
                inputProps={{
                  style: { height: "3px" },
                }}
                style={{ zIndex: 0 }}
                disabled={props.doorDeliveryPaymentMode === "paid"}
                value={doorDeliveryCharge}
                margin="normal"
                fullWidth={true}
                onChange={(e: any) =>
                  setDoorDeliveryCharge(parseInt(e.target.value))
                } />
            </Grid>
          </Grid>
          <Grid container spacing={0} style={{ padding: "4px" }}>
            <Grid md={6} xs={9} item style={{ paddingTop: "10px" }}>
              Extra Delivery Charge{" "}
            </Grid>
            <Grid md={3} xs={2} item>
              <TextField
                variant="standard"
                inputProps={{
                  style: { height: "3px" },
                }}
                style={{ zIndex: 0 }}
                disabled={true}
                value={extraDoorDeliveryCharge}
                margin="normal"
                fullWidth={true} />
            </Grid>
            <Grid md={3} xs={1} item>
              <i
                className="fas fa-pencil-alt"
                onClick={() => setExtraDoorDeliveryDialog(true)}
              ></i>
            </Grid>
          </Grid>
          <Grid container spacing={0} style={{ padding: "4px" }}>
            <Grid
              md={6}
              xs={9}
              item
              style={{
                paddingTop: "10px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Total
            </Grid>
            <Grid md={3} xs={2} item>
              <TextField
                variant="standard"
                inputProps={{
                  style: { height: "3px" },
                }}
                style={{ zIndex: 0 }}
                fullWidth={true}
                id="total"
                placeholder="Total"
                InputProps={{
                  readOnly: true,
                }}
                value={doorDeliveryCharge + extraDoorDeliveryCharge}
                margin="normal" />
            </Grid>
            <Grid md={3} xs={1} item></Grid>
          </Grid>
        </div>
      );
    };

    console.log(paymentDiv());
    // const handleMakePayment = async (paymentMode:any) => {
    //   try {
    //     props.showLoader();

    //     let payload: any = {
    //       docket: props.docket,
    //       doorDeliveryAddr: {},
    //       discount:doorDeliveryDiscount,
    //       settledCollectedAmount:paymentMode==="paid" ? extraDoorDeliveryCharge : Number(doorDeliveryCharge) + Number(extraDoorDeliveryCharge) - Number(doorDeliveryDiscount)  ,
    //       settledAmount: Number(doorDeliveryCharge) + Number(extraDoorDeliveryCharge) - Number(doorDeliveryDiscount) 
    //     };

    //     if (props.user.opBranch && props.user.opBranch._id) {
    //       payload.sub = "B";
    //       payload.entity = props.user.opBranch._id;
    //     } else {
    //       payload.sub = "F";
    //       payload.entity = props.user.opFleet._id;
    //     }

    //     payload.doorDeliveryAddr.placeId =
    //       props.origin && props.origin.place_id;
    //     payload.doorDeliveryAddr.coordinate = {
    //       latitude: props?.coordinate?.latitude,
    //       longitude: props?.coordinate?.longitude,
    //     };
    //     if(paymentMode==="credit") payload.paymentMode= paymentMode;
    //     payload.doorDeliveryAddr.placeValue = props.placeValue;
    //     payload.doorDeliveryAddr.city = city?.value;
    //     payload.doorDeliveryAddr.name = props.name;
    //     if(props.pincode)
    //       payload.doorDeliveryAddr.pincode = props.pincode;
    //     payload.doorDeliveryAddr.formatted_address = props.formatted_address;
    //     await doorDeliveryMakePayment(payload);
    //     props.setOpen(false);
    //     props.onMakePaymentSuccess();
    //     showMessage("Make Payment Success", success);
    //     props.hideLoader();
    //   } catch (err:any) {
    //     showMessage(err.message, failed);
    //     props.hideLoader();
    //   }
    // };


    const getSuggestionsForCustomers = async () => {
      console.log(`kjsdfsdncwdc`)
      let data: any[] = [];
      let payload = {
        customerContact: props?.receiver?.contact,
      };
      try {
        const response = await listAddressSuggetion(payload);
        response?.forEach((address: any) => {
          data.push({
            value: address.placeId,
            label: address?.place?.formatted_address,
            locationType: address?.locationType || null,
            l1: address?.l1,
            l2: address?.l2,
            isDataFromSuggetion: true,
            place: address?.place,
            city: address?.city,
            customerName: address?.customerName,
            customerContact: address?.customerContact,
            _id: address?._id,
          });
        });

        // Add "Use current location" option to the beginning of the list
        data.unshift({
          value: "current_location",
          label: "",
          _id: undefined,
          isDataFromSuggetion: false,
        });
        setSavedAddresses(data);
      } catch (error) {
        console.error("Error fetching address suggestions", error);
        showHttpError(error);
      }
    };

    const getSuggestions = async () => {
      let data: any[] = [];
      let payload = {
        customerContact: props?.receiver?.contact,
      };
      try {
        const response = await listAddressSuggetionByUser(payload);
        response?.forEach((address: any) => {
          data.push({
            value: address.placeId,
            label: address?.place?.formatted_address,
            locationType: address?.locationType || null,
            l1: address?.l1,
            l2: address?.l2,
            isDataFromSuggetion: true,
            place: address?.place,
            city: address?.city,
            customerName: address?.customerName,
            customerContact: address?.customerContact,
            _id: address?._id,
          });
        });

        // Add "Use current location" option to the beginning of the list
        data.unshift({
          value: "current_location",
          label: "",
          _id: undefined,
          isDataFromSuggetion: false,
        });
        setSavedAddresses(data);
      } catch (error) {
        console.error("Error fetching address suggestions", error);
        showHttpError(error);
      }
    };

    console.log('new address data', savedAddresses, props.receiver, state, props?.searchLocation)

    const handleCancel = () => {
      props.onChange('searchLocation', undefined);
    };

    const handleCreateEditLocationSuggetion = async (
      data: CreateEditLocationType,
      id?: string
    ) => {
      props.showLoader()
      try {
        let response;
        let locationData: any = {};
        if (!props?.searchLocation?.isEdit) {
          if (props.isCustomerTracking) {
            response = await addAddressSuggetion(data);
          }
          else {
            response = await addAddressSuggetionByUser(data);
          }
        } else {
          if (props.isCustomerTracking) {
            response = await editAddressSuggetion(id ? id : "", data);
          }
          else {
            response = await editAddressSuggetionByUser(id ? id : "", data);
            locationData.isEdit = false
          }
        }
        if (props.isCustomerTracking) {
          getSuggestionsForCustomers()
        }
        else {
          getSuggestions()
        }
        locationData = { ...locationData, isDataFromSuggetion: true }
        props.onChange('searchLocation', { ...locationData, l1: '', l2: '' })
        setGoogleAutoComplete(null)
        setActiveLocationType(response?.[0]?.locationType);
      } catch (error) {
        showHttpError(error);
      } finally {
        props.hideLoader()
      }
    };

    const handleLocationSave = async () => {
      const coreData: any = {
        place: {
          searchLocation: props?.searchLocation
        }
      }
      let data;
      if (!coreData?.place?.searchLocation?.isEdit) {
        data = processLocationPayload([coreData], 0);
      }
      let locationObj = coreData;
      let processedLocation;
      if (data || coreData?.place?.searchLocation?.isEdit) {
        if (coreData?.place?.searchLocation?.isEdit) {
          processedLocation = {
            placeId: locationObj?.place?.searchLocation?.place
              .placeId as string,
            coordinate: {
              latitude: locationObj.place.searchLocation?.place?.coordinate
                ?.latitude as number,
              longitude: locationObj.place.searchLocation?.place?.coordinate
                ?.longitude as number,
            },
            placeValue: locationObj.place.searchLocation?.place?.placeValue,
            city: locationObj.place.searchLocation?.place?.city?.name || "",
            area: locationObj.place.searchLocation?.place?.area?.name || "",
            name: locationObj.place.searchLocation?.place?.name,
            pincode: locationObj.place.searchLocation?.place?.pincode || "",
            formatted_address:
              locationObj.place.searchLocation?.place?.formatted_address,
            state: locationObj.place.searchLocation?.place?.state?.name || "",
          };
        }

        let payload = {
          l1: locationObj?.place?.searchLocation?.l1,
          l2: locationObj?.place?.searchLocation?.l2,
          locationType: activeLocationType as LocationType,
          place: !coreData?.place?.searchLocation?.isEdit ? data?.place : processedLocation,
          remarks: locationObj?.place?.searchLocation?.locationRemark,
          customerName: props.receiver?.name,
          customerContact: Number(props.receiver?.contact),
          _id: locationObj?.place?.searchLocation?._id,
        };
        await handleCreateEditLocationSuggetion(
          payload as CreateEditLocationType,
          locationObj?.place?.searchLocation?._id
        );
      }
    };

    const handleCancelDoorDelivery = async () => {
      console.log("come", props.doorDeliveryDocketId)
      const payload = {
        "doorDeliveryDocketId": props.doorDeliveryDocketId,
        "entity": props.user.opBranch._id,
        "sub": "B",
        "cancelReason": cancelOrderRemarks,

      }
      console.log(`props 20251`, props)
      const payloadFromCustomer = {
        doorDeliveryDocketId: props.doorDeliveryDocketId,
        cancelReason: cancelOrderRemarks
      }

      try {
        if (props?.user?.uid === null || props?.user?.uid === undefined) {
          await reqForcancelDoorDeliveryFromCustomer(payloadFromCustomer);
          showMessage("Successfully Cancelled");
        }
        else {
          await reqForcancelDoorDelivery(payload);
          showMessage("Successfully Cancelled");
        }
      }
      catch (err) {
        showHttpError(err);
        setCancelOrderDialog(false)
      }
    }

    const handleDoorDeliveryDiscount = async () => {
      const payload = {
        "entity": props.user.opBranch._id,
        "sub": "B",
        "amount": discountAmount,
        "remarks": discountRemarks,

      }

      try {
        console.log("checked", props.doorDeliveryDocketId)
        await reqForDoorDeliveryDiscount(props.doorDeliveryDocketId, payload);
        showMessage("Successfully Discount Approved");
      }
      catch (err) {
        showHttpError(err);
        setCancelOrderDialog(false)
      }
    }

    const handleUpdate = async () => {
      try {
        props.showLoader();

        let payload: any = {
          docketNumber: props.docket,
          doorDeliveryAddr: {},
          doorDelCharges: props.deliveryCharge,
          place: {},
          serviceType: service.serviceType,
          doorDelAddressId: props.searchLocation?._id
        };

        if (service.serviceType === "EXPRESS") {
          payload.assetType = fleetTypeWiseRate?.assetType || props.assetType;
        }
        if (props.user.opBranch && props.user.opBranch._id) {
          payload.sub = "B";
          payload.entity = props.user.opBranch._id;
        } else {
          payload.sub = "B";
          payload.entity = props.user.opBranch._id;
        }

        console.log("pirops", props, payload);
        // payload.doorDeliveryAddr.city = props.city.value;
        payload.doorDeliveryAddr.pincode = props.searchLocation?.place?.pincode;
        payload.doorDeliveryAddr.placeId = props.searchLocation?.place?.placeId;
        payload.doorDeliveryAddr.l1 = props.searchLocation?.l1;
        payload.doorDeliveryAddr.l2 = props.searchLocation?.l2;
        payload.doorDeliveryAddr.locationType = props.searchLocation?.locationType;

        payload.place.placeId = props.searchLocation?.place?.placeId;
        payload.place.coordinate = {
          latitude: props.searchLocation?.place?.coordinate?.latitude,
          longitude: props.searchLocation?.place?.coordinate?.longitude,
        };
        payload.place.placeValue = props.searchLocation?.place?.formatted_address;
        payload.place.city = props.searchLocation?.place?.city?.name;
        payload.place.state = props.searchLocation?.place?.state?.name;
        payload.place.name = props.searchLocation?.place?.name;
        payload.place.area = props.searchLocation?.place?.area?.name;
        payload.place.pincode = props.searchLocation?.place?.pincode;

        payload.place.formatted_address = props.searchLocation?.place?.formatted_address;
        if (props.isCustomerTracking) {
          await setCustomerDoorDelivery(payload);
        } else {
          await setDoorDelivery(payload);
        }
        if (props.whereFrom === "callRecords") props.handleAfterBooking();
        showMessage("Success", success);
        handleDialogClose();
        props.hideLoader();
      } catch (err: any) {
        showHttpError(err);
        props.hideLoader();
      }
    };

    const theme = useTheme();
    const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
    const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
    const mobileView = isMobile1 || isMobile2

    console.log(
      "extraDoorDeliveryCharge",
      extraDoorDeliveryCharge,
      props.open, props.searchLocation?.place?.placeId, `sdcjksdncv`, googleAutoComplete
    );

    const secondsToHms: any = (d: any) => {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);

      var hDisplay = h > 0 ? h + (h == 1 ? " Hour, " : " Hours, ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " Minute, " : " Minutes") : "";
      return hDisplay + mDisplay;
    };

    const getIntraCityPrice = async () => {

      console.log(`hzsdcnsdf`, props.searchLocation)
      let size: any = [];
      setExpressServicesOptions("");
      props.packages.map((x: any) => {
        size.push(typeof x?.size === "string" ? x?.size : x?.size?.value)
      });

      try {
        let payload = {
          places: [
            {
              fromPlace: props.fromPlaceId,
              toPlace: props.isCustomerTracking ? (props.searchLocation?.isDataFromSuggetion ? props.searchLocation?.place?.placeId : props.searchLocation?.place_id) : props.searchLocation?.place?.placeId,
            },
          ],
          city: props.delCity?.label,
          size: size,
          kg: Number(props.packages.reduce((acc: any, curr: any) => acc + curr.weight * curr.qty, 0)),
        };

        let data = await getAvailableServicesIntraCityPrice(payload);

        let { intraCityPrice, totalDistanceMeter, totalDuration }: any = data;

        setTotalDistance(`${(totalDistanceMeter / 1000).toFixed(1)} KM`);
        // setTotalKms(parseInt(totalDistanceMeter/1000));
        setTotalDuration(secondsToHms(totalDuration));
        if (intraCityPrice.length === 0) {
          showMessage("No IntraCity Rate Found", failed);
        }
        setTotalKg(Number(props.packages.reduce((acc: any, curr: any) => acc + curr.weight * curr.qty, 0)))
        setTotalPkg(Number(props.packages.reduce((acc: any, curr: any) => acc + curr.qty, 0)))

        let isRegularPriceNotExist = false;
        console.log(isRegularPriceNotExist)
        let regularCost = 0;
        props.packages.map((x: any) => {
          let size = typeof x?.size === "string" ? x?.size : x?.size?.value;
          let cost = intraCityPrice.find(
            (y: any) => y.type === "REGULAR" && y.size === size
          )?.price;
          if (!cost) {
            isRegularPriceNotExist = true;
          }
          console.log(
            "cost, x.qty, cost & x.qty",
            cost,
            x.qty,
            cost & x.qty,
            props.packages
          );
          regularCost += cost * x.qty;
        });


        setServicesOptions([{
          value: regularCost,
          label: `₹${regularCost}`,
          serviceType: "REGULAR",
        }]);

        let expressCost: any = [], lowerRate: any;
        intraCityPrice
          .filter((x: any) => x.type === "EXPRESS")
          .sort(
            (a: any, b: any) => a.assetSubType?.assetType.capacity - b.assetSubType?.assetType.capacity
          )
          .map((x: any) => {
            // if(x.assetType.capacity >= totalKg && expressCost.length<2) 
            expressCost.push(x)
            // else lowerRate=x;
          });
        if (lowerRate) expressCost.push(lowerRate);

        expressCost.map((e: any) => {
          setExpressServicesOptions((expressServicesOptions: any) => [...expressServicesOptions, {
            label: `${e?.assetSubType?.assetType?.assetName} - ₹${Math.round(Number(e?.price * totalDistanceMeter / 1000))} `,
            value: Math.round(Number(e?.price * totalDistanceMeter / 1000)),
            serviceType: "EXPRESS",
            assetType: e?.assetSubType?.assetType?._id,
            availableFreeMinutes: e?.availableFreeMinutes,
            perMinuteCharge: e?.perMinuteCharge,
          }]);
        }
        );


        // if (servicesOptions.length > 0) {
        //   let cheapest = servicesOptions.sort(
        //     (a: any, b: any) => a.value - b.value
        //   )[0];
        //   props.onChange("deliveryCharge", cheapest.value);
        //   if (parseInt(doorDeliveryCharge) <= 0) {
        //     props.onChange("doorDeliveryPaymentMode", {
        //       value: "topay",
        //       label: "topay",
        //     });
        //   }
        //   props.onChange("serviceType", cheapest.serviceType);
        //   if (cheapest.serviceType === "EXPRESS") {
        //     props.onChange("assetType", cheapest.assetType);
        //   }
        //   setService(cheapest);
        //   setServicesOptions(servicesOptions);
        // } else {
        //   props.onChange("deliveryCharge", 0);

        //   props.onChange("doorDeliveryPaymentMode", {
        //     value: "topay",
        //     label: "topay",
        //   });

        //   setService("");
        //   setServicesOptions(servicesOptions);
        // }

      } catch (err: any) {
        showHttpError(err);
      }
    };

    useEffect(() => {
      // if(props.coordinate && props.open && props.formatted_address)getIntraCityPrice();
    }, [props.doorDelDocketNumber, props.coordinate, props.open])

    const handleChargesDisable = (typeOfCharge: any) => {

      if (typeOfCharge === "charge") {
        console.log("check ing", props.isDeliveryModule);
        if (props.isCustomerTracking) setShowDoorDelCharge(false);
        else if (paymentMode && typeof paymentMode === "string" && paymentMode === 'topay') setShowDoorDelCharge(true);
        else if (paymentMode && typeof paymentMode === "object" && paymentMode.value === 'topay') setShowDoorDelCharge(true);

      }
      else {
        if (props.isBookingModule || props.isCustomerTracking || !props.doorDelDocketNumber) setShowExtra(false);
        else if (paymentMode && typeof paymentMode === "string" && paymentMode === 'topay') setShowExtra(false);
        else if (paymentMode && typeof paymentMode === "object" && paymentMode.value === 'topay') setShowExtra(false);
      }
    };

    useEffect(() => {

      if (props.open) {
        if (props?.searchLocation) {
          if (props.searchLocation?.place) {
            setSelectedAddress({
              formatted_address: props.searchLocation?.place?.formatted_address,
              placeId: props.searchLocation?.place?.placeId
            })
          }
        }
        console.log("check ing 9.556", props.searchLocation, `props.open`, props.open, googleAutoComplete);
      }
      console.log("check ing 9.557", doorDeliveryCharge, `jhusdfnsv`, googleAutoComplete, `sdvjsdfdfvsdvf`, props);
    }, [googleAutoComplete, props.open]);

    console.log("doooorDe", doorDeliveryCharge, `jhusdfnsv`, googleAutoComplete);

    const isDisabled: boolean = Boolean(props.searchLocation?.isDataFromSuggetion && !props.searchLocation?.isEdit);
    return <>
      <Dialog open={extraDoorDeliveryDialog} maxWidth="sm">
        <DialogContent>
          <Input12
            type="Number"
            value={newExtraDoorDeliveryCharge}
            onChange={(e: any) => {
              setNewExtraDoorDeliveryCharge(parseInt(e.target.value));
            }}
          />
        </DialogContent>
        <DialogActions>
          <ButtonDefault
            onClick={() => {
              setExtraDoorDeliveryCharge(
                newExtraDoorDeliveryCharge + extraDoorDeliveryCharge
              );
              setExtraDoorDeliveryDialog(false);
            }}
          >
            Okay
          </ButtonDefault>
        </DialogActions>
      </Dialog>
      {props.children}
      {/* {
      props.isSettled && 
      <div style={{display: 'flex'}}>
      <ButtonPrimary disabled={true}>
        Door Delivery Settled : {doorDeliveryCharge} 
       
      </ButtonPrimary>
       <i
       className="fa fa-info-circle"
       style={{
         color: "blue",
         fontSize: "20px",
         marginTop: "6px",
       }}
       onClick={() => setOpen(true)}
     ></i>
      </div>
    }
    {(!props.shouldDoorDeliveryBook && !props.isSettled) && (
      <ButtonDefault style={{ marginLeft: 25 }} onClick={() => setOpen(true)}>
        Door Delivery Charge {paymentMode?.value}{" "}
        {doorDeliveryCharge > 0 && doorDeliveryCharge}
      </ButtonDefault>
    )}
    {(props.shouldDoorDeliveryBook && !props.isSettled) && (
      <ButtonPrimary style={{ marginLeft: 25 }} onClick={() => setOpen(true)}>
        Door Delivery Charge {paymentMode && paymentMode?.value}{" "}
        {doorDeliveryCharge}
      </ButtonPrimary>
    )} */}
      <Dialog
        open={props.open}
        onClose={handleDialogClose}
        maxWidth={"md"}
        fullWidth={true}
        fullScreen={mobileView}
      // style={{ zIndex: 1000 }}
      >
        <DialogTitle style={{ width: "100%", display: "flex" }}>
          {/* <Grid
            sm={12}
            md={12}
            lg={12}
            style={{display:"flex",margin:"0px 5px",justifyContent:"space-between",alignItems:"center", gap:"10px",color:"white"}}
          > */}
          <div className="mx-auto flex gap-1">
            <h4 style={{ color: "white", margin: "0px" }}>Door Delivery</h4>
            <h5 style={{ color: "white", margin: "0px" }}> {props.doorDelDocketNumber}</h5>
          </div>
          {/* <h4 style={{display:"flex",position: "absolute",right: "1rem"}}> 
                  <button  onClick={()=>handleDialogClose()} style={{backgroundColor:"var(--clr-danger-50)",border:"none", borderRadius: "50%"}}> <CloseIcon style={{width:"28px",height: "28px", color:"var(--clr-danger-900)" }}></CloseIcon> </button>
            </h4> */}
          {/* </Grid>   */}
        </DialogTitle>

        {!props.disableAddress && (
          <DialogContent >
            {/* <Paper elevation={2} style={{padding:"0px 15px 10px 0px",margin:"5px 0px"}}> */}
            <div className="p-1 border-white shadow-small br-10 mt-1">
              <Grid container spacing={0} style={{ justifyContent: "space-around" }}>
                <Grid item xs={12} lg={10} >
                  <GoogleAutoComplete
                    general={false}
                    where={"Customer"}
                    className={`settledAddresses`}
                    value={googleAutoComplete}
                    onChange={(data: any) => {
                      try {
                        setGoogleAutoComplete({
                          formatted_address: data?.formatted_address || data?.place?.formatted_address,
                          placeId: data?.place_id || data?.place?.placeId
                        })
                        if (data.isDataFromSuggetion) {
                          props.onChange("searchLocation", data)
                          props.onChange("l1", data.l1);
                          props.onChange("l2", data.l2);
                          props.onChange("locationType", data.locationType);
                          props.onChange("isEdit", data.isEdit);


                          setActiveLocationType(data.locationType);
                        } else {
                          if (data?.type === "current_location") {
                            props.onChange("searchLocation", data)
                          } else {
                            props.onChange("searchLocation", data)
                            props.onChange("l1", data.l1);
                            props.onChange("l2", data.l2);
                            props.onChange("locationType", data.locationType);
                            props.onChange("isEdit", data.isEdit);

                            setActiveLocationType(null);
                          }
                        }
                      } catch (err: any) {
                        showHttpError(err);
                      }
                    }}
                    extraField={{
                      index: 0,
                      savedAddresses,
                      selectedAddress,
                      setSavedAddresses,
                      getSuggestions,
                      getSuggestionsForCustomers,
                    }}
                  />

                </Grid>
                <Grid item xs={12} lg={2} style={{ justifyContent: "center" }}>
                  <ButtonPrimary className={`button-primary-400`} style={{ width: "100%" }} onClick={() => { props.onReset(), setCity(""), setState("") }}>
                    <BiRefresh style={{ fontSize: "x-large" }} />{mobileView && "Refresh"}
                  </ButtonPrimary>
                </Grid>
                {props.isCustomerTracking && <Grid item md={12} lg={12} sm={12} xs={12} >
                  {/* <Paper elevation={2} style={{width:"fit-content",margin:"auto"}}> */}
                  <MovableMap driverMode={true}
                    latLongData={{
                      "latFrom": props?.coordinate?.lat || props.user.latitude,
                      "lngFrom": props?.coordinate?.lng || props.user.longitude
                    }}
                  />
                  {/* </Paper> */}
                </Grid>}

              </Grid>
            </div>
            {/* </Paper> */}

            {/* <Paper style={{padding:"0px 15px 10px 0px",margin:"5px 0px"}} elevation={2}> */}
            <div className="p-1 br-10 shadow-small border-white mt-1">
              <Grid container spacing={0} style={{ justifyContent: "space-around" }}>
                <Grid item xs={12}>
                  <input
                    disabled={props.isSettled || isDisabled}
                    placeholder="Office Name / Number"
                    className="br-4 py-8 px-1 border-white w-100"
                    // label={
                    //   <div
                    //     style={{
                    //       fontSize: `${mobileView ? "10px" : "inherit"}`,
                    //     }}
                    //   >
                    //     {" "}
                    //     Office Name / Number{" "}
                    //   </div>
                    // }
                    value={props?.searchLocation?.l1}
                    onChange={(e: any) => {
                      props.onChange("l1", e.target.value)
                      props.onChange("searchLocation", { ...props.searchLocation, l1: e.target.value })
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <input
                    disabled={props.isSettled || isDisabled}
                    // label={
                    //   <div
                    //     style={{
                    //       fontSize: `${mobileView ? "10px" : "inherit"}`,
                    //       whiteSpace: "normal",
                    //       wordBreak: "break-word",
                    //     }}
                    //   >
                    //    Landmark (optional)
                    //   </div>
                    // }
                    placeholder="Landmark (optional)"
                    className="br-4 py-8 px-1 border-white w-100"
                    value={props?.searchLocation?.l2}
                    onChange={(e: any) => {
                      props.onChange("l2", e.target.value)
                      props.onChange("searchLocation", { ...props.searchLocation, l2: e.target.value })
                    }}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <input
                    disabled={true}
                    placeholder="Pincode"
                    className="br-4 py-8 px-1 border-white w-100"
                    value={props.pincode}
                    onChange={(e: any) =>
                      props.onChange("pincode", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Select
                    isDisabled={true}
                    options={allCities}
                    value={city}
                    styles={selectStyle}
                    onChange={(e: any) => {
                      console.log(e);

                      props.onChange("city", e);
                    }}
                    placeholder="Select City"
                  />
                </Grid> */}
              </Grid>
            </div>
            {!isDisabled ? (
              <div className="flex ai-center gap-10 my-1">
                {LocationtypeEnums?.map((locationType) => {
                  const isActive = activeLocationType === locationType;
                  return (
                    <div
                      key={locationType}
                      onClick={() => {
                        !isDisabled && setActiveLocationType(locationType);
                      }}
                      className={`flex ai-center jc-center location-header ${isActive ? "active" : ""
                        }`}
                    >
                      <div
                        className="location-text"
                        onClick={() => {
                          !isDisabled &&
                            props.onChange("searchLocation", { ...props.searchLocation, locationType })
                        }}
                      >
                        {locationType}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
            {((!isDisabled && props?.searchLocation)) || props.searchLocation?.isEdit ? (
              <div className="flex ai-center jc-end gap-10 mt-2">
                <Button
                  type="button"
                  variant="secondary"
                  action="secondary"
                  onClick={handleLocationSave}
                >
                  {props?.searchLocation?.isEdit ? "Update" : "Save"}
                </Button>
                <Button
                  onClick={handleCancel}
                  type="button"
                  variant="primary"
                  action="secondary"
                >
                  Cancel
                </Button>
              </div>
            ) : null}
            {/* </Paper> */}
            {/* <Grid item xs={4}>
                <Select
                  isDisabled={true}
                  value={state}
                  styles={selectStyle}
                  placeholder="Select State"
                />
              </Grid> */}


            <div>
              {!props.disablePayment && (
                !mobileView ?
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 16,
                      // border: "1px solid var(--clr-primary-400)",
                      borderRadius: 10,
                      marginTop: "1rem"
                    }}
                    className="shadow-small border-white"
                  >
                    {/* {(props.isBookingModule || !props.doorDelDocketNumber || props.isDeliveryModule) && ( */}
                    <ButtonPrimary className={`button-primary-primary`} onClick={() => getIntraCityPrice()} >
                      Get Price
                    </ButtonPrimary>
                    {/* // )} */}

                    <div className="flex ai-center gap-1 flex-wrap px-1">
                      <div className="flex ai-center gap-4">
                        <HoursIcon style={{ width: "20px", height: "20px" }} /> :{" "}
                        {totalDuration}
                      </div>
                      <div className="flex ai-center gap-4">
                        {/* <i className="fa fa-truck" aria-hidden="true"></i> :{" "} */}
                        <VehicleIcon style={{ width: "20px", height: "20px" }} /> :{" "}
                        {totalDistance}
                      </div>
                      <div className="flex ai-center gap-4">
                        <PackageIcon style={{ width: "20px", height: "20px" }} />:{" "}
                        {totalPkg}
                      </div>
                      <div className="flex ai-center gap-4">
                        <WeightIcon style={{ width: "20px", height: "20px" }} />:{" "}
                        {totalKg} Kg
                      </div>
                    </div>
                    <div>
                      <Select12
                        value={{ label: service?.serviceType || "Select Service", value: service?.serviceType || "Select Service" }}
                        onChange={(e: any) => {
                          props.onChange("serviceType", e.value);
                          setService(e);
                          if (e.label === "EXPRESS") props.onChange("deliveryCharge", expressServicesOptions ? expressServicesOptions[0].value : "");
                          else props.onChange("deliveryCharge", servicesOptions ? servicesOptions[0].value : "");
                        }}
                        options={props?.packages?.[0]?.size === "custom" ? [{ label: "EXPRESS", value: "EXPRESS" }] : [{ label: "REGULAR", value: "REGULAR" }, { label: "EXPRESS", value: "EXPRESS" }]}
                        // placeholder="Select Service"
                        isDisabled={
                          (paymentMode?.value === "paid" && !props.isBookingModule) || props.doorDelDocketNumber
                        }
                      />
                    </div>
                    {service.serviceType === 'EXPRESS' && <div>
                      <Select12
                        value={fleetTypeWiseRate}
                        onChange={(e: any) => {
                          if (e.serviceType === "EXPRESS") {
                            props.onChange("assetType", e.assetType);
                          }
                          props.onChange("deliveryCharge", e.value);

                          setFleetTypeWiseRate(e);
                        }}
                        options={expressServicesOptions}
                        placeholder="Select Cost"
                        isDisabled={
                          (paymentMode?.value === "paid" && !props.isBookingModule) || props.doorDelDocketNumber
                        }
                      />
                    </div>}
                  </div> :
                  // <Paper elevation={2} style={{padding:"5px",margin:"5px 0px"}}>
                  <div className="mt-1 p-1 border-white shadow-small br-10">
                    <Grid container >
                      {/* <Grid item xs={6} sm={6}>
                        <i className="fa fa-clock" aria-hidden="true"></i> :{" "} {totalDuration}
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <i className="fa fa-truck" aria-hidden="true"></i> :{" "} {totalDistance}
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <FiPackage/>:{" "} {totalPkg} 
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <GiWeight/>:{" "}{totalKg} Kg
                      </Grid> */}
                      <Grid item xs={12} sm={6} style={{ margin: "auto" }}>
                        {/* {(props.isBookingModule || paymentMode?.value !== "paid" || props.isDeliveryModule) && ( */}
                        <ButtonPrimary onClick={() => getIntraCityPrice()}>
                          Get Price
                        </ButtonPrimary>
                        {/* )} */}
                      </Grid>
                      {<Grid item xs={12} sm={5} style={{ padding: "0px" }}>
                        <Select12
                          value={service}
                          onChange={(e: any) => {
                            props.onChange("serviceType", e.value);
                            setService(e);
                            if (e.label === "EXPRESS") props.onChange("deliveryCharge", expressServicesOptions ? expressServicesOptions[0].value : "");
                            else props.onChange("deliveryCharge", servicesOptions ? servicesOptions[0].value : "");
                          }}
                          options={props?.packages?.[0]?.size === "custom" ? [{ label: "EXPRESS", value: "EXPRESS" }] : [{ label: "REGULAR", value: "REGULAR" }, { label: "EXPRESS", value: "EXPRESS" }]}
                          placeholder="Select Service"
                          isDisabled={
                            paymentMode?.value === "paid" && !props.isBookingModule
                          }
                        />
                      </Grid>}


                      {service.serviceType === 'EXPRESS' && <Grid item xs={6} sm={6} style={{ margin: "auto", padding: "0px" }}> <div>
                        <Select12
                          value={fleetTypeWiseRate}
                          onChange={(e: any) => {
                            if (e.serviceType === "EXPRESS") {
                              props.onChange("assetType", e.assetType);
                            }
                            props.onChange("deliveryCharge", e.value);

                            setFleetTypeWiseRate(e);
                          }}
                          options={expressServicesOptions}
                          placeholder="Select Cost"
                          isDisabled={
                            paymentMode?.value === "paid" && !props.isBookingModule
                          }
                        />
                      </div></Grid>
                      }
                    </Grid>
                  </div>
                // </Paper>
              )}

              {
                (service?.perMinuteCharge || service?.availableFreeMinutes) &&
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 16,
                    border: "1px solid var(--clr-primary-400)",
                    borderRadius: 16,
                    marginTop: "1rem"
                  }}
                  className="shadow-small"
                >
                  {
                    service?.availableFreeMinutes &&
                    <div style={{ margin: 10, fontSize: 15 }}>
                      Available Free Minutes<HoursIcon /> :{" "}
                      {service?.availableFreeMinutes}
                    </div>
                  }
                  {
                    service?.perMinuteCharge &&
                    <div style={{ margin: 10, fontSize: 15 }}>
                      Per Minute Charge<VehicleIcon /> :{" "}
                      {service?.perMinuteCharge}
                    </div>
                  }
                </div>
              }

              {/* <Paper elevation={2} style={{margin:"10px 0px"}}> */}
              <div className="p-1 br-10 shadow-small border-white mt-1">
                <div>
                  {!props.disablePayment && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: `flex-between`,
                        flexDirection: `${mobileView ? "column" : "row"}`,
                        alignItems: `${mobileView ? "stretch" : "center"}`,
                        width: "100%",
                        flexWrap: `wrap`,
                        gap: "1rem"
                      }}
                    >
                      <div>
                        {/* {console.log("checking",props.isDeliveryModule)} */}
                        <Select
                          isDisabled={props.doorDelDocketNumber}
                          placeholder="Payment Mode"
                          value={paymentMode}
                          // options={(props.isCustomerTracking) ? props.paymentOptions : paymentModes}
                          options={props.paymentOptions}
                          // isDisabled={props.isDeliveryModule}
                          onChange={(e: any) => {
                            props.onChange("doorDeliveryPaymentMode", e),
                              setPaymentMode(e)
                          }
                          }
                        />
                      </div>
                      <p>Charges:</p>

                      <Input placeholder='Charge' list='suggestion'
                        // style={{height:"10px",width:"100px",marginTop:"-17px"}}
                        onChange={(e: any) => {
                          console.log("deliveryCharge", e.target.value);
                          if (parseInt(e.target.value) > 0) {
                            props.onChange("deliveryCharge", e.target.value);
                          } else {
                            props.onChange("deliveryCharge", 0);
                          }
                          if (parseInt(doorDeliveryCharge) <= 0) {
                            props.onChange("doorDeliveryPaymentMode", {
                              value: "topay",
                              label: "topay",
                            });
                          }
                          props.onChange("deliveryCharge", e.target.value);
                          setDoorDeliveryCharge(e.target.value);
                        }}
                        // disabled={props.isDeliveryModule || props.isCustomerTracking}
                        disabled={!showDoorDelCharge}
                        value={doorDeliveryCharge}
                      ></Input>
                      <datalist id='suggestion'>
                        {customerPrice.map((e: any, index: any) => {
                          return (<option key={index} value={e.value}>{e.value}</option>)
                        })}
                      </datalist>


                      {showExtra &&
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p style={{ margin: 15 }}>+</p>

                          <p style={{ margin: 15 }}>Extra:</p>

                          <TextField
                            variant="standard"
                            inputProps={{
                              style: { height: "3px" },
                            }}
                            style={{ zIndex: 0 }}
                            disabled={true}
                            value={extraDoorDeliveryCharge}
                            margin="normal"
                            fullWidth={true} />

                          {!props.isSettled && (
                            <i
                              className="fas fa-pencil-alt"
                              onClick={() => setExtraDoorDeliveryDialog(true)}
                            ></i>
                          )}
                        </div>
                      }
                      {(!props?.isBookingModule && !props?.isCustomerTracking && props.doorDelDocketNumber) && paymentMode?.value !== `paid` &&
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p style={{ margin: 15 }}>-</p>

                          <p style={{ margin: 15 }}>Discount:</p>

                          <TextField
                            variant="standard"
                            inputProps={{
                              style: { height: "3px" },
                            }}
                            style={{ zIndex: 0 }}
                            disabled={true}
                            value={doorDeliveryDiscount}
                            margin="normal"
                            fullWidth={true} />

                          {!props.isSettled && (
                            <i
                              className="fas fa-pencil-alt"
                              onClick={() => setDiscountDialog(true)}
                            ></i>
                          )}
                        </div>
                      }
                      {props.isSettled && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <p style={{ margin: 12 }}>=</p>
                          <p style={{ margin: 12 }}>Total</p>
                          <TextField
                            variant="standard"
                            inputProps={{
                              style: { height: "3px" },
                            }}
                            style={{ zIndex: 0 }}
                            disabled={true}
                            value={(doorDeliveryCharge) + extraDoorDeliveryCharge}
                            margin="normal"
                            fullWidth={true} />
                        </div>
                      )}

                      {mobileView && <div style={{ display: "flex", margin: "auto", justifyContent: "center" }}>
                        {/* <i
                    className="fa fa-info-circle"
                    style={{ color: "blue", fontSize: "15px"}}
                    onClick={() => setOpenPkgDetail(true) }
                  ></i> */}
                        <InfoIcon className="text-primary-400" style={{ width: "28px", height: "28px" }} onClick={() => setOpenPkgDetail(true)} />
                      </div>}
                    </div>
                  )}
                </div>
              </div>
              {/* </Paper> */}

            </div>
          </DialogContent>
        )}
        <DialogActions
        // style={{ display: `${mobileView ? "flex" : "block"}`, justifyContent:"center" }}
        >

          {/* <div style={{display:"flex",justifyContent:"space-around",marginBottom:"5px",gap:"5px", width:"100%"}}>  */}
          <div className="flex ai-center jc-end gap-8">

            {(props.whereFrom === "readyForDispatch") && props.doorDelDocketNumber && (
              <ButtonDefault className={`button-danger-primary fs-popup-btn`} onClick={() => setCancelOrderDialog(true)}>
                Cancel Door Delivery
              </ButtonDefault>
            )}
            {props.update && (
              <ButtonPrimary className={`button-primary-secondary fs-popup-btn`} onClick={() => handleUpdate()}>
                Update
              </ButtonPrimary>
            )}
            <ButtonDefault className={`button-danger-secondary fs-popup-btn`} onClick={() => handleDialogClose()}>
              Close
            </ButtonDefault>

            {/* {props.isDeliveryModule && !props.isSettled && (
            <ButtonPrimary
              style={{ width: 200}}
              onClick={() => handleMakePayment(paymentMode?.value)}
            >
              {paymentMode && paymentMode?.value === "paid"
                ? "Settle"
                : "Make Payment"}{" "}
              {Number(doorDeliveryCharge) + Number(extraDoorDeliveryCharge) - Number(doorDeliveryDiscount)}
            </ButtonPrimary>
           )} */}
            {props.isBookingModule && paymentMode && paymentMode?.value && (
              <ButtonPrimary
                disabled={
                  props.shouldDoorDeliveryBook && paymentMode?.value === "paid"
                }
                style={{ width: 200 }}
                onClick={() => {
                  handleDialogClose();
                  props.onChange("shouldDoorDeliveryBook", true);
                }}
              >
                {paymentMode?.value === "paid" ? "Payment Received" : "Book"} ₹:{" "}
                {doorDeliveryCharge}
              </ButtonPrimary>
            )}
            {/* {props.shouldDoorDeliveryBook && props.isBookingModule && (
            <ButtonPrimary
              style={{ width: 200 ,margin:"0px 20px"}}
              onClick={() => {
                props.onChange("doorDeliveryPaymentMode", "");
                props.onChange("shouldDoorDeliveryBook", false);
                props.onChange("deliveryCharge", 0);
              }}
            >
              Cancel Booking
            </ButtonPrimary>
          )} */}
            {/* {
                <ButtonDefault onClick={()=> {handleDialogClose(); if(!props.isBookingModule) props.onReset(); }}>Close</ButtonDefault>} */}
          </div>

        </DialogActions>
      </Dialog>

      <Dialog
        open={cancelOrderDialog}
        onClose={() => setCancelOrderDialog(false)}
        maxWidth="md"
      >
        <DialogTitle> Remarks </DialogTitle>
        <DialogContent>

          <Input12
            onChange={(e: any) => setCancelOrderRemarks(e.target.value)}
            value={cancelOrderRemarks}
            placeholder="Add Reasons"
          />
        </DialogContent>
        <DialogActions>
          <ButtonPrimary onClick={() => { handleCancelDoorDelivery(); setCancelOrderDialog(false) }}>Submit</ButtonPrimary>
          <ButtonDefault onClick={() => { setCancelOrderDialog(false), setCancelOrderRemarks("") }}>Close</ButtonDefault>
        </DialogActions>
      </Dialog>

      <Dialog
        open={discountDialog}
        onClose={() => setDiscountDialog(false)}
        maxWidth="md"
      >
        <DialogTitle> Remarks </DialogTitle>
        <DialogContent>

          <Input12
            onChange={(e: any) => setDiscountAmount(e.target.value)}
            value={discountAmount}
            placeholder="Discount Amount"
          />
          <Input12
            onChange={(e: any) => setDiscountRemarks(e.target.value)}
            value={discountRemarks}
            placeholder="Add Reasons"
          />
        </DialogContent>
        <DialogActions>
          <ButtonPrimary onClick={() => { handleDoorDeliveryDiscount(), setDiscountRemarks(""), setDiscountAmount("") }}>Submit</ButtonPrimary>
          <ButtonDefault onClick={() => { setDiscountDialog(false), setDiscountRemarks(""), setDiscountAmount(""), props.onReset() }}>Close</ButtonDefault>
        </DialogActions>
      </Dialog>

      <Dialog open={openPkgDetail} onClose={() => setOpenPkgDetail(false)}>
        <DialogContent>
          <Grid container>
            <Grid item xs={6} sm={6}>
              <i className="fa fa-clock" aria-hidden="true"></i> :{" "} {totalDuration}
            </Grid>
            <Grid item xs={6} sm={6}>
              <i className="fa fa-truck" aria-hidden="true"></i> :{" "} {totalDistance}
            </Grid>
            <Grid item xs={6} sm={6}>
              <FiPackage />:{" "} {totalPkg}
            </Grid>
            <Grid item xs={6} sm={6}>
              <GiWeight />:{" "}{totalKg} Kg
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDefault onClick={() => setOpenPkgDetail(false)}>
            Close
          </ButtonDefault>
        </DialogActions>
      </Dialog>
    </>;
  }
);

const mapStateToProps = (state: RootState) => ({
  cityValue: state.branch.branchForm,
  citylist: state.user.allcities,
  user: state.user,
});

const mapDispatchToProps = {
  showLoader,
  hideLoader,
  getCity,
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(DoorDelivery);
