import React from "react";
import { Tooltip } from "@mui/material";

interface IpropesToolTip {
  title?: any;
  placement?: any;
  children?: any;
  id?: any;
  formatter?: any;
  labelFormatter?: any;
}

const ToolTip = (props: IpropesToolTip) => {
  return (
    <Tooltip title={props.title} placement={props.placement} id={props.id}>
      {props.children}
    </Tooltip>
  );
};

export default ToolTip;
