import { Dispatch } from "redux";
import { SET_CUSTOMER_DOCKET_LOCATION, SET_DRIVER_LOCATION } from "../constants/liveLocation";
import { RootState, socket } from "../store";
import showMessage, { failed } from "../utils/message";

export const setDriverLocationData = (data: any) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
      try {

        let { liveLocation, user } = getState()

        let socketData:any = {
          isStarted: true,
          driver: user.uid,
          ...liveLocation.driverLocation,
          ...data
        }
    
          // console.log('setLiveDocketTracking', )
           dispatch({ type: SET_DRIVER_LOCATION, payload: socketData });
          socket.emit('setLiveDocketTracking',socketData)

        
      } catch (error:any) {
        showMessage(error.message, failed);
      }
    };
  };

  let interval:any = null
  export const getCustomerDocketLocation = (data: any) => {
    return async () => {
      if (data) {
        interval = setInterval(() => {
          // console.log('getLiveDocketTracking', data)
          socket.emit('getLiveDocketTracking', data)
        }, 1000);
      }
    }
  };

  export const stopGetCustomerDocketLocation = () => {
    // console.log('cleared')
    clearInterval(interval)
  };

  export const setCustomerDocketLocation = (data: any) => {
    return async (dispatch: Dispatch) => {
      dispatch({ type: SET_CUSTOMER_DOCKET_LOCATION, payload: data });
    }
  }