import {
  CHANGE_SIMPLE,
  SET_FLEETS,
  SET_FLEET,
  SET_FRANCHISE_FLEETS,
  RESET,
  FLEET_CREATE_FAIL,
  FLEET_CREATE_SUCCESS,
  FLEET_EDIT_FAIL,
  FLEET_EDIT_SUCCESS,
  FLEET_DOOR_SUCCESS,
  FLEET_DOOR_FAIL,
} from "../../constants/fleet";

import {
  createDooreFleetService,
  createFleetService,
  fetchAllFleetsService,
  fetchAllFranchiseFleetsService,
  updateFleetService,
} from "../../services/fleet";
import { Dispatch } from "redux";
import { RootState } from "../../store";

export const changeSimple = (what: any, val: any) => async (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  try {
    await dispatch({ type: CHANGE_SIMPLE, payload: { what, val } });
    let fleetsMap = getState().fleet.get("fleetsMap");
    if (what[0] == "regNumber" && val in fleetsMap) {
      await dispatch({
        type: SET_FLEET,
        payload: fleetsMap[val],
      });
    }
  } catch (err : any) {
    console.log(err);
  }
};

export const setFleetError = (what: any) => ({
  type: "SET_FLEET_ERROR",
  payload: what,
});

export const changeSelectedCities = (data: any) => ({
  type: "CHANGE_OFD_CITIES",
  payload: data,
});

export const createFleet = (data: any) => async (dispatch: any) => {
  try {
    try {
      await createFleetService(data);
      await dispatch({ type: FLEET_CREATE_SUCCESS });
      await dispatch(resetFleet());
    } catch (err : any) {
      console.log(err);
      await dispatch({
        type: FLEET_CREATE_FAIL,
        payload: err,
      });
    }
  } catch (err : any) {
    console.log(err);
    dispatch({
      type: FLEET_CREATE_FAIL,
      payload: {
        reasons: [{ message: "Something went wrong" }],
      },
    });
  }
};

export const editFleet = (id: string, data: any) => async (dispatch: any) => {
  try {
    try {
      await updateFleetService(id, data);
      dispatch({ type: FLEET_EDIT_SUCCESS });
      await dispatch(resetFleet());
    } catch (err : any) {
      dispatch({
        type: FLEET_EDIT_FAIL,
        payload: err,
      });
    }
  } catch (err : any) {
    console.log(err);
    dispatch({
      type: FLEET_CREATE_FAIL,
      payload: {
        reasons: [{ message: "Something went wrong" }],
      },
    });
  }
};

export const createDoorFleetOrder = (data: any) => async (dispatch: any) => {
    try {
      console.log("action data", data);
      
      await createDooreFleetService(data);
      dispatch({ type: FLEET_DOOR_SUCCESS });
    } catch (err : any) {
      console.log("action error", data);
      dispatch({
        type: FLEET_DOOR_FAIL,
        payload: err,
      });
    }
};

export const fetchFleets = () => async (dispatch: Dispatch) => {
  try {
    await dispatch({ type: SET_FLEETS, payload: [] });
    let response = await fetchAllFleetsService();
    await dispatch({ type: SET_FLEETS, payload: response });
  } catch (error) {
    console.log("error: ", error);
  }
};

export const fetchFranchiseFleets = () => async (dispatch: Dispatch) => {
  try {
    await dispatch({ type: SET_FRANCHISE_FLEETS, payload: [] });
    let response = await fetchAllFranchiseFleetsService();
    await dispatch({ type: SET_FRANCHISE_FLEETS, payload: response });
  } catch (error) {
    console.log("error: ", error);
  }
};
export const clearFleets = () => {
  return async () => {
    // await dispatch({ type: RESET })
  };
};

export const resetFleet = () => async (dispatch: Dispatch) => {
  try {
    await dispatch({ type: RESET });
    try {
      // const data = { company: user.company.id }
      let fleets = await fetchAllFleetsService();
      await dispatch({ type: SET_FLEETS, payload: fleets });
    } catch (err : any) {
      console.log(err);
    }
  } catch (err : any) {
    console.log(err);
  }
};
