import React, { ComponentProps, ReactNode } from "react";
import {
  CSSColorShadesType,
  CSSColorVariantType,
  CSSTextTransformType,
  CSSWeightType,
} from "../../newSrc/types/common";

// types
type PageTitleProps = {
  title: string;
  className?: string;
  Icon?: ReactNode;
  textTransform?: CSSTextTransformType;
  fontWeight?: CSSWeightType;
  backgroundColor?: CSSColorVariantType;
  backgroundColorShades?: CSSColorShadesType;
  color?: CSSColorVariantType;
  colorShades?: CSSColorShadesType;
  isCenter?: boolean;
} & ComponentProps<"h1">;

// Custom FC: PageTitle
const PageTitle = ({
  title,
  className = "",
  Icon,
  textTransform = "capitalize",
  fontWeight = 600,
  backgroundColor = "primary",
  color = "white",
  backgroundColorShades = 400,
  colorShades = 900,
  isCenter = true,
  style,
  ...props
}: PageTitleProps) => {
  return (
    <h1
      className={`${className} m-0 ${
        isCenter ? "mx-auto" : ""
      } py-8 px-1 br-4 bg-${backgroundColor}-${backgroundColorShades} text-${color}-${colorShades} max-content ${
        Icon ? "flex-center gap-8" : ""
      } tt-${textTransform} fw-${fontWeight}`}
      {...props}
      style={{ fontSize: "clamp(1.125rem, 3vw, 2rem)", ...style }}
    >
      {Icon && Icon}
      {title}
    </h1>
  );
};

export default PageTitle;
