import { ServiceMaker, buildQueryString } from ".";

interface IRatePayload {
  route?: string;
  sender?: number;
}
export const suggestRate = (ratePayload: IRatePayload) =>
  ServiceMaker("rate/get", "POST", ratePayload);

export const getDoorDeliveryRatesByCustomer = (data: any) =>
  ServiceMaker(`rate/getDoorDeliveryRateByCustomer?${buildQueryString(data)}`, "GET");

export const getCustomerFixedRate = (id: string) =>
  ServiceMaker(`rate/fixed/getByCustomer/${id}`, "GET")