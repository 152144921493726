import { ServiceMaker } from './index'

export const createRoute = (data:any) => ServiceMaker('route/create', 'POST', data)

export const updateRoute = (id:string, data:any) => ServiceMaker(`route/update/${id}`, 'PUT', data)

export const deleteRoute = (id:string) => ServiceMaker('route/delete', 'DELETE', { id: id })

export const activateRoute = (id:string) => ServiceMaker(`route/activate/${id}`, 'PUT', {})

export const listRouteAll = () => ServiceMaker('route/list/all', 'GET')

export const listByCompany = (company:string) => ServiceMaker(`route/list/${company}`, 'GET')

export const listRoutes = () => ServiceMaker(`route/list`, 'GET')

// export const filterRoute = (data) => ServiceMaker('route/filter', 'POST', data)

export const bookFilterRoute = (data?:any) => ServiceMaker('route/book/filter', 'POST', data)

// export const filterRoutesBySourceAndDestination = (from,to) => ServiceMaker(`route/filter/${from}/${to}`)

export const listAllFranchiseBranches = () => ServiceMaker('route/get/franchise/branches/by/company','GET')