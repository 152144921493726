import React, { ComponentProps } from "react";
import "./switch.css";

type SwitchProps = { label?: string; id?: string } & ComponentProps<"input">;

export default function Switch({ label, id, className = "", ...props }: SwitchProps) {
  return (
    <div className={`flex ai-center gap-4 relative py-8 ${className}`}>
      {label && (
        <label
          className="taptap-checkbox-label user-select-none pointer m-0 fw-500"
          htmlFor={`taptap-checkbox-${id}`}
        >
          {label}
        </label>
      )}
      <input
        className="taptap-checkbox-input"
        type="checkbox"
        id={`taptap-checkbox-${id}`}
        name={`taptap-checkbox-${id}`}
        {...props}
      />
      <label
        htmlFor={`taptap-checkbox-${id}`}
        className="m-0 taptap-checkbox-input-container pointer"
      ></label>
    </div>
  );
}
