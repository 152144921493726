import React, { ComponentPropsWithoutRef, ReactNode } from "react";
import "./button.css";
import { LoaderIcon } from "../../../constants/icons";
import { CSSColorVariantType, UserButtonActionsType } from "../../../types/common";

// type
export type ButtonProps = {
  variant?: CSSColorVariantType;
  action?: UserButtonActionsType;
  shape?: "circle" | "oval" | "";
  className?: string;
  Icon?: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  textTransform?: "capitalize" | "lowercase" | "uppercase" | "";
  fullWidth?: boolean;
  center?: boolean;
  isHoverEffect?: boolean;
  size?: "extra-small" | "small" | "regular" | "medium" | "large";
  isLoading?: boolean;
  loadingText?: string;
} & ComponentPropsWithoutRef<"button">;

// Custom Button Component
const Button = ({
  children,
  variant = "primary",
  action = "primary",
  shape = "",
  className = "",
  Icon,
  textTransform = "",
  fullWidth = false,
  center = false,
  isHoverEffect = true,
  startIcon,
  endIcon,
  size = "regular",
  isLoading = false,
  loadingText,
  disabled,
  ...props
}: ButtonProps) => {
  const finalStartIcon = Icon || startIcon;
  return (
    <button
      className={`taptap-btn px-1 py-8 br-4 fw-500 border-none outline-none user-select-none fs-button size-${size} ${
        action !== "tertiary" ? "button" : ""
      } ${textTransform} ${
        finalStartIcon || endIcon ? "flex ai-center gap-4" : ""
      } ${shape} button-${variant}-${action} ${className} ${
        fullWidth ? "w-100" : "max-content"
      } ${center ? "mx-auto" : ""} ${
        isHoverEffect ? "hover-effect-on" : "hover-effect-off"
      }`}
      disabled={isLoading || disabled}
      {...props}
    >
      {isLoading && <LoaderIcon className="loading-spinner-animation" />}
      {finalStartIcon && !isLoading && finalStartIcon}
      {loadingText && isLoading ? loadingText : children}
      {endIcon && !isLoading && endIcon}
    </button>
  );
};

export default Button;
