import { DirectionsRenderer } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setCustomerBookingTracking } from "../../../actions/customerBooking";
import { RootState } from "../../../store";
import { showHttpError } from "../../../utils/message";
import DirectionStartPosition from './DirectionStartPosition'

interface IProps {
  origin: any;
  dest: any;
  wayPoint: any;
  driverLocation: any;
  route:any;
  setCustomerBookingTracking:any;
}
const DirectionsRendererComponent = (props: IProps) => {
  const [directionsResponse, setDirectionsResponse] = useState<any>(null);
  const [routeCallController, setRouteCallCountroller] = useState(0);
  const [position, setPosition] = useState<any>(null)

  const calculateRoute = async (origin: any) => {

    if(!origin.lat || !origin.lng){
      return
    }
    console.log('origin dataa', origin)
    try {
      const directionsService = new window.google.maps.DirectionsService();
      const results = await directionsService.route({
        origin: new window.google.maps.LatLng(
          origin.lat,
          origin.lng
        ),
        destination: new window.google.maps.LatLng(
          props.dest.coordinate?.latitude,
          props.dest.coordinate?.longitude
        ),
        travelMode: window.google.maps.TravelMode.DRIVING,
        waypoints:[
          {location: new google.maps.LatLng(
            props.origin.coordinate?.latitude,
            props.origin.coordinate?.longitude
          )},
          ... props.wayPoint.map((e: any) => ({
          location: new google.maps.LatLng(
            e?.placeId[0].coordinate?.latitude,
            e?.placeId[0]?.coordinate?.longitude
          ),
        }))],

        optimizeWaypoints: true,
        provideRouteAlternatives: true,
      });
      console.log("result", results);

      let route = props.route
      let routesWithNewEstimation = results?.routes[0]?.legs

      routesWithNewEstimation.map((x, i) => {
        route[i].distance = x.distance?.value
        route[i].duration= (x.duration?.value || 0) + 600
      })
      props.setCustomerBookingTracking(route)
      setDirectionsResponse(results);
      if(position){
        transition(results?.routes[0]?.legs[0]?.start_location)
      }else{
        setPosition(directionsResponse?.routes[0]?.legs[0]?.start_location)
      }
    } catch (error) {
      showHttpError(error);
    }
  };

  let numDeltas = 100;
  let delay = 10; //milliseconds
  let i = 0;
  let deltaLat:any;
  let deltaLng:any;

  function transition(result:any){
      i = 0;
      deltaLat = (result.lat - position.lat)/numDeltas;
      deltaLng = (result.lng - position.lng)/numDeltas;
      moveMarker();
  }

  function moveMarker(){
      position.lat += deltaLat;
      position.lng += deltaLng;
      var latlng = new google.maps.LatLng(position.lat, position.lng);
      setPosition(latlng)
      if(i!=numDeltas){
          i++;
          setTimeout(moveMarker, delay);
      }
  }

  useEffect(() => {
    let id: any = null;
    id = setInterval(() => {
      setRouteCallCountroller((routeCallController) => routeCallController + 1);
    }, 30000);

    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    if (routeCallController > 0) {
      calculateRoute({
        lat: props.driverLocation.lat,
        lng: props.driverLocation.lng,
      });
    }
  }, [routeCallController]);

  useEffect(() => {

    if(props.driverLocation.lat && props.driverLocation.lng){
      calculateRoute({
        lat: props.driverLocation.lat,
        lng: props.driverLocation.lng,
      });
    }else if(props.origin && props.dest && props.wayPoint){
      calculateRoute({
        lat: props?.origin.coordinate?.latitude,
        lng: props?.origin.coordinate?.longitude
      });
    }
  }, [props.origin, props.dest, props.wayPoint]);

  return (
    <>
      <DirectionStartPosition legPosition={directionsResponse?.routes[0]?.legs[0]?.start_location}/>
      <DirectionsRenderer
        directions={directionsResponse}
        options={{
          markerOptions: { icon: "none" },
          suppressMarkers: true,
          polylineOptions: {
            strokeOpacity: 1,
            strokeColor: "var(--clr-primary-400, #0077cc)",
            strokeWeight: 2,
          },
        }}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    driverLocation: state.liveLocation.customerDocketLocation,
    route: state.customerBooking.tracking.route
  };
};

const mapDispatchToProps = {
  setCustomerBookingTracking
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DirectionsRendererComponent);
