import { SET_CUSTOMER_TRACKING_RESET, SET_CUSTOMER_TRACKING_RESPONSE } from "../constants/trackingModule";

const initState = {}

const customerTracking = (
    state: any = initState,
    action: { [k: string]: any }
  ) => {
    switch (action.type) {
      case SET_CUSTOMER_TRACKING_RESPONSE: {
        return {
          ...state,
          ...action.payload
        }
      }
      case SET_CUSTOMER_TRACKING_RESET: {
        return {
          ...initState
        }
      }
      default: {
        return state;
      }
    }
  };

  
export default customerTracking;
