import API from "../axios";

export const getCustomerByGST = (data: { gst: string }) => {
  return new Promise<{
    trade_name: string;
    address1: string;
    address2: string;
    pincode: string;
  }>((resolve, reject) => {
    API.post<{
      response: {
        results: {
          message: {
            trade_name: string;
            address1: string;
            address2: string;
            pincode: string;
          };
        };
      };
    }>("ewaybill/india/gst/details", data)
      .then((res) => resolve(res.data.response.results.message))
      .catch((err) => reject(err));
  });
};
