import React from "react";
import { IconType } from "react-icons";

type IconProps = {
  Icon: IconType;
  className?: string;
};

const Icon = ({ Icon, className = "" }: IconProps) => {
  return <Icon className={`${className}`} />;
};

export default Icon;
