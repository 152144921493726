import { JOI_OFD_CITY_ROOM_SOCKET, REGISTER_SOCKET } from "../../constants/auth";

export const registerSocket = (data: any) => {
  return {
    type: REGISTER_SOCKET,
    data: data,
  };
};

export const joinOfdCity = (data: any) => {
  return {
    type: JOI_OFD_CITY_ROOM_SOCKET,
    data: data,
  };
};
