import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useMediaQuery, useTheme } from "@mui/material";
import './header.css';

const Header = (props: any) => {
    console.log(`props 10.0`, props)
    const [docket, setDocket] = useState("");

    const [showContent, setShowContent] = useState(false);
    const [showResourcesContent, setShowResourcesContent] = useState(false);
    const [showOthersContent, setShowOthersContent] = useState(false);
    const setDocketCap = (e: any) => {
        setDocket(e.toUpperCase());
    };
    const navigate = useNavigate()
    const theme = useTheme();
    const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
    const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
    const mobileView = isMobile1 || isMobile2

    const trackDocket = () => {
        const BranchDocketRegex = (str: any) => {
            const branchDocketRegex = /^[A-Z]{2,5}[1-9]{1}[0-9]{9}$/;
            return branchDocketRegex.test(str);
        }
        const BrnachTrackingIdRegex = (str: any) => {
            const trakingIdRegex = /^[A-Z]{3,5}-[0-9]{3,5}-[0-9]{0,10}$/;
            return trakingIdRegex.test(str);
        }
        const CustomerDocketRegex = (str: any) => {
            const customerDocketRegex = /^[A-Z]{2,4}-[A-Z]{1,3}-[0-9]{1,5}$/;
            return customerDocketRegex.test(str);
        }

        if (BranchDocketRegex(docket) === true) {
            alert("Tracking Number is Not Valid !")
            // navigate({ pathname: "/" })
            navigate("/")
            setDocket("")
            // navigate({pathname:"/TempTracking",
            // state:{
            //     from: "Customer-NotAuthenticated-BranchOrder",
            //     docketNumber: {docket},
            //     typeOfDocket: "Branch Docket"
            // }
            // })
        }
        else if (BrnachTrackingIdRegex(docket) === true) {
            // navigate({
            //     pathname: "/TempTracking",
            //     state: {
            //         from: "Customer-NotAuthenticated-BranchOrder",
            //         docketNumber: { docket },
            //         typeOfDocket: "Branch Order Tracking Id"
            //     }
            // })
            navigate("/TempTracking", {
                state: {
                    from: "Customer-NotAuthenticated-BranchOrder",
                    docketNumber: { docket },
                    typeOfDocket: "Branch Order Tracking Id"
                }
            });
        }
        else if (CustomerDocketRegex(docket) === true) {
            // navigate({
            //     pathname: "/TempTracking",
            //     state: {
            //         from: "Customer-NotAuthenticated-CustomerOrder",
            //         docketNumber: { docket },
            //         typeOfDocket: "Customer Docket"
            //     }
            // })
            navigate("/TempTracking", {
                state: {
                    from: "Customer-NotAuthenticated-CustomerOrder",
                    docketNumber: { docket },
                    typeOfDocket: "Customer Docket"
                }
            });
        }
        else if (CustomerDocketRegex(docket) === false || BrnachTrackingIdRegex(docket) === false || BranchDocketRegex(docket) === false) {
            alert("Docket Number Or Tracking Number is Not Valid !")
            navigate("/")
        }
    };

    const MyArr: any = [{ head: "Direct Sales Agent", tabName: "Direct Sales Agent" },
    { head: "Individual With Fleet", tabName: "Individual With Fleet" },
    { head: "Transport Company", tabName: "Transport Company" },
    { head: "Warehouse", tabName: "Warehouse" },
    { head: "Investors", tabName: "Investors" },
    ]
    const ResourcesArr: any = [{ head: "Privacy Policy", tabName: "Privacy Policy" },
    { head: "User Agreement", tabName: "User Agreement" },
    { head: "T & C", tabName: "T & C" },
    { head: "FAQ", tabName: "FAQ" },
    { head: "Blog", tabName: "Blog" },
    ]
    const OthersArr: any = [
        { label: "Careers", value: "Careers" },
        { label: "Insurance", value: "Insurance" },
        { label: "Store", value: "Store" },
        { label: "Buy / Sell Vehicle", value: "Buy / Sell Vehicle" },
    ]

    return (
        <nav
            id="taptapheader"
            className={
                props?.deviceStatus
                    ? "navbar navbar-custom navbar-fixed-top"
                    : "navbar navbar-custom navbar-fixed-top-extra"
            }
            role="navigation"
        >
            <div id="wraper" style={{ border: "0.2px solid tr" }}>
                <div
                    style={{ width: mobileView ? "auto" : "-webkit-fill-available", paddingInline: "1rem" }}
                    //  className="container navigation"
                    className="navigation"
                >
                    {/* {
                        process.env.NODE_ENV !== 'production' &&  */}
                    {/* <button onClick={ async () => {
                            try {
                                const response = await sendNotification({deviceId : localStorage.getItem("deviceId")})
                                console.log(response,"response1111")
                            } catch (error) {
                                   console.error('Error subscribing to push notifications:', error);
                            }
   
                        }}>send notification</button> */}
                    {/* } */}

                    {/* {
                    process.env.NODE_ENV !== 'production' &&  */}
                    {/* <button onClick={ async () => {
   
                        try {
                            const response = await sendNotification({})
                            console.log(response,"response1111")
                        } catch (error) {
                               console.error('Error subscribing to push notifications:', error);
                        }

                        }}>send notification to all</button> */}
                    {/* } */}


                    <div className="navbar-header page-scroll">
                        <button
                            type="button"
                            className="navbar-toggle"
                            data-toggle="collapse"
                            data-target=".navbar-main-collapse"
                            style={{ backgroundColor: "white", border: "none", fontSize: "large" }}
                        >
                            <i className="fa fa-bars"></i>
                        </button>
                        <a className="navbar-brand" href="/">
                            <img
                                src="../img/tap8.png"
                                alt=""
                                width="120"
                                height="30"
                            />
                        </a>
                        {/* <a className="navbar-brand" href="https://play.google.com/store/apps/details?id=in.taptap.twa&pcampaignid=web_share" target={!mobileView ? "_blank" : ""}>
                            <img
                                src="play-store-image.png"
                                alt=""
                                width="30"
                                height={!mobileView ? "30" : "25"}
                            />
                        </a> */}
                        {/* <a className="navbar-brand" href="https://play.google.com/store/apps/details?id=in.taptap.twa&pcampaignid=web_share" target="_blank">
                            <img
                                src="apple-header-logo.png"
                                alt=""
                                width="30"
                                height="30"
                            />
                        </a> */}
                    </div>

                    {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
                    <div className={mobileView ? "collapse navbar-right navbar-main-collapse" : "collapse navbar-collapse navbar-right navbar-main-collapse"}>
                        <ul className="nav navbar-nav" style={{
                            border: mobileView ? "0.2px solid transparent" : "", height: mobileView ? "785px" : "",
                            fontSize: mobileView ? "large" : "", overflow: mobileView ? "scroll" : "",
                        }}>
                            {mobileView ?
                                <li onClick={() => {
                                    const value = document.querySelector(".collapse");
                                    value?.classList.remove("in")
                                    console.log(`value`, value)
                                }} style={{ fontSize: "xx-large", padding: "5.5% 7.5%", boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px", width: "95%", margin: "auto" }}>
                                    <div onClick={() => navigate("/aboutUs")}>About Us</div>
                                </li>
                                :
                                <li className="dropdown">
                                    <Link to="/aboutUs">About Us</Link>
                                </li>
                            }
                            {mobileView ?
                                <li
                                    style={{ fontSize: "x-large", padding: "1rem 1.5rem", boxShadow: "0 1px 1px rgba(0, 0, 0, 0.3)" }}
                                    onClick={() => { setShowContent(!showContent) }}
                                // className=" wow"
                                // data-wow-delay="0.6s"
                                >
                                    <div style={{ border: "0.2px solid transaprent", width: "100%", display: "flex" }}>
                                        <div style={{ border: "0.2px solid transaprent", width: "85%" }}>Partners</div>
                                        <div style={{ border: "0.2px solid transaprent", width: "15%" }}> {showContent ? <FaChevronUp /> : <FaChevronDown />} </div>
                                    </div>
                                    <div style={{ marginTop: showContent ? "5%" : "" }}>
                                        {showContent && (
                                            MyArr && MyArr?.length > 0 && MyArr?.map((x: any) => {
                                                return (<div
                                                    onClick={() => {
                                                        if (x.tabName === "Direct Sales Agent") {
                                                            navigate("/partners/direct-sales")
                                                            const value = document.querySelector(".collapse");
                                                            value?.classList.remove("in")
                                                            console.log(`value`, value)
                                                        }
                                                        else if (x.tabName === "Individual With Fleet") {
                                                            navigate("/partners/individual-with-fleet")
                                                            const value = document.querySelector(".collapse");
                                                            value?.classList.remove("in")
                                                            console.log(`value`, value)
                                                        }
                                                        else if (x.tabName === "Transport Company") {
                                                            navigate("/partners/transport-company")
                                                            const value = document.querySelector(".collapse");
                                                            value?.classList.remove("in")
                                                            console.log(`value`, value)
                                                        }
                                                        else {
                                                            alert("This Feature Will be Livee Soon. Stay Tuned !")
                                                        }
                                                    }}
                                                    style={{
                                                        border: "0.2px solid transparent",
                                                        marginTop: "10%",
                                                        marginLeft: "2.5%",
                                                        padding: "1% 5%",
                                                        fontSize: "30px"
                                                    }}
                                                >
                                                    {x?.head}
                                                </div>)
                                            })
                                        )}
                                    </div>
                                </li>
                                :
                                <li
                                // className=" wow"
                                // data-wow-delay="0.6s"
                                >
                                    <Link to="/partners">Partners</Link>
                                </li>
                            }

                            {mobileView ?
                                <li
                                    style={{ fontSize: "x-large", padding: "1rem 1.5rem", boxShadow: "0 1px 1px rgba(0, 0, 0, 0.3)" }}
                                    onClick={() => {
                                        const value = document.querySelector(".collapse");
                                        value?.classList.remove("in")
                                        console.log(`value`, value)
                                    }}
                                // className=" wow"
                                // data-wow-delay="0.6s"
                                >
                                    <div onClick={() => navigate("/customer")}>Customer</div>
                                </li>
                                :
                                <li
                                // className=" wow"
                                // data-wow-delay="0.6s"
                                >
                                    <Link to="/customer">Customer</Link>
                                </li>
                            }

                            {/* {mobileView ? 
                                <li
                                    style={{fontSize : "xx-large" , padding : "5.5% 7.5%" ,boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px" , width : "95%" , margin : "auto"}}
                                    onClick={() => {
                                        const value = document.querySelector(".collapse");
                                        value?.classList.remove("in")
                                        console.log(`value`,value)
                                    }}
                                    // className=" wow"
                                    // data-wow-delay="0.6s"
                                >
                                    <div onClick={() => alert("This Feature Will be Livee Soon. Stay Tuned !")}>Investors</div>
                                </li>    
                                :
                                null
                            } */}

                            {mobileView ?
                                <li
                                    style={{ fontSize: "x-large", padding: "1rem 1.5rem", boxShadow: "0 1px 1px rgba(0, 0, 0, 0.3)" }}
                                    onClick={() => {
                                        const value = document.querySelector(".collapse");
                                        value?.classList.remove("in")
                                        console.log(`value`, value)
                                    }}
                                // className=" wow"
                                // data-wow-delay="0.6s"
                                >
                                    <div onClick={() => navigate("/kabra-express/our-networks")}>
                                        Our Networks
                                    </div>
                                </li>
                                :
                                <li
                                // className=" wow"
                                // data-wow-delay="0.6s"
                                >
                                    <Link to="/kabra-express/our-networks">
                                        Our Networks
                                    </Link>
                                </li>
                            }

                            {/* {mobileView ?
                                <li
                                    style={{fontSize : "xx-large" , padding : "5.5% 7.5%" ,boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px" , width : "95%" , margin : "auto"}}
                                    onClick={() => {
                                        const value = document.querySelector(".collapse");
                                        value?.classList.remove("in")
                                        console.log(`value`,value)
                                    }}
                                    // className=" wow"
                                    // data-wow-delay="0.6s"
                                >
                                    <div onClick={() => navigate("/assist")}>Assist</div>
                                </li>
                                : 
                                <li
                                    className=" wow"
                                    data-wow-delay="0.6s"
                                >
                                    <Link to="/assist">Assist</Link>
                                </li>
                            } */}
                            {/* <li
                                className=" wow"
                                data-wow-delay="0.6s"
                            >
                                <Link to="/careers">
                                    Career
                                </Link>
                            </li> */}
                            {mobileView &&
                                <li
                                    style={{ fontSize: "x-large", padding: "1rem 1.5rem", boxShadow: "0 1px 1px rgba(0, 0, 0, 0.3)" }}
                                    onClick={() => { setShowOthersContent(!showOthersContent) }}
                                // className="wow dropdownOthersMobileBox"
                                // data-wow-delay="0.6s"
                                >
                                    {/* <div>Others</div> */}
                                    <div style={{ border: "0.2px solid transaprent", width: "100%", display: "flex" }}>
                                        <div style={{ border: "0.2px solid transaprent", width: "85%" }}>Others</div>
                                        <div style={{ border: "0.2px solid transaprent", width: "15%" }}> {showOthersContent ? <FaChevronUp /> : <FaChevronDown />} </div>
                                    </div>
                                    <div style={{ marginTop: showOthersContent ? "5%" : "" }}>
                                        {showOthersContent && (
                                            OthersArr && OthersArr?.length > 0 && OthersArr?.map((x: any) => {
                                                return (<div
                                                    onClick={() => {
                                                        if (x?.value === "Careers") {
                                                            navigate("/careers")
                                                            const value = document.querySelector(".collapse");
                                                            value?.classList.remove("in")
                                                            console.log(`value`, value)
                                                        }
                                                        else {
                                                            alert("This Feature Will be Livee Soon. Stay Tuned !")
                                                        }
                                                    }}
                                                    style={{
                                                        border: "0.2px solid transparent",
                                                        padding: "1rem 1.5rem",
                                                        fontSize: "large"
                                                    }}
                                                >
                                                    {x?.label}
                                                </div>)
                                            })
                                        )}
                                    </div>
                                    {/* <div> */}
                                    {/* <div>
                                    Others
                                </div> */}
                                    {/* <Select12
                                    className={"selectedSelecBtn"}
                                    // options={userDoc[0].branch.map((b: any) => ({
                                    //   label: b.branchName,
                                    //   value: b._id,
                                    // }))}
                                    options={
                                        [
                                            {label: "Careers",value: "Careers"},
                                            {label: "Insurance",value: "Insurance"},
                                            {label: "Store",value: "Store"},
                                            {label: "Buy / Sell Vehicle",value: "Buy / Sell Vehicle"},
                                        ]
                                    }
                                    // value={logins.entity}
                                    onChange={(e: any) => {
                                        if(e?.value === "Careers"){
                                            navigate("/careers")
                                        }
                                        else if(e?.value === "Insurance"){
                                            // navigate("/insurance")
                                            alert("This Feature Will be Livee Soon. Stay Tuned !")
                                        }
                                        else if(e?.value === "Store"){
                                            // navigate("/store")
                                            alert("This Feature Will be Livee Soon. Stay Tuned !")
                                        }
                                        else if(e?.value === "Buy / Sell Vehicle"){
                                            // navigate("/second-hand-vehicle")
                                            alert("This Feature Will be Livee Soon. Stay Tuned !")
                                        }
                                    }}
                                    // isClearable={true}
                                    // isDisabled={logins.entity ? true : false}
                                /> */}

                                    {/* <div className="dropdown-content">
                                    <Link to="/careers">Careers</Link>
                                    <Link to="/insurance">Insurance</Link>
                                    <Link to="/store">Store</Link>
                                    <Link to="/second-hand-vehicle">Buy / Sell Vehicle</Link>
                                </div> */}
                                    {/* </div> */}
                                </li>
                                // )
                                // :
                                // <li
                                //     // className=" wow"
                                //     // data-wow-delay="0.6s"
                                // >
                                //     <div className="dropdownOthers">
                                //     <div className="dropbtn">
                                //         <div>Others</div>
                                //         <FaChevronDown className="arrowDown"/>
                                //     </div>

                                //     <div className="dropdown-content">
                                //         <Link to="/careers">Careers</Link>
                                //         <Link to="/insurance">Insurance</Link>
                                //         <Link to="/store">Store</Link>
                                //         <Link to="/second-hand-vehicle">Buy / Sell Vehicle</Link>
                                //     </div>
                                //     </div>
                                // </li>
                            }


                            {mobileView &&
                                <li
                                    style={{ fontSize: "x-large", padding: "1rem 1.5rem", boxShadow: "0 1px 1px rgba(0, 0, 0, 0.3)" }}
                                    onClick={() => { setShowResourcesContent(!showResourcesContent) }}
                                >
                                    <div style={{ border: "0.2px solid transaprent", width: "100%", display: "flex" }}>
                                        <div style={{ border: "0.2px solid transaprent", width: "85%" }}>Resources</div>
                                        <div style={{ border: "0.2px solid transaprent", width: "15%" }}> {showResourcesContent ? <FaChevronUp /> : <FaChevronDown />} </div>
                                    </div>
                                    <div style={{ marginTop: showResourcesContent ? "5%" : "" }}>
                                        {showResourcesContent && (
                                            ResourcesArr && ResourcesArr?.length > 0 && ResourcesArr?.map((x: any) => {
                                                return (<div
                                                    onClick={() => {
                                                        if (x.tabName === "Privacy Policy") {
                                                            navigate("/privacy-and-policy/")
                                                            const value = document.querySelector(".collapse");
                                                            value?.classList.remove("in")
                                                            console.log(`value`, value)
                                                        }
                                                        else if (x.tabName === "User Agreement") {
                                                            navigate("/useragreement")
                                                            const value = document.querySelector(".collapse");
                                                            value?.classList.remove("in")
                                                            console.log(`value`, value)
                                                        }
                                                        else if (x.tabName === "T & C") {
                                                            navigate("/terms-and-condition")
                                                            const value = document.querySelector(".collapse");
                                                            value?.classList.remove("in")
                                                            console.log(`value`, value)
                                                        }
                                                        else if (x.tabName === "FAQ") {
                                                            navigate("/faq")
                                                            const value = document.querySelector(".collapse");
                                                            value?.classList.remove("in")
                                                            console.log(`value`, value)
                                                        }
                                                        else if (x.tabName === "Blog") {
                                                            navigate("/blog")
                                                            const value = document.querySelector(".collapse");
                                                            value?.classList.remove("in")
                                                            console.log(`value`, value)
                                                        }
                                                        else {
                                                            alert("This Feature Will be Livee Soon. Stay Tuned !")
                                                            navigate("/")
                                                        }
                                                    }}
                                                    style={{
                                                        border: "0.2px solid transparent",
                                                        marginTop: "10%",
                                                        marginLeft: "2.5%",
                                                        padding: "1% 5%",
                                                        fontSize: "30px"
                                                    }}
                                                >
                                                    {x?.head}
                                                </div>)
                                            })
                                        )}
                                    </div>
                                </li>
                                // :
                                // <li
                                //     // className=" wow"
                                //     // data-wow-delay="0.6s"
                                // >
                                //     <div className="dropdownResources">
                                //     <div className="dropbtn">
                                //         <div>Resources</div>
                                //         <FaChevronDown className="arrowDown"/>
                                //     </div>

                                //     <div className="dropdown-content">
                                //         {ResourcesArr && ResourcesArr?.length > 0 && ResourcesArr?.map((x : any) => {
                                //             return (<div
                                //                         onClick={() =>  {
                                //                             if(x.tabName === "Privacy Policy"){
                                //                                 history.push("/privacy-and-policy/")
                                //                                 const value = document.querySelector(".collapse");
                                //                                 value?.classList.remove("in")
                                //                                 console.log(`value`,value)
                                //                             }
                                //                             else if(x.tabName === "User Agreement"){
                                //                                 history.push("/useragreement")
                                //                                 const value = document.querySelector(".collapse");
                                //                                 value?.classList.remove("in")
                                //                                 console.log(`value`,value)
                                //                             }
                                //                             else if(x.tabName === "T & C"){
                                //                                 history.push("/terms-and-condition")
                                //                                 const value = document.querySelector(".collapse");
                                //                                 value?.classList.remove("in")
                                //                                 console.log(`value`,value)
                                //                             }
                                //                             else if(x.tabName === "FAQ"){
                                //                                 history.push("/faq")
                                //                                 const value = document.querySelector(".collapse");
                                //                                 value?.classList.remove("in")
                                //                                 console.log(`value`,value)
                                //                             }
                                //                             else if(x.tabName === "Blog"){
                                //                                 history.push("/blog")
                                //                                 const value = document.querySelector(".collapse");
                                //                                 value?.classList.remove("in")
                                //                                 console.log(`value`,value)
                                //                             }
                                //                             else{
                                //                                 alert("This Feature Will be Livee Soon. Stay Tuned !")
                                //                                 history.push("/")
                                //                             }
                                //                         }}
                                //                     >
                                //                 {x?.head}
                                //             </div>)
                                //         })}
                                //     </div>

                                //     <div className="dropdown-content">
                                //         <Link to="/careers">Careers</Link>
                                //         <Link to="/insurance">Insurance</Link>
                                //         <Link to="/store">Store</Link>
                                //         <Link to="/second-hand-vehicle">Buy / Sell Vehicle</Link>
                                //     </div>
                                //     </div>
                                // </li>
                            }

                            {mobileView ? (
                                <div className="navbar-form navbar-left ">
                                    {/* <li
                                    style={{fontSize : "xx-large" , padding : "5.5% 7.5%" ,boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px" , width : "95%" , margin : "auto"}}
                                    onClick={() => {
                                        const value = document.querySelector(".collapse");
                                        value?.classList.remove("in")
                                        console.log(`value`,value)
                                    }}
                                    className=" wow"
                                    data-wow-delay="0.8s"
                                >
                                    <a href="/login">
                                        <img src="../img/user.svg" width="22px" />{" "}
                                        <div onClick={() => navigate("/partners")}>Sign Up</div>
                                    </a>
                                </li> */}

                                    <li
                                        style={{ padding: "1rem 1.5rem", backgroundColor: "royalblue", borderRadius: "5px", textAlign: "center", color: "white" }}
                                        onClick={() => {
                                            const value = document.querySelector(".collapse");
                                            value?.classList.remove("in")
                                            console.log(`value`, value)
                                        }}
                                    // className=" wow"
                                    // data-wow-delay="0.8s"
                                    >
                                        {/* <a href="/login"> */}
                                        {/* <img src="../img/user.svg" width="22px" />{" "} */}
                                        <div onClick={() => navigate("/login")}>Log In</div>
                                        {/* </a> */}
                                    </li>

                                    <div style={{ position: "relative", marginTop: "1rem" }}>
                                        <span className="fa fa-search" onClick={() => {
                                            const value = document.querySelector(".collapse");
                                            value?.classList.remove("in")
                                            console.log(`value`, value)
                                            trackDocket()
                                        }} style={{
                                            right: "1rem",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            position: "absolute"
                                        }}></span>
                                        <input
                                            type="text"
                                            // className="form-control wow"
                                            // data-wow-delay="0.8s"
                                            style={{ width: "100%", padding: "1rem 1.25rem", borderRadius: "5px", border: "1px solid rgba(0, 0, 0, .4)" }}
                                            placeholder="Track"
                                            value={docket}
                                            onChange={(e) => {
                                                setDocketCap(e.target.value);
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    trackDocket();
                                                }
                                            }}
                                        />
                                    </div>
                                    {/* <div style={{fontSize : "xx-large" , padding : "4.5% 2.5%" , display : "flex"}}>
                                    <input
                                        type="text"
                                        // className="trackInputNavbarMobile"
                                        // data-wow-delay="0.8s"
                                        style={{width : "200px"}}
                                        placeholder="Your tracking no."
                                        value={docket}
                                        onChange={(e) => {
                                            setDocketCap(e.target.value);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                trackDocket();
                                            }
                                        }}
                                    />
                                    <button
                                        onClick={trackDocket}
                                        className="btn get-started-track wow"
                                        // data-wow-delay="0.8s"
                                        style={{width : "125px"}}
                                    >
                                        Track
                                    </button>
                                </div> */}
                                </div>) :
                                (<div className="navbar-form navbar-left">
                                    <div className="form-group trackInputNavbarWeb">
                                        <span className="fa fa-search track-search-icon" onClick={() => { trackDocket() }}></span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            // data-wow-delay="0.8s"
                                            placeholder="Tracking no."
                                            value={docket}
                                            onChange={(e) => {
                                                setDocketCap(e.target.value);
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    trackDocket();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>)}

                            {!mobileView &&
                                <li
                                // className=" wow"
                                // data-wow-delay="0.8s"
                                >
                                    <Link to="/login">
                                        {/* <a href="/login"> */}
                                        <img src="../img/user.svg" width="22px" />{" "}
                                        <span>SignIn</span>
                                        {/* </a> */}
                                    </Link>
                                </li>
                            }
                            {/* <li className=" wow" data-wow-delay="0.8s">
                <a href="/customerLogin">
                  <img src="../img/user.svg" width="25px" />{" "}
                  <span>Customer Login</span>
                </a>
              </li> */}
                        </ul>
                    </div>

                    {/* <!-- /.navbar-collapse --> */}
                </div>
            </div>
            {/* <!-- /.container --> */}
        </nav>
    );
};

export default
    // withRouter(
    Header
// );
