import React from 'react'
import { connect } from 'react-redux'
import formatDate from "../../../services/FormatDate";
import setTimeFormat from "../../../services/FormatTime";
import { HoursIcon, InfoIcon, VehicleIcon } from "../../../newSrc/constants/icons"

export const AddressComponent = (props: any) => {
    const meterTOkmChange = (meter: any) => {
        return (meter / 1000).toFixed(2)
    }

    const secondTominConvert = (time: any, index: any) => {
        let t = new Date();
        t.setSeconds(t.getSeconds() + time + index * 30 * 60);
        return `${formatDate(t)}- ${setTimeFormat(t)}`
    }

    return (
        <div>
            <div className="border-white shadow-small flex-grow-1 br-4 p-1 mb-4" style={{ ...(props?.activeDest === props?.eData.placeIndex ? { border: '1px solid var(--clr-primary-400)' } : props?.activeDest < props?.eData.placeIndex ? {} : { border: '2px solid var(--clr-primary-400)' }) }}>
                <div>
                    <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", alignItems: 'center', gap: "5px", fontWeight: "bold" }} className="fs-link">
                            <div className="flex-center p-8" style={{ width: "25px", height: "25px", backgroundColor: "var(--clr-primary-400)", color: "var(--clr-white-900)", textAlign: "center", borderRadius: "50%", fontWeight: "bold" }}>{props?.iData + 1}</div>
                            <div style={{ width: "85%", textAlign: "left", fontWeight: "bold" }}>{props?.eData?.placeId?.name}</div>
                            <div style={{ width: "7.5%", height: "20px", textAlign: "center", fontWeight: "bold" }} onClick={() => {
                                props?.setAdressPickUpInfo(props?.iData + 1),
                                    props?.setAdressPackageData({ serviceType: props?.eData.placeType, placeId: props?.eData.placeId?.placeId })
                            }}>
                                <InfoIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-500)", cursor: "pointer" }} />
                            </div>
                        </div>
                        <div style={{ marginTop: "5px" }}>
                            <div className="flex-col gap-4 fs-link">
                                <div className="flex ai-center gap-4 fs-link"><VehicleIcon style={{ width: "24px", height: "24px" }} className="flex-shrink-0 text-primary-400" />{"  "}{meterTOkmChange(props?.eData?.distance)} km away from {props?.iData == 0 ? "Driver" : "Previous Location"}
                                </div>
                                <div className="flex ai-center gap-4 fs-link fs-link"><HoursIcon style={{ width: "24px", height: "24px" }} className="flex-shrink-0 text-primary-400" /> {" "}
                                    {secondTominConvert(props?.eData.duration, props?.iData)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AddressComponent)