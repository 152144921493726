// react
import React, { useState } from "react";
// constants (data | icon | images)
import {
  landingPageMainNavItem,
  landingPagePartnersNavItem,
} from "../../../../constants/data";
import {
  DirectSalesAgentIcon,
  IndividualWithFleetIcon,
  InvestorsIcon,
  LockIcon,
  TransportCompanyIcon,
  WarehouseIcon,
} from "../../../../constants/icons";
import { ReactComponent as BikeSvg } from "../../assets/bike.svg";
import { ReactComponent as CourierSvg } from "../../assets/courier.svg";
import { ReactComponent as FlightSvg } from "../../assets/flight.svg";
import { ReactComponent as FullLoadSvg } from "../../assets/full-load.svg";
import { ReactComponent as MoversSvg } from "../../assets/movers.svg";
import { ReactComponent as PackersSvg } from "../../assets/packers.svg";
import { ReactComponent as PartLoadSvg } from "../../assets/part-load.svg";
import { ReactComponent as ShipSvg } from "../../assets/ship.svg";
import { ReactComponent as TempoSvg } from "../../assets/tempo.svg";
import { ReactComponent as WarehouseSvg } from "../../assets/warehouse.svg";
// context
import { useLandingPageContext } from "../../../../hooks/useLandingPageContext";
// css
import "./navigation.css";
import Dropdown from "../Dropdown/Dropdown";

// variables
const servicesIcon = [
  {
    id: 0,
    isOne: false,
    images: [BikeSvg, TempoSvg],
  },
  {
    id: 1,
    isOne: false,
    images: [WarehouseSvg, CourierSvg],
  },
  {
    id: 2,
    isOne: false,
    images: [PartLoadSvg, FullLoadSvg],
  },
  // {
  //   id: 3,
  //   isOne: true,
  //   images: [FullLoadSvg],
  // },
  {
    id: 3,
    isOne: true,
    images: [PackersSvg, MoversSvg],
  },
  {
    id: 4,
    isOne: false,
    images: [FlightSvg, ShipSvg],
  },
];
const partnersIcon = [
  <DirectSalesAgentIcon />,
  <IndividualWithFleetIcon />,
  <TransportCompanyIcon />,
  <WarehouseIcon />,
  <InvestorsIcon />,
];
const listItemClassName =
  "landing-page-main-nav-list-item | pointer fw-500 px-1 py-8 flex-col-center gap-4 user-select-none fs-link text-white-300";

// FC: Navigation Component
const Navigation = () => {
  // State:
  const [dialogOpen, setDialogOpen] = useState(() => -1);
  // from Landing Page Context
  const {
    mainRef,
    activePage,
    activeService,
    setActiveService,
    activePartner,
    setActivePartner,
    setActiveExpressParcelDeliveryType,
    setActiveLoadType,
  } = useLandingPageContext();

  const handleMouseEnter = (index: number) => {
    setDialogOpen(index);
  };

  const handleMouseLeave = () => {
    setDialogOpen(-1);
  };

  // services
  if (activePage === "services") {
    return (
      <nav className="landing-page-main-nav | shadow-small">
        <ul className="landing-page-main-nav-list services | m-0">
          {landingPageMainNavItem.length > 0 &&
            landingPageMainNavItem.map((item, index) => (
              <li
                key={item}
                className={`relative ${listItemClassName} ${
                  activeService === item ? "active" : ""
                }`}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                onClick={() => {
                  if (
                    item === "Express Parcel" ||
                    item === "Part Load & Full Load"
                  ) {
                    setDialogOpen(index);
                    return;
                  } else {
                    setDialogOpen(-1);
                  }
                  if (item === "Packers & Movers" || item === "International") {
                    return alert("Coming Soon😊");
                  } else {
                    setActiveService(item);
                    mainRef.current?.scrollTo({ top: 0, behavior: "smooth" });
                  }
                }}
              >
                <span className="| flex ai-center gap-4">
                  {/* {activeService === item ? servicesIcon[index].images.map(item => <img loading="lazy" key={item} style={{ width: "30px" }} src={item} alt="img" />) : servicesIcon[index].grayImages.map(item => <img loading="lazy" key={item} style={{ width: "30px" }} src={item} alt="img" />)} */}
                  {servicesIcon[index].images.map((Item, index) => (
                    <Item
                      key={index}
                      style={{ width: "30px", height: "30px" }}
                      className={`navigation-icon flex-center ${
                        activeService === item
                          ? "text-black-900"
                          : "text-white-300"
                      }`}
                    />
                  ))}
                </span>
                {/* <span className="| flex gap-4">{servicesIcon[index].whiteImages.map(item => <img key={item} style={{ width: "30px" }} src={item} alt="img" />)}</span> */}
                <div className="flex ai-baseline gap-4">
                  <span className="text-center">{item}</span>
                  {(item === "Packers & Movers" ||
                    item === "International") && (
                    <span className="navigation-lock-icon">
                      <LockIcon />
                    </span>
                  )}
                </div>
                {item === "Express Parcel" && (
                  <Dropdown
                    item="Express Parcel"
                    items={[
                      {
                        value: "Branch to Branch",
                        Icon: WarehouseSvg,
                        onClick: () => {
                          setActiveService("Express Parcel");
                          setActiveExpressParcelDeliveryType(
                            "Branch to Branch"
                          );
                        },
                      },
                      {
                        value: "Door Delivery",
                        Icon: CourierSvg,
                        onClick: () => {
                          setActiveService("Express Parcel");
                          setActiveExpressParcelDeliveryType("Door Delivery");
                        },
                      },
                    ]}
                    index={index}
                    dialogOpen={dialogOpen}
                    setDialogOpen={setDialogOpen}
                  />
                )}
                {item === "Part Load & Full Load" && (
                  <Dropdown
                    item="Part Load & Full Load"
                    items={[
                      {
                        value: "Part Load",
                        Icon: PartLoadSvg,
                        onClick: () => {
                          setActiveService("Part Load & Full Load");
                          setActiveLoadType("Part Load");
                        },
                      },
                      {
                        value: "Full Load",
                        Icon: FullLoadSvg,
                        onClick: () => {
                          setActiveService("Part Load & Full Load");
                          setActiveLoadType("Full Load");
                        },
                      },
                    ]}
                    index={index}
                    dialogOpen={dialogOpen}
                    setDialogOpen={setDialogOpen}
                  />
                )}
              </li>
            ))}
        </ul>
      </nav>
    );
  }
  // partners
  else if (activePage === "partners") {
    return (
      <nav className="landing-page-main-nav | shadow-small">
        <ul className="landing-page-main-nav-list partners | m-0">
          {landingPagePartnersNavItem.length > 0 &&
            landingPagePartnersNavItem.map((item, index) => (
              <li
                key={item}
                className={`relative ${listItemClassName} ${
                  activePartner === item ? "active" : ""
                }`}
                onClick={() => {
                  if (item === "Warehouse" || item === "Investors") {
                    return alert("Coming Soon😊");
                  } else {
                    setActivePartner(item);
                    mainRef.current?.scrollTo({ top: 0, behavior: "smooth" });
                  }
                }}
              >
                {/* <span className="| flex gap-4">{activeService === item ? servicesIcon[index].images.map(item => <img key={item} style={{ width: "30px" }} src={item} alt="img" />) : servicesIcon[index].whiteImages.map(item => <img key={item} style={{ width: "30px" }} src={item} alt="img" />)}</span><span>{item}</span> */}
                <span className="| " style={{ fontSize: "30px" }}>
                  {partnersIcon[index]}
                </span>
                <div className="flex ai-baseline gap-4">
                  <span className="text-center">{item}</span>
                  {(item === "Warehouse" || item === "Investors") && (
                    <span className="navigation-lock-icon">
                      <LockIcon />
                    </span>
                  )}
                </div>
              </li>
            ))}
        </ul>
      </nav>
      // <nav className="landing-page-main-nav">
      //   <ul className="landing-page-main-nav-list partners | m-0">{landingPagePartnersNavItem.length > 0 && landingPagePartnersNavItem.map((item, index) => <li key={item} className={`${listItemClassName} ${activePartner === item ? "active" : ""}`} onClick={() => { setActivePartner(item); mainRef.current?.scrollTo({ top: 0, behavior: "smooth" }) }}>{partnersIcon[index]}{item}</li>)}</ul>
      // </nav>
    );
  }
  // customer
  else {
    return null;
  }
};

export default Navigation;
