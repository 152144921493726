import { buildQueryString, ServiceMaker } from ".";

// Common

export const verifyContact = async (data:object) => await ServiceMaker(`customerBooking/check/customer?${buildQueryString(data)}`,"GET")

export const sendOTPForLandingForms = async (data:object) => await ServiceMaker(`customerLogin/sendOtp`,"POST",data)

export const verifyOTPLandingForms = async (data:object) => await ServiceMaker(`customerLogin/verifyOTP`,"POST",data)

// Intracity

export const createIntracityLead = async (data:object) => await ServiceMaker(`customerBooking/leads/intraCity/create`,"POST",data)

// PartLoad

export const createPartLoadLead = async (data:object) => await ServiceMaker(`customerBooking/leads/partLoad/create`,"POST",data)

// PartLoad

export const createFullLoadLead = async (data:object) => await ServiceMaker(`customerBooking/leads/fullLoad/create`,"POST",data)

// Express Parcel Booking 

export const createExpressParcelBooking = async (data: object) => await ServiceMaker(`customerBooking/load/orders`,"POST",data)

// getBranchToBranchPrice

export const expressParcelBookingGetBranchToBranchPrice = async (data: object) => await ServiceMaker(`customerBooking/estimated/getBranchToBranchPrice?${buildQueryString(data)}`,"GET",)

export const createFullLoadLeadexpressParcelLeadBookingGetBranchToBranchPrice = async (data: object) => await ServiceMaker(`customerBooking/leads/branchToBranch/create`,"POST",data)

