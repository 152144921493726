import * as Yup from "yup";
import { fromJS } from "immutable";

export const add_Expense_Boolean = (val: boolean) => ({
  name: val,
  fleet: val,
  branch: val,
  general: val,
  variance: val,
  sub: val,
  duration: val,
  company: val,
  category: val,
});
export const add_Fixed_Expense_Boolean = (val: boolean) => ({
  entity: val,
  entity_id: val,
  ref: val,
  duration: val,
  amt: val,
  company: val,
  salary_days: val,
  extra_salary_days: val
});

export const add_Expense_Validation = fromJS({
  name: Yup.string().required("This is a required field"),
  sub: Yup.string().required("This is a required field"),
  category: Yup.object(),
  fleet: Yup.string(),
  branch: Yup.string(),
  general: Yup.string(),
  duration: Yup.string(),
  company: Yup.string(),
  monthly_duration: Yup.bool(),
});
export const add_Fixed_Expense_Validation = fromJS({
  entity_id: Yup.object().shape({
    value: Yup.string().required("This is a required field"),
  }),
  ref: Yup.object().shape({
    value: Yup.string().required("This is a required field"),
  }),
  duration: Yup.string().required("This is a required field"),
  amt: Yup.string().required("This is a required field"),
});

export const add_Sub_Income_Validation = fromJS({
  name: Yup.string().required("This is a required field"),
  ref: Yup.string().required("This is a required field"),
});
export const add_Sub_Income_Boolean = (val: boolean) => ({
  name: val,
  ref: val,
});
export const validateExpense = (schema: any, obj: any) => {
  try {
    if (!schema) return false;
    schema.validateSync(obj);
    return false;
  } catch (err:any) {
    return err.message;
  }
};
