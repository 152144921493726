export const QR_SET_SCAN_LOGS = "QR:SET_SCAN_LOGS";
export const QR_SET_DIALOG = "QR:QR_SET_DIALOG";
export const QR_SET_SCAN_CACHES = "QR:QR_SET_SCAN_CACHES"

export interface setScanLogsAction {
  type: typeof QR_SET_SCAN_LOGS;
  payload: any;
}

export interface setQrDialogAction {
  type: typeof QR_SET_DIALOG;
  payload: boolean;
}

export interface setQrScanCachesAction {
  type: typeof QR_SET_SCAN_CACHES;
  payload: string;
}