const prefix = 'INCOME: '

export const GET_INCOME_TYPES = prefix + 'GET_INCOME_TYPES'
export const GET_INCOME_TYPES_FAILED = prefix + 'GET_INCOME_TYPES_FAILED'

export const CHANGE_ADD_INCOME = prefix + 'CHANGE_ADD_INCOME'

export const GET_SUB_TYPES = prefix + 'GET_SUB_TYPES'
export const SET_ASSET_TYPES = prefix + 'SET_ASSET_TYPES'
export const SET_ASSET_TYPES_BALANCE = prefix + 'SET_ASSET_TYPES_BALANCE'
export const GET_ASSET_TYPES = prefix + 'GET_ASSET_TYPES'
export const POST_INCOME_RECORD_SUCCESS = prefix + 'POST_INCOME_RECORD_SUCCESS'
export const POST_INCOME_RECORD_FAILED = prefix + 'POST_INCOME_RECORD_FAILED'
export const CLEAR_ADD_INCOME = prefix + "CLEAR_ADD_INCOME"

export const SET_INCOME_RECORD = prefix + 'SET_INCOME_RECORD'
export const RESET_INCOME_RECORD = prefix + 'RESET_INCOME_RECORD'
export const DELETE_INCOME_RECORD = prefix + 'DELETE_INCOME_RECORD'
export const INCOME_RECORD_EDITED_SUCCESS = prefix + 'INCOME_RECORD_EDITED_SUCCESS'
export const EDIT_INCOME_RECORD_FAILED = prefix + 'EDIT_INCOME_RECORD_FAILED'
export const EDIT_INCOME_RECORD_SUCCESS = prefix + 'EDIT_INCOME_RECORD_SUCCESS'
export const SET_ASSET_BALANCES = prefix + 'SET_ASSET_BALANCES'
export const REACT_SELECT_ERROR = prefix + 'REACT_SELECT_ERROR'
export const CHECK_INCUR_INCOME_VALIDATION = prefix + 'CHECK_INCUR_INCOME_VALIDATION'
export const SET_PAST_INCOMES = prefix + 'SET_PAST_INCOMES'
export const GET_ASSET_BAL = prefix + 'GET_ASSET_BAL'
export const UPDATE_PAST_INCOMES = prefix + 'UPDATE_PAST_INCOMES'
export const ADD_INCOME_TYPE = prefix + 'ADD_INCOME_TYPE'
export const REMOVE_INCOME_TYPE = prefix + 'REMOVE_INCOME_TYPE'