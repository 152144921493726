import { LIST_OF_ALL_USERS } from "../constants/userList";

const initstate:any = []

const listOfAllUser = (state = initstate , action: { type: any; payload: any }) => {
  switch (action.type) {
    case LIST_OF_ALL_USERS : {
        return {
            ...state,
            userList : action.payload
        }
    }
    default:
      return state;
  }
}

export default listOfAllUser;