import { useJsApiLoader } from "@react-google-maps/api";

const LIBRARIES = ["places"];
const commonOptions: any = {
  googleMapsApiKey: "AIzaSyA5kw1jdPoWOGfUDL0o2vW0Ezf2Cvq12OU",
  libraries: LIBRARIES,
  nonce: "nonce",
};

export const useGoogleMapJsLoader = () => {
  const { isLoaded, loadError } = useJsApiLoader(commonOptions);
  return {
    isLoaded,
    loadError,
  };
};
