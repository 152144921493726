import { LocationType } from "../types/booking.type";

export const LocationtypeEnums: LocationType[] = [
  "WORK",
  "HOME",
  "HOTEL",
  "OTHER",
];

export const senderTypeList = {
  B: [
    { label: "Self", value: "S" },
    { label: "Contact", value: "C" },
    { label: "Bussiness", value: "B" },
  ],
  P: [
    { label: "Self", value: "S" },
    { label: "Contact", value: "C" },
  ],
  C: [
    { label: "Self", value: "S" },
    { label: "Contact", value: "C" },
  ],
};

export const vehicleModelOptions = [
  {
    label: "Flat",
    value: "Flat",
  },
  {
    label: "Open",
    value: "Open",
  },
  {
    label: "Full Covered",
    value: "Full Covered",
  },
  {
    label: "Half Covered",
    value: "Half Covered",
  },
];

export const paymentTypeOptions = [
  {
    label: "ToPay",
    value: "topay",
  },
  {
    label: "Paid",
    value: "paid",
  },
];

export const remarksOptions = [
  { id: 0, remark: "Driver takes longer than required time" },
  { id: 1, remark: "Destination is incorrect!" },
  { id: 2, remark: "Selected vehicle is inappropriate!" },
];
