import axios from "axios";
import { getDest } from "./routeUtils/destination";
import { getAcrossService, fetchDocketDetails } from "../services/operations";
import { getLoadingReportList } from "../services/report";
import { fedexCreatePickup } from "../services/fedex";
import { getCityByPincode } from "../services/area";
import { unloadPackages } from "../services/unloading";
import { listByCompany } from "../services/route";
import {
  getNotificationService,
  getPendingActionNotificationCount,
} from "../services/user";
import API from "../axios";

import { SET_RIGHTS } from "../constants/rights";
import { SET_APPRIGHTS } from "../constants/appRights";

import { SET_COMPANY_ROUTES, GET_ROUTES } from "../constants/route";
import { changeSimple } from "./branch/index";
import {
  SHOW_LOADER,
  HIDE_LOADER,
  SET_DOCKET,
  SET_ACROSS,
  RESET_EDIT,
} from "../constants/booking";
import { fetchUnverifiedDocketCount } from "../services/verifyPOD";

import { DELIVERY_PENDING, DELIVERY_SUCCESS } from "../constants/delivery";

import {
  SET_RECEIVED_UNAPPROVED_MEMOS,
  APPROVE_OR_REJECT_NOTIFY_MEMO,
} from "../constants/memo";

import { baseURL } from "../axios";

import {
  fetchRouteForExpense,
  fetchIncentiveAmount,
} from "../services/expenses";

import { getUnapprovedMemosService } from "../services/memo";

import { userAllBalancesService } from "../services/user";

import { failed, showHttpError, showMessage } from "../utils/message";
import * as auth from "./auth";

import { fetchFleets } from "./fleet/index";
// import showErrorMessage from "../containers/generics/showErrorMessage";
import { makeDocketMap } from "../containers/operations/loadutils/makeDocketMap";
import { socket } from "../store";
import { pendingApproval } from "../services/franchise";
import jsonToFormdata from "../utils/jsonToFormdata";
import {
  returnODAPackagesService
} from "../services/delivery";
import { getAllBranchesByCompany } from "../services/branch";
import { fetchBranchwiseBookingCounters } from "../services/booking";
import {
  updateVerifyExpenseBadges,
  updatePodCount,
  updateVerifyStockBadges,
  updateVerifyShortageBadges,
  updateOperationsAllCount,
  resetOperatingCount,
  graceToweCount,
  updatePettyCashBadges,
  // OperationsRequestCount,
} from "./sidebareBadges";
import { getStockCount } from "../services/verifyStock";
import { getShortageCount } from "../services/settledAmount";
// import getOperationRequestCount from "../services/operationsCount";
import { Dispatch } from "redux";
import { RootState } from "../store";
import { getTableCashCount } from "../services/TableCashList";
import { SET_SENDER_ADDRESS_FROM_OPBRANCH } from "../constants/booking";
import { listFilteredBranches } from "./franchise";
import { getOutForDeliveryData } from "../services/fleet";
import { countsCCAvenueTxns } from "../services/paymentGateWay";
import { getMaterialList } from "../services/intraCityRate";
// import { REGISTER_SOCKET } from "../constants/auth";

const url = baseURL;
// const url = 'http://192.168.1.142:3000/api'

export { url };
export * from "./expense";
export * from "./booking";
export * from "./delivery";
export * from "./branch";
export * from "./auth";

export const fetchBalance = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      let { user } = getState();
      let { uid, loginType, company } = user;
      let payload: { [k: string]: any } = { user: uid, loginType: loginType };
      payload["branch"] = loginType == "B" ? user.opBranch._id : "";
      payload["fleet"] = loginType == "F" ? user.opFleet._id : "";
      payload["company"] = company.id;

      let reportView = await API.post("report/view", payload);
      // let pendingReports = API.post('report/pending', payload)
      // const resList = await Promise.all([reportView, pendingReports])

      // let [repView, pendingRep] = resList

      let { expenses: expenseObj } = reportView.data.response;
      let { opening } = expenseObj;

      const balance = opening;
      dispatch(updateBalance(balance));
    } catch (err : any) { }
  };
};
export const updateBalance = (balance: any, options?: any) => ({
  type: "UPDATE_BALANCE",
  payload: balance,
  options,
});

export const updateIncentive = () => {
  return async (dispatch: Dispatch) => {
    try {
      let amount = await fetchIncentiveAmount();
      dispatch({
        type: "UPDATE_INCENTIVE",
        payload: Number(amount) >= 0 ? Number(amount).toFixed(0) : "N/A",
      });
    } catch (err : any) { }
  };
};

export const CCAvenueTxnsCount = (data: any) => {
  console.log(data, "data111111111111")
  return async (dispatch: Dispatch) => {
    try {
      let amount = await countsCCAvenueTxns(data);
      dispatch({
        type: "UPDATE_CCAVENUETXNS",
        payload: Number(amount?.notSettledCount) >= 0 ? Number(amount?.notSettledCount).toFixed(0) : "N/A",
      });
    } catch (err : any) { }
  };
};

export const refreshMemos = () => ({ type: "REFRESH_MEMO" });

//area functionality
export const getCity = (pin: any) => {
  return async (dispatch: Dispatch) => {
    // let response = await axios.get(url + '/area/city/' + pin)
    try {
      const city = await getCityByPincode(pin);
      // if (response.data.status != 1) throw "Something went wrong"
      dispatch(changeSimple(["city"], city));
    } catch (err:any) {
      showMessage(err.message || "Invalid Pincode", failed);
    }
  };
};

export const changeBranchHub = (data: any) => ({
  type: "BRANCH_HUB",
  payload: data,
});

export const changeChildFleets = (data: any) => ({
  type: "CHILD_FLEETS",
  payload: data,
});

//overall entities : material packing
export const getMaterial = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      let reduxData = getState();
      if (reduxData?.booking?.material?.length <= 0) {
        let data = await getMaterialList();
        // if (response.data.status != 1) throw "Could not fetch materials";
        dispatch({
          type: "SET_MATERIAL",
          payload: data,
        });
      }
      else {
        dispatch({
          type: "SET_MATERIAL",
          payload: reduxData?.booking?.material,
        });
      }
    } catch (err : any) { }
  };
};

export const getPackingType = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      let reduxData = getState();
      console.log(`Packing Type List`, reduxData?.booking?.packingType?.length)
      if (reduxData?.booking?.packingType?.length === undefined) {
        let response = await axios.get(url + "/packing/type/list");
        if (response.data.status != 1) {
          throw "Could not fetch packages";
        }
        dispatch({
          type: "SET_PACKING_TYPE",
          payload: response.data.response,
        });
      }
      else {
        dispatch({
          type: "SET_PACKING_TYPE",
          payload: reduxData?.booking?.packingType,
        });
      }
    } catch (err : any) { }
  };
};

export const checkMemos = (user: any) => async (dispatch: Dispatch) => {
  try {
    const memoResult = await getUnapprovedMemosService({ user });

    await dispatch({
      type: SET_RECEIVED_UNAPPROVED_MEMOS,
      payload: memoResult,
    });
  } catch (err : any) { }
};

// export const setFranchiseRequests = (company) => async (dispatch, getState) => {
//     try{
//         let franchiseRequests = await
//     }catch(err){

//     }
// }

export const approveOrRejectNotifyMemo = (memo: any) => ({
  type: APPROVE_OR_REJECT_NOTIFY_MEMO,
  payload: memo,
});

export const setFranchiseRequestsNotifications = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const company = getState().user.company.id;
      const data = await pendingApproval(company);
 
      let parentCompanies = data.map((d: any) => d.parent_company);
      let childCompanies = data.map((d: any) => d.child_company);
      let companies = parentCompanies
        .concat(childCompanies)
      let companyMap = new Set(companies);

      let companySet = new Set();


      data.forEach((r: any) => {
        const { parent_company, child_company } = r;
        let intentCompany =
          parent_company != company ? parent_company : child_company;
        if (companyMap.has(intentCompany) && child_company == company) {
          companySet.add(intentCompany);
        }
      });
      const franchiseRequests = Array.from(companySet);
      dispatch({
        type: "INIT:SET_FRANCHISE_REQUESTS",
        payload: franchiseRequests,
      });
    } catch (err : any) {
      showHttpError(err)
    }
  };
};
export const setCities = () => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await axios({
        method: "get",
        url: url + "/city/list",
      });
      dispatch({
        type: "SET_CITIES",
        payload: res.data.response,
      });
    } catch (error) { }
  };
};
export const setBranches = (cid: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: "SET_BRANCHES",
        payload: await getAllBranchesByCompany(cid),
      });
    } catch (err : any) { }
  };
};
let watchLocationId: any;
export const login = (
  contact: number,
  pwd: string,
  otp: any,
  deviceId: any
) => {
  const version = `1.0.5`
  const flag = {
    username: contact,
    password: pwd,
    OTP: otp,
    deviceId: deviceId,
    version: version,
  };
  return async (dispatch: any, getState: () => any) => {
    try {
      dispatch({
        type: "START_LOADER",
      });
      let response = await axios({
        method: "post",
        url: url + "/login",
        data: flag,
      });
      if (response.data.response.deviceId) {
        localStorage.setItem("ud", response.data.response.deviceId);
      }
      localStorage.setItem("loginBy","transporter")
      if (response.data.response.otpRequired) {
        dispatch({
          type: "END_LOADER",
        });
        return response.data.response.otpRequired;
      }

      let cid = response.data.response.user.company._id;
      const { _id: user } = response.data.response.user;
      if (!cid || !user) throw "fail";
      try {
        const geo = navigator.geolocation;

        if (!geo) {
          showMessage("Geolocation is not upported", "salmon");
          return;
        }

        var geo_options = {
          enableHighAccuracy: true,
          timeout: 5000000,
          // maximumAge: 1000000
        };

        watchLocationId = geo.watchPosition(
          function (position) {
            let latitude: any = position.coords.latitude;
            let longitude: any = position.coords.longitude;
            let accuracy: any = position.coords.accuracy;
            let dateTime: any = new Date().toLocaleString();

            localStorage.setItem("latitude", latitude);
            localStorage.setItem("longitude", longitude);
            localStorage.setItem("accuracy", accuracy);
            localStorage.setItem("dateTime", dateTime);

            if (socket) {
              try {
                // update code to send location less frequent
                // let token = sessionStorage.getItem('kabra:token')
                // socket.emit('updateLocation',{ latitude, longitude, accuracy, token  })
              } catch (err : any) { }
            }
          },
          function () {
            // showMessage("Sorry, no position available.");
            // showMessage('code: '    + error.code    + '\n' +
            // 'message: ' + error.message + '\n');
          },
          geo_options
        );
      } catch (err : any) { }

      setInterval(function () {
        let latitude = localStorage.getItem("latitude");
        let longitude = localStorage.getItem("longitude");
        let accuracy = localStorage.getItem("accuracy");

        const { opBranch, loginType, opFleet } = getState().user;

        let branch_id = loginType == "B" ? opBranch._id : "";
        let fleet_id = loginType == "F" ? opFleet._id : "";
        let entity = loginType == "B" ? branch_id : fleet_id;

        if (socket) {
          try {
            // update code to send location less frequent
            let token = sessionStorage.getItem("kabra:token");
            if (latitude && longitude && entity) {
              socket.emit("updateLocation", {
                latitude,
                longitude,
                accuracy,
                token,
                sub: loginType,
                entity: entity,
              });
            }
          } catch (err : any) { }
        }
      }, 60000);
      //geo.watchPosition()
      /* geo.watchPosition(({ coords, timestamp }) => {

                    let latitude = coords.latitude
                    let longitude = coords.longitude
                    let accuracy = coords.accuracy
                    

                    localStorage.setItem('latitude', latitude)
                    localStorage.setItem('longitude', longitude)
                    localStorage.setItem('accuracy', accuracy)

                }) */

      await dispatch({
        type: SET_RIGHTS,
        payload: response.data.response.user.departments,
        role: response.data.role,
      });

      await dispatch({
        type: SET_APPRIGHTS,
        payload: response.data.response.user.appDepartments,
      });

      const receivedRights = getState().rights;
      const receivedAppRights = getState().appRights;

      dispatch({
        type: "LOGIN",
        payload: response,
      });

      const { accessToken } = response.data;

      // localStorage.setItem('contact_lcm', contact)
      // localStorage.setItem('pwd_lcm', pwd)
      await dispatch(auth.registerSocket({ token: accessToken }));


      await dispatch(fetchFleets()),
        await dispatch(setBranches(cid)),
        await dispatch(fetchUserAllBalances(user)),
        Promise.all([
          dispatch(setCities()),
          dispatch(listFilteredBranches(getState().user.company.id)),
          dispatch({
            type: "SET_NOTIFICATION_LOADING",
            payload: true,
          }),
          getNotificationService({ offset: 0, limit: 20 })
            .then((newNoti) => {
              dispatch({
                type: "SET_NOTIFICATION_FULFILLED",
                payload: {
                  notifications: newNoti,
                  notificationLoadMore: newNoti.length === 20,
                  offset: 0,
                },
              });
            })
            .catch(() => {
              dispatch({
                type: "SET_NOTIFICATION_LOADING",
                payload: false,
              });
            }),
          dispatch(updatePendingActionNotificationCount()),
          dispatch(checkMemos(user)),
          // dispatch(setFranchiseRequests()),

          getLoadingReportList({
            receiver: user,
            latest: true,
          }).then((loadingReportList) =>
            dispatch({
              type: "INIT:SET_LOADING_REPORTS",
              payload: loadingReportList,
            })
          ),

          dispatch(setFranchiseRequestsNotifications()),
          dispatch(updateIncentive()),
          dispatch(CCAvenueTxnsCount({
            user: response?.data?.response?.user?._id,
            countTypes: ["notSettled"]
          }))
        ]);
      dispatch({
        type: "END_LOADER",
      });

      if (receivedRights.verification.verifiedPackages === true) {
        getStockCount().then((stockResponse) =>
          dispatch(updateVerifyStockBadges(stockResponse))
        );
      }

      if (receivedRights.verification.controlTower === true) {
        dispatch(graceToweCount());
      }

      if (receivedAppRights.verification.pettyCash === true) {
        getTableCashCount().then((cashResponse) =>
          dispatch(updatePettyCashBadges(cashResponse.tableCashCount))
        );
      }

      if (receivedRights.verification.verifySettledAmount === true) {
        getShortageCount().then((shortageResponse) =>
          dispatch(updateVerifyShortageBadges(shortageResponse))
        );
      }

      // let OperationRightResponse = await getState().rights.operations.backToHub;
      // if (OperationRightResponse === true) {
      //   // let shortageResponse = await get();
      //   // // dispatch(updateVerifyShortageBadges(shortageResponse));
      //   dispatch(OperationsRequestCount());
      // }

      if (receivedRights.verification.expense === true) {
        dispatch(updateVerifyExpenseBadges());
      }

      if (receivedRights.verification.pod.verifyPOD === true) {
        fetchUnverifiedDocketCount().then((res) =>
          dispatch(updatePodCount(res))
        );
      }

      dispatch({
        type: "SET_ALLOWED_ATTENDANCE_FROM _ANYWHEWRE",
        payload:
          response &&
            response.data.response.user &&
            response.data.response.user.userType &&
            response.data.response.user.userType.allowAttendanceFromAnywhere
            ? true
            : false,
      });
    } catch (err:any) {
      if (err.response) {
        dispatch({
          type: "LOGIN_FAIL",
          payload:
            err.response.data.message
              ? err.response.data.message
              : "Invalid credentials!",
        });
      } else if (err.request) {
        dispatch({
          type: "LOGIN_FAIL",
          payload:
            err.message == "Network Error" ? "No Internet!" : err.message,
        });
      } else {
        dispatch({
          type: "LOGIN_FAIL",
          payload: err.message,
        });
      }
      dispatch({
        type: "END_LOADER",
      });
    }
  };
};

export const fetchUserAllBalances = (id: any) => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    userAllBalancesService(id)
      .then((res) => {
        let response = {
          ...res,
          fleets: getState().fleet.get('fleets'),
          branches: getState().branch.branchMap,
        };
        dispatch({ type: "USER_ALL_BALANCES", payload: response });
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const logOut = () => async (dispatch: Dispatch) => {
  socket.emit("logout", sessionStorage.getItem("kabra:token"));
  dispatch({ type: "LOG_OUT" });
  navigator.geolocation.clearWatch(watchLocationId);
};

export const loadMoreNotifications = () => async (
  dispatch: any,
  getState: () => RootState
) => {
  try {
    const notifications = getState().user.notifications;
    const offset = (notifications && notifications.length) || 0;
    const newNoti = await getNotificationService({ offset, limit: 20 });
    await dispatch({
      type: "SET_NOTIFICATION_LOADING",
      payload: true,
    });
    dispatch({
      type: "SET_NOTIFICATION_FULFILLED",
      payload: {
        notifications: newNoti,
        notificationLoadMore: newNoti.length === 20,
        offset,
      },
    });
    dispatch(updatePendingActionNotificationCount());
  } catch (err : any) {
    dispatch({
      type: "SET_NOTIFICATION_LOADING",
      payload: false,
    });
  }
};

export const updatePendingActionNotificationCount = () => async (
  dispatch: Dispatch
) => {
  getPendingActionNotificationCount()
    .then((count) =>
      dispatch({
        type: "SET_NOTIFICATION_PENDING_ACTION_COUNT",
        payload: count,
      })
    )
    .catch((err) => {
      console.log(err);
    });
};

export const clearSelectedEntity = () => {
  return async (dispatch: any) => {
    try {
      await dispatch({ type: "CLEAR_ENTITY" });
    } catch (err : any) { }
  };
};

export const individualOpBranch = (id: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { user } = getState();
      const payload = {
        user: user.uid,
        sub: "B",
        entity: id.value,
        company: user.company._id,
      };
      try {
        socket.emit("branchLogin", payload);
      } catch (err : any) { }
      await dispatch({ type: "INDIVISUAL_OP_BRANCH", payload: id });
      dispatch({ type: SET_SENDER_ADDRESS_FROM_OPBRANCH });

      // dispatch(updateOperationsAllCount());
    } catch (err : any) { }
  };
};

export const selectOperatingBranch = (id: any) => {
  return async (dispatch: any, getState: () => RootState) => {
    try {
      const { user } = getState();
      const payload = {
        user: user.uid,
        sub: "B",
        entity: id.value,
        company: user.company._id,
      };
      try {
        socket.emit("branchLogin", payload);
      } catch (err : any) { }
      await dispatch({ type: "SEL_OP_BRANCH", payload: id });

      dispatch(updateOperationsAllCount());
      // await dispatch(fetchBalance(payload))
    } catch (err : any) { }
  };
};
//login fleet functionality
export const selectOperatingFleet = (id: any) => async (dispatch: any) => {
  try {
    await dispatch({ type: "SEL_OP_FLEET", payload: id });
    dispatch(resetOperatingCount());
    // await dispatch(fetchBalance(payload))
  } catch (err : any) { }
};

export const showAllBalanceModal = () => ({ type: "SHOW_BALANCE_DIALOG" });

export const hideAllBalanceModal = () => ({ type: "HIDE_BALANCE_DIALOG" });

export const getDepartments = (data: any) => {
  return async (dispatch: Dispatch) => {
    let response = axios.post(url + "/user/departments/" + data.uid, data);
    response
      .then((result:any) => {
        if (result.data.status === 1) {
          dispatch({
            type: "ASS_DEP_SUCCESS",
            payload: result.data,
          });
          dispatch({
            type: "ASS_USER",
            payload: result.data.response._id,
          });
        } else
          dispatch({
            type: "ASS_DEP_FAIL",
          });
      })
      .catch(() => {
        dispatch({
          type: "ASS_DEP_FAIL",
        });
      });
  };
};

export const getFleets = (data: any) => {
  return async (dispatch: Dispatch) => {
    let response = axios.post(url + "/user/fleets/" + data.uid, data);
    response
      .then((result:any) => {
        if (result.data.status === 1) {
          dispatch({
            type: "ASS_FLEET_SUCCESS",
            payload: result.data,
          });
          dispatch({
            type: "ASS_USER",
            payload: result.data.response._id,
          });
        } else
          dispatch({
            type: "ASS_DEP_FAIL",
          });
      })
      .catch(() => {
        dispatch({
          type: "ASS_DEP_FAIL",
        });
      });
  };
};

export const addDepartment = (name: any) => {
  return {
    type: "ADD_DEP",
    payload: name,
  };
};

export const removeDepartment = (name: any) => {
  return {
    type: "REM_DEP",
    payload: name,
  };
};

export const getBranches = (data: any) => {
  return async (dispatch: Dispatch) => {
    let response = axios.post(url + "/user/branches/" + data.uid, data);
    response
      .then((data:any) => {
        if (data.data.status === 1) {
          dispatch({
            type: "ASS_BR",
            payload: data.data.response.branches,
          });
          dispatch({
            type: "ASS_USER",
            payload: data.data.response._id,
          });
        }
      })
      .catch(() => { });
  };
  // return {
  //   type: "FETCH_BRANCH",
  //   payload: {
  //     rbranches: rBrArr.slice(),
  //     tbranches: brArr.slice()
  //   }
  // }
};

export const getRoutes = () => {
  return async (dispatch: any) => {
    try {
      let response = await fetchRouteForExpense();
      dispatch({ type: GET_ROUTES, payload: response });
    } catch (error) { }
  };
};

export const assignAddBranch = (data: any) => {
  return {
    type: "ASS_ADD_BRANCH",
    payload: data,
  };
};

export const assignAddFleet = (data: any) => {
  return {
    type: "ASS_ADD_FLEET",
    payload: data,
  };
};

export const assignRemoveFleet = (num: any) => {
  return {
    type: "ASS_REM_FLEET",
    payload: num,
  };
};

export const assignBranchReq = (data: any) => {
  return async (dispatch: Dispatch) => {
    let response = axios.post(url + "/user/assignbranch", data);
    response
      .then((result:any) => {
        if (result.data.status === 1) {
          dispatch({
            type: "BRANCH_ASSIGN_SUCCESS",
            payload: data,
          });
        } else
          dispatch({
            type: "BRANCH_ASSIGN_FAIL",
          });
      })
      .catch(() => {
        dispatch({
          type: "BRANCH_ASSIGN_FAIL",
        });
      });
  };
};

export const assignDepartReq = (data: any) => {
  return async (dispatch: Dispatch) => {
    let response = axios.post(url + "/user/assigndepart", data);
    response
      .then((result:any) => {
        if (result.data.status === 1) {
          dispatch({
            type: "DEP_ASSIGN_SUCCESS",
            payload: data,
          });
        } else
          dispatch({
            type: "DEP_ASSIGN_FAIL",
          });
      })
      .catch(() => {
        dispatch({
          type: "DEP_ASSIGN_FAIL",
        });
      });
  };
};

export const removeBranch = (name: any) => {
  return {
    type: "REM_BRANCH",
    payload: name,
  };
};

export const addDest = () => {
  return async (dispatch: Dispatch) => {
    try {
      const data = getDest();
      if (data == -1) throw "Create a valid milestone";
      await dispatch({
        type: "ADD_DEST",
        payload: data,
      });
    } catch (err : any) { }
  };
};

export const suggBranch = (data: any, sugg: any) => {
  return {
    type: "SUGG_BR",
    payload: {
      data: data,
      sugg: sugg,
    },
  };
};

// export const fetchBranches = (city: any, sugg: any) => {
//   return async (dispatch: Dispatch) => {
//     try {
//       const response: any = dispatch({
//         type: "FILTER",
//         payload: axios({
//           method: "get",
//           url: url + "/branch/filter/" + city,
//         }),
//       });

//       response.then(async (data: any) => {
//         await dispatch(suggBranch(data.value.data.response, sugg));
//       });
//     } catch (err : any) { }
//   };
// };

// export const changeCityRoute = (city: any, order: any) => {
//   return async (dispatch: Dispatch) => {
//     try {
//       await dispatch({
//         type: "ROUTE_CITY",
//         payload: { order: order, name: city },
//       });
//       const elem: any = document.querySelector(
//         "#city" + order + ' option[value="' + city + '"]'
//       );
//       if (typeof elem === null) {
//       } else {
//         let id = elem.dataset.value;
//         const response: any = dispatch({
//           type: "FILTER",
//           payload: axios({
//             method: "get",
//             url: url + "/branch/filter/" + id,
//           }),
//         });
//         response.then(async (data: any) => {
//           if (data.value.data.status)
//             await dispatch({
//               type: "ROUTE_SUGG",
//               payload: { data: data.value.data.response, order: order },
//             });
//         });
//       }
//     } catch (err : any) { }
//   };
// };

export const changeBranchRoute = (branch: any, order: any) => {
  return async (dispatch: Dispatch) => {
    await dispatch({
      type: "ROUTE_BRANCH",
      payload: { order: order, name: branch },
    });
  };
};

export const fetchAllBranches = (sugg: any) => {
  return (dispatch: Dispatch) => {
    try {
      const response: any = dispatch({
        type: "ALL_BRANCHES",
        payload: axios({
          method: "get",
          url: url + "/branch/all/",
        }),
      });

      response.then((data: any) => {
        dispatch(brSearch(data.value.data.response, sugg));
      });
    } catch (err : any) { }
  };
};

export const allfleets = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const response: any = dispatch({
        type: "ALL_BRANCHES",
        payload: axios({
          method: "post",
          url: url + "/fleet/all/",
          data: data,
        }),
      });

      response.then(async (data: any) => {
        await dispatch({
          type: "ALL_FLEETS",
          payload: data.value.data.response,
        });
      });
    } catch (err : any) { }
  };
};

export const fetchCity = (like: any, sugg: any) => {
  return (dispatch: Dispatch) => {
    const response: any = dispatch({
      type: "FILTER",
      payload: axios({
        method: "post",
        url: url + "/city/filter/" + like,
      }),
    });

    response.then((data: any) => {
      dispatch(suggCity(data.value.data.response, sugg));
    });
  };
};

export const suggCity = (data: any, sugg: any) => {
  return {
    type: "SUGG_CITY",
    payload: { data: data, sugg: sugg },
  };
};

export const brSearch = (data: any, sugg: any) => {
  return {
    type: "BR_SEARCH",
    payload: {
      sugg: sugg,
      data: data,
    },
  };
};

//rate creation module
export const getAllRoutes = (data: any) => {
  return async (dispatch: Dispatch) => {
    let { uid, cid, token } = data;
    let response = await axios.post(url + "/route/company", {
      user: uid,
      companyId: cid,
      token,
    });
    if (response.data.status != 1) throw "Could not fetch routes";
    await dispatch({
      type: "SET_ALL_ROUTES",
      payload: response.data.response,
    });
  };
};

export const filterRoute = (like: any, sugg: any) => {
  return (dispatch: Dispatch) => {
    const response: any = dispatch({
      type: "FILTER_ROUTE",
      payload: axios({
        method: "post",
        url: url + "/route/filter/" + like,
      }),
    });

    response.then((data: any) => {
      dispatch({
        type: "SUGG_ROUTE",
        payload: { response: data.value.data.response, sugg: sugg },
      });
    });
  };
};

export const getRoute = (id: any) => {
  return {
    type: "GET_ROUTE",
    payload: axios({
      method: "get",
      url: url + "/route/view/" + id,
    }),
  };
};

export const fetchRate = (id: any) => {
  return {
    type: "SELECT_RATE",
    payload: id,
  };
};

export const addRate = (id: any) => {
  const elem1: any = document.getElementById("from");
  const elem2: any = document.getElementById("to");
  const elem3: any = document.getElementById("price");
  let rate: any;
  if (typeof elem1 !== null && typeof elem2 !== null && typeof elem3 !== null) {
    rate = {
      min: elem1.value,
      max: elem2.value,
      price: elem3.value,
    };
  }
  return {
    type: "ADD_RATE",
    payload: { id: id, rate: rate },
  };
};

export const addRateByType = (id: any) => {
  const elem1: any = document.getElementById("ptype");
  const elem2: any = document.getElementById("pprice");
  let rate: any;
  if (typeof elem1 !== null && typeof elem2 !== null) {
    rate = {
      type: elem1.value,
      price: elem2.value,
    };
  }
  return {
    type: "ADD_RATE_TYPE",
    payload: { id: id, rate: rate },
  };
};

export const updateFrom = (id: any, i: any, value: any) => {
  return {
    type: "FROM",
    payload: {
      id: id,
      i: i,
      value: value,
    },
  };
};

export const updateTo = (id: any, i: any, value: any) => {
  return {
    type: "TO",
    payload: {
      id: id,
      i: i,
      value: value,
    },
  };
};

export const updatePrice = (id: any, i: any, value: any) => {
  return {
    type: "PRICE",
    payload: {
      id: id,
      i: i,
      value: value,
    },
  };
};

export const updatePriceByType = (id: any, i: any, value: any) => {
  return {
    type: "PRICE_TYPE",
    payload: {
      id: id,
      i: i,
      value: value,
    },
  };
};

export const updateType = (id: any, i: any, value: any) => {
  return {
    type: "TYPE",
    payload: {
      id: id,
      i: i,
      value: value,
    },
  };
};

export const createRate = (data: any) => {
  return (dispatch: Dispatch) => {
    const response: any = dispatch({
      type: "CREATE_RATE",
      payload: axios({
        method: "post",
        url: url + "/rate/create/",
        data: data,
      }),
    });

    response.then(() => { });
  };
};

//fuel module
export const openKM = (value: any) => {
  return {
    type: "OPEN_KM",
    payload: value,
  };
};

export const closeKM = (value: any) => {
  return {
    type: "CLOSE_KM",
    payload: value,
  };
};

export const setPPL = (value: any) => {
  return {
    type: "PPL",
    payload: value,
  };
};

// export const updateLoc = (bid, i, branchName, isNew) => {
//   return (dispatch) => {
//     const response = dispatch({
//       type: "GET_BRANCH_DUMMY",
//       payload: axios({
//         method: "get",
//         url: url + "/branch/view/" + bid,
//       }),
//     });
//     if (!isNew) {
//       response.then((data) => {
//         let { latitude, longitude } = data.value.data.response.location;
//         dispatch({
//           type: "LOC_UPDATE",
//           payload: {
//             lat: latitude,
//             lng: longitude,
//             order: i,
//             branchName: branchName,
//           },
//         });
//       });
//     }
//   };
// };
// export const updateEditLoc = (bid, i, branchName, isNew) => {
//   return (dispatch) => {
//     const response = dispatch({
//       type: "GET_BRANCH_DUMMY",
//       payload: axios({
//         method: "get",
//         url: url + "/branch/view/" + bid,
//       }),
//     });
//     if (!isNew) {
//       response.then((data) => {
//         let { latitude, longitude } = data.value.data.response.location;
//         dispatch({
//           type: "LOC_EDIT_UPDATE",
//           payload: {
//             lat: latitude,
//             lng: longitude,
//             order: i,
//             branchName: branchName,
//           },
//         });
//       });
//     }
//   };
// };
// export const updateNewLoc = (bid, i, branchName) => {
//   return (dispatch) => {
//     const response = dispatch({
//       type: "GET_BRANCH_DUMMY",
//       payload: axios({
//         method: "get",
//         url: url + "/branch/view/" + bid,
//       }),
//     });

//     response.then((data) => {
//       let { latitude, longitude } = data.value.data.response.location;
//       dispatch({
//         type: "NEW_LOC_UPDATE",
//         payload: {
//           lat: latitude,
//           lng: longitude,
//           order: i,
//           branchName: branchName,
//           bid: bid,
//         },
//       });
//     });
//   };
// };
// export const updateNewEditLoc = (bid, i, branchName) => {
//   return (dispatch) => {
//     const response = dispatch({
//       type: "GET_BRANCH_DUMMY",
//       payload: axios({
//         method: "get",
//         url: url + "/branch/view/" + bid,
//       }),
//     });

//     response.then((data) => {
//       let { latitude, longitude } = data.value.data.response.location;
//       dispatch({
//         type: "NEW_EDIT_LOC_UPDATE",
//         payload: {
//           lat: latitude,
//           lng: longitude,
//           order: i,
//           branchName: branchName,
//           bid: bid,
//         },
//       });
//     });
//   };
// };
export const selectRoute = (order: any) => {
  return {
    type: "SELECT_FUEL_ROUTE",
    payload: { order: order },
  };
};
export const addPlace = (data: any) => {
  return {
    type: "ADD_PLACE",
    payload: data,
  };
};
export const updateMap = (i: any) => {
  return {
    type: "UPDATE_MAP",
    payload: { i: i },
  };
};
export const filterFuel = (data: any) => {
  return async (dispatch: Dispatch) => {
    if (data.uid) {
      let fuels = await axios({
        method: "post",
        url: url + "/fuel/filter/" + data.uid,
        data: data,
      });
      await dispatch({
        type: "SET_FUELS_EDIT",
        payload: fuels.data.response,
      });
    } else {
      let fuels = await axios({
        method: "post",
        url: url + "/fuel/filter/-1",
        data: data,
      });
      await dispatch({
        type: "SET_FUELS_EDIT",
        payload: fuels.data.response,
      });
    }
  };
};
export const changeVnum = (data: any) => {
  return {
    type: "FUEL_VNUM",
    payload: data,
  };
};
export const changeFuelContact = (data: any) => {
  return {
    type: "FUEL_CONTACT",
    payload: data,
  };
};
export const editFuelVnum = (data: any) => {
  return {
    type: "EDIT_FUEL_VNUM",
    payload: data,
  };
};
export const editFuelContact = (data: any) => {
  return {
    type: "EDIT_FUEL_CONTACT",
    payload: data,
  };
};
export const editFuelOpen = (data: any) => {
  return {
    type: "EDIT_FUEL_OPEN",
    payload: data,
  };
};
export const editFuelClose = (data: any) => {
  return {
    type: "EDIT_FUEL_CLOSE",
    payload: data,
  };
};
export const editFuelAmt = (data: any) => {
  return {
    type: "EDIT_FUEL_AMT",
    payload: data,
  };
};
export const editFuelPPL = (data: any) => {
  return {
    type: "EDIT_FUEL_PPL",
    payload: data,
  };
};

export const addEditPlace = (data: any) => {
  return {
    type: "ADD_EDIT_PLACE",
    payload: data,
  };
};
export const deleteEditPlace = (i: any) => {
  return {
    type: "DEL_EDIT_PLACE",
    payload: parseInt(i, 10),
  };
};
export const changeFuelFilterVnum = (data: any) => {
  return {
    type: "FUEL_FILTER_VNUM",
    payload: data,
  };
};
export const changeFuelFilterContact = (data: any) => {
  return {
    type: "FUEL_FILTER_CONTACT",
    payload: data,
  };
};
export const changeFuelFromDate = (data: any) => {
  return {
    type: "FUEL_FROM",
    payload: data,
  };
};
export const changeFuelToDate = (data: any) => {
  return {
    type: "FUEL_TO",
    payload: data,
  };
};
export const selectFuel = (_id: any) => {
  return {
    type: "SELECT_FUEL",
    payload: { id: _id },
  };
};
export const setSelectedPPL = (value: any) => {
  return {
    type: "SET_SELECTED_PPL",
    payload: value,
  };
};
export const fuelReport = (data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: "FUEL_REP",
      payload: axios({
        url: url + "/fuel/report/" + data.uid,
        method: "POST",
        responseType: "blob", // important
        data: data,
      }),
    });
  };
};
export const generateReport = (data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: "FUEL_REP",
      payload: axios({
        url: url + "/fuel/genericReport",
        method: "POST",
        responseType: "blob", // important
        data: data,
      }),
    });
  };
};

//Edit Branch Select
export const selectEditBranch = (bid: any) => {
  return async (dispatch: Dispatch) => {
    try {
      let response = await axios(url + "/branch/view/" + bid);
      if (response.data.status === 1) {
        await dispatch({
          type: "BRANCH_EDIT_FETCH",
          payload: response.data.response,
        });
        await dispatch({
          type: "TRANS",
          payload: response.data.response.transhipment,
        });
        await dispatch({
          type: "FRANC",
          payload: response.data.response.franchise,
        });
      } else throw "Something went wrong";
    } catch (err : any) { }
  };
};

export const updateTransMin = (value: any, i: any, t: any) => {
  return {
    type: "UPDATE_TR_MIN",
    payload: { value: value, i: i, t: t },
  };
};
export const updateTransMax = (value: any, i: any, t: any) => {
  return {
    type: "UPDATE_TR_MAX",
    payload: { value: value, i: i, t: t },
  };
};
export const updateTransPrice = (value: any, i: any, t: any) => {
  return {
    type: "UPDATE_TR_PRICE",
    payload: { value: value, i: i, t: t },
  };
};
export const addTrans = (slab: any, t: any) => {
  return {
    type: "ADD_TRANS",
    payload: { slab: slab, t: t },
  };
};
export const updateFrBookMin = (value: any, i: any, t: any) => {
  return {
    type: "UPDATE_FR_BOOK_MIN",
    payload: { value: value, i: i, t: t },
  };
};
export const updateFrBookMax = (value: any, i: any, t: any) => {
  return {
    type: "UPDATE_FR_BOOK_MAX",
    payload: { value: value, i: i, t: t },
  };
};
export const updateFrBookPrice = (value: any, i: any, t: any) => {
  return {
    type: "UPDATE_FR_BOOK_PRICE",
    payload: { value: value, i: i, t: t },
  };
};
export const addBook = (slab: any, t: any) => {
  return {
    type: "ADD_BOOK",
    payload: { slab: slab, t: t },
  };
};
export const updateFrDelMin = (value: any, i: any, t: any) => {
  return {
    type: "UPDATE_FR_DEL_MIN",
    payload: { value: value, i: i, t: t },
  };
};
export const updateFrDelMax = (value: any, i: any, t: any) => {
  return {
    type: "UPDATE_FR_DEL_MAX",
    payload: { value: value, i: i, t: t },
  };
};
export const updateFrDelPrice = (value: any, i: any, t: any) => {
  return {
    type: "UPDATE_FR_DEL_PRICE",
    payload: { value: value, i: i, t: t },
  };
};
export const addDel = (slab: any, t: any): any => {
  return {
    type: "ADD_DEL",
    payload: { slab: slab, t: t },
  };
};
export const changePPF = () => {
  return {
    type: "PPF",
  };
};
export const changeKGW = () => {
  return {
    type: "KGW",
  };
};
export const changeFRPPF = () => {
  return {
    type: "FR_PPF",
  };
};
export const changeFRKGW = () => {
  return {
    type: "FR_KGW",
  };
};

// not used and module not used where this is imported.
export const getUserByContact = (data: any) => {
  let { contact } = data;
  return async (dispatch: Dispatch) => {
    let response = axios.post(url + "/user/viewbycontact/" + contact, data);
    response
      .then((data: any) => {
        if (data.data.status === 1) {
          dispatch({
            type: "GET_USER_BY_CONTACT",
            payload: data.data.response,
          });
        } else {
          dispatch({
            type: "GET_NAME_BY_CONTACT_FAIL",
          });
          throw "status fail";
        }
      })
      .catch(() => { });
  };
};

//booking edit sub module

export const resetEdit = (data?: any) => ({ type: RESET_EDIT, payload: data });

//loading module

//unloading module

export const fetchingUnload = () => ({ type: "UNLOAD_PENDING" });

export const unloadReq = (data: any) => {
  return async (dispatch: any) => {
    try {
      let { user, ...rest } = data;
      window.scrollTo(0, 0);
      let response = await unloadPackages(rest);
      await dispatch({
        type: "UNLOAD_SUCCESS",
        payload: response,
      });
    } catch (err : any) {
      console.log(err);

      dispatch({ type: HIDE_LOADER });
      // await dispatch({
      //     type: UNLOAD_FAIL,
      //     payload: {
      //         reasons: [{ message: 'Could not unload packages' }]
      //     }
      // })
    }
  };
};

export const generateFedexPickup = (data: any) => async (dispatch: any) => {
  try {
    let { packages } = data;
    window.scrollTo(0, 0);
    let { fedexPickupNumber } = await fedexCreatePickup({ packages });
    await unloadPackages();

    await dispatch({
      type: "FEDEX_PICKUP_SUCCESS",
      payload: { fedexPickupNumber, packages },
    });
    // await dispatch(updateAllDockets())
    // await dispatch(closeLoadModal())
  } catch (err:any) {
    showMessage(
      err && err.message ? err.message : " Pickup Request failed.",
      "salmon"
    );
    // dispatch({
    //     type: FEDEX_PICKUP_FAIL,
    //     payload:
    // })
    // dispatch(closeLoadModal())
  }
};

export const changeStartDate = (data: any) => ({
  type: "CHANGE_START_DATE",
  payload: data,
});
export const changeEndDate = (data: any) => ({
  type: "CHANGE_END_DATE",
  payload: data,
});
export const changeRange = (data: any) => ({
  type: "CHANGE_RANGE",
  payload: data,
});
export const changeColor = (data: any) => ({
  type: "CHANGE_COLOR",
  payload: data,
});

export const getMyUnPackages = (payload: any) => {
  return async (dispatch: any) => {
    try {
      await dispatch({ type: SHOW_LOADER });
      let response = await axios.post(url + "/booking/unload", payload);
      let { status, response: packages } = response.data;
      if (status != 1) throw "Could not get packages";
      await dispatch({
        type: "UNLOADING_DATA_FOR_POPUP",
        payload: response.data.response,
      });
      await dispatch({
        type: "SET_UN_PACKAGES",
        payload: await makeDocketMap(packages),
        concern: "/unloading",
      });
      dispatch({ type: HIDE_LOADER });
    } catch (err : any) {
      dispatch({ type: HIDE_LOADER });
    }
  };
};
// packages that are intransit but will be delivered by current branch
export const getMyUnPackagesForDestBranch = () => {
  return async (dispatch: any, getState: any) => {
    try {
      const sub = getState().user.loginType;
      if (sub == "B") {
        const branch = getState().user.opBranch._id;
        const startDate = getState().user.startDate;
        const endDate = getState().user.endDate;
        const dateRange = getState().user.dateRange.value;
        const colorFilter = getState().user.colorFilter.value;
        await dispatch({ type: SHOW_LOADER });
        const response = await axios.post(
          url + "/booking/unload/by/destBranch",
          // { branch, startDate, endDate }
          { branch, startDate, endDate, dateRange, colorFilter }
        );
        const { status, response: packages } = response.data;
        if (status != 1) throw "Could not get packages";
        await dispatch({
          type: "UNLOADING_DATA_FOR_POPUP",
          payload: response.data.response,
        });
        await dispatch({
          type: "SET_UN_PACKAGES_FOR_DESTBRANCH",
          payload: await makeDocketMap(packages),
        });
        dispatch({ type: HIDE_LOADER });
      }
    } catch (err : any) {
      dispatch({ type: HIDE_LOADER });
    }
  };
};
export const filterDocketsByDest = (data: any) => ({
  type: "DEST_BRANCH_FILTER",
  payload: data,
});

export const filterDocketsCity = (data: any) => ({
  type: "DEST_CITY_FILTER",
  payload: data,
});

export const resetUnloadPackages = () => async (dispatch: any) => {
  await dispatch({ type: "REFRESH_UNLOAD_PACKAGES" });
};

export const getMyOdaPackages = (payload: any, noloader?: any) => {
  return async (dispatch: any) => {
    try {
      if (!noloader) await dispatch({ type: SHOW_LOADER });
      // await dispatch(fetchingUnload());
      const response = await getOutForDeliveryData(payload);

      await dispatch({
        type: "OUT_FOR_DELIVERY_PACKAGES_REGNUMWISE",
        payload: { packages: response },
      });
      dispatch({ type: HIDE_LOADER });
    } catch (err : any) {
      dispatch({ type: HIDE_LOADER });
    }
  };
};
export const clearReNumwiseDocket = () => ({
  type: "CLEAR_REGNUM_WISE_DOCKET",
});

export const deliveryStart = () => ({ type: DELIVERY_PENDING });

export const deliveryEnd = () => ({ type: DELIVERY_SUCCESS });

export const delAmt = (val: any) => ({ type: "DEL_AMT", payload: val });

export const getBookingDelivery = (data: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const sub = getState().user.loginType;
      const entity =
        getState().user.loginType === "B"
          ? getState().user.opBranch._id
          : getState().user.opFleet._id;
      const payload = {
        docket: data.docketNo,
        sub: sub,
        entity: entity,
        queryType: "delivery",
        old: false,
      };
      let response = await fetchDocketDetails(payload);

      if (response.data.status !== 1) throw "No such docket number found";
      await dispatch({
        type: "DELIVERY_SET",
        payload: response.data.response,
      });
    } catch (err : any) { }
  };
};

export const changeDelFr = (val: any) => ({ type: "DEL_FR", payload: val });

export const changeDelMode = (val: any) => ({ type: "DEL_MODE", payload: val });

export const changeDelRate = (val: any) => ({ type: "DEL_RATE", payload: val });

export const setDocket = (payload?: any, func?: any, args?: any) => {
  let deliveryFlag = /delivery/.test(args);
  return async (dispatch: any, getState: () => RootState) => {
    try {
      let { packages, isDirectUrl } = payload;

      if (packages.length < 1) {
        // showErrorMessage("this docket has no packages.");
        showMessage("this docket has no packages.", failed);
        return;
      }
      if (!getState().user.allCities) {
        await dispatch(setCities());
      }
      let { originBranch, destCity } = packages[0];
      if (
        typeof originBranch == "object" &&
        originBranch.hasOwnProperty("_id")
      ) {
        originBranch = originBranch._id;
      }
      if (typeof destCity == "object" && destCity.hasOwnProperty("_id")) {
        destCity = destCity._id;
      }
      let across = await getAcrossService(
        originBranch,
        payload.docket.isFedexBooking
          ? payload.docket.destBranch.address.pincode
          : destCity,
        isDirectUrl
      );

      await dispatch({ type: SET_ACROSS, payload: across });
      const { allcities } = getState().user;
      dispatch({
        type: SET_DOCKET,
        payload: {
          ...payload,
          // routes,
          delivery: deliveryFlag,
          allcities,
        },
      });
      if (typeof func === "function") {
        func(args);
      }
    } catch (err : any) {
      console.log("errBooking :", err)
    }
  };
};

export const setTrackingnumber = (data: any) => ({
  type: "SET_TRACKING_NUMBER",
  payload: data,
});

export const showLoader = () => ({ type: SHOW_LOADER });

export const hideLoader = () => ({ type: HIDE_LOADER });

export const handleSoundStart = () => ({
  type: "UPDATE_SOUND_START",
});

export const handleSoundStop = () => ({
  type: "UPDATE_SOUND_STOP",
});

export const outForDeliveryRemark = (data: any, cb: any, setRemarks: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      let { user, ...rest } = data;
      // throw 'Yuhi'
      await axios.post(url + "/package/remark", jsonToFormdata(rest));
      const company = getState().user.company._id,
        branch = getState().user.opBranch._id;

      let packagePayload = {
        token: getState().user.token,
        uid: getState().user.uid,
        sub: getState().user.loginType,
        entity:
          getState().user.loginType == "B"
            ? getState().user.opBranch._id
            : getState().user.opFleet._id,
        company,
        branch,
      };
      await dispatch(getMyOdaPackages(packagePayload, true));
      await setRemarks();
      cb();
    } catch (error) { }
  };
};

export const returnODAPackages = (data: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(showLoader());
      await returnODAPackagesService(data);

      let packagePayload = {
        sub: getState().user.loginType,
        entity:
          getState().user.loginType == "B"
            ? getState().user.opBranch._id
            : getState().user.opFleet._id,
      };
      await dispatch(getMyOdaPackages(packagePayload, true));
      dispatch(hideLoader());
    } catch (error) {
      showMessage("Something went wrong!", "salmon");
      dispatch(hideLoader());
    }
  };
};

export const nextPrevPackageUnloading = (data: any) => ({
  type: "NEXT_PREV_PACKAGE_UNLOADING",
  data,
});

export const addMaxBalance = (data: any) => ({
  type: "MAX_BALANCE",
  payload: data,
});

export const updateBookingCounters = () => {
  return async (dispatch: any, getState: any) => {
    try {
      const user = getState().user;
      if (user.loginType) {
        const sub = user.loginType;
        const entity =
          user.loginType === "B" ? user.opBranch._id : user.opFleet._id;
        const result = await fetchBranchwiseBookingCounters({ sub, entity });
        dispatch({
          type: "CHANGE_BOOKING_COUNTERS",
          payload: result,
        });
      }
    } catch (err : any) { }
  };
};
export const changeAttendanceCount = (data: any) => ({
  type: "CHANGE_ATTENDANCE_COUNT",
  payload: data,
});
export const setLatitude = (data: any) => ({
  type: "SET_LATITUDE",
  payload: data,
});
export const setLongitude = (data: any) => ({
  type: "SET_LONGITUDE",
  payload: data,
});
export const setAttendance = (data: any) => ({
  type: "SET_ATTENDANCE",
  payload: data,
});
export const changeAttendencePopup = () => ({
  type: "SET_ATTENDENCE_POPUP",
});
export const verifyRemarks = (data: any) => ({
  type: "SET_VERIFY_REMARKS",
  payload: data,
});

export const sidebarUpdateAck = () => ({ type: "SIDEBAR:UPDATE_ACK" });

export const setRoutesAction = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      let routes = await listByCompany(getState().user.company.id);
      dispatch({ type: SET_COMPANY_ROUTES, payload: routes });
    } catch (err : any) { }
  };
};

export const setBreakInBreakOutDialogOpen = (data: boolean) => ({
  type: "SET_BREAK_IN_BREAK_OUT_DIALOG",
  payload: data,
});
