// react
import React, { useState } from "react";
// context
import { useLandingPageContext } from "../../../../hooks/useLandingPageContext";
// constants (data | icons)
import { faqs, faqsData } from "./data";
import { DownIcon, UpIcon } from "../../../../constants/icons";
// components
import { Heading } from "../../../../components/UI";

// FC: FAQ Component
const FAQ = () => {
  // State
  const [activeFAQ, setActiveFAQ] = useState(0);
  const {
    activePage,
    activeService,
    activePartner,
    activeExpressParcelDeliveryType,
  } = useLandingPageContext();
  // data
  const data =
    faqsData.find((faq) => {
      if (faq.service === "Express Parcel") {
        return faq.expressParcelType === activeExpressParcelDeliveryType;
      }
      if (faq.page === activePage) {
        if (faq.service === activeService) {
          return true;
        }
        if (faq.partner === activePartner) {
          return true;
        }
      }
      return false;
    })?.faqs || faqs;

  // component return
  return (
    <main className="faqs | p-3 bg-white-800">
      <section
        className="| flex-col gap-component my-container"
        style={{ marginBlock: "auto" }}
      >
        <Heading className="text-primary-400">
          Frequently Asked Questions
        </Heading>
        <ul className="| small-container flex-col gap-1 m-0 mx-auto">
          {data &&
            Array.isArray(data) &&
            data.length > 0 &&
            data.map((item, index) => (
              <li
                key={item.question}
                className={`| text-neutral-900 px-2 py-1 br-4 shadow-small pointer`}
                style={{
                  backgroundColor: `${
                    activeFAQ === index
                      ? "var(--clr-white-700)"
                      : "var(--clr-white-900)"
                  }`,
                }}
                onClick={() => {
                  if (activeFAQ === index) {
                    setActiveFAQ(-1);
                  } else {
                    setActiveFAQ(index);
                  }
                }}
              >
                <div className="| text-neutral-900 fs-list-item-heading flex-between gap-8">
                  <span className="| fw-600">{item.question}</span>
                  <span className="| flex-shrink-0">
                    {activeFAQ === index ? (
                      <UpIcon onClick={() => setActiveFAQ(-1)} />
                    ) : (
                      <DownIcon onClick={() => setActiveFAQ(index)} />
                    )}
                  </span>
                </div>
                {activeFAQ === index && (
                  <div className="faq-answer | m-0 fs-list-item-text fw-400 py-8">
                    {item.answer}
                  </div>
                )}
              </li>
            ))}
        </ul>
      </section>
    </main>
  );
};

export default FAQ;
