import React from 'react'
import "./grid.css";

interface IPropsvalue {
  alignItems?: string;
  justifyContent?: string;
  direction?: string;
  wrap?: string;
  className?: string;
  container?: any;
  lg?: any;
  md?: any;
  sm?: any;
  xs?: any;
  xl?: any;
  spacing?: any;
  children?: any;
  style?: React.CSSProperties;
  item?: any;
  onClick?: any;
  ref?:any
}

const Grid = (props:IPropsvalue) => {
  
    return (
      <div  
      style={{
        ...(props.alignItems ? {alignItems:props.alignItems} : null),
        ...(props.justifyContent ? {justifyContent:props.justifyContent} : null),
        ...(props.direction ? {flexDirection:props.direction} : null),
        ...(props.wrap ? {flexWrap:props.wrap} : null),
        ...props.style
      } as any} 
      onClick={() => props.onClick && props.onClick()}
      className={
        `${props.container ? "containerGrid" : ''} 
        ${props.className ? props.className : ''} 
        ${props.lg ? `lg-${props.lg}` : ''} 
        ${props.md ? `md-${props.md}` : ''} 
        ${props.sm ? `sm-${props.sm}` : ''} 
        ${props.xs ? `xs-${props.xs}` : ''} 
        ${props.xl ? `xl-${props.xs}` : ''} 
        ${props.item ? `item` : ''} 
        ${(props.children && Object.keys(props).length === 1) ? `xs-12` : ''} 
        ${props.spacing ? `spacing-${props.spacing === 0.5 ? '05' : props.spacing}` : ''}`
      }>
        {props.children}
      </div>
    )
}
export default Grid