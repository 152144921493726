import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { customerLogin, customerLoginWithToken } from "../../actions/customerLogin";
import { string, object } from "yup";
import {
    Link,
    Navigate,
    useLocation,
    useNavigate
} from "react-router-dom";
import { Field, Formik, FormikErrors, FormikValues } from "formik";
import "./newLoginPage.css";
import { MdCall } from "react-icons/md";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FormControlLabel } from "../../Components/Radio/CustomRadio";
import CheckBox from "../../Components/CheckBox/CheckBox";


interface FormValues {
    mobileNumber: string;
    password: string;
    OTP: string;
}

const initialValues: FormValues = {
    mobileNumber: "",
    password: "",
    OTP: "",
};

interface IProps {
    customerLogin: any;
    user: {
        token?: string;
        userName?: string;
        startLoader?: any;
        branches: any;
        fleets: any;
    };
    customerReducer: any;
    customerLoginWithToken: any;
}

export const NewCustomerLoginPage = (props: IProps) => {
    const { customerLogin, user, customerReducer, customerLoginWithToken } = props;
    const [tabValue, setTabValue] = useState(0);
    const [rememberMeCheckBox, setRememberMeCheckBox] = useState(true);
    // const history = useHistory();
    const navigate = useNavigate()
    const locationDetails: any = useLocation();
    const phoneRegExp = /^[1-9]\d{9}/;
    const [showPass, setShowPass] = useState(false);

    useEffect(() => {
        if (new Date(localStorage.refreshTokenExpiresAt).getTime() > new Date().getTime()) {
            if (localStorage?.refreshToken) {
                handleRefreshTokenLogin();
            }
        }
    }, [tabValue])

    const handleLogin = (value: any) => {
        if (value === 0) {
            navigate("/login");
        }
        if (value === 1) {
            navigate("/login");
        }
    }

    const [otpFieldVisible, setOtpFieldVisible] = useState<boolean>(false);

    if (customerReducer && customerReducer?.token && customerReducer?.name && localStorage.getItem("kabra:customertoken")) {
        if (locationDetails && locationDetails?.state?.from === "Payment_Success") {
            let finalAns = locationDetails?.state?.content.split("&")
            let finalAns2: any = finalAns[finalAns?.length - 1].split("=")
            console.log(`locationDetails`, finalAns, `finalAns2`, finalAns2, locationDetails, finalAns2[finalAns2?.length - 1])
            return (
                // <Redirect
                //     to={{
                //         pathname: "/CustomerDashboard/TempTracking",
                //         state: {
                //             from: "Customer-Authenticated-BranchOrder",
                //             backSource: locationDetails?.pathname,
                //             docketNumber: { docket: finalAns2[1] },
                //             typeOfDocket: "Branch Docket"
                //         },
                //     }}
                // />
                <Navigate to="/CustomerDashboard/TempTracking" state={{
                    from: "Customer-Authenticated-BranchOrder",
                    backSource: locationDetails?.pathname,
                    docketNumber: { docket: finalAns2[1] },
                    typeOfDocket: "Branch Docket"
                }} />
            );
        }
        else {
            return (
                // <Redirect
                //     to={{
                //         pathname: "/customerDashboard/booking",
                //         state: { from: "customerLogin" },
                //     }}
                // />
                <Navigate to="/customerDashboard/booking" state={{ from: "customerLogin" }}/>
            );
        }
    }

    const handleRefreshTokenLogin = () => { customerLoginWithToken(localStorage?.refreshToken) }

    return (
        <Fragment>
            <div>

                <div className="newLoadingHeadContainer">
                    <h5 className="loginHeading">Sign In</h5>

                    <div className="credsContainer">
                        <div className='newLoginNavbar'>
                            <div className='newLoginPageHeadingTabs'>
                                <div className={tabValue === 0 ? "sixTabs active" : "sixTabs"} onClick={() => { handleLogin(0), setTabValue(0) }}>Customer</div>
                                <div className={tabValue === 1 ? "sixTabs active" : "sixTabs"} onClick={() => { handleLogin(1), setTabValue(1) }}>Transporter</div>
                            </div>
                        </div>

                        <Formik

                            initialValues={initialValues}

                            onSubmit={async (values: FormValues) => {
                                let response = await customerLogin(
                                    values.mobileNumber,
                                    values.password,
                                    rememberMeCheckBox,
                                    localStorage.getItem("deviceId"),
                                    localStorage.getItem("latitude"),
                                    localStorage.getItem("longitude")
                                );
                                if (response) {
                                    setOtpFieldVisible(true);
                                }
                            }}
                            validationSchema={object().shape({
                                password: string()
                                    .required("*Password must be required*")
                                    .min(6, "*Password must be at least 6 characters*"),

                                mobileNumber: string()
                                    .required("*Phone number is required*")
                                    .max(10, "*Your phone number should be 10 digits short*")
                                    .matches(
                                        phoneRegExp,
                                        "*Your phone number should be 10 digits long*"
                                    ),
                            })}

                            validate={(values: FormValues) => {
                                const errors: FormikErrors<FormikValues> = {};

                                if (!values.password) {
                                    errors.password = "*Password must be required*";
                                } else if (!values.mobileNumber) {
                                    errors.mobileNumber = "*Phone number is required*";
                                }
                                return errors;
                            }}

                            render={({ handleSubmit, errors, touched }) => (

                                <form onSubmit={handleSubmit}>

                                    <div className="contactNumberDiv">
                                        {/* <select className="selectTagNo">
                                        <option disabled selected>
                                        +91
                                        </option>
                                    </select> */}
                                        <div className="phoneLogo">
                                            <MdCall />
                                        </div>
                                        <Field
                                            type="number"
                                            id="contact"
                                            data-testid="contact"
                                            name="mobileNumber"
                                            className="contactField"
                                            placeholder="Enter your 10 digit phone number"
                                            autoComplete="off"
                                        />
                                    </div>

                                    <div className="passwordDiv">
                                        <div className="dummyDiv">
                                            {showPass ?
                                                <FaEye onClick={() => setShowPass(!showPass)} />
                                                :
                                                <FaEyeSlash onClick={() => setShowPass(!showPass)} />
                                            }
                                        </div>
                                        <Field
                                            type={showPass ? "text" : "password"}
                                            id="password"
                                            data-testid="password"
                                            name="password"
                                            className="passwordField"
                                            placeholder="Enter Password"
                                        // autoComplete="off"
                                        />
                                        {/* <div className="passLogo">
                                        <RiLockPasswordFill />
                                    </div> */}
                                    </div>

                                    <div style={{ border: "0.2px solid transparent", fontSize: "large", textAlign: "center" }}>
                                        <FormControlLabel
                                            control={
                                                <CheckBox
                                                    checked={rememberMeCheckBox}
                                                    onChange={() => setRememberMeCheckBox(!rememberMeCheckBox)}
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <span style={{ fontSize: "large" }}>
                                                    Remember Me
                                                </span>
                                            }
                                        />
                                    </div>


                                    {touched.mobileNumber && errors.mobileNumber ? (
                                        <div className="loginError">{errors.mobileNumber}</div>
                                    ) : null}

                                    {touched.password && errors.password ? (
                                        <div className="loginError">{errors.password}</div>
                                    ) : null}

                                    {otpFieldVisible && (
                                        <div>
                                            <p className="otp-msg">
                                                We have sent OTP on your registered mobile. <br />
                                                Please enter OTP here to proceed.
                                            </p>

                                            <div className="form-group otp-field">
                                                <Field
                                                    type="text"
                                                    name="OTP"
                                                    className="form-control otp-input-field"
                                                    id="OTP"
                                                    data-testid="OTP"
                                                    placeholder="OTP"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div id="newLoading">
                                        {user.startLoader && <p className="loginLoader"></p>}
                                    </div>

                                    <div className="loginBtnDiv">
                                        <button
                                            type="submit"
                                            disabled={(user.startLoader || errors.mobileNumber || errors.password)}
                                            className={(user.startLoader || errors.mobileNumber || errors.password) ? "newLoginBtndisabled" : "newLoginBtn"}
                                            data-testid="login_button"
                                        >
                                            LET'S BEGIN
                                        </button>
                                    </div>

                                    <div className="createAccount">New on TapTap ?
                                        <span style={{ color: "#337ab7" }} onClick={() => navigate("/customer")}>SignUp Now</span>
                                    </div>

                                    <hr style={{ border: "0.5px solid gray" }} />

                                    <div className="resetPasswordDiv">
                                        <div className="changePass">
                                            <Link to="/customerLogin/changePassword" className="cp-span">
                                                Change Password
                                            </Link>
                                        </div>
                                        <div className="middleLine"></div>
                                        <div className="forgetPass">
                                            <Link to="/customerLogin/forgotPassword" className="fp-span">
                                                Forgot Password
                                            </Link>
                                        </div>
                                    </div>


                                </form>

                            )}

                        ></Formik>

                    </div>
                </div>

            </div>

        </Fragment>
    )

}

const mapStateToProps = (state: any) => ({
    customer: state?.customer,
    user: state?.user,
    customerReducer: state.customerLoginReducer,
})

const mapDispatchToProps = {
    customerLogin,
    customerLoginWithToken
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomerLoginPage)