import React, {
  ComponentProps,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import "./tabs.css";
import { useNavigate } from "react-router-dom";

type TabProps = {
  index: number;
  activeTabIndex: number;
  label: string | ReactNode;
  value?: string;
  Icon?: ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  right?: boolean;
  badgeCount?: number;
  to?: string;
} & ComponentProps<"a">;

type Tab = TabProps;

type TabsProps = {
  defaultActiveIndex?: TabProps["index"];
} & ComponentProps<"div">;

export function Tabs({ className = "", children, ...props }: TabsProps) {
  const tabsClassName =
    className +
    " taptap-tabs-container flex gap-1 shadow-small border-white overflow-x-auto fs-link none-if-empty";
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const tabsContainerRef = useRef<HTMLDivElement | null>(null);

  const updateArrowsVisibility = () => {
    if (tabsContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tabsContainerRef.current;

      // Determine whether to show the left arrow
      setShowLeftArrow(scrollLeft > 0);

      // Small tolerance value to account for subpixel inaccuracies
      const tolerance = 1;

      // Determine whether to show the right arrow, comparing scrollLeft + clientWidth and scrollWidth with a tolerance
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth - tolerance);
    }
  };

  useEffect(() => {
    const container = tabsContainerRef.current;

    // Run initially to set the arrow visibility correctly
    updateArrowsVisibility();

    if (container) {
      // Add event listener for scrolling to update arrow visibility dynamically
      container.addEventListener("scroll", updateArrowsVisibility);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (container) {
        container.removeEventListener("scroll", updateArrowsVisibility);
      }
    };
  }, []);

  const scrollLeft = () => {
    tabsContainerRef.current?.scrollBy({ left: -150, behavior: "smooth" });
  };

  const scrollRight = () => {
    tabsContainerRef.current?.scrollBy({ left: 150, behavior: "smooth" });
  };

  // component return
  return (
    <div className="taptap-tabs-wrapper" style={{ position: "relative" }}>
      {showLeftArrow && (
        <span className="taptap-tabs-container-left-arrow" onClick={scrollLeft}>
          &lt;
        </span>
      )}
      <div
        ref={tabsContainerRef}
        className={`${tabsClassName} ${showLeftArrow ? "left-active" : ""} ${showRightArrow ? "right-active" : ""}`}
        {...props}
        style={{ overflowX: "auto", whiteSpace: "nowrap" }}
      >
        {children}
      </div>
      {showRightArrow && (
        <span
          className="taptap-tabs-container-right-arrow"
          onClick={scrollRight}
        >
          &gt;
        </span>
      )}
    </div>
  );
}

export function Tab({
  index,
  activeTabIndex,
  className = "",
  label,
  style,
  right,
  Icon,
  startIcon,
  endIcon,
  children,
  badgeCount,
  onClick,
  to,
  ...props
}: TabProps) {
  const navigate = useNavigate();
  if (right === false) return null;
  const isTabActive = activeTabIndex === index;

  const tabClassName = `taptap-tabs-tab user-select-none pointer flex-center fw-500 flex-grow-1 p-10 ${
    isTabActive ? "text-black-900 bg-white-800" : "text-white-300"
  }`;

  // component return
  return (
    <a
      className={tabClassName}
      style={{
        textDecoration: style?.textDecoration ?? "none",
        borderBottom:
          style?.borderBottom ??
          `4px solid ${isTabActive ? "var(--clr-primary-400)" : "transparent"}`,
        minWidth: style?.minWidth ?? "max-content",
        ...style,
      }}
      onClick={(e) => {
        to && navigate(to);
        onClick?.(e);
      }}
      {...props}
    >
      {Icon || startIcon}
      {label}
      {badgeCount !== undefined && badgeCount !== null && badgeCount > 0 && (
        <span className="taptap-tabs-tab-badge-count">
          {formatCount(badgeCount)}
        </span>
      )}
      {endIcon}
      {children}
    </a>
  );
}

type TabPanelProps = {
  index: number;
  activeTabIndex: number;
  value?: string;
} & ComponentProps<"div">;

export function TabPanel({
  index,
  activeTabIndex,
  className = "",
  children,
  ...props
}: TabPanelProps) {
  const tabPanelClassName =
    className + ` none-if-empty ${activeTabIndex === index ? "" : "none"}`;

  if (activeTabIndex !== index) {
    return null;
  }

  return (
    <div className={tabPanelClassName} {...props}>
      {children}
    </div>
  );
}

function formatCount(num: number) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M+"; // Format millions
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "k+"; // Format thousands
  } else {
    return num; // Return the number itself if below 1000
  }
}
