export const procPackages = (packages: any) => {
  if (!packages) return null;

  const packageMap: any = {};
  packages.forEach((p: any) => {
    const {
        materialType,
        packingType,
        dimension,
        weight,
        rate,
        amount,
        stack,
        haz,
        frag,
      } = p,
      { l, b, h } = dimension;

    if (!materialType || !packingType) return null;
    let uniqueId = [
      materialType._id,
      packingType._id,
      l,
      b,
      h,
      weight,
      rate,
      amount,
      stack,
      haz,
      frag,
    ];
    let uid = uniqueId.join("@");
    if (!(uid in packageMap)) {
      packageMap[uid] = [p];
    } else packageMap[uid].push(p);
  });
  return packageMap;
};
