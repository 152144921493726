import React, { useEffect, useRef, useCallback } from "react";
import { useState } from "react";
import Loader from "@mui/material/CircularProgress";
import {Autocomplete} from "@react-google-maps/api";
import HowItWorks from '../../../Components/How It Works/HowItWorks';
import FAQ from '../../../Components/FAQ/FAQ';
import Testimonials from "../../../Components/Tetimonials/Testimonials";
import OurFeatures from "../../../Components/Features/OurFeatures";
import { useGoogleMapJsLoader } from "../../../hooks/useGoogleMapJsLoader";
import { useMediaQuery, useTheme } from "@mui/material";

const PackersAndMoversForm = () => {

  const [serviceType, setServiceType] = useState("Commercial");
  const [sourceLocation, setSourceLocation] = useState("");
  const [autoCompleteOrigin, setAutoCompleteOrigin] = useState<any>(null)
  const originIn: any = useRef();
  const [destinatioLocation, setDestinatioLocation] = useState("");
  const [autoCompleteDest, setAutoCompleteDest] = useState<any>(null)
  const destIn: any = useRef();
  const [formDetails, setFomrDetails] = useState(false)
  const [packageCount, setPackageCount] = useState([{}])
  const [residentialType, setResidentialType] = useState("");
  const [assetTypeList, setAssetTypeList] = useState([])
  const [assetType, setAssetType] = useState("");
  const [vehicleName, setVehicleName] = useState("")
  const [loading] = useState(false);
  const [result] = useState([]);
  const [date, setDate] = useState("");  
  const [tabActive,setTabActive] = useState(0);

  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2
  
  const topFunction2 = (props:any)=>{
    document.body.scrollTop = 0;
    {props === "book" ? document.documentElement.scrollTop = 0 : props === "feature" ? document.documentElement.scrollTop = 515 : props === "how" ? document.documentElement.scrollTop = 1040 :
    props === "test" ? document.documentElement.scrollTop = 1575 : props === "faq's" ? document.documentElement.scrollTop = 2100 : 0 }
  } 
  
  const onLoadOrigin = useCallback(function callback(autocomplete:any) {
    setAutoCompleteOrigin(autocomplete)
  }, [])

  const onLoadDest = useCallback(function callback(autocomplete:any) {
    setAutoCompleteDest(autocomplete)
  }, [])

  useEffect(() => {
    defineAssetType();
  }, [formDetails]);

  useEffect(() => {
    setDate("")
    setFomrDetails(false)
    setPackageCount([{}])
  }, [serviceType])

  const defineAssetType = () => {
    const AssetTypesArr: any = [{ id: 1, name: "Two Wheeler" }, { id: 2, name: "Three Wheeler" }, { id: 3, name: "Four Wheeler" }]
    setAssetTypeList(AssetTypesArr)
  }

  const handleResults = () => {
    if (serviceType === "residential") {
      if (
        residentialType === "" ||
        sourceLocation === "" ||
        destinatioLocation === "" ||
        date === ""
      ) {
        alert("Kindly Fill All the Details !");
      } else {
        console.log({
          "Type of Form": `${serviceType} Packers & Movers`,
          ResidentialType: residentialType,
          sourceLocation: sourceLocation,
          Date: date,
          PackageDetails: packageCount
        });
        setResidentialType("")
        setDate("")
        setPackageCount([{}])
        setResidentialType("")
        setFomrDetails(false)
      }
    }
    if (serviceType === "commercial") {
      if (
        sourceLocation === "" ||
        destinatioLocation === "" ||
        date === ""
      ) {
        alert("Kindly Fill All the Details !");
      } else {
        console.log({
          "Type of Form": `${serviceType} Packers & Movers`,
          SourceLocation: sourceLocation,
          DestinatioLocation: destinatioLocation,
          Date: date,
          PackageDetails: packageCount
        });
        setDate("")
        setPackageCount([{}])
        setFomrDetails(false)
      }
    }
    if (serviceType === "Vehicles") {
      if (
        sourceLocation === "" ||
        destinatioLocation === "" ||
        date === "" ||
        assetType === "" ||
        vehicleName === ""
      ) {
        alert("Kindly Fill All the Details !");
      } else {
        console.log({
          "Type of Form": `${serviceType} Packers & Movers`,
          sourceLocation: sourceLocation,
          destinatioLocation: destinatioLocation,
          Date: date,
          AssetType: assetType,
          vehicleName: vehicleName
        });
        setDate("")
        setPackageCount([{}])
        setVehicleName("")
        setAssetType("")
        setFomrDetails(false)
      }
    }
  };

  // const { isLoaded } = useJsApiLoader({
  //   googleMapsApiKey: "",
  //   libraries: ["places"],
  // });
  const { isLoaded } = useGoogleMapJsLoader()
  if (!isLoaded) {
    return <></>;
  }

  const packersAndMovers = {
    "HowItWorks": [{ headings: ["Submit details and book a slot", "Confirm the list", "Get the quote"] },
    {
      img: ["how-it-works-img1.png",
        "how-it-works-img2.png",
        "how-it-works-img3.png"]
    }],
    "Features": [
      {
        img: ["https://img.icons8.com/bubbles/80/000000/cash-in-hand.png",
          "https://img.icons8.com/bubbles/80/000000/security-checked.png",
          "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
          "https://img.icons8.com/bubbles/80/000000/conference-call.png",
          "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
          "https://img.icons8.com/bubbles/80/000000/conference-call.png"]
      },
      { headings: ["24x7 Great Customer Support", "Household Moving", "Corporate Office Moving", "On-Time Delivery", "Good Quality Packing Material", "Notification at every stage", "CCTV surveillance"] },
      {
        content: ["Our team is available 24/7 to help you with your packing and moving needs.",
          "We provide packing and moving services for households of all sizes.",
          "We can help you move your corporate office anywhere in India.",
          "We guarantee on-time delivery to any destination in India.",
          "We use only the best quality packing materials to ensure the safety of your belongings.",
          "We deliver to your doorstep, so you can just sit back and relax.",
          "We provide you with the option to pay so that you can pay us only when you receive your product.",]
      }],
    "FAQ": [
      {
        question: ["Why do the final charges vary from the estimates ?",
          "What is the cheapest way to relocate ?",
          "Does Porter provide Intercity/interstate house shifting service ?",
          "How much does packing a house cost ?",
          "Are there any additional or hidden charges ?"]
      },
      {
        answer: ["Our charges are fixed. What you see is what you pay. Prices are subject to revision if there is a change in the location or final item list. Rates can also change basis the complexity of shifting.",
          "Our packers & movers help you with complete solutions starting from packing the goods, loading unloading, dismantling-assembly, etc. But if you have already packed your goods and are ready to do some heavy lifting, you can book our mini trucks and save money.",
          "Yes, we do provide intercity/interstate house shifting service.",
          "Packing charges for a house are dependent on the number of goods available for packing and the kind of packing materials required to pack fragile and non-fragile materials. Price also varies on the number of bedrooms. Charges for 1 RK/Studio, 1 BHK, 2 BHK, and 3BHK are mentioned above. To get a price estimate for packing fill in your details in the “Where do you want to move?” section and we will reach you in 24 hours.",
          "The quote provided to you includes all the charges. There is no change in the quote unless there is a last-minute change in the requirements."
        ]
      }],
    "Testimonials": [{ img: [] }, { name: [] }, { possition: [] }, { company: [] },
    {
      feedback: ["I was very pleased with the service I received from Kabra Express Packers and Movers. They were very professional and courteous throughout the entire process. I was also impressed with their knowledge and expertise. They were able to handle my large moving project with ease and everything arrived at my new home safely. I would highly recommend their services to anyone in need of a packer and movers.",
        "I have availed the services of Kabra Express Packers and Movers on two occasions, and I have been highly satisfied with their service on both occasions. They are a very professional outfit, and their staff is always polite and helpful. They have the knowledge and expertise to handle any size moving project, and they place a high priority on the safety of your belongings. I would recommend their.",
        "We used Kabra Express for our move from Ahmedabad to Mumbai and we were very pleased with their service. They were very professional and efficient in packing and moving our belongings. We were very happy with how safe and secure our belongings were during the move. We would highly recommend Kabra Express to anyone looking for professional and reliable packers and movers service."
      ]
    }]
  }


  return (
  <>
    {mobileView ? ( 
      <>
        <div className="pandm">
          <section id="intro" className="intro">
            <div className="intro-content">
              <div style={{height: "50px"}}></div>
            <h1 style={{textAlign: "center", fontSize: "24px", marginBottom: "0", paddingBlock: "1rem"}}>Get Estimate</h1>
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="logo_1 mt-55 mt40Mobile">
                      <img src="img/21.png" className="img-responsive mt-55" alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6 firm_1">
                    <div>
                      <div className="row form_page">
                        <div className="tab-content">
                          <div className="tab-pane active" id="my_1">
                            <div className="ownBtnMobileVersion ml-3Mobile">
                              <button className={serviceType === "Residential" ? "activeButton" : ""} onClick={()=>{setServiceType("Residential")}}> Residential </button>
                              <button className={serviceType === "Commercial" ? "activeButton mt3Percentage" : "mt3Percentage"} onClick={()=>{setServiceType("Commercial")}}> Commercial </button>
                              <button className={serviceType === "Vehicles" ? "activeButton" : ""} onClick={()=>{setServiceType("Vehicles")}}> Vehicles </button>
                            </div>
                            <div className="tab-content ml5Percentage">
                              {serviceType === "Residential" && (
                                <div className="tab-pane active" id="my_1">
                                  <div className="row ml-170">
                                    <Autocomplete
                                      onPlaceChanged={() => {
                                        if (autoCompleteOrigin !== null) {
                                          let ans= autoCompleteOrigin.getPlace().formatted_address
                                          setSourceLocation(ans)
                                        } else {
                                          console.log('Autocomplete is not loaded yet!')
                                        }
                                      }} onLoad={onLoadOrigin}
                                      >
                                        <input
                                          type='text'
                                          placeholder='Source Location'
                                          className="form-control form_one ml170"
                                          ref={originIn}
                                        />
                                      </Autocomplete>
                                    </div>

                                    <div className="row ml-170 mt10Mobile">
                                      <Autocomplete
                                        onPlaceChanged={() => {
                                          if (autoCompleteDest !== null) {
                                            let ans = autoCompleteDest.getPlace().formatted_address
                                            setDestinatioLocation(ans)
                                          } else {
                                            console.log('Autocomplete is not loaded yet!')
                                          }
                                        }} onLoad={onLoadDest}
                                      >
                                        <input
                                          type='text'
                                          placeholder='Destination Location'
                                          className="form-control form_one ml170 mt0"
                                          ref={destIn}
                                        />
                                      </Autocomplete>
                                    </div>

                                    <div className="row ml-170 mt10Mobile">
                                      <input
                                        type="date"
                                        onChange={(eve) => {
                                          setDate(eve.target.value);
                                        }}
                                        className="form-control form_one mt0 ml170"
                                        id="inputZip"
                                        required
                                      />
                                    </div>
                                    <div className="row ml-170 mt10Mobile">
                                      <button
                                        className="btn btn-primary-one text-white form_one mt0 ml170"
                                        onClick={handleResults}
                                      >
                                        Get Details
                                      </button>
                                    </div>

                                    {loading === true && (
                                      <span className="tac">
                                        <Loader />
                                      </span>
                                    )}
                                    {result && loading === false && (
                                      // <div className="table-responsive">
                                      //   <table className="table">
                                      //     <thead>
                                      //       <tr>
                                      //         <th>Type</th>
                                      //         <th>Estimated Time</th>
                                      //         <th>Estimated Amount</th>
                                      //       </tr>
                                      //     </thead>
                                      //     {result.length > 0 ? (
                                      //       <tbody>
                                      //         {result.map((r: any) => (
                                      //           <tr key={r.type}>
                                      //             <td>
                                      //               <a onClick={() => {}}>{r.type}</a>
                                      //             </td>
                                      //             <td>
                                      //               {new Date(
                                      //                 r.estimatedDeliveryTime
                                      //               ).toLocaleDateString()}
                                      //             </td>
                                      //             <td>{r.estimatedCost}</td>
                                      //           </tr>
                                      //         ))}
                                      //       </tbody>
                                      //     ) : (
                                      //       <tbody>
                                      //         <tr>
                                      //           <td
                                      //             colSpan={3} className="tac"
                                      //           >
                                      //             {/* No service found! */}
                                      //             <div>
                                      //                 <div className="col-md-6 col-sm-6">
                                      //                     <input
                                      //                       type="date"
                                      //                       onChange={(eve) => {
                                      //                         setDate(eve.target.value);
                                      //                       }}
                                      //                       className="form-control form_one"
                                      //                       id="inputZip"
                                      //                       required
                                      //                     />
                                      //                 </div>
                                      //                 <div className="col-md-6">
                                      //                   <button
                                      //                     className="btn btn-primary-one text-white form_one"
                                      //                     onClick={handleResults}
                                      //                   >
                                      //                     Search
                                      //                   </button>
                                      //                 </div>
                                      //             </div>
                                      //           </td>
                                      //         </tr>
                                      //       </tbody>
                                      //     )}
                                      //   </table>
                                      // </div>
                                      null
                                    )}
                                  </div>
                                )}
                                {serviceType === "Commercial" && (
                                  <div className="tab-pane active" id="my_1">
                                    <div className="row ml-170">
                                      <Autocomplete
                                        onPlaceChanged={() => {
                                          if (autoCompleteOrigin !== null) {
                                            let ans = autoCompleteOrigin.getPlace().formatted_address
                                            setSourceLocation(ans)
                                          } else {
                                            console.log('Autocomplete is not loaded yet!')
                                          }
                                        }} onLoad={onLoadOrigin}
                                      >
                                        <input
                                          type='text'
                                          placeholder='Source Location'
                                          className="form-control form_one ml170"
                                          ref={originIn}
                                        />
                                      </Autocomplete>
                                    </div>

                                    <div className="row ml-170 mt10Mobile">
                                      <Autocomplete
                                        onPlaceChanged={() => {
                                          if (autoCompleteDest !== null) {
                                            let ans = autoCompleteDest.getPlace().formatted_address
                                            setDestinatioLocation(ans)
                                          } else {
                                            console.log('Autocomplete is not loaded yet!')
                                          }
                                        }} onLoad={onLoadDest}
                                      >
                                        <input
                                          type='text'
                                          placeholder='Destination Location'
                                          className="form-control form_one ml170 mt0"
                                          ref={destIn}
                                        />
                                      </Autocomplete>
                                    </div>

                                    <div className="row ml-170 mt10Mobile">
                                      <input
                                        type="date"
                                        onChange={(eve) => {
                                          setDate(eve.target.value);
                                        }}
                                        className="form-control form_one mt0 ml170"
                                        id="inputZip"
                                        required
                                      />
                                    </div>
                                    <div className="row ml-170 mt10Mobile">
                                      <button
                                        className="btn btn-primary-one text-white form_one mt0 ml170"
                                        onClick={handleResults}
                                      >
                                        Get Details
                                      </button>
                                    </div>

                                    {loading === true && (
                                      <span className="tac">
                                        <Loader />
                                      </span>
                                    )}
                                    {result && loading === false && (
                                      // <div className="table-responsive">
                                      //   <table className="table">
                                      //     <thead>
                                      //       <tr>
                                      //         <th>Type</th>
                                      //         <th>Estimated Time</th>
                                      //         <th>Estimated Amount</th>
                                      //       </tr>
                                      //     </thead>
                                      //     {result.length > 0 ? (
                                      //       <tbody>
                                      //         {result.map((r: any) => (
                                      //           <tr key={r.type}>
                                      //             <td>
                                      //               <a onClick={() => {}}>{r.type}</a>
                                      //             </td>
                                      //             <td>
                                      //               {new Date(
                                      //                 r.estimatedDeliveryTime
                                      //               ).toLocaleDateString()}
                                      //             </td>
                                      //             <td>{r.estimatedCost}</td>
                                      //           </tr>
                                      //         ))}
                                      //       </tbody>
                                      //     ) : (
                                      //       <tbody>
                                      //         <tr>
                                      //           <td
                                      //             colSpan={3} className="tac"
                                      //           >
                                      //             {/* No service found! */}
                                      //             <div>
                                      //                 <div className="col-md-6 col-sm-6">
                                      //                     <input
                                      //                       type="date"
                                      //                       onChange={(eve) => {
                                      //                         setDate(eve.target.value);
                                      //                       }}
                                      //                       className="form-control form_one"
                                      //                       id="inputZip"
                                      //                       required
                                      //                     />
                                      //                 </div>
                                      //                 <div className="col-md-6">
                                      //                   <button
                                      //                     className="btn btn-primary-one text-white form_one"
                                      //                     onClick={handleResults}

                                      //                   >
                                      //                     Search
                                      //                   </button>
                                      //                 </div>
                                      //             </div>
                                      //           </td>
                                      //         </tr>
                                      //       </tbody>
                                      //     )}
                                      //   </table>
                                      // </div>
                                      null
                                    )}
                                  </div>
                                )}
                                {serviceType === "Vehicles" && (
                                  <div className="tab-pane active" id="my_1">
                                    <div className="row ml-170">
                                      <Autocomplete
                                        onPlaceChanged={() => {
                                          if (autoCompleteOrigin !== null) {
                                            let ans = autoCompleteOrigin.getPlace().formatted_address
                                            setSourceLocation(ans)
                                          } else {
                                            console.log('Autocomplete is not loaded yet!')
                                          }
                                        }} onLoad={onLoadOrigin}
                                      >
                                        <input
                                          type='text'
                                          placeholder='Source Location'
                                          className="form-control form_one ml170"
                                          ref={originIn}
                                        />
                                      </Autocomplete>
                                    </div>

                                    <div className="row ml-170 mt10Mobile">
                                      <Autocomplete
                                        onPlaceChanged={() => {
                                          if (autoCompleteDest !== null) {
                                            let ans = autoCompleteDest.getPlace().formatted_address
                                            setDestinatioLocation(ans)
                                          } else {
                                            console.log('Autocomplete is not loaded yet!')
                                          }
                                        }} onLoad={onLoadDest}
                                      >
                                        <input
                                          type='text'
                                          placeholder='Destination Location'
                                          className="form-control form_one ml170 mt0"
                                          ref={destIn}
                                        />
                                      </Autocomplete>
                                    </div>

                                    <div className="row ml-170 mt10Mobile">
                                      <select
                                        id="inputState"
                                        className="form-control form_one ml170 mt0"
                                        value={assetType}
                                        onChange={(e) => {
                                          setAssetType(e.target.value)
                                        }}
                                      >
                                        <option label="Select Vehicle Type" value="" />
                                        {assetTypeList.map((c: any) => (
                                          <option
                                            key={c.id}
                                            label={c.name}
                                            value={c.name}
                                          />
                                        ))}
                                      </select>
                                    </div>
                                    <div className="row ml-170 mt10Mobile">
                                      <input
                                        type="text"
                                        value={vehicleName}
                                        onChange={(e) => {
                                          setVehicleName(e.target.value);
                                        }}
                                        className="form-control form_one ml170 mt0"
                                        id="inputZip"
                                        placeholder="Vehicle Name"
                                        required
                                      />
                                    </div>

                                    <div className="row ml-170 mt10Mobile">
                                      <input
                                        type="date"
                                        onChange={(eve) => {
                                          setDate(eve.target.value);
                                        }}
                                        className="form-control form_one ml170 mt0"
                                        id="inputZip"
                                        required
                                      />
                                    </div>
                                    <div className="row ml-170 mt10Mobile">
                                      <button
                                        className="btn btn-primary-one text-white form_one ml170 mt0"
                                        onClick={handleResults}
                                      >
                                        Get Details
                                      </button>
                                    </div>

                                    {loading === true && (
                                      <span className="tac">
                                        <Loader />
                                      </span>
                                    )}
                                    {result && loading === false && (
                                      // <div className="table-responsive">
                                      //   <table className="table">
                                      //     <thead>
                                      //       <tr>
                                      //         <th>Type</th>
                                      //         <th>Estimated Time</th>
                                      //         <th>Estimated Amount</th>
                                      //       </tr>
                                      //     </thead>
                                      //     {result.length > 0 ? (
                                      //       <tbody>
                                      //         {result.map((r: any) => (
                                      //           <tr key={r.type}>
                                      //             <td>
                                      //               <a onClick={() => {}}>{r.type}</a>
                                      //             </td>
                                      //             <td>
                                      //               {new Date(
                                      //                 r.estimatedDeliveryTime
                                      //               ).toLocaleDateString()}
                                      //             </td>
                                      //             <td>{r.estimatedCost}</td>
                                      //           </tr>
                                      //         ))}
                                      //       </tbody>
                                      //     ) : (
                                      //       <tbody>
                                      //         <tr>
                                      //           <td
                                      //             colSpan={3} className="tac"
                                      //           >
                                      //             {/* No service found! */}
                                      //             <div>
                                      //                 <div className="col-md-6 col-sm-6">
                                      //                     <input
                                      //                       type="date"
                                      //                       onChange={(eve) => {
                                      //                         setDate(eve.target.value);
                                      //                       }}
                                      //                       className="form-control form_one"
                                      //                       id="inputZip"
                                      //                       required
                                      //                     />
                                      //                 </div>
                                      //                 <div className="col-md-6">
                                      //                   <button
                                      //                     className="btn btn-primary-one text-white form_one"
                                      //                     onClick={handleResults}

                                      //                   >
                                      //                     Search
                                      //                   </button>
                                      //                 </div>
                                      //             </div>
                                      //           </td>
                                      //         </tr>
                                      //       </tbody>
                                      //     )}
                                      //   </table>
                                      // </div>
                                      null
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div data-aos="fade-up" data-aos-delay="800"></div>
                    </div>
                  </div>
                </div>
              </div>``
            </section>
          </div>
          <div className="feturesTabs">
            <div className="features commonBoxShadow" onMouseOver={() => { setTabActive(1) }}>
              <div className="section-title" data-wow-delay="0.1s">
                <h2>Our Features</h2>
              </div>
              <div className="centalisedFeatures">
                <OurFeatures data={packersAndMovers} />
              </div>
            </div>
            <div className="howItWorks commonBoxShadow" onMouseOver={() => { setTabActive(2) }}>
              <div className="section-title" data-wow-delay="0.1s">
                <h2>How It Works</h2>
              </div>
              <div className="centalisedHowItWorks">
                <HowItWorks data={packersAndMovers} />
              </div>
            </div>
            <div className="trestimonials commonBoxShadow" onMouseOver={() => { setTabActive(3) }}>
              <div className="section-title" data-wow-delay="0.1s">
                <h2>Testimonials</h2>
              </div>
              <div className="centalisedTestimonials">
                <Testimonials data={packersAndMovers} />
              </div>
            </div>
            <div className="faqIntracity commonBoxShadow" onMouseOver={() => { setTabActive(4) }}>
              <section className="faq">
                <div className="container">
                  <div className="section-title" data-wow-delay="0.1s">
                    <h2>FAQ</h2>
                  </div>
                  <FAQ data={packersAndMovers} />
                </div>
              </section>
            </div>
          </div>
        </>
      ) : (
        <div className="pandmContainer">
          <div className="scrollableContainer">
            <div className="sideNav" onMouseEnter={() => document.body.style.overflowY = 'hidden'} onMouseLeave={() => document.body.style.overflowY = 'scroll'}>
              <div className={tabActive === 0 ? "activeNew" : ""} onClick={() => { topFunction2("book"), setTabActive(0) }}>Book Now</div>
              <div className={tabActive === 1 ? "activeNew" : ""} onClick={() => { topFunction2("feature"), setTabActive(1) }}>Features</div>
              <div className={tabActive === 2 ? "activeNew" : ""} onClick={() => { topFunction2("how"), setTabActive(2) }}>How It Works</div>
              <div className={tabActive === 3 ? "activeNew" : ""} onClick={() => { topFunction2("test"), setTabActive(3) }}>Testimonials</div>
              <div className={tabActive === 4 ? "activeNew" : ""} onClick={() => { topFunction2("faq's"), setTabActive(4) }}>FAQ's</div>
            </div>
            <div className="pandm">
              <div className="intro-content formAndPhoto commonBoxShadow" onMouseOver={() => { setTabActive(0) }}>
                <div className="col-lg-6 firm_1 wow commonPaddingHeight mt-50" data-wow-delay="0.1s" >
                  <div className="bttn">
                    <div className="ownBtn">
                      <button className={serviceType === "Residential" ? "activeButton" : ""} onClick={() => { setServiceType("Residential") }}> Residential </button>
                      <button className={serviceType === "Commercial" ? "activeButton" : ""} onClick={() => { setServiceType("Commercial") }}> Commercial </button>
                      <button className={serviceType === "Vehicles" ? "activeButton" : ""} onClick={() => { setServiceType("Vehicles") }}> Vehicles </button>
                    </div>
                  </div>

                  {serviceType === "Residential" && (
                    <>
                      <div className="row ml-170">
                        <Autocomplete
                          onPlaceChanged={() => {
                            if (autoCompleteOrigin !== null) {
                              let ans = autoCompleteOrigin.getPlace().formatted_address
                              setSourceLocation(ans)
                            } else {
                              console.log('Autocomplete is not loaded yet!')
                            }
                          }} onLoad={onLoadOrigin}
                        >
                          <input
                            type='text'
                            placeholder='Source Location'
                            className="form-control form_one ml170"
                            ref={originIn}
                          />
                        </Autocomplete>
                      </div>

                      <div className="row ml-170 mt10Mobile">
                        <Autocomplete
                          onPlaceChanged={() => {
                            if (autoCompleteDest !== null) {
                              let ans = autoCompleteDest.getPlace().formatted_address
                              setDestinatioLocation(ans)
                            } else {
                              console.log('Autocomplete is not loaded yet!')
                            }
                          }} onLoad={onLoadDest}
                        >
                          <input
                            type='text'
                            placeholder='Destination Location'
                            className="form-control form_one ml170 mt0"
                            ref={destIn}
                          />
                        </Autocomplete>
                      </div>

                      <div className="row ml-170 mt10Mobile">
                        <input
                          type="date"
                          onChange={(eve) => {
                            setDate(eve.target.value);
                          }}
                          className="form-control form_one mt0 ml170"
                          id="inputZip"
                          required
                        />
                      </div>
                      <div className="row ml-170 mt10Mobile">
                        <button
                          className="btn btn-primary-one text-white form_one mt0 ml170"
                          onClick={handleResults}
                        >
                          Get Details
                        </button>
                      </div>

                      {loading === true && (
                        <span className="tac">
                          <Loader />
                        </span>
                      )}
                      {result && loading === false && (
                        // <div className="table-responsive">
                        //   <table className="table">
                        //     <thead>
                        //       <tr>
                        //         <th>Type</th>
                        //         <th>Estimated Time</th>
                        //         <th>Estimated Amount</th>
                        //       </tr>
                        //     </thead>
                        //     {result.length > 0 ? (
                        //       <tbody>
                        //         {result.map((r: any) => (
                        //           <tr key={r.type}>
                        //             <td>
                        //               <a onClick={() => {}}>{r.type}</a>
                        //             </td>
                        //             <td>
                        //               {new Date(
                        //                 r.estimatedDeliveryTime
                        //               ).toLocaleDateString()}
                        //             </td>
                        //             <td>{r.estimatedCost}</td>
                        //           </tr>
                        //         ))}
                        //       </tbody>
                        //     ) : (
                        //       <tbody>
                        //         <tr>
                        //           <td
                        //             colSpan={3} className="tac"
                        //           >
                        //             {/* No service found! */}
                        //             <div>
                        //                 <div className="col-md-6 col-sm-6">
                        //                     <input
                        //                       type="date"
                        //                       onChange={(eve) => {
                        //                         setDate(eve.target.value);
                        //                       }}
                        //                       className="form-control form_one"
                        //                       id="inputZip"
                        //                       required
                        //                     />
                        //                 </div>
                        //                 <div className="col-md-6">
                        //                   <button
                        //                     className="btn btn-primary-one text-white form_one"
                        //                     onClick={handleResults}
                        //                   >
                        //                     Search
                        //                   </button>
                        //                 </div>
                        //             </div>
                        //           </td>
                        //         </tr>
                        //       </tbody>
                        //     )}
                        //   </table>
                        // </div>
                        null
                      )}
                    </>
                  )}
                  {serviceType === "Commercial" && (
                    <>
                      <div className="row ml-170">
                        <Autocomplete
                          onPlaceChanged={() => {
                            if (autoCompleteOrigin !== null) {
                              let ans = autoCompleteOrigin.getPlace().formatted_address
                              setSourceLocation(ans)
                            } else {
                              console.log('Autocomplete is not loaded yet!')
                            }
                          }} onLoad={onLoadOrigin}
                        >
                          <input
                            type='text'
                            placeholder='Source Location'
                            className="form-control form_one ml170"
                            ref={originIn}
                          />
                        </Autocomplete>
                      </div>

                      <div className="row ml-170 mt10Mobile">
                        <Autocomplete
                          onPlaceChanged={() => {
                            if (autoCompleteDest !== null) {
                              let ans = autoCompleteDest.getPlace().formatted_address
                              setDestinatioLocation(ans)
                            } else {
                              console.log('Autocomplete is not loaded yet!')
                            }
                          }} onLoad={onLoadDest}
                        >
                          <input
                            type='text'
                            placeholder='Destination Location'
                            className="form-control form_one ml170 mt0"
                            ref={destIn}
                          />
                        </Autocomplete>
                      </div>

                      <div className="row ml-170 mt10Mobile">
                        <input
                          type="date"
                          onChange={(eve) => {
                            setDate(eve.target.value);
                          }}
                          className="form-control form_one mt0 ml170"
                          id="inputZip"
                          required
                        />
                      </div>
                      <div className="row ml-170 mt10Mobile">
                        <button
                          className="btn btn-primary-one text-white form_one mt0 ml170"
                          onClick={handleResults}
                        >
                          Get Details
                        </button>
                      </div>

                      {loading === true && (
                        <span className="tac">
                          <Loader />
                        </span>
                      )}
                      {result && loading === false && (
                        // <div className="table-responsive">
                        //   <table className="table">
                        //     <thead>
                        //       <tr>
                        //         <th>Type</th>
                        //         <th>Estimated Time</th>
                        //         <th>Estimated Amount</th>
                        //       </tr>
                        //     </thead>
                        //     {result.length > 0 ? (
                        //       <tbody>
                        //         {result.map((r: any) => (
                        //           <tr key={r.type}>
                        //             <td>
                        //               <a onClick={() => {}}>{r.type}</a>
                        //             </td>
                        //             <td>
                        //               {new Date(
                        //                 r.estimatedDeliveryTime
                        //               ).toLocaleDateString()}
                        //             </td>
                        //             <td>{r.estimatedCost}</td>
                        //           </tr>
                        //         ))}
                        //       </tbody>
                        //     ) : (
                        //       <tbody>
                        //         <tr>
                        //           <td
                        //             colSpan={3} className="tac"
                        //           >
                        //             {/* No service found! */}
                        //             <div>
                        //                 <div className="col-md-6 col-sm-6">
                        //                     <input
                        //                       type="date"
                        //                       onChange={(eve) => {
                        //                         setDate(eve.target.value);
                        //                       }}
                        //                       className="form-control form_one"
                        //                       id="inputZip"
                        //                       required
                        //                     />
                        //                 </div>
                        //                 <div className="col-md-6">
                        //                   <button
                        //                     className="btn btn-primary-one text-white form_one"
                        //                     onClick={handleResults}

                        //                   >
                        //                     Search
                        //                   </button>
                        //                 </div>
                        //             </div>
                        //           </td>
                        //         </tr>
                        //       </tbody>
                        //     )}
                        //   </table>
                        // </div>
                        null
                      )}
                    </>
                  )}
                  {serviceType === "Vehicles" && (
                    <>
                      <div className="row ml-170">
                        <Autocomplete
                          onPlaceChanged={() => {
                            if (autoCompleteOrigin !== null) {
                              let ans = autoCompleteOrigin.getPlace().formatted_address
                              setSourceLocation(ans)
                            } else {
                              console.log('Autocomplete is not loaded yet!')
                            }
                          }} onLoad={onLoadOrigin}
                        >
                          <input
                            type='text'
                            placeholder='Source Location'
                            className="form-control form_one ml170"
                            ref={originIn}
                          />
                        </Autocomplete>
                      </div>

                      <div className="row ml-170 mt10Mobile">
                        <Autocomplete
                          onPlaceChanged={() => {
                            if (autoCompleteDest !== null) {
                              let ans = autoCompleteDest.getPlace().formatted_address
                              setDestinatioLocation(ans)
                            } else {
                              console.log('Autocomplete is not loaded yet!')
                            }
                          }} onLoad={onLoadDest}
                        >
                          <input
                            type='text'
                            placeholder='Destination Location'
                            className="form-control form_one ml170 mt0"
                            ref={destIn}
                          />
                        </Autocomplete>
                      </div>

                      <div className="row ml-170 mt10Mobile">
                        <select
                          id="inputState"
                          className="form-control form_one ml170 mt0"
                          value={assetType}
                          onChange={(e) => {
                            setAssetType(e.target.value)
                          }}
                        >
                          <option label="Select Vehicle Type" value="" />
                          {assetTypeList.map((c: any) => (
                            <option
                              key={c.id}
                              label={c.name}
                              value={c.name}
                            />
                          ))}
                        </select>
                      </div>
                      <div className="row ml-170 mt10Mobile">
                        <input
                          type="text"
                          value={vehicleName}
                          onChange={(e) => {
                            setVehicleName(e.target.value);
                          }}
                          className="form-control form_one ml170 mt0"
                          id="inputZip"
                          placeholder="Vehicle Name"
                          required
                        />
                      </div>

                      <div className="row ml-170 mt10Mobile">
                        <input
                          type="date"
                          onChange={(eve) => {
                            setDate(eve.target.value);
                          }}
                          className="form-control form_one ml170 mt0"
                          id="inputZip"
                          required
                        />
                      </div>
                      <div className="row ml-170 mt10Mobile">
                        <button
                          className="btn btn-primary-one text-white form_one ml170 mt0"
                          onClick={handleResults}
                        >
                          Get Details
                        </button>
                      </div>

                      {loading === true && (
                        <span className="tac">
                          <Loader />
                        </span>
                      )}
                      {result && loading === false && (
                        // <div className="table-responsive">
                        //   <table className="table">
                        //     <thead>
                        //       <tr>
                        //         <th>Type</th>
                        //         <th>Estimated Time</th>
                        //         <th>Estimated Amount</th>
                        //       </tr>
                        //     </thead>
                        //     {result.length > 0 ? (
                        //       <tbody>
                        //         {result.map((r: any) => (
                        //           <tr key={r.type}>
                        //             <td>
                        //               <a onClick={() => {}}>{r.type}</a>
                        //             </td>
                        //             <td>
                        //               {new Date(
                        //                 r.estimatedDeliveryTime
                        //               ).toLocaleDateString()}
                        //             </td>
                        //             <td>{r.estimatedCost}</td>
                        //           </tr>
                        //         ))}
                        //       </tbody>
                        //     ) : (
                        //       <tbody>
                        //         <tr>
                        //           <td
                        //             colSpan={3} className="tac"
                        //           >
                        //             {/* No service found! */}
                        //             <div>
                        //                 <div className="col-md-6 col-sm-6">
                        //                     <input
                        //                       type="date"
                        //                       onChange={(eve) => {
                        //                         setDate(eve.target.value);
                        //                       }}
                        //                       className="form-control form_one"
                        //                       id="inputZip"
                        //                       required
                        //                     />
                        //                 </div>
                        //                 <div className="col-md-6">
                        //                   <button
                        //                     className="btn btn-primary-one text-white form_one"
                        //                     onClick={handleResults}

                        //                   >
                        //                     Search
                        //                   </button>
                        //                 </div>
                        //             </div>
                        //           </td>
                        //         </tr>
                        //       </tbody>
                        //     )}
                        //   </table>
                        // </div>
                        null
                      )}
                    </>
                  )}
                  <div data-aos="fade-up" data-aos-delay="800"></div>
                </div>
                <div className="col-lg-6" data-wow-duration="2s" data-wow-delay="0.2s">
                  <img src="img/21.png" alt="" />
                </div>
              </div>
              <div className="feturesTabs">
                <div className="features commonBoxShadow" onMouseOver={() => { setTabActive(1) }}>
                  <div className="section-title" data-wow-delay="0.1s">
                    <h2>Our Features</h2>
                  </div>
                  <div className="centalisedFeatures">
                    <OurFeatures data={packersAndMovers} />
                  </div>
                </div>
                <div className="howItWorks commonBoxShadow" onMouseOver={() => { setTabActive(2) }}>
                  <div className="section-title" data-wow-delay="0.1s">
                    <h2>How It Works</h2>
                  </div>
                  <div className="centalisedHowItWorks">
                    <HowItWorks data={packersAndMovers} />
                  </div>
                </div>
                <div className="trestimonials commonBoxShadow" onMouseOver={() => { setTabActive(3) }}>
                  <div className="section-title" data-wow-delay="0.1s">
                    <h2>Testimonials</h2>
                  </div>
                  <div className="centalisedTestimonials">
                    <Testimonials data={packersAndMovers} />
                  </div>
                </div>
                <div className="faqIntracity commonBoxShadow" onMouseOver={() => { setTabActive(4) }}>
                  <section className="faq">
                    <div className="container">
                      <div className="section-title wow" data-wow-delay="0.1s">
                        <h2>Frequently Asked Questions</h2>
                      </div>
                      <FAQ data={packersAndMovers} />
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PackersAndMoversForm;