import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../newSrc/pages/Landing/components";
import QuickAccess from "../newSrc/components/QuickAccess/QuickAccess";
import LandingPageContextProvider from "../newSrc/context/LandingPageContextProvider";
import { toast } from "react-toastify";

export const Layout = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  if(!isOnline) {
    toast.error("Please check your internet connection and try again!")
  }

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <>
      <LandingPageContextProvider>
        <Header />
        <Outlet />
        <QuickAccess />
      </LandingPageContextProvider>
    </>
  );
};
