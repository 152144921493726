import { ServiceMaker, buildQueryString } from ".";


export const createHandlingChargeSlab = (data: any) =>
ServiceMaker(`handlingChargeSlab/create`, "POST",data);

export const listHandlingChargeSlab = (data?: any) =>
ServiceMaker(`handlingChargeSlab/list?${buildQueryString(data)}`, "GET");

export const updateHandlingChargeSlab = (id:any,data: any) =>
ServiceMaker(`handlingChargeSlab/update/${id}`, "PATCH",data);

export const deleteHandlingChargeSlab = (id:any) =>
ServiceMaker(`handlingChargeSlab/delete/${id}`, "DELETE");