import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ConfirmReloadDialog from "./ConfirmReloadDialog";
import "react-toastify/dist/ReactToastify.css";
import PlaySound from "./containers/manage/PlaySound";
import "./../src/containers/CustomerSection/CustomerBookingTracking/customerBooking.css"
import TagManager from 'react-gtm-module';
import "./newSrc/styles/colors.css"
import "./newSrc/styles/utils.css"
// import Loader from "./Components/Loader/Loader";
// import AllRoutes from "./router";
import Loader from "./Components/Loader/Loader";
import AllRoutes from "./router";
import { subscriptionNotification, updateSubscriptionNotification } from "./subscriptionNotification";
import { socket } from "./store";

const tagManagerArgs = {
  gtmId: 'GTM-WXPKHMBM',
};
TagManager.initialize(tagManagerArgs);

// const AllRoutes = React.lazy(() => import("../../client/src/router"))
function App() {

  const [isSplashScreenOver, setIsSplashScreenOver] = useState(false)

  // TODO this is for notification and it is required so dont remove this comment

  const requestNotification = async () => {
    const notification = await Notification.requestPermission()

    if (notification === 'granted') {
      // const token = await getToken(messaging,{vapidKey:"BBLbfdSNjhA7ykGx3RDd1-OaBKoqE_k5PkJ1_ZZgNb_s9xzHxm3bUHAL9X5x4KUbDzgSaXQg01OXlxhvSG7nJe4"})
      subscriptionNotification()
    }
    else if (notification === 'denied') {
      // alert("you deinied notification permission")
      localStorage.removeItem("pubKey")
      // unsubscribeFromFCM()
    }
    else {
      // unsubscribeFromFCM()
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsSplashScreenOver(true);
    }, 2000);
    requestNotification()
    return () => {
      clearTimeout(timeoutId)
    }
  },[])

  // TODO this socket is for notification and it is required so dont remove this comment

  socket.on("keyUpdated", (_data : any) => {
    localStorage.removeItem("pubKey");
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        updateSubscriptionNotification()
      }
    });
  })

  return (
    <Fragment>
      {
        !isSplashScreenOver && <Loader />
      }
      <Router>
      <AllRoutes />
      </Router>
      <ConfirmReloadDialog />
      <PlaySound />
    </Fragment>
  );
}

export default App;
