import { Dispatch } from 'redux';
import { SET_CUSTOMER_TRACKING_RESET, SET_CUSTOMER_TRACKING_RESPONSE } from './../constants/trackingModule';

export const SetCustomerTracking = (props:any)=>{

    let docketImages = props?.orderData?.docket?.docketImages?.length > 0 ? props?.orderData?.docket?.docketImages.map((x:any) => ({image: x})) : []
    let bthImages = props?.orderData?.bthReqData?.length > 0 ? props?.orderData?.bthReqData : []
    let payload = {
        ...props,
        gallary: [...docketImages, ...bthImages]
    }
    return async (dispatch: Dispatch) => {
        dispatch({ type: SET_CUSTOMER_TRACKING_RESPONSE, payload: payload });
    }
}

export const ResetCustomerTracking = ()=>{
    return async (dispatch: Dispatch) => {
        dispatch({ type: SET_CUSTOMER_TRACKING_RESET });
    }
}