import React, { useRef, useState, useEffect, useCallback } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import "./movableMap.css"
import { connect } from "react-redux";
import { hideLoader, showLoader } from './../../../actions/UserActions';
import {
  addIntraCityPickupDropLocation, removeIntraCityPickupDropLocation, setIntraCityPickupDropLocation, addIntraCityPickupPackages,
  removeIntraCityPickupDropPackages, setIntraCityPackages, createCustomerIntraCityBookingRequest, getIntraCityPrice, setIntraCity,
} from "../../../actions/customerBooking";


function MovableMap(props: any) {
  const mapRef: any = useRef(null);
  const [map, setMap] = useState<any>(null);
  const [mapCenter, setMapCenter] = useState<any>({ lat: '23.0225', lng: '72.5714' });
  const [_currentLatLng, setCurrentLatLng] = useState<any>({ lat: "", lng: "" })
  const [mapMoving, setMapMoving] = useState(false);
  // const [locatedAddress, setLocatedAddress] = useState(``)
  // const [currentCoordinates, setCurrentCoordinates] = useState<any>();

  const handleMapLoad = (mapInstance: any) => {
    setMap(mapInstance);
  }

  const [markers,setMarkers] = useState<any>([
    { id : 0,lat: 23.0225, lng: 72.5714,icon :"https://cdn.prod.website-files.com/62c5e0898dea0b799c5f2210/62e8212acc540f291431bad2_location-icon.png"},
    { id : 1,lat: 23.0225, lng: 72.5714, icon : "https://i.ibb.co/qNYSsGY/download-removebg-preview.png"}
  ])


  // const debounce = (func: any, delay: any) => {
  //   let timeoutId: any;
  //   return (...args: any) => {
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => {
  //       func.apply(null, args);
  //     }, delay);
  //   };
  // };

//  const handleMapCenterChanged = debounce(() => {
//     if (map) {
//       const newCenter = {
//         lat: map.getCenter().lat(),
//         lng: map.getCenter().lng()
//       };
//       setMapCenter(newCenter);
//     } ``
//   },200)

useEffect(() => {
  if (props?.currentLocation?.lat || props?.movableMarkerLocation?.coordinate) {
    setMarkers((prevState: any) => {
      return [
        {
          ...prevState[0],
          lat: parseFloat(props?.currentLocation?.lat),
          lng: parseFloat(props?.currentLocation?.lng)
        },
        {
          ...prevState[1],
          lat: parseFloat(props?.movableMarkerLocation?.coordinate?.latitude),
          lng: parseFloat(props?.movableMarkerLocation?.coordinate?.longitude)
        }
      ];
    });
  }
}, [props?.currentLocation,props?.movableMarkerLocation?.coordinate]);

  const debouncedHandleMapCenterChanged = useCallback(
    async () => {
      if (map) {
        const newCenter = {
          lat: map.getCenter().lat(),
          lng: map.getCenter().lng(),
        };
        setMarkers((prevState:any) => {
          return [
            prevState[0],
            {
              ...prevState[1],
              lat: parseFloat(newCenter.lat),
              lng: parseFloat(newCenter.lng)
            },
          ];
        });
        setMapCenter(newCenter);
        // Call APIs only when map has finished dragging
        // if (!mapMoving) {
          const geocoder = new google.maps.Geocoder();
          const latLng = new google.maps.LatLng(mapCenter.lat, mapCenter.lng);

          geocoder.geocode({ location: latLng }, (results:any, status:any) => {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                const newCoords = {
                  lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng(),
                };
                // Update markers, call API with newCoords, handle address
                props.onMoveAndStop({
                  coords : {
                    latitude: newCenter.lat,
                    longitude: newCenter.lng,
                  }
                }); // Assuming a callback function in parent component
                setCurrentLatLng(newCoords);
                // setLocatedAddress(results[0].formatted_address);
              }
            }
          });
        // }
      }
    },
    [map, mapMoving,props.onMoveAndStop] // Only re-run on map changes and when onMoveAndStop prop changes
  );
  
  let { latLongData, mobileViewState, customerMode, driverMode } = props;

  const { isLoaded, loadError }: any = useLoadScript({
    googleMapsApiKey: "AIzaSyA5kw1jdPoWOGfUDL0o2vW0Ezf2Cvq12OU"
  });

  const handleMapDragStart = () => {
    setMapMoving(true);
  };

  const handleMapDragEnd = () => {
    setMapMoving(false);
    debouncedHandleMapCenterChanged(); // Call debounced function after dragging ends
  };

  useEffect(() => {
    if (latLongData.latFrom !== null || latLongData.lngFrom !== null) {
      const newCenter = {
        lat: latLongData.latFrom,
        lng: latLongData.lngFrom
      };
      setMapCenter(newCenter);
      setCurrentLatLng(newCenter)
    }
    else if (latLongData.latFrom === undefined || latLongData.lngFrom === undefined) {
      // const newCenter = {
      //   lat: `23.0225`,
      //   lng: `72.5714`
      // };
      // setMapCenter(newCenter);
    }
  }, [latLongData])

  const Options = {
    // minZoom: 15,
    // maxZoom: 15,
    fullscreenControl: false,
    styles: [
      // Customize map styles
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ],
    mapTypeControl: false, // Hide map type control
    gestureHandling: 'cooperative', // Disable map gestures
    // draggable: false,
    // draggable: false, // Disable map dragging
    // fullscreenControl: false, // Hide fullscreen control
    // zoomControl: false, // Hide zoom control
    // mapTypeControl: false, // Hide map type control
    // streetViewControl: false, // Hide street view control
    // rotateControl: false, // Hide rotate control
    // scaleControl: false, // Hide scale control
    // scrollwheel: false, // Disable scrollwheel zoom
  }

  // const handleValuesFromMap = () => {
  //   // let TempLatLng = mapCenter;
  //   if (locatedAddress && locatedAddress.length > 0) {
  //     props.setIntraCityPickupDropLocation('place.placeValue', locatedAddress, props?.indexForRedux)
  //     // setMapCenter(TempLatLng)
  //   }
  //   else {
  //     showMessage(`Kindly Select Lcoation on Map Correctly.`, failed, 2000)
  //   }
  // }

  return (
    <div id="MovableMap">
      {loadError && <p>{loadError}</p>}
      {!isLoaded && <p>Loading .. </p>}
      {isLoaded && (
        <div>
          {mobileViewState ?
            <GoogleMap
              mapContainerStyle={{
                margin: customerMode ? "0px" : driverMode ? "0px" : "3%",
                width: customerMode ? "100%" : driverMode ? "100%" : "70%",
                height: customerMode ? "350px" : driverMode ? "200px" : "450px"
              }}
              id={"map"}
              ref={mapRef}
              center={mapCenter}
              onDragStart={handleMapDragStart}
              onDragEnd={handleMapDragEnd}
              zoom={13}
              onLoad={handleMapLoad}
              options={Options}
            >
              {/* <TravellingMarker
                position={ mapCenter }
              /> */}
              {markers.map((marker: any) => (
              <Marker
                key={marker.id}
                position={{
                  lat: parseFloat(marker.lat),
                  lng: parseFloat(marker.lng)
                }}
                icon={mapMoving && marker.id === 1 ? {
                  url: "",
                  scaledSize: new window.google.maps.Size(0, 0)
                } : {
                  url: marker.icon,
                  scaledSize: new window.google.maps.Size(30, 30)
                }}
              />
            ))}
              {mapMoving &&
                <img
                  src="https://i.ibb.co/qNYSsGY/download-removebg-preview.png" // Replace with your drag dot image
                  style={{
                    position: "absolute",
                    top: "42%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "30px"
                  }}
                />
              }
            </GoogleMap>
            :
            <GoogleMap
              id={"map"}
              mapContainerStyle={{
                marginLeft: customerMode ? "0px" : driverMode ? "0px" : "20px",
                width: "100%",
                maxWidth: customerMode ? "1024px" : driverMode ? "280px" : "1500px",
                height: customerMode ? "200px" : driverMode ? "200px" : "600px",
              }}
              ref={mapRef}
              center={mapCenter}
              onDragStart={handleMapDragStart}
              onDragEnd={handleMapDragEnd}
              zoom={13}
              onLoad={handleMapLoad}
              options={Options}
            >
            {markers.map((marker: any) => (
              <Marker
                key={marker.id}
                position={{
                  lat: parseFloat(marker.lat),
                  lng: parseFloat(marker.lng)
                }}
                icon={mapMoving && marker.id === 1 ? {
                  url: "",
                  scaledSize: new window.google.maps.Size(0, 0)
                } : {
                  url: marker.icon,
                  scaledSize: new window.google.maps.Size(30, 30)
                }}
              />
            ))}
              {mapMoving &&
                <img
                  src="https://i.ibb.co/qNYSsGY/download-removebg-preview.png" // Replace with your drag dot image
                  style={{
                    position: "absolute",
                    top: "42%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "30px"
                  }}
                />
              }
            {/* {pathCoordinates.length > 0 && (
            <Polyline
              path={pathCoordinates}
              strokeColor="#0000FF" // Adjust color as needed
              strokeOpacity={0.8} // Adjust opacity as needed
              strokeWeight={2} // Adjust line thickness as needed
            />
          )} */}
            </GoogleMap>
          }
        </div>
      )}
    </div>
  );
}


const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    customerReducer: state.customerLoginReducer,
    customerBooking: state.customerBooking,
    booking: state.booking
  };
};

const mapDispatchToProps = {
  addIntraCityPickupDropLocation,
  removeIntraCityPickupDropLocation,
  setIntraCityPickupDropLocation,
  addIntraCityPickupPackages,
  removeIntraCityPickupDropPackages,
  setIntraCityPackages,
  createCustomerIntraCityBookingRequest,
  getIntraCityPrice,
  setIntraCity,
  showLoader,
  hideLoader,
};

 export default connect(mapStateToProps, mapDispatchToProps)(MovableMap);