import React, { ComponentProps, ReactNode } from "react";

type SelectContainerProps = {
  label: ReactNode;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
} & ComponentProps<"div">;

export const SelectContainer = ({ label, children, className = "", disabled, ...props }: SelectContainerProps) => {
  return (
    <div className={`${className} flex-col gap-4`} {...props}>
      <label className={`fw-500 m-0 ${disabled ? "text-white-300" : ""}`}>
        {label}
      </label>
      {children}
    </div>
  );
};

type TwoColumnSelectContainerProps = {
  label1: string;
  label2: string;
  children1: ReactNode;
  children2: ReactNode;
};

export const TwoColumnSelectContainer = ({
  label1,
  label2,
  children1,
  children2,
}: TwoColumnSelectContainerProps) => {
  return (
    <div className="two-column m-one-column gap-1">
      <SelectContainer label={label1} children={children1} />
      <SelectContainer label={label2} children={children2} />
    </div>
  );
};

type TwoColumnContainerProps = {
  children1: ReactNode;
  children2: ReactNode;
}

export const TwoColumnContainer = ({
  children1,
  children2,
}: TwoColumnContainerProps) => {
  return (
    <div className="two-column m-one-column gap-1">
      {children1}
      {children2}
    </div>
  );
};
