import React from "react";
import { Tooltip } from "@mui/material";

interface IpropesToolTip {
  title?: any;
  placement?: any;
  children?: any;
  id?: any;
  formatter?: any;
  labelFormatter?: any;
}

const CustomToolTip = (props: IpropesToolTip) => {
  return (
    <Tooltip title={<h6 style={{ color: "red",backgroundColor:"white",textAlign:"center" }}>{props.title}</h6>} placement={props.placement} id={props.id}>
      {props.children}
    </Tooltip>
  );
};

export default CustomToolTip;
    