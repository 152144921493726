import React, { Fragment } from "react";

const TrackBar = (props: any) => {
  // console.log(props.statuses);
  try {
    let { statuses, active } = props;
    let classFlag = "trackbar_done";
    return (
      <div className="trackbar_main">
        {statuses.map((item: any, i: number) => {
          let cl = classFlag;
          if (item == active) classFlag = "trackbar_todo";
          return (
            <div key={i} className={cl}>
              {item}
            </div>
          );
        })}
      </div>
    );
  } catch (err : any) {
    return null;
  }
};

const statuses = [
  "Open",
  "In Progress",
  "Closed By User",
  "Closed By Customer",
];
const statuses2 = ["Open", "In Progress", "closed By System"];

const Title = (props: any) => {
  let { status } = props;
  // console.log(status);
  const statusSplit = status.split("@");
  // console.log(statusSplit);
  let procStatus;

  switch (statusSplit[0]) {
    case "O": {
      // result = "Open";
      procStatus = "Open";
      break;
    }
    case "I": {
      // result = "In Progress";
      procStatus = "In Progress";
      break;
    }
    case "CU": {
      // result = "Closed By User";
      procStatus = "Closed By User";
      break;
    }
    case "CC": {
      // result = "Closed By Customer";
      procStatus = "Closed By Customer";
      break;
    }
    case "CS": {
      // result = "Closed By Customer";
      procStatus = "Closed By System";
      break;
    }
    default: {
      // result = "Open";
      procStatus = "Open";
    }
  }
  return (
    <div className="row trackhead" style={{ borderBottom: "1px solid grey" }}>
      <div
        className="col-md-4 col-sm-12 col-xs-12"
        style={{ textAlign: "center", paddingTop: "10px" }}
      >
        <h4>Complaint Status:</h4>
      </div>
      <div className="col-md-8 col-sm-12 col-xs-12">
        {" "}
        <TrackBar
          statuses={status === "CS" ? statuses2 : statuses}
          active={procStatus}
        />
      </div>
    </div>
  );
};

const ComplaintTrack = (props: any) => {
  // console.log(props);
  return (
    <Fragment>
      <Title status={props.chat.COMP.complaintStatus} />
    </Fragment>
  );
};

export default ComplaintTrack;
