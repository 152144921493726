import React from 'react'
import { Heading } from "../UI";

const Investors = () => {
    return (
        <section className="bg-primary-400 p-3 flex-col-center gap-1">
            <Heading className="text-white-900">Investors</Heading>
            <img loading="lazy" src="/assets/about-us/kabra-express.png" alt="Kabra Express" className="kabra-express-footer-logo | br-1" />
        </section>
    )
}

export default Investors