import React from 'react'
import { GoGraph } from "react-icons/go"

const OurFeatures = (props: any) => {
  return (
    <div className="featured">
      <div className="medium-4 columns rectangleDiv">
        <div className="round-icon">
          <img src="motorcycle-delivery-single-box.png" />
        </div>
        <div className={"featureTitles"}>{props.data.Features[1].headings[0]}</div>
        <p>{props.data.Features[2].content[0]}</p>
      </div>
      <div className="medium-4 columns rectangleDiv">
        <div className="round-icon">
          <span><GoGraph /></span>
        </div>
        <div className={"featureTitles"}>{props.data.Features[1].headings[1]}</div>
        <p>{props.data.Features[2].content[1]}</p>
      </div>
      <div className="medium-4 columns rectangleDiv">
        <div className="round-icon">
          <span><GoGraph /></span>
        </div>
        <div className={"featureTitles"}>{props.data.Features[1].headings[2]}</div>
        <p>{props.data.Features[2].content[2]}</p>
      </div>
      <div className="medium-4 columns rectangleDiv">
        <div className="round-icon">
          <span><GoGraph /></span>
        </div>
        <div className={"featureTitles"}>{props.data.Features[1].headings[3]}</div>
        <p>{props.data.Features[2].content[3]}</p>
      </div>
      <div className="medium-4 columns rectangleDiv">
        <div className="round-icon">
          <span><GoGraph /></span>
        </div>
        <div className={"featureTitles"}>{props.data.Features[1].headings[4]}</div>
        <p>{props.data.Features[2].content[4]}</p>
      </div>
      <div className="medium-4 columns rectangleDiv">
        <div className="round-icon">
          <span><GoGraph /></span>
        </div>
        <div className={"featureTitles"}>{props.data.Features[1].headings[5]}</div>
        <p>{props.data.Features[2].content[5]}</p>
      </div>
    </div>
  )
}

export default OurFeatures