import React, { ReactNode, useEffect, useRef } from 'react'
import { useLocation } from "react-router-dom";
import { MobileFooterNavigation } from "../../../../components";
import { useLandingPageContext } from "../../../../hooks/useLandingPageContext";
import { useWindow } from "../../../../hooks/useWindow";

type LandingPageMainComponentProps = {
    children: ReactNode
}

const LandingPageMainComponent = ({ children }: LandingPageMainComponentProps) => {
    const sectionRef = useRef<HTMLElement | null>(null);
    const mobileRef = useRef<HTMLElement | null>(null);
    const { width } = useWindow();
    const location = useLocation();
    const { setIsScrolled } = useLandingPageContext();

    useEffect(() => {
        sectionRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
        mobileRef.current?.scrollTo({ top: 0, behavior: "smooth" })
    }, [location]);

    // useEffect
    useEffect(() => {
        // Scroll event listener
        const handleScroll = () => {
            // Check if scrolled down 400px
            if (sectionRef.current && sectionRef.current.scrollTop > 10) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        // Add event listener to mainRef
        sectionRef.current?.addEventListener('scroll', handleScroll);

        // Cleanup function
        return () => {
            sectionRef.current?.removeEventListener('scroll', handleScroll);
        };
    }, [sectionRef]); // Only re-run effect if mainRef changes
    return (
        <section className={`landing-page-main-component ${width > 600 ? "custom-scrollbar" : ""}`} ref={sectionRef}>
            <section className="for-mobile custom-scrollbar" ref={mobileRef}>
                {children}
            </section>
            <MobileFooterNavigation />
        </section>
    )
}

export default LandingPageMainComponent