import { ServiceMaker, buildQueryString } from "./index";

export const doorDeliverylistDoorDelivery = (data:any) =>
ServiceMaker(`doorDelivery/listDoorDelivery?${buildQueryString(data)}`,"GET")

export const readyForDispatchaTotal = (data:any) =>
ServiceMaker(`doorDelivery/readyForDispatchCount?${buildQueryString(data)}`,"GET")

export const getCancelDoorDeliveryBookingData = (data:any) =>
ServiceMaker(`doorDelivery/cancel/requests?${buildQueryString(data)}`,"GET")

export const reqForcancelDoorDelivery = (data:any) =>
ServiceMaker(`doorDelivery/cancel/request`, "POST",data) 

export const reqForcancelDoorDeliveryFromCustomer = (data:any) =>
ServiceMaker(`customerBooking/doorDelivery/cancel`, "PATCH",data) 

export const processDoorDeliveryCancelRequest = (data:any) =>
ServiceMaker(`doorDelivery/cancel/process`, "POST",data) 

export const sendSMSForDooDelivery = (data:any) =>
ServiceMaker(`doorDelivery/doorDeliverySms`, "POST",data) 

export const sendVoiceMSG = (data:any) =>
ServiceMaker(`doorDelivery/voiceMessageDelivery`, "POST",data) 

export const getCustomerType = (data:any) =>
ServiceMaker(`doorDelivery/customerType`, "POST",data) 
