import { fromJS, List, updateIn } from "immutable";
import {
  SET_COMPANIES,
  SET_BRANCHES,
  CHANGE_MANAGE_SIMPLE,
  CHANGE_RATE_SLAB,
  ADD_RATE_SLAB,
  DEL_RATE_SLAB,
  FRANCHISE_ADDED_SUCCESS,
  SET_FRANCHISES,
  REMOVE_FRANCHISEE,
  ACTIVATE_OR_DEACTIVATE_FRANCHISEE,
  SET_FRANCHISE_TO_EDIT,
  RESET_MANAGE,
  SET_ROUTES,
  RESET,
  SET_HEAD,
  FRANCHISE_HEAD_CREATED,
  SET_DELIVERY_COMMISSION_SUCCESS,
  SET_DELIVERY_COMMISSION,
  SET_FRANCHISE_COMPANIES,
} from "../constants/franchise";

import { toast } from "react-toastify";

const initState = fromJS<any>({
  companies: [],
  branches: [],
  franchiseCompanies: [],
  company: null,
  branch: null,
  rateType: "flat",
  franchise: {},
  franchises: [],
  slab: [],
  booking: {
    enabled: false,
    rateType: "",
    slab: [],
    flat: "",
  },
  delivery: {
    enabled: false,
    rateType: "",
    slab: [],
    flat: "",
  },
  isBooking: false,
  isDelivery: false,
  routes: [],
  route: null,
});

const initFranchise = {
  _id: null,
  company: null,
  branch: null,
  booking: {},
  delivery: {},
};

export default (state = initState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case SET_COMPANIES: {
      return state.set("companies", List(action.payload));
    }

    case SET_BRANCHES: {
      return state.set("branches", List(action.payload));
    }

    case SET_FRANCHISE_COMPANIES: {
      return state.set("franchiseCompanies", List(action.payload));
    }

    case CHANGE_MANAGE_SIMPLE: {
      const { what, val } = action.payload;
      let newState = state.setIn(what, val);
      if (what[0] == "company") {
        newState = newState.set("head", null);
      }
      return newState;
    }

    case CHANGE_RATE_SLAB: {
      const { idx, where, what, val } = action.payload;

      return updateIn(state, where, (items:any) =>
        items.map((i: any, index: number) =>
          index == idx ? { ...i, [what]: val } : i
        )
      );
    }

    case ADD_RATE_SLAB: {
      const { what } = action.payload;
      const slabs = state.getIn([...what]);
      // console.log("slabs:",slabs.get(slabs.size-1))
      const minval =
        slabs && slabs.size > 0 ? slabs.get(slabs.size - 1).max : 0;
      return state.setIn(
        what,
        slabs && slabs.size > 0
          ? slabs.push({ min: parseInt(minval) + 1 })
          : List([{ min: parseInt(minval) + 1 }])
      );
    }

    case DEL_RATE_SLAB: {
      const { what, idx } = action.payload;
      return updateIn(state, what, (items:any) =>
        items.filter((_item: any, index: number) => index != idx)
      );
    }

    case FRANCHISE_ADDED_SUCCESS: {
      if (state.get("edit")) {
        toast.success("Franchise updated successfully!");
      } else {
        toast.success("Franchise created successfully!");
      }

      return state
        .set("route", null)
        .set("isBooking", false)
        .setIn(["booking"], {});
    }

    case SET_DELIVERY_COMMISSION_SUCCESS: {
      toast.success("Delivery commission set");
      return state.set("isDelivery", false).setIn(["delivery"], {});
    }
    case SET_FRANCHISES: {
      const { franchises, head } = action.payload;

      return state
        .set("franchises", List(franchises))
        .set("head", fromJS(head[0]));
    }
    case REMOVE_FRANCHISEE: {
      const id = action.payload;

      return state.set(
        "franchises",
        state.get("franchises")
          ? state.get("franchises").filter((f: { _id: string }) => f._id !== id)
          : List([])
      );
      // .set('head', fromJS(head[0]))
    }
    case ACTIVATE_OR_DEACTIVATE_FRANCHISEE: {
      const { id, active } = action.payload;
      return state.set(
        "franchises",
        state.get("franchises")
          ? state
              .get("franchises")
              .map((f: { _id: string }) =>
                f._id !== id ? f : { ...f, active }
              )
          : List([])
      );
    }
    case SET_DELIVERY_COMMISSION: {
      const { commissionType } = action.payload;
      let payload = {};
      if (commissionType == "slab") {
        payload = { rateType: commissionType, slab: action.payload.slab };
      }
      if (commissionType == "flat") {
        payload = { rateType: commissionType, flat: action.payload.flat };
      }
      return state.setIn(["delivery"], fromJS(payload));
    }
    case SET_FRANCHISE_TO_EDIT: {
      let { childCompany, route, rateType, individual } = action.payload;
      const { bookingCommission, deliveryCommission } = rateType;

      const addToState = { ...action.payload };

      addToState.isBooking = action.payload.bookingComm ? true : false;
      addToState.isDelivery = action.payload.deliveryComm ? true : false;

      addToState.booking = {
        rateType: isNaN(bookingCommission.flat) ? "slab" : "flat",
        ...rateType.bookingCommission,
        slab: isNaN(bookingCommission.flat) ? List(bookingCommission.slab) : [],
      };
      addToState.delivery = {
        rateType: isNaN(deliveryCommission.flat) ? "slab" : "flat",
        ...rateType.deliveryCommission,
        slab: isNaN(deliveryCommission.flat)
          ? List(deliveryCommission.slab)
          : [],
      };
      const elem: any = document.getElementById("manage_franchise");
      if (typeof elem === null) {
      } else {
        elem?.click();
        elem?.scrollIntoView({ behavior: "smooth" });
      }

      let childCompanyOption =
        individual == true
          ? { label: "Individuals", value: "i" }
          : { label: childCompany.companyName, value: childCompany._id };
      return state
        .merge(addToState)
        .set("company", childCompanyOption)
        .set("route", { label: route.name, value: route._id })
        .set("rateType", rateType)
        .set("edit", true);
    }

    case RESET_MANAGE: {
      return state.merge(initFranchise);
    }

    case SET_ROUTES: {
      const { franchise, own } = action.payload;
      return state.set("routes", List(franchise.concat(own)));
    }

    case RESET: {
      return state
        .set("childCompany", null)
        .set("route", null)
        .set("isBooking", false)
        .set("isDelivery", false)
        .setIn(["booking"], fromJS({ rateType: "flat", flat: "" }))
        .setIn(["delivery"], fromJS({ rateType: "flat", flat: "" }));
    }

    case SET_HEAD: {
      const { amount, limit, id: head } = action.payload;

      return state.merge({ amount, limit, head });
    }

    case FRANCHISE_HEAD_CREATED: {
      toast.success("Franchise Head Created successfully!");
      return state.merge({
        head: null,
        amount: "",
        limit: "",
        company: null,
      });
    }

    default: {
      return state;
    }
  }
};
