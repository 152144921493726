// react
import React from 'react'
// react-router-dom
// ui components
import { Button, Heading } from "../UI"
// constants (data | images)
import { aboutUs } from "../../constants/data"
import { gmailImg, linkedinImg, twitterImg } from "../../constants/images"
import { useNavigate } from "react-router-dom"


// FC: OurTeam Component
const OurTeam = () => {
  // useHistory 
  const navigate = useNavigate();
  // component return
  return (
    <main className="| bg-primary-400">
      <section className="page-container-grid">
        <div className="| my-container flex-col gap-1 p-3">
          <Heading className="| text-white-900">Meet our Team</Heading>
          <ul className="about-us-team-list | four-column gap-2 t-two-column m-one-column">
            {aboutUs.team.length > 0 && aboutUs.team.slice(0, 4).map(item => <li key={item.name} className="about-us-tema-list-item | flex-col jc-between ai-center gap-8 br-4 p-1 shadow-large bg-white-900 text-center">
              <img loading="lazy" src={item.img} alt="profile" style={{ maxWidth: "80px" }} className="circle" />
              <span className="| fw-600 text-primary-900 fs-list-item-heading">{item.name}</span>
              <span className="| fw-500 text-primary-900 fs-list-item-text">{item.title}</span>
              <div className="| flex-center gap-8">
                <img loading="lazy" src={linkedinImg} alt="linkedin" className="pointer" style={{ maxWidth: "25px" }} />
                <img loading="lazy" src={twitterImg} alt="twitter" className="pointer" style={{ maxWidth: "25px" }} />
                <img loading="lazy" src={gmailImg} alt="gmail" className="pointer" style={{ maxWidth: "25px" }} />
              </div>
            </li>)}
          </ul>
        </div>
        <div className="| bg-white-800 p-3 flex-col-center gap-8">
          <p className="| m-0 fs-subheading fw-600 text-center">Got interested in working with us?</p>
          <p className="| m-0 fs-medium fw-500">Join us now!</p>
          <Button variant="primary" action="primary" className="| fs-button" onClick={() => navigate("/careers",{replace : true})}>Explore Careers</Button>
        </div>
      </section>
    </main>
  )
}

export default OurTeam