import { facebookImg, instagramImg, linkedinImg, youtubeImg } from "./images";

type HeaderNavListItemType = {
  id: number;
  value: "services" | "partners" | "customer";
  href: string;
  variant:
    | "neutral"
    | "primary"
    | "secondary"
    | "accent"
    | "danger"
    | "warning"
    | "success"
    | "info";
  action: "primary" | "secondary" | "tertiary";
};

export const headerNavListItems: HeaderNavListItemType[] = [
  {
    id: 0,
    value: "services",
    href: "customer",
    variant: "primary",
    action: "primary",
  },
  {
    id: 1,
    value: "partners",
    href: "partners",
    variant: "primary",
    action: "tertiary",
  },
  {
    id: 2,
    value: "customer",
    href: "customer",
    variant: "primary",
    action: "tertiary",
  },
];

export const headerMobileMenuListItems = [
  {
    id: 1,
    value: "About Us",
    href: "about-us",
    isDropdown: false,
  },
  {
    id: 2,
    value: "Services",
    isDropdown: true,
    dropdownItems: [
      {
        id: 1,
        value: "Intra City",
        isDropdown: false,
      },
      {
        id: 2,
        value: "Domestic",
        isDropdown: false,
      },
      {
        id: 3,
        value: "Part Load",
        isDropdown: false,
      },
      {
        id: 4,
        value: "Full Load",
        isDropdown: false,
      },
      {
        id: 5,
        value: "Packers & Movers",
        isDropdown: false,
      },
      {
        id: 6,
        value: "International",
        isDropdown: false,
      },
    ],
  },
  {
    id: 3,
    value: "Partners",
    isDropdown: true,
    dropdownItems: [
      {
        id: 1,
        value: "Direct Sales Agent",
        isDropdown: false,
      },
      {
        id: 2,
        value: "Individual With Fleet",
        isDropdown: false,
      },
      {
        id: 3,
        value: "Transport Company",
        isDropdown: false,
      },
      {
        id: 4,
        value: "Warehouse",
        isDropdown: false,
      },
      {
        id: 5,
        value: "Investors",
        isDropdown: false,
      },
    ],
  },
  {
    id: 4,
    value: "Customer",
    isDropdown: false,
  },
  {
    id: 5,
    value: "Our Networks",
    href: "our-network",
    isDropdown: false,
  },
  {
    id: 6,
    value: "Others",
    isDropdown: true,
    dropdownItems: [
      {
        id: 1,
        value: "Careers",
        href: "careers",
        isDropdown: false,
      },
      {
        id: 2,
        value: "Insurance",
        isDropdown: false,
        isComingSoon: true,
      },
      {
        id: 3,
        value: "Store",
        isDropdown: false,
        isComingSoon: true,
      },
      {
        id: 4,
        value: "Buy/Sell Vehicle",
        isDropdown: false,
        isComingSoon: true,
      },
    ],
  },
  {
    id: 7,
    value: "Resources",
    isDropdown: true,
    dropdownItems: [
      {
        id: 1,
        value: "Privacy Policy",
        href: "privacy-policy",
        isDropdown: false,
      },
      {
        id: 2,
        value: "User Agreement",
        href: "user-agreement",
        isDropdown: false,
      },
      {
        id: 3,
        value: "T&C",
        href: "t&c",
        isDropdown: false,
      },
      // {
      //     id: 4,
      //     value: "FAQ",
      //     isDropdown: false,
      // },
      // {
      //     id: 5,
      //     value: "Blog",
      //     isDropdown: false,
      // },
    ],
  },
];
export const landingPageSidebarItems = [
  "Book Now",
  "Features",
  "How It Works",
  "Testimonails",
  "FAQs",
] as const;

export type LandingPageMainNavItem = (typeof landingPageMainNavItem)[number];
export type CustomerBookingNavItemType =
  (typeof customerBookingNavItem)[number];
export const landingPageMainNavItem = [
  "Intra City",
  "Express Parcel",
  "Part Load & Full Load",
  "Packers & Movers",
  "International",
] as const;
export const customerBookingNavItem = [
  "Intra City",
  "Express Parcel",
  "Part Load",
  "Full Load",
  "Packers & Movers",
  "International",
] as const;
export const customerHistoryNavItem = ["Online", "Branch"] as const;
export type CustomerHistoryNavItem = (typeof customerHistoryNavItem)[number];
export const customerSubHistoryNavItem = [
  "Upcoming",
  "OnGoing",
  "Completed",
  "Cancelled",
  "MonthWise",
] as const;
export type CustomerSubHistoryNavItem =
  (typeof customerSubHistoryNavItem)[number];
export const branchSubHistoryNavItem = [
  "OnGoing",
  "Completed",
  "Cancelled",
  "MonthWise",
] as const;
export type BranchSubHistoryNavItem = (typeof branchSubHistoryNavItem)[number];
export type LandingPagePartnersNavItem =
  (typeof landingPagePartnersNavItem)[number];
export const landingPagePartnersNavItem = [
  "Direct Sales Agent",
  "Individual With Fleet",
  "Transport Company",
  "Warehouse",
  "Investors",
] as const;

export const mobileLandingPageCustomerListItem = [
  {
    id: 0,
    value: "Intra City",
    img: "/assets/landing-page/customer-service/intra-city.webp",
    description:
      "Delivery your package within city on demand by bike, tempo & bolero",
  },
  {
    id: 1,
    value: "Express Parcel",
    img: "/assets/landing-page/customer-service/domestic.jpg",
    description:
      "Delivery your packages to another cities with options such as branch delivery and door delivery",
  },
  {
    id: 2,
    value: "Part Load & Full Load",
    img: "/assets/landing-page/customer-service/part-load.jpg",
    description:
      "Delivery your less than truck load to another cities with door pickup and door delivery service",
  },
  // {
  //     id: 3,
  //     value: "Full Load",
  //     img: "/assets/landing-page/customer-service/full-load.webp",
  //     description: "Delivery your full truck load to another cities on demand by selecting from wide range of vehicles",
  // },
  {
    id: 3,
    value: "Packers & Movers",
    img: "/assets/landing-page/customer-service/packers-and-movers.jpg",
    description:
      "Get your home and office appliances, furniture and fixture packed, transported and unpacked to a new place",
  },
  {
    id: 4,
    value: "International",
    img: "/assets/landing-page/customer-service/international.jpg",
    description:
      "Delivery your package in other countries through flight or frieght ship by getting quotation from us",
  },
] as const;

type MobileLandingPagePartnerListItem = {
  id: number;
  value: (typeof landingPagePartnersNavItem)[number];
  img: string;
  description: string;
};
export const mobileLandingPagePartnerListItem: MobileLandingPagePartnerListItem[] =
  [
    {
      id: 0,
      value: "Direct Sales Agent",
      img: "/assets/landing-page/customer-service/intra-city.webp",
      description:
        "Sign Up & Start earning by helping transporters understand how to use the platform and support customers to book various kind of transportation service",
    },
    {
      id: 1,
      value: "Individual With Fleet",
      img: "/assets/landing-page/customer-service/domestic.jpg",
      description:
        "Sign up and start earning by delivery intracity & intercity orders by your fleet with flexible working hours",
    },
    {
      id: 2,
      value: "Transport Company",
      img: "/assets/landing-page/customer-service/part-load.jpg",
      description:
        "Sign up to manage your transport business digitally turning to strong processes and higher customer satisfaction. Additionally earn extra revenue by being part of the network get more business from other transporters and expand delivery network for your customers",
    },
    {
      id: 3,
      value: "Warehouse",
      img: "/assets/landing-page/customer-service/full-load.webp",
      description:
        "Sign up and make your warehouse spaces available to platform's customers and transporters",
    },
    {
      id: 4,
      value: "Investors",
      img: "/assets/landing-page/customer-service/packers-and-movers.jpg",
      description:
        "Sign up and make taptap.in live for your city's transporters empowering them with latest technology & help customers book various kind of transportation services",
    },
  ];

export const aboutUs = {
  headings: [
    "Kabra Logitech Private Limited provides an efficient and cost-effective solution, helping businesses save time and resources.",
    "We automate and streamline logistics processes by providing businesses with an efficient ERP solution.",
    "With over two decades of experience in the logistics industry, Kabra Logitech is your go- to choice for innovative and effective ERP solutions.",
    "Our company's core product, «TapTap» helps businesses manage their inventory, shipping, and tracking information in a single platform.",
  ],
  headline:
    "TAP TAP- An ERP software that takes care of the needs of all the agents. One can track the route of their logistics services along with the income and expenses. This would help the client in having all the data in one place making work hassle-free. Kabra Logitech Private Limited provides an efficient and cost-effective solution, helping businesses save time and resources. We automate and streamline logistics processes by providing businesses with an efficient ERP solution. With over two decades of experience in the logistics industry, Kabra Logitech is your go- to choice for innovative and effective ERP solutions.",
  team: [
    {
      id: 0,
      name: "Chintan Kabra",
      title: "Chief Executive Officer",
      linkedin: "#",
      twitter: "#",
      mail: "#",
      img: "/assets/about-us/team/placeholder.jpg",
    },
    {
      id: 1,
      name: "Jay Patel",
      title: "Full Stack Developer",
      linkedin: "#",
      twitter: "#",
      mail: "#",
      img: "/assets/about-us/team/placeholder.jpg",
    },
    {
      id: 2,
      name: "Prashant Bhatiya",
      title: "React JS Developer",
      linkedin: "#",
      twitter: "#",
      mail: "#",
      img: "/assets/about-us/team/placeholder.jpg",
    },
    {
      id: 3,
      name: "Vraj Patel",
      title: "React JS Developer",
      linkedin: "#",
      twitter: "#",
      mail: "#",
      img: "/assets/about-us/team/placeholder.jpg",
    },
    {
      id: 4,
      name: "Pankaj Gautam",
      title: "React JS Developer",
      linkedin: "#",
      twitter: "#",
      mail: "#",
      img: "/assets/about-us/team/placeholder.jpg",
    },
    {
      id: 5,
      name: "Uttam Makwana",
      title: "React JS Developer",
      linkedin: "#",
      twitter: "#",
      mail: "#",
      img: "/assets/about-us/team/placeholder.jpg",
    },
    {
      id: 6,
      name: "Dhruvi Prajapati",
      title: "HR Executive",
      linkedin: "#",
      twitter: "#",
      mail: "#",
      img: "/assets/about-us/team/placeholder.jpg",
    },
  ],
  facts: [
    {
      id: 0,
      title: "Clients",
      number: "10000+",
    },
    {
      id: 1,
      title: "Transporters",
      number: "1000+",
    },
    {
      id: 2,
      title: "Vehicles",
      number: "20000+",
    },
    {
      id: 3,
      title: "Branches",
      number: "100+",
    },
  ],
  products: [
    {
      id: 0,
      title: "TapTap for Customer",
      description:
        "Become a customer and access our premium services such as Intra City, Domestic, Part Load, Full Load.",
    },
    {
      id: 1,
      title: "TapTap for Transporter",
      description:
        "Become a trasnporter and access our premium services such as Intra City, Domestic, Part Load, Full Load.",
    },
  ],
  missionAndVision: [
    {
      title: "Our Vision",
      description:
        "Our vision is to make TapTap the foremost choice for logistics services in the nation, offering unmatched solutions and an exceptional delivery experience.",
    },
    {
      title: "Our Mission",
      description:
        "Our mission at TapTap is to excel as the most cost-effective service provider and capital-efficient company while simultaneously establishing our position as the foremost logistics partner within the industry.",
    },
  ],
  coreValues: [
    {
      id: 0,
      title: "Integrity",
      description:
        "Upholding unwavering honesty and ethical principles, fostering trust and respect",
    },
    {
      id: 1,
      title: "Passion for Excellence",
      description:
        "Relentlessly pursuing the highest standards, igniting growth and innovation",
    },
    {
      id: 2,
      title: "Innovation & Learning",
      description:
        "Embracing change, fueling growth through continuous exploration and improvement",
    },
    {
      id: 3,
      title: "Customer First",
      description:
        "Dedication to exceed expectations, delivering exceptional solutions and experiences",
    },
    {
      id: 4,
      title: "Confidence & Respect",
      description:
        "Valuing self-assurance and treating all with dignity and esteem",
    },
    {
      id: 5,
      title: "Building a community",
      description:
        "Fostering connections, collaboration, and shared growth through unity",
    },
  ],
};

export const contactUs = {
  information: [
    {
      id: 0,
      title: "Location",
      description:
        "02/13 Vallabh Flats, Vijay Chowk, NR Sardar Patel Diamond Market, Bapunagar, Ahmedabad, Gujarat 380024",
    },
    {
      id: 1,
      title: "Email",
      description: "customersupport@kabraexpress.com",
    },
    {
      id: 2,
      title: "Contact Number",
      description: "+917575857777\n+91 9099982886",
    },
  ],
};

export const careers = {
  options: [
    {
      id: 0,
      title: "React JS Developer",
      description: "xyz",
      location: "Prahlad Nagar",
      isOpening: false,
      tag: "IT",
    },
    {
      id: 1,
      title: "Node JS Developer",
      description: "xyz",
      location: "Prahlad Nagar",
      isOpening: false,
      tag: "IT",
    },
    {
      id: 2,
      title: "UI/UX Developer",
      description: "xyz",
      location: "Prahlad Nagar",
      isOpening: false,
      tag: "IT",
    },
    {
      id: 3,
      title: "DevOps Developer",
      description: "xyz",
      location: "Prahlad Nagar",
      isOpening: false,
      tag: "IT",
    },
    {
      id: 4,
      title: "Full Stack Developer",
      description: "xyz",
      location: "Prahlad Nagar",
      isOpening: false,
      tag: "IT",
    },
    {
      id: 5,
      title: "Software Tester",
      description: "xyz",
      location: "Prahlad Nagar",
      isOpening: false,
      tag: "IT",
    },
    {
      id: 6,
      title: "Branch Manager",
      description: "xyz",
      location: "Bapunagar, Ahmedabad",
      isOpening: false,
      tag: "Branch Manager",
    },
    {
      id: 7,
      title: "Branch Executive",
      description: "xyz",
      location: "Bapunagar, Ahmedabad",
      isOpening: false,
      tag: "Branch Executive",
    },
    {
      id: 8,
      title: "Tele Calling Support",
      description: "xyz",
      location: "Bapunagar, Ahmedabad",
      isOpening: false,
      tag: "Tele Calling Support",
    },
    {
      id: 9,
      title: "Fleet Driver",
      description: "xyz",
      location: "Bapunagar, Ahmedabad",
      isOpening: false,
      tag: "Fleet Driver",
    },
    {
      id: 10,
      title: "Branch Labour",
      description: "xyz",
      location: "Bapunagar, Ahmedabad",
      isOpening: false,
      tag: "Branch Labour",
    },
  ],
};

export const termsAndConditions = [
  "It's deemed that consignor/consignee has read all the terms & conditions and have agreed mutually before booking goods for transportation through us.",
  "Goods/Parcels are items handed over for transportation.",
  "Consignor is the sender of goods/Parcels.",
  "Consignee is receiver of goods/Parcels.",
  "Consignment note is receipt issued to consignor at the time of booking, and we the transporter is the entity that will transport goods/parcels.",
  "It is presumed that consignor has kept original invoice and other legal documents required for transportation within the goods.",
  "It is of consignor's & consignee's responsibility to properly and adequately pack goods required as per its type & nature. Kabra is not liable for any kind of leakage, breakage, pilferage and/or spoilage of goods.",
  "We don't support transportation of perishable goods.",
  "In Case of any transportation of perishable goods, Kabra would not be responsible for any loss/damage caused.",
  "We only support Branch to Branch and Branch-Door good Service.",
  "We transport all goods booked by us through road. We won't be responsible for any loss/damage occurred to consignor and/or consignee due to uncontrollable events such as riots, earthquake, theft, accident, fire, floods, etc. It's advisable to get marine insurance for goods/parcels.",
  "Delivery of goods shall be taken within three days after their arrival at delivery destination, failing which the consignee/consignor will be liable to pay additional demurrage charge (Demurrage refers to the charge that the merchant pays for the use of the container within the terminal beyond the free time period) at Rs.20/- per day per goods/parcels.",
  "If any Competent Govt. authority detains goods, we won't be responsible for the same and consignor/consignee shall indemnify us for any expenses, losses, damages suffered by the company. Consignor/consignee would be responsible to obtain all the permit required before booking and shall provide us requisite documents for transportation of goods.",
  "In the event of loss of consignment note goods might won't be delivered and consignor/consignee shall have no objection if the company delivers the goods directly to consignee on the basis consignment number or name of consignee as mentioned on consignment note.",
  "Consignor of the goods would be responsible to declare the actual value, and description of goods at the time of booking. We are not aware about the value, nature, type and description of goods given for transport.",
  "We at Kabra would be Liable for any kind of loss/damage/missing of goods. It is restricted to freight value charged on consignment note or Rs.500 whichever is less.",
  "We are not Transporting any goods which are prohibited by law in India, goods hazardous in nature and/or are dangerous to human life. Consignor shall not book any of such goods for transport and will be solely responsible.",
  "If the delivery is not taken from our warehouse within 15 days from the date of booking than we are not responsible for such goods and we have right to sell the goods to recover the total charges due as per consignment note.",
  "GST is applicable on total freight amount including other charges exceeding Rs.750 and its responsibility of the service receiver to pay tax as per reverse charge mechanism of section 9 (3) of CGST/SGST Act 2017.",
  "Any misinformation on consignment note shall be intimidated to our head office as soon as possible.",
  "Any complaint related to transportation of goods shall be communicated to our head office within seven days.",
  "Any dispute will be subject to Ahmedabad Jurisdiction.",
];

export const footerLinks = [
  { img: facebookImg, link: "https://www.facebook.com/KabraExpressLogistics" },
  { img: instagramImg, link: "https://www.instagram.com/kabra.express/" },
  {
    img: linkedinImg,
    link: "https://www.linkedin.com/company/kabra-express-logistics-private-limited/",
  },
  {
    img: youtubeImg,
    link: "https://www.youtube.com/channel/UCt4wx_zJAyQ-el--Jy3iurQ",
  },
];
