import React from "react";
import { toast, ToastOptions, Bounce } from 'react-toastify';

type MessageType = {
  children : React.ReactNode
}

export const toastMessage = (message: string | React.ReactNode, options?: ToastOptions) => {
    const defaultOptions: ToastOptions = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    };

    const mergedOptions = { ...defaultOptions, ...options };

    toast(message, mergedOptions);
};

export const Message = ({ children} : MessageType) => (
    <div>{children}</div>
);