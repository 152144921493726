// react
import React from 'react'
// constants (data | icon)
import { aboutUs } from "../../constants/data"
import { BuildingACommunityIcon, ConfidenceAndRespectIcon, CustomerFirstIcon, InnovationAndLearningIcon, IntegrityIcon, PassionforExcellenceIcon } from "../../constants/icons"
// ui components
import { Heading } from "../UI";


// variables
const coreValuesIcons = [<IntegrityIcon />, <PassionforExcellenceIcon />, <InnovationAndLearningIcon />, <CustomerFirstIcon />, <ConfidenceAndRespectIcon />, <BuildingACommunityIcon />];


// FC: Values Component
const Values = () => {
    return (
        <main className="| bg-white-800">
            <section className="page-container | my-container flex-col gap-20 p-3">
                {/* Mission and Vision */}
                <Heading>Our Core Values</Heading>
                <ul className="| three-column t-two-column m-one-column m-0 gap-2 gap-x-1">
                    {aboutUs.coreValues.length > 0 && aboutUs.coreValues.map((item, index) => <li key={item.title} className="| bg-white-700 flex-col gap-8 p-1 br-4">
                        <span className="| p-1 circle bg-white-600 as-start fs-icon">{coreValuesIcons[index]}</span>
                        <h1 className="| fs-list-item-heading m-0 fw-500">{item.title}</h1>
                        <p className="| fs-list-item-text m-0 fw-400">{item.description}</p>
                    </li>
                    )}
                </ul>
                {/* Mission and Vision */}
                <ul className="| two-column m-one-column m-0 gap-2">
                    {aboutUs.missionAndVision.length > 0 && aboutUs.missionAndVision.map(item => <li key={item.title} className="| bg-white-700 flex-col gap-1 py-2 px-20 br-4 border-left-primary">
                        <h1 className="| fs-subheading fw-600 m-0">{item.title}</h1>
                        <p className="| fs-medium m-0 fw-400">{item.description}</p>
                    </li>
                    )}
                </ul>
            </section>
        </main>
    )
}

export default Values