import { fromJS } from "immutable";
import { showMessage, success } from "../utils/message";
import * as yup from "yup";
import { isArrayCheck } from "../containers/generics/CheckArray";

const initState = {
  allbranches: null,
  branchMap: new Map(),
  branches: null,
  allPlatformBranches: [],
  branchForm: fromJS({
    branchName: "",
    shortName: "",
    active: true,

    working: {
      rohh: "",
      rchh: "",
      mohh: "",
      mchh: "",
      tohh: "",
      tchh: "",
      wohh: "",
      wchh: "",
      thohh: "",
      thchh: "",
      fohh: "",
      fchh: "",
      saohh: "",
      sachh: "",
      suohh: "",
      suchh: "",
    },
    pContact: "",
    sContact: "",
    email: "",
    paid: false,
    topay: false,
    credit: false,
    foc: false,
    noncash: false,
    dCredit: false,
    dNoncash: false,
    isBooking: false,
    isDelivery: false,
    ownership: "o",
    isHub: false,
    isWarehouse: false,
    isIntermediateBranch: false,
    childBranches: [],
    childFleets: [],
    maxBalance: "",
    tableCash: "",
    branchImage: "",
    businessType: "",
    overhead: "",

    // [changes
    deliveryCharge: {
      interState: false,
      intraState: false
    },
    // changes]

    cashBranch: false,
    customerVerification: false,
    allDocketVerification: false,
    withoutEWayBillDocketVerification: false,
    photoVerification: false,
    otpVerification: false,
    receiverAllDocketVerification: false,
    receiverPhotoVerification: false,
    receiverOtpVerification: false,
    pendingDelivery: false,
    doorDeliverySms: false,
    qrPrinter: false,
    deliveryWithQr: false,
    potentialCustomerAmount: "",
    maxPendingDeliveryAmount: "",
    maxPendingDeliveryPackages: "",
    cams: [],
    l1: "",
    l2: "",
    pin: "",
    city: "",
    placeId: "",
    shortAddress: "",
    rented: {
      rentAgreement: null,
      rentAmount: null,
      rentDate: null,
    },
    errors: {
      branchName: null,
      shortName: null,
      active: null,

      working: {
        rohh: "",

        rchh: "",

        mohh: "",

        mchh: "",

        tohh: "",

        tchh: "",

        wohh: "",

        wchh: "",

        thohh: "",
        thchh: "",

        fohh: "",

        fchh: "",

        saohh: "",

        sachh: "",

        suohh: "",

        suchh: "",
      },
      pContact: null,
      sContact: null,
      email: null,
      paid: null,
      topay: null,
      credit: null,
      foc: null,
      noncash: null,
      dCredit: null,
      dNoncash: null,
      isBooking: null,
      isDelivery: null,
      ownership: null,
      isHub: null,
      isWarehouse: null,
      isIntermediateBranch: null,
      cashBranch: null,
      childBranches: [],
      childFleets: [],
      maxBalance: "",
      tableCash: "",
      cams: [],
      l1: null,
      l2: null,
      pin: null,
      city: null,
      shortAddress: null,
      placeId: null,
      rented: {
        rentAgreement: null,
        rentAmount: null,
        rentDate: null,
      },
      maxPendingDeliveryAmount: null,
      potentialCustomerAmount: null,
      maxPendingDeliveryPackages: null,
      bpc_margin_top: 0,
      bpc_margin_bottom: 12,
      bpc_margin_left: 0,
      bpc_margin_right: 0,
      deliveryCharge: {
        interState: "",
        intraState: ""
      },
    },
  }),
  filteredBranches: {
    query: {},
    data: {},
  },
};

const days = [
  "mohh",
  "mchh",
  "tohh",
  "tchh",
  "wohh",
  "wchh",
  "thohh",
  "thchh",
  "fohh",
  "fchh",
  "saohh",
  "sachh",
  "suohh",
  "suchh",
];

const time = yup.date();

const branchSchemaFieldWise: any = fromJS({
  branchName: yup
    .string()
    .required("Branch name is required")
    .min(3, "Too short")
    .max(40, "Too long"),
  shortName: yup
    .string()
    .required("Short name is required")
    .min(3, "Too short")
    .max(5, "Too long"),
  working: {
    rohh: time,
    rchh: time,
    mohh: time,
    mchh: time,
    tohh: time,
    tchh: time,
    wohh: time,
    wchh: time,
    thohh: time,
    thchh: time,
    fohh: time,
    fchh: time,
    saohh: time,
    sachh: time,
    suohh: time,
    suchh: time,
  },
  pContact: yup
    .string()
    .required("Primary contact is required")
    .matches(/^[\d]{10}$/, "Invalid Primary contact"),
  sContact: yup.string().matches(/^[\d]{10}$/, "Invalid secondary contact"),
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email"),
  paid: yup.boolean().required("booking.paid is required"),
  topay: yup.boolean().required("booking.topay is required"),
  credit: yup.boolean().required("booking.credit is required"),
  foc: yup.boolean().required("booking.foc is required"),
  // noncash: yup.boolean().required("booking.noncash is required"),
  dCredit: yup.boolean().required("booking.credit is required"),
  // dNoncash: yup.boolean().required("delivery.noncash is required"),
  isBooking: yup.boolean().required("Booking field is required"),
  isDelivery: yup.boolean().required("Delivery field is required"),
  // isTr: yup.boolean().required('this field is required'),
  // trBy: '',
  // trArr: [],
  ownership: yup
    .string()
    .required("Ownership info required")
    .matches(/^(o|r)$/, "Invalid ownership info"),
  isHub: yup.boolean().required("Please Select hub field"),
  isWarehouse: yup.boolean().required("Please Select isWarehouse field"),
  isIntermediateBranch: yup
    .boolean()
    .required("Please Select isIntermediateBranch field"),
  cams: yup.string().url("invalid Cam url"),

  l1: yup
    .string()
    .required("Address line1 is required")
    .min(3, "Too short")
    .max(65, "Too long"),
  l2: yup
    .string()
    .required("Address line2 is required")
    .min(3, "Too short")
    .max(65, "Too long"),
  pin: yup
    .string()
    .required("Pincode is required")
    .matches(/^[1-9][\d]{5}$/, "Invalid pincode"),
  // maxBalance: yup.string().required("Maximum balance is required"),
  // manager: yup.string().required("Manager is required"),
  city: yup.object().shape({
    name: yup.string().required("City name is required"),
    _id: yup.string().required("City _id is required"),
    // Add additional validation rules for other properties of the city object if needed
  }).required("City object is required"),
  location: yup.object().shape({
    latitude: yup
      .number()
      .min(0)
      .max(90),
    longitude: yup
      .number()
      .min(-180)
      .max(180),
  }),
});

const procWorking = (working: any) => {
  let { regular, custom } = working;
  let result: any = {};
  let days = [
    { front: "m", name: "mon" },
    { front: "t", name: "tue" },
    { front: "w", name: "wed" },
    { front: "th", name: "thu" },
    { front: "f", name: "fri" },
    { front: "sa", name: "sat" },
    { front: "su", name: "sun" },
  ];
  let dayMap: any = {
    mon: "m",
    tue: "t",
    wed: "w",
    thu: "th",
    fri: "f",
    sat: "sa",
    sun: "su",
  };
  let rohh =
    working.custom.length > 0
      ? new Date(regular.regStart)
      : new Date("2020-10-14T04:30:00.443Z"),
    rchh =
      working.custom.length > 0
        ? new Date(regular.regEnd)
        : new Date("2020-10-14T12:30:00.443Z");

  result["rohh"] = rohh;
  result["rchh"] = rchh;

  days.forEach((day) => {
    result[day.front + "ohh"] = rohh;
    result[day.front + "chh"] = rchh;
  });

  custom.forEach((day: any) => {
    let ohh =
      working.custom.length > 0
        ? new Date(day.cusStart)
        : new Date("2020-10-14T04:30:00.443Z"),
      chh =
        working.custom.length > 0
          ? new Date(day.cusEnd)
          : new Date("2020-10-14T12:30:00.443Z");

    result[dayMap[day["cusDay"]] + "ohh"] = ohh;
    result[dayMap[day["cusDay"]] + "chh"] = chh;
  });

  return result;
};

const branchReducer = (state: any = initState, action: any) => {
  switch (action.type) {
    case "BR_CHANGE_SIMPLE": {
      let branchForm = state.branchForm;
      const { index, val }: any = action.payload;
      branchForm = branchForm.setIn(index, val);
      try {
        branchSchemaFieldWise.getIn(index).validateSync(val);
        branchForm = branchForm.setIn(["errors", ...index], null);
      } catch (err: any) {
        if (err.name == "ValidationError") {
          branchForm = branchForm.setIn(["errors", ...index], err.message);
        }
      }
      if (
        index.length == 2 &&
        ["rohh", "romm", "rchh", "rcmm"].includes(index[1])
      ) {
        days.forEach((day) => {
          if (day.includes(index[1].substring(1, 4))) {
            branchForm = branchForm.setIn(["working", day], val);
          }
        });
        return { ...state, branchForm };
      }
      return { ...state, branchForm };
    }

    case "BRANCH_HUB": {
      let branchForm = state.branchForm;
      return {
        ...state,
        branchForm: branchForm.set("childBranches", action.payload),
      };
    }

    case "CHILD_FLEETS": {
      let branchForm = state.branchForm;
      return {
        ...state,
        branchForm: branchForm.set("childFleets", action.payload),
      };
    }

    case "MAX_AMOUNT": {
      let branchForm = state.branchForm;
      return {
        ...state,
        branchForm: branchForm.set("maxBalance", action.payload),
      };
    }
    case "BR_CAM_ADD": {
      let branchForm = state.branchForm;
      let cams = branchForm
        .get("cams")
        .toJS()
        .slice();
      cams.push(action.payload);
      branchForm = branchForm.set("cams", fromJS(cams));
      return { ...state, branchForm };
    }
    case "BR_CAM_REMOVE": {
      let i = action.payload;
      let branchForm = state.branchForm;
      let cams = branchForm
        .get("cams")
        .toJS()
        .slice();
      cams.splice(i, 1);
      branchForm = branchForm.set("cams", fromJS(cams));
      return { ...state, branchForm };
    }
    case "FETCH_BRANCH_SUCCESS": {
      let branchForm = state.branchForm;
      let data = action.payload,
        sameFields = [
          "branchName",
          "shortName",
          "pContact",
          "sContact",
          "email",
          "isBooking",
          "isDelivery",
          "_id",
          "childBranches",
          "childFleets",
          "maxBalance",
          "tableCash",
          "rentAgreement",
          "branchImage",
          "businessType",
          "overhead",
          "qrPrinter",
          "customerVerification",
          "allDocketVerification",
          "withoutEWayBillDocketVerification",
          "photoVerification",
          "otpVerification",
          "receiverAllDocketVerification",
          "receiverPhotoVerification",
          "receiverOtpVerification",
          "pendingDelivery",
          "doorDeliverySms",
          "deliveryWithQr",
          // "expenseDepartment",

          "deliveryCharge",
          "active",
          "maxPendingDeliveryPackages",
          "maxPendingDeliveryAmount",
          "potentialCustomerAmount",
          "shortAddress"
        ];
      sameFields.forEach((field) => {
        branchForm = branchForm.setIn([field], data[field]);
      });
      branchForm = branchForm.setIn(["cams"], fromJS(data["cams"]));

      let { paid, toPay: topay, credit, foc, noncash } = data.booking;
      let { credit: dCredit, noncash: dNoncash } = data.delivery;
      let { booking: isBooking, delivery: isDelivery } = data.operation;
      let { l1, l2, pincode: pin, city, placeId } = data.address;

      let biltyPrintConfig = {
        margin: {
          top: 0,
          bottom: 12,
          left: 0,
          right: 0
        },
      }

      if (data.biltyPrintConfig && data.biltyPrintConfig.margin) {
        biltyPrintConfig = {
          margin: {
            top: data.biltyPrintConfig.margin?.top || -0.2,
            bottom: data.biltyPrintConfig.margin?.bottom || 12,
            left: data.biltyPrintConfig.margin?.left || 0,
            right: data.biltyPrintConfig.margin?.right || 0
          },
        }
      }
      let {
        hub: isHub,
        isWarehouse: isWarehouse,
        isIntermediateBranch: isIntermediateBranch,
        cashBranch: cashBranch,
        bLocation: bLocation,
        manager: manager,
      } = data;
      let own;
      switch (data.ownership) {
        case 0: {
          own = "o";
          break;
        }
        case 1: {
          own = "r";
          branchForm = branchForm.set("rented", fromJS(data.rented));
          break;
        }
      }

      branchForm = branchForm
        .set("topay", topay)
        .set("paid", paid)
        .set("credit", credit)
        .set("dCredit", dCredit)
        .set("dNoncash", dNoncash)
        .set("foc", foc)
        .set("noncash", noncash)
        .set("isBooking", isBooking)
        .set("isDelivery", isDelivery)
        .set("l1", l1)
        .set("l2", l2)
        .set("pin", pin)
        .set("city", city)
        .set("placeId", placeId?.placeId)
        .set("placeValue", placeId?.placeValue)
        .set("ownership", own)
        .set("isHub", isHub)
        .set("isWarehouse", isWarehouse)
        .set("isIntermediateBranch", isIntermediateBranch)
        .set("cashBranch", cashBranch)
        .set("bLocation", bLocation)
        .set("manager", manager)
        .set("bpc_margin_top", biltyPrintConfig?.margin?.top)
        .set("bpc_margin_bottom", biltyPrintConfig?.margin?.bottom)
        .set("bpc_margin_left", biltyPrintConfig?.margin?.left)
        .set("bpc_margin_right", biltyPrintConfig?.margin?.right)

      if (isArrayCheck(data.working.custom) && data.working.regular) {
        const result = procWorking(data.working);
        Object.keys(result).map((key) => {
          branchForm = branchForm.setIn(["working", key], result[key]);
        });
      } else {
        const result = procWorking(data.working);
        Object.keys(result).map((key) => {
          branchForm = branchForm.setIn(["working", key], result[key]);
        });
      }

      console.log('branchForm', branchForm)
      return {
        ...state,
        branchForm,
      };
    }
    case "BRANCH_RESET": {
      return {
        ...state,
        branchForm: initState.branchForm,
      };
    }
    case "ALL_PLATFORM_BRANCHES": {
      return {
        ...state,
        allPlatformBranches: action.payload,
      };
    }
    case "BRANCH_CREATE_OR_UPDATE": {
      let { message, response: branch } = action.payload;
      showMessage(message || "Branch Updated Successfully", success);

      let branches = action.branchesArr.map((tempBranch: any) => {
        if (tempBranch._id == branch._id) {
          return branch;
        } else {
          return tempBranch;
        }
      });

      return {
        ...state,
        branchForm: initState.branchForm,
        branches,
        opBranch:
          state.opBranch && state.opBranch._id == branch._id
            ? branch
            : state.opBranch,
      };
    }
    case "SET_ERROR": {
      try {
        let { branchForm } = state;
        const { payload } = action;
        branchSchemaFieldWise
          .getIn(payload)
          .validateSync(branchForm.getIn(payload));
        branchForm = branchForm.setIn(["errors", ...payload], null);
        return { ...state, branchForm };
      } catch (err: any) {
        const { payload } = action;
        if (err.name == "ValidationError") {
          let { branchForm } = state;
          branchForm = branchForm.setIn(["errors", ...payload], err.message);
          return { ...state, branchForm };
        }
      }
      return state;
    }
    case "DELETE_BRANCH": {
      showMessage("Branch Deleted successfully", success);
      return { ...state, branchForm: initState.branchForm };
    }

    case "SET_BRANCHES": {
      const branches = action.payload;
      const branchMap = new Map();
      for (let i = 0; i < branches.length; i++) {
        if (branchMap.has(branches[i]._id)) {
          continue;
        } else {
          branchMap.set(branches[i]._id, branches[i]);
        }
      }
      return {
        ...state,
        allbranches: branches,
        branchMap: branchMap,
      };
    }

    case "SET_FILTERED_BRANCHES": {
      const Branches = action.payload.data;
      // const Branches = filteredBranches.reduce((prev: any, now: any) => {
      //   return {...prev, [now.company._id]: now}
      // }, {})
      return {
        ...state,
        filteredBranches: {
          ...state.filteredBranches,
          query: {
            ...state.filteredBranches.query,
            company: action.payload.query
          },
          data: {
            ...state.filteredBranches.data,
            Branches
          }
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default branchReducer;
