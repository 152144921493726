export const SMS_ACCEPTED_RECORDS = "SMS_ACCEPTED_RECORDS";
export const SMS_REJECTED_RECORDS = "SMS_REJECTED_RECORDS";

export const CALL_NORMAL_DELIVERY_ACCEPTED_RECORDS = `CALL_NORMAL_DELIVERY_ACCEPTED_RECORDS`;
export const CALL_NORMAL_DELIVERY_PENDING_RECORDS = `CALL_NORMAL_DELIVERY_PENDING_RECORDS`;
export const CALL_NORMAL_DELIVERY_REJECTED_RECORDS = `CALL_NORMAL_DELIVERY_REJECTED_RECORDS`;

export const CALL_DOOR_DELIVERY_ACCEPTED_RECORDS_WITH_OPTION_PRESSED = `CALL_DOOR_DELIVERY_ACCEPTED_RECORDS_WITH_OPTION_PRESSED`;
export const CALL_DOOR_DELIVERY_ACCEPTED_RECORDS_WITHOUT_OPTION_PRESSED = `CALL_DOOR_DELIVERY_ACCEPTED_RECORDS_WITHOUT_OPTION_PRESSED`;

export const CALL_DOOR_DELIVERY_PENDING_RECORDS = `CALL_DOOR_DELIVERY_PENDING_RECORDS`;
export const CALL_DOOR_DELIVERY_REJECTED_RECORDS = `CALL_DOOR_DELIVERY_REJECTED_RECORDS`;

export const TOTAL_CALL_SMS_COUNT = 'TOTAL_CALL_SMS_COUNT';
