import { ServiceMaker } from "./index";
const qs = (obj:any) => {
  return Object.entries(obj)
    .map(([key, val]:any) => `${key}=${encodeURIComponent(val)}`)
    .join("&");
};

export const fetchPackagesForDelivery = (data:{
  branch: string
}) =>
  ServiceMaker("booking/delivery/filter", "POST", data);

export const fetchPendingPOD = (data:any) =>
  ServiceMaker(`booking/pendingPOD?${qs(data)}`, "GET");

export const uploadPendingPOD = (data:{
  docket: string,
  deliveryRegNumber?: string,
  deliveredToName?: string,
  receiverContact: number,
  otp: number
}) =>
  ServiceMaker(`package/pendingPOD`, "PATCH", data);

export const returnODAPackagesService = (data:{
  packages: [
    string
  ],
  loginType: string,
  remarkText : string
}) =>
  ServiceMaker("package/returnODA", "POST", data);

export const fetchODAPackagesService = (data:{
  sub: string,
  entity: string
}) =>
  ServiceMaker("booking/outfordelivery", "POST", data);


export const fetchCFOPayment = (data:any) =>
  ServiceMaker("delivery/customerBooking/makePayment", "POST", data);