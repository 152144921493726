import React from "react";
import { default as PAPER } from "@mui/material/Paper";

interface IpropesPaper {
  style?: any;
  elevation?: number;
  onClick?: any;
  children?: any;
  className?: any;
  square?: boolean;
  classes?: any;
  key?: any;
}

export const Paper = (props: IpropesPaper) => {
  let {
    style,
    elevation,
    onClick,
    className,
    square,
    classes,
    key,
    children,
  } = props;
  return (
    <PAPER
      elevation={elevation}
      style={style}
      onClick={onClick}
      className={className}
      square={square}
      classes={classes}
      key={key}
    >
      {children}
    </PAPER>
  );
};
