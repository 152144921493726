import { APPEND_CUSTOMER_CHAT, SET_CUSTOMER_CHAT } from "../constants/customerChat";

const initState = {
    feedbackNumber : null,
    docketNumber : null,
    userId : null,
    customerId : null,
    chat : []
}

const customerChat = (
    state: any = initState,
    action: { [k: string]: any }
  ) => {
    switch (action.type) {
      case SET_CUSTOMER_CHAT: {
        return {
          ...state,
          ...action.payload
        }
      }
      case APPEND_CUSTOMER_CHAT: {
        return {
            ...state,
            chat: [...state.chat, action.payload]
        }
      }
      default: {
        return state;
      }
    }
  };

  
export default customerChat;
