const prefix = "AUTH:";
const socketPrefix = "server/" + prefix;

export const REGISTER_SOCKET = socketPrefix + "REGISTER_SOCKET";

export const REGISTER_SUCCESS = prefix + "REGISTER_SUCCESS";

export const REGISTER_FAIL = prefix + "REGISTER_FAIL";

export const REAUTH_SOCKET = socketPrefix + "REAUTH_SOCKET";

export const JOI_OFD_CITY_ROOM_SOCKET = socketPrefix + "JOI_OFD_CITY_ROOM_SOCKET";
