import API from "../axios";
import assert from "assert";
import { AxiosError, AxiosResponse } from "axios";

const generic_error = "Something Went Wrong !";

export const buildQueryString = (obj: Object): string => {
  if (typeof obj === "object" && obj !== null) {
    return Object.entries(obj)
      .map(
        ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
      )
      .join("&");
  }
  return "";
};
export function queryStringToJSON(queryString:string) {
  var pairs = queryString.split('&');
  var result:any = {};
  pairs.forEach(function(pair:any) {
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });
  console.log(result)
  return result;
}
/**
 *
 * @param {string} url -example branch/manage
 * @param {string} method - GET | POST | DELETE | PUT
 * @param {object} data - http body payload
 * @param {number=} level
 *
 * @example
 * ServiceMaker('branch/manage','POST',{field1: 'fsdff',field2: 20})
 *
 */

export function ServiceMaker<T = any>(
  url: string,
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
  data = {},
  level?: number
): Promise<T> {
  return new Promise((resolve, reject) => {
    const processRequest = (res: AxiosResponse) => {
      assert.equal(res.data.status, 1);
      if (level == 0) resolve(res.data);
      resolve(res.data.response);
    };
    const handleError = (err: AxiosError) => {
      if (err instanceof assert.AssertionError) {
        reject(generic_error);
      } else {
        if (err && err.response && err.response.status == 401) {
          // window.location.assign('/login')
          sessionStorage.removeItem("kabra:token");
          alert("Session timed out.");
        }
        // const isCustomer: any = sessionStorage.getItem("IsCustomer")
        // if (err.response?.status === 404 && isCustomer) {
        //   window.location.href = "https://taptap.in/404"
        // }
        reject(
          err && err.response && err.response.data
            ? err.response.data 
            : err && err.response
            ? err.response
            : err
        );
      }
    };
    switch (method) {
      case "GET": {
        API.get(url)
          .then(processRequest)
          .catch(handleError);
        break;
      }
      case "POST": {
        API.post(url, data)
          .then(processRequest)
          .catch(handleError);
        break;
      }
      case "PATCH":
        API.patch(url, data)
          .then(processRequest)
          .catch(handleError);
        break;
      case "PUT":
        API.put(url, data)
          .then(processRequest)
          .catch(handleError);
        break;
      case "DELETE": {
        API.delete(url, { data })
          .then(processRequest)
          .catch(handleError);
        break;
      }
    }
  });
}
