import { ServiceMaker } from "./index";

export const setMissingPackages = (data: any) =>
  ServiceMaker("package/remark", "POST", data);

export const setFoundPackages = (data: any) =>
  ServiceMaker("package/found/missingParcel", "PATCH", data);

export const setReceivedPackages = (data: any) =>
ServiceMaker("package/received", "PATCH", data);

export const sendBackToOrigin = (data: {
  docket: string;
  branch: string;
  BTOCharge: number;
}) => ServiceMaker("booking/sendDocketToOrigin", "POST", data);

export const sendBackToNewDestination = (data: {
  docket: string;
  branch: string;
  NDCharge: number;
  newDestination: string;
}) => ServiceMaker("booking/sendDocketToNewDestination", "POST", data);
