import API from "../axios";
import { buildQueryString, ServiceMaker } from "./index";

export const createAssetType = (data: {
  consumable: boolean;
  isUnique: boolean;
  assetName: string;
  category: string;
}) => API.post("asset/type/create", data);

export const editAssetType = (
  id: string,
  data: {
    category: string;
    assetName: string;
    consumable?: boolean;
    isUnique?: boolean;
  }
) =>
  new Promise((resolve, reject) => {
    API.put("asset/type/update/" + id, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err.response));
  });
export const getAssetTypes = (company: any) => {
  return new Promise((resolve, reject) => {
    API.get("asset/type/list/by/company/" + company)
      .then((res) => resolve(res.data.response))
      .catch((err) => reject(err.response));
  });
};
export const createAssetBalance = (
  data: any
  // branch: string;
  // type: string;
  // supplier: string;
  // price: number;
  // qty: number;
  // uniqueId: string;
  // assetBalance: string;
  // action: string;
) => {
  return new Promise((resolve, reject) => {
    API.post("asset/balance/create", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err.response));
  });
};

export const createMultipleUniqueAsset = (data: any) =>
  ServiceMaker("asset/createMultipleUniqueAsset", "POST", data);

export const updateAssetBalance = (data: {
  branch: string;
  type: string;
  supplier: string;
  price: number;
  qty: number;
  uniqueId: string;
  assetBalance: string;
  action: string;
  id: string;
}) => {
  return new Promise((resolve, reject) => {
    API.put(`asset/balance/update/${data.id}`, data)
      .then((res) => resolve(res))
      .catch((err) => reject(err.response));
  });
};

export const removeAssetBalance = (data: any) => {
  return new Promise((resolve, reject) => {
    API.delete(`asset/balance/delete/${data.id}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err.response));
  });
};

export const getAssetBalance = (data: {
  branch?: string;
  assetType?: string;
  balances?: [string];
}) => ServiceMaker("asset/balance/list", "POST", data);

export const getAssetBalanceDetailed = (data: {
  branch?: string;
  assetType?: string;
  balances?: [string];
}) => ServiceMaker("asset/balance/list/detailed", "POST", data);

export const viewAssetBalanceDetails = (id: string) =>
  ServiceMaker("asset/balance/view/" + id, "GET");

export const listAssetType = (data: any) =>
  ServiceMaker("asset/type/list", "POST", data);

export const viewAssetBalance = (id: string) =>
  ServiceMaker("asset/balance/view/" + id, "GET");

export const assetTrackingList = (data: any) =>
  ServiceMaker(
    `asset/request/listAssetLogs?${buildQueryString(data)} `,
    "GET"
  );

export const assetuniqueIdsList = (data: any) =>
  ServiceMaker(`asset/count/listUniqueIds?${buildQueryString(data)}`, "GET");

export const getAssetExpenseRecord = (data:any) =>
ServiceMaker(`expense/getExpenseRecord?${buildQueryString(data)}`, "GET");