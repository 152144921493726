import React, { useEffect, useRef, useCallback } from "react";
import { useState } from "react";
// import Loader from "@mui/material/CircularProgress";
import { connect } from "react-redux";
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { Autocomplete } from "@react-google-maps/api";
import { showLoader, hideLoader } from './../../../actions/UserActions';
import { customerLogin } from "../../../actions/customerLogin";
import HowItWorks from '../../../Components/How It Works/HowItWorks';
import FAQ from '../../../Components/FAQ/FAQ';
import { setPartLoadPickupDropLocation } from './../../../actions/customerBooking';
import Testimonials from "../../../Components/Tetimonials/Testimonials";
import OurFeatures from "../../../Components/Features/OurFeatures";
// import { useGoogleMapJsLoader } from "../../../hooks/useGoogleMapJsLoader";
import { useMediaQuery, useTheme } from "@mui/material";
import { showHttpError } from "../../../utils/message";
import { listAssetSubType } from "../../../services/assetType";
import { Select12 } from "../../../Components/Selectcomponents/Select";
import { Dialog, DialogActions, DialogContent } from "../../../Components/Dialog/DialogComponent";
import { ButtonDefault, ButtonPrimary } from "../../../Components/Button/Buttoncontainer";
// import { Link, useNavigate } from "react-router-dom";
import MyPartLoadForm from "./PartLoadFormik";
import { Link, useNavigate } from "react-router-dom";
// import { getOtp } from "../../../services/area";

const FullloadForm = (props:any) => {

  // const navigate = useNavigate();
  const destIn: any = useRef();
  const navigate = useNavigate()
  const originIn: any = useRef();
  const [date, setDate] = useState("");
  const [tabValue, setTabValue] = useState(0);
  // const [truckNos, setTruckNos] = useState("");
  const [tabActive, setTabActive] = useState(0);
  const [vehicleModel, setVehicleModel] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [assetType, setAssetType] = useState<any>({});
  // const [sourceLocation, setSourceLocation] = useState("");
  const [destinations, setDestinations] = useState<any>([{}]);
  // let mobileviewinitial = window.innerWidth > 770 ? false : true;
  const [autoCompleteDest, setAutoCompleteDest] = useState<any>(null);
  // const [ismobileview, setismobileview] = useState(mobileviewinitial);
  const [autoCompleteOrigin, setAutoCompleteOrigin] = useState<any>(null);
  // const [pickupDropLocation, setPickupDropLocation] = useState<any>({})

  const [assetTypeList, setAssetTypeList] = useState([]);
  const [assetSubType, setAssetSubType] = useState<any>({});
  const [allAssetTypeList, setAllAssetTypeList] = useState([]);
  const [assetSubTypeList, setAssetSubTypeList] = useState([]);
  const [vehicleModelTypeList, setVehicleModelTypeList] = useState([]);

  // const [contactNo, setContactNo] = useState(0);
  const [customerPassword, setCustomerPassword] = useState();
  const [customerMobileNo, setCustomerMobileNo] = useState();

  
  const topFunction2 = (props:any)=>{
    document.body.scrollTop = 0;
    {props === "book" ? document.documentElement.scrollTop = 0 : props === "feature" ? document.documentElement.scrollTop = 515 : props === "how" ? document.documentElement.scrollTop = 1040 :
    props === "test" ? document.documentElement.scrollTop = 1575 : props === "faq's" ? document.documentElement.scrollTop = 2100 : 0 }
  } 
  
  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2
  
  const onLoadOrigin = useCallback(function callback(autocomplete:any) {
    setAutoCompleteOrigin(autocomplete)
  }, [])

  const onLoadDest = useCallback(function callback(autocomplete:any) {
    setAutoCompleteDest(autocomplete)
  }, [])

  const defineAssetType = async () => {
    props?.showLoader();
    try {
      let response: any = await listAssetSubType();
      setAllAssetTypeList(response);
      let ans: any = [];
      response && response?.length > 0 && response?.map((x: any) => { ans?.push({ label: x?.assetType?.assetName, value: x?.assetType?._id }) });
      setAssetTypeList(ans);
      console.log(`listassetType response`, response);
      props?.hideLoader();
    } catch (error) {
      props?.hideLoader();;
      showHttpError(error);
    }
  }

  const findVehicleName = () => {
    const ans: any = [];
    allAssetTypeList?.map((v: any) => {
      if (v?.assetType?._id === assetType?.value) {
        ans?.push(v);
      }
    })
    console.log(`assetType zsddkcvjsdcksmdcsdcmdc`, ans);
    let finalAns = ans?.length > 0 && ans?.map((x: any) => { return { label: x?.name, value: x?._id } })
    setAssetSubTypeList(finalAns)
  }

  const findVehicleModel = () => {
    let ans: any;
    allAssetTypeList?.map((v: any) => {
      if (v?.assetType?._id === assetType?.value) {
        ans = v?.bodyTypes
      }
    })
    console.log(`assetType zsddkcvjsdcksmdcsdcmdc`, ans);
    let finalAns = ans?.length > 0 && ans?.map((x: any, index: any) => { console.log(`indexsdekrvzsdvnjh`, x, `zsdv`, index); return { label: x, value: x } })
    console.log(`assetType zsddkcvjsdcksmdcsdcmdc zsdfvzxcvmnv`, ans, `finalAns`, finalAns);
    setVehicleModelTypeList(finalAns)
  }

  const handleAdd = () => {
    setDestinations([...destinations, {}])
  }

  const handleRemove = (i: any) => {
    const newDestinations = [...destinations]
    newDestinations.splice(i, 1)
    setDestinations(newDestinations)
  }

  const handleChangeDestinations = (e: any, i: any) => {
    let updated = destinations.map((xx: any, ii: any) => {
      if (ii === i) {
        return e.formatted_address;
      } else {
        return xx;
      }
    });
    setDestinations(updated)
  }

  const fullLoad = {
    "HowItWorks": [{ headings: ["Fill in your data", "Get an instant quote", "Book a container"] },
    {
      img: ["how-it-works-img1.png",
        "how-it-works-img2.png",
        "how-it-works-img3.png"]
    }],
    "Features": [
      {
        img: ["https://img.icons8.com/bubbles/80/000000/cash-in-hand.png",
          "https://img.icons8.com/bubbles/80/000000/security-checked.png",
          "https://img.icons8.com/bubbles/80/000000/security-checked.png",
          "https://img.icons8.com/bubbles/80/000000/security-checked.png",
          "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
          "https://img.icons8.com/bubbles/80/000000/conference-call.png"]
      },
      { headings: ["Wide service offering", "Multiple quotations", "Flexible transportation", "Adjustable validity dates", "Email it to anyone", "Easy administration"] },
      {
        content: ["Choose from 140+ services and 600 ports worldwide.",
          "Submit up to 10 port-pair combinations at once with our multilane feature. 20",
          "Select port-to-port or door-to-door – or whatever combination you need.",
          "Choose your validity date for more security down the road with our valid-from option.",
          "Send an email copy to yourself, colleagues, or customers.",
          "Access all quotations via My Quotations."]
      }],
    "FAQ": [
      {
        question: ["Do I need to register to use Quick Quotes ?",
          "What is the standard free-time allowance ?",
          "How long is the rate valid ?",
          "Is it possible to extend the validity of the quotation ?",
          "Can rates on Quick Quotes be negotiated ?",
          "Does any General Rate Increase (GRI) apply ?",
          "What are the charges that need to be settled at the load port along with the freight ?"]
      },
      {
        answer: ["Yes, simply complete the registration process. We will create an account that gives you access to our Quick Quotes tool.",
          "The free-time allowance for Hapag-Lloyd depends on your routing. You can find details here. If you require more leeway, you can use our Additional Freetime option to buy up to 10 extra days when you place your booking.",
          "Please check the validity of the quotation, as it may differ depending on the origin and destination. Use our “valid-from” option to select the starting date for validity.",
          "No, the validity of the offer cannot be extended. You can, however, simply submit a new Quick Quotes request to get a valid quote at a later date.",
          "Quick Quotes is an online pricing tool that gives you container shipping rates based on which you can place a booking instantly. There is no option to negotiate these rates online.",
          "Your Quick Quotes contain a fixed rate. No GRI applies during the validity of the offer. Surcharges are subject to change and are those valid at the time of the shipment.",
          "All charges are either export- (origin), import- (destination), or sea-freight-related. Depending on the agreement between the shipper and consignee, the charges either have to be prepaid or collected (there might also be legal requirements in some countries).",
        ]
      }],
    "Testimonials": [{ img: [] }, { name: [] }, { possition: [] }, { company: [] },
    {
      feedback: ["The Full-Truckload transport provided by Kabra Express was very efficient and cost-effective. I was able to get my goods shipped across long distances without any hassle. The team was very professional and helped me with all my shipping needs. I would recommend their services to anyone looking for a reliable and affordable shipping solution.",
        "We have been using Kabra Express for our full-truckload transport needs for a while now and have always been impressed with their service. They are always on time and their rates are very competitive. We would recommend their services to anyone looking for a reliable and cost-effective full-truckload transport solution.",
        "Kabra Express offered me a very competitive rate for their Full-Truckload transport service. I was very happy with the level of service and the timeliness of the delivery. I would recommend their services to anyone looking for a high-quality, cost-effective solution for their shipping needs."
      ]
    }]
  }

  // const multipleExist = (arr: any, values: any) => {
  //   return values.every((value: any) => {
  //     return arr.includes(value);
  //   });
  // };

  // const setLocationByTypingRes = (e: any, index: any) => {
  //   let area = e.address_components.find((x: any) => {
  //     return multipleExist(x.types, [
  //       "sublocality_level_1",
  //       "sublocality",
  //       "political",
  //     ]);
  //   });

  //   let pincode = e.address_components.find((x: any) => {
  //     return multipleExist(x.types, [
  //       'postal_code'
  //     ]);
  //   })

  //   // if(pincode === undefined){
  //   //   resetSelectedFormLocation();
  //   //   return showHttpError("Kindly Select Location More Specific !")
  //   // }

  //   let city = e.address_components.find((x: any) => {
  //     return x.types.length === 2 && multipleExist(x.types, ["locality", "political"]);
  //   })

  //   // if (index > 0 && getData?.pickDropLocation[0]?.contactPerson?.city !== "") {
  //   //   if (getData?.pickDropLocation[0]?.contactPerson?.city !== city?.long_name) {
  //   //     return showHttpError("Location Should be in same City as per Lcoation No 1 !")
  //   //   }
  //   // }

  //   let state = e.address_components.find((x: any) => {
  //     return multipleExist(x.types, ["administrative_area_level_1", "political"]);
  //   })

  //   props.setPartLoadPickupDropLocation('place.placeId', e.place_id, index)
  //   props.setPartLoadPickupDropLocation('place.coordinate.latitude', e.geometry.location.lat, index)
  //   props.setPartLoadPickupDropLocation('place.coordinate.longitude', e.geometry.location.lng, index)
  //   props.setPartLoadPickupDropLocation('place.placeValue', e.name, index)
  //   props.setPartLoadPickupDropLocation('place.formatted_address', e.formatted_address, index)
  //   props.setPartLoadPickupDropLocation('place.name', e.name, index)
  //   props.setPartLoadPickupDropLocation('place.area', area.long_name, index)
  //   props.setPartLoadPickupDropLocation('place.pincode', pincode.long_name, index)
  //   props.setPartLoadPickupDropLocation('place.city', city.long_name, index)
  //   props.setPartLoadPickupDropLocation('place.state', state.long_name, index)
  //   props.setPartLoadPickupDropLocation('contactPerson.state', state.long_name, index)
  //   props.setPartLoadPickupDropLocation('contactPerson.pincode', pincode.long_name, index)
  //   props.setPartLoadPickupDropLocation('contactPerson.city', city.long_name, index)
  //   props.setPartLoadPickupDropLocation('contactPerson.isDisabled', state.long_name && city.long_name && pincode.long_name ? true : false, index)
  //   props.setPartLoadPickupDropLocation('contactPerson.floor', "", index)
  //   props.setPartLoadPickupDropLocation('contactPerson.officeName', "", index)
  //   props.setPartLoadPickupDropLocation('contactPerson.l1', "", index)
  //   props.setPartLoadPickupDropLocation('contactPerson.l2', "", index)
  //   props.setPartLoadPickupDropLocation('contactPerson.nearby_landmark', "", index)
  // }

  if (props?.customerReducer && props?.customerReducer?.token && props?.customerReducer?.name && localStorage.getItem("kabra:customertoken")) {
    navigate("/customerDashboard/booking",{
      state: { from: "customerLogin" }
    })
  }

  // useEffect(() => {
  //   function handleresize() {
  //     if (window.innerWidth > 770) {
  //       setismobileview(false);
  //     } else {
  //       setismobileview(true);
  //     }
  //   }
  //   window.addEventListener("resize", handleresize);
  //   return () => {
  //     window.removeEventListener("resize", handleresize);
  //   };
  // }, []);

  useEffect(() => {
    defineAssetType();
  }, [assetType]);

  useEffect(() => {
    { console.log(`assetType`, assetType, `assetTypeList`, assetTypeList) }
    findVehicleName();
  }, [assetType])

  useEffect(() => {
    { console.log(`assetType`, assetType, `assetTypeList`, assetTypeList) }
    findVehicleModel();
  }, [assetSubType])

  useEffect(() => {
    console.log(`AutoCompletezdva`, props?.customerBooking?.partLoad.pickDropLocation, `date`, date)
    // setPickupDropLocation(props?.customerBooking?.partLoad.pickDropLocation);
  }, [props?.customerBooking?.partLoad.pickDropLocation])

  return (
    <>
    {mobileView ? (
        <div className="">
          <div className="fullLoad">
            <section id="intro" className="intro">
              <div className="intro-content mt40">
            <h1 style={{textAlign: "center", fontSize: "24px", marginBottom: "0", paddingBlock: "1rem"}}>Get Estimate</h1>

                <div className="container mt85Mobile">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="logo_1">
                        <img src="img/21.png" className="img-responsive mt-55" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6 firm_1">
                      <div>
                        <div className="row form_page">
                          <div className="tab-content">
                            <div className="tab-pane active" id="my_1">
                              <div className="row ml-170">
                                <Autocomplete
                                  onPlaceChanged={() => {
                                    if (autoCompleteOrigin !== null) {
                                      // let ans = autoCompleteOrigin.getPlace().formatted_address
                                      // setSourceLocation(ans)
                                    } else {
                                      console.log('Autocomplete is not loaded yet!')
                                    }
                                  }} onLoad={onLoadOrigin}
                                >
                                  <input
                                    type='text'
                                    placeholder='Source Location'
                                    className="form-control form_one ml170"
                                    ref={originIn}
                                  />
                                </Autocomplete>
                              </div>

                              {destinations.map((val: string, i: any) => {
                                console.log(val)
                                return (
                                  <div key={i} className="row dflex ml-170 mt10Mobile">
                                    <div className="mt-10 ml170">
                                      <Autocomplete
                                        onPlaceChanged={() => {
                                          if (autoCompleteDest !== null) {
                                            handleChangeDestinations(autoCompleteDest.getPlace(), i)
                                          } else {
                                            console.log('Autocomplete is not loaded yet!')
                                          }
                                        }} onLoad={onLoadDest}
                                      >
                                        <input
                                          type='text'
                                          placeholder='Destination Location'
                                          className="form-control form_one"
                                          ref={destIn}
                                        />
                                      </Autocomplete>
                                    </div>
                                    {i === destinations.length - 1 && i < 2 &&
                                      <div className="ml5">
                                        <AiOutlinePlus onClick={() => { handleAdd() }} className="mt11" />
                                      </div>
                                    }
                                    {i !== 0 &&
                                      <div className="ml5">
                                        <AiOutlineMinus onClick={() => { handleRemove(i) }} className="mt11" />
                                      </div>

                                    }
                                  </div>
                                )
                              })}

                              <div className="row dflex ml-0 ml145 mb10 ml0Mobile mt10Mobile">

                                <Select12
                                  placeholder="Vehicle Type"
                                  value={assetType}
                                  options={assetTypeList}
                                  onChange={(e: any) =>
                                    setAssetType(e)
                                  }
                                />
                                <Select12
                                  placeholder="Vehicle Name"
                                  value={assetSubType}
                                  options={assetSubTypeList}
                                  onChange={(e: any) =>
                                    setAssetSubType(e)
                                  }
                                />
                                <Select12
                                  placeholder="Vehicle Model"
                                  value={vehicleModel}
                                  options={vehicleModelTypeList}
                                  onChange={(e: any) =>
                                    setVehicleModel(e)
                                  }
                                />
                              </div>
                            </div>

                            <div className="row ml-170 mt10Mobile">
                              <input
                                type="date"
                                onChange={(eve) => {
                                  setDate(eve.target.value);
                                }}
                                className="form-control form_one mt0 ml170"
                                id="inputZip"
                                required
                              />
                            </div>
                            <div className="row ml-170 mt10Mobile">
                              <button
                                className="btn btn-primary-one text-white form_one mt0 ml170"
                                onClick={() => setIsDialogOpen(true)}
                              >
                                Get Details
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-up" data-aos-delay="800"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="feturesTabs">
            <div className="features commonBoxShadow" onMouseOver={() => { setTabActive(1) }}>
              <div className="section-title" data-wow-delay="0.1s">
                <h2>Our Features</h2>
              </div>
              <div className="centalisedFeatures">
                <OurFeatures data={fullLoad} />
              </div>
            </div>
            <div className="howItWorks commonBoxShadow" onMouseOver={() => { setTabActive(2) }}>
              <div className="section-title" data-wow-delay="0.1s">
                <h2>How It Works</h2>
              </div>
              <div className="centalisedHowItWorks">
                <HowItWorks data={fullLoad} />
              </div>
            </div>
            <div className="trestimonials commonBoxShadow" onMouseOver={() => { setTabActive(3) }}>
              <div className="section-title" data-wow-delay="0.1s">
                <h2>Testimonials</h2>
              </div>
              <div className="centalisedTestimonials">
                <Testimonials data={fullLoad} />
              </div>
            </div>
            <div className="faqIntracity commonBoxShadow" onMouseOver={() => { setTabActive(4) }}>
              <section className="faq">
                <div className="container">
                  <div className="section-title" data-wow-delay="0.1s">
                    <h2>FAQ</h2>
                  </div>
                  <FAQ data={fullLoad} />
                </div>
              </section>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="fullLoadContainer">
            <div className="scrollableContainer">
              <div className="sideNav" onMouseEnter={() => document.body.style.overflowY = 'hidden'} onMouseLeave={() => document.body.style.overflowY = 'scroll'}>
                <div className={tabActive === 0 ? "activeNew" : ""} onClick={() => { topFunction2("book"), setTabActive(0) }}>Book Now</div>
                <div className={tabActive === 1 ? "activeNew" : ""} onClick={() => { topFunction2("feature"), setTabActive(1) }}>Features</div>
                <div className={tabActive === 2 ? "activeNew" : ""} onClick={() => { topFunction2("how"), setTabActive(2) }}>How It Works</div>
                <div className={tabActive === 3 ? "activeNew" : ""} onClick={() => { topFunction2("test"), setTabActive(3) }}>Testimonials</div>
                <div className={tabActive === 4 ? "activeNew" : ""} onClick={() => { topFunction2("faq's"), setTabActive(4) }}>FAQ's</div>
              </div>
              <div className="fullLoad">
                {/* <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ width: "50%", marginLeft: "2.5%", marginTop: "3.5%" }}>
                    <div style={{ width: "50%", margin: "auto", marginTop: "5%" }}>
                      <AutoComplete
                        value={pickupDropLocation?.place?.placeValue}
                        onChange={(e: any) => {
                          console.log(e, "eeee");
                          console.log("020230", e);
                          setLocationByTypingRes(e, 0);
                        }}
                        where={"Customer"}
                        general={false}
                      >
                        <input
                          type='text'
                          placeholder='Source Location'
                          className="form-control form_one"
                          ref={destIn}
                        />
                      </AutoComplete>
                    </div>

                    <div style={{ width: "100%", margin: "auto" }}>
                      {destinations.map((val: string, i: any) => {
                        console.log(val)
                        return (
                          <div style={{ width: "50%", display: "flex", margin: "auto" }}>
                            <div style={{ width: "100%", margin: "auto", marginTop: "5%" }}>

                              <AutoComplete
                                value={pickupDropLocation?.place?.placeValue}
                                onChange={(e: any) => {
                                  console.log(e, "eeee");
                                  console.log("020230", e);
                                  setLocationByTypingRes(e, 1);
                                }}
                                where={"Customer"}
                                general={false}
                              >
                                <input
                                  type='text'
                                  placeholder='Destination Location'
                                  className="form-control form_one"
                                  ref={destIn}
                                />
                              </AutoComplete>
                            </div>
                            {i === destinations.length - 1 && i < 2 &&
                              <div style={{ marginTop: "5%", marginLeft: "5%" }}>
                                <AiOutlinePlus onClick={() => { handleAdd() }} className="mt11" />
                              </div>
                            }
                            {i !== 0 &&
                              <div style={{ marginTop: "5%", marginLeft: "5%" }}>
                                <AiOutlineMinus onClick={() => { handleRemove(i) }} className="mt11" />
                              </div>

                            }
                          </div>
                        )
                      })}
                    </div>

                    <div style={{ width: "100%", margin: "auto" }}>
                      <div style={{ width: "50%", margin: "auto", display: "flex" }}>
                        <Select12
                          placeholder="Vehicle Type"
                          value={assetType}
                          options={assetTypeList}
                          onChange={(e: any) => {
                            setAssetType(e);
                            changeAssetType("assetType", e);
                          }
                          }
                        />
                        <Select12
                          placeholder="Vehicle Name"
                          value={assetSubType}
                          options={assetSubTypeList}
                          onChange={(e: any) => {
                            setAssetSubType(e)
                            changeAssetType("assetSubType", e);
                          }
                          }
                        />
                      </div>

                      <div style={{ width: "50%", margin: "auto", display: "flex" }}>
                        <Select12
                          placeholder="Vehicle Model"
                          value={vehicleModel}
                          options={vehicleModelTypeList}
                          onChange={(e: any) => {
                            setVehicleModel(e)
                            changeAssetType("vehicleModel", e);
                          }
                          }
                        />
                        <input
                          type="number"
                          min={2}
                          value={truckNos}
                          onChange={(eve) => {
                            setTruckNos(eve.target.value);
                            changeAssetType("truckNos", eve.target.value);
                          }}
                          className="form-control mt0 w125 w135Mobile"
                          id="inputZip"
                          placeholder="Vehicles Nos"
                          required
                        />
                      </div>
                      <div style={{ width: "50%", margin: "auto", display: "flex" }}>
                        <input
                          type="date"
                          onChange={(eve) => {
                            setDate(eve.target.value);
                          }}
                          className="form-control form_one mt0 ml170"
                          id="inputZip"
                          required
                        />
                      </div>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "2.5%" }}>
                      <button
                        className="btn btn-primary-one text-white"
                        onClick={() => setIsDialogOpen(true)}
                      >
                        Get Details
                      </button>
                    </div>

                  </div>

                  <div style={{ width: "50%" }}>
                    <img src="img/21.png" alt="" />
                  </div>
                </div> */}

                <MyPartLoadForm from={"FullLoad"} />

                {/* <br />
                <br />
                <br />
                <br /> */}

                <div className="feturesTabs">
                  <div className="features commonBoxShadow" onMouseOver={() => { setTabActive(1) }}>
                    <div className="section-title" data-wow-delay="0.1s">
                      <h2>Our Features</h2>
                    </div>
                    <div className="centalisedFeatures">
                      <OurFeatures data={fullLoad} />
                    </div>
                  </div>
                  <div className="howItWorks commonBoxShadow" onMouseOver={() => { setTabActive(2) }}>
                    <div className="section-title" data-wow-delay="0.1s">
                      <h2>How It Works</h2>
                    </div>
                    <div className="centalisedHowItWorks">
                      <HowItWorks data={fullLoad} />
                    </div>
                  </div>
                  <div className="trestimonials commonBoxShadow" onMouseOver={() => { setTabActive(3) }}>
                    <div className="section-title" data-wow-delay="0.1s">
                      <h2>Testimonials</h2>
                    </div>
                    <div className="centalisedTestimonials">
                      <Testimonials data={fullLoad} />
                    </div>
                  </div>
                  <div className="faqIntracity commonBoxShadow" onMouseOver={() => { setTabActive(4) }}>
                    <section className="faq">
                      <div className="container">
                        <div className="section-title wow" data-wow-delay="0.1s">
                          <h2>Frequently Asked Questions</h2>
                        </div>
                        <FAQ data={fullLoad} />
                      </div>
                    </section>
                  </div>
                </div>

              </div>
              {/* <div className="intro-content mt-40Mobile formAndPhoto commonBoxShadow" onMouseOver={() => { setTabActive(0) }}>
                  <div className="col-lg-6 firm_1 wow commonPaddingHeight" data-wow-delay="0.1s">
                    <div className="row ml-170">
                      <Autocomplete
                        onPlaceChanged={() => {
                          if (autoCompleteOrigin !== null) {
                            let ans = autoCompleteOrigin.getPlace().formatted_address
                            setSourceLocation(ans)
                          } else {
                            console.log('Autocomplete is not loaded yet!')
                          }
                        }} onLoad={onLoadOrigin}
                      >
                        <input
                          type='text'
                          placeholder='Source Location'
                          className="form-control form_one ml170"
                          ref={originIn}
                        />
                      </Autocomplete>

                      {destinations.map((val: string, i: any) => {
                        console.log(val)
                        return (
                          <div key={i} className="row dflex ml-170 mt10Mobile">
                            <div className="mt-10 ml185">
                              <Autocomplete
                                onPlaceChanged={() => {
                                  if (autoCompleteDest !== null) {
                                    handleChangeDestinations(autoCompleteDest.getPlace(), i)
                                  } else {
                                    console.log('Autocomplete is not loaded yet!')
                                  }
                                }} onLoad={onLoadDest}
                              >
                                <input
                                  type='text'
                                  placeholder='Destination Location'
                                  className="form-control form_one"
                                  ref={destIn}
                                />
                              </Autocomplete>
                            </div>
                            {i === destinations.length - 1 && i < 2 &&
                              <div className="ml5">
                                <AiOutlinePlus onClick={() => { handleAdd() }} className="mt11" />
                              </div>
                            }
                            {i !== 0 &&
                              <div className="ml5">
                                <AiOutlineMinus onClick={() => { handleRemove(i) }} className="mt11" />
                              </div>

                            }
                          </div>
                        )
                      })}

                      <div className="row dflex ml145 mb10 ml0Mobile mt10Mobile">
                        <Select12
                          placeholder="Vehicle Type"
                          value={assetType}
                          options={assetTypeList}
                          onChange={(e: any) =>
                            setAssetType(e)
                          }
                        />
                        <Select12
                          placeholder="Vehicle Name"
                          value={assetSubType}
                          options={assetSubTypeList}
                          onChange={(e: any) =>
                            setAssetSubType(e)
                          }
                        />
                      </div>

                      <div className="row dflex ml145 mb10 ml0Mobile mt10Mobile">
                        <Select12
                          placeholder="Vehicle Model"
                          value={vehicleModel}
                          options={vehicleModelTypeList}
                          onChange={(e: any) =>
                            setVehicleModel(e)
                          }
                        />
                      </div>

                      <div className="row ml-170 mt10Mobile">
                        <input
                          type="date"
                          onChange={(eve) => {
                            setDate(eve.target.value);
                          }}
                          className="form-control form_one mt0 ml170"
                          id="inputZip"
                          required
                        />
                      </div>
                      <div className="row ml-170 mt10Mobile">
                        <button
                          className="btn btn-primary-one text-white form_one mt0 ml170"
                          onClick={handleResults}
                        >
                          Get Details
                        </button>
                      </div>

                      <div data-aos="fade-up" data-aos-delay="800"></div>
                    </div>
                    <div className="col-lg-6" data-wow-duration="2s" data-wow-delay="0.2s">
                      <img src="img/21.png" alt="" />
                    </div>
                  </div>
                </div> */}
            </div>
          </div>

          <Dialog
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            maxWidth={"lg"}
          >
            <div className="SigninPopupcontainer">
              <div className='defineUsersContainer'>
                <div className={tabValue === 0 ? "signinTabs activeNew" : "signinTabs"} onClick={() => setTabValue(0)}>Registered User</div>
                <div className={tabValue === 1 ? "signinTabs activeNew" : "signinTabs"} onClick={() => setTabValue(1)}>New User</div>
              </div>
              <DialogContent>
                {tabValue === 0 ?
                  <div className="userDetialsforHomePopup">
                    <input className='form-control' type={"email"} value={customerMobileNo} placeholder='Contact No' onChange={(e: any) => setCustomerMobileNo(e.target.value)} />
                    <br />
                    <input className='form-control' type={"password"} value={customerPassword} placeholder='Password' onChange={(e: any) => setCustomerPassword(e.target.value)} />
                  </div>
                  :
                  <div className="userDetialsforHomePopup">
                    <input className='form-control' type={"text"} placeholder='First Name' />
                    <br />
                    <input className='form-control' type={"text"} placeholder='Last Name' />
                    <br />
                    <input className='form-control' type={"email"} placeholder='E-mail' />
                    <br />
                    <input className='form-control' type={"password"} placeholder='Password' />
                    <br />
                    <input className='form-control' type={"number"} placeholder='Contact No' onChange={() => { 
                      // setContactNo(e) 
                      }} />
                    <br />
                    <button className='btn btn-primary otpButton' onClick={() => { 
                      // handelgetOtp() 
                      }}>Get Otp</button>
                    <br />
                    <input className='form-control otpInput' type={"number"} placeholder='Enter Otp' />
                  </div>
                }
                <br />
                {tabValue === 0 &&
                  <div className="createAccount">
                    <Link to="/customerLogin/forgotPassword" className="fp-span">
                      Forgot Password ?
                    </Link>
                  </div>
                }
              </DialogContent>
              <DialogActions>
                <div className='dflex w100 btns'>
                  {tabValue === 0 ?
                    <ButtonPrimary className="mr5 loginForHomePopup" onClick={() => { 
                      // handleLogin() 
                    }}> Login </ButtonPrimary>
                    :
                    <ButtonPrimary className="mr5 loginForHomePopup"> Sign Up </ButtonPrimary>
                  }
                  <ButtonDefault className="mr5 closeForHomePopup" onClick={() => setIsDialogOpen(false)}> close </ButtonDefault>
                </div>
              </DialogActions>
            </div>
          </Dialog>
        </>
      )
      }
    </>
  );
};


const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    customerReducer: state.customerLoginReducer,
    customerBooking: state.customerBooking,
  };
};

const mapDispatchToProps = {
  customerLogin,
  hideLoader,
  showLoader,
  setPartLoadPickupDropLocation
  // removeIntraCityPickupDropLocation,
  // setIntraCityPickupDropLocation,
  // setIntraCity
};

export default connect(mapStateToProps, mapDispatchToProps)(FullloadForm)