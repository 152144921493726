// react
import React, { useState } from 'react'
// css
import './careers.css';
// ui components
import { Button, PageHeader } from "../UI";
// constants (data | icon)
import { careers } from "../../constants/data";
import { LocationIcon, ViewOpeningIcon } from "../../constants/icons";


// FC: Careers Component
const Careers = () => {
  // State
  const [activeTag, setActiveTag] = useState("IT");

  // component return
  return (
    <main className="careers | bg-white-900 page-container-grid">
      <PageHeader title="Careers" imgSrc="careers/bg.jpg" />
      {/* Careers List of Options */}
      <div className="| p-3 pt-2">
        <nav className="| my-container flex-center">
          <ul className="careers-navigation | flex-center gap-1 flex-wrap m-none bg-primary-50 oval py-1 px-2">
            {careers.options.length > 0 &&
              Array.from(new Set(careers.options.map(item => item.tag))).map(tag => (
                <li key={tag} className={`careers-navigation-list-item | py-8 px-1 flex-center fs-link user-select-none fw-500 pointer text-primary-900 ${activeTag === tag ? "active" : ""} oval`} onClick={() => setActiveTag(tag)}>{tag}</li>
              ))
            }
          </ul>
        </nav>
        {/* Careers Cards */}
        <ul className="careers-cards | three-column t-two-column m-one-column gap-2 p-1">
          {careers.options.length > 0 && careers.options.map(item => {
            if (item.tag === activeTag) {
              return <li key={item.title} className="| p-1 br-4 border-left-primary flex-col gap-8 shadow-medium">
                <span className="| fs-list-item-heading fw-500">{item.title}</span>
                <span className="| fs-list-item-text flex gap-4 ai-center fw-400"><LocationIcon />{item.location}</span>
                <Button variant="primary" action="primary" Icon={<ViewOpeningIcon />} className="| as-start fs-button">View Opening</Button>
              </li>
            }
          })}
        </ul>
      </div>
    </main>
  )
}

export default Careers