import { LOCK_USER_WHILE_LOGIN } from './../constants/userLogin';

const initSDtate:any = {
    isLocked : true
}

const userLoginLocking = (state = initSDtate , action: { type: any; payload: any }) => {
  switch (action.type) {
    case LOCK_USER_WHILE_LOGIN : {
        return {
            ...state,
            isLocked : action.payload
        }
    }
    default:
      return state;
  }
}

export default userLoginLocking;