import React from "react";
import { Button } from "../../newSrc/components/UI";
import { ButtonProps } from "../../newSrc/components/UI/button/button";
import {
  ArrowLeftIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  DownloadIcon,
  FilterIcon,
  InfoIcon,
  PencilIcon,
  PlusIcon,
  PowerResetIcon,
  PrintIcon,
  RefreshIcon,
  SaveIcon,
} from "../../newSrc/constants/icons";

type CustomButtonProps = Partial<ButtonProps>;

type EditButtonProps = CustomButtonProps;
export function EditButton({
  variant,
  action,
  children,
  ...props
}: EditButtonProps) {
  return (
    <Button
      {...props}
      startIcon={<PencilIcon />}
      variant={variant ?? "primary"}
      action={action ?? "primary"}
    >
      {children ?? "Edit"}
    </Button>
  );
}

type SaveButtonProps = CustomButtonProps;
export function SaveButton({
  variant,
  action,
  children,
  ...props
}: SaveButtonProps) {
  return (
    <Button
      {...props}
      startIcon={<SaveIcon />}
      variant={variant ?? "primary"}
      action={action ?? "primary"}
    >
      {children ?? "Save"}
    </Button>
  );
}

type CreateButtonProps = CustomButtonProps;
export function CreateButton({
  variant,
  action,
  children,
  ...props
}: CreateButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "primary"}
    >
      {children ?? "Create"}
    </Button>
  );
}

type CloseButtonProps = CustomButtonProps;
export function CloseButton({
  variant,
  action,
  children,
  ...props
}: CloseButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "danger"}
      action={action ?? "secondary"}
    >
      {children ?? "Close"}
    </Button>
  );
}

type PreviousButtonProps = CustomButtonProps;
export function PreviousButton({
  variant,
  action,
  children,
  ...props
}: PreviousButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "secondary"}
    >
      {children ?? "Previous"}
    </Button>
  );
}

type NextButtonProps = CustomButtonProps;
export function NextButton({
  variant,
  action,
  children,
  ...props
}: NextButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "primary"}
    >
      {children ?? "Next"}
    </Button>
  );
}

type UpdateButtonProps = CustomButtonProps;
export function UpdateButton({
  variant,
  action,
  children,
  ...props
}: UpdateButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "secondary"}
      action={action ?? "secondary"}
    >
      {children ?? "Update"}
    </Button>
  );
}

type DeleteButtonProps = CustomButtonProps;
export function DeleteButton({
  variant,
  action,
  children,
  ...props
}: DeleteButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "danger"}
      action={action ?? "primary"}
    >
      {children ?? "Delete"}
    </Button>
  );
}

type SubmitButtonProps = CustomButtonProps;
export function SubmitButton({
  variant,
  action,
  children,
  ...props
}: SubmitButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "primary"}
    >
      {children ?? "Submit"}
    </Button>
  );
}

type ApplyButtonProps = CustomButtonProps;
export function ApplyButton({
  variant,
  action,
  children,
  ...props
}: ApplyButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "primary"}
    >
      {children ?? "Apply"}
    </Button>
  );
}

type MonthWiseButtonProps = CustomButtonProps;
export function MonthWiseButton({
  variant,
  action,
  children,
  ...props
}: MonthWiseButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "primary"}
      type="submit"
    >
      {children ?? "MonthWise"}
    </Button>
  );
}

type PrintButtonProps = CustomButtonProps;
export function PrintButton({
  variant,
  action,
  children,
  ...props
}: PrintButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "secondary"}
      startIcon={<PrintIcon />}
      title="Print"
    >
      {children}
    </Button>
  );
}

type ExportButtonProps = CustomButtonProps;
export function ExportButton({
  variant,
  action,
  children,
  ...props
}: ExportButtonProps) {
  return (
    <Button
      {...props}
      action={action ?? "secondary"}
      variant={variant ?? "primary"}
    >
      {children ?? "Export"}
    </Button>
  );
}

type DownloadButtonProps = CustomButtonProps;
export function DownloadButton({
  children,
  size,
  variant,
  action,
  ...props
}: DownloadButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "secondary"}
      action={action ?? "secondary"}
      size={size ?? "large"}
      title="Download"
      startIcon={<DownloadIcon />}
    >
      {children}
    </Button>
  );
}

type PrintSummaryButtonProps = CustomButtonProps;
export function PrintSummaryButton({
  variant,
  action,
  children,
  ...props
}: PrintSummaryButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "secondary"}
    >
      {children ?? "Print Summary"}
    </Button>
  );
}

type RefreshButtonProps = CustomButtonProps;
export function RefreshButton({
  children,
  variant,
  action,
  size,
  ...props
}: RefreshButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "secondary"}
      action={action ?? "secondary"}
      size={size ?? "large"}
      startIcon={<RefreshIcon />}
      title="Reset"
    >
      {children}
    </Button>
  );
}
// <i className="fas fa-sync"></i>
type SortButtonProps = CustomButtonProps;
export function SortButton({
  variant,
  action,
  children,
  ...props
}: SortButtonProps) {
  return (
    <Button
      {...props}
      action={action ?? "primary"}
      variant={variant ?? "primary"}
    >
      {children ?? "Sort"}
    </Button>
  );
}

type FetchRecordsButtonProps = CustomButtonProps;
export function FetchRecordsButton({
  variant,
  action,
  children,
  ...props
}: FetchRecordsButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "primary"}
    >
      {children ?? "Fetch Records"}
    </Button>
  );
}

type AddNewButtonProps = CustomButtonProps;
export function AddNewButton({
  variant,
  action,
  children,
  ...props
}: AddNewButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "secondary"}
    >
      {children ?? "Add New"}
    </Button>
  );
}

type SentButtonProps = CustomButtonProps;
export function SentButton({
  variant,
  action,
  children,
  ...props
}: SentButtonProps) {
  return (
    <Button
      variant={variant ?? "primary"}
      action={action ?? "primary"}
      {...props}
    >
      {children ?? "Sent"}
    </Button>
  );
}

type CancelButtonProps = CustomButtonProps;
export function CancelButton({
  variant,
  action,
  children,
  ...props
}: CancelButtonProps) {
  return (
    <Button
      variant={variant ?? "danger"}
      action={action ?? "secondary"}
      {...props}
    >
      {children ?? "Cancel"}
    </Button>
  );
}

type GraceButtonProps = CustomButtonProps;
export function GraceButton({
  variant,
  action,
  children,
  ...props
}: GraceButtonProps) {
  return (
    <Button
      variant={variant ?? "primary"}
      action={action ?? "primary"}
      {...props}
    >
      {children ?? "Grace"}
    </Button>
  );
}

type ResetButtonProps = CustomButtonProps;
export function ResetButton({
  variant,
  action,
  children,
  ...props
}: ResetButtonProps) {
  return (
    <Button
      variant={variant ?? "primary"}
      action={action ?? "secondary"}
      {...props}
    >
      {children ?? "Reset"}
    </Button>
  );
}

export function ResetIconButton({
  variant,
  action,
  children,
  ...props
}: ResetButtonProps) {
  return (
    <Button
      variant={variant ?? "secondary"}
      action={action ?? "secondary"}
      {...props}
      Icon={<PowerResetIcon />}
    />
  );
}

type ReceivedButtonProps = CustomButtonProps;
export function ReceivedButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "primary"}
    >
      {children ?? "Received"}
    </Button>
  );
}

export function YesButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "secondary"}
    >
      {children ?? "Yes"}
    </Button>
  );
}

export function NoButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "danger"}
      action={action ?? "secondary"}
    >
      {children ?? "No"}
    </Button>
  );
}

export function OkButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "primary"}
    >
      {children ?? "OK"}
    </Button>
  );
}

export function PlusIconButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "primary"}
      Icon={<PlusIcon className="flex-shrink-0" />}
    />
  );
}

export function MinusIconButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "primary"}
      Icon={<PlusIcon />}
    />
  );
}

export function DeleteIconButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "danger"}
      action={action ?? "primary"}
      Icon={<DeleteIcon />}
    />
  );
}

export function EditIconButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "secondary"}
      action={action ?? "secondary"}
      Icon={<PencilIcon />}
    />
  );
}

export function CheckIconButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "success"}
      action={action ?? "secondary"}
      Icon={<CheckIcon />}
    />
  );
}
export function CancelIconButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "danger"}
      action={action ?? "secondary"}
      Icon={<CloseIcon />}
    />
  );
}

export function InfoIconButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "primary"}
      endIcon={<InfoIcon />}
    >{children}</Button>
  );
}

export function RetryButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "secondary"}
    >{children ?? "Retry"}</Button>
  );
}

export function ClearButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "secondary"}
      action={action ?? "secondary"}
    >{children ?? "Clear"}</Button>
  );
}

export function VerifyButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "primary"}
    >{children ?? "Verify"}</Button>
  );
}

export function AcceptButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "primary"}
    >{children ?? "Accept"}</Button>
  );
}

export function PayButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "primary"}
    >{children ?? "Pay"}</Button>
  );
}

export function AddButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "primary"}
    >{children ?? "Add"}</Button>
  );
}

export function BackButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "secondary"}
      Icon={<ArrowLeftIcon />}
    >{children ?? "Back"}</Button>
  );
}

export function FetchButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "primary"}
    >{children ?? "Fetch"}</Button>
  );
}

export function FilterIconButton({
  variant,
  action,
  children,
  ...props
}: ReceivedButtonProps) {
  return (
    <Button
      {...props}
      variant={variant ?? "primary"}
      action={action ?? "primary"}
      Icon={<FilterIcon />}
    >{children}</Button>
  );
}