// react
import React, { useEffect, useState } from "react";
// ui components
import { Button, Dialog, Select } from "../../../components/UI";
// constants (icon)
import { GetDetailsIcon } from "../../../constants/icons";
import ContactOTPForm from "../components/CommonForms/ContactOTPForm";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { hideLoader, showLoader } from "../../../../actions/UserActions";
import { createPartLoadLead, sendOTPForLandingForms, verifyContact, verifyOTPLandingForms } from "../../../../services/landingPageForms";
import { useNavigate } from "react-router-dom";
import { toastMessage } from "../../../../Components/showMessages/toastMessage";
import { showHttpError } from "../../../../utils/message";
import { SET_PARTLOAD } from "../../../../constants/customerBooking";
import { getMaterialList } from "../../../../services/intraCityRate";
import { getCityList } from "../../../../services/area";
import { getOptions } from "../../../utils/helper";
import { StateObjType, StateType } from "../partner-forms/useCustomState";
import { customerLoginByOTP } from "../../../../actions/customerLogin";

type SelectType = {
    label : string | undefined,
    value : string | undefined
}


// variables

// FC: Part Load Get Estimate Component
export default function PartLoadForm() {
    // State
    const [quantity, setQuantity] = useState<StateObjType>(() => ({
        value: "",
        error: false,
        errorMessage: ""
    }));
    const [weight, setWeight] = useState<StateObjType>(() => ({
        value: "",
        error: false,
        errorMessage: ""
    }));
    const [isContactDialogOpen, setIsContactDialogOpen] = useState<boolean>(false)
    const [contact,setContact] = useState<StateType>("")
    const [originCity, setOriginCity] = useState<SelectType>()
    const [destCity,setDestCity] = useState<SelectType>()
    const [date, setDate] = useState("")
    const [materialType,setMaterialType] = useState<SelectType>()
    const navigate = useNavigate()
    // const { setActivePage } = useLandingPageContext();
    const dispatch = useAppDispatch()
    const [materialTypeList, setMaterialTypeList] = useState<any>([]);
    const [otp, setOTP] = useState<StateType>("");
    const [isOTPClick,setIsOTPClick] = useState<boolean>(false)
    const [name, setName] = useState<StateType>("");
    const [isCustomer, setIsCustomer] = useState<boolean>(false)

    // const setPackagesFeature = (name: any, value: any, index: any) => {
    //         dispatch(setPartLoadPackages(name, value, index))
    // }
    const [cityOptions,setCityOptions] = useState<SelectType[]>([])

    const setUpData = async () => {
        try {
          const [cityList] = await Promise.all([
            getCityList()
          ]);
          setCityOptions(getOptions(cityList, /^[a-zA-Z]+$/));
        } catch (err: any) {}
      };

    const leadPayload = {
        originCity,
        destCity,
        date,
        materialType,
        qty : quantity,
        weight,
        contact
    }

    console.log(leadPayload,"leadPayload11111111111")

    const intracityMaterialTypeList = async () => {
        dispatch(showLoader())
        try {
            let payload: any = { type: "IntraCityMaterial" };
            let response = await getMaterialList("", payload);
            setMaterialTypeList(
                response?.map((x: any) => {
                    return { value: x._id, label: x.name };
                })
            );

            dispatch(hideLoader())
        } catch (error) {
            showHttpError(error);
            dispatch(hideLoader())
        }
    };

    useEffect(() => {
        intracityMaterialTypeList()
        setUpData()
    },[])

    useEffect(() => {
      if(isOTPClick){
        handleContactSubmit()
      }
    },[isOTPClick])

    const handleContactSubmit = async () => {
        dispatch(showLoader());
        
        const payload = { contact };
        
        try {
          const res = await verifyContact(payload);
          
          if (res) {
            
            const leadPayload = {
                originCity : originCity?.value,
                destCity : destCity?.value,
                date,
                materialType : materialType?.value,
                qty : quantity.value,
                weight : weight.value,
                contact
            }

            await sendOTPForLandingForms(payload)
            await createPartLoadLead(leadPayload);
            // setPackagesFeature('qty', parseInt(quantity.value), val.id)
            // setPackagesFeature('materialType', materialType, val.id)
            
            dispatch({
              type: SET_PARTLOAD,
              payload: {
                what: "isBookedWithoutToken",
                val: true,
              },
            });
            dispatch({
              type: SET_PARTLOAD,
              payload: {
                what: "lead",
                val: leadPayload,
              },
            });
            // navigate("/login", {
            //   state: {
            //     action: "isBookedWithoutToken",
            //     data: { contact },
            //   },
            // });
            toastMessage("OTP sent successfully", {
              theme: "colored",
              type: "success",
            });
          } else {
            // setActivePage("customer");
            await sendOTPForLandingForms(payload)
            setIsCustomer(true)
            toastMessage("Please Register Customer", {
              theme: "colored",
              type: "error",
            });
          }
          
          console.log(res, "response11111111");
          
        } catch (error) {
          showHttpError(error);
          return error;
        } finally {
          dispatch(hideLoader());
        }
      };
      
      const verifyOTP = async () => {
        dispatch(showLoader());
        let deviceId = localStorage.getItem("deviceId");
        const payload = { 
            contact,
            OTP : otp,
            rememberMe: true,
            deviceId
         };
        
        try {
          const res = await verifyOTPLandingForms(payload);
          if(res.accessToken && res.customer){
            dispatch(customerLoginByOTP(Number(contact),otp as  string,true,deviceId ? deviceId : undefined))
            navigate("/customerDashboard/booking")
            setIsOTPClick(false)
            setIsContactDialogOpen(false)
            setIsCustomer(false)
            toastMessage("Customer Login Successfully", {
              theme: "colored",
              type: "success",
            });
          }
          else{
            toastMessage("failed to Login", {
              theme: "colored",
              type: "error",
            });
          }
          console.log(res, "response11111111");
          
        } catch (error) {
          showHttpError(error);
          return error;
        } finally {
          dispatch(hideLoader());
        }
      };
    
      const backButtonClick = () => {
        setIsContactDialogOpen(false)
        setContact("")
      }

    // does: validate quantity
    function quantityValidation(e: React.FocusEvent<HTMLInputElement, Element>) {
        const quantityValue = e.target.value;
        if (quantityValue === "0") {
            return setQuantity(prevQuantity => {
                return { ...prevQuantity, error: true, errorMessage: "Quantity should be greater than 0" }
            })
        }
        if (!quantityValue || quantityValue == null || quantityValue === " ") {
            return setQuantity(prevQuantity => {
                return { ...prevQuantity, error: true, errorMessage: "Quantity is required!" }
            })
        } else {
            return setQuantity(prevQuantity => {
                return { ...prevQuantity, error: false }
            })
        }
    }
    // does: validate weight
    function weightValidation(e: React.FocusEvent<HTMLInputElement, Element>) {
        const weightValue = e.target.value;
        if (weightValue === "0") {
            return setWeight(prevWeight => {
                return { ...prevWeight, error: true, errorMessage: "Weight should be greater than 0" }
            })
        }
        if (!weightValue || weightValue == null || weightValue === " ") {
            return setWeight(prevWeight => {
                return { ...prevWeight, error: true, errorMessage: "Weight is required!" }
            })
        } else {
            return setWeight(prevWeight => {
                return { ...prevWeight, error: false }
            })
        }
    }


    // component return
    return <div className="| flex-col gap-8 w-100">
        {/* Date & Material Type */}
        <div className="| four-column gap-8 t-one-column">
        {/* Source Location */}
        <Select isClearable={true} value={originCity} onChange={(e: any) => setOriginCity(e)} isSearchable={true} options={cityOptions} placeholder={"Source Location"} />
        {/* Destination Location */}
        <Select isClearable={true} value={destCity} onChange={(e: any) => setDestCity(e)} isSearchable={true} options={cityOptions} placeholder={"Destination Location"} />
            <input type="date" value={date} onChange={(e:React.ChangeEvent<HTMLInputElement>) => setDate(e.target.value)} name="part-load-date" id="part-load-date" className="| p-8 border-white outline-none br-4" />
            <Select isClearable={true} value={materialType} onChange={(e: any) => setMaterialType(e)} isSearchable={true} options={materialTypeList} placeholder={"Select Material Type"} />
        {/* </div> */}
        {/* Quantity & Weight */}
        {/* <div className="| two-column gap-8 t-one-column"> */}
            <div className="| flex-col">
                <input type="number" placeholder="Quantity" name="part-load-quantity" id="part-load-quantity" value={quantity.value} onChange={e => setQuantity(prevQuantity => {
                    return { ...prevQuantity, value: e.target.value }
                })} onBlur={quantityValidation} className="| p-8 br-4 border-white outline-none" min={0} />
                <p className="| text-danger-50 fs-extrasmall" style={{ marginTop: "2px" }}>{quantity.error && quantity.errorMessage}</p>
            </div>
            <div className="| flex-col">
                <input type="number" placeholder="Weight(KG)" name="part-load-weight" id="part-load-weight" value={weight.value} onChange={e => setWeight(prevWeight => {
                    return { ...prevWeight, value: e.target.value }
                })} onBlur={weightValidation} className="| p-8 br-4 border-white outline-none" min={0} />
                <p className="| text-danger-50 fs-extrasmall" style={{ marginTop: "2px" }}>{weight.error && weight.errorMessage}</p>
            </div>
            <div className="">
            <Button variant="secondary" action="secondary" onClick={() => {
                setIsContactDialogOpen(true)
            }} Icon={<GetDetailsIcon />} className="fs-button" style={{padding:"7px"}}>Get Estimated Rate</Button>
        </div>
        </div>

        <Dialog
        header={"Verify Contact"}
        body={
          <ContactOTPForm 
            isOTPModal={isContactDialogOpen} 
            contact={contact} 
            setContact={setContact} 
            otp={otp} 
            setOTP={setOTP}
            name={name} 
            setName={setName} 
            isCustomer={isCustomer}
            setIsCustomer={setIsCustomer}
            isOTPClick={isOTPClick}
            setIsOTPClick={setIsOTPClick}
          />
        }
        footer={
          <div className="flex gap-1">
          <div className="flex gap-1">
            <div className="flex gap-1">
            <Button onClick={() => {
              backButtonClick()
            }} variant="info" action="secondary" type="button">
              close
            </Button>
          </div>
          {
            isOTPClick ?  <div className="flex gap-1">
            <Button onClick={() => {
                verifyOTP()
              }} variant="primary" action="secondary" type="button">
                submit
              </Button>
          </div> : null
          }
         
          </div>
         </div>
        }
        size="regular"
        state={isContactDialogOpen}
        setState={setIsContactDialogOpen}
        closeIcon
      ></Dialog>
    </div>
}