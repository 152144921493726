import { ServiceMaker, buildQueryString } from "./index";

export const getDiscountRequestList = (data:any) =>
ServiceMaker(`operation/discountReq?${buildQueryString(data)}`,"GET")

export const requestDiscount = (docketId:string,data:any) =>
ServiceMaker(`operation/discountReq/${docketId}`,"PATCH",data)

export const submitRequestAction = (id:string,data:any) =>
ServiceMaker(`operation/discount/process/${id}`,"PATCH",data)

export const processDoorDeliveryDiscountReq = (id:string,data:any) =>
ServiceMaker(`operation/doorDelDiscount/process/${id}`,"PATCH",data)

export const reqForDoorDeliveryDiscount = (Id:string,data:any) =>
ServiceMaker(`operation/doorDelDiscountReq/${Id}`,"PATCH",data)