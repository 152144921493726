import { useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from 'react'

const HowItWorks = (props: any) => {
  const [hoverkey, setHoverkey] = useState(1);
  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2

  return (
    <>
      {mobileView ? (
        <div className='howitWorksContainer'>
          <div className='textDiv'>
            <div className={hoverkey === 1 ? "HowItWorks activeHowItWorks opaCityNew1" : "HowItWorks opaCityNew"} onClick={() => { setHoverkey(1) }}>
              <div className='w25percent'>
                <button className="btn_New">1</button>
              </div>
            </div>
            <div className={hoverkey === 2 ? "HowItWorks activeHowItWorks opaCityNew1" : "HowItWorks opaCityNew"} onClick={() => { setHoverkey(2) }} >
              <div className='w25percent'>
                <button className="btn_New">2</button>
              </div>
            </div>
            <div className={hoverkey === 3 ? "HowItWorks activeHowItWorks opaCityNew1" : "HowItWorks opaCityNew"} onClick={() => { setHoverkey(3) }} >
              <div className='w25percent'>
                <button className="btn_New">3</button>
              </div>
            </div>
          </div>
          <div className='imgDiv'>
            {hoverkey === 1 ? <div className='imgHeading'>{props.data.HowItWorks[0].headings[0]}</div> :
              hoverkey === 2 ? <div className='imgHeading'>{props.data.HowItWorks[0].headings[1]}</div> :
                hoverkey === 3 ? <div className='imgHeading'>{props.data.HowItWorks[0].headings[2]}</div> : ""}
            <img src={hoverkey === 1 ? props.data.HowItWorks[1].img[0] :
              hoverkey === 2 ? props.data.HowItWorks[1].img[1] :
                hoverkey === 3 ? props.data.HowItWorks[1].img[2] : ""} alt="Something Happens !" />
          </div>
        </div>
      ) : (
        <div className='howitWorksContainer'>
          <div className='textDiv'>
            <div className={hoverkey === 1 ? "HowItWorks opaCityNew1" : "HowItWorks opaCityNew"} onMouseEnter={() => { setHoverkey(1) }}>
              <div className='w25percent'>
                <button className="btn_New">1</button>
                <h5>{props.data.HowItWorks[0].headings[0]}</h5>
              </div>
              <div className='headingText'>
                Hello !
              </div>
            </div>
            <div className={hoverkey === 2 ? "HowItWorks opaCityNew1" : "HowItWorks opaCityNew"} onMouseEnter={() => { setHoverkey(2) }} >
              <div className='w25percent'>
                <button className="btn_New">2</button>
                <h5>{props.data.HowItWorks[0].headings[1]}</h5>
              </div>
              <div className='headingText'>
                Hello !
              </div>
            </div>
            <div className={hoverkey === 3 ? "HowItWorks opaCityNew1" : "HowItWorks opaCityNew"} onMouseEnter={() => { setHoverkey(3) }} >
              <div className='w25percent'>
                <button className="btn_New">3</button>
                <h5>{props.data.HowItWorks[0].headings[2]}</h5>
              </div>
              <div className='headingText'>
                Hello !
              </div>
            </div>
          </div>
          <div className='imgDiv'>
            <img src={hoverkey === 1 ? props.data.HowItWorks[1].img[0] :
              hoverkey === 2 ? props.data.HowItWorks[1].img[1] :
                hoverkey === 3 ? props.data.HowItWorks[1].img[2] : ""} alt="Something Happens !" />
          </div>
        </div>
      )}
    </>
  )
}

export default HowItWorks