import React, { ComponentProps, useEffect, useState } from 'react';
import { mobileCarouselImages } from '../../../../constants/mobileCarouselImages';
import './carousel.css';
import { GrLinkNext } from "react-icons/gr";
import { GrLinkPrevious } from "react-icons/gr";
import { SignInIcon } from "../../../constants/icons";

type CarouselProps = {
    images: string[],
    interval?: number,
    title?: string;
    hideButton?: boolean;
    hideAd?: boolean;
    isTextShadow?: boolean;
    onBtnClick?: () => void;
    isOverlay?: boolean;
} & ComponentProps<"div">;

const Carousel = ({ images = mobileCarouselImages, title, onBtnClick, hideButton, hideAd = false, interval = 3000, isTextShadow = true, isOverlay = true, ...props }: CarouselProps) => {
    const [activeImg, setActiveImg] = useState<number>(0);
    const [isClicked, setIsClicked] = useState<boolean>(false);
    const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);

    // Set the previous carousel image
    function setPreviousImg() {
        setIsClicked(true);
        setActiveImg(prevActiveImg => (prevActiveImg === 0 ? images.length - 1 : prevActiveImg - 1));
        if (debounceTimer) clearTimeout(debounceTimer);
    }

    // Set the next carousel image
    function setNextImg() {
        setIsClicked(true);
        setActiveImg(prevActiveImg => (prevActiveImg === images.length - 1 ? 0 : prevActiveImg + 1));
        if (debounceTimer) clearTimeout(debounceTimer);
    }

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (!isClicked) {
            timeoutId = setTimeout(() => {
                setNextImg();
            }, interval);
        } else {
            timeoutId = setTimeout(() => {
                setIsClicked(false);
            }, 1000);
        }

        setDebounceTimer(timeoutId);

        // Cleanup
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [activeImg, isClicked]);       

    return (
        <div className='my-carousel-container' {...props}>
            {/* {images.map((mobileCarouselImage, index) => (
                <img loading="lazy" key={index} src={mobileCarouselImage} alt="mobile-carousel-image" className={`${activeImg === index && "active"}`} />
            ))} */}
            <img loading="lazy" src={images[activeImg]} alt="mobile-carousel-image" className={``} />
            <button onClick={setPreviousImg} className='my-carousel-previous-button border-none outline-none' style={{ display: hideButton ? "none" : "flex" }}><GrLinkPrevious /></button>
            <button onClick={setNextImg} className='my-carousel-next-button border-none outline-none' style={{ display: hideButton ? "none" : "flex" }}><GrLinkNext /></button>
            <div className="actions | absolute-center flex-col gap-1" style={{ display: hideAd ? "none" : "" }}>
                {title && <p className={`fs-heading fw-700 text-white-900 max-content ${isTextShadow ? "text-shadow-small" : ""}`} style={{ margin: 0 }}>Become a <span className="fancy">{title}</span></p>}
                <p style={{ margin: 0, marginInline: "auto", backgroundColor: "var(--clr-primary-400)", padding: "0.75rem 1rem", borderRadius: "5px", color: "var(--clr-white-900)" }} className=" fs-button flex ai-center gap-4 pointer" onClick={() => {
                    if (onBtnClick) {
                        onBtnClick();
                    }
                }}> <SignInIcon /> {title}</p>
            </div>
            {isOverlay && <div className="carousel-overlay"></div>}
        </div>
    );
};

export default Carousel;
