import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { TabPanel } from "../../../Components/Tab/Tab";
import { getCompanyList, changeCompanyDoc, updateCompanyDocAction, setCompanyDocAction, clearCompanyDoc } from './../../../actions/company/index';
import { createCompanyDocAction, setCompanyError } from './../../../actions/company';
import { getCompany } from './../../../services/home';
import HowItWorks from '../../../Components/How It Works/HowItWorks';
import FAQ from '../../../Components/FAQ/FAQ';
import Testimonials from "../../../Components/Tetimonials/Testimonials";
import OurFeatures from "../../../Components/Features/OurFeatures";
import Testing from "./Testing/Testing";
import "../css/partnersUrl.css"
import DirectSalesAgent from "./FormikForms/DirectSalesPerson/DirectSalesAgent";
import IndividualWithFleet from "./FormikForms/IndividualWithFleet/IndividualWithFleet";
import TransportCompany from "./FormikForms/TransportCompany/TransportCompany";
// import { useHistory } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PartnerUrl = () => {
  // const history = useHistory();
  const navigate = useNavigate()
  const [tabValue, setTabValue] = useState(0);
  const [tabActive,setTabActive] = useState(0);    
  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2

  let lastTab = "";
  let directSalesPageNo = 0;

  useEffect(()=>{
    setTabActive(0);
    topFunction();
  }, [tabValue])

  const handleChange = (value: any) => {
    setTabValue(value);
  };

  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  const topFunction2 = (props: any) => {
    document.body.scrollTop = 0;
    {props === "book" ? document.documentElement.scrollTop = 0 : props === "feature" ? document.documentElement.scrollTop = 515 : props === "how" ? document.documentElement.scrollTop = 1040 :
    props === "test" ? document.documentElement.scrollTop = 1575 : props === "faq's" ? document.documentElement.scrollTop = 2100 : 0 }
  } 
  
  const individualWithoutFleet ={"HowItWorks" : [{headings:["Click on register","Fill out the detailed form","Get in touch"]},
                                    {img:["https://lugg.com/static/media/map.4ba7a5f2.png",
                                        "https://lugg.com/static/media/vehicle-time.ed407219.png",
                                        "https://lugg.com/static/media/photo-description.8677e94d.png"]}],
                                    "Features" : [
                                    {img:["https://img.icons8.com/bubbles/80/000000/cash-in-hand.png",
                                        "https://img.icons8.com/bubbles/80/000000/security-checked.png",
                                        "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
                                        "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
                                        "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
                                        "https://img.icons8.com/bubbles/80/000000/conference-call.png"]},
                                    {headings:["Scale","24/7 support","Access to APIs","Effort-based earning","Flexibility to choose a time slot","CCTV surveillance"]},
                                    {content:["Team up as a franchisee with Kabra, and become a part of our strong network covering 500+ cities and over 7000 pin codes.",
                                            "You can reach out to Shadowfax representatives at any point of the day.",
                                            "Franchise partners will be given complete access to APIs which is our stronghold and makes logistics solutions seamless and affordable.",
                                            "Deliver More, Earn More",
                                            "Part-time Delivery Partner - 4 hours/day 6 days a week Full-time Delivery Partner - 8 hours/day 6 days a week.",
                                            "We have set up in our facility so that your product is always safe and secure."]}],
                                    "FAQ" : [
                                    {question:["What are the eligibility criteria to enroll in this program ?",
                                            "What is my earning potential working with you ?",
                                            "Will I get any kind of training before I go on-field ?",
                                            "What kind of leaves am I entitled to after I have successfully been onboarded ?",
                                            "Do I need to deposit any amount with the company after I am successfully onboarded ?"]},
                                    {answer:["To become our Individuals without fleet:You must be 18 years old You should have an android smartphone You should have a vehicle (2/3/4 wheeler) that meets all the applicable laws and safety requirements A valid Driving License A valid ID Proof A valid PAN Card",
                                            "Kabra Express is one of India’s leading logistics company. Since the logistics industry is one of the fastest growing industries at present, hence, by working with a company such as Kabra Express, there is always the opportunity for you to increase your earning potential.",
                                            "Kabra Express focuses extensively on training its personnel. After your successful joining, we will provide you with the training required to perform your job well.",
                                            "Regardless of our 365 days and 24 hours operations, you are always entitled to 1 week off. However, you can always choose to work on your week-offs to increase your earning potential. Any additional leaves can be taken (as per policy) with prior information.",
                                            "No, you need not deposit any amount"
                                        ]}],
                                    "Testimonials":[{img:[]},{name:[]},{possition:[]},{company:[]},
                                    {feedback:["I have been using Kabra Express for a few months now and I am very happy with the service. The delivery is always on time and the drivers are very polite. I would recommend it to anyone looking for a reliable and convenient delivery service.",
                                                "I have been using Kabra express for the past few months and I am very impressed with their service. I love that I can get all my groceries, food, and even gifts delivered to my doorstep with just a few clicks. Kabra express has made my life so much easier and hassle-free!",
                                                "I was so skeptical about ordering my groceries online but Kabra Express has changed my mind. They are prompt and efficient & the products are always fresh. I don't have to worry about anything, they take care of it all. Highly recommend!"
                                    ]}]                                        
                   }
  const individualWithFleet ={"HowItWorks" : [{headings:["Requirements","Documents","Signup process"]},
                                    {img:["https://lugg.com/static/media/map.4ba7a5f2.png",
                                        "https://lugg.com/static/media/vehicle-time.ed407219.png",
                                        "https://lugg.com/static/media/photo-description.8677e94d.png"]}],
                                    "Features" : [
                                    {img:["https://img.icons8.com/bubbles/80/000000/cash-in-hand.png",  
                                        "https://img.icons8.com/bubbles/80/000000/security-checked.png",
                                        "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
                                        "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
                                        "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
                                        "https://img.icons8.com/bubbles/80/000000/conference-call.png"]},
                                    {headings:["You’re in charge","You pick the time","Your ride, your city","Earn as you go","You’ll be far from bored","Join the movement"]},
                                    {content:["There is no one to report to, you are your boss.",
                                            "Log in and log out anytime you want. We are live 24x7.",
                                            "All deliveries will be made within city limits.",
                                            "We offer one of the best compensations in the market.",
                                            "Run different orders ranging from deliveries to rides.",
                                            "Be a part of the fastest-growing delivery startup."]}],
                                    "FAQ" : [
                                    {question:["How to attach Tata ace or any goods vehicle in Kabra Express ?",
                                            "What documents and permits do I need to onboard Kabra Express ?",
                                            "How much you can earn per week ?",
                                            "Can I refer my friends and colleagues to join Kabra Express ?",
                                            "Can I attach my tata ace on a monthly contract or rent mini trucks on monthly basis ?",
                                            "How do I know if the courier has delivered the parcel ?"]},
                                    {answer:["Becoming a Kabra Express partner and attaching your truck with us is a very easy process. You can do it with three easy steps from the comfort of your home! Download the Kabra Express Partner Driver App Register with us and submit all your documents within the app Once the documents are verified do a dummy order, You will start getting trips immediately.",
                                            "For commercial vehicle attachment following documents are required: RC book | Fitness certificate | Insurance | Pollution Control | Owner/Driver documents: Driving License | PAN card | Bank Details | Address Proof",
                                            "Your earnings are dependent on the time you spend on the Kabra Express platform. Longer engagement with the platform leads to more trips which in turn ensures high earnings.",
                                            "Yes! We encourage our driver partners to promote Kabra Express among their peers and refer them to join us. In return, they deserve a goodwill bonus on each successful onboarding of all vehicle types with a carrying capacity of 1 ton, 2 ton, and 3 ton.",
                                            "You can attach your commercial vehicle to Kabra Express on a daily, weekly, or monthly basis for a part-time or full-time engagement.",
                                            "You will receive a text message or email. The recipient will also receive a text message notifying them that the courier is coming (unless the delivery is a surprise)."
                                        ]}],
                                    "Testimonials":[{img:[]},{name:[]},{possition:[]},{company:[]},
                                    {feedback:["I have been using Kabra Express for a few months now and I am very happy with the service. The delivery is always on time and the drivers are very polite. I would recommend it to anyone looking for a reliable and convenient delivery service.",
                                                "I have been using Kabra express for the past few months and I am very impressed with their service. I love that I can get all my groceries, food, and even gifts delivered to my doorstep with just a few clicks. Kabra express has made my life so much easier and hassle-free!",
                                                "I was so skeptical about ordering my groceries online but Kabra Express has changed my mind. They are prompt and efficient & the products are always fresh. I don't have to worry about anything, they take care of it all. Highly recommend!"
                                    ]}]                                        
                   }
  const transportComapny ={"HowItWorks" : [{headings:["Get quote","Choose the vehicle","Real-time tracking"]},
                                    {img:["https://lugg.com/static/media/map.4ba7a5f2.png",
                                        "https://lugg.com/static/media/vehicle-time.ed407219.png",
                                        "https://lugg.com/static/media/photo-description.8677e94d.png"]}],
                                    "Features" : [
                                    {img:["https://img.icons8.com/bubbles/80/000000/cash-in-hand.png",
                                        "https://img.icons8.com/bubbles/80/000000/security-checked.png",
                                        "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
                                        "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
                                        "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
                                        "https://img.icons8.com/bubbles/80/000000/conference-call.png"]},
                                    {headings:["Complementary Solutions","Consulting Partners","Integration Partners","Referral Partners","Technology Partners","CCTV surveillance"]},
                                    {content:["Our priority is your time. We know how important it is for you to get your things on time, every time.",
                                            "We only work with the best quality products to ensure that you get the best experience possible.",
                                            "We deliver to your doorstep, so you can just sit back and relax.",
                                            "We provide you with the option to pay so that you can pay us only when you receive your product.",
                                            "You will be updated and notified at every stage of the packing and moving process so that you know where your product is at all times.",
                                            "We have set up in our facility so that your product is always safe and secure."]}],
                                    "FAQ" : [
                                    {question:["How early should I book a courier ?",
                                            "Can I choose a specific courier for my delivery ?",
                                            "Can I order a specific vehicle ?",
                                            "Can I cancel or change my order ?",
                                            "Why hasn't anyone been assigned to my order yet ?",
                                            "How do I know if the courier has delivered the parcel ?"]},
                                    {answer:["You only need to book 30 minutes in advance if the first delivery is within travel zone 1, one hour in advance if in travel zone 2 and 1.5–2 hours if further out.",
                                            "When you fill in the form, you can use the free Preferred Courier service in your online account. You can choose couriers you have used before and whom you have given a 5-star rating. Your chosen courier will receive a message that you would like them to deliver your order. If the courier wants to take the order, he/she clicks on it.",
                                            "We don’t know in advance what car the courier is driving. To make sure you get a courier whose car is big enough, specify the dimensions and weight of the item. You will get responses from couriers who have cars big enough to fulfill your order.",
                                            "If you change the order after the courier has set off, you will be charged ₹ 200. You can make changes through your online account or by phone, but these changes have to be agreed upon with the courier.",
                                            "Our system works based on responses. So far, no couriers have responded to your order. Depending on the day and the route, it can take from one minute to an hour to find a courier.",
                                            "You will receive a text message or email. The recipient will also receive a text message notifying them that the courier is coming (unless the delivery is a surprise)."
                                        ]}],
                                    "Testimonials":[{img:[]},{name:[]},{possition:[]},{company:[]},
                                    {feedback:["I have been using Kabra Express for a few months now and I am very happy with the service. The delivery is always on time and the drivers are very polite. I would recommend it to anyone looking for a reliable and convenient delivery service.",
                                                "I have been using Kabra express for the past few months and I am very impressed with their service. I love that I can get all my groceries, food, and even gifts delivered to my doorstep with just a few clicks. Kabra express has made my life so much easier and hassle-free!",
                                                "I was so skeptical about ordering my groceries online but Kabra Express has changed my mind. They are prompt and efficient & the products are always fresh. I don't have to worry about anything, they take care of it all. Highly recommend!"
                                    ]}]                                        
                   }
  
  const CommonBox = ()=>{
    return(
      <div className="customerCommonBox">
        <div className="features commonBoxShadow" onMouseOver={() => { setTabActive(1) }}>
          <div className="section-title" data-wow-delay="0.1s">
            <h2>Our Features</h2>
          </div>
          <div className="centalisedFeatures">
            <OurFeatures data={tabValue === 0 ? individualWithoutFleet : tabValue === 1 ? individualWithFleet :
              tabValue === 2 ? transportComapny : tabValue === 3 ? transportComapny :
                tabValue === 4 ? transportComapny : ""} />
          </div>
        </div>
        <div className="howItWorks commonBoxShadow" onMouseOver={() => { setTabActive(2) }}>
          <div className="section-title" data-wow-delay="0.1s">
            <h2>How It Works</h2>
          </div>
          <div className="centalisedHowItWorks">
            <HowItWorks data={tabValue === 0 ? individualWithoutFleet : tabValue === 1 ? individualWithFleet :
              tabValue === 2 ? transportComapny : tabValue === 3 ? transportComapny :
                tabValue === 4 ? transportComapny : ""} />
          </div>
        </div>
        <div className="trestimonials commonBoxShadow" onMouseOver={() => { setTabActive(3) }}>
          <div className="section-title" data-wow-delay="0.1s">
            <h2>Testimonials</h2>
          </div>
          <div className="centalisedTestimonials">
            <Testimonials data={tabValue === 0 ? individualWithoutFleet : tabValue === 1 ? individualWithFleet :
              tabValue === 2 ? transportComapny : tabValue === 3 ? transportComapny :
                tabValue === 4 ? transportComapny : ""} />
          </div>
        </div>
        <div className="faqIntracity commonBoxShadow" onMouseOver={() => { setTabActive(4) }}>
          <section className="faq">
            <div className="container">
              <div className="section-title wow" data-wow-delay="0.1s">
                <h2>Frequently Asked Questions</h2>
              </div>
              <FAQ data={tabValue === 0 ? individualWithoutFleet : tabValue === 1 ? individualWithFleet :
                tabValue === 2 ? transportComapny : tabValue === 3 ? transportComapny :
                  tabValue === 4 ? transportComapny : ""} />
            </div>
          </section>
        </div>
        {mobileView ? <></> : 
        <div className="row ml38Percentage mt50">
          <button
            className="btn btn-primary-one text-white form_one ml170 mt5"
            onClick={() => {
              topFunction();
            }}
          > 
              Join Us Now
            </button>
          </div>}
      </div>
    )
  }

  const SideNav = () => {
    return (
      <div className="sideNav" onMouseEnter={() => { document.body.style.overflowY = 'hidden' }} onMouseLeave={() => document.body.style.overflowY = 'scroll'}>
        <div className={tabActive === 0 ? "activeNew" : ""} onClick={() => { topFunction2("book"), setTabActive(0) }}>Join Us</div>
        <div className={tabActive === 1 ? "activeNew" : ""} onClick={() => { topFunction2("feature"), setTabActive(1) }}>Features</div>
        <div className={tabActive === 2 ? "activeNew" : ""} onClick={() => { topFunction2("how"), setTabActive(2) }}>How It Works</div>
        <div className={tabActive === 3 ? "activeNew" : ""} onClick={() => { topFunction2("test"), setTabActive(3) }}>Testimonials</div>
        <div className={tabActive === 4 ? "activeNew" : ""} onClick={() => { topFunction2("faq's"), setTabActive(4) }}>FAQ's</div>
      </div>
    )
  }

  const MyArr: any = [{ head: "Direct Sales Agent", tabName: "Direct Sales Agent" },
  { head: "Individual With Fleet", tabName: "Individual With Fleet" },
  { head: "Transport Company", tabName: "Transport Company" },
  { head: "Warehouse", tabName: "Warehouse" },
  { head: "Investors", tabName: "Investors" },
  ]

  const getBasicDetails = () => {
    console.log("hbvjbdvbndvs")
  }
  return (
    <Fragment>
      {mobileView ?
      <div className='Container2'>
      {MyArr.map((e:any,i:number)=>{
            console.log(`<IndivisualRegistration514 />` , e , i)
            return(
            <div className="question-wrapper2" key={i}>
              <div className={lastTab !== "" && lastTab === e.head ? 'question2 qustion2Active' : 'question2'} onClick={() => {
                console.log(`e fgsdb`,e.tabName)
                if(e.tabName === "Direct Sales Agent"){
                  navigate("/partners/direct-sales")
                }
                else if(e.tabName === "Individual With Fleet"){
                  navigate("/partners/individual-with-fleet")
                }
                else if(e.tabName === "Transport Company"){
                  navigate("/partners/transport-company")
                }
                else{
                  alert("This Feature Will be Livee Soon. Stay Tuned !")
                }
              }}>
                <div className={lastTab !== "" && lastTab === e.head ? 'normalHeading h6New' : 'normalHeading'}>{e.head}</div>
                {/* <div className={e.head === "Direct Sales Agent" ? "ml30Percent" : e.head === "Individual With Fleet" ? "ml23Percent" : 
                                e.head === "Transport Company" ? "ml26Percent" : e.head === "Warehouse" ? "ml51Percent" : e.head === "Investors" ? "ml57Percent" : ""}>
                  {lastTab !== "" && lastTab === e.head ? <FaChevronUp /> : <FaChevronDown />}
                </div> */}
                </div>
                {/* <div className={numberval2 === 1? 'answer2 mt10px active' : 'answer2 mt10px'}>
                  {e.tabName === "Direct Sales Agent" && numberval2 === 1 ? <>  <IndivisualRegistration displayFleet={false} /> 
                  <CommonHomeTab Arr={individualWithFleet} /></>: ""}
                  {e.tabName === "Individual With Fleet" && numberval2 === 2 ?  
                  <>  <IndivisualRegistration displayFleet={true} /> 
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
                  {e.tabName === "Transport Company" && numberval2 === 3 ?  
                  <><IndivisualRegistration />
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
                  {e.tabName === "Warehouse" && numberval2 === 4 ?  
                  <><Testing />
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
                  {e.tabName === "Investors" && numberval2 === 5 ?  
                  <><Testing />
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
              </div>
              <div className={numberval2 === 2? 'answer2 mt10px active' : 'answer2 mt10px'}>
                  {e.tabName === "Direct Sales Agent" && numberval2 === 1 ? 
                  <IndivisualRegistration displayFleet={false} /> : ""}
                  {e.tabName === "Individual With Fleet" && numberval2 === 2 ? 
                  <>  <IndivisualRegistration displayFleet={true} /> 
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
                  {e.tabName === "Transport Company" && numberval2 === 3 ? 
                  <><IndivisualRegistration />
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
                  {e.tabName === "Warehouse" && numberval2 === 4 ? 
                  <><Testing />
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
                  {e.tabName === "Investors" && numberval2 === 5 ? 
                  <><Testing />
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
              </div>
              <div className={numberval2 === 3? 'answer2 mt10px active' : 'answer2 mt10px'}>
                  {e.tabName === "Direct Sales Agent" && numberval2 === 1 ? 
                  <IndivisualRegistration displayFleet={false} /> : ""}
                  {e.tabName === "Individual With Fleet" && numberval2 === 2 ? 
                  <>  <IndivisualRegistration displayFleet={true} /> 
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
                  {e.tabName === "Transport Company" && numberval2 === 3 ? 
                  <><IndivisualRegistration />
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
                  {e.tabName === "Warehouse" && numberval2 === 4 ? 
                  <><Testing />
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
                  {e.tabName === "Investors" && numberval2 === 5 ? 
                  <><Testing />
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
              </div>
              <div className={numberval2 === 4? 'answer2 mt10px active' : 'answer2 mt10px'}>
                  {e.tabName === "Direct Sales Agent" && numberval2 === 1 ? 
                  <IndivisualRegistration displayFleet={false} /> : ""}
                  {e.tabName === "Individual With Fleet" && numberval2 === 2 ? 
                  <>  <IndivisualRegistration displayFleet={true} /> 
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
                  {e.tabName === "Transport Company" && numberval2 === 3 ? 
                  <><IndivisualRegistration />
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
                  {e.tabName === "Warehouse" && numberval2 === 4 ? 
                  <><Testing />
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
                  {e.tabName === "Investors" && numberval2 === 5 ? 
                  <><Testing />
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
              </div>
              <div className={numberval2 === 5? 'answer2 mt10px active' : 'answer2 mt10px'}>
                  {e.tabName === "Direct Sales Agent" && numberval2 === 1 ? 
                  <IndivisualRegistration displayFleet={false} /> : ""}
                  {e.tabName === "Individual With Fleet" && numberval2 === 2 ? 
                  <>  <IndivisualRegistration displayFleet={true} /> 
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
                  {e.tabName === "Transport Company" && numberval2 === 3 ? 
                  <><IndivisualRegistration />
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
                  {e.tabName === "Warehouse" && numberval2 === 4 ? 
                  <><Testing />
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
                  {e.tabName === "Investors" && numberval2 === 5 ? 
                  <><Testing />
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
              </div>
              <div className={numberval2 === 6? 'answer2 mt10px active' : 'answer2 mt10px'}>
                  {e.tabName === "Direct Sales Agent" && numberval2 === 1 ? 
                  <IndivisualRegistration displayFleet={false} /> : ""}
                  {e.tabName === "Individual With Fleet" && numberval2 === 2 ? 
                  <>  <IndivisualRegistration displayFleet={true} /> 
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
                  {e.tabName === "Transport Company" && numberval2 === 3 ? 
                  <><IndivisualRegistration />
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
                  {e.tabName === "Warehouse" && numberval2 === 4 ? 
                  <><Testing />
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
                  {e.tabName === "Investors" && numberval2 === 5 ? 
                  <><Testing />
                  <CommonHomeTab Arr={individualWithFleet} /></> : ""}
              </div> */}
              </div>
            )
          })}
        </div>
        :
        <>
          <div className='homePageDivFromPartners'>
            <div className='headingNavbar'>
              <div className='homePageHeadingTabs'>
                <div className={tabValue === 0 ? "sixTabs active" : "sixTabs"} onClick={() => { handleChange(0) }}>Direct Sales Agent</div>
                <div className={tabValue === 1 ? "sixTabs active" : "sixTabs"} onClick={() => { handleChange(1) }}>Individual With Fleet</div>
                <div className={tabValue === 2 ? "sixTabs active" : "sixTabs"} onClick={() => { handleChange(2) }}>Transport Company</div>
                <div className={tabValue === 3 ? "sixTabs active" : "sixTabs"} onClick={() => { alert("This Freeature Will Be Available Soon !") }}>Warehouse</div>
                <div className={tabValue === 4 ? "sixTabs active" : "sixTabs"} onClick={() => { alert("This Freeature Will Be Available Soon !") }}>Investors</div>
              </div>
            </div>
          </div>
          <TabPanel value={tabValue} index={0}>
            <div className="intracityContainer">
              <div className="scrollableContainer">
                <SideNav />
                <div className="intraCity">
                  <div onMouseOver={() => { setTabActive(0) }}>
                    {/* <IndivisualRegistration displayFleet={false} /> */}
                    <div className="transportCompanyContainer">
                      <div className='transportImageConainer'>
                        <img src='https://i.ibb.co/gFCyznG/6bb6a3473470d9c49b3cf0974f446347-removebg-preview.png' />
                      </div>
                      <div className="transportFormikFormContainer">
                        {directSalesPageNo === 0 &&
                          <DirectSalesAgent
                            commonUsedForm={true}
                            messageClass={"massageTitle"}
                            commonInputFieldCss={"generalInputField"}
                            submitFunc={getBasicDetails}
                          />
                          // <Formik
                          //   initialValues={{
                          //     username: '',
                          //     email: '',
                          //   }}
                          //   onSubmit={(values:any) => {
                          //     // same shape as initial values
                          //     console.log(values);
                          //   }}
                          // >
                          //   {({ errors, touched, validateField, validateForm }) => (
                          //     <Form>
                          //       <Field name="email" validate={validateEmail} />
                          //       {errors.email && touched.email && <div>{errors.email}</div>}

                          //       <Field name="username" validate={validateUsername} />
                          //       {errors.username && touched.username && <div>{errors.username}</div>}
                          //       {/** Trigger field-level validation
                          //         imperatively */}
                          //       <button type="button" onClick={() => validateField('username')}>
                          //         Check Username
                          //       </button>
                          //       {/** Trigger form-level validation
                          //         imperatively */}
                          //       <button
                          //         type="button"
                          //         onClick={() => validateForm().then(() => console.log('blah'))}
                          //       >
                          //         Validate All
                          //       </button>
                          //       <button type="submit">Submit</button>
                          //     </Form>
                          //   )}
                          // </Formik>
                        }
                        {/* {directSalesPageNo === 1 &&
                              <div>02</div>
                            } 
                            {directSalesPageNo === 2 &&
                              <div>03</div>
                            } 
                            {directSalesPageNo === 3 &&
                              <div>04</div>
                            }  */}
                        {/* <div className="buttonsContainerForTC">
                              {directSalesPageNo > 0 && 
                                <ButtonSecondary 
                                className={"prevButton"}
                                onClick={() => {
                                  handleDirectSalesAgentButtons("prevButton")
                                }}
                                >
                                  Previous
                                </ButtonSecondary>
                              }
                              {directSalesPageNo <= 2 && 
                                <ButtonPrimary 
                                  className={"nextButton"}
                                  onClick={() => handleDirectSalesAgentButtons("nextButton")}
                                >
                                  Next
                                </ButtonPrimary>
                              } 
                              {directSalesPageNo === 3 && 
                                <ButtonPrimary 
                                  className={"nextButton"}
                                  onClick={() => handleDirectSalesAgentButtons("submitButton")}
                                >
                                  Submit
                                </ButtonPrimary>
                              }
                            </div> */}
                      </div>
                    </div>
                  </div>
                  <CommonBox />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <div className="intracityContainer">
              <div className="scrollableContainer">
                <SideNav />
                <div className="intraCity">
                  <div onMouseOver={() => { setTabActive(0) }}>
                    {/* <IndivisualRegistration displayFleet={true} formName={""} /> */}
                    {/* <PartnersForms formName={"individualWithFleet"} /> */}

                    <div className="transportCompanyContainer">
                      <div className='transportImageConainer'>
                        <img src='https://i.ibb.co/gFCyznG/6bb6a3473470d9c49b3cf0974f446347-removebg-preview.png' />
                      </div>
                      <div className="transportFormikFormContainer">
                        {directSalesPageNo === 0 &&
                          <IndividualWithFleet
                            commonUsedForm={true}
                            messageClass={"massageTitle"}
                            commonInputFieldCss={"generalInputField"}
                            submitFunc={getBasicDetails}
                          />
                          // <Formik
                          //   initialValues={{
                          //     username: '',
                          //     email: '',
                          //   }}
                          //   onSubmit={(values:any) => {
                          //     // same shape as initial values
                          //     console.log(values);
                          //   }}
                          // >
                          //   {({ errors, touched, validateField, validateForm }) => (
                          //     <Form>
                          //       <Field name="email" validate={validateEmail} />
                          //       {errors.email && touched.email && <div>{errors.email}</div>}

                          //       <Field name="username" validate={validateUsername} />
                          //       {errors.username && touched.username && <div>{errors.username}</div>}
                          //       {/** Trigger field-level validation
                          //         imperatively */}
                          //       <button type="button" onClick={() => validateField('username')}>
                          //         Check Username
                          //       </button>
                          //       {/** Trigger form-level validation
                          //         imperatively */}
                          //       <button
                          //         type="button"
                          //         onClick={() => validateForm().then(() => console.log('blah'))}
                          //       >
                          //         Validate All
                          //       </button>
                          //       <button type="submit">Submit</button>
                          //     </Form>
                          //   )}
                          // </Formik>
                        }
                        {/* {directSalesPageNo === 1 &&
                              <div>02</div>
                            } 
                            {directSalesPageNo === 2 &&
                              <div>03</div>
                            } 
                            {directSalesPageNo === 3 &&
                              <div>04</div>
                            }  */}
                        {/* <div className="buttonsContainerForTC">
                              {directSalesPageNo > 0 && 
                                <ButtonSecondary 
                                className={"prevButton"}
                                onClick={() => {
                                  handleDirectSalesAgentButtons("prevButton")
                                }}
                                >
                                  Previous
                                </ButtonSecondary>
                              }
                              {directSalesPageNo <= 2 && 
                                <ButtonPrimary 
                                  className={"nextButton"}
                                  onClick={() => handleDirectSalesAgentButtons("nextButton")}
                                >
                                  Next
                                </ButtonPrimary>
                              } 
                              {directSalesPageNo === 3 && 
                                <ButtonPrimary 
                                  className={"nextButton"}
                                  onClick={() => handleDirectSalesAgentButtons("submitButton")}
                                >
                                  Submit
                                </ButtonPrimary>
                              }
                            </div> */}
                      </div>
                    </div>
                  </div>
                  <CommonBox />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <div className="intracityContainer">
              <div className="scrollableContainer">
                <SideNav />
                <div className="intraCity">
                  <div onMouseOver={() => { setTabActive(0) }}>
                    {/* <IndivisualRegistration formName={"TransportCompnay"} /> */}

                    <div className="transportCompanyContainer">
                      <div className='transportImageConainer'>
                        <img src='https://i.ibb.co/gFCyznG/6bb6a3473470d9c49b3cf0974f446347-removebg-preview.png' />
                      </div>
                      <div className="transportFormikFormContainer">
                        {directSalesPageNo === 0 &&
                          <TransportCompany
                            commonUsedForm={true}
                            messageClass={"massageTitle"}
                            commonInputFieldCss={"generalInputField"}
                            submitFunc={getBasicDetails}
                          />
                          // <Formik
                          //   initialValues={{
                          //     username: '',
                          //     email: '',
                          //   }}
                          //   onSubmit={(values:any) => {
                          //     // same shape as initial values
                          //     console.log(values);
                          //   }}
                          // >
                          //   {({ errors, touched, validateField, validateForm }) => (
                          //     <Form>
                          //       <Field name="email" validate={validateEmail} />
                          //       {errors.email && touched.email && <div>{errors.email}</div>}

                          //       <Field name="username" validate={validateUsername} />
                          //       {errors.username && touched.username && <div>{errors.username}</div>}
                          //       {/** Trigger field-level validation
                          //         imperatively */}
                          //       <button type="button" onClick={() => validateField('username')}>
                          //         Check Username
                          //       </button>
                          //       {/** Trigger form-level validation
                          //         imperatively */}
                          //       <button
                          //         type="button"
                          //         onClick={() => validateForm().then(() => console.log('blah'))}
                          //       >
                          //         Validate All
                          //       </button>
                          //       <button type="submit">Submit</button>
                          //     </Form>
                          //   )}
                          // </Formik>
                        }
                        {/* {directSalesPageNo === 1 &&
                              <div>02</div>
                            } 
                            {directSalesPageNo === 2 &&
                              <div>03</div>
                            } 
                            {directSalesPageNo === 3 &&
                              <div>04</div>
                            }  */}
                        {/* <div className="buttonsContainerForTC">
                              {directSalesPageNo > 0 && 
                                <ButtonSecondary 
                                className={"prevButton"}
                                onClick={() => {
                                  handleDirectSalesAgentButtons("prevButton")
                                }}
                                >
                                  Previous
                                </ButtonSecondary>
                              }
                              {directSalesPageNo <= 2 && 
                                <ButtonPrimary 
                                  className={"nextButton"}
                                  onClick={() => handleDirectSalesAgentButtons("nextButton")}
                                >
                                  Next
                                </ButtonPrimary>
                              } 
                              {directSalesPageNo === 3 && 
                                <ButtonPrimary 
                                  className={"nextButton"}
                                  onClick={() => handleDirectSalesAgentButtons("submitButton")}
                                >
                                  Submit
                                </ButtonPrimary>
                              }
                            </div> */}
                      </div>
                    </div>
                  </div>
                  <CommonBox />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <div className="intracityContainer">
              <div className="scrollableContainer">
                <SideNav />
                <div className="intraCity">
                  <div onMouseOver={() => { setTabActive(0) }}>
                    <Testing />
                  </div>
                  <CommonBox />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <div className="intracityContainer">
              <div className="scrollableContainer">
                <SideNav />
                <div className="intraCity">
                  <div onMouseOver={() => { setTabActive(0) }}>
                    <Testing />
                  </div>
                  <CommonBox />
                </div>
              </div>
            </div>
          </TabPanel>
        </>
      }
    </Fragment>
  );
};
const mapDispatchToProps = {
  getCompanyList,
  changeCompanyDoc,
  createCompanyDocAction,
  updateCompanyDocAction,
  setCompanyDocAction,
  clearCompanyDoc,
  getCompany,
  setCompanyError,
};

const mapStateToProps = (state: any) => {
  return {
    cities: state.user.allcities,
    companyList: state.company.getIn(["companyList"]),
    company: state.company.get("company_doc"),
    role: state.user.role,
    userCompanyID: state.user.company.id,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PartnerUrl);
