
import { ServiceMaker , buildQueryString} from "./index";

export const getAllBranches = (data?: any) =>
ServiceMaker("branch/all", "POST", data);

export const fetchBranchRank = (data:any) =>
    ServiceMaker("report/branchRank","POST",data)

    export const fetchBranchMISReport = (data:any) =>
    ServiceMaker(`report/branchIndividualMIS?${buildQueryString(data)}`,"GET")

    export const fetchBranchMISNEWReport = (data:any) =>
    ServiceMaker(`report/branchIndividualMISNew?${buildQueryString(data)}`,"GET")

