import React from 'react'
import { PageHeader } from "../UI"
import { termsAndConditions } from "../../constants/data"

const TermsAndConditions = () => {
    return (
        <main className="page-container-grid">
            <PageHeader title="Terms & Conditions" imgSrc="terms-and-conditions/bg.jpg" />
            <section className="p-3 my-container flex-center">
                <ul className="terms-and-conditions-list flex-col gap-1" style={{ listStyle: "disc" }}>
                    {termsAndConditions.length > 0 && termsAndConditions.map(item => <li key={item} className="fs-medium fw-400" style={{maxWidth: "65ch"}}>{item}</li>)}
                </ul>
            </section>
        </main>
    )
}

export default TermsAndConditions