import React, { ComponentProps } from "react";
import "./circular-progress.css";
import { CSSColorVariantType, CSSWeightType } from "../../../types/common";

type CircularProgressProps = {
  size?: number;
  thickness?: number;
  variant?: CSSColorVariantType;
  colorWeight?: CSSWeightType;
} & ComponentProps<"i">;

export default function CircularProgress({
  size = 10,
  thickness = 5,
  variant = "primary",
  colorWeight = 400,
  style,
  ...props
}: CircularProgressProps) {
  return (
    <i
      data-size={size}
      data-thickness={thickness}
      style={{
        width: size ?? style?.width,
        height: size ?? style?.height,
        border: `${thickness ?? style?.borderWidth} ${style?.borderStyle} var(--clr-${variant}-${colorWeight})`,
        ...style,
      }}
      className={`taptap-circular-progress`}
      {...props}
    />
  );
}
