export default (data: any) => {
  const d = new FormData();
  Object.keys(data).map((key) => {
    try {
      if (data[key] === null || data[key] === undefined) {
        return;
      }
      if (data[key] && typeof data[key] === "object") {
        if (
          data[key].constructor &&
          (data[key].constructor === Blob || data[key].constructor === File)
        ) {
          d.append(key, data[key], data[key].name);
        } else if (
          data[key].constructor &&
          data[key].constructor === Array &&
          data[key].length > 0 &&
          data[key][0] &&
          (data[key][0].constructor === Blob ||
            data[key][0].constructor === File)
        ) {
          data[key].map((fl: File, ind: number) => {
            d.append(`${key}[${ind}]`, fl, fl.name);
          });
        } else if (
          data[key].constructor &&
          (data[key].constructor === String || data[key].constructor === Date)
        ) {
          d.append(key, data[key]);
        } else {
          d.append(key, JSON.stringify(data[key]));
        }
      } else {
        d.append(key, data[key]);
      }
    } catch (err : any) {
      console.log("got json to form error:--", err);
    }
  });
  return d;
};
