import React from "react";
import { Select } from "../UI";
import makeAnimated from "react-select/animated";

type OriginDestinationBranchProps = {
  allBranchList: any;
  originBranch: any;
  setOriginBranch: any;
  destinationBranch: any;
  setDesitnationBranch: any;
  isMulti?: boolean;
  containerClassName?: string;
};

const OriginDestinationBranch = ({
  allBranchList,
  originBranch,
  setOriginBranch,
  destinationBranch,
  setDesitnationBranch,
  isMulti = false,
  containerClassName = "two-column m-one-column",
}: OriginDestinationBranchProps) => {
  return (
    <div className={`${containerClassName} gap-1`}>
      <div>
        <label htmlFor="origin-branch" className="m-0 fw-500">
          Origin Branch
        </label>
        <Select
          isMulti={isMulti}
          options={allBranchList}
          value={originBranch}
          onChange={(e: any) => setOriginBranch(e)}
          components={makeAnimated()}
          placeholder={"Source Branch"}
        />
      </div>
      <div>
        <label htmlFor="destination-branch" className="m-0 fw-500">
          Destination Branch
        </label>
        <Select
          isMulti={isMulti}
          options={allBranchList}
          value={destinationBranch}
          onChange={(e: any) => setDesitnationBranch(e)}
          components={makeAnimated()}
          placeholder={"Destination Branch"}
        />
      </div>
    </div>
  );
};

export default OriginDestinationBranch;
