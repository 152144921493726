export const logoImg = "/newAssets/logo/logo.png";
export const appleStoreImg = "/assets/landing-page/footer-images/apple-store-logo.svg";
export const playStoreImg = "/assets/landing-page/footer-images/playstore-logo.svg";
export const facebookImg = "/assets/landing-page/footer-images/facebook.png";
export const instagramImg = "/assets/landing-page/footer-images/instagram.png";
export const linkedinImg = "/assets/landing-page/footer-images/linkedin.png";
export const youtubeImg = "/assets/landing-page/footer-images/youtube.png";
export const gmailImg = "/assets/about-us/team/gmail.png";
export const twitterImg = "/assets/about-us/team/twitter.png";
export const kabraExpressImg = "/assets/landing-page/footer-images/kabra_express_logo.png";

export const ourFeaturesImages = ["/assets/landing-page/our-features/on-time-delivery.webp", "/assets/landing-page/our-features/on-time-delivery.webp", "/assets/landing-page/our-features/on-time-delivery.webp", "/assets/landing-page/our-features/on-time-delivery.webp", "/assets/landing-page/our-features/on-time-delivery.webp", "/assets/landing-page/our-features/on-time-delivery.webp"]

export const howItWorksImages = ["/assets/landing-page/how-it-works/1.png", "/assets/landing-page/how-it-works/1.png", "/assets/landing-page/how-it-works/1.png"];
export const testimonialsImages = ["/assets/landing-page/testimonials/1.png", "/assets/landing-page/testimonials/2.png", "/assets/landing-page/testimonials/3.png"];


export const mobileCarouselPartnersImages = ["/assets/mobile-carousel-partners-images/1.jpg", "/assets/mobile-carousel-partners-images/2.jpg", "/assets/mobile-carousel-partners-images/3.jpg", "/assets/mobile-carousel-partners-images/4.jpg", "/assets/mobile-carousel-partners-images/5.jpg",]

export const bikeImg = "/assets/navigation/bike.png";
export const bikeGrayImg = "/assets/navigation/bike-gray.png";
export const mobileBikeImg = "/assets/navigation/mobile-bike.png";
export const bikeWhiteImg = "/assets/navigation/bike-white.png";
export const fullLoadImg = "/assets/navigation/full-load.png";
export const fullLoadGrayImg = "/assets/navigation/full-load-gray.png";
export const mobileFullLoadImg = "/assets/navigation/mobile-full-load.png";
export const fullLoadWhiteImg = "/assets/navigation/full-load-white.png";
export const partLoadImg = "/assets/navigation/part-load.png";
export const partLoadGrayImg = "/assets/navigation/part-load-gray.png";
export const mobilePartLoadImg = "/assets/navigation/mobile-part-load.png";
export const partLoadWhiteImg = "/assets/navigation/part-load-white.png";
export const tempoImg = "/assets/navigation/tempo.png";
export const tempoGrayImg = "/assets/navigation/tempo-gray.png";
export const mobileTempoImg = "/assets/navigation/mobile-tempo.png";
export const tempoWhiteImg = "/assets/navigation/tempo-white.png";
export const packersImg = "/assets/navigation/packers.png";
export const packersGrayImg = "/assets/navigation/packers-gray.png";
export const mobilePackersImg = "/assets/navigation/mobile-packers.png";
export const packersWhiteImg = "/assets/navigation/packers-white.png";
export const moversImg = "/assets/navigation/movers.png";
export const moversGrayImg = "/assets/navigation/movers-gray.png";
export const mobileMoversImg = "/assets/navigation/mobile-movers.png";
export const moversWhiteImg = "/assets/navigation/movers-white.png";
export const courierImg = "/assets/navigation/courier.png";
export const courierGrayImg = "/assets/navigation/courier-gray.png";
export const mobileCourierImg = "/assets/navigation/mobile-courier.png";
export const courierWhiteImg = "/assets/navigation/courier-white.png";
export const warehouseImg = "/assets/navigation/warehouse.png";
export const warehouseGrayImg = "/assets/navigation/warehouse-gray.png";
export const mobileWarehouseImg = "/assets/navigation/mobile-warehouse.png";
export const warehouseWhiteImg = "/assets/navigation/warehouse-white.png";
export const flightImg = "/assets/navigation/flight.png";
export const flightGrayImg = "/assets/navigation/flight-gray.png";
export const mobileFlightImg = "/assets/navigation/mobile-flight.png";
export const flightWhiteImg = "/assets/navigation/flight-white.png";
export const shipImg = "/assets/navigation/ship.png";
export const shipGrayImg = "/assets/navigation/ship-gray.png";
export const mobileShipImg = "/assets/navigation/mobile-ship.png";
export const shipWhiteImg = "/assets/navigation/ship-white.png";

// banners
// intra city
export const intraCityWebBanner = "/assets/banners/intra-city/web.jpg"
export const intraCityMobile1Banner = "/assets/banners/intra-city/mobile-1.jpg"
export const intraCityMobile2Banner = "/assets/banners/intra-city/mobile-2.jpg"
export const intraCityMobile3Banner = "/assets/banners/intra-city/mobile-3.jpg"

// part load
export const partLoadWebBanner = "/assets/banners/part-load/web.jpg"
export const partLoadMobile1Banner = "/assets/banners/part-load/mobile-1.jpg"
export const partLoadMobile2Banner = "/assets/banners/part-load/mobile-2.jpg"
export const partLoadMobile3Banner = "/assets/banners/part-load/mobile-3.jpg"

// full load
export const fullLoadWebBanner = "/assets/banners/full-load/web.jpg"
export const fullLoadMobile1Banner = "/assets/banners/full-load/mobile-1.jpg"
export const fullLoadMobile2Banner = "/assets/banners/full-load/mobile-2.jpg"
export const fullLoadMobile3Banner = "/assets/banners/full-load/mobile-3.jpg"