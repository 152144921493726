import { FormControlLabel,Switch as SwitchContainer } from "@mui/material"
import React from 'react'

interface Iprops{
    onChange?:any;
    onClick?:any;
    checked?:any;
    value?:any;
    name?:any;
    label?:any;
    disabled?:any;
    color?: any;
}
export  function Switch(props:Iprops) {
    return (
        <FormControlLabel
        control={
          <SwitchContainer
            onChange={props.onChange }
            onClick={props.onClick}
           checked={props.checked}
            value={props.value}
            name={props.name}
            disabled={props.disabled}
            color="primary"
          />
        }
        label={props.label}
        labelPlacement="start"
      />
    )
}
