import React from 'react';
import TagManager from 'react-gtm-module';

const TriggerExample = () => {
  const handleClick = () => {
        // Initialize Google Tag Manager
        const tagManagerArgs = {
        gtmId: 'GTM-WXPKHMBM',
        };
        TagManager.initialize(tagManagerArgs);

    // Trigger an event (replace 'your_event_name' with your desired event name)
    TagManager.dataLayer({
      dataLayer: {
        event: 'Initialisation',
      },
    });

    // Additional actions when the button is clicked
    // ...
  };

  return (
    <div>
      <h1>Trigger Example</h1>
      <button onClick={handleClick}>Trigger Event</button>
    </div>
  );
};

export default TriggerExample;
