import { IDepartmentRights } from "../reducers/utils/ProcessRights";

export const SET_RIGHTS = "RIGHTS:SET_RIGHTS";

interface SetRightsAction {
  type: typeof SET_RIGHTS;
  payload: {
    rights: IDepartmentRights<boolean>;
  }[];
  role: "S" | "A" | "O";
}

export type RightsActionTypes = SetRightsAction;
