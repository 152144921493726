import React, { CSSProperties, useState } from "react";
import { CSSSizes } from "../../../types/common";
import { IoIosStarOutline, IoIosStar } from "react-icons/io";
import "./rating.css";

type RatingProps = {
  className?: string;
  containerClassName?: string;
  name?: string;
  value: number;
  max?: number;
  precision?: number;
  size?: CSSSizes;
  readonly?: boolean; // Whether the rating can be changed by the user
  onChange?: (value: number) => void; // Callback to handle rating change
  style?: CSSProperties;
  FirstIcon?: any;
  SecondIcon?: any;
};

export default function Rating({
  // name,
  value,
  max = 3,
  precision = 1,
  size = "regular",
  onChange,
  readonly = false,
  className = "",
  containerClassName = "",
  style,
  FirstIcon = IoIosStar,
  SecondIcon = IoIosStarOutline,
}: RatingProps) {
  const [hoverValue, setHoverValue] = useState<number | null>(null);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const stars = new Array(max).fill(0);
  const RenderedFillIcon = FirstIcon;
  const RenderedOutlineIcon = SecondIcon;

  const handleMouseEnter = (index: number) => {
    if (!readonly) {
      setHoverValue(index + 1);
    }
  };

  const handleMouseLeave = () => {
    
    if (!isClicked && !readonly) {
      setHoverValue(null);
    }
    setIsClicked(false);
  };

  const handleClick = (index: number) => {
    if(!readonly){
      const newValue = (index + 1) * precision;
      setIsClicked(true);
      onChange?.(newValue);
    }
  };

  const getStarIcon = (index: number) => {
    const filled = hoverValue !== null ? hoverValue > index : value > index;
    return filled ? (
      <RenderedFillIcon style={{ color: "orange"}} />
    ) : (
      <RenderedOutlineIcon />
    );
  };

  return (
    <div
      className={`${
        containerClassName || "flex ai-center gap-4"
      } taptap-rating-container`}
    >
      {stars.map((_, index) => (
        <span
          key={index}
          onMouseEnter={() => {
            handleMouseEnter(index);
          }}
          onMouseLeave={() => {
            handleMouseLeave();
          }}
          onClick={() => {
            handleClick(index);
          }}
          
          className={`${className} taptap-rating-star taptap-rating-star-${size}`}
          style={style}
        >
          {getStarIcon(index)}
        </span>
      ))}
    </div>
  );
}
