import React from "react";
import ReactDOM from "react-dom";
import Printd from "printd";

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log("print catch:--", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Print component rendering failed.Contact taptap support.</h1>;
    }

    return this.props.children;
  }
}

export const printReactComponent = (
  Component: any,
  props: any,
  printCss?: any
) => {
  let printDoc: any = document.getElementById("taptap_print_component");
  ReactDOM.render(
    (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    ),
    printDoc,
    () => {
      const d = new Printd();
      d.print(
        printDoc,
        [printCss]
      );
    }
  );
};
