import React, { useState, useEffect } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import useFetchCurrentLocation from "../../pages/CustomerModule/Components/CustomerBooking/hooks/useFetchCurrentLocation.hooks.";
import {
  getAutoCompleteOptions,
  getPlaceData,
} from "../../../services/autoComplete";
import { showHttpError } from "../../../utils/message";
import { MdMyLocation } from "react-icons/md";
import "../../components/GoogleAutoComplete/googleMapAutoComplete.css";
import {
  EditIcon,
  LandMarkIcon,
  SearchIcon,
  SharpLocationIcon,
} from "../../constants/icons";

interface AutoCompleteProps {
  general: boolean;
  where: string;
  className?: string;
  value: any;
  onChange: (value: any) => void;
  onBlur?: (e: any) => void;
  isDisabled?: boolean;
  placeholder?: string;
  setLatitudeLongitude?: (info: any) => void;
  extraField: any;
  isEditable?: boolean;
}

const GoogleAutoComplete: React.FC<AutoCompleteProps> = (props) => {
  const {
    general,
    where,
    className,
    onChange,
    onBlur,
    extraField,
    isEditable = true,
  } = props;

  // states and hooks declaration

  const [inputValue, setInputValue] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  // custom hooks declaration

  const { error, fetchLocation } = useFetchCurrentLocation(); // Use the custom hook

  useEffect(() => {
    if (extraField?.selectedAddress) {
      setInputValue({
        value: extraField?.selectedAddress?.placeId,
        label: extraField?.selectedAddress?.formatted_address,
      });
    } else {
      setInputValue(null);
    }
  }, [extraField?.selectedAddress]);

  useEffect(() => {
    if (extraField?.savedAddresses?.length === 0) {
      setInputValue(null);
    }
  }, [extraField?.savedAddresses?.length]);

  console.log(extraField, "extraField?.coordinateLocation");

  // useEffect(() => {
  //   if(extraField?.coordinateLocation){
  //     setInputValue({
  //       value: extraField?.coordinateLocation?.placeId,
  //       label: extraField?.coordinateLocation?.formatted_address
  //     })
  //   }
  // },[extraField?.coordinateLocation])

  const fetchOptions = async (search: string) => {
    try {
      setIsLoading(true);
      // Filter saved addresses based on search input
      const filteredSavedAddresses = extraField?.savedAddresses.filter(
        (address: any) =>
          address.label.toLowerCase().includes(search.toLowerCase())
      );

      if (search) {
        const payload = { text: search, general, where };
        const res = await getAutoCompleteOptions(payload);

        const fetchedOptions = res.map((i: any) => ({
          label: i.description,
          value: i.place_id,
          isDataFromSuggetion: false,
        }));

        return {
          options: [...filteredSavedAddresses, ...fetchedOptions],
        };
      }
      return { options: filteredSavedAddresses };
    } catch (err) {
      showHttpError(err);
      return { options: [] };
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectChange = async (selectedOption: any) => {
    try {
      if (!selectedOption) return;

      if (selectedOption.value === "current_location") {
        const currentLocation = await fetchLocation("current_location");
        if (currentLocation) {
          onChange({
            ...currentLocation,
            isDataFromSuggetion: false,
            type: "current_location",
          });
        }
        setInputValue({
          value: currentLocation.placeId,
          label: currentLocation?.formatted_address,
          locationType: null,
          isDataFromSuggetion: false,
          _id: undefined,
        });
        return;
      }

      if (!selectedOption.isDataFromSuggetion) {
        const res = await getPlaceData({ input: selectedOption.value });
        const info = {
          lat: res.geometry.location.lat,
          lng: res.geometry.location.lng,
        };
        if (props.setLatitudeLongitude) {
          props.setLatitudeLongitude(info);
        }
        onChange({ ...res, isDataFromSuggetion: false });
        setInputValue(selectedOption);
        return;
      }
      onChange({
        ...selectedOption,
        isDataFromSuggetion: true,
      });
      setInputValue(selectedOption);
    } catch (err) {
      showHttpError(err);
    }
  };

  const handleEdit = async (selectedOption: any) => {
    try {
      if (!selectedOption) return;
      onChange({
        ...selectedOption,
        isDataFromSuggetion: true,
        isEdit: true,
      });
      setInputValue(selectedOption);
      setMenuIsOpen(false);
    } catch (err) {
      showHttpError(err);
    }
  };

  // const customStyles = {
  //   control: (provided: any, state: any) => ({
  //     ...provided,
  //     borderRadius: "8px",
  //     border: "1px solid #ced4da",
  //     padding: "4px",
  //     boxShadow: state.isFocused ? "0 0 0 1px #3699FF" : null,
  //   }),
  //   option: (provided: any, state: any) => ({
  //     ...provided,
  //     backgroundColor: state.isFocused ? "#3699FF" : null,
  //     color: state.isFocused ? "white" : null,
  //   }),
  // };

  return (
    <div className={`autocomplete-container ${className}`}>
      {error && <p className="error-text">{error}</p>}
      <AsyncPaginate
        value={inputValue}
        loadOptions={fetchOptions}
        isDisabled={props.isDisabled}
        placeholder={props.placeholder || "Search for a location"}
        onChange={handleSelectChange}
        isLoading={isLoading}
        debounceTimeout={800}
        additional={{ page: 1 }}
        className={className}
        onBlur={onBlur}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        cacheUniqs={[extraField?.savedAddresses]}
        components={{
          Option: (optionProps) => (
            <CustomOption {...optionProps} handleEdit={handleEdit} isEditable={isEditable} />
          ),
        }}
      />
    </div>
  );
};

const CustomOption = (props: any) => {
  const { innerProps, isFocused, isSelected, data, handleEdit,isEditable } = props;

  return (
    <div
      {...innerProps}
      className={`custom-option ${isFocused ? "focused" : ""} ${isSelected ? "custom-option-active" : ""
        }`}
    >
      <div className="option-content w-100">
        {data.value === "current_location" ? (
          <div className="flex ai-center gap-10">
            <MdMyLocation className="icon-location" />
            <span>Use current location</span>
          </div>
        ) : (
          <>
            {data?.isDataFromSuggetion && isEditable ? (
              <div className="flex ai-center jc-between w-100">
                <div className="label-header">
                  <p className="label-text">{data.locationType}</p>
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent propagation to the option selection
                    handleEdit(data); // Pass setMenuIsOpen to handleEdit
                  }}
                  className="edit-button"
                >
                  <EditIcon className="fs-regular" />
                </button>
              </div>
            ) : null}

            {data?.isDataFromSuggetion ? (
              <div className="sublocation-container">
                <div className="flex ai-center gap-10">
                  <LandMarkIcon className="fs-regular" />
                  <span>{data.l1}</span>
                </div>
                <div className="flex ai-center gap-10">
                  <LandMarkIcon className="fs-regular" />
                  <span>{data.l2}</span>
                </div>
              </div>
            ) : null}

            <div className="flex ai-center gap-10">
              {data?.isDataFromSuggetion ? (
                <SharpLocationIcon className="fs-subheading" />
              ) : (
                <SearchIcon className="fs-regular" />
              )}
              <span>{data.label}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GoogleAutoComplete;
