import React from "react";

import "./static/ripple.css";
import ripple from "../../static/svgs/Ripple.svg";
export const Ripple = (props?:any) => <div className="loader_container" style={{minHeight: props?.minHeight || "100vh"}}>
  <img className="loader" src={ripple} style={props?.style} />
  </div>;
export const RippleForInput = () => (
  <img className="loader_for_input" src={ripple} />
);
// export default Ripple
