import React, { Fragment } from "react";

const Individual = () => {
  return (
    <Fragment>
      <section className="top-heading-1">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div
                className="section-title-ca wow fadeInLeft"
                data-wow-delay="2s"
              >
                <h1>Individual</h1>
                <p className="sa-par">
                  Looking for a delivery partner? Choose Shiprocket and unlock
                  the value of your business.
                </p>
                <a href="#" className="btn get-started-top btn-ps">
                  Learn More
                </a>
              </div>
            </div>

            <div className="col-md-6 wow fadeInRight" data-wow-delay="2s">
              <div className="ci-secimg">
                <img src="img/2354111.png"></img>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="service" className="section-padding">
        <div className="whole-wrap">
          <div className="container box_1170">
            <div className="br-par wow fadeInUp" data-wow-delay="2s">
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
                making this the first true generator on the Internet. It uses a
                dictionary of over 200 Latin words, combined with a handful of
                model sentence structures, to generate Lorem Ipsum which looks
                reasonable. The generated Lorem Ipsum is therefore always free
                from repetition, injected humour, or non-characteristic words
                etc.
              </p>
            </div>

            <section id="pricing" className="home-section  paddingbot-60">
              <div className="container marginbot-50">
                <div className="row">
                  <div className="col-lg-8 col-lg-offset-2">
                    <div className="wow lightSpeedIn" data-wow-delay="0.1s">
                      <div
                        className="section-title wow fadeInUp"
                        data-wow-delay="0.1s"
                      >
                        <h2>Choose a Plan That Works Best for You</h2>
                        <p>Start with a free account and upgrade as you grow</p>
                      </div>
                    </div>
                    <div className="divider-short"></div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-sm-6 pricing-box">
                    <div className="wow bounceInUp" data-wow-delay="0.1s">
                      <div className="pricing-content general">
                        <h2>LITE</h2>
                        <h3>
                          $33<sup>.99</sup> <span>/ one time</span>
                        </h3>
                        <ul>
                          <li>
                            Anthropometry (BMI, WH Ratio){" "}
                            <i className="fa fa-check icon-success"></i>
                          </li>
                          <li>
                            Post Examination Review{" "}
                            <i className="fa fa-check icon-success"></i>
                          </li>
                          <li>
                            General Screening – Basic{" "}
                            <i className="fa fa-check icon-success"></i>
                          </li>
                          <li>
                            <del>Health Screening Report</del>{" "}
                            <i className="fa fa-times icon-danger"></i>
                          </li>
                        </ul>

                        <div className="price-bottom">
                          <a href="#" className="btn get-started btn-lg">
                            Purchase
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 pricing-box">
                    <div className="wow bounceInUp" data-wow-delay="0.2s">
                      <div className="pricing-content general last">
                        <h2>PROFESSIONAL</h2>
                        <h3>
                          $47<sup>.99</sup> <span>/ one time</span>
                        </h3>
                        <ul>
                          <li>
                            Anthropometry (BMI, WH Ratio){" "}
                            <i className="fa fa-check icon-success"></i>
                          </li>
                          <li>
                            Post Examination Review{" "}
                            <i className="fa fa-check icon-success"></i>
                          </li>
                          <li>
                            General Screening – Regular{" "}
                            <i className="fa fa-check icon-success"></i>
                          </li>
                          <li>
                            <del>Health Screening Report</del>{" "}
                            <i className="fa fa-times icon-danger"></i>
                          </li>
                        </ul>

                        <div className="price-bottom">
                          <a href="#" className="btn get-started btn-lg">
                            Purchase
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="tab-content">
              <div className="container tab-pane active" id="my_1">
                <div
                  className="section-title wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <h2>Our Features</h2>
                </div>
                <div className="row">
                  <div className="col-md-4 col-sm-4">
                    <div
                      className="service-info wow fadeInUp"
                      data-wow-delay="0.1s"
                    >
                      <div className="icon">
                        <img src="https://img.icons8.com/bubbles/80/000000/code.png" />
                      </div>
                      <div className="icon-info">
                        <h5>Al Driven ERP Software</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                      </div>
                    </div>
                    <div
                      className="service-info wow fadeInUp"
                      data-wow-delay="0.4s"
                    >
                      <div className="icon">
                        <img src="https://img.icons8.com/bubbles/80/000000/bank-card-front-side.png" />
                      </div>
                      <div className="icon-info">
                        <h5>Fuel Card</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div
                      className="service-info wow fadeInUp"
                      data-wow-delay="0.2s"
                    >
                      <div className="icon">
                        <img src="https://img.icons8.com/bubbles/80/000000/gps-device.png" />
                      </div>
                      <div className="icon-info">
                        <h5>GPS Tracking Device</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                      </div>
                    </div>
                    <div
                      className="service-info wow fadeInUp"
                      data-wow-delay="0.5s"
                    >
                      <div className="icon">
                        <img src="https://img.icons8.com/bubbles/80/000000/discount.png" />
                      </div>
                      <div className="icon-info">
                        <h5>Discounted Price On Tyres</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div
                      className="service-info wow fadeInUp"
                      data-wow-delay="0.3s"
                    >
                      <div className="icon">
                        <img src="https://img.icons8.com/bubbles/80/000000/car.png" />
                      </div>
                      <div className="icon-info">
                        <h5>Batteries &amp; Accessories</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                      </div>
                    </div>
                    <div
                      className="service-info wow fadeInUp"
                      data-wow-delay="0.6s"
                    >
                      <div className="icon">
                        <img src="https://img.icons8.com/bubbles/80/000000/share.png" />
                      </div>
                      <div className="icon-info">
                        <h5>Referral Program</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="mt-5">
                    <div
                      className="section-title wow fadeInUp"
                      data-wow-delay="1s"
                    >
                      <h2>How it works</h2>
                    </div>
                    <div
                      id="myCarousel"
                      className="carousel slide"
                      data-ride="carousel wow fadeInUp"
                      data-wow-delay="0.1s"
                    >
                      {/* <!-- Indicators --> */}
                      <ol className="carousel-indicators">
                        <li
                          data-target="#myCarousel"
                          data-slide-to="0"
                          className="active"
                        ></li>
                        <li data-target="#myCarousel" data-slide-to="1"></li>
                        <li data-target="#myCarousel" data-slide-to="2"></li>
                      </ol>

                      {/* <!-- Wrapper for slides --> */}
                      <div className="carousel-inner">
                        <div className="item active">
                          <img
                            src="img/transporter/ibt.png"
                            className="img-responsive"
                            alt="Los Angeles"
                            style={{ width: "100%" }}
                          />
                        </div>

                        <div className="item">
                          <img
                            src="img/transporter/it.png"
                            className="img-responsive"
                            alt="Chicago"
                            style={{ width: "100%" }}
                          />
                        </div>

                        <div className="item">
                          <img
                            src="img/transporter/branch.png"
                            alt="New york"
                            className="img-responsive"
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            src="img/transporter/collaborate.png"
                            alt="New york"
                            className="img-responsive"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>

                      {/* <!-- Left and right controls --> */}
                      <a
                        className="left carousel-control"
                        href="#myCarousel"
                        data-slide="prev"
                      >
                        <span className="glyphicon glyphicon-chevron-left"></span>
                        <span className="sr-only">Previous</span>
                      </a>
                      <a
                        className="right carousel-control"
                        href="#myCarousel"
                        data-slide="next"
                      >
                        <span className="glyphicon glyphicon-chevron-right"></span>
                        <span className="sr-only">Next</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-pane fade" id="my_2">
                <div
                  className="section-title wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <h2>Our Features</h2>
                </div>
                <div className="row">
                  <div className="col-md-4 col-sm-4">
                    <div
                      className="service-info wow fadeInUp"
                      data-wow-delay="0.1s"
                    >
                      <div className="icon">
                        <img src="https://img.icons8.com/bubbles/80/000000/code.png" />
                      </div>
                      <div className="icon-info">
                        <h5>Al Driven ERP Software</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                      </div>
                    </div>
                    <div
                      className="service-info wow fadeInUp"
                      data-wow-delay="0.4s"
                    >
                      <div className="icon">
                        <img src="https://img.icons8.com/bubbles/80/000000/bank-card-front-side.png" />
                      </div>
                      <div className="icon-info">
                        <h5>Fuel Card</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div
                      className="service-info wow fadeInUp"
                      data-wow-delay="0.2s"
                    >
                      <div className="icon">
                        <img src="https://img.icons8.com/bubbles/80/000000/gps-device.png" />
                      </div>
                      <div className="icon-info">
                        <h5>GPS Tracking Device</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                      </div>
                    </div>
                    <div
                      className="service-info wow fadeInUp"
                      data-wow-delay="0.5s"
                    >
                      <div className="icon">
                        <img src="https://img.icons8.com/bubbles/80/000000/discount.png" />
                      </div>
                      <div className="icon-info">
                        <h5>Discounted Price On Tyres</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4">
                    <div
                      className="service-info wow fadeInUp"
                      data-wow-delay="0.3s"
                    >
                      <div className="icon">
                        <img src="https://img.icons8.com/bubbles/80/000000/car.png" />
                      </div>
                      <div className="icon-info">
                        <h5>Batteries &amp; Accessories</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                      </div>
                    </div>
                    <div
                      className="service-info wow fadeInUp"
                      data-wow-delay="0.6s"
                    >
                      <div className="icon">
                        <img src="https://img.icons8.com/bubbles/80/000000/share.png" />
                      </div>
                      <div className="icon-info">
                        <h5>Referral Program</h5>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="mt-5">
                    <div
                      className="section-title wow fadeInUp"
                      data-wow-delay="1s"
                    >
                      <h2>How it works</h2>
                    </div>
                    <div
                      id="myCarousel"
                      className="carousel slide"
                      data-ride="carousel wow fadeInUp"
                      data-wow-delay="0.1s"
                    >
                      {/* <!-- Indicators --> */}
                      <ol className="carousel-indicators">
                        <li
                          data-target="#myCarousel"
                          data-slide-to="0"
                          className="active"
                        ></li>
                        <li data-target="#myCarousel" data-slide-to="1"></li>
                        <li data-target="#myCarousel" data-slide-to="2"></li>
                      </ol>

                      {/* <!-- Wrapper for slides --> */}
                      <div className="carousel-inner">
                        <div className="item active">
                          <img
                            src="img/transporter/ibt.png"
                            className="img-responsive"
                            alt="Los Angeles"
                            style={{ width: "100%" }}
                          />
                        </div>

                        <div className="item">
                          <img
                            src="img/transporter/it.png"
                            className="img-responsive"
                            alt="Chicago"
                            style={{ width: "100%" }}
                          />
                        </div>

                        <div className="item">
                          <img
                            src="img/transporter/branch.png"
                            alt="New york"
                            className="img-responsive"
                            style={{ width: "100%" }}
                          />
                        </div>
                        <div className="item">
                          <img
                            src="img/transporter/collaborate.png"
                            alt="New york"
                            className="img-responsive"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>

                      {/* <!-- Left and right controls --> */}
                      <a
                        className="left carousel-control"
                        href="#myCarousel"
                        data-slide="prev"
                      >
                        <span className="glyphicon glyphicon-chevron-left"></span>
                        <span className="sr-only">Previous</span>
                      </a>
                      <a
                        className="right carousel-control"
                        href="#myCarousel"
                        data-slide="next"
                      >
                        <span className="glyphicon glyphicon-chevron-right"></span>
                        <span className="sr-only">Next</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="carousel">
        <div className="section-title wow fadeInUp" data-wow-delay="1s">
          <h2>Testimonials</h2>
        </div>
        <div className="container">
          <div className="row">
            <div
              className="col-md-8 col-md-offset-2 wow fadeInUp"
              data-wow-delay="1.5s"
            >
              <div className="quote">
                <i className="fa fa-quote-left fa-4x"></i>
              </div>
              <div
                className="carousel slide"
                id="fade-quote-carousel"
                data-ride="carousel"
                data-interval="3000"
              >
                {/* <!-- Carousel indicators --> */}
                <ol className="carousel-indicators">
                  <li data-target="#fade-quote-carousel" data-slide-to="0"></li>
                  <li data-target="#fade-quote-carousel" data-slide-to="1"></li>
                  <li
                    data-target="#fade-quote-carousel"
                    data-slide-to="2"
                    className="active"
                  ></li>
                  <li data-target="#fade-quote-carousel" data-slide-to="3"></li>
                  <li data-target="#fade-quote-carousel" data-slide-to="4"></li>
                  <li data-target="#fade-quote-carousel" data-slide-to="5"></li>
                </ol>
                {/* <!-- Carousel items --> */}
                <div className="carousel-inner">
                  <div className="item">
                    <div className="profile-circle">
                      <img
                        src="img/testimonials/1.jpg"
                        className="img-circle"
                      />
                    </div>
                    <blockquote>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Quidem, veritatis nulla eum laudantium totam
                        tempore optio doloremque laboriosam quas, quos eaque
                        molestias odio aut eius animi. Impedit temporibus nisi
                        accusamus.
                      </p>
                      <h6></h6>
                    </blockquote>
                  </div>
                  <div className="item">
                    <div className="profile-circle">
                      <img
                        src="img/testimonials/2.jpg"
                        className="img-circle"
                      />
                    </div>
                    <blockquote>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Quidem, veritatis nulla eum laudantium totam
                        tempore optio doloremque laboriosam quas, quos eaque
                        molestias odio aut eius animi. Impedit temporibus nisi
                        accusamus.
                      </p>
                      <h6></h6>
                    </blockquote>
                  </div>
                  <div className="active item">
                    <div className="profile-circle">
                      <img
                        src="img/testimonials/3.jpg"
                        className="img-circle"
                      />
                    </div>
                    <blockquote>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Quidem, veritatis nulla eum laudantium totam
                        tempore optio doloremque laboriosam quas, quos eaque
                        molestias odio aut eius animi. Impedit temporibus nisi
                        accusamus.
                      </p>
                    </blockquote>
                  </div>
                  <div className="item">
                    <div className="profile-circle">
                      <img
                        src="img/testimonials/4.jpg"
                        className="img-circle"
                      />
                    </div>
                    <blockquote>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Quidem, veritatis nulla eum laudantium totam
                        tempore optio doloremque laboriosam quas, quos eaque
                        molestias odio aut eius animi. Impedit temporibus nisi
                        accusamus.
                      </p>
                    </blockquote>
                  </div>
                  <div className="item">
                    <div className="profile-circle">
                      <img
                        src="img/testimonials/5.jpg"
                        className="img-circle"
                      />
                    </div>
                    <blockquote>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Quidem, veritatis nulla eum laudantium totam
                        tempore optio doloremque laboriosam quas, quos eaque
                        molestias odio aut eius animi. Impedit temporibus nisi
                        accusamus.
                      </p>
                    </blockquote>
                  </div>
                  <div className="item">
                    <div className="profile-circle">
                      <img
                        src="img/testimonials/6.jpg"
                        className="img-circle"
                      />
                    </div>
                    <blockquote>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Quidem, veritatis nulla eum laudantium totam
                        tempore optio doloremque laboriosam quas, quos eaque
                        molestias odio aut eius animi. Impedit temporibus nisi
                        accusamus.
                      </p>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className=" br-text wow bounceInLeft" data-wow-delay="0.2s">
        <h3>Trusted By Thousands of Online Sellers</h3>
        <p>An all-in-one eCommerce Solution for your shipping needs</p>
        <a href="#" className="btn get-started btn-ps">
          Partner with us
        </a>
      </div>
    </Fragment>
  );
};

export default Individual;
