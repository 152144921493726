export const toFirstPage = () => {
  const step3 = document.getElementById("step3");
  const step1 = document.getElementById("step1");
  if (step3) {
    step3.style.display = "none";
  }
  if (step1) {
    step1.style.display = "block";
  }
};

export const biltyCss = () => {
  return `
#step3{
    page-break-after: always
  }
.hide_print{
    color: transparent;
    }
    html,body{
    position: relative; 
    width: 100%;
    }
    #root {
    margin: 0.2cm 0 0 0;
    border: 1px solid black;
    width: 20.5cm;
    height: 11.5cm;
    }
    .part0 {
    height: 2.3cm;
    }
    .flex {
    display: flex;
    }
    /* .part1{
    width: 20.5cm
    } */
    .flex-part2 {
    display: flex;
    width: 20.5cm;
    flex: 1.9cm 8.4cm 1.9cm 8.4cm;
    }
    .flex-part2>div {
    /* flex:1.9cm 8.4cm; */
    }
    /* .flex>div {
    width: 5.4cm;
    height: 1.2cm;
    } */
    .part1>.flex>div>p {
    margin-left: 0.1cm;
    margin-top: 0.2cm;
    width: 1.2cm;
    }
    .flex{
    display: flex;
    }
    .flex_part1_sub{
        display:flex;
        width: 4.1cm;
    }
    .part1-label{
    width: 1cm;
    }
    .part1-value {
    width: 4.8cm;
    }
    .flex-column {
    display: flex;
    flex-direction: column;
    }
    .part2 {
    height: 2.4cm;
    }
    /* div.part2 > div.flex-column > div{
    height: 0.6cm;
    } */
    .part2_label {
    width: 2cm;
    }
    .part2_value {
    width: 8.7cm;
    }
    .goods_value{
        width: 3.5cm;
    }
    .invoice_label{
        width: 1.2cm;
    }
    .invoice_value{
        width: 3.5cm;
    }
    .part2_sub_value{
    width: 4.3cm;
    }
    .charge{
        text-align: left;
    }
    .part3_label {
    width: 2cm;
    margin: 0;
    height: 0.7cm;
    }
    .part3_value {
    width: 10.8cm;
    margin: 0;
    height: 0.6cm;
    }
    .part3_label_blank {
    width: 2cm;
    // margin-top: 0cm;
    // margin-left: 0cm;
    height: 1.2cm;
    margin-top: -0.6cm;
    }
    
    .part3_value_blank {
    width: 10.8cm;
    // margin: 0;
    height: 1.2cm;
    margin-top: -0.6cm;
    }
    
    .part4_value_blank {
    width: 12.8cm;
    margin-top: 0cm;
    margin-left: 0cm;
    height: 1.8cm;
    }
    
    .part1_sub_label {
    width:1.6cm
    }
    .part1_sub_value{
    width: 2.9cm;
    }
    
    .part2 p {
    margin: 0
    }
    
    #part2 {
    display: flex;
    // margin-left: 0.1cm;
    // margin-top: 0.1cm;
    height: 0.6cm;
    }
    
    .flex_part3 {
    display: flex;
    margin-left: 0.1cm;
    }
    
    .gap_part3 {
    margin-top: 0.1cm
    }
    
    #part2>p {
    height: 0.6cm;
    margin: 0
    }
    
    div.part3_label>p {
    margin: 0;
    }
    #asd{
    width:20.5cm;
    }
    .padding_packages {
        padding-top: 0.2cm
    }
    .part2 {
        margin-top: 3px;
    }
    .part1 {
        margin-top: 1px 
    }
`;
};
