export const SET_FEEDBACK_FILTERS = "SET_FEEDBACK_FILTERS";   
export const SET_FEEDBACK_BULK_FILTERS = "SET_FEEDBACK_BULK_FILTERS";   
export const RESET_FEEDBACK_FILTERS = "RESET_FEEDBACK_FILTERS";   

export interface setFeedbackFilters {
    type: typeof SET_FEEDBACK_FILTERS;
    payload: any; 
}

export interface setFeedbackBulkFilters {
    type: typeof SET_FEEDBACK_BULK_FILTERS;
    payload: any
}