import React, { useEffect } from "react";
import { Button, Input } from "../../../../components/UI";
import { StateType } from "../../partner-forms/useCustomState";

type TContactOTPForm = {
  isOTPModal?: boolean;
  setIsOTPModal?: React.Dispatch<React.SetStateAction<boolean>>;
  contact: StateType;
  setContact: React.Dispatch<React.SetStateAction<StateType>>;
  otp: StateType;
  setOTP: React.Dispatch<React.SetStateAction<StateType>>;
  name: StateType;
  setName: React.Dispatch<React.SetStateAction<StateType>>;
  isOTPClick?: boolean;
  setIsOTPClick?: React.Dispatch<React.SetStateAction<boolean>>
  isCustomer?: boolean;
  setIsCustomer?: React.Dispatch<React.SetStateAction<boolean>>
};

const ContactOTPForm = ({
  contact,
  setContact,
  otp,
  setOTP,
  isOTPClick,
  setIsOTPClick,
  name,
  setName,
  isCustomer,
  setIsCustomer
}: TContactOTPForm) => {

  useEffect(() => {
    if(contact && contact?.toString()?.length){
      if(setIsCustomer && setIsOTPClick){
        setIsCustomer(false)
        setIsOTPClick(false)
        // setContact("")
        // setOTP("")
        // setName("")
      }
    }
  },[contact?.toString()?.length])

  return (
    <div className="flex flex-col gap-1 m-1">
      <div className="flex gap-1">
        <div className="flex-grow-1">
          <Input
            variant="primary"
            title="Contact Number"
            type="number"
            state={{ value: contact, setValue: setContact }}
            name="contact"
          />
        </div>
        <Button type="button" variant="primary" action="primary" onClick={() =>  {
          if(setIsOTPClick){
            setIsOTPClick(true)
          }
        }}>Send OTP</Button>
      </div>
      {
        isCustomer ?  <div>
        <Input
          variant="primary"
          title="Name"
          type="text"
          state={{ value: name, setValue: setName }}
          name="Name"
        />
      </div> : null
      }
      {
        isOTPClick ?  <div>
        <Input
          variant="primary"
          title="Otp"
          type="tel"
          max={999999}
          state={{ value: otp, setValue: setOTP }}
          name="Otp"
        />
      </div> : null
      }
    </div>
  );
};

export default ContactOTPForm;
