import { fromJS, List } from "immutable";

import {
  GET_INCOME_TYPES,
  CHANGE_ADD_INCOME,
  GET_INCOME_TYPES_FAILED,
  GET_SUB_TYPES,
  POST_INCOME_RECORD_SUCCESS,
  CLEAR_ADD_INCOME,
  SET_INCOME_RECORD,
  RESET_INCOME_RECORD,
  DELETE_INCOME_RECORD,
  SET_ASSET_TYPES,
  // SET_ASSET_TYPES_FOR_INCOME,
  EDIT_INCOME_RECORD_FAILED,
  POST_INCOME_RECORD_FAILED,
  EDIT_INCOME_RECORD_SUCCESS,
  REACT_SELECT_ERROR,
  SET_PAST_INCOMES,
  GET_ASSET_BAL,
  UPDATE_PAST_INCOMES,
  ADD_INCOME_TYPE,
  REMOVE_INCOME_TYPE,
} from "../constants/income";
import { showMessage, failed, success } from "../utils/message";
import {
  incur_Income_Boolean,
  incur_Income_Validation,
  validateIncome,
} from "./schemas/addIncome";
import { isArrayCheck } from "../containers/generics/CheckArray";
import { toast } from "react-toastify";

const initialState = fromJS<any>({
  fetchedInputTypes: [],
  fetchedSubInputTypes: [],
  assetTypes: [],
  selectRefTypes: [],
  selectSubInputTypes: [],
  postIncomeRecord: {
    gst: "",
    commission: "",
    type: "add",
    ref: "",
    assetType: "",
    amt: "",
    sub: "",
    sub_val: "",
    route: "",
    start_date: new Date(),
    end_date: new Date(),
    user: "",
    is_route: false,
    branch: "",
    category: "",
    non_cash: false,
    remarks: "",
    monthly_duration: false,
    paymentType: "",
    transactionImage: "",
    transactionId: "",
    creditor: "",
    Agent: "",
    Branch: "",
    // incomeRecordValid: false
  },
  assetBalances: [],
  postIncomeRecordErrors: { ...incur_Income_Boolean(true) },
  postIncomeRecordTouched: { ...incur_Income_Boolean(false) },
});

export default (state = initialState, action: { [k: string]: any }) => {
  switch (action.type) {
    case ADD_INCOME_TYPE: {
      return state.set(
        "fetchedInputTypes",
        state.get("fetchedInputTypes").push(action.payload)
      );
    }

    case REMOVE_INCOME_TYPE: {
      let id = action.payload;
      return state.set(
        "fetchedInputTypes",
        state.get("fetchedInputTypes").filter((i: { id: any }) => i.id !== id)
      );
    }

    case GET_INCOME_TYPES: {
      const mapfetchedInputTypes = isArrayCheck(action.payload)
        ? action.payload.map((t: { [k: string]: any }) => ({
            label: t.name,
            value: t.id,
          }))
        : [];

      return state
        .setIn(["fetchedInputTypes"], List(action.payload))
        .setIn(["selectRefTypes"], mapfetchedInputTypes);
    }

    case GET_INCOME_TYPES_FAILED: {
      showMessage("Could not fetch Income types !", failed);
      return state;
    }

    case CHANGE_ADD_INCOME: {
      let { what, val } = action.payload;
      let finalValue = val;
      if (what == "non_cash") {
        finalValue = !(state.getIn(["postIncomeRecord", "non_cash"]) === true);
      }
      return state
        .setIn(["postIncomeRecord", ...what], finalValue)
        .setIn(
          ["postIncomeRecordErrors", ...what],
          validateIncome(incur_Income_Validation.getIn(what), finalValue)
        )
        .setIn(["postIncomeRecordTouched", ...what], true);
    }

    case REACT_SELECT_ERROR: {
      let { what } = action.payload;
      return state.setIn(
        ["postIncomeRecordErrors", ...what],
        "This is required field"
      );
    }

    case CLEAR_ADD_INCOME: {
      return state
        .set("fetchedSubInputTypes", [])
        .set("selectSubInputTypes", [])
        .setIn(["postIncomeRecord", "sub_val"], "")
        .setIn(["postIncomeRecord", "assetType"], "");
    }

    case GET_SUB_TYPES: {
      const selectBranches = isArrayCheck(action.payload)
        ? action.payload.map((e: { [k: string]: any }) => ({
            label: e.branchName ? e.branchName : "N/A",
            value: e._id ? e._id : "",
          }))
        : [];
      return state
        .set("fetchedSubInputTypes", action.payload)
        .set("selectSubInputTypes", selectBranches);
    }

    case GET_ASSET_BAL: {
      const selectAssetBal = isArrayCheck(action.payload)
        ? action.payload.map((t: { [k: string]: any }) => ({
            label: t.uniqueId ? t.uniqueId : t.qty + " " + t.type.assetName,
            value: t && t._id ? t._id : "",
          }))
        : [];
      return state
        .set("fetchedSubInputTypes", action.payload)
        .set("selectSubInputTypes", selectAssetBal);
    }

    case SET_ASSET_TYPES: {
      let assetTypes = action.payload;
      const mapAssetTypes = isArrayCheck(assetTypes)
        ? assetTypes.map((a: { [k: string]: any }) => ({
            label: a.assetName,
            value: a._id,
          }))
        : [];
      //here sub input is asset balance
      return state
        .set("assetTypes", assetTypes)
        .set("selectAssetTypes", mapAssetTypes);
    }
    case POST_INCOME_RECORD_SUCCESS: {
      showMessage("Income Record successfully Added", success);
      let data = {
        gst: "",
        commission: "",
        type: "add",
        ref: "",
        assetType: "",
        amt: "",
        sub: "",
        sub_val: "",
        is_route: false,
        route: "",
        start_date: new Date(),
        end_date: new Date(),
        user: "",
        branch: "",
        category: "",
        non_cash: false,
        remarks: "",
        monthly_duration: false,
        incomeRecordValid: false,
        paymentType: "",
        transactionImage: "",
        transactionId: "",
        creditor: "",
        Agent: "",
        Branch: "",
      };
      return state.set("postIncomeRecord", data);
    }

    case RESET_INCOME_RECORD: {
      let data = {
        gst: "",
        commission: "",
        type: "add",
        ref: "",
        assetType: "",
        no_of_seats: "",
        amt: "",
        sub: "",
        sub_val: "",
        route: "",
        is_route: false,

        start_date: new Date(),
        end_date: new Date(),
        user: "",
        branch: "",
        category: "",
        non_cash: false,
        remarks: "",
        monthly_duration: false,
        incomeRecordValid: false,
        paymentType: "",
        transactionImage: "",
        transactionId: "",
        creditor: "",
        Agent: "",
        Branch: "",
      };
      return state
        .set("postIncomeRecord", data)
        .set("postIncomeRecordErrors", incur_Income_Boolean(true))
        .set("fetchedSubInputTypes", [])
        .set("assetTypes", [])
        .set("selectSubInputTypes", []);
    }
    case DELETE_INCOME_RECORD: {
      showMessage("Succssfully Deleted !", success);
      let data = {
        gst: "",
        commission: "",
        type: "add",
        ref: "",
        assetType: "",
        amt: "",
        sub: "",
        is_route: false,

        sub_val: "",
        route: "",
        start_date: new Date(),
        end_date: new Date(),
        user: "",
        branch: "",
        category: "",
        non_cash: false,
        remarks: "",
        monthly_duration: false,
        incomeRecordValid: false,
        paymentType: "",
        transactionImage: "",
        transactionId: "",
        creditor: "",
        Agent: "",
        Branch: "",
      };
      return state.set("postIncomeRecord", data);
    }
    case POST_INCOME_RECORD_FAILED: {
      showMessage(action.payload, failed);
      return state;
    }
    case EDIT_INCOME_RECORD_FAILED: {
      showMessage(action.payload, failed);
      return state;
    }
    case EDIT_INCOME_RECORD_SUCCESS: {
      toast.success("Income Record Edited Successfully ");
      let data = {
        gst: "",
        commission: "",
        type: "add",
        ref: "",
        assetType: "",
        amt: "",
        sub: "",
        sub_val: "",
        is_route: false,
        route: "",
        start_date: new Date(),
        end_date: new Date(),
        user: "",
        branch: "",
        category: "",
        non_cash: false,
        remarks: "",
        monthly_duration: false,
        incomeRecordValid: false,
        paymentType: "",
        transactionImage: "",
        transactionId: "",
        creditor: "",
        Agent: "",
        Branch: "",
      };
      return state.set("postIncomeRecord", data);
    }
    case POST_INCOME_RECORD_FAILED: {
      showMessage("Couldn't Add Income Record", failed);
      return state;
    }
    case SET_INCOME_RECORD: {
      let data = action.payload;
      data["type"] = "edit";
      return state.set("postIncomeRecord", data);
    }
    case SET_PAST_INCOMES: {
      return state.set("pastIncomes", action.payload);
    }
    case UPDATE_PAST_INCOMES: {
      let { payload, options } = action;
      if (options && options.remove === true)
        return state
          .set(
            "pastIncomes",
            state.get("pastIncomes").filter((i: any) => i.id != options.id)
          )
          .set("postIncomeRecord", initialState.get("postIncomeRecord"));
      if (options && options.update === true) {
        return state
          .set(
            "pastIncomes",
            state.get("pastIncomes").map((i: any) => {
              if (i.id == options.id) {
                return { ...i, amt: payload.amt };
              }
              return i;
            })
          )
          .set("postIncomeRecord", initialState.get("postIncomeRecord"));
      }
      return state.set(
        "pastIncomes",
        [action.payload].concat(state.get("pastIncomes"))
      );
    }
    default: {
      return state;
    }
  }
};
