import { ALL_PLATFORM_USERS_LIST } from "../constants/allPlatformUsersList";

const initSDtate:any = [];

const allPlatformUsersList = (state = initSDtate , action: any) => {
    const {type , payload} = action;
    // console.log(`Settings Api Called From Reducer New Payload` , payload)
    switch (type) {
        case ALL_PLATFORM_USERS_LIST : {
            return [
                ...payload
            ]
        }
        default:
        return state;
    }
}

export default allPlatformUsersList;