import React, { Fragment } from "react";
import Shimmer from "react-shimmer-effect";
import withStyles from '@mui/styles/withStyles';
// import ToolTip from "../../Components/ToolTip/ToolTip";
import { getAvgColor, shimmerStyle } from "./colorUtil";
import { withRouter } from "../../hoc/withRouter";
// import {withRouter} from "react-router-dom";
const innerColorScheme = [
  {
    title: "<24 Hrs",
    color: "#008000",
  },
  {
    title: "1-2 Days",
    color: "#61bd10",
  },
  {
    title: ">2 Days",
    color: "#81f911",
  },
];
const innerColorScheme4 = [
  {
    title: "0-2 D",
    color: "#61bd10",
  },
  {
    title: ">2 D",
    color: "#81f911",
  },
];
const innerColorScheme1 = [
  {
    title: "< 80 %",
    color: "#008000",
  },
  {
    title: "80-100 ",
    color: "#61bd10",
  },
  {
    title: ">100 %",
    color: "#81f911",
  },
];
const innerColorScheme2 = [
  {
    title: "Current",
    color: "#008000",
  },
  {
    title: "1-2 Mo",
    color: "#61bd10",
  },
  {
    title: ">2 Mo",
    color: "#81f911",
  },
];

const innerColorScheme5 = [
  {
    title: "1",
    color: "#008000",
  },
  {
    title: "2",
    color: "#61bd10",
  },
  {
    title: "3",
    color: "#81f911",
  },
];

const innerColorScheme7 = [
  {
    title: "<3 days",
    color: "#008000",
  },
  {
    title: "3-5 days",
    color: "#61bd10",
  },
  {
    title: ">5 days",
    color: "#81f911",
  },
];

const innerColorScheme6 = [
  {
    title: "O",
    color: "#008000",
  },
  {
    title: "I",
    color: "#61bd10",
  },
  {
    title: "CU",
    color: "#81f911",
  },
  {
    title: "CC",
    color: "#81f911",
  },
];

const innerColorScheme8 = [
  {
    title: "1",
    color: "#008000",
  },
  {
    title: ">1",
    color: "#61bd10",
  },
];

const calculatePercentage = (counterData) => {
  if (typeof counterData !== "object") {
    return 0;
  }
  const { allCount, inners } = counterData;
  if (typeof allCount === "number" && inners) {
    if (allCount === 0 || inners.length === 0) {
      return 100;
    }
    // const w1 =(data[inners[0].field] / data[field]) * 100
    const w2 = inners[1] > 0 ? (inners[1] / allCount) * 100 : 0;
    const w3 = inners[2] > 0 ? (inners[2] / allCount) * 100 : 0;
    return 100 - w2 - w3;
  } else return "";
};

const calculateWeightedPercentage = (counterData) => {
  if (typeof counterData !== "object") {
    return 0;
  }
  const { allCount, inners } = counterData;
  if (allCount === 0 || inners.length === 0) {
    return 0;
  }

  const innersSum = inners[0] * 1 + inners[1] * 2 + inners[2] * 3;
  const per = innersSum / allCount;
  const weightedPer = (per * 100) / 3;

  return weightedPer;
};

// const ToolTip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: theme.palette.common.white,
//     color: "rgba(0, 0, 0, 0.87)",
//     boxShadow: theme.shadows[1],
//     fontSize: 14,
//   },
// }))(ToolTip);

const ProgressBar = (props) => {
  const handleRedirect = (uri, extra) => {
    props.navigate(uri, {state: { data: extra } });
  };
  const { data, title, fields, direction } = props;
  let score = null;
  if (data) {
    const scoreSum = fields.reduce((oldVal, counterData) => {
      const percentage = data
        ? calculatePercentage(data[counterData.field])
        : 0;
      return percentage + oldVal;
    }, 0);
    score = Math.ceil(scoreSum / fields.length);
  }
  // props.getScore ? props.getScore(score) : {};
  return (
    <Fragment>
      <center>
        <h4>
          <b>
            {title}{" "}
            {score !== null ? (
              <Fragment>
                <Shimmer key={Math.random()}>
                  <span
                    style={{
                      ...shimmerStyle(getAvgColor(score), direction),
                      position: "relative",
                      padding: "10px",
                    }}
                  >
                    Score: {score}
                    {props.getScore ? props.getScore(score) : ""}
                  </span>
                </Shimmer>
              </Fragment>
            ) : null}
          </b>
        </h4>
      </center>
      {data ? (
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          {fields.map(({ title, field, link, extra, handleClick }) => {
            console.log("field", field);
            const percentage =
              field === "rating"
                ? calculateWeightedPercentage(data[field])
                : field === "complaintStatus"
                ? ""
                : calculatePercentage(data[field]);

            return (
              <Shimmer key={`${field}${Math.random()}`}>
                <div
                  className="pStyle"
                  style={{
                    ...shimmerStyle(getAvgColor(percentage), direction),
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <div className="pDiv">
                    <div className="pCenter">
                      {title}:
                      {typeof percentage === "number"
                        ? Math.ceil(percentage)
                        : ""}
                      %<br />
                    </div>
                  </div>
                  {data[field].inners ? (
                    <div className="innerDiv">
                      {data[field].inners.map((innerCount, index) => {
                        const width =
                          data[field] === 0
                            ? 100 / data[field].inners.length
                            : (innerCount / data[field].allCount) * 100;
                        console.log(field, width);
                        return (
                          <div
                            key={`${field}${index}`}
                            style={{
                              width: `${width}%`,
                              ...shimmerStyle(
                                innerColorScheme[index].color,
                                direction
                              ),
                            }}
                            className="animDiv"
                            onClick={() => {
                              (handleClick && handleClick(index)) ||
                                handleRedirect(link, extra);
                            }}
                          >
                            <div className="itemTitle items">
                              {   field == "rejectedReportCount" || field == "rejectedReportAmount"
                                ? innerColorScheme8[index].title :
                                  field === "rating"
                                ? innerColorScheme5[index].title
                                : field === "pendingComplaint" ||
                                  field === "pendingDelivery" ||
                                  field === "pendingForDestination"
                                ? innerColorScheme7[index].title
                                : field == "companyPayable" ||
                                  field == "user" ||
                                  field == "companyReceivable" ||
                                  field == "individualReceivable" ||
                                  field == "individualPayable" ||
                                  field == "individualFleetReceivable" ||
                                  field == "individualFleetPayable" 
                                ? innerColorScheme1[index].title
                                : field == "creditCustomer" ||
                                  field == "vendor" ||
                                  field == "creditors"
                                ? innerColorScheme2[index].title
                                : data[field].inners.length < 3
                                ? innerColorScheme4[index].title
                                : innerColorScheme[index].title}
                            </div>
                            <div className="items">
                              {Math.round(innerCount)}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="innerDiv">
                      <div
                        key={`${field}${0}`}
                        style={{
                          width: `${100}%`,
                          ...shimmerStyle(
                            innerColorScheme6[0].color,
                            direction
                          ),
                        }}
                        className="animDiv"
                        onClick={() => {
                          (handleClick && handleClick(0)) ||
                            handleRedirect(link, {
                              label: "Open",
                              value: "O",
                            });
                        }}
                      >
                        <div className="itemTitle items">
                          {innerColorScheme6[0].title}
                        </div>
                        <div className="items">{Math.round(data[field].O)}</div>
                      </div>
                      <div
                        key={`${field}${1}`}
                        style={{
                          width: `${100}%`,
                          ...shimmerStyle(
                            innerColorScheme6[1].color,
                            direction
                          ),
                        }}
                        className="animDiv"
                        onClick={() => {
                          (handleClick && handleClick(1)) ||
                            handleRedirect(link, {
                              label: "In Progress",
                              value: "I",
                            });
                        }}
                      >
                        <div className="itemTitle items">
                          {innerColorScheme6[1].title}
                        </div>
                        <div className="items">{Math.round(data[field].I)}</div>
                      </div>

                      <div
                        key={`${field}${2}`}
                        style={{
                          width: `${100}%`,
                          ...shimmerStyle(
                            innerColorScheme6[2].color,
                            direction
                          ),
                        }}
                        className="animDiv"
                        onClick={() => {
                          (handleClick && handleClick(2)) ||
                            handleRedirect(link, {
                              label: "Closed By User",
                              value: "CU",
                            });
                        }}
                      >
                        <div className="itemTitle items">
                          {innerColorScheme6[2].title}
                        </div>
                        <div className="items">
                          {Math.round(data[field].CU)}
                        </div>
                      </div>

                      <div
                        key={`${field}${3}`}
                        style={{
                          width: `${100}%`,
                          ...shimmerStyle(
                            innerColorScheme6[3].color,
                            direction
                          ),
                        }}
                        className="animDiv"
                        onClick={() => {
                          (handleClick && handleClick(3)) ||
                            handleRedirect(link, {
                              label: "Closed By Customer",
                              value: "CC",
                            });
                        }}
                      >
                        <div className="itemTitle items">
                          {innerColorScheme6[3].title}
                        </div>
                        <div className="items">
                          {Math.round(data[field].CC)}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Shimmer>
            );
          })}
        </div>
      ) : (
        <div
          style={{
            margin: "30px auto 15px auto",
            width: "100%",
            height: "80px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            backgroundColor: "lightgrey",
          }}
        >
          <center style={{ margin: "auto" }}>{"No Data"}</center>
        </div>
      )}
    </Fragment>
  );
};

export default 
withRouter(
  ProgressBar
  );
