import React, { Fragment, useState, useEffect } from "react";
import fetchImage from "../../services/MyImage";
import { showLoader, hideLoader } from "../../actions/UserActions";
import { connect } from "react-redux";
import getImageUrl from "../../utils/getImageUrl";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ToolTip from "../../Components/ToolTip/ToolTip";
import "./image.css";
import { ImageOffIcon } from "../../newSrc/constants/icons";

const OnlyImage = (props) => {
  const [image, setImage] = useState("");
  const [rotateDeg, setRotateDeg] = useState(0);
  const [zoomDisplay, setZoomDisplay] = useState(false);

  const setImageUrl = async () => {
    setImage("");
    props.showLoader();
    try {
      if (props.isSigned) {
        const image = await getImageUrl(props.objectID);
        setImage(image);
      } else {
        const response = await fetchImage(props.objectID);
        const image = await getImageUrl(response);
        setImage(image);
      }

      props.hideLoader();
    } catch (err) {
      props.hideLoader();
    }
  };
  useEffect(() => {
    if (props.objectID) {
      setImageUrl();
    }
  }, [props.objectID]);

  const rotateRight = () => {
    let x = rotateDeg;
    x += 90;
    setRotateDeg(x);
  };

  const rotateLeft = () => {
    let x = rotateDeg;
    x -= 90;
    setRotateDeg(x);
  };

  return (
    <Fragment>
      {/\.pdf$/i.test(props.objectID) ? (
        <object
          data={image}
          type="application/pdf"
          height={props.height ? props.height : "800px"}
          width={props.width ? props.width : "500px"}
        ></object>
      ) : (
        <div>
          <div style={{ padding: "10px" }}>
            {image ? <img
              className={props.className ? props.className : "basicStyles"}
              src={image}
              alt="No Image"
              // height={props.height ? props.height : "300px"}
              // width="auto"
              style={{ transform: `rotate(${rotateDeg}deg)`, ...(props.height ? {height: props.height} : {}), ...(props.width ? {width: props.width} : {}) }}
              // onDoubleClick={zoom}
            /> : <div className="flex-center p-1">
                <ImageOffIcon style={{width: "30px", height: "30px"}} className="mx-auto text-black-100" />
            </div> }
          </div>
        </div>
      )}
    </Fragment>
  );
};
const mapStateToProps = () => ({});

const mapDispatchToProps = {
  showLoader,
  hideLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnlyImage);
