import { fromJS } from "immutable";
import {
  RESET_INCUR_EXPENSE,
  DELETE_EXPENSE_RECORD,
  EDIT_EXPENSE_RECORD,
  SET_EXPENSE_INCUR,
  FUEL_IN_EXPENSE_ON_CHANGE,
  SET_FUEL_VALUES,
  DELETE_FUEL_EXPENSE,
  EDIT_EXPENSE_RECORD_FAILED,
  COULD_NOT_FETCH_CONTACT,
  DELETE_EXPENSE_RECORD_FAILED,
  NON_CASH_CHANGE,
  FIXED_EXPENSE_TYPE,
  SET_PAST_EXPENSES,
  AVAILABLE_BALANCE,
  SHOW_LOADER_IN_AMOUNT,
  HIDE_LOADER_IN_AMOUNT,
  DISABLE_OP_KMS,
  ENABLE_OP_KMS,
  PRINT_EXPENSE_VOUCHER,
  CLOSE_VOUCHER_MODAL,
} from "../constants/expense";
import { SET_COMPANY_ROUTES, GET_ROUTES } from "../constants/route";
import { isArrayCheck } from "../containers/generics/CheckArray";
import { failed, showMessage, success } from "../utils/message";

import {
  expense_Boolean,
  expenseValidationSchema,
  validateExpense,
  fuelExpenseValidation,
} from "./schemas/expense";

const initExpense = {
  type: "add",
  sub: "",
  sub_val: "",
  supplier: "",
  suppliers: null,
  route: "",
  ref: "",
  amt: "",
  end_date: new Date(),
  start_date: new Date(),
  is_route: false,
  monthly_duration: false,
  tripDate: "",
  month: "",
  year: "",
  attachRoute: false,
  duration: true,
  assetType: "",
  assetBalance: "",
  remarks: "",
  isFixedExpense: false,
  paymentType: "",
  transactionImage: "",
  transactionId: "",
  vendor: "",
  paymentMode: "",
};

const initState = {
  expenseHandlingData: "",
  expenseTypes: [],
  subExpenses: [],
  assetTypes: [],
  assetBalances: [],
  routes: null,
  selectExpenseTypes: [],
  fuel: {
    op: "",
    cl: "",
    ppl: "",
    places: [],
    new: "",
    rnum: "",
    driver: "",
    dName: "",
    remarks: "",
    from: "",
    fuelQty: "",
    to: "",
    selectedFuel: "",
    filterContact: "",
    filterVehicle: "",
    avgIdeal: "",
  },
  fuelErrors: fromJS({
    op: true,
    cl: true,
    ppl: true,
    rnum: true,
    driver: true,
    dName: true,
    remarks: true,
    fuelQty: true,
    avgIdeal: true,
  }),
  assign: {
    branches: null,
    departments: null,
    fleets: null,
  },
  disableOpKms: false,
  printExpenseVoucher: {},
  voucherPrint: false,
  expense: initExpense,
  expenseErrors: { ...expense_Boolean(true) },
  expenseTouched: { ...expense_Boolean(false) },
  remainingLimit: 0,
  loaderAcrossAmount: false,
};

const expenseReducer = (state: any = initState, action: any) => {
  switch (action.type) {
    case PRINT_EXPENSE_VOUCHER: {
      return {
        ...state,
        printExpenseVoucher: action.payload,
      };
    }
    case DISABLE_OP_KMS: {
      return {
        ...state,
        disableOpKms: true,
      };
    }
    case ENABLE_OP_KMS: {
      return {
        ...state,
        disableOpKms: false,
      };
    }

    case "SET_EXPENSE_TYPES": {
      let expenses = action.payload;
      let selectExpenseTypes = [];
      let expenseTypes: any = {};
      for (let i = 0; i < expenses.length; i++) {
        const e = expenses[i];
        selectExpenseTypes.push({ label: e.name, value: e.id });
        expenseTypes[e.id] = e;
      }
      // return { ...state, expenseTypes: action.payload, expenseTypesMap: expenseTypes, selectExpenseTypes }
      return { ...state, expenseTypes, selectExpenseTypes };
    }
    case "SELECT_EXPENSE_TYPE": {
      let expense: any = Object.assign({}, initExpense);
      let { val, expenseData } = action.payload;
      expense = {
        ...expense,
        ...expenseData,
        ref: val,
      };
      expense.subExpenses = null;
      expense.subExpense = "";
      let expenseErrors = { ...state.expenseErrors };
      let expenseTouched = { ...state.expenseTouched };
      expenseTouched.expenseType = true;
      expenseErrors.expenseType = validateExpense(
        expenseValidationSchema.getIn(["expenseType"]),
        action.payload
      );
      if (action.payload == "") expense.expenseType = "";
      return {
        ...state,
        expense: expense,
        expenseErrors,
        expenseTouched,
        remainingLimit: 0,
      };
    }
    case "SELECT_SUB_EXPENSE_TYPE": {
      let expense = Object.assign({}, state.expense);
      // let id = "id";
      if (state.expense.sub == "U" || state.expense.sub == "B") {
        // id = "_id";
      }
      expense = {
        ...expense,
        assetBalance: "",
        sub_val: action.payload,
      };
      let expenseErrors = { ...state.expenseErrors };
      expenseErrors.subExpense = validateExpense(
        expenseValidationSchema.getIn(["subExpense"]),
        action.payload
      );
      let expenseTouched = { ...state.expenseTouched };
      expenseTouched.subExpense = true;
      // expenseErrors.subExpense = validateExpense(expenseValidationSchema.subExpense, action.payload)
      if (action.payload == "") expense.subExpense = "";
      return {
        ...state,
        expense,
        expenseErrors,
        expenseTouched,
      };
    }
    case "SET_SUB_EXPENSES": {
      return { ...state, subExpenses: action.payload };
    }
    case SHOW_LOADER_IN_AMOUNT: {
      return {
        ...state,
        loaderAcrossAmount: true,
      };
    }
    case HIDE_LOADER_IN_AMOUNT: {
      return {
        ...state,
        loaderAcrossAmount: false,
      };
    }
    case DELETE_FUEL_EXPENSE: {
      showMessage("Fuel Expense Deleted !");
      return state;
    }
    case "EXPENSE_AMT": {
      let expense = Object.assign({}, state.expense);
      expense.amt = action.payload;
      let expenseErrors = state.expenseErrors;
      expenseErrors.amt = validateExpense(
        expenseValidationSchema.getIn(["amt"]),
        action.payload
      );
      let expenseTouched = state.expenseTouched;
      expenseTouched.amt = true;
      return {
        ...state,
        expense,
        expenseErrors,
        expenseTouched,
      };
    }
    case AVAILABLE_BALANCE: {
      let remainingLimit =
        action.payload && action.payload.fixed
          ? action.payload.fixed.amt - action.payload.past
          : 0;
      if(action.payload && action.payload.fixed && action.payload.past!==null && action.payload.fixed.expectedCost){
        remainingLimit = action.payload.fixed.expectedCost
          ? action.payload.fixed.expectedCost - action.payload.past
          : 0;
      }
      return {
        ...state,
        remainingLimit,
      };
    }
    case "TOGGLE_ATTACH_ROUTE": {
      let expense = Object.assign({}, state.expense);
      expense.attachRoute = !expense.attachRoute;
      return { ...state, expense: expense };
    }
    case "SET_EXP_ROUTES": {
      let expense = Object.assign({}, state.expense);
      expense.routes = action.payload;
      return { ...state, expense: expense };
    }
    case "SEL_EXP_ROUTE": {
      let expense = Object.assign({}, state.expense);
      let route = "";
      state.routes.map((rt: any) => {
        if (rt._id == action.payload.value) {
          route = action.payload;
        }
      });
      expense.route = route;
      let expenseErrors = state.expenseErrors;
      expenseErrors.route = validateExpense(
        expenseValidationSchema.getIn(["route"]),
        action.payload
      );
      let expenseTouched = state.expenseTouched;
      expenseTouched.route = true;
      return {
        ...state,
        expense: expense,
        expenseErrors: expenseErrors,
      };
    }
    case "EXP_START_DATE": {
      let expense = Object.assign({}, state.expense);
      expense.start_date = action.payload;
      return { ...state, expense: expense };
    }
    case "EXP_END_DATE": {
      let expense = Object.assign({}, state.expense);
      expense.end_date = action.payload;
      return { ...state, expense: expense };
    }
    case "SET_VOUCHER_IMAGE": {
      let expense = Object.assign({}, state.expense);
      expense.voucherImage = action.payload;
      return { ...state, expense: expense };
    }
    case "SET_BILL_IMAGE": {
      let expense = Object.assign({}, state.expense);
      expense.billImage = action.payload;
      return { ...state, expense: expense };
    }
    case RESET_INCUR_EXPENSE: {
      let expenseErrors = { ...expense_Boolean(true) };
      return {
        ...state,
        expense: initState.expense,
        fuel: initState.fuel,
        fuelErrors: initState.fuelErrors,
        expenseErrors: expenseErrors,
        remainingLimit: 0,
        disableOpKms: false,
        voucherPrint: false,
        expenseHandlingData: "",
      };
    }
    case CLOSE_VOUCHER_MODAL: {
      return {
        ...state,
        voucherPrint: false,
      };
    }
    case DELETE_EXPENSE_RECORD: {
      let expense = {
        type: "add",
        sub: null,
        sub_val: "",
        supplier: "",
        suppliers: null,
        route: "",
        ref: "",
        amt: "",
        remarks: "",
        end_date: new Date(),
        start_date: new Date(),
        is_route: false,
        tripDate: "",
        attachRoute: false,
        duration: true,
        assetType: "",
        assetBalances: "",
        assetBalance: "",
        isFixedExpense: false,
        paymentType: "",
        transactionImage: "",
        transactionId: "",
        vendor: "",
        paymentMode: "",
      };
      let expenseErrors = { ...expense_Boolean(true) };
      showMessage("Expense Record Deleted !", success);
      return {
        ...state,
        expense: expense,
        expenseErrors: expenseErrors,
      };
    }
    case FIXED_EXPENSE_TYPE: {
      let expense = Object.assign({}, state.expense);
      expense.isFixedExpense = action.payload;
      return {
        ...state,
        expense: expense,
      };
    }
    case COULD_NOT_FETCH_CONTACT: {
      showMessage("Couldn't Fetch Contact", success);
      return state;
    }
    case SET_EXPENSE_INCUR: {
      let expense = action.payload;
      expense = {
        ...expense,
        type: "edit",
        expenseType: expense.ref,
      };
      return {
        ...state,
        expense: expense,
      };
    }
    case SET_FUEL_VALUES: {
      return {
        ...state,
        fuel: action.payload,
      };
    }
    case EDIT_EXPENSE_RECORD: {
      showMessage("Expense Record Has Been Edited !", success);
      let expense = {
        type: "add",
        sub: null,
        sub_val: "",
        supplier: "",
        suppliers: null,
        route: "",
        ref: "",
        amt: "",
        remarks: "",
        end_date: new Date(),
        start_date: new Date(),
        is_route: false,
        tripDate: "",
        attachRoute: false,
        duration: true,
        assetType: "",
        assetBalances: "",
        assetBalance: "",
        // remarks: "",
        isFixedExpense: false,
        paymentType: "",
        transactionImage: "",
        transactionId: "",
        vendor: "",
        paymentMode: "",
      };
      return { ...state, expense: expense };
    }
    case DELETE_EXPENSE_RECORD_FAILED: {
      showMessage(
        action.payload || "Couldn't Delete Expense Record !",
        success
      );
      return state;
    }
    case EDIT_EXPENSE_RECORD_FAILED: {
      showMessage(action.payload, success);
      return state;
    }

    case FUEL_IN_EXPENSE_ON_CHANGE: {
      let { what, val } = action.payload;
      let fuel = Object.assign({}, state.fuel);
      fuel[what] = val;
      return {
        ...state,
        fuel: fuel,
        fuelErrors: state.fuelErrors.setIn(
          what,
          validateExpense(fuelExpenseValidation.getIn(what), val)
        ),
      };
    }
    case NON_CASH_CHANGE: {
      let { what, val } = action.payload;
      let expense = Object.assign({}, state.expense);
      expense[what] = val;
      return {
        ...state,
        expense: expense,
      };
    }
    case SET_PAST_EXPENSES: {
      return {
        ...state,
        pastExpenses: action.payload,
      };
    }
    case "INCUR_EXP_SUCCESS": {
      showMessage("Expense Recorded Successfully!", success);
      return {
        ...state,
        voucherPrint: true,
        voucherNumber: action.payload,
        expenseHandlingData: action.handlingLogs
          ? {
              ...action.handlingLogs,
              amt: action.handlingLogs && action.handlingLogs.amount,
            }
          : "",
        // expense: { ...initExpense, expenseTypes: state.expense.expenseTypes },
        // fuel: initState.fuel
      };
    }
    case "INCUR_EXP_FAILED": {
      showMessage(
        action.payload ? action.payload : "couldn't add expense",
        failed
      );
      return state;
    }
    case "EXP_ASSET_SET": {
      // let expense = Object.assign({}, state.expense)
      let assetTypes = action.payload;
      return { ...state, assetTypes: assetTypes };
    }
    case "EXP_SEL_ASSET_TYPE": {
      let expense = Object.assign({}, state.expense);
      expense.assetType = action.payload;

      let expenseErrors = state.expenseErrors;
      expenseErrors.assetType = validateExpense(
        expenseValidationSchema.getIn(["assetType"]),
        action.payload
      );
      let expenseTouched = state.expenseTouched;
      expenseTouched.assetType = true;
      return {
        ...state,
        expense: expense,
        expenseErrors: expenseErrors,
      };
    }
    case "SET_ASSET_BAL": {
      let selectAssetBal,
        assetBalance: any = {};
      if (isArrayCheck(action.payload)) {
        selectAssetBal = action.payload.map((a: { [k: string]: any }) => ({
          label: a?.uniqueId ? a?.uniqueId : a?.qty + " " + a?.type?.assetName,
          value: a._id,
          fleet_id: a?.fleetInfo?._id,
        }));
        action.payload.forEach((e: { [k: string]: any }) => {
          let key = e?.uniqueId ? e?.uniqueId : e?.qty + " " + e?.type?.assetName;
          assetBalance[key] = e;
        });
      }
      return { ...state, assetBalances: assetBalance, selectAssetBal };
    }
    case "SELECT_ASSET_BAL": {
      let expense = Object.assign({}, state.expense);
      expense = {
        ...expense,
        assetBalance: action.payload,
        sub_val: "",
      };
      let expenseErrors = state.expenseErrors;
      expenseErrors.assetBalance = validateExpense(
        expenseValidationSchema.getIn(["assetBalance"]),
        action.payload
      );
      let expenseTouched = state.expenseTouched;
      expenseTouched.assetBalance = true;
      return {
        ...state,
        expense,
        expenseErrors: expenseErrors,
      };
    }
    case SET_COMPANY_ROUTES: {
      return {
        ...state,
        routes: action.payload,
      };
    }
    case GET_ROUTES: {
      return {
        ...state,
        routes: action.payload,
      };
    }

    default:
      return state;
  }
};

export default expenseReducer;
