import { ServiceMaker } from "./index";

export const sendOTP = (data:{
  contact: any,
  retryCount?: number
}) => ServiceMaker("generate/otp", "POST", data);

export const verifyOTP = (data:{
  contact: any,
  OTP: string,
  latitude?: number,
  longitude?: number,
  deviceId?: string
}) => ServiceMaker("verify/otp", "POST", data);

export const setPassword = (data:{
  password: string,
  contact: any,
  OTP: string
}) => ServiceMaker("set/password", "POST", data);

export const setNewPassword = (data:{
  username: number,
  password: string,
  newPassword: string
}) =>
  ServiceMaker("change/password", "POST", data);

  export const setCustomerNewPassword = (data:{
    username: number,
    password: string,
    newPassword: string
  }) =>
    ServiceMaker("change/customerPassword", "POST", data);

    export const setCustomerPassword = (data:{
      password: string,
      contact: any,
      OTP: string
    }) => ServiceMaker("set/customerPassword", "POST", data);

    export const verifyCustomerOTP = (data:{
      contact: any,
      OTP: string,
      latitude?: number,
      longitude?: number,
      deviceId?: string
    }) => ServiceMaker("verify/customerOtp", "POST", data);

    export const sendCustomerOTP = (data:{
      contact: any,
      retryCount?: number
    }) => ServiceMaker("generate/customerOtp", "POST", data);


