import React, { forwardRef, ReactNode, type ComponentProps } from "react";
import "./textfield.css";

type TextFieldProps = {
  label?: ReactNode;
  error?: boolean;
  errorMessage?: string | ReactNode;
  helperText?: string | ReactNode;
  containerClassName?: string;
} & ComponentProps<"input">;

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      error,
      errorMessage,
      helperText,
      id,
      className = "",
      containerClassName = "",
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={`${containerClassName} taptap-textfield-container | ${label ? "flex-col gap-4" : ""}`}
      >
        <label
          htmlFor={`taptap-textfield-${id}`}
          className={`taptap-textfield-label | m-0 fw-400 text-black-700`}
        >
          {label}
        </label>
        <input
          ref={ref}
          className={`taptap-textfield-input | fs-small py-8 px-8 br-4 border-white outline-none ${className} ${label ? "" : "w-100"}`}
          id={`taptap-textfield-${id}`}
          {...props}
        />
        {error && errorMessage && (
          <p className="text-danger-400 fs-small">{errorMessage}</p>
        )}
        {helperText && (
          <p className="text-primary-900 fs-small">{helperText}</p>
        )}
      </div>
    );
  }
);

type TextAreaProps = {
  label?: string;
  containerClassName?: string;
} & ComponentProps<"textarea">;

export function TextArea({ label, containerClassName = "", className= "", rows, cols, ...props }: TextAreaProps) {
  return (
    <div className={`${label ? "flex-col gap-4" : ""} ${containerClassName}`}>
      {label && (
        <label
          htmlFor="taptap-textarea"
          className="taptap-textarea-label | m-0 fw-500"
        >
          {label}
        </label>
      )}
      <textarea
        className={`taptap-textarea-input | p-8 br-4 border-white outline-none ${className}`}
        id="taptap-textarea"
        rows={rows ?? 5}
        cols={cols ?? 30}
        {...props}
      />
    </div>
  );
}


export default TextField;