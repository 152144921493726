import { useState } from "react";
import { StateType } from "../../pages/Landing/partner-forms/useCustomState";

export function useContactUs() {
    const [name, setName] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Name is required!"
        }
    });
    const [email, setEmail] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Email is required!"
        }
    });
    const [subject, setSubject] = useState<StateType>(() => {
        return {
            value: "",
            error: false,
            errorMessage: "Name is required!"
        }
    });
    const [message, setMessage] = useState<string>("");

    return { name, setName, email, setEmail, subject, setSubject, message, setMessage }
}