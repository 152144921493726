import React  from 'react'
import './loader.css';
import Lottie from 'react-lottie';
import logoData from "./logo.json";

const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: logoData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <section className="my-app-loader" style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <img src="/newAssets/logo/logo.png" alt="Logo" style={{width: "100%", maxWidth: "400px"}}  />
      <section className="my-app-loader-section" style={{ height: "250px", maxWidth: "400px" }}>
        <Lottie
          options={defaultOptions}
          width={"100%"}
          height={"100%"}
        />
      </section>
    </section>
  )
}

export default Loader