import { HIDE_LOADER, SHOW_LOADER } from "../constants/booking";
import { SET_QUICK_ORDER } from "../constants/customerBooking";
import { trackCustomerBooking } from "../services/customersection";



export const getCustomerOrderQuickData = ({data}: any) => {
    return async (dispatch: any) => {
        try {
           await dispatch({ type: SHOW_LOADER });
              const response = await trackCustomerBooking({customerDocketId:data})
                // console.log('response', response)
                dispatch({
                    type:SET_QUICK_ORDER,
                    payload:{
                        what:"isOpen",
                        val: true
                    }
                })
                dispatch({
                    type:SET_QUICK_ORDER,
                    payload:{
                        what:"data",
                        val: response
                    }
                })
           dispatch({ type: HIDE_LOADER });
       } catch (error) {
        
           dispatch({ type: HIDE_LOADER });
       }
  
       
      }
    };

export const closequickOrdrDialog = () => {
    
    return (dispatch:any)=>{
        console.log('runnn')
        dispatch({
            type:SET_QUICK_ORDER,
            payload:{
                what:"isOpen",
                val: false
            }
        })
        dispatch({
            type:SET_QUICK_ORDER,
            payload:{
                what:"data",
                val: {}
            }
        })
    }
}    