import * as Yup from "yup";
import { fromJS } from "immutable";

export const add_Income_Boolean = (val: boolean) => ({
  name: val,
  fleet: val,
  branch: val,
  general: val,
  variance: val,
  sub: val,
  duration: val,
  company: val,
});

export const add_Income_Validation = fromJS({
  name: Yup.string().required("This is a required field"),
  sub: Yup.string().required("This is a required field"),
});

export const incur_Income_Boolean = (val: boolean) => ({
  ref: val,
  amt: val,
  sub: val,
  sub_val: val,
  assetType: val,
  is_route: val,
  route: val,
  user: val,
  branch: val,
});

export const incur_Income_Validation = fromJS({
  ref: Yup.object().shape({
    value: Yup.string().required("This is a required field"),
  }),
  amt: Yup.string().required("This is a required field"),
  sub: Yup.string().required("This is a required field"),
  remarks: Yup.string(),
  sub_val: Yup.object().shape({
    value: Yup.string().required("This is a required field"),
  }),
  assetType: Yup.object().shape({
    value: Yup.string().required("This is a required field"),
  }),
  no_of_seats: Yup.number().when("assetType", {
    is: (AT:any) => AT && AT.label && String(AT.label).toLowerCase() == "bus",
    then: (schema) => schema.required().min(1),
    otherwise: (schema) => schema.notRequired(),
  }),
  route: Yup.object().shape({
    value: Yup.string().required("This is a required field"),
  }),
  non_cash: Yup.boolean()
    .default(false)
    .required(),
  // assetType: Yup.object()
  //     .when('sub', {
  //         is: (val) => val == 'A',
  //         then: Yup.object().shape({
  //             value: Yup.string().required('This is a required field')
  //         }),
  //         otherwise: Yup.object()
  //     }),
  // is_route: Yup.boolean(),
  // route: Yup.object()
  //     .when('is_route', {
  //         is: true,
  //         then: Yup.object().shape({
  //             value: Yup.string().required('This is a required field')
  //         }),
  //     })
});
export var final_Incur_Income_Validation = Yup.object({
  ref: Yup.string().required("Please Select Ref Type !"),
  amt: Yup.string().required("Please Enter Amount"),
  sub: Yup.string().required("Please Select Ref Type !"),
  sub_val: Yup.string().required("Must enter valid sub value"),
  remarks: Yup.string(),
  no_of_seats: Yup.mixed().notRequired(),
  is_route: Yup.boolean(),
  route: Yup.mixed().when("is_route", {
    is: (val:any) => val == true,
    then: (schema) => schema.required("Route in required !"),
  }),
  paymentType: Yup.mixed().when("non_cash", {
    is: (val:any) => val === true,
    then: (schema) => schema.required("Payment Type in required !"),
  }),
  transactionId: Yup.mixed().when("non_cash", {
    is: (val:any) => val === true,
    then: (schema) => schema.required("transaction Id in required !"),
  }),
  transactionImage: Yup.mixed().when("non_cash", {
    is: (val:any) => val === true,
    then: (schema) => schema.required("transaction Image in required !"),
  }),
});

export const validateIncome = (schema: any, obj: any) => {
  try {
    schema.validateSync(obj);
    return false;
  } catch (err:any) {
    return err.message;
  }
};
