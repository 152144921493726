import { ServiceMaker, buildQueryString } from "./index";

export const getPendingDocketCount = () => {
  return ServiceMaker(`report/pendingPackageCount`, "GET");
};

export const getUnverifiedDocketCount = () => {
  return ServiceMaker(`report/unverifiedPkgCount`, "GET");
};

export const getPkgCount = (data: { companyId: string; queryType: string }) => {
  return ServiceMaker(`report/packageCount?${buildQueryString(data)}`, "GET");
};
export const getCompanyGraceData = () =>
   ServiceMaker("controlTower/blockedCompanyList",
    "GET"
  );

export const getCompany = (data: any) => {
  return ServiceMaker(
    `dashboard/companyBalanceDetails?${buildQueryString(data)}`,
    "GET"
  );
};

export const getUser = (data: any) => {
  return ServiceMaker(`dashboard/userBalanceDetails?slab=${data}`, "GET");
};
export const getUserData = () => {
  return ServiceMaker(`dashboard/blockedUsersList`, "GET");
};
export const getReconcil = (data: any) => {
  return ServiceMaker(
    `dashboard/reconcileDetails?${buildQueryString(data)}`,
    "GET"
  );
};

export const getCreditCustomerPopUp = () => {
  return ServiceMaker(`dashboard/creditCustomerBalanceDetails`, "GET");
};
export const getCreditCustomer = () => {
  return ServiceMaker(`dashboard/creditCustomerBlockedList`, "GET");
};

export const getIntermediatePackage = (data: string) => {
  return ServiceMaker(`report/intermediatePkgCount/${data}`, "GET");
};

export const getPendingAmount = (data: {
  companyId: string;
  queryType: string;
}) => {
  return ServiceMaker(`report/pendingAmount?${buildQueryString(data)}`, "GET");
};

export const getNews = (data: { sub: "B" | "F"; entity: string }) => {
  return ServiceMaker(`dashboard/news?${buildQueryString(data)}`, "GET");
};
export const getBranchList = (data: any) => {
  return ServiceMaker(`report/unverifiedPkgCount?type=${data}`, "GET");
};

export const getBranchWiseList = () => {
  return ServiceMaker(`controlTower/blockedBranchList`, "GET");
};

// /api/controlTower/blockedBranchList

export const getGracePeriod = (data: any) =>
  ServiceMaker("controlTower/gracePeriod", "POST", data);

export const cashMatch = (data: { sub: "B" | "F"; entity: string }) => {
  return ServiceMaker(`controlTower/cashMatch`, "POST", data);
};

export const getFleetData = (data: any) => {
  return ServiceMaker(
    `controlTower/blockedFleetList?${buildQueryString(data)}`,
    "GET"
  );
};

export const getKey = () => 
  ServiceMaker("webpush/getKey", "GET");

export const subscribeKey = (data:any) => 
  ServiceMaker("webpush/subscribe", "POST",data);


export const sendNotification = (data:any) => 
  ServiceMaker(`webpush/notify`, "POST",data);

export const getPlatformSettlementDetails = (data: any) => {
  return ServiceMaker(
    `dashboard/platformSettlementDetails?${buildQueryString(data)}`,
    "GET"
  );
};
  