const swDev = () => {
    function determineAppServerKey() {
        const vapidPublicKey =
        "BJthRQ5myDgc7OSXzPCMftGw-n16F7zQBEN7EUD6XxcfTTvrLGWSIG7y_JxiWtVlCFua0S8MTB5rPziBqNx1qIo";
        return urlBase64ToUint8Array(vapidPublicKey);
    }

    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }
    
    try {
        let swUrl = `${process.env.PUBLIC_URL}/sw.js`
        if ('serviceWorker' in navigator) {
            window.addEventListener('load', function() {
            navigator.serviceWorker
              .register(swUrl)
              .then((registration) => {
                console.log('Service Worker registered with scope:', registration.scope);
              })
              .catch((error) => {
                console.error('Service Worker registration failed:', error);
              });
            })
          }
    } catch (error) {
        console.log(error);
    }
}

// Example event listener for subscription change
navigator.serviceWorker.addEventListener('pushsubscriptionchange', (event) => {
    // Handle subscription change, e.g., update the server with the new subscription
    console.log('Push subscription changed:', event.newSubscription);
  });

navigator.serviceWorker.ready.then(res => {
    res.pushManager.getSubscription().then(data => {
        if(data == undefined){
            console.log("subscribed 1")
        }
        else{
            console.log("subscribed 2")
        }
    })
})

export default swDev