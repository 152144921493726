import React, { memo, forwardRef, type ComponentPropsWithoutRef } from "react";
import "./menu";
import { IconType } from "react-icons";

// MenuItemProps: Use ComponentPropsWithoutRef for button element
type MenuItemProps = {
  onSelect?: () => void;
  children: React.ReactNode;
  Icon?: IconType;
} & ComponentPropsWithoutRef<"button">;

const MenuItem = forwardRef<HTMLButtonElement, MenuItemProps>((props, ref) => {
  const { className = "", onSelect, children, Icon, ...rest } = props;
  const handleClick = () => {
    onSelect?.();
  };

  return (
    <button
      ref={ref}
      className={`taptap-menu-item px-1 py-10 flex ai-center gap-4 ${className}`}
      onClick={handleClick}
      {...rest}
    >
      {Icon && <Icon />}
      {children}
    </button>
  );
});

MenuItem.displayName = "MenuItem";
export default memo(MenuItem);
