import * as Yup from "yup";
import { fromJS } from "immutable";

Yup.setLocale({
  mixed: {
    default: "mixed default",
    required: "mixed required",
  },
  number: {
    min: "number min",
  },
});

const routeValidationObject : any = {
  routeType: Yup.string().required("routeType is a required field."),
  serviceType: Yup.string().when("routeType", {
    is: (val:any) => val == "P",
    then: (schema) => schema.required("serviceType is a required field."),
  }),
  subServiceType: Yup.string().when("serviceType", {
    is: (val:any) => val == "B",
    then: (schema) => schema.required("subServiceType is a required field."),
  }),
  name: Yup.string().required("Route name is a required field."),
  modeOfPayment: Yup.array().of(
    Yup.string()
      .required()
      .min(2)
  ),
  modeOfTransfer: Yup.string().required("modeOfTransfer is a required field."),
  TransportSubType: Yup.string().when("modeOfTransfer", {
    is: (val:any) => val == "Road",
    then: (schema) => schema.required("Transport sub type is a required."),
  }),
  working: Yup.object().shape({
    sun: Yup.boolean(),
    mon: Yup.boolean(),
    tue: Yup.boolean(),
    wed: Yup.boolean(),
    thu: Yup.boolean(),
    fri: Yup.boolean(),
    sat: Yup.boolean(),
  }),
  constraints: Yup.object().shape({
    perPackageVolume: Yup.object().shape({
      l: Yup.number()
        .required("Package length is required field.")
        .positive("Package length must be positive."),
      b: Yup.number()
        .required("Package width is required field.")
        .positive("Package width must be positive."),
      h: Yup.number()
        .required("Package height is required field.")
        .positive("Package height must be positive."),
    }),
    perPackageWeight: Yup.number()
      .required("perPackageWeight is required field.")
      .positive("perPackageWeight must be positive"),
  }),
  capacity: Yup.object().shape({
    weight: Yup.number()
      .required("weight is required field.")
      .positive("weight must be positive."),
    volume: Yup.number()
      .required("volume is required field.")
      .positive("volume must be positive."),
  }),
  insurance: Yup.boolean(),
  collections: Yup.boolean(),
  fragile: Yup.boolean(),
  unstackable: Yup.boolean(),
  hazardous: Yup.boolean(),
  denominator: Yup.number()
    .required("denominator is required field.")
    .positive("denominator must be positive"),
  minChargeWeight: Yup.number()
    .required("minChargeWeight is required field.")
    .positive("minChargeWeight must be positive"),
  //   packageRates: Yup.array().of(Yup.object().shape({
  //     name: Yup.string().required('Package Size name is required in rate!'),
  //     l: Yup.number().positive().integer().min(1).required('length is required'),
  //     b: Yup.number().positive().integer().min(1).required('width is required'),
  //     h: Yup.number().positive().integer().min(1).required('height is required'),
  //     unit: Yup.string().oneOf(['cm','m','in']).required('Valid unit is required'),
  //     weight: Yup.number().positive().integer().min(1).required('length is required'),
  //     rate: Yup.number().positive().integer().min(1).required()

  // })),
  destination: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number(),
        company: Yup.string()
          .min(24)
          .max(24)
          .required("company is a required field"),
        city: Yup.string()
          .min(24)
          .max(24)
          .required("city is a required field"),
        branch: Yup.string()
          .min(24)
          .max(24)
          .required("branch is a required field"),
        cutOffTime: Yup.object().shape({
          h: Yup.number()
            .required()
            .positive()
            .integer()
            .min(0)
            .max(23),
          m: Yup.number()
            .required()
            .positive()
            .integer()
            .min(0)
            .max(59),
        }),
        noOfDays: Yup.number()
          .required()
          .integer()
          .min(0),
        deliveryTime: Yup.object().shape({
          h: Yup.number()
            .required()
            .positive()
            .integer()
            .min(0)
            .max(23),
          m: Yup.number()
            .required()
            .positive()
            .integer()
            .min(0)
            .max(59),
        }),
        loading: Yup.boolean(),
        unloading: Yup.boolean(),
      })
    )
    .min(2, "Minimum 2 destination required"),
};
export const routeValidation = fromJS<any>(routeValidationObject);

export const wholeRouteValidation = Yup.object().shape(routeValidationObject);
