import React, { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';

interface CollapseProps {
  in: boolean; // Whether the component is open or closed
  children: React.ReactNode;
  className?: string;
  collapsedSize?: string; // Size when collapsed, default is '0px'
  timeout?: number; // Animation duration in ms, default is 300
  orientation?: 'vertical' | 'horizontal'; // Collapse direction, default is 'vertical'
  onEnter?: () => void;
  onEntering?: () => void;
  onEntered?: () => void;
  onExit?: () => void;
  onExiting?: () => void;
  onExited?: () => void;
}

const Collapse: React.FC<CollapseProps> = ({
  in: open,
  children,
  className,
  collapsedSize = '0px',
  timeout = 300,
  orientation = 'vertical',
  onEnter,
  onEntering,
  onEntered,
  onExit,
  onExiting,
  onExited,
  ...otherProps
}) => {
  const [size, setSize] = useState(open ? 'auto' : collapsedSize);
  const [isTransitioning, setTransitioning] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open) {
      handleEnter();
    } else {
      handleExit();
    }
  }, [open]);

  const handleEnter = () => {
    if (onEnter) onEnter();
    setTransitioning(true);
    const dimension = orientation === 'horizontal' ? 'scrollWidth' : 'scrollHeight';
    const fullSize = `${wrapperRef.current?.[dimension]}px`;

    setSize(collapsedSize);
    setTimeout(() => {
      setSize(fullSize);
      if (onEntering) onEntering();
    }, 10); // Delay to ensure transition

    setTimeout(() => {
      setSize('auto'); // Reset to auto after animation completes
      if (onEntered) onEntered();
      setTransitioning(false);
    }, timeout);
  };

  const handleExit = () => {
    if (onExit) onExit();
    setTransitioning(true);
    const dimension = orientation === 'horizontal' ? 'scrollWidth' : 'scrollHeight';
    const fullSize = `${wrapperRef.current?.[dimension]}px`;

    setSize(fullSize);
    setTimeout(() => {
      setSize(collapsedSize);
      if (onExiting) onExiting();
    }, 10);

    setTimeout(() => {
      if (onExited) onExited();
      setTransitioning(false);
    }, timeout);
  };

  return (
    <div
      className={clsx('nothing', className, {
        'nothing-transition': isTransitioning,
      })}
      style={{
        transition: `all ${timeout}ms ease`,
        overflow: 'hidden',
        [orientation === 'horizontal' ? 'width' : 'height']: size,
      }}
      {...otherProps}
    >
      <div ref={wrapperRef}>
        {children}
      </div>
    </div>
  );
};

export default Collapse;
