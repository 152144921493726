import React, { Fragment, useState, useEffect } from "react";
import fetchImage from "../../services/MyImage";
import { showLoader, hideLoader } from "../../actions/UserActions";
import { connect } from "react-redux";
import getImageUrl from "../../utils/getImageUrl";
import "./image.css";
import { listBranchActivities, processBranchActivity } from "../../services/dashboard2";
import showMessage, { showHttpError, success } from "../../utils/message";
import { Button, Dialog, NoDataFound, TextField, Tooltip } from "../../newSrc/components/UI";
import { ArrowLeftIcon, ArrowRightIcon, ArrowRotateLeftIcon, ArrowRotateRightIcon } from "../../newSrc/constants/icons";
import { CancelIconButton, CheckIconButton, CloseButton } from "../../Components/Button/AllButton";

const MyImage = (props: any) => {
  const [image, setImage] = useState("");
  const [rotateDeg, setRotateDeg] = useState(0);
  const [zoomDisplay, setZoomDisplay] = useState(false);
  const [status, setStatus] = useState("")
  const [adminRemark, setAdminRemark] = useState("")
  const [isProcessDialog, setIsProcessDialog] = useState(false)
  const [prevPage, setPrevPage] = useState(0)
  // const [branchActivityId, setBranchActivityId] = useState("");

  const setImageUrl = async () => {
    setImage("");
    props.showLoader();
    try {
      if (props.isSigned) {
        const image = await getImageUrl(props.objectID);
        setImage(image);
      } else {
        const response = await fetchImage(props.objectID);
        const image = await getImageUrl(response);
        setImage(image);
      }

      props.hideLoader();
    } catch (err) {
      props.hideLoader();
    }
  };
  useEffect(() => {
    if (props.objectID) {
      setImageUrl();
    }
  }, [props.objectID]);

  const rotateRight = () => {
    let x = rotateDeg;
    x += 90;
    setRotateDeg(x);
  };

  const rotateLeft = () => {
    let x = rotateDeg;
    x -= 90;
    setRotateDeg(x);
  };

  const zoom = () => {
    setZoomDisplay(!zoomDisplay);
  };

  const handleClear = () => {
    props.setAction("");
    // setBranchActivityId("");
    setStatus("");
    setIsProcessDialog(false);
    setAdminRemark("");
  };

  const getCameraActiveData = async () => {
    const payload = {
      action: "CameraStatus",
      startDate:new Date(),
      endDate: new Date()
    };
    try {
      showLoader()
      if (props.cameraStatusDate) {
        let startDate = new Date(props.cameraStatusDate);
        startDate.setHours(0, 0, 0, 0);
        let endDate = new Date(props.cameraStatusDate);
        endDate.setDate(endDate.getDate() + 1);
        endDate.setHours(0, 0, 0, 0);
        payload.startDate = startDate;
        payload.endDate = endDate;
      }
      const response = await listBranchActivities(payload);

      props.setCameraActiveSatusData(response);
      hideLoader()
    } catch (error) {
      hideLoader()
      showHttpError(error)
    }
  };
  const handleProcessBranchAct = async () => {
    showLoader();
    let PrevPage = prevPage
    try {
      const payload = {
        actId: props.branchActivityId,
        status: status,
        action: props.action,
        adminRemark: adminRemark,
      };
      await processBranchActivity(payload);
      props.setImageData(null)
      getCameraActiveData()
      showMessage("Remark Added Successfully", success);
      handleClear();
      setIsProcessDialog(false)
      setPrevPage(PrevPage)
      hideLoader();
    } catch (err) {
      handleClear();
      showHttpError(err);
      hideLoader();
    }
  };

  return (
    <Fragment>
      {/\.pdf$/i.test(props.objectID) ? (
        <object
          data={image}
          type="application/pdf"
          height={props.height ? props.height : "800px"}
          width={props.width ? props.width : "500px"}
        ></object>
      ) : (
        <div className="p-1 mt-10 mb-1">
          <div style={{}}>
            <img
              className={zoomDisplay ? "zoom" : ""}
              src={image}
              height={props.height ? props.height : "300px"}
              width="auto"
              style={{ objectFit: "contain", transform: `rotate(${rotateDeg}deg)`, ...(props.height ? { height: props.height } : {}), ...(props.width ? { width: props.width } : {}) }}
              onDoubleClick={zoom}
            />
          </div>
          {
            image === '' &&
              <NoDataFound content="Image Not Uploaded!" />
          }
          {
            props.rotate !== false &&
            <div className="flex gap-1 jc-center mt-1">
              <Tooltip title="Rotate left">
                <ArrowRotateLeftIcon onClick={rotateLeft} />
              </Tooltip>

              <Tooltip title="Rotate right">
                <ArrowRotateRightIcon onClick={rotateRight} />
              </Tooltip>
            </div>

          }
          {
            props.pagination !== false &&
            <div className="flex gap-1 jc-center mt-1">
              <Tooltip title="Prev Image">
                <ArrowLeftIcon onClick={() => props.paginateNext()} />
              </Tooltip>

              <Tooltip title="Next Image">
                <ArrowRightIcon onClick={() => props.paginateBack()} />
              </Tooltip>
            </div>
          }
          {
            props?.approveReject && (!(props?.userRowData?.processStatus === "A" || props.userRowData.processStatus === "R") && <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
                <Tooltip
                  title={<div style={{ fontSize: "14px" }}>Approve</div>}
                >
                  <CheckIconButton onClick={() => {
                    setIsProcessDialog(true);
                    setAdminRemark("");
                    // setAction(
                    //   props.row.original && props.row.original.action
                    // );
                    // setBranchActivityId(
                    //   props.row.original && props.row.original._id
                    // );
                    setStatus("A");
                  }} />
                </Tooltip>
                <Tooltip title={<div style={{ fontSize: "14px" }}>Reject</div>}>
                  <CancelIconButton onClick={() => {
                    setIsProcessDialog(true);
                    setAdminRemark("");
                    // setAction(
                    //   props.row.original && props.row.original.action
                    // );
                    // setBranchActivityId(
                    //   props.row.original && props.row.original._id
                    // );
                    setStatus("R");
                  }} />
                </Tooltip>
            </div>
              // : <p style={{textAlign:"center"}}>Already Given Status</p>
            )
          }

        </div>
      )}
      <Dialog
        state={isProcessDialog}
        setState={setIsProcessDialog}
        onClose={() => setIsProcessDialog(false)}
        size="small"
        body={
          <TextField
            label="Admin Remark"
            placeholder="Enter Remark"
            id="my-image-admin-remark"
            value={adminRemark}
            onChange={(e) => setAdminRemark(e.target.value)}
          />
        }

        footer={
          <div className="flex gap-1">
            <Button variant="secondary" action="secondary" onClick={() => handleProcessBranchAct()}>
              Submit
            </Button>
            <CloseButton onClick={() => setIsProcessDialog(false)} />
          </div>
        }
      />

    </Fragment>
  );
};
const mapStateToProps = () => ({});

const mapDispatchToProps = {
  showLoader,
  hideLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyImage);
