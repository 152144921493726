import { RESET_CCAVENUE_TXNS_STATUS, UPDATE_CCAVENUE_TXNS_STATUS } from "../constants/paymentGateWay";

const initState:any = {};

const paymentGateWayReducer = (state = initState , action: any) => {
    const {type , payload} = action;
    switch (type) {
        case UPDATE_CCAVENUE_TXNS_STATUS : {
            console.log("payload: ",payload)
            return {
                ...payload
            }
        }
        case RESET_CCAVENUE_TXNS_STATUS : {
            return {}
        }
        default:
        return state;
    }
}

export default paymentGateWayReducer;