import {
    MEMO_RECEIVED,
    SET_RECEIVED_UNAPPROVED_MEMOS,
    APPROVE_OR_REJECT_NOTIFY_MEMO,
    SET_APPROVED_REJECTED_MEMOS,
  } from "../constants/memo";
  
  import { toast } from "react-toastify";
  import { isArrayCheck } from "../containers/generics/CheckArray";
  
  const initState = {
    notifications: [],
    notificationLoadMore: true,
    isNotificationsLoading: false,
    pendingActionNotificationCount: 0,
  };
  
  const customerNotificatiionReducer = (state: any = initState, action: { [k: string]: any }) => {
    switch (action.type) {
      case MEMO_RECEIVED: {
        const { memo: memoState } = state;
        const { uid } = state;
        let memoDoc = action.data;
        if (memoDoc.receiver != uid) {
          return state;
        }
        const pendingMemos = Array.isArray(memoState.notifyMemos)
          ? memoState.notifyMemos.slice()
          : [];
        toast.info("You have received a new memo!");
        return {
          ...state,
          memo: {
            ...memoState,
            notifyMemos: pendingMemos.concat([action.data]),
          },
          updateSidebar: true,
        };
      }
  
      case APPROVE_OR_REJECT_NOTIFY_MEMO: {
        if (!isArrayCheck(state.memo.notifyMemos)) return state;
        const memo = action.payload;
        return {
          ...state,
          memo: {
            ...state.memos,
            notifyMemos: state.memo.notifyMemos.filter(
              (m: { _id: string }) => m._id != memo._id
            ),
          },
          updateSidebar: true,
        };
      }
      case SET_APPROVED_REJECTED_MEMOS: {
        const { message } = action.data;
        toast.info(message);
        return state;
      }
      case SET_RECEIVED_UNAPPROVED_MEMOS: {
        const memos = action.payload;
        return {
          ...state,
          memo: {
            ...state.memo,
            notifyMemos: memos,
          },
        };
      }
      case "SIDEBAR:UPDATE_ACK": {
        return {
          ...state,
          updateSidebar: false,
        };
      }
      case "SET_CUSTOMER_NOTIFICATION_LOADING": {
        return {
          ...state,
          isNotificationsLoading: action.payload,
        };
      }
      case "SET_CUSTOMER_NOTIFICATION_FULFILLED": {
        if (action.payload && action.payload.offset > 0) {
          return {
            ...state,
            notifications: [
              ...state.notifications,
              ...action.payload.notifications,
            ],
            notificationLoadMore: action.payload.notificationLoadMore,
            isNotificationsLoading: false,
          };
        }
        return {
          ...state,
          notifications: action.payload.notifications,
          notificationLoadMore: action.payload.notificationLoadMore,
          isNotificationsLoading: false,
        };
      }
      case "SET_CUSTOMER_NOTIFICATION_PENDING_ACTION_COUNT": {
        return {
          ...state,
          pendingActionNotificationCount: action.payload,
        };
      }
      case "REFRESH_MEMO": {
        return { ...state };
      }
      default: {
        return state;
      }
    }
  };
  
  export default customerNotificatiionReducer;
  