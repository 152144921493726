import React, { type ComponentProps } from "react";
import "./checkbox.css";

type CheckboxType = {
  label?: string;
  labelClassName?: string;
  id?: string;
  containerClassName?: string;
  labelPosition?: "top" | "right" | "bottom" | "left";
} & ComponentProps<"input">;

export default function Checkbox({
  label,
  id,
  containerClassName = "",
  labelClassName = "",
  labelPosition = "left",
  ...props
}: CheckboxType) {
  return (
    <div
      className={`taptap-checkbox-container | gap-4 ${labelPosition}  ${containerClassName}`}
    >
      {label ? (
        <label
          htmlFor={`taptap-custom-checkbox-input-${id}`}
          className={` | m-0 fw-500 user-select-none pointer ${labelClassName}`}
        >
          {label}
        </label>
      ) : null}
      <input
        type="checkbox"
        className="taptap-custom-checkbox-input pointer"
        id={`taptap-custom-checkbox-input-${id}`}
        {...props}
      />
    </div>
  );
}
