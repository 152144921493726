import React from "react";
import { Checkbox } from "@mui/material";
interface IpropsCheckBox {
  checked?: boolean;
  disabled?: boolean;
  onChange?: any;
  onClick?: any;
  title?: string;
  style?: React.CSSProperties;
  key?: any;
  classes?: any;
  color?: "primary" | "secondary" | "default";
  value?: any;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  icon?: React.ReactNode;
  checkedIcon?: React.ReactNode;
  id?: any;
  tabIndex?: any;
}

const CheckBox = (props: IpropsCheckBox) => {
  let {
    style,
    checked,
    onChange,
    onClick,
    disabled,
    key,
    title,
    classes,
    color,
    value,
    inputProps,
    icon,
    checkedIcon,
    id,
    tabIndex,
  } = props;
  return (
    <Checkbox
      style={style}
      title={title}
      checked={checked}
      onChange={onChange}
      onClick={onClick}
      disabled={disabled}
      key={key}
      classes={classes}
      color={color}
      value={value}
      inputProps={inputProps}
      icon={icon}
      checkedIcon={checkedIcon}
      id={id}
      tabIndex={tabIndex}
    />
  );
};

export default CheckBox;
