import Axios from "axios";
import { baseURL } from "../axios";
import * as auth from "./auth";
import { toastMessage } from "../Components/showMessages/toastMessage";
import { getCustomerNotificationService, getCustomerPendingActionNotificationCount } from "../services/user";
import { Dispatch } from "redux";
import { RootState } from "../store";

const url = baseURL;

export const customerLogin = (contact: number, pwd: string, rememberMe?: boolean,deviceId ?: string,latitude ?: any,longitude ?: any) => {
  const flag : any = {
    username: contact,
    password: pwd,
    rememberMe : rememberMe,
  };
  if(deviceId){
    flag.deviceId = deviceId;
  }
  if(latitude){
    flag.latitude = latitude;
  }
  if(longitude){
    flag.longitude = longitude;
  }
  return async (dispatch: any) => {
    try {
      dispatch({
        type: "START_LOADER",
      });
      let response = await Axios({
        method: "post",
        url: url + "/customerLogin",
        data: flag,
      });
      console.log("customer login response", response);
      dispatch({
        type: "CUSTOMER_LOGIN",
        payload: response.data.response,
      });
      getCustomerNotificationService({ offset: 0, limit: 20 })
      .then((newNoti) => {
        dispatch({
          type: "SET_CUSTOMER_NOTIFICATION_FULFILLED",
          payload: {
            notifications: newNoti,
            notificationLoadMore: newNoti.length === 20,
            offset: 0,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: "SET_CUSTOMER_NOTIFICATION_LOADING",
          payload: false,
        });
      }),
      localStorage.setItem("loginBy","customer")
      await dispatch(auth.registerSocket({ token: response.data.response.accessToken }))
      dispatch({
        type: "END_LOADER",
      });
    } catch (err : any) {
      console.log(err);
      if (err.response) {
        dispatch({
          type: "LOGIN_FAIL",
          payload:
            err.response.data.message === "Invalid or expired otp."
              ? err.response.data.message
              : "Invalid credentials!",
        });
      } else if (err.request) {
        dispatch({
          type: "LOGIN_FAIL",
          payload:
            err.message == "Network Error" ? "No Internet!" : err.message,
        });
      } else {
        dispatch({
          type: "LOGIN_FAIL",
          payload: err.message,
        });
      }
      dispatch({
        type: "END_LOADER",
      });
    }
  };
};

export const loadMoreCustomerNotifications = () => async (
  dispatch: any,
  getState: () => RootState
) => {
  try {
    const notifications = getState().user.notifications;
    const offset = (notifications && notifications.length) || 0;
    const newNoti = await getCustomerNotificationService({ offset, limit: 20 });
    await dispatch({
      type: "SET_CUSTOMER_NOTIFICATION_LOADING",
      payload: true,
    });
    dispatch({
      type: "SET_CUSTOMER_NOTIFICATION_FULFILLED",
      payload: {
        notifications: newNoti,
        notificationLoadMore: newNoti.length === 20,
        offset,
      },
    });
    dispatch(updateCustomerPendingActionNotificationCount());
  } catch (err : any) {
    dispatch({
      type: "SET_CUSTOMER_NOTIFICATION_LOADING",
      payload: false,
    });
  }
};

export const updateCustomerPendingActionNotificationCount = () => async (
  dispatch: Dispatch
) => {
  getCustomerPendingActionNotificationCount()
    .then((count) =>
      dispatch({
        type: "SET_CUSTOMER_NOTIFICATION_PENDING_ACTION_COUNT",
        payload: count,
      })
    )
    .catch((err) => {
      console.log(err);
    });
};

export const customerLoginByOTP = (contact: number, otp: string, rememberMe?: boolean,deviceId ?: string,name ?: string,latitude ?: any,longitude ?: any) => {
  const flag : any = {
    contact,
    OTP: otp,
    rememberMe : rememberMe,
  };
  if(deviceId){
    flag.deviceId = deviceId;
  }
  if(name){
    flag.name = name;
  }
  if(latitude){
    flag.latitude = latitude;
  }
  if(longitude){
    flag.longitude = longitude;
  }
  return async (dispatch: any) => {
    try {
      dispatch({
        type: "START_LOADER",
      });
      let response = await Axios({
        method: "post",
        url: url + "/customerLogin/verifyOTP",
        data: flag,
      });
      localStorage.setItem("loginBy","customer")
      sessionStorage.setItem("loginFromForm",true as any)
      console.log("customer login response", response);
      dispatch({
        type: "CUSTOMER_LOGIN",
        payload: response.data.response,
      });
      await dispatch(auth.registerSocket({ token: response.data.response.accessToken }))
      dispatch({
        type: "END_LOADER",
      });
      toastMessage("Customer Login Successfully", {
        theme: "colored",
        type: "success",
      });
      return response
    } catch (err : any) {
      toastMessage("failed to Login", {
        theme: "colored",
        type: "error",
      });
      console.log(err);
      if (err.response) {
        dispatch({
          type: "LOGIN_FAIL",
          payload:
            err.response.data.message === "Invalid or expired otp."
              ? err.response.data.message
              : "Invalid credentials!",
        });
      } else if (err.request) {
        dispatch({
          type: "LOGIN_FAIL",
          payload:
            err.message == "Network Error" ? "No Internet!" : err.message,
        });
      } else {
        dispatch({
          type: "LOGIN_FAIL",
          payload: err.message,
        });
      }
      dispatch({
        type: "END_LOADER",
      });
      return err
    }
  };
};

export const addressSuggestionAction = (data: any) => {
  console.log(data, "data from 001")
  return {
    type: "SET_CUSTOMER__ADDRESS_SUGGESTION_DETAILS",
    data
  }
}

export const friendAddressSuggestionAction = (data: any) => {
  console.log(data, "data from 001")
  return {
    type: "SET_CUSTOMER__FRIEND_ADDRESS_SUGGESTION_DETAILS",
    data
  }
}

export const customerLogoutReducer = () => { return { type: "CUSTOMER_LOGOUT" } }

export const customerLoginWithToken = (token : any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: "START_LOADER",
      });
      let response = await Axios({
        method: "post",
        url: url + "/customerToken/getIdToken",
        data: {rt : token},
      });
      console.log("customerLoginWithToken customer login response 96000", response);
      dispatch({
        type: "CUSTOMER_LOGIN",
        payload: response?.data?.response,
      });
      dispatch({
        type: "END_LOADER",
      });
    } catch (err : any) {
      console.log(err);
      dispatch({
        type: "END_LOADER",
      });
    }
  };
};

export const setCustomerDetails = () => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: "START_LOADER",
      });
      let response = await Axios({
        method: "get",
        url: url + "/customerProfile/getDetails",
      });
      console.log("SET_CUSTOMER_DETAILS customer login response 96000", response);
      dispatch({
        type: "SET_CUSTOMER_DETAILS",
        payload: response?.data?.response,
      });
      dispatch({
        type: "END_LOADER",
      });
    } catch (err : any) {
      console.log(err);
      dispatch({
        type: "END_LOADER",
      });
    }
  };
}