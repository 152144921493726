export const moreInformationIndividualWithFleet = [
  {
    id: 0,
    description:
      "Attach your two-wheeler, Tata Ace, or Bolero pickup with TapTap for steady trips, assured income, and added incentives. Enjoy hassle-free navigation with GPS, standardized rates, and flexible hours.",
  },
  {
    id: 1,
    description:
      "Join our delivery partner program across India for sustainable earnings and seamless customer service. Sign up now with your Aadhaar, bank details, and Android phone to start earning today! No boss, flexible schedule, etiquette training, quick pay.",
  },
  {
    id: 2,
    description:
      "We respect your time and efforts and take pride in serving fellow Indians. Join our community with over 1000 partners and monthly earnings exceeding ₹30,000. Join India’s most loved quick commerce platform and turn your miles into money.",
  },
];

export const moreInformationPartLoadAndFullLoad = [
  {
    id: 0,
    description:
      "Welcome to TapTap, your trusted partner for comprehensive transportation solutions. We specialize in both Part Load (PTL) and Full Truck Load (FTL) services, ensuring that your goods are delivered safely and efficiently across India. Our mission is to provide cost-effective and reliable logistics solutions tailored to your business needs.",
  },
  {
    id: 1,
    description:
      "Part Load (PTL): Our Part Load services are designed to maximize efficiency and minimize costs by consolidating multiple shipments into a single truck. This approach not only reduces transportation expenses but also lowers your carbon footprint, making it an eco-friendly choice. With PTL, you pay only for the space your cargo occupies, offering significant savings. Our flexible options include various types of trucks and trailers, ensuring that your specific requirements are met.",
  },
  {
    id: 2,
    description:
      "Full Truck Load (FTL): For businesses with larger shipments, our Full Truck Load services provide dedicated transportation solutions. Whether you're transporting heavy machinery, construction materials, bulky goods, or high-value items, our FTL services offer the reliability and efficiency you need.",
  },
];

export const moreInformationData = [
  {
    id: 0,
    page: "partners",
    partner: "Individual With Fleet",
    moreInformation: moreInformationIndividualWithFleet,
  },
  {
    id: 1,
    page: "services",
    service: "Part Load & Full Load",
    moreInformation: moreInformationPartLoadAndFullLoad,
  },
];
