import jsonToFormdata from "../utils/jsonToFormdata";
import { buildQueryString, ServiceMaker } from "./index";

export const generateMemoService = (data: any) => ServiceMaker("memo", "POST", data);

export const generateMemoSendService = (data: any) => ServiceMaker("transactions/memo", "POST", jsonToFormdata(data));

export const transactionWallet = (data: any) => ServiceMaker("transactions/wallet", "POST", data);


export const fetchMemoService = (data: {
  memoNo: string
}) =>
  ServiceMaker("memo/fetch", "POST", data);

export const getPendingMemosByUserAccount = (sub: string, entity: string) =>
  ServiceMaker(`memo/pending/${sub}/${entity}`, "GET");
/**
 * @deprecated
 * remove when no longer in use
 */
export const getUnreportedMemoService = (data: {
  loginType: string
}) =>
  ServiceMaker("memo/unreported", "POST", data);

export const transferService = (data: any) =>
  ServiceMaker("memo/transfer", "POST", data, 0);

export const getSentMemoService = (data: any) =>
  ServiceMaker("memo/sent", "POST", data);

export const updateNonCashTxnDetails = async (data: any) => ServiceMaker(
  "memo/updateTransactionDetails",
  "PATCH",
  data
);

export const getReceivedMemoService = (data: any) =>
  ServiceMaker("memo/received", "POST", data);

export const approveMemoService = (data: any) =>
  ServiceMaker("memo/approve", "POST", data);

export const rejectMemoService = (data: {
  memo: string
}) =>
  ServiceMaker("memo/reject", "POST", data);

export const getUnapprovedMemosService = (data: any) =>
  ServiceMaker("memo/toapprove", "POST", data);

export const getPendingMemoByCompany = (company: string) =>
  ServiceMaker(`memo/company/pending/${company}`, "GET");


export const listCustomerMemo = (data?: any) =>
  ServiceMaker(`memo/listCustomerMemo?${buildQueryString(data)}`, "GET");

export const processCustomerMemo = (data?: any) =>
  ServiceMaker(`memo/processCustomerMemo`, "PATCH", data);
