import { showLoader, hideLoader } from "../UserActions";
import {
  createBranchService,
  deleteBranchService,
  viewBranch,
} from "../../services/branch";
import { showMessage, failed } from "../../utils/message";
import jsonToFormdata from "../../utils/jsonToFormdata";
import { getAllBranches } from "../../services/branchRank";

export const changeSimple = (index: any, val: any) => ({
  type: "BR_CHANGE_SIMPLE",
  payload: { index, val },
});
export const brCam = (val: any) => ({ type: "BR_CAM_ADD", payload: val });
export const changeBranchHub = (data: any) => ({
  type: "BRANCH_HUB",
  payload: data,
});
export const brCamRemove = (i: any) => {
  return {
    type: "BR_CAM_REMOVE",
    payload: i,
  };
};

export const pinPoint = (data: any) => ({
  type: "PIN_POINT",
  payload: data,
});

export const fetchBranch = (bid: any) => {
  return async (dispatch: any) => {
    try {
      const response = await viewBranch(bid);
      if (
        response.rented &&
        response.rented.rentDate &&
        response.rented.rentDate.length > 10
      ) {
        response.rented.rentDate = response.rented.rentDate.substring(0, 10);
      }
      dispatch({
        type: "FETCH_BRANCH_SUCCESS",
        payload: response,
      });
    } catch (err : any) {
      console.log(err);
    }
  };
};

export const clearBranch = () => ({ type: "BRANCH_RESET" });

export const setAllPlatformBranches = () => {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const response = await getAllBranches()
      dispatch({
        type: "ALL_PLATFORM_BRANCHES",
        payload: response,
      });
      dispatch(hideLoader());
    } catch (err : any) {
      dispatch(hideLoader());
      showMessage(err.message || "couldn't create branch", failed);
    }
  };
}

export const branchCreateReq = (payload: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      const { branches } = getState().user;
      dispatch(showLoader());
      const response = await createBranchService(jsonToFormdata(payload));
      dispatch({
        type: "BRANCH_CREATE_OR_UPDATE",
        payload: response,
        branchesArr: branches,
      });
      dispatch(hideLoader());
    } catch (err : any) {
      dispatch(hideLoader());
      showMessage(err.message || "couldn't create branch", failed);
    }
  };
};

export const deleteBranch = (id: any, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const response = await deleteBranchService(id, data);
      dispatch({
        type: "DELETE_BRANCH",
        payload: response,
      });
      dispatch(hideLoader());
    } catch (err : any) {
      dispatch(hideLoader());
      showMessage(err.message || "couldn't delete branch", failed);
    }
  };
};

export const setSimpleError = (payload: any) => ({
  type: "SET_ERROR",
  payload: payload,
});
