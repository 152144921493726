import { ServiceMaker, buildQueryString } from "./index";

export const getCustomerReport = (data: any) =>
  ServiceMaker(`customerreport/booking/list?${buildQueryString(data)}`, "GET");

export const getCustomerReportMonthWise = (data: any) =>
  ServiceMaker(`customerBooking/branchBooking/monthWise?${buildQueryString(data)}`, "GET");

export const getCustomerReportCustomerOrder = (data? : any) =>
  ServiceMaker(
    `customerBooking/customerWise/listCustomerBooking?${buildQueryString(
      data
    )}`,
    "GET"
  );
