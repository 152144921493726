import { useState, useCallback } from "react";
import { multipleExist } from "../utils/booking.utils";
import { getPlaceDataFromLatLngDetail } from "../../../../../../services/autoComplete";
import { showHttpError } from "../../../../../../utils/message";

// Define the hook
export type LocationFetchType = "current_location" | "lat_lng";

export type LatLngType = {
  lat: number;
  lng: number;
};

const useFetchCurrentLocation = (intracity?: any) => {
  const [location, setLocation] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  // Modify fetchLocation to return a Promise
  const fetchLocation = useCallback(
    (type: LocationFetchType, coords?: LatLngType): Promise<any> => {
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          setError("Geolocation is not supported by your browser");
          reject("Geolocation is not supported by your browser");
          return;
        }

        const successFun = async (pos: any) => {
          let argCoordinates = {
            latitude: coords?.lat,
            longitude: coords?.lng,
          };
          let crd  =
            type === "current_location" ? pos.coords : argCoordinates;

          try {
            const payload = {
              lat: crd.latitude,
              lng: crd.longitude,
            };

            const ans = await getPlaceDataFromLatLngDetail(payload);

            const locationDetail = {
              ...ans?.results?.[0],
              lat: crd.latitude,
              lng: crd.longitude,
              formatted_address: ans?.results?.[0]?.formatted_address,
              place_id: ans?.results?.[0]?.place_id,
              name: ans?.results?.[0]?.formatted_address,
              area: ans?.results?.[0]?.address_components?.find((x: any) =>
                multipleExist(x.types, [
                  "sublocality_level_1",
                  "sublocality",
                  "political",
                ])
              )?.long_name,
              city: ans?.results?.[0]?.address_components?.find((x: any) =>
                multipleExist(x.types, ["locality", "political"])
              )?.long_name,
              state: ans?.results?.[0]?.address_components?.find((x: any) =>
                multipleExist(x.types, [
                  "administrative_area_level_1",
                  "political",
                ])
              )?.long_name,
              pincode: ans?.results?.[0]?.address_components?.find((x: any) =>
                multipleExist(x.types, ["postal_code"])
              )?.long_name,
            };

            setLocation(locationDetail);
            resolve(locationDetail); // Resolve the Promise with location details
          } catch (error) {
            showHttpError(error);
            setError("Failed to fetch location details.");
            reject(error); // Reject the Promise if an error occurs
          }
        };

        const errorFun = (err: any) => {
          setError("Unable to retrieve your location");
          reject(err);
        };

        navigator.geolocation.getCurrentPosition(successFun, errorFun);
      });
    },
    [intracity]
  );

  return { location, error, fetchLocation };
};

export default useFetchCurrentLocation;
