import React from "react";
import "./docketNumber.css";
import { Link } from "react-router-dom";

interface IProps {
  docketNumber: string;
  customerSection?: boolean
  // color?: string; 
}

const DocketNumberRedirect = (props: IProps) => {
  return (
    <Link
      to={{
        pathname:props?.customerSection?"/customerDashboard/track": "/dashboard/track",
      }}
      state={{
        from: "redirect-docket-to-tracking",
        data: props.docketNumber,
      }}
      style={{ textDecoration: "none", color: 
      // props.color ? props.color :
      "var(--clr-primary-400)"
      // "black"
     }}
    >
      <div className="docket">{props.docketNumber && props.docketNumber.toUpperCase()}</div>
    </Link>
  );
};

export default DocketNumberRedirect;
