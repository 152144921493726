import React from 'react'
import "./FAQ.css"

const FAQ = (props:any) => {
  return (
    <>
    <div className="containerForMobileVersion">
        <div className="row">
            <div className="col-md-12">
                <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    
                    <div className="panel panel-default">
                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            <div className="panel-heading" role="tab" id="headingOne">
                                <h4 className="panel-title">
                                {props.data.FAQ[0].question[0]}
                                </h4>
                            </div>
                        </a>

                    <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="panel-body">{props.data.FAQ[1].answer[0]}
                        </div>
                    </div>
                    </div>

                    <div className="panel panel-default">
                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <div className="panel-heading" role="tab" id="headingTwo">
                                <h4 className="panel-title">
                                    {props.data.FAQ[0].question[1]}
                                </h4>
                            </div>
                        </a>

                    <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                        <div className="panel-body">{props.data.FAQ[1].answer[1]}
            </div>
                    </div>
                    </div>
                    <div className="panel panel-default">
                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <div className="panel-heading" role="tab" id="headingThree">
                                <h4 className="panel-title">
                                    {props.data.FAQ[0].question[2]}
                                </h4>
                            </div>
                        </a>

                    <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                        <div className="panel-body">{props.data.FAQ[1].answer[2]} </div>
                    </div>
                    </div>
                    <div className="panel panel-default">
                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            <div className="panel-heading" role="tab" id="headingFour">
                                <h4 className="panel-title">
                                {props.data.FAQ[0].question[3]}
                                </h4>
                            </div>
                        </a>

                    <div id="collapseFour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
                        <div className="panel-body">{props.data.FAQ[1].answer[3]}
                        </div>
                    </div>
                    </div>

                    <div className="panel panel-default">
                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            <div className="panel-heading" role="tab" id="headingFive">
                                <h4 className="panel-title">
                                    {props.data.FAQ[0].question[4]}
                                </h4>
                            </div>
                        </a>

                    <div id="collapseFive" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
                        <div className="panel-body">{props.data.FAQ[1].answer[4]}
            </div>
                    </div>
                    </div>
                    <div className="panel panel-default">
                        <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                            <div className="panel-heading" role="tab" id="headingSix">
                                <h4 className="panel-title">
                                    {props.data.FAQ[0].question[5]}
                                </h4>
                            </div>
                        </a>

                    <div id="collapseSix" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
                        <div className="panel-body">{props.data.FAQ[1].answer[5]} </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
</div>
    </>
  )
}

export default FAQ