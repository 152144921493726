const formatTime = (time: number): string => {
  // Ensure time is in milliseconds (convert if necessary)
  const timeInMs = typeof time === 'string' ? parseInt(time) * 1000 : time;

  // Calculate hours and minutes from milliseconds
  const hours = Math.floor(timeInMs / (60 * 60 * 1000)); // Hours in milliseconds
  const minutes = Math.floor((timeInMs % (60 * 60 * 1000)) / (60 * 1000)); // Minutes in milliseconds

  // Format hours and minutes with leading zeros (0 padding)
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  // Return formatted time string
  return `${formattedHours}:${formattedMinutes}`;
};


export default formatTime;


export const formatTimeToMMSS = (time: number): string => {
  // Ensure time is in milliseconds (convert if necessary)
  const timeInMs = typeof time === 'string' ? parseInt(time) * 1000 : time;

  // Calculate minutes and seconds from milliseconds
  const minutes = Math.floor(timeInMs / (60 * 1000)); // Minutes in milliseconds
  const seconds = Math.floor((timeInMs % (60 * 1000)) / 1000); // Seconds in milliseconds

  // Format minutes and seconds with leading zeros (0 padding)
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  // Return formatted time string
  return `${formattedMinutes}:${formattedSeconds}`;
};
