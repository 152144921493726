import React from "react";
import Creatable, { CreatableProps } from "react-select/creatable";

type CreatableSelectProps = { label?: string, containerClassName?: string } & CreatableProps<any, any, any>;

export default function CreatableSelect({
  label,
  containerClassName = "",
  ...props
}: CreatableSelectProps) {
  return (
    <div className={`flex-col gap-4 ${containerClassName}`}>
      {label && <label className="m-0 fw-500">{label}</label>}
      <Creatable {...props} />
    </div>
  );
}
