import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  useCallback,
  FunctionComponent,
  useMemo,
  ReactNode,
} from "react";
import Webcam from "react-webcam";
import { compressImageFile } from "../utils/compressImage";
import ImageCompression from "browser-image-compression/dist/browser-image-compression";
import { getSignedUrl } from "../services/utils";
import Axios from "axios";
import "./imageInput.css";
import { ArrowRotateLeftIcon, ArrowRotateRightIcon, DeleteIcon, GalleryIcon } from "../newSrc/constants/icons";
import { Button, Dialog, Tooltip } from "../newSrc/components/UI";
import ProgressBar from "../containers/Home/ProgressBar";


// import { ButtonPrimary } from "./Button/Buttoncontainer";
/**
 * use example:
 * const temp = () => {
 * const [file, setFile] = useState(null);
 * return (
 *   <ImageInput
 *     name="POD"
 *     Icon={UploadIcon}
 *     onChange={(file) => {
 *       setFile(file) ;
 *     }}
 *     value={file}
 *   />
 * );
 * };
 */
interface ImageInputProps {
  name: string;
  Icon?: React.ElementType;
  onChange: (file: null | File | File[]) => void;
  value: any;
  extras?: JSX.Element;
  compressImage?: boolean;
  multiple?: boolean;
  acceptPdf?: boolean;
  info?: string;
  onlyCamera?: boolean;
  height?: any;
  width?: any;
  padding?: any;
  margin?: any;
  style?: any;
  trigger?:any;
  handleCloseTrigger?:any
  disableExtrasClickClose?: boolean
  label?: string;
  children?: ReactNode;
  wantTooltip?: boolean;
}

const ImageInput: FunctionComponent<ImageInputProps> = ({
  name,
  Icon = GalleryIcon,
  onChange,
  value,
  extras,
  compressImage,
  multiple,
  acceptPdf,
  info,
  onlyCamera,
  height,
  width,
  margin,
  style,
  trigger,
  handleCloseTrigger,
  disableExtrasClickClose,
  label,
  children,
  wantTooltip = true
}) => {
  const [openImageDialog, setImageDialog] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<any>(null)
  const [compressing, setCompressing] = useState(false);
  const [rotateDeg, setRotateDeg] = useState(0);
  const [zoomDisplay, setZoomDisplay] = useState(false);
  const [deviceId, setDeviceId] = React.useState({});
  const [devices, setDevices] = React.useState<
    { deviceId?: any; label?: any }[]
  >([]);
  const handleDevices = React.useCallback(
    (mediaDevices: any) =>
      setDevices(mediaDevices.filter(({ kind }: any) => kind === "videoinput")),
    [setDevices]
  );

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  useEffect(() => {
    const fetchData = async () => {
      const a: any = document.getElementById("inputimage" + name);
      if (a) {
        a.value = "";
      }
      if (!value) {
        return setImageUrl(null);
      }
      if (typeof value === "object") {
        if (value.constructor === Blob || value.constructor === File) {
          return setImageUrl(window.URL.createObjectURL(value));
        }
        if (
          value.constructor === Array &&
          value.length > 0 &&
          value[0] &&
          (value[0].constructor === Blob || value.constructor === File)
        ) {
          return setImageUrl(
            (value).map((v) => window.URL.createObjectURL(v))
          );
        }
        if (
          value.constructor === Array &&
          value.length > 0 &&
          value[0] &&
          typeof value[0] === "string"
        ) {
          return setImageUrl(
            await Promise.all(
              (value).map(async (v) => {
                try {
                  const newUrl =
                    v.includes("https://") || v.includes("http://")
                      ? v
                      : await getSignedUrl(v);
                  const s = newUrl.split("?");
                  if (/(\.pdf|\.png|\.jpg|\.jpeg)$/.test(s[0])) {
                    return newUrl;
                  } else {
                    return (await Axios.get(newUrl)).data;
                  }
                } catch (err : any) {}
              })
            )
          );
        }
      }
      if (typeof value === "string") {
        try {
          const newUrl =
            value.includes("https://") || value.includes("http://")
              ? value
              : await getSignedUrl(value);
          const s = newUrl.split("?");
          if (/(\.pdf|\.png|\.jpg|\.jpeg)$/.test(s[0])) {
            return setImageUrl(newUrl);
          } else {
            return setImageUrl((await Axios.get(newUrl)).data);
          }
        } catch (err : any) {}
      }

      setImageUrl(null);
    };
    fetchData();
  }, [value]);

  const toggleImageDialog = useCallback(() => {
    setImageDialog(!openImageDialog);
    if(openImageDialog){
      handleCloseTrigger?.()
    }
  }, [openImageDialog]);

  useEffect(() => {
    if(trigger){
      toggleImageDialog()
    }
  }, [trigger]);

  
  const ClearImage = useCallback(() => {
    onChange(null);
  }, []);

  const uploadImageFromSystem = useCallback(() => {
    const a = document.getElementById("inputimage" + name);
    if (a) {
      a.click();
    }
  }, []);

  const changeFileInput = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setCompressing(true);
      debugger
      if (e.target.files && e.target.files.length > 0) {
        const compressedFiles = await Promise.all(
          Array.from(e.target.files).map(async (file: any) => {
            if (/\.pdf$/i.test(file.name)) {
              return file;
            } else {
              if (compressImage !== false) {
                const newFile: any = await compressImageFile(file);
                newFile.name = file.name;
                return newFile;
              } else {
                return file;
              }
            }
          })
        );
        if (multiple !== true) {
          onChange(compressedFiles[0]);
        } else {
          onChange(compressedFiles);
        }
      }
      setCompressing(false);
    },
    []
  );

  const webcamRef: any = useRef(null);
  const capture = useCallback(async () => {
    if (webcamRef.current) {
      const imageSrc = (webcamRef.current).getScreenshot();
      const ImageName = name + Date.now() + ".jpeg";
      const imageFile: any = await ImageCompression.getFilefromDataUrl(
        imageSrc,
        ImageName
      );
      if (compressImage !== false) {
        const newFile: any = await compressImageFile(imageFile);
        if (multiple === true) {
          onChange([newFile]);
        } else {
          onChange(newFile);
        }
      } else {
        if (multiple === true) {
          onChange([imageFile]);
        } else {
          onChange(imageFile);
        }
      }
    }
  }, [webcamRef]);

  const [permissionError, setPermissionError] = useState({
    status: true,
    msg: "",
  });

  const rotateRight = useCallback(() => {
    let x = rotateDeg;
    x += 90;
    setRotateDeg(x);
  }, [rotateDeg]);

  const rotateLeft = useCallback(() => {
    let x = rotateDeg;
    x -= 90;
    setRotateDeg(x);
  }, [rotateDeg]);

  const zoom = useCallback(() => {
    setZoomDisplay(!zoomDisplay);
  }, [zoomDisplay]);

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: true }).then(
      (stream) => {
        setPermissionError({ status: false, msg: "" });
        stream.getTracks().forEach(function(track) {
          if (track.readyState == "live" && track.kind === "video") {
            track.stop();
          }
        });
      },
      (_error) => {
        // if (error.name == "NotAllowedError") {
        //   // user denied access to camera
        // }
        setPermissionError({ status: true, msg: _error.message });
      }
    );
  }, []);
  const WrappedIcon: React.ElementType = useMemo(
    () =>
      React.forwardRef<any>((props, ref) => {
        return <Icon {...props} ref={ref} />;
      }),
    [Icon]
  );


  return (
    <>
      <div style={{ border: label  ? "1px dashed var(--clr-white-400)" : "", padding : label ? '1rem' : '0' }} onClick={toggleImageDialog} title={wantTooltip ? name : ""} className="fs-link flex ai-center gap-1 br-4">
        <WrappedIcon
          onClick={toggleImageDialog}
          style={{
            ...(width ? { width: width } : {}),
            ...(height ? { height: height } : {}),
            borderRadius: 4,
            cursor: "pointer",
            flexShirnk: 0,
            ...(margin?{margin: margin}:{}),
            ...(style?{...style}:{}),
            color: imageUrl ? "green" : "text-primary-400"
          }}
        />
        {label && <label className="m-0 fw-500">{label}</label>}
        {children}
      </div>
      <input
        id={"inputimage" + name}
        type="file"
        multiple={multiple === true}
        onChange={changeFileInput}
        accept={
          acceptPdf
            ? "application/pdf, image/png, image/jpg, image/jpeg"
            : "image/png, image/jpg, image/jpeg"
        }
        style={{ display: "none" }}
      />
      <Dialog
      state={openImageDialog}
      setState={setImageDialog}
      header='Uploading Image'
      size="regular"
      body={
        <>
         {info && <h4>{info}</h4>}
          {compressing === true ? (
            <div style={{ textAlign: "center" }}>
              <ProgressBar />
            </div>
          ) : imageUrl ? (
            imageUrl.length > 0 && typeof imageUrl === "object" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  overflowX: "scroll",
                  overflowY: "hidden",
                }}
              >
                {imageUrl.map((src:any) => (
                  <div>
                    <img
                      className={zoomDisplay ? "zoom" : ""}
                      src={src}
                      width="auto"
                      height="300px"
                      style={{
                        objectFit: "contain",
                        padding: 10,
                        backgroundColor: "whitesmoke",
                        transform: `rotate(${rotateDeg}deg)`,

                        // border: "solid black 2px",
                        // borderRadius: 10,
                      }}
                      onDoubleClick={zoom}
                    />
                    <div className="pointer">
                      <Tooltip title="Rotate left" position="top">
                        <ArrowRotateLeftIcon onClick={rotateLeft} />
                      </Tooltip>

                      <Tooltip title="Rotate right" position="top">
                        <ArrowRotateRightIcon onClick={rotateRight} />
                      </Tooltip>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <img
                  className={zoomDisplay ? "zoom" : ""}
                  src={imageUrl}
                  width="auto"
                  height="300px"
                  style={{
                    transform: `rotate(${rotateDeg}deg)`,
                  }}
                  onDoubleClick={zoom}
                />
                <div
                className="flex jc-center fs-link ai-center my-1 pointer gap-1"
                >
                  <Tooltip title="Rotate left" position="top">
                    <ArrowRotateLeftIcon  onClick={rotateLeft} />
                  </Tooltip>

                  <Tooltip title="Rotate right" position="top">
                    <ArrowRotateRightIcon onClick={rotateRight} />
                  </Tooltip>
                </div>
              </div>
            )
          ) : permissionError.status === true ? (
            <div>
              <h3>Could not access Camera</h3>
              <h4>
                Ensure that camera exists and permission to access camera is
                given.
              </h4>
              <h4 className="bg-danger-50 text-danger-900 max-content py-8 px-1 br-4">
                <span>Error: {permissionError.msg}</span>
              </h4>
            </div>
          ) : (
            <>
              <Webcam
                audio={false}
                // height={720}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={"100%"}
                videoConstraints={{ deviceId }}
                // videoConstraints={{
                //   facingMode: "environment",
                // }}
              />
              <div>
                {devices.map((device, key) => (
                  <button
                    key={device.deviceId}
                    onClick={() => setDeviceId(device.deviceId)}
                  >
                    {`Camera ${key + 1}`}
                  </button>
                ))}
              </div>
            </>
          )}
        </>
      }
      footer={
        <>
         {imageUrl ? (
            <Fragment>
              <div onClick={() => !disableExtrasClickClose && toggleImageDialog()}>{extras}</div>

              <Button className="flex gap-1 ai-center" variant="danger" action='secondary' onClick={ClearImage} >

                  <DeleteIcon />
                <p>Remove</p>
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              {permissionError.status !== true && (
                <Fragment>
                  {!disableExtrasClickClose && <div onClick={() =>  toggleImageDialog()}>{extras}</div> || (
                      <DeleteIcon />
                  )}
                  <div>
                    <div style={{ textAlign: "center" }}>
                      <button
                        className="button-primary-secondary fs-popup-btn flex-center gap-8 p-1 br-4"
                        onClick={capture}
                      >
                        
                        <GalleryIcon />
                        <p style={{textTransform: "uppercase"}}>capture</p>
                      </button>
                    </div>
                  </div>
                </Fragment>
              )}
              {!onlyCamera && (
                <div
                  style={{
                    display: "inline-block",
                    margin: "0 4px",
                    textAlign: "center",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <button className={`button-primary-secondary fs-popup-btn flex-center gap-8 p-1 br-4`} onClick={uploadImageFromSystem}>
                      <GalleryIcon className="text-primary-900" />
                    <p className="text-primary-900" style={{textTransform: "uppercase"}}>upload</p>
                  </button>
                </div>
              )}

              
            </Fragment>
          )}
          </>
      }
      />
      
    </>
  );
};

export default ImageInput;
