import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import HowItWorks from '../../../Components/How It Works/HowItWorks';
import FAQ from '../../../Components/FAQ/FAQ';
import Testimonials from "../../../Components/Tetimonials/Testimonials";
import OurFeatures from "../../../Components/Features/OurFeatures";
import "../css/partners.css"
import ContactDetailsForm from "./FormikForms/contactDetailsForm";
import { useMediaQuery, useTheme } from "@mui/material";


const transportComapny = {
  "HowItWorks": [{ headings: ["Get quote", "Choose the vehicle", "Real-time tracking"] },
  {
    img: ["how-it-works-img1.png",
      "how-it-works-img2.png",
      "how-it-works-img3.png"]
  }],
  "Features": [
    {
      img: ["https://img.icons8.com/bubbles/80/000000/cash-in-hand.png",
        "https://img.icons8.com/bubbles/80/000000/security-checked.png",
        "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
        "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
        "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
        "https://img.icons8.com/bubbles/80/000000/conference-call.png"]
    },
    { headings: ["Complementary Solutions", "Consulting Partners", "Integration Partners", "Referral Partners", "Technology Partners", "CCTV surveillance"] },
    {
      content: ["Our priority is your time. We know how important it is for you to get your things on time, every time.",
        "We only work with the best quality products to ensure that you get the best experience possible.",
        "We deliver to your doorstep, so you can just sit back and relax.",
        "We provide you with the option to pay so that you can pay us only when you receive your product.",
        "You will be updated and notified at every stage of the packing and moving process so that you know where your product is at all times.",
        "We have set up in our facility so that your product is always safe and secure."]
    }],
  "FAQ": [
    {
      question: ["How early should I book a courier ?",
        "Can I choose a specific courier for my delivery ?",
        "Can I order a specific vehicle ?",
        "Can I cancel or change my order ?",
        "Why hasn't anyone been assigned to my order yet ?",
        "How do I know if the courier has delivered the parcel ?"]
    },
    {
      answer: ["You only need to book 30 minutes in advance if the first delivery is within travel zone 1, one hour in advance if in travel zone 2 and 1.5–2 hours if further out.",
        "When you fill in the form, you can use the free Preferred Courier service in your online account. You can choose couriers you have used before and whom you have given a 5-star rating. Your chosen courier will receive a message that you would like them to deliver your order. If the courier wants to take the order, he/she clicks on it.",
        "We don’t know in advance what car the courier is driving. To make sure you get a courier whose car is big enough, specify the dimensions and weight of the item. You will get responses from couriers who have cars big enough to fulfill your order.",
        "If you change the order after the courier has set off, you will be charged ₹ 200. You can make changes through your online account or by phone, but these changes have to be agreed upon with the courier.",
        "Our system works based on responses. So far, no couriers have responded to your order. Depending on the day and the route, it can take from one minute to an hour to find a courier.",
        "You will receive a text message or email. The recipient will also receive a text message notifying them that the courier is coming (unless the delivery is a surprise)."
      ]
    }],
  "Testimonials": [{ img: [] }, { name: [] }, { possition: [] }, { company: [] },
  {
    feedback: ["I have been using Kabra Express for a few months now and I am very happy with the service. The delivery is always on time and the drivers are very polite. I would recommend it to anyone looking for a reliable and convenient delivery service.",
      "I have been using Kabra express for the past few months and I am very impressed with their service. I love that I can get all my groceries, food, and even gifts delivered to my doorstep with just a few clicks. Kabra express has made my life so much easier and hassle-free!",
      "I was so skeptical about ordering my groceries online but Kabra Express has changed my mind. They are prompt and efficient & the products are always fresh. I don't have to worry about anything, they take care of it all. Highly recommend!"
    ]
  }]
}

const CustomerUrl = () => {
  //  const [contactNo,setContactNo] = useState(0); 
   const [tabActive,setTabActive] = useState(0);
  //  const history = useHistory();
  // const navigate = useNavigate()
  
  const topFunction2 = (props:any)=>{
    document.body.scrollTop = 0;
    // handleFlatChange: any;
    {props === "book" ? document.documentElement.scrollTop = 0 : props === "feature" ? document.documentElement.scrollTop = 515 : props === "how" ? document.documentElement.scrollTop = 1040 :
    props === "test" ? document.documentElement.scrollTop = 1575 : props === "faq's" ? document.documentElement.scrollTop = 2100 : 0 }
  } 
  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2
  
  useEffect(() => {
    topFunction2("book")
  }, []);

  const topFunction = ()=>{
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <Fragment>
      {mobileView ?
      <>
            <div className="customerUrlBox" onMouseOver={()=>{setTabActive(0)}}>
                {/* <CustomerRegistration /> */}
                <div className="imgContainer">
                  <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdfPR9e9Cbcoz96gLYHbnbKQUffhvqwuTW6Q&usqp=CAU"  />
                </div>
                  <div className='customerFormContainer'>
                    <h3 className="customerHeading">Become A Customer</h3>
                    {/* <div className='formPage1Container'>
                        <div className='formPage1'>
                            <input className='form-control' 
                            type={"text"} 
                            placeholder='Full Name'
                            onChange={(e: any) => {
                              onChangehandler(["Name"], e.target.value)
                            }}
                            />
                            <br />
                            <input className='form-control' 
                            type={"email"} 
                            placeholder='E-mail'
                            onChange={(e: any) => {
                              onChangehandler(["email"], e.target.value)
                            }}
                             />
                            <br />
                            <input className='form-control' 
                            type={"password"} 
                            placeholder='Password' 
                            onChange={(e: any) => {
                              onChangehandler(["password"], e.target.value)
                            }}
                            />
                            <br />
                            <input className='form-control' 
                            type={"number"} 
                            placeholder='Contact No' 
                            onChange={(e: any) =>
                              onChangehandler(["contactNo"], e.target.value)
                            }  
                            />
                            <br />
                            <button className='btn btn-primary otpButton' 
                            onClick={()=>{handelgetOtp()}}>
                              Get Otp</button>
                            <br />
                            <input className='form-control otpInput' 
                            type={"number"} 
                            placeholder='Enter Otp'
                            onChange={(e: any) => {
                              onChangehandler(["otp"], e.target.value)
                            }}
                            />
                        </div>
                    </div>
                    <div className='changeFormbtns'>
                    <br />
                      <div className="btn btn-primary-one text-white puBtn"
                      onClick={handleSubmit}
                      >SignUp</div>
                    </div> */}
              <ContactDetailsForm
                className={"individualWFContactForm"}
                commonUsedForm={true}
                submitBtn={true}
                emailClass={"emailDesign"}
                formTitle={"individualWFContactForm"}
              />
            </div>
          </div>

          <div className="feturesTabs" style={{ marginTop: "-7.5%" }}>
            <div className="features commonBoxShadow">
              <div className="section-title" data-wow-delay="0.1s">
                <h2>Our Features</h2>
              </div>
              <div className="centalisedFeatures">
                <OurFeatures data={transportComapny} />
              </div>
            </div>
            <div className="howItWorks commonBoxShadow">
              <div className="section-title" data-wow-delay="0.1s">
                <h2>How It Works</h2>
              </div>
              <div className="centalisedHowItWorks">
                <HowItWorks data={transportComapny} />
              </div>
            </div>
            <div className="trestimonials commonBoxShadow">
              <div className="section-title" data-wow-delay="0.1s">
                <h2>Testimonials</h2>
              </div>
              <div className="centalisedTestimonials">
                <Testimonials data={transportComapny} />
              </div>
            </div>
            <div className="faqIntracity commonBoxShadow">
              <section className="faq">
                <div className="container">
                  <div className="section-title" data-wow-delay="0.1s">
                    <h2>FAQ</h2>
                  </div>
                  <FAQ data={transportComapny} />
                </div>
              </section>
            </div>
          </div>

          <div className="row">
            <button
              className="btn btn-primary-one text-white form_one"
              style={{ marginLeft: "15%", marginBottom: "5%" }}
              onClick={() => {
                topFunction();
              }}
            >
              Join Us Now
            </button>
          </div>
          {/* <CommonHomeTab Arr={transportComapny} /> */}
        </>
        :
        <div className="intracityContainer">
          <div className="scrollableContainer">
            <div className="sideNav" onMouseEnter={() => { document.body.style.overflowY = 'hidden' }} onMouseLeave={() => document.body.style.overflowY = 'scroll'}>
              <div className={tabActive === 0 ? "activeNew" : ""} onClick={() => { topFunction2("book"), setTabActive(0) }}>Join Us</div>
              <div className={tabActive === 1 ? "activeNew" : ""} onClick={() => { topFunction2("feature"), setTabActive(1) }}>Features</div>
              <div className={tabActive === 2 ? "activeNew" : ""} onClick={() => { topFunction2("how"), setTabActive(2) }}>How It Works</div>
              <div className={tabActive === 3 ? "activeNew" : ""} onClick={() => { topFunction2("test"), setTabActive(3) }}>Testimonials</div>
              <div className={tabActive === 4 ? "activeNew" : ""} onClick={() => { topFunction2("faq's"), setTabActive(4) }}>FAQ's</div>
            </div>
            <div className="intraCity">
              <div className="customerUrlBox" onMouseOver={() => { setTabActive(0) }}>
                {/* <CustomerRegistration /> */}
                <div className="imgContainer">
                  <img src="truck-images.jpg" />
                </div>
                <div className='customerFormContainer'>
                  <h3 className="customerHeading">Become A Customer</h3>
                  {/* <div className='formPage1Container'>
                            <div className='formPage1Heading'>Contact Person</div>
                            <div className='formPage1'>
                            <input className='form-control' 
                            type={"text"} 
                            placeholder='Full Name'
                            onChange={(e: any) => {
                              onChangehandler(["Name"], e.target.value)
                            }}
                            />                                
                            <br />
                                <input className='form-control' 
                                type={"email"} 
                                placeholder='E-mail'
                                onChange={(e: any) => {
                                  onChangehandler(["email"], e.target.value)
                                }}
                                />
                                <br />
                                <input className='form-control' 
                                type={"password"} 
                                placeholder='Password' 
                                onChange={(e: any) => {
                                  onChangehandler(["password"], e.target.value)
                                }}
                                />
                                <br />
                                <input className='form-control' 
                                type={"number"} 
                                placeholder='Contact No' 
                                onChange={(e: any) =>
                                  onChangehandler(["contactNo"], e.target.value)
                                } 
                                  />
                                <br />
                                <button className='btn btn-primary otpButton' 
                                onClick={()=>{
                                  handelgetOtp()
                                  }}>
                                    Get Otp</button>
                                <br />
                                <input className='form-control otpInput' 
                                type={"number"} 
                                placeholder='Enter Otp'
                                onChange={(e: any) => {
                                  onChangehandler(["otp"], e.target.value)
                                }}
                                />
                            </div>
                        </div> */}
                  <ContactDetailsForm
                    className={"individualWFContactForm"}
                    commonUsedForm={true}
                    submitBtn={true}
                    emailClass={"emailDesign"}
                    formTitle={"individualWFContactForm"}
                  />
                  {/* <div className='changeFormbtns' style={{border:"0.2px solid transparent",marginTop:"0.5%"}}>
                          <div className="btn btn-primary-one text-white puBtn"
                          onClick={handleSubmit}
                          >
                          SignUp</div>
                        </div> */}
                </div>
              </div>
              <div className="customerCommonBox">
                <div className="features commonBoxShadow" onMouseOver={() => { setTabActive(1) }}>
                  <div className="section-title" data-wow-delay="0.1s">
                    <h2>Our Features</h2>
                  </div>
                  <div className="centalisedFeatures">
                    <OurFeatures data={transportComapny} />
                  </div>
                </div>
                <div className="howItWorks commonBoxShadow" onMouseOver={() => { setTabActive(2) }}>
                  <div className="section-title" data-wow-delay="0.1s">
                    <h2>How It Works</h2>
                  </div>
                  <div className="centalisedHowItWorks">
                    <HowItWorks data={transportComapny} />
                  </div>
                </div>
                <div className="trestimonials commonBoxShadow" onMouseOver={() => { setTabActive(3) }}>
                  <div className="section-title" data-wow-delay="0.1s">
                    <h2>Testimonials</h2>
                  </div>
                  <div className="centalisedTestimonials">
                    <Testimonials data={transportComapny} />
                  </div>
                </div>
                <div className="faqIntracity commonBoxShadow" onMouseOver={() => { setTabActive(4) }}>
                  <section className="faq">
                    <div className="container">
                      <div className="section-title wow" data-wow-delay="0.1s">
                        <h2>Frequently Asked Questions</h2>
                      </div>
                      <FAQ data={transportComapny} />
                    </div>
                  </section>
                </div>
              </div>
              <div className="row ml38Percentage mt50">
                <button
                  className="btn btn-primary-one text-white form_one ml170 mt5"
                  onClick={() => {
                    topFunction();
                  }}
                >
                  Join Us Now
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </Fragment>
  );
};
{/* <> */ }
{/* <ZomatoView> */ }
// header={
//   <div style={{ fontSize: "initial" }}>
//     Packages &nbsp;{" "}
//     <i className="fa fa-archive" aria-hidden="true"></i>{" "}
//   </div>
// }
// onClick={() =>
//   this.setState({
//     detailsPopup: true,
//     mobileActive: 4,
//   })
// }
// >
{/* <div> */ }
{/* Packages :{" "} */ }
{/* {this.props.bookingReducer.packages &&
                  this.props.bookingReducer.packages.length} */}
{/* </div> */ }
// </ZomatoView>
// </>
const mapDispatchToProps = {
  //   getCompanyList,
  //   changeCompanyDoc,
  //   createCompanyDocAction,
  //   updateCompanyDocAction,
  //   setCompanyDocAction,
  //   clearCompanyDoc,
  //   getCompany,
  //   setCompanyError,
};

const mapStateToProps = (state: any) => {
  return {
    cities: state.user.allcities,
    companyList: state.company.getIn(["companyList"]),
    company: state.company.get("company_doc"),
    role: state.user.role,
    userCompanyID: state.user.company.id,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerUrl);
