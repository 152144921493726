import { procPackages } from "./processPackages";
import { fetchBranchesList } from "../../../services/branch";

let makeDocketMap = async (packages: any) => {
  let docketMap: any = {};
  // let validCities = new Set()
  let validBranches: any = new Set();
  let totalLoad = 0;
  let boxOptions: any = [];
  packages.forEach(({ _id, packages }: any) => {
    totalLoad += packages.length;
    let packageMap = procPackages(packages);
    docketMap[_id.uid] = packageMap;
    validBranches.add(packages[0].destBranch);
    if (_id.isBox == true) {
      boxOptions.push({ label: _id.uid, value: _id.uid });
    }
  });
  validBranches = Array.from(validBranches).filter((f) => f && f !== null);
  const branches = await fetchBranchesList({ branches: validBranches });
  let cityMap = new Map(
    branches.map((b: any) => [b.address.city._id, b.address.city])
  );
  let branchMap = new Map(branches.map((b: any) => [b._id, b]));

  return {
    validCities: Array.from(cityMap.values()),
    validBranches: Array.from(branchMap.values()),
    docketMap: docketMap,
    boxOptions,
    totalLoad,
  };
};

export { makeDocketMap };
