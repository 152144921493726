import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import "../css/partnersUrl.css"
import { useLocation } from "react-router-dom";
import OurFeatures from "../../../Components/Features/OurFeatures";
import HowItWorks from "../../../Components/How It Works/HowItWorks";
import Testimonials from "../../../Components/Tetimonials/Testimonials";
import FAQ from "../../../Components/FAQ/FAQ";
import TransportCompany from "./FormikForms/TransportCompany/TransportCompany";
import DirectSalesAgent from "./FormikForms/DirectSalesPerson/DirectSalesAgent";
import IndividualWithFleet from "./FormikForms/IndividualWithFleet/IndividualWithFleet";

export const SubPartners = (props: any) => {
    console.log(`SubPartners Props`, props);
    const location: any = useLocation();

    const getAns = () => {
        if (location?.pathname.includes("direct-sales")) {
            return "direct";
        }
        else if (location?.pathname.includes("individual-with-fleet")) {
            return "individual";
        }
        else if (location?.pathname.includes("transport-company")) {
            return "transport";
        }
        return;
    }

    const individualWithoutFleet = {
        "HowItWorks": [{ headings: ["Click on register", "Fill out the detailed form", "Get in touch"] },
        {
            img: ["how-it-works-img1.png",
                "how-it-works-img2.png",
                "how-it-works-img3.png"]
        }],
        "Features": [
            {
                img: ["https://img.icons8.com/bubbles/80/000000/cash-in-hand.png",
                    "https://img.icons8.com/bubbles/80/000000/security-checked.png",
                    "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
                    "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
                    "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
                    "https://img.icons8.com/bubbles/80/000000/conference-call.png"]
            },
            { headings: ["Scale", "24/7 support", "Access to APIs", "Effort-based earning", "Flexibility to choose a time slot", "CCTV surveillance"] },
            {
                content: ["Team up as a franchisee with Kabra, and become a part of our strong network covering 500+ cities and over 7000 pin codes.",
                    "You can reach out to Shadowfax representatives at any point of the day.",
                    "Franchise partners will be given complete access to APIs which is our stronghold and makes logistics solutions seamless and affordable.",
                    "Deliver More, Earn More",
                    "Part-time Delivery Partner - 4 hours/day 6 days a week Full-time Delivery Partner - 8 hours/day 6 days a week.",
                    "We have set up in our facility so that your product is always safe and secure."]
            }],
        "FAQ": [
            {
                question: ["What are the eligibility criteria to enroll in this program ?",
                    "What is my earning potential working with you ?",
                    "What is my earning potential working with you ?",
                    "Will I get any kind of training before I go on-field ?",
                    "What kind of leaves am I entitled to after I have successfully been onboarded ?",
                    "Do I need to deposit any amount with the company after I am successfully onboarded ?"]
            },
            {
                answer: ["To become our Individuals without fleet:You must be 18 years old You should have an android smartphone You should have a vehicle (2/3/4 wheeler) that meets all the applicable laws and safety requirements A valid Driving License A valid ID Proof A valid PAN Card",
                    "Kabra Express is one of India’s leading logistics company. Since the logistics industry is one of the fastest growing industries at present, hence, by working with a company such as Kabra Express, there is always the opportunity for you to increase your earning potential.",
                    "Kabra Express is one of India’s leading logistics company. Since the logistics industry is one of the fastest growing industries at present, hence, by working with a company such as Kabra Express, there is always the opportunity for you to increase your earning potential.",
                    "Kabra Express focuses extensively on training its personnel. After your successful joining, we will provide you with the training required to perform your job well.",
                    "Regardless of our 365 days and 24 hours operations, you are always entitled to 1 week off. However, you can always choose to work on your week-offs to increase your earning potential. Any additional leaves can be taken (as per policy) with prior information.",
                    "No, you need not deposit any amount"
                ]
            }],
        "Testimonials": [{ img: [] }, { name: [] }, { possition: [] }, { company: [] },
        {
            feedback: ["I have been using Kabra Express for a few months now and I am very happy with the service. The delivery is always on time and the drivers are very polite. I would recommend it to anyone looking for a reliable and convenient delivery service.",
                "I have been using Kabra express for the past few months and I am very impressed with their service. I love that I can get all my groceries, food, and even gifts delivered to my doorstep with just a few clicks. Kabra express has made my life so much easier and hassle-free!",
                "I was so skeptical about ordering my groceries online but Kabra Express has changed my mind. They are prompt and efficient & the products are always fresh. I don't have to worry about anything, they take care of it all. Highly recommend!"
            ]
        }]
    }

    const individualWithFleet = {
        "HowItWorks": [{ headings: ["Requirements", "Documents", "Signup process"] },
        {
            img: ["how-it-works-img1.png",
                "how-it-works-img2.png",
                "how-it-works-img3.png"]
        }],
        "Features": [
            {
                img: ["https://img.icons8.com/bubbles/80/000000/cash-in-hand.png",
                    "https://img.icons8.com/bubbles/80/000000/security-checked.png",
                    "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
                    "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
                    "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
                    "https://img.icons8.com/bubbles/80/000000/conference-call.png"]
            },
            { headings: ["You’re in charge", "You pick the time", "Your ride, your city", "Earn as you go", "You’ll be far from bored", "Join the movement"] },
            {
                content: ["There is no one to report to, you are your boss.",
                    "Log in and log out anytime you want. We are live 24x7.",
                    "All deliveries will be made within city limits.",
                    "We offer one of the best compensations in the market.",
                    "Run different orders ranging from deliveries to rides.",
                    "Be a part of the fastest-growing delivery startup."]
            }],
        "FAQ": [
            {
                question: ["How to attach Tata ace or any goods vehicle in Kabra Express ?",
                    "What documents and permits do I need to onboard Kabra Express ?",
                    "How much you can earn per week ?",
                    "Can I refer my friends and colleagues to join Kabra Express ?",
                    "Can I attach my tata ace on a monthly contract or rent mini trucks on monthly basis ?",
                    "How do I know if the courier has delivered the parcel ?"]
            },
            {
                answer: ["Becoming a Kabra Express partner and attaching your truck with us is a very easy process. You can do it with three easy steps from the comfort of your home! Download the Kabra Express Partner Driver App Register with us and submit all your documents within the app Once the documents are verified do a dummy order, You will start getting trips immediately.",
                    "For commercial vehicle attachment following documents are required: RC book | Fitness certificate | Insurance | Pollution Control | Owner/Driver documents: Driving License | PAN card | Bank Details | Address Proof",
                    "Your earnings are dependent on the time you spend on the Kabra Express platform. Longer engagement with the platform leads to more trips which in turn ensures high earnings.",
                    "Yes! We encourage our driver partners to promote Kabra Express among their peers and refer them to join us. In return, they deserve a goodwill bonus on each successful onboarding of all vehicle types with a carrying capacity of 1 ton, 2 ton, and 3 ton.",
                    "You can attach your commercial vehicle to Kabra Express on a daily, weekly, or monthly basis for a part-time or full-time engagement.",
                    "You will receive a text message or email. The recipient will also receive a text message notifying them that the courier is coming (unless the delivery is a surprise)."
                ]
            }],
        "Testimonials": [{ img: [] }, { name: [] }, { possition: [] }, { company: [] },
        {
            feedback: ["I have been using Kabra Express for a few months now and I am very happy with the service. The delivery is always on time and the drivers are very polite. I would recommend it to anyone looking for a reliable and convenient delivery service.",
                "I have been using Kabra express for the past few months and I am very impressed with their service. I love that I can get all my groceries, food, and even gifts delivered to my doorstep with just a few clicks. Kabra express has made my life so much easier and hassle-free!",
                "I was so skeptical about ordering my groceries online but Kabra Express has changed my mind. They are prompt and efficient & the products are always fresh. I don't have to worry about anything, they take care of it all. Highly recommend!"
            ]
        }]
    }

    const transportComapny = {
        "HowItWorks": [{ headings: ["Get quote", "Choose the vehicle", "Real-time tracking"] },
        {
            img: ["how-it-works-img1.png",
                "how-it-works-img2.png",
                "how-it-works-img3.png"]
        }],
        "Features": [
            {
                img: ["https://img.icons8.com/bubbles/80/000000/cash-in-hand.png",
                    "https://img.icons8.com/bubbles/80/000000/security-checked.png",
                    "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
                    "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
                    "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
                    "https://img.icons8.com/bubbles/80/000000/conference-call.png"]
            },
            { headings: ["Complementary Solutions", "Consulting Partners", "Integration Partners", "Referral Partners", "Technology Partners", "CCTV surveillance"] },
            {
                content: ["Our priority is your time. We know how important it is for you to get your things on time, every time.",
                    "We only work with the best quality products to ensure that you get the best experience possible.",
                    "We deliver to your doorstep, so you can just sit back and relax.",
                    "We provide you with the option to pay so that you can pay us only when you receive your product.",
                    "You will be updated and notified at every stage of the packing and moving process so that you know where your product is at all times.",
                    "We have set up in our facility so that your product is always safe and secure."]
            }],
        "FAQ": [
            {
                question: ["How early should I book a courier ?",
                    "Can I choose a specific courier for my delivery ?",
                    "Can I order a specific vehicle ?",
                    "Can I cancel or change my order ?",
                    "Why hasn't anyone been assigned to my order yet ?",
                    "How do I know if the courier has delivered the parcel ?"]
            },
            {
                answer: ["You only need to book 30 minutes in advance if the first delivery is within travel zone 1, one hour in advance if in travel zone 2 and 1.5–2 hours if further out.",
                    "When you fill in the form, you can use the free Preferred Courier service in your online account. You can choose couriers you have used before and whom you have given a 5-star rating. Your chosen courier will receive a message that you would like them to deliver your order. If the courier wants to take the order, he/she clicks on it.",
                    "We don’t know in advance what car the courier is driving. To make sure you get a courier whose car is big enough, specify the dimensions and weight of the item. You will get responses from couriers who have cars big enough to fulfill your order.",
                    "If you change the order after the courier has set off, you will be charged ₹ 200. You can make changes through your online account or by phone, but these changes have to be agreed upon with the courier.",
                    "Our system works based on responses. So far, no couriers have responded to your order. Depending on the day and the route, it can take from one minute to an hour to find a courier.",
                    "You will receive a text message or email. The recipient will also receive a text message notifying them that the courier is coming (unless the delivery is a surprise)."
                ]
            }],
        "Testimonials": [{ img: [] }, { name: [] }, { possition: [] }, { company: [] },
        {
            feedback: ["I have been using Kabra Express for a few months now and I am very happy with the service. The delivery is always on time and the drivers are very polite. I would recommend it to anyone looking for a reliable and convenient delivery service.",
                "I have been using Kabra express for the past few months and I am very impressed with their service. I love that I can get all my groceries, food, and even gifts delivered to my doorstep with just a few clicks. Kabra express has made my life so much easier and hassle-free!",
                "I was so skeptical about ordering my groceries online but Kabra Express has changed my mind. They are prompt and efficient & the products are always fresh. I don't have to worry about anything, they take care of it all. Highly recommend!"
            ]
        }]
    }

    const topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    useEffect(() => {
        console.log(`SubPartners location`, location?.pathname.includes("individual-with-fleet"));
    }, [])

    let customerTransportImageConainer: any = {
        border: "0.2px solid transparent",
        width: "95%",
        margin: "auto",
        marginTop: "20%",
    }

    let customerTransportImageConainerImg: any = {
        border: "0.2px solid transparent",
        width: "100%",
        margin: "auto",
        marginTop: "0.5%",
    }

    return (
        <div>
            <div style={customerTransportImageConainer}>
                <img style={customerTransportImageConainerImg} src='https://i.ibb.co/gFCyznG/6bb6a3473470d9c49b3cf0974f446347-removebg-preview.png' />
            </div>
            {location?.pathname.includes("direct-sales") ?
                <DirectSalesAgent
                    commonUsedForm={true}
                    messageClass={"massageTitle"}
                    commonInputFieldCss={"generalInputField"}
                // submitFunc = {getBasicDetails}
                /> :
                location?.pathname.includes("individual-with-fleet") ?
                    <IndividualWithFleet
                        commonUsedForm={true}
                        messageClass={"massageTitle"}
                        commonInputFieldCss={"generalInputField"}
                    // submitFunc = {getBasicDetails}
                    /> :
                    location?.pathname.includes("transport-company") ?
                        <TransportCompany
                            commonUsedForm={true}
                            messageClass={"massageTitle"}
                            commonInputFieldCss={"generalInputField"}
                        // submitFunc = {getBasicDetails}
                        /> : null
            }
            {getAns() === "direct" &&
                <div className="feturesTabs">
                    <div className="features commonBoxShadow">
                        <div className="section-title" data-wow-delay="0.1s">
                            <h2>Our Features</h2>
                        </div>
                        <div className="centalisedFeatures">
                            <OurFeatures data={individualWithoutFleet} />
                        </div>
                    </div>
                    <div className="howItWorks commonBoxShadow">
                        <div className="section-title" data-wow-delay="0.1s">
                            <h2>How It Works</h2>
                        </div>
                        <div className="centalisedHowItWorks">
                            <HowItWorks data={individualWithoutFleet} />
                        </div>
                    </div>
                    <div className="trestimonials commonBoxShadow">
                        <div className="section-title" data-wow-delay="0.1s">
                            <h2>Testimonials</h2>
                        </div>
                        <div className="centalisedTestimonials">
                            <Testimonials data={individualWithoutFleet} />
                        </div>
                    </div>
                    <div className="faqIntracity commonBoxShadow">
                        <section className="faq">
                            <div className="container">
                                <div className="section-title" data-wow-delay="0.1s">
                                    <h2>Frequently Asked Questions</h2>
                                </div>
                                <FAQ data={individualWithoutFleet} />
                            </div>
                        </section>
                    </div>
                </div>
            }
            {getAns() === "individual" &&
                <div className="feturesTabs">
                    <div className="features commonBoxShadow">
                        <div className="section-title" data-wow-delay="0.1s">
                            <h2>Our Features</h2>
                        </div>
                        <div className="centalisedFeatures">
                            <OurFeatures data={individualWithFleet} />
                        </div>
                    </div>
                    <div className="howItWorks commonBoxShadow">
                        <div className="section-title" data-wow-delay="0.1s">
                            <h2>How It Works</h2>
                        </div>
                        <div className="centalisedHowItWorks">
                            <HowItWorks data={individualWithFleet} />
                        </div>
                    </div>
                    <div className="trestimonials commonBoxShadow">
                        <div className="section-title" data-wow-delay="0.1s">
                            <h2>Testimonials</h2>
                        </div>
                        <div className="centalisedTestimonials">
                            <Testimonials data={individualWithFleet} />
                        </div>
                    </div>
                    <div className="faqIntracity commonBoxShadow">
                        <section className="faq">
                            <div className="container">
                                <div className="section-title" data-wow-delay="0.1s">
                                    <h2>Frequently Asked Questions</h2>
                                </div>
                                <FAQ data={individualWithFleet} />
                            </div>
                        </section>
                    </div>
                </div>
            }
            {getAns() === "transport" &&
                <div className="feturesTabs">
                    <div className="features commonBoxShadow">
                        <div className="section-title" data-wow-delay="0.1s">
                            <h2>Our Features</h2>
                        </div>
                        <div className="centalisedFeatures">
                            <OurFeatures data={transportComapny} />
                        </div>
                    </div>
                    <div className="howItWorks commonBoxShadow">
                        <div className="section-title" data-wow-delay="0.1s">
                            <h2>How It Works</h2>
                        </div>
                        <div className="centalisedHowItWorks">
                            <HowItWorks data={transportComapny} />
                        </div>
                    </div>
                    <div className="trestimonials commonBoxShadow">
                        <div className="section-title" data-wow-delay="0.1s">
                            <h2>Testimonials</h2>
                        </div>
                        <div className="centalisedTestimonials">
                            <Testimonials data={transportComapny} />
                        </div>
                    </div>
                    <div className="faqIntracity commonBoxShadow">
                        <section className="faq">
                            <div className="container">
                                <div className="section-title" data-wow-delay="0.1s">
                                    <h2>Frequently Asked Questions</h2>
                                </div>
                                <FAQ data={transportComapny} />
                            </div>
                        </section>
                    </div>
                </div>
            }
            <div className="row">
                <button
                    className="btn btn-primary-one text-white form_one"
                    style={{ marginLeft: "15%", marginBottom: "5%" }}
                    onClick={() => {
                        topFunction();
                    }}
                >
                    Join Us Now
                </button>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    user: state?.user,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SubPartners)