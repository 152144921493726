import * as Yup from "yup";
import { fromJS } from "immutable";

export const expense_Boolean = (val) => ({
  expenseType: val,
  subExpense: val,
  amt: val,
  route: val,
  assetType: val,
  assetBalance: val,
});

export const expenseValidationSchema = fromJS({
  expenseType: Yup.object().shape({
    value: Yup.string().required("This is a required field"),
  }),
  sub: Yup.string().required("This is a required field"),
  subExpense: Yup.object().shape({ 
    label:Yup.string().required("This is a required field"),
    value:Yup.string().required("This is a required field"),
}),
  amt: Yup.string().required("This is a required field"),
  route: Yup.object().shape({
    value: Yup.string().required("This is a required field"),
  }),
  assetType: Yup.object().shape({
    value: Yup.string().required("This is a required field"),
  }),
  assetType: Yup.object().shape({
    value: Yup.string().required("This is a required field"),
  }),
});
Yup.addMethod(Yup.mixed, 'check', function(key, message, func) {
  message = message || "Closing kms shd be more";
  func = func || function(value) {
    return value > this.options.context[key];
  };

  return this.test("check", message, func);
});
export const finalExpenseValidation = Yup.object({
  ref: Yup.string().required("Please Select An Expense Type"),
  // amt: Yup.string().required('Please Enter Amount'),
  sub: Yup.string().required("Please Select An Expense Type"),
  sub_val: Yup.string().when("sub", {
    is: (val) => val == "G",
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.required("Please Select Sub Type"),
  }),
  is_route: Yup.boolean(),
  amt: Yup.string().when("sub", {
    is: (val) => val != "F",
    then: (schema) => schema.required("Please Enter Amount"),
  }),
  route: Yup.string().when("is_route", {
    is: true,
    then: (schema) => schema.required("Please Select Route"),
  }),
  op: Yup.string().when("sub", {
    is: (val) => val === "F",
    then: (schema) => schema.notRequired(), //.required('Please Enter Opening Kilometers')
  }),
  cl: Yup.string().when("sub", {
    is: (val) => val === "F",
    then: (schema) => schema
      .required("Please Enter Closing Kilometers")
      .test("op", "Closing Kilometres Should be Greater than Opening", function(
        value
      ) {
        const { op } = this.parent;
        console.log("op: ", parseInt(op, 10));
        console.log("value: ", value);
        console.log("value > op: ", parseInt(value, 10) > parseInt(op, 10));
        return parseInt(value, 10) > (parseInt(op, 10) || 0);
      }),
  }),
  ppl: Yup.string().when("sub", {
    is: (val) => val === "F",
    then: (schema) => schema.required("Please Enter Price Per Litre"),
  }),
  driver: Yup.string().when("sub", {
    is: (val) => val === "F",
    then: (schema) => schema
      .required("Please Enter Driver's Number")
      .min(10, "Contact Should Be 10 digits")
      .max(10, "Contact Should Be 10 digits"),
  }),
  dName: Yup.string().when("sub", {
    is: (val) => val === "F",
    then: (schema) => schema.required("Please Enter Valid Driver's Number"),
  }),
  fuelQty: Yup.string().when("sub", {
    is: (val) => val === "F",
    then: (schema) => schema.required("Please Enter Fuel Quantity"),
  }),
});

export const fuel_Boolean = (val) => ({
  op: val,
  cl: val,
  ppl: val,
  rnum: val,
  driver: val,
  dName: val,
  remarks: val,
  fuelQty: val,
  remarks: val,
});

export const fuelExpenseValidation = fromJS({
  op: Yup.string()
    .required("This is a required field")
    .min(1, "It can't be zero")
    .max(99999999, "Too long"),
  cl: Yup.string()
    .required("This is a required field")
    .min(1, "It can't be zero")
    .max(99999999, "Too long"),
  ppl: Yup.string()
    .required("This is a required field")
    .min(1, "It can't be zero")
    .max(9999999, "Too long"),
  rnum: Yup.string()
    .required("This is a required field")
    .min(1, "It can't be zero")
    .max(50, "Reg number can't be too long"),
  driver: Yup.string()
    .required("This is a required field")
    .min(1, "Contact must be 10 digits")
    .max(10, "Contact must be 10 digits"),
  dName: Yup.string()
    .required("This is a required field")
    .min(1, "Contact must be 10 digits")
    .max(10, "Contact must be 10 digits"),
  remarks: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters"),
  fuelQty: Yup.string()
    .required("This is a required field")
    .min(1, "It can't be zero")
    .max(10000, "Too long"),
  paymentType: Yup.mixed().when("paymentMode", {
    is: (val) => val === "nonCash",
    then: (schema) => schema.required("Payment Type in required !"),
  }),
  transactionId: Yup.mixed().when("paymentMode", {
    is: (val) => val === "nonCash",
    then: (schema) => schema.required("transaction Id in required !"),
  }),
  transactionImage: Yup.mixed().when("paymentMode", {
    is: (val) => val === "nonCash",
    then: (schema) => schema.required("transaction Image in required !"),
  }),
  vendor: Yup.string().required("This is a required field"),
});

export const validateExpense = (schema, obj) => {
  console.log("schema, obj: ", schema, obj);
  try {
    const res = schema.validateSync(obj);
    return false;
  } catch (err) {
    console.log(err);
    return err.message;
  }
};
