import React, { useEffect } from "react";
import { withRouter } from "../../../hoc/withRouter";
// import {withRouter} from "react-router-dom";

const CustomerFirst = (props: any) => {
  useEffect(() => {
    props.navigate({
      pathname: "/customer/report",
      customerId: props.params.customerId,
    });
  }, []);
  return <div></div>;
};
export default 
withRouter(
  CustomerFirst
);
