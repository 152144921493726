import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { ButtonDefault, ButtonPrimary } from './../../../Components/Button/Buttoncontainer';
import showMessage, { showHttpError, success } from "../../../utils/message";
import { Dialog, DialogTitle, DialogContent, DialogActions } from '../../../Components/Dialog/DialogComponent';
import { GetRatingFromCustomer, cancelIntracityOrder } from "../../../services/customer";
import { getFeedbackOfCustomerComplaint } from "../../../services/complaint";
import { CalendarIcon, CallIcon, CashIcon, DistanceIcon, DocketNumberIcon, GalleryIcon, HistoryIcon, HoursIcon, MapLocationIcon, RupeeIcon, ServiceTypeIcon, TimeToReachIcon, TimelineIcon, VehicleIcon, UserIcon, BackIcon, PrintIcon, DownloadIcon, CancelIcon, MessageAltIcon, SendIcon, MovingTruckIcon, ChatIcon, LastMessageIcon, DownIcon, UpIcon } from "../../../newSrc/constants/icons";
import { payOnlineBycustomer } from "../../../services/verifyPOD";
import { addComplaintForCustomer, processPartLoadRateApprovalByCustomer } from "../../../services/customersection";
import getImageUrl from "../../../utils/getImageUrl";
import ImageInput from "../../../Components/ImageInput";
import PageTitle from "../../../Components/Title/PageTitle";
import { printBuilty2 } from '../../operations/bookutils/steps/builty';
import formatDate from "../../../services/FormatDate";
import ShareToWhatsapp from "../../sharing/WhatsAppSharing";
import { Rating, useMediaQuery, useTheme } from "@mui/material";
import { Button } from "../../../newSrc/components/UI";
import OnlyImage from "../../Image/OnlyImage";
import ViewShipmentTrack from "../../CustomerFleetOrders/ViewShipmentTrack";
import { getComplaintStatus, getComplaintType } from "../../../newSrc/utils/helper";
import { MdVerified } from "react-icons/md";
import AddressComponent from "./AddressComponent";
import ComplaintButtons from "./ComplaintButtons";

const CustomerTrackingView = (newProps: any) => {

    const timeConvert = (time: any) => {
        const timeString12hr = new Date(time).toLocaleTimeString("en-US", {
            hour12: true,
            hour: "numeric",
            minute: "numeric",
        });
        return timeString12hr;
    }

    interface PackgePopup {
        serviceType: any,
        placeId?: any
    }
    const CustomerData: any = newProps?.data?.customerTrackingData?.orderData;
    const navigate = useNavigate()
    const [isPickupInfoOpen, setIsPickupInfoOpen] = useState(0);
    const [packageDataInfo, setPackageDataInfo] = useState<PackgePopup>({ serviceType: "", placeId: "" });
    const [logs, setLogs] = useState<any>([]);
    const [activeDest, setActiveDest] = useState<any>(null);
    const [statusForOrder, setStatusForOrder] = useState("");
    const [ratingFromUser, setRatingFromUser] = useState(0);
    const [reviewFromUser, setReviewFromUser] = useState("");
    const [latComplaint, setLastComplaint] = useState<any>();
    const [isDialogOpen, setIsDialogOpen] = useState(0);
    const [complaintRaised, setComplaintRaised] = useState(false);
    const [paymentModechange, setPaymentModechange] = useState(false);
    const [selectRateOfPartLoad, setSelectRateOfPartLoad] = useState(false);
    const [cancelOrder, setCancelOrder] = useState(false);
    const [customerOrderNoList, setCustomerOrderNoList] = useState<any>();
    const [description, setDescription] = useState("");
    const [customerDocket, setCustomerDocket] = useState<any>();
    const [complaintType, setComplaintType] = useState("");
    const [image, setImage] = useState("");
    const [customerComplaintRefOrderNo, setCustomerComplaintRefOrderNo] = useState<any>();
    const [handleFeedbackButton, setHandleFeedbackButton] = useState(true);
    const [onLineQr, setOnlineQr] = useState("Paytm");
    const [selectPaymentOptionBtn, setSelectPaymentOptionBtn] = useState("cash");
    const [transactionId, setTransactionId] = useState("")
    const [cancelOrderRemarks, setCancelOrderRemarks] = useState('');
    const [_, setOrderStarted] = useState(false)
    const [driverImageUrl, setDriverImageUrl] = useState();
    const [selectedRateInPopup, setSelectedRateInPopup] = useState(0);
    const [selectedRateInId, setSelectedRateInId] = useState("");
    const [selectedRateRemarks, setSelectedRateRemarks] = useState("");

    const setAdressPickUpInfo = (val: any) => {
        setIsPickupInfoOpen(val);
    }

    const setAdressPackageData = (obj: any) => {
        setPackageDataInfo(obj)
    }

    const setIsDialogOpenFunc = (num: number) => {
        setIsDialogOpen(num)
    }
    const secondsToHms = (d: any) => {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var hDisplay = h > 0 ? h + (h == 1 ? " Hr, " : " Hr, ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " Min " : " Min ") : "";
        return hDisplay + mDisplay;
    }

    const ans = CustomerData?.route &&
        CustomerData?.route.sort((a: any, b: any) => {
            return a?.placeIndex - b?.placeIndex
        }) || []

    const someCoords = CustomerData?.route && [CustomerData?.route[0]?.placeId, CustomerData?.route[1]?.placeId];

    const handleDetailedLogsForCustomer = () => {
        console.log("Clicked From New", newProps.data.customerTrackingData.source)
        if (newProps.data.customerTrackingData.source === "Customer-Authenticated-BranchOrder") {
            navigate("/CustomerDashBoard/detailedLogs", {
                state: {
                    from: "Customer-Order-Track",
                    logsInfo: logs,
                    view: "Branch-Order"
                }
            })
        }
        else if (newProps.data.customerTrackingData.source === "Customer-Authenticated-CustomerOrder") {
            navigate("/CustomerDashBoard/detailedLogs", {
                state: {
                    from: "Customer-Order-Track",
                    BranchDetails: CustomerData,
                    logsInfo: logs,
                    view: "Customer-Order"
                }
            })
        }
        else if (newProps.data.customerTrackingData.source === "Customer-NotAuthenticated-CustomerOrder") {
            navigate("/detailedLogs", {
                state: {
                    from: "Customer-Order-Track",
                    BranchDetails: CustomerData,
                    logsInfo: logs,
                    view: "Customer-Order"
                }
            })
        }
        else if (newProps.data.customerTrackingData.source === "Customer-NotAuthenticated-BranchOrder") {
            navigate("/detailedLogs", {
                state: {
                    from: "Branch-Order-Track",
                    logsInfo: logs,
                    view: "Branch-Order"
                }
            })
        }
        else if (newProps.data.customerTrackingData.source === "Branch-Authenticated-BranchOrder") {
            navigate("/dashboard/detailedLogs", {
                state: {
                    from: "Branch-Order-Track",
                    logsInfo: logs,
                    view: "Branch-Order"
                }
            })
        }
        {
            newProps.data.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" &&
                navigate("/dashboard/detailedLogs", {
                    state: {
                        from: "Branch-Order-Track",
                        BranchDetails: CustomerData,
                        logsInfo: logs,
                        view: "Customer-Order"
                    }
                })
        }
    }

    const handleRatings = async (value: any) => {
        if (reviewFromUser.length < 2) {
            return showHttpError("All Fields Are Mandatory !")
        }
        if (ratingFromUser === 0) {
            return showHttpError("All Fields Are Mandatory !")
        }
        try {
            let payload = {
                feedbackType: "REV",
                feedbackTitle: reviewFromUser,
                shipmentNumber: value,
                rating: ratingFromUser
            }
            await GetRatingFromCustomer(payload);
            navigate("pastBookings")
        } catch (error: any) {
            if (error.message === "You cannot set review for this booking!") {
                alert("You are not eleigible to give review Or rating. Thank You !")
                setReviewFromUser("")
                setRatingFromUser(0)
            } else {
                alert("You are not eleigible to give review Or rating. Thank You !")
                setReviewFromUser("")
                setRatingFromUser(0)
            }
        }
    }

    const handleIndividualChat = () => {
        {
            newProps.data.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" ?
                (alert("This Chat Module Only Valid For Customer Kindly Login as a Customer !"))
                :
                (
                    navigate("customerChat")
                )
        }
    }

    const handleIndividualCustomerOrderChat = (extraData: any, feedbackNumber: any, bookingtype?: any) => {
        navigate("/customerChat/individualChat", {
            state: {
                from: "redirect-chat-from-customerchat-list",
                data: { feedbackNumber },
                anotherData: extraData,
                bookingType: bookingtype
            }
        })
    }

    const raiseComplaint = async () => {
        newProps?.data?.showLoader();
        try {
            let payloadForcomplaint: any = {
                feedbackType: "COMP",
                complaintType: complaintType,
                shipmentNumber: customerDocket.toString(),
                messageText: description,
                customerPackageOrder: customerComplaintRefOrderNo
            }
            let TempResponse = await addComplaintForCustomer(payloadForcomplaint);
            if (TempResponse.feedbackNumber !== "") {
                setComplaintRaised(true);
                showMessage("Your Complaint has been created, To see all Complaints Click on the Message Icon", success, 3000)
            }
            newProps?.data?.hideLoader();
        } catch (error) {
            newProps?.data?.hideLoader();
            let newError: any = error;
            alert(newError.message);
        }
    }

    const getRaringResponse = async () => {
        newProps?.data?.showLoader();
        try {
            let Payload: any = { "feedbackNumber": `C-${CustomerData?.shipmentNumber}`, "feedbackType": "REV", customer: newProps?.data?.user?._id }
            let ans = await getFeedbackOfCustomerComplaint(Payload);
            if (ans.length > 0) {
                setRatingFromUser(ans[0].rating)
                setReviewFromUser(ans[0].feedbackTitle)
                setHandleFeedbackButton(false)
            }
            console.log("ans 2020", ans)
            newProps?.data?.hideLoader();
        } catch (error) {
            newProps?.data?.hideLoader();
            showHttpError(error);
            console.log("error 2020", error)
        }
    }

    const handleCustomerOnlinePayment = async () => {
        newProps?.data?.showLoader();
        try {
            let payload = {
                "shipmentNumber": CustomerData?.shipmentNumber,
                "nonCashPmntTypeId": "60d19948c8140f31b7f89a8d",
                "txnId": transactionId,
                "settledCollectedAmount": CustomerData?.gross
            }
            await payOnlineBycustomer(payload);
            setTransactionId("");
            showMessage("Online Payment Request Created, Kindly Wait For confirmation !", success, 3000)
            newProps?.data?.hideLoader();
        } catch (error) {
            newProps?.data?.hideLoader();
            showHttpError(error);
        }
    }

    const handleCustomerPartLoadRateApproval = async (id: any) => {
        console.log(`newProps?.data sdfbadfb`, CustomerData)
        newProps?.data?.showLoader();
        try {
            let payload = {
                remarks: selectedRateRemarks,
                status: "A"
            }
            await processPartLoadRateApprovalByCustomer(id, payload);
            showMessage(`Thank you for Accepting the bid, Transporter will contact you soon ~!`)
            newProps?.data?.hideLoader();
            setSelectedRateInId("");
            setSelectedRateInPopup(0);
            setSelectedRateRemarks("");
            navigate("pastBookings")
        } catch (error) {
            newProps?.data?.hideLoader();
            showHttpError(error);
        }
    }

    const cancelIntracityBooking = () => {
        newProps?.data?.showLoader();
        try {
            let payload = {
                "reqId": CustomerData?._id,
                "remark": cancelOrderRemarks,
                "reqType": "CC"
            }
            let response = cancelIntracityOrder(payload)
            showMessage(`${response} cancelIntracityOrder Api Called`, success)
            navigate(newProps?.data?.customerTrackingData?.backToOriginPath)
            newProps?.data?.hideLoader();
        } catch (error) {
            newProps?.data?.hideLoader();
            showHttpError(error)
        }
    }

    useEffect(() => {
        setLogs(CustomerData?.logs);
        if (CustomerData?.feedbacks?.complaints?.length > 0) {
            let Temp2: any = CustomerData?.feedbacks?.complaints
            setLastComplaint(Temp2[Temp2.length - 1]);
        }
        showMessage("Order Tracked Succesfully", success, 2000);
    }, [])

    useEffect(() => {
        if (CustomerData?.orderStatus === "D") {
            getRaringResponse();
        }
    }, [newProps.data.customerTrackingData])

    useEffect(() => {
        if (isDialogOpen === 0) {
            setComplaintType("")
        }
        if (isDialogOpen === 1) {
            setComplaintType("PM")
        }
        if (isDialogOpen === 2) {
            setComplaintType("PF")
        }
        if (isDialogOpen === 3) {
            setComplaintType("DMG")
        }
        if (isDialogOpen === 4) {
            setComplaintType("MB")
        }
        if (isDialogOpen === 5) {
            setComplaintType("LD")
        }
        if (isDialogOpen === 6) {
            setComplaintType("OT")
        }
    }, [isDialogOpen])

    const getImageOfDriver = async () => {
        newProps?.data?.showLoader();
        try {
            console.log("driver image response:", CustomerData);
            let response = await getImageUrl(CustomerData?.driver?.userProfile?.image);
            console.log("driver image response:", response, CustomerData, CustomerData?.driver?.userProfile?.image);
            setDriverImageUrl(response);
            newProps?.data?.hideLoader();
        } catch (error) {
            newProps?.data?.hideLoader();
            showHttpError(error);
        }
    }

    useEffect(() => {
        getImageOfDriver();
    }, [])

    useEffect(() => {
        if (CustomerData?.orderStatus === null || CustomerData?.orderStatus === "BR") {
            setStatusForOrder("Upcoming")
        }
        if (CustomerData?.orderStatus === "CC") {
            setStatusForOrder("Cancelled")
        }
        if (CustomerData?.orderStatus === "CRD") {
            setStatusForOrder("Cancel BY Driver")
        }
        if (CustomerData?.orderStatus === "B" || CustomerData?.orderStatus === "I") {
            setStatusForOrder("OnGoing")
        }
        if (CustomerData?.orderStatus === "D") {
            setStatusForOrder("Delivered")
        }

        setCustomerDocket(CustomerData?.shipmentNumber);

        if (CustomerData?.startOrder) {
            newProps.data.getCustomerDocketLocation(CustomerData?.driver?._id)
        }
        let TempCustomerOrderNoList: any = [];
        {
            CustomerData?.packages?.map((elem: any) => {
                TempCustomerOrderNoList.push({ key: elem?.customerPkgIdentifier, value: elem?.customerPackageOrder })
            })
        }
        setCustomerOrderNoList(TempCustomerOrderNoList);
    }, [])

    useEffect(() => {
        if (CustomerData.route && CustomerData.route.length > 0) {
            let estimation = CustomerData?.route.sort((a: any, b: any) => {
                return a?.placeIndex - b?.placeIndex
            }) || []

            if (CustomerData?.startOrder) {
                setOrderStarted(CustomerData?.startOrder)
                setActiveDest(estimation.find((x: any) => !x.checkIn && !x.checkOut)?.placeIndex)
            }
            newProps?.data?.setCustomerBookingTracking({
                route: estimation,
                lastStation: activeDest
            })

        }
    }, [CustomerData?.route])

    const theme = useTheme();
    const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
    const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
    const mobileView = isMobile1 || isMobile2
    const [isLastActivityOpen, setIsLastActivityOpen] = useState(() => false);
    const [isComplaintsOpen, setIsComplaintOpen] = useState(() => false);
    const [isLastComplaintOpen, setIsLastComplaintOpen] = useState(() => false);

    return (
        <>
            {mobileView && newProps.data.customerTrackingData.source !== "Customer-NotAuthenticated-CustomerOrder" ?
                <div className="p-1 pt-0 bg-white-900 flex ai-center gap-8 relative fs-heading">
                    <BackIcon className="text-primary-900" onClick={() => navigate(newProps?.data?.customerTrackingData?.backToOriginPath)} />
                    <div className="" style={{ position: "absolute", left: "50%", transform: "translateX(-50%)" }}>
                        <PageTitle title="Live Tracking" className="bg-transparent text-primary-900" style={{ fontSize: "clamp(1.75rem, 3vw, 2rem)" }} />
                    </div>
                </div>
                :
                <div className="p-1 bg-white-900">
                    <PageTitle title="Live Tracking" className="fs-heading bg-transparent text-primary-900" style={{ fontSize: "clamp(22px, 3vw, 24px)" }} />
                </div>
            }
            <div className={`${newProps.data.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" ? 'trackingForBranchPersonContainer' : `trackingForCustomerContainer`} br-4 p-1 max-w-1440 border-white shadow-small`}>
                <div className="flex ai-center jc-end gap-8 flex-wrap relative">
                    {newProps.data.customerTrackingData.source === "Customer-NotAuthenticated-CustomerOrder" &&
                        <button className="flex-center fs-link bg-primary-400 text-white-900 p-8 border-none outline-none br-4" onClick={() => { navigate("/") }} style={{ height: "40px" }}><BackIcon /></button>
                    }
                    {!mobileView && newProps?.data?.customerTrackingData?.source !== "Customer-NotAuthenticated-CustomerOrder" &&
                        <ButtonPrimary
                            onClick={() => navigate(newProps?.data?.customerTrackingData?.backToOriginPath)}
                            className="button-primary-primary flex-center fs-link p-8 fs-link min-w-auto"
                            style={{ height: "40px" }}
                        >
                            <BackIcon />
                        </ButtonPrimary>
                    }
                    {newProps.data.customerTrackingData.source === "Customer-Authenticated-CustomerOrder"
                        || newProps.data.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" ?
                        <div className="flex jc-end gap-8">
                            <button className="bg-transparent border-none" onClick={() => {
                                const routes = CustomerData.route
                                console.log("routes :", routes)
                                const firstPlace = routes.find((route: { placeIndex: number; }) => route.placeIndex == 1)
                                const lastPlace = routes.sort((routeA: any, routeB: any) => routeB.placeIndex - routeA.placeIndex)[0]
                                let printData = CustomerData;
                                printData.firstPlace = firstPlace?.placeId
                                printData.lastPlace = lastPlace?.placeId
                                printData.firstCustomer = firstPlace?.customer
                                printData.lastCustomer = lastPlace?.customer

                                printBuilty2({ ...printData, actualBookingType: printData.type });
                            }}><PrintIcon style={{ fontSize: "x-large" }} className="text-primary-400" /></button>
                            <button className="bg-transparent border-none"><DownloadIcon style={{ fontSize: "x-large" }} className="text-primary-400" /></button>
                            <ShareToWhatsapp docketId={CustomerData?._id} />
                            <button className="bg-transparent border-none" onClick={() => { setCancelOrder(true) }}><CancelIcon style={{ fontSize: "xx-large" }} className="text-danger-400" /></button>
                        </div>
                        : <></>
                    }
                    <Dialog
                        open={cancelOrder}
                        maxWidth={"xl"}
                        onClose={() => {
                            setCancelOrder(false)
                        }}
                    >
                        <div className='tac bold'>
                            <DialogTitle
                                style={{ textAlign: "center", fontSize: "18px" }}
                                id="draggable-dialog-title"
                            >
                                Are You Sure You Want To Cancel Order
                            </DialogTitle>
                        </div>
                        <DialogContent>
                            <div>
                                <input
                                    className="br-4 border-white py-8 px-1 w-100"
                                    placeholder="Reason For Cancelation Remark"
                                    value={cancelOrderRemarks}
                                    onChange={(e: any) => setCancelOrderRemarks(e.target.value)}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <div className='onLinePkgbtnMobile'>
                                <ButtonDefault className={`button-danger-primary fs-popup-btn`} onClick={() => {
                                    cancelIntracityBooking(), setCancelOrder(false)
                                }}>
                                    Cancel Order
                                </ButtonDefault>
                                <ButtonDefault className={`button-danger-secondary fs-popup-btn`} onClick={() => {
                                    setCancelOrder(false)
                                }}>
                                    Close
                                </ButtonDefault>
                            </div>
                        </DialogActions>
                    </Dialog>
                </div>

                <div className="br-4 border-white mt-1 shadow-small p-1">
                    <div className=''>
                        {mobileView ?
                            <div className='grid gap-1'>
                                <div className="two-column gap-8">
                                    <div className="flex ai-center gap-4 fs-link">
                                        <span style={{ width: "auto" }}><CalendarIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />{" "}</span>
                                        <p className="m-w-auto">
                                            {formatDate(CustomerData?.createdAt)}
                                        </p>
                                    </div>
                                    <div className="flex ai-center gap-4 fs-link">
                                        <span style={{ width: "auto" }}><HoursIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />{" "}</span>
                                        <p className="m-w-auto">
                                            {timeConvert(CustomerData?.createdAt)}
                                        </p>
                                    </div>
                                </div>
                                <div className="two-column gap-8">
                                    <div className="flex ai-center gap-4 fs-link">
                                        <span style={{ width: "auto" }}><DocketNumberIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} /></span>
                                        <p className="m-w-auto">
                                            {" "}{CustomerData?.shipmentNumber}
                                        </p>
                                    </div>
                                    <div className="flex ai-center gap-4 fs-link">
                                        <span style={{ width: "auto" }}><TimelineIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />{" "}</span>
                                        <p className="m-w-auto">
                                            {" "}{statusForOrder}
                                        </p>
                                    </div>
                                </div>
                                <div className="two-column gap-8">
                                    <div className="flex ai-center gap-4 fs-link">
                                        <span style={{ width: "auto" }}><ServiceTypeIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />{" "}</span>
                                        <p className="m-w-auto">
                                            {" "} {CustomerData?.type}
                                        </p>
                                    </div>
                                    <div className="flex ai-center gap-4 fs-link">
                                        <span className="m-w-auto" style={{ color: "var(--clr-primary-400)" }}><MapLocationIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />{" "}</span>
                                        <p className="m-w-auto">
                                            {" "}{CustomerData?.route?.length}
                                        </p>
                                    </div>
                                </div>
                                <div className="two-column gap-8">
                                    {CustomerData?.route?.length > 0 &&
                                        <div className="flex ai-center gap-4 fs-link">
                                            <span style={{ width: "auto" }}><VehicleIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />{" "}</span>
                                            <p className="m-w-auto">
                                                {" "} {ans[ans?.length - 1]?.distance / 1000} KM
                                            </p>
                                        </div>
                                    }
                                    <div className="flex ai-center gap-4 fs-link">
                                        <span style={{ width: "auto" }}><TimeToReachIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />{" "}</span>
                                        <p className="m-w-auto">
                                            {" "}{secondsToHms(ans[ans?.length - 1]?.duration)}
                                        </p>
                                    </div>
                                </div>
                                <div className="two-column gap-8">
                                    <div className="flex ai-center gap-4 fs-link">
                                        <span style={{ width: "auto" }}><CashIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />{" "}</span>
                                        {statusForOrder === "Cancelled" ?
                                            <p className='paymentOptionContainer m-w-auto'>
                                                <div>
                                                    Order Cancelled
                                                </div>
                                            </p>
                                            :
                                            <p className="m-w-auto">
                                                {CustomerData?.settled ?
                                                    <div>
                                                        Settled
                                                    </div>
                                                    :
                                                    <div>
                                                        Unsettled
                                                    </div>
                                                }</p>
                                        }
                                    </div>
                                    <div className="flex ai-center gap-4 fs-link">
                                        <span style={{ width: "auto" }}><RupeeIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />{" "}</span>
                                        {CustomerData?.gross ? <p>{CustomerData?.gross}</p> :
                                            <p className="m-w-auto">
                                                {" "}
                                                {
                                                    (CustomerData?.type === "PartLoad" || CustomerData?.status === "P") &&
                                                        CustomerData?.customerBookingGrossReq?.length === 0 ? <ButtonPrimary onClick={() => setSelectRateOfPartLoad(true)} style={{ backgroundColor: "var(--clr-primary-400)", width: "max-content" }}>Select Rate</ButtonPrimary> : <Button variant="primary" action="primary" disabled>Awaited</Button>}
                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='tracking-module-general-info five-column t-three-column'>
                                <div className="flex ai-center gap-4 fs-link">
                                    <span className="flex-shrink-0"><CalendarIcon style={{ backgroundColor: "white", color: "var(--clr-primary-400)" }} />{" "}</span>
                                    <p>
                                        {formatDate(CustomerData?.createdAt)}
                                    </p>
                                </div>
                                <div className="flex ai-center gap-4 fs-link">
                                    <span className="flex-shrink-0"><DocketNumberIcon style={{ backgroundColor: "white", color: "var(--clr-primary-400)" }} /></span>
                                    <p>
                                        {" "}{CustomerData?.shipmentNumber}
                                    </p>
                                </div>
                                <div className="flex ai-center gap-4 fs-link">
                                    <span className="flex-shrink-0"><ServiceTypeIcon style={{ backgroundColor: "white", color: "var(--clr-primary-400)" }} />{" "}</span>
                                    <p>
                                        {" "} {CustomerData?.type}
                                    </p>
                                </div>
                                <div className="flex ai-center gap-4 fs-link">
                                    <span style={{ fontSize: "x-large", flexShrink: 0, padding: '1%', color: "var(--clr-primary-400)" }}><MapLocationIcon style={{ backgroundColor: "white", color: "var(--clr-primary-400)" }} />{" "}</span>
                                    <p>
                                        {" "}{CustomerData?.route?.length}
                                    </p>
                                </div>
                                <div className="flex ai-center gap-4 fs-link">
                                    <span className="flex-shrink-0"><CashIcon style={{ backgroundColor: "white", color: "var(--clr-primary-400)" }} />{" "}</span>
                                    {statusForOrder === "Cancelled" ?
                                        <p className='paymentOptionContainer'>
                                            <div>
                                                Order Cancelled
                                            </div>
                                        </p>
                                        :
                                        <p className='paymentOptionContainer'>
                                            {CustomerData?.settled ?
                                                <div>
                                                    {CustomerData?.paymentType}
                                                </div>
                                                :
                                                <div className="ownBtn">
                                                    <button className={selectPaymentOptionBtn === "cash" ? "activeButton" : ""} onClick={() => { setSelectPaymentOptionBtn("cash") }} > Cash </button>
                                                    <button className={selectPaymentOptionBtn === "online" ? "activeButton" : ""} onClick={() => { setSelectPaymentOptionBtn("online"), setPaymentModechange(true) }}> Online </button>
                                                </div>
                                            }
                                        </p>
                                    }
                                </div>
                                <div className="flex ai-center gap-4 fs-link">
                                    <span className="flex-shrink-0"><HoursIcon style={{ backgroundColor: "white", color: "var(--clr-primary-400)" }} />{" "}</span>
                                    <p>
                                        {timeConvert(CustomerData?.createdAt)}
                                    </p>
                                </div>
                                <div className="flex ai-center gap-4 fs-link">
                                    <span className="flex-shrink-0"><TimelineIcon style={{ backgroundColor: "white", color: "var(--clr-primary-400)" }} />{" "}</span>
                                    <p>
                                        {" "}{statusForOrder}
                                    </p>

                                </div>
                                <div className="flex ai-center gap-4 fs-link">
                                    <span className="flex-shrink-0"><TimeToReachIcon style={{ backgroundColor: "white", color: "var(--clr-primary-400)" }} />{" "}</span>
                                    <p>
                                        {" "}{secondsToHms(ans[ans?.length - 1]?.duration)}
                                    </p>
                                </div>
                                {CustomerData?.route?.length > 0 &&
                                    <div className="flex ai-center gap-4 fs-link">
                                        <span className="flex-shrink-0"><DistanceIcon style={{ backgroundColor: "white", color: "var(--clr-primary-400)" }} />{" "}</span>
                                        <p>
                                            {" "} {ans[ans?.length - 1]?.distance / 1000} KM
                                        </p>
                                    </div>
                                }
                                <div className="flex ai-center gap-4 fs-link">
                                    <span className="flex-shrink-0"><RupeeIcon style={{ backgroundColor: "white", color: "var(--clr-primary-400)" }} />{" "}</span>
                                    <p>
                                        {" "}
                                        {(CustomerData?.type === "PartLoad" || CustomerData?.type === "FullLoad") ?
                                            (CustomerData?.gross ? CustomerData?.gross
                                                :
                                                (CustomerData?.customerBookingGrossReq[0]?._id ?
                                                    <ButtonPrimary onClick={() => setSelectRateOfPartLoad(true)}>Select Rate</ButtonPrimary>
                                                    :
                                                    <div>Wait For the Transporters to Bid</div>
                                                )
                                            )
                                            :
                                            CustomerData?.gross
                                        }
                                    </p>
                                </div>

                            </div>
                        }
                    </div>
                    <Dialog
                        open={paymentModechange}
                        maxWidth={"md"}
                        onClose={() => {
                            setPaymentModechange(false);
                            setSelectPaymentOptionBtn("cash")
                        }}
                    >
                        <div className='tac bold'>
                            <DialogTitle
                                style={{ textAlign: "center" }}
                                id="draggable-dialog-title"
                            >
                                Pay Us Via Online
                            </DialogTitle>
                        </div>
                        <DialogContent>
                            <div className='paymentPopupForCustomer'>
                                <div className="ownBtn">
                                    <button className={onLineQr === "Paytm" ? "activeButton" : ""} onClick={() => { setOnlineQr("Paytm") }} > Paytm </button>
                                    <button className={onLineQr === "Gpay" ? "activeButton" : ""} onClick={() => { setOnlineQr("Gpay") }}> G Pay </button>
                                </div>
                                <div className='flex-col gap-1 max-w-300'>
                                    <input placeholder='Transaction Id' value={transactionId} type={"text"} onChange={(e: any) => { setTransactionId(e.target.value) }} className="w-100 border-white outline-none br-4 py-8 px-1" />
                                    {onLineQr === "Gpay" ?
                                        <img src='https://i.ibb.co/FbGQ3jZ/GPay-Qr-Code.jpg' alt='GPay Qr Code' className="custom-img" />
                                        :
                                        <img src='https://i.ibb.co/x8V3knw/paytm-Qr-Code.jpg' alt='Paytm Qr Code' className="custom-img" />
                                    }
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <div className='flex jc-end gap-8 ai-center flex-wrap'>
                                <ButtonPrimary className={`button-primary-secondary fs-popup-btn`} onClick={() => {
                                    if (transactionId === "") {
                                        showHttpError("Transaction Id Is Madatory");
                                        return;
                                    }
                                    handleCustomerOnlinePayment()
                                    setPaymentModechange(false)
                                }}>
                                    Submit
                                </ButtonPrimary>
                                <ButtonDefault className={`button-danger-secondary fs-popup-btn`} onClick={() => {
                                    setPaymentModechange(false),
                                        setSelectPaymentOptionBtn("cash")
                                }}>
                                    Close
                                </ButtonDefault>
                            </div>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={selectRateOfPartLoad}
                        maxWidth={"xl"}
                        onClose={() => { setSelectRateOfPartLoad(false); }}
                    >
                        <div className='tac bold'>
                            <DialogTitle
                                style={{ textAlign: "center" }}
                                id="draggable-dialog-title"
                            >
                                <div style={{ color: "white", fontSize: "large" }}> Select Your Suitable Rate </div>
                            </DialogTitle>
                        </div>
                        <DialogContent>
                            <div>
                                <div>
                                    {CustomerData?.customerBookingGrossReq?.map((x: any, index: any) => {
                                        return (
                                            <div
                                                style={{
                                                    display: "flex", padding: "1.25rem 1.5rem", borderRadius: "5px", width: "100%", gap: "3rem", justifyContent: "space-evenly",
                                                    boxShadow: "0 2px 1px rgba(0, 0, 0, 0.5)", alignItems: "center",
                                                    backgroundColor: selectedRateInPopup === (index + 1) ? "var(--clr-primary-400)" : "", color: selectedRateInPopup === (index + 1) ? "white" : ""
                                                }}
                                                onClick={() => { return setSelectedRateInPopup(index + 1), setSelectedRateInId(x?._id) }}
                                            >
                                                <p style={{ margin: 0 }}> {x?.driverCompany?.companyName} </p>
                                                <p style={{ margin: 0 }}> {x?.gross} </p>
                                                <p style={{ margin: 0 }}>
                                                    {x?.senderRemarks}
                                                </p>
                                            </div>)
                                    })}
                                </div>
                                <br />
                                <div>
                                    <input
                                        className="border-white br-4 w-100 p-8"
                                        placeholder="Remarks"
                                        value={selectedRateRemarks}
                                        onChange={(e: any) => setSelectedRateRemarks(e.target.value)}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <div className='flex gap-8 ai-center'>
                                <ButtonPrimary
                                    className={`button-primary-secondary fs-popup-btn`}
                                    onClick={() => {
                                        if (selectedRateRemarks !== "") {
                                            if (selectedRateInPopup > 0) {
                                                handleCustomerPartLoadRateApproval(selectedRateInId);
                                                setSelectRateOfPartLoad(false);
                                            }
                                            else {
                                                showHttpError("Minimum 1 Bid Should be Selected")
                                            }
                                        }
                                        else {
                                            showHttpError("Remarks Should Not Be Empty")
                                        }
                                    }}
                                >
                                    Submit
                                </ButtonPrimary>
                                <ButtonDefault
                                    className={`button-danger-secondary fs-popup-btn`}
                                    onClick={() => { setSelectRateOfPartLoad(false) }}>
                                    Close
                                </ButtonDefault>
                            </div>
                        </DialogActions>
                    </Dialog>
                </div>

                {statusForOrder === "Upcoming" ? <></> :
                    <div className="paper mt-1">
                        {mobileView ?
                            <div>
                                <p className="text-left fw-600 fs-link"> Delivery Partner Details </p>
                                {CustomerData?.driver &&
                                    <>
                                        <p className="driverImage">
                                            <OnlyImage objectID={driverImageUrl} className={'max-w-50 block mx-auto'} />
                                        </p>
                                        <div className="two-column fs-link">
                                            <span className="flex ai-center gap-4"><UserIcon className="text-primary-400" style={{ fontSize: "x-large" }} />{`${CustomerData?.driver?.name?.fName} ${CustomerData?.driver?.name?.lName}`}</span>
                                            <span className="flex ai-center gap-4"><CallIcon className="text-primary-400" style={{ fontSize: "x-large" }} />{CustomerData?.driver?.contact}</span>
                                        </div>
                                        <div className="two-column mt-1 fs-link">
                                            <span className="flex ai-center gap-4">
                                                <MovingTruckIcon className="text-primary-400" style={{ fontSize: "x-large" }} />
                                                {CustomerData?.regNumber}
                                            </span>
                                        </div>
                                    </>
                                }
                            </div>
                            :
                            <div>
                                {CustomerData?.driver &&
                                    <div className="flex jc-between ai-center gap-4 flex-wrap">
                                        <p className="driverImage">
                                            <OnlyImage objectID={driverImageUrl} className={'max-w-50'} />
                                        </p>
                                        <p className="flex ai-center gap-4 fs-link">
                                            <span> <UserIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} /> </span>
                                            <span> {`${CustomerData?.driver?.name?.fName} ${CustomerData?.driver?.name?.lName}`} </span>
                                        </p>
                                        <p className="flex ai-center gap-4 fs-link">
                                            <span> <CallIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} /> </span>
                                            <span> {CustomerData?.driver?.contact} </span>
                                        </p>
                                        <p className="flex ai-center gap-4 fs-link">
                                            <span> <MovingTruckIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} /> </span>
                                            <span> {CustomerData?.regNumber} </span>
                                        </p>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }

                {
                    statusForOrder !== "Upcoming" && CustomerData?.otp && new Date(CustomerData?.otp?.expireDate).getTime() > new Date().getTime() && CustomerData?.orderStatus !== "D" &&
                    <div className="paper bg-primary-50 text-primary-900 mt-1 fs-link"><p className="flex-center gap-4 fs-link">
                        Your OTP Number is {" "}
                        <strong> {CustomerData?.otp?.otp}</strong>
                    </p></div>
                }

                {CustomerData?.orderStatus !== "D" ?
                    <div className="mt-1 br-4">
                        <div className='overflow-hidden two-column m-one-column t-one-column gap-1 shadow-none'>
                            <div style={{}} className="br-4 border-white shadow-small p-1">
                                <div style={{ overflowY: "auto", height: "100%", maxHeight: mobileView ? "450px" : "450px", display: "flex", flexDirection: "column", gap: "1rem" }}>
                                    {newProps?.data?.customerTracking?.route?.map((e: any, i: any) => {
                                        return (
                                            <AddressComponent activeDest={activeDest} eData={e} iData={i} setAdressPickUpInfo={setAdressPickUpInfo} setAdressPackageData={setAdressPackageData} />
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="overflow-hidden p-1 border-white shadow-small br-4 flex-grow-1" style={{ minHeight: "450px" }}>
                                <div className='mapDiv | m-0'>
                                    {CustomerData && CustomerData?.route &&
                                        <ViewShipmentTrack Route={someCoords} from={"customerTrackingMap"} encoded={CustomerData?.googleMapPolyLine} />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='mt-1'>
                            <div className='p-1 shadow-small border-white br-4 flex-col'>
                                {mobileView &&
                                    <p className="br-4 flex-between gap-1 text-primary-900 p-8 fs-link" onClick={() => setIsLastActivityOpen(prev => !prev)}>
                                        Last Activity
                                        {isLastActivityOpen ? <UpIcon /> : <DownIcon />}
                                    </p>
                                }
                                {logs[0] &&
                                    <div className={`${newProps.data.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" ? "" : ""} flex-col m-0 w-100`}>
                                        {mobileView ?
                                            <>
                                                {isLastActivityOpen &&
                                                    <div className="grid gap-1 pt-1 fs-link">
                                                        <div className="two-column gap-8">
                                                            <p className="flex ai-center gap-4">
                                                                <CalendarIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                                                <div className='commonComplaintSecondDiv'>
                                                                    {formatDate(logs[logs?.length - 1].trackingTime)}
                                                                </div>
                                                            </p>
                                                            <p className="flex ai-center gap-4">
                                                                <HoursIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                                                <div className='commonComplaintSecondDiv'>
                                                                    {timeConvert(logs[logs?.length - 1].trackingTime)}
                                                                </div>
                                                            </p>
                                                        </div>
                                                        <div className="two-column gap-8">
                                                            {logs[logs?.length - 1].action !== "Accepted" ?
                                                                <p className="flex ai-center gap-4">
                                                                    <UserIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                                                    {logs[0].customer?.name ? logs[0].customer?.name : logs[logs?.length - 1].user?.name?.fName}
                                                                </p>
                                                                :
                                                                logs[logs?.length - 1].action === "startOrder" ?
                                                                    <p className="flex ai-center gap-4">
                                                                        <UserIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                                                        {logs[logs?.length - 1].user?.name?.fName}
                                                                    </p>
                                                                    :
                                                                    <p className="flex ai-center gap-4">
                                                                        <UserIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                                                        {logs[logs?.length - 1].user?.name?.fName}
                                                                    </p>
                                                            }
                                                            {logs[logs?.length - 1].action !== "Accepted" ?
                                                                <p style={{ color: "var(--clr-primary-400)" }} className="flex ai-center gap-4">
                                                                    <CallIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                                                    {logs[0].customer?.contact ? logs[0].customer?.contact : logs[logs?.length - 1].user?.contact}
                                                                </p>
                                                                :
                                                                logs[logs?.length - 1].action === "startOrder" ?
                                                                    <p style={{ color: "var(--clr-primary-400)" }} className="flex ai-center gap-4">
                                                                        <CallIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                                                        {logs[logs?.length - 1].user?.contact}
                                                                    </p>
                                                                    :
                                                                    <p style={{ color: "var(--clr-primary-400)" }} className="flex ai-center gap-4">
                                                                        <CallIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                                                        {logs[logs?.length - 1].user?.contact}
                                                                    </p>
                                                            }
                                                        </div>
                                                        <p className="flex ai-center gap-4">
                                                            <TimelineIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                                                            <div className='commonComplaintSecondDiv'>
                                                                {logs[logs?.length - 1].action}
                                                            </div>
                                                        </p>
                                                        <div className="two-column gap-8">
                                                            <Button fullWidth className="jc-center" variant="primary" action="secondary" Icon={<GalleryIcon />} onClick={() => alert("Testing Ig Going On !")}>Images</Button>
                                                            <Button fullWidth className="jc-center" variant="primary" action="secondary" Icon={<HistoryIcon />} onClick={() => handleDetailedLogsForCustomer()}>History</Button>
                                                        </div>
                                                    </div>}
                                            </>
                                            :
                                            <div className="flex-between gap-1 ai-center flex-wrap w-100">
                                                <p className="br-4 bg-primary-400 text-white-900 px-1 py-8 max-content fs-link">
                                                    Last Activity
                                                </p>
                                                <div className="flex-grow-1 flex-between ai-center gap-1 flex-wrap">
                                                    <p className="flex ai-center gap-4">
                                                        <div>
                                                            <CalendarIcon style={{ fontSize: "x-large", color: "var(--clr-primary-400)" }} />
                                                        </div>
                                                        <div>
                                                            {formatDate(logs[logs?.length - 1].trackingTime)}
                                                        </div>
                                                    </p>
                                                    <p className="flex ai-center gap-4">
                                                        <div>
                                                            <HoursIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                        </div>
                                                        <div>
                                                            {timeConvert(logs[logs?.length - 1].trackingTime)}
                                                        </div>
                                                    </p>
                                                    <p className="flex ai-center gap-4">
                                                        <div>
                                                            <TimelineIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                        </div>
                                                        <div>
                                                            {logs[logs?.length - 1].action}
                                                        </div>
                                                    </p>
                                                    {logs[logs?.length - 1].action !== "Accepted" ?
                                                        <p className="flex ai-center gap-4">
                                                            <div>
                                                                <UserIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                            </div>
                                                            <div>
                                                                {logs[0].customer?.name ? logs[0].customer?.name : logs[logs?.length - 1].user?.name?.fName}
                                                            </div>
                                                        </p>
                                                        :
                                                        logs[logs?.length - 1].action === "startOrder" ?
                                                            <p className="flex ai-center gap-4">
                                                                <div>
                                                                    <UserIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                                </div>
                                                                <div>
                                                                    {logs[logs?.length - 1].user?.name?.fName}
                                                                </div>
                                                            </p>
                                                            :
                                                            <p className="flex ai-center gap-4">
                                                                <div>
                                                                    <UserIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                                </div>
                                                                <div>
                                                                    {logs[logs?.length - 1].user?.name?.fName}
                                                                </div>
                                                            </p>
                                                    }
                                                    {logs[logs?.length - 1].action !== "Accepted" ?
                                                        <p style={{ color: "var(--clr-primary-400)" }} className="flex ai-center gap-4">
                                                            <div>
                                                                <CallIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                            </div>
                                                            <div>
                                                                {logs[0].customer?.contact ? logs[0].customer?.contact : logs[logs?.length - 1].user?.contact}
                                                            </div>
                                                        </p>
                                                        :
                                                        logs[logs?.length - 1].action === "startOrder" ?
                                                            <p style={{ color: "var(--clr-primary-400)" }} className="flex ai-center gap-4">
                                                                <div>
                                                                    <CallIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                                </div>
                                                                <div>
                                                                    {logs[logs?.length - 1].user?.contact}
                                                                </div>
                                                            </p>
                                                            :
                                                            <p style={{ color: "var(--clr-primary-400)" }} className="flex ai-center gap-4">
                                                                <div>
                                                                    <CallIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                                </div>
                                                                <div>
                                                                    {logs[logs?.length - 1].user?.contact}
                                                                </div>
                                                            </p>
                                                    }
                                                    <p className="flex-center gap-4 p-8 br-4 bg-primary-50 text-primary-900" onClick={() => alert("Testing Ig Going On !")}>
                                                        <GalleryIcon style={{ fontSize: "x-large" }} />
                                                        Gallery
                                                    </p>
                                                    <p className='flex-center gap-4 p-8 br-4 bg-primary-50 text-primary-900' onClick={() => handleDetailedLogsForCustomer()} >
                                                        <HistoryIcon />
                                                        History
                                                    </p>
                                                </div>

                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <div className="paper bg-success-50 fw-900 text-success-900 flex-col-center fs-tagline text-center mt-1">
                        <MdVerified /> Your Order is Delivered Successfully
                    </div>
                }

                {newProps.data.customerTrackingData.source === "Customer-Authenticated-CustomerOrder"
                    || newProps.data.customerTrackingData.source === "Branch-Authenticated-CustomerOrder" ?
                    <>
                        {statusForOrder === "Upcoming" ? <></> :
                            <>
                                {CustomerData?.orderStatus === "D" ?
                                    <div className="paper flex-col gap-1 mt-1">
                                        <div className="flex-col gap-8">
                                            <p className="fs-link fw-600 text-left">Review & Rating</p>
                                            <div className="flex-center">
                                                <Rating
                                                    max={3}
                                                    style={{ fontSize: "xx-large", marginTop: "2.5%" }}
                                                    name="hover-feedback1"
                                                    value={ratingFromUser}
                                                    precision={1}
                                                    onChange={(e: any) => {
                                                        setRatingFromUser(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <textarea
                                            className="border-white p-8 br-4 w-100"
                                            value={reviewFromUser}
                                            placeholder="Write your review here..."
                                            rows={3}
                                            onChange={(el: any) => { setReviewFromUser(el.target.value) }}
                                        />
                                        {handleFeedbackButton && reviewFromUser &&
                                            <Button fullWidth className="flex-center" variant="primary" action="primary" Icon={<SendIcon />} onClick={() => handleRatings(CustomerData?.shipmentNumber)}>
                                                Send
                                            </Button>
                                        }
                                    </div>
                                    :
                                    <>
                                        <div className="mt-1 paper">
                                            {mobileView ?
                                                <div className="grid gap-1">
                                                    <p className="br-4 flex-between gap-1 text-primary-900 p-8 fs-link" onClick={() => setIsComplaintOpen(prev => !prev)}>
                                                        Complaints
                                                        {isComplaintsOpen ? <UpIcon /> : <DownIcon />}
                                                    </p>
                                                    {isComplaintsOpen &&
                                                        <>
                                                            <div className="grid gap-1">
                                                                <Button variant="primary" fullWidth action="secondary" onClick={() => setIsDialogOpen(1)}>Missing</Button>
                                                                <Button variant="primary" fullWidth action="secondary" onClick={() => setIsDialogOpen(2)}>Pill Frase</Button>
                                                                <Button variant="primary" fullWidth action="secondary" onClick={() => setIsDialogOpen(3)}>Damage</Button>
                                                                <Button variant="primary" fullWidth action="secondary" onClick={() => setIsDialogOpen(4)}>Miss Behave</Button>
                                                                <Button variant="primary" fullWidth action="secondary" onClick={() => setIsDialogOpen(5)}>Late Delivery</Button>
                                                                <Button variant="primary" fullWidth action="secondary" onClick={() => setIsDialogOpen(6)}>Others</Button>
                                                            </div>
                                                            {complaintRaised &&
                                                                <div className="chatIconContainer" onClick={() => { handleIndividualChat() }}>
                                                                    <ChatIcon className="text-primary-400" />
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                :
                                                <div className="flex ai-center gap-1 flex-wrap">
                                                    <div className='bg-primary-400 text-white-900 p-8 br-4 fs-link mx-auto'>Complaints</div>
                                                    {/* <div className="btnsForComplaints w-auto flex-grow-1 gap-1 jc-between flex-wrap">
                                                        <button onClick={() => setIsDialogOpen(1)}>Missing</button>
                                                        <button onClick={() => setIsDialogOpen(2)}>Pill Frase</button>
                                                        <button onClick={() => setIsDialogOpen(3)}>Damage</button>
                                                        <button onClick={() => setIsDialogOpen(4)}>Miss Behave</button>
                                                        <button onClick={() => setIsDialogOpen(5)}>Late Delivery</button>
                                                        <button onClick={() => setIsDialogOpen(6)}>Others</button>
                                                    </div> */}
                                                    <ComplaintButtons setIsDialogOpenFunc={setIsDialogOpenFunc} numbers={6} />
                                                    {complaintRaised &&
                                                        <div className="chatIconContainer" onClick={() => { handleIndividualChat() }}>
                                                            <ChatIcon className="text-primary-400" />
                                                        </div>
                                                    }
                                                </div>}
                                            <Dialog
                                                open={Boolean(isDialogOpen)}
                                                maxWidth={"xl"}
                                                onClose={() => {
                                                    setIsDialogOpen(0);
                                                }}
                                            >
                                                <div className='tac bold'>
                                                    <DialogTitle
                                                        style={{ textAlign: "center" }}
                                                        id="draggable-dialog-title"
                                                    >
                                                        <div className='commonTitleOfPopup'>
                                                            Raise Your Concern
                                                        </div>
                                                    </DialogTitle>
                                                </div>
                                                <DialogContent>
                                                    {isDialogOpen === 1 ?
                                                        <div className="flex-col gap-1">
                                                            <div className="">
                                                                <select
                                                                    id="inputState"
                                                                    className="form-control form_one"
                                                                    value={customerComplaintRefOrderNo}
                                                                    onChange={(e) => {
                                                                        setCustomerComplaintRefOrderNo(e.target.value);
                                                                    }}
                                                                >
                                                                    <option label="Select Order No" value="" />
                                                                    {customerOrderNoList.map((c: any) => (
                                                                        <option
                                                                            key={c.key}
                                                                            label={c.key}
                                                                            value={c.value}
                                                                        />
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="">
                                                                <textarea
                                                                    className="py-8 px-1 border-white br-4"
                                                                    rows={3}
                                                                    value={description}
                                                                    placeholder="Provide detail information regarding your issue"
                                                                    onChange={(e: any) => setDescription(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        :
                                                        isDialogOpen === 2 ?
                                                            <div className="flex-col gap-1">
                                                                <div className="">
                                                                    <select
                                                                        id="inputState"
                                                                        className="form-control form_one w-100"
                                                                        value={customerComplaintRefOrderNo}
                                                                        onChange={(e) => {
                                                                            setCustomerComplaintRefOrderNo(e.target.value);
                                                                        }}
                                                                    >
                                                                        <option label="Select Order No" value="" />
                                                                        {customerOrderNoList.map((c: any) => (
                                                                            <option
                                                                                key={c.key}
                                                                                label={c.key}
                                                                                value={c.value}
                                                                            />
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="">
                                                                    <textarea
                                                                        className="py-8 px-1 border-white br-4 w-100"
                                                                        rows={3}
                                                                        value={description}
                                                                        placeholder="Provide detail information regarding your issue"
                                                                        onChange={(e: any) => setDescription(e.target.value)}
                                                                    />
                                                                </div>
                                                                <div className="refrenceImageComplaint">
                                                                    <ImageInput
                                                                        name="Add Image"
                                                                        style={{ marginTop: "25%", marginLeft: "25%" }}
                                                                        Icon={(props: any) => (
                                                                            <i {...props} className={"fas fa-id-card file_icon"} />
                                                                        )}
                                                                        onChange={(e: any) => setImage(e)}
                                                                        value={image}
                                                                        extras={<div></div>}
                                                                    />
                                                                </div>
                                                            </div>
                                                            :
                                                            isDialogOpen === 3 ?
                                                                <div className="flex-col gap-1">
                                                                    <div className="">
                                                                        <textarea
                                                                            className="py-8 px-1 border-white br-4 w-100"
                                                                            rows={3}
                                                                            value={description}
                                                                            placeholder="Provide detail information regarding your issue"
                                                                            onChange={(e: any) => setDescription(e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className="refrenceImageComplaint">
                                                                        <ImageInput
                                                                            name="Add Image"
                                                                            style={{ marginTop: "25%", marginLeft: "25%" }}
                                                                            Icon={(props: any) => (
                                                                                <i {...props} className={"fas fa-id-card file_icon"} />
                                                                            )}
                                                                            onChange={(e: any) => setImage(e)}
                                                                            value={image}
                                                                            extras={<div></div>}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                :
                                                                isDialogOpen === 4 ?
                                                                    <div className="PopupDialogComlaint4">
                                                                        <div className="refrenceImageComplaint4">
                                                                            Driver Name : {" "}
                                                                            {CustomerData?.driver?.name?.fName} {" "}
                                                                            {CustomerData?.driver?.name?.lName}
                                                                        </div>
                                                                        <div className="">
                                                                            <textarea
                                                                                className="py-8 px-1 border-white br-4 w-100"
                                                                                rows={3}
                                                                                value={description}
                                                                                placeholder="Provide detail information regarding your issue"
                                                                                onChange={(e: any) => setDescription(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    isDialogOpen === 5 ?
                                                                        <div className="">
                                                                            <div className="">
                                                                                <textarea
                                                                                    className="py-8 px-1 border-white br-4 w-100"
                                                                                    rows={3}
                                                                                    value={description}
                                                                                    placeholder="Provide detail information regarding your issue"
                                                                                    onChange={(e: any) => setDescription(e.target.value)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        isDialogOpen === 6 ?
                                                                            <div className="">
                                                                                <div className="">
                                                                                    <textarea
                                                                                        className="py-8 px-1 border-white br-4 w-100"
                                                                                        rows={3}
                                                                                        value={description}
                                                                                        placeholder="Provide detail information regarding your issue"
                                                                                        onChange={(e: any) => setDescription(e.target.value)}
                                                                                    />
                                                                                </div>
                                                                                <div className="refrenceImageComplaint">
                                                                                    <ImageInput
                                                                                        name="Add Image"
                                                                                        style={{ marginTop: "25%", marginLeft: "25%" }}
                                                                                        Icon={(props: any) => (
                                                                                            <i {...props} className={"fas fa-id-card file_icon"} />
                                                                                        )}
                                                                                        onChange={(e: any) => setImage(e)}
                                                                                        value={image}
                                                                                        extras={<div></div>}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <></>
                                                    }
                                                </DialogContent>
                                                <DialogActions>
                                                    <div className='flex jc-end gap-8'>
                                                        <ButtonPrimary className={`button-primary-secondary fs-popup-btn`} onClick={() => {
                                                            setIsDialogOpen(0),
                                                                raiseComplaint();
                                                        }}>
                                                            Submit
                                                        </ButtonPrimary>
                                                        <ButtonDefault
                                                            className="button-danger-secondary fs-popup-btn"
                                                            onClick={() => setIsDialogOpen(0)}
                                                        >
                                                            close
                                                        </ButtonDefault>
                                                    </div>
                                                </DialogActions>
                                            </Dialog>
                                        </div>
                                        {latComplaint !== undefined &&
                                            <div className="lastComplaintArr">
                                                {mobileView ?
                                                    <div className='mt-1'>
                                                        <div className="grid paper">
                                                            <p className="br-4 flex-between gap-1 text-primary-900 p-8 fs-link" onClick={() => setIsLastComplaintOpen(prev => !prev)}>
                                                                Last Complaint
                                                                {isLastComplaintOpen ? <UpIcon /> : <DownIcon />}
                                                            </p>
                                                            {isLastComplaintOpen &&
                                                                <div className="grid gap-1 mt-1">
                                                                    <div className='two-column gap-1'>
                                                                        <p className='flex ai-center gap-4'>
                                                                            <CalendarIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                                            {formatDate(latComplaint?.createdAt)}
                                                                        </p>
                                                                        <p className='flex ai-center gap-4'>
                                                                            <HoursIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                                            {timeConvert(latComplaint?.createdAt)}
                                                                        </p>
                                                                    </div>
                                                                    <div className="two-column gap-1">
                                                                        <p className='flex ai-center gap-4'>
                                                                            <ServiceTypeIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                                            {getComplaintType(latComplaint.complaintType)}
                                                                        </p>
                                                                        <p className="flex ai-center gap-4">
                                                                            <TimelineIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                                            {getComplaintStatus(latComplaint.complaintStatus)}
                                                                        </p>
                                                                    </div>
                                                                    <p className='text-white-100 bg-white-800 flex-between p-10'>
                                                                        {latComplaint.messages[latComplaint.messages.length - 1].text}
                                                                        <Button variant="primary" action="primary" Icon={<LastMessageIcon />} onClick={() => { handleIndividualChat() }}></Button>
                                                                    </p>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='mt-1'>
                                                        <div className="flex ai-center gap-1 jc-between flex-wrap paper">
                                                            <p className='lastComplaint'> Last Complaint </p>
                                                            <p className='flex ai-center gap-4'>
                                                                <div>
                                                                    <CalendarIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                                </div>
                                                                <div>
                                                                    {formatDate(latComplaint?.createdAt)}
                                                                </div>
                                                            </p>
                                                            <p className='flex ai-center gap-4'>
                                                                <div>
                                                                    <HoursIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                                </div>
                                                                <div>
                                                                    {timeConvert(latComplaint?.createdAt)}
                                                                </div>
                                                            </p>
                                                            <p className='flex ai-center gap-4'>
                                                                <div>
                                                                    <ServiceTypeIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                                </div>
                                                                <div>
                                                                    {latComplaint.complaintType === "LD" ? `Late Delivery` : latComplaint.complaintType === "PM" ? `Parcel Missing` :
                                                                        latComplaint.complaintType === "OT" ? `Other` : latComplaint.complaintType === "DMG" ? `Parcel Damaged` :
                                                                            latComplaint.complaintType === "PF" ? `Pilferege` : latComplaint.complaintType === "MB" ? `Misbehavior by Kabra Employees` : ``}
                                                                </div>
                                                            </p>
                                                            <p className="flex ai-center gap-4">
                                                                <div>
                                                                    <TimelineIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                                </div>
                                                                <div>
                                                                    {latComplaint?.complaintStatus === "O" ? `Opened` : latComplaint?.complaintStatus === "I" ? `In progress` :
                                                                        latComplaint?.complaintStatus === "CP" ? `Compensation Paid` : latComplaint?.complaintStatus === "CG" ? `Compensation Granted` :
                                                                            latComplaint?.complaintStatus === "CU" ? `Closed By Executive` : latComplaint?.complaintStatus === "CC" ? `Closed By Customer` :
                                                                                latComplaint?.complaintStatus === "CS" ? `Closed By System` : latComplaint?.complaintStatus === "RE" ? `Re Opened` : ``}
                                                                </div>
                                                            </p>
                                                            <p className='flex ai-center gap-4'>
                                                                <div>
                                                                    <MessageAltIcon style={{ fontSize: "x-large", backgroundColor: "white", color: "var(--clr-primary-400)" }} />
                                                                </div>
                                                                <div>
                                                                    {latComplaint.messages[latComplaint.messages.length - 1].text}
                                                                </div>
                                                            </p>
                                                            <p className="flex ai-center gap-4" onClick={() => {
                                                                handleIndividualCustomerOrderChat(CustomerData?.feedbacks?.complaints[CustomerData?.feedbacks?.complaints.length - 1],
                                                                    CustomerData?.feedbacks?.complaints[CustomerData?.feedbacks?.complaints.length - 1]?.feedbackNumber, "CustomerBooking")
                                                            }}>
                                                                <ChatIcon className="text-primary-400" style={{ fontSize: "large" }} />
                                                            </p>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </>
                                }
                            </>
                        }
                    </> : <></>
                }

                <Dialog
                    open={Boolean(isPickupInfoOpen)}
                    maxWidth={"xl"}
                    onClose={() => {
                        setIsPickupInfoOpen(0);
                    }}
                >
                    <div className='tac bold'>
                        <DialogTitle
                            style={{ textAlign: "center" }}
                            id="draggable-dialog-title"
                        >
                            View Package Details
                        </DialogTitle>
                    </div>
                    <DialogContent>
                        {CustomerData?.packages?.map((neELe: any) => {
                            if (neELe.fromPlace == packageDataInfo?.placeId) {
                                return (
                                    <div className="fs-link">
                                        <div className="flex gap-4 ai-center flex-wrap">
                                            <p>
                                                Service Type :
                                            </p>
                                            <p className="fw-500">
                                                {packageDataInfo?.serviceType}
                                            </p>
                                        </div>
                                        <div className="flex gap-4 ai-center flex-wrap">
                                            <p>
                                                Package Number :
                                            </p>
                                            <p className="fw-500">
                                                {neELe?.customerPkgIdentifier}
                                            </p>
                                        </div>
                                        <div className="flex gap-4 ai-center flex-wrap">
                                            <p>
                                                Material Name :
                                            </p>
                                            <p className="fw-500">
                                                {neELe?.materialType?.name}
                                            </p>
                                        </div>
                                        <div className="flex gap-4 ai-center flex-wrap">
                                            <p>
                                                Quantity :
                                            </p>
                                            <p className="fw-500">
                                                {neELe?.qty}
                                            </p>
                                        </div>
                                        <div className="flex gap-4 ai-center flex-wrap">
                                            <p>
                                                COD Amount :
                                            </p>
                                            <p className="fw-500">
                                                {neELe?.codAmount}
                                            </p>
                                        </div>
                                        <br />
                                    </div>
                                )
                            } else if (neELe.toPlace == packageDataInfo?.placeId) {
                                return (
                                    <div className="fs-link">
                                        <div className="flex ai-center gap-4 flex-wrap">
                                            <p>
                                                Service Type :
                                            </p>
                                            <p className="fw-500">
                                                {packageDataInfo?.serviceType}
                                            </p>
                                        </div>
                                        <div className="flex ai-center gap-4 flex-wrap">
                                            <p>
                                                Package Number :
                                            </p>
                                            <p className="fw-500">
                                                {neELe?.customerPkgIdentifier}
                                            </p>
                                        </div>
                                        <div className="flex ai-center gap-4 flex-wrap">
                                            <p>
                                                Material Name :
                                            </p>
                                            <p className="fw-500">
                                                {neELe?.materialType?.name}
                                            </p>
                                        </div>
                                        <div className="flex ai-center gap-4 flex-wrap">
                                            <p>
                                                Quantiy :
                                            </p>
                                            <p className="fw-500">
                                                {neELe?.qty}
                                            </p>
                                        </div>
                                        <div className="flex ai-center gap-4 flex-wrap">
                                            <p>
                                                COD Amount :
                                            </p>
                                            <p className="fw-500">
                                                {neELe?.codAmount}
                                            </p>
                                        </div>
                                        <br />
                                    </div>
                                )
                            }
                            <hr />
                        })}
                    </DialogContent>
                    <DialogActions>
                        <div className='onLinePkgbtnMobile'>
                            <ButtonDefault className={`button-danger-secondary fs-popup-btn`} onClick={() => {
                                setIsPickupInfoOpen(0)
                            }}>
                                Close
                            </ButtonDefault>
                        </div>
                    </DialogActions>
                </Dialog>

            </div>
        </>
    )
}

export default CustomerTrackingView