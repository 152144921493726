const prefix = 'COMPANY:'

export const GET_COMPANY_REQ_SUCCESS = prefix + 'GET_COMPANY_REQ_SUCCESS'
export const EDIT_COMPANY_REQ_SUCCESS = prefix + 'EDIT_COMPANY_REQ_SUCCESS'
// expense types
export const CHANGE_ADD_EXPENSE_TYPE = prefix + 'CHANGE_ADD_EXPENSE_TYPE'
export const SET_ASSET_TYPES = prefix + 'SET_ASSET_TYPES'
export const SUBMIT_EXPENSE_SUCCESS = prefix + 'SUBMIT_EXPENSE_SUCCESS'
export const SUBMIT_EXPENSE_FAILED = prefix + 'SUBMIT_EXPENSE_FAILED'
export const SET_ASSET_TYPES_INCOME_TYPE = prefix + 'SET_ASSET_TYPES_INCOME_TYPE'
export const GET_COMPANY_LIST = prefix + 'GET_COMPANY_LIST'

//EDIT EXPENSE TYPE 
export const SET_EXPENSE_TYPE = prefix + 'SET_EXPENSE_TYPE'
export const RESET_EXPENSE_TYPE = prefix + 'RESET_EXPENSE_TYPE'
export const DELETE_EXPENSE_TYPE = prefix + 'DELETE_EXPENSE_TYPE'

// fixed expense types
export const GET_EXPENSE_TYPES = prefix + 'GET_EXPENSE_TYPES'
export const GET_EXPENSE_TYPES_FAILED = prefix + 'GET_EXPENSE_TYPES_FAILED'
export const CHANGE_FIXED_EXPENSE = prefix + 'CHANGE_FIXED_EXPENSE'
export const CHANGE_ENTITY_ID = prefix + 'CHANGE_ENTITY_ID'
export const SUBMIT_FIXED_EXPENSE_FAILED = prefix + 'SUBMIT_FIXED_EXPENSE_FAILED'
export const EDIT_FIXED_EXPENSE_FAILURE = prefix + 'EDIT_FIXED_EXPENSE_FAILURE'
export const SUBMIT_FIXED_EXPENSE_SUCCESS = prefix + 'SUBMIT_FIXED_EXPENSE_SUCCESS'
export const EDIT_EXPENSE_SUCCESS = prefix + 'EDIT_EXPENSE_SUCCESS'
export const EDIT_EXPENSE_FAILED = prefix + 'EDIT_EXPENSE_FAILED'
export const GET_ASSET_BALANCES = prefix + 'GET_ASSET_BALANCES'
export const GET_VEHICLE_ID = prefix + 'GET_VEHICLE_ID'

//EDIT FIXED EXPENSE
export const RESET_FIXED_EXPENSE = prefix + 'RESET_FIXED_EXPENSE'
export const GET_FIXED_EXPENSE_BY_ID = prefix + 'GET_FIXED_EXPENSE_BY_ID'
export const SET_FIXED_EXPENSE_TYPE = prefix + 'SET_FIXED_EXPENSE_TYPE'
export const EDIT_FIXED_EXPENSE_SUCCESS = prefix + 'EDIT_FIXED_EXPENSE_SUCCESS'
export const DELETE_FIXED_EXPENSE_TYPE = prefix + 'DELETE_FIXED_EXPENSE_TYPE'

// add income types
export const CHANGE_ADD_INCOME_TYPE = prefix + 'CHANGE_ADD_INCOME_TYPE'
export const SUBMIT_INCOME_TYPE_SUCCESS = prefix + 'SUBMIT_INCOME_TYPE_SUCCESS'
export const SUBMIT_INCOME_TYPE_FAILED = prefix + 'SUBMIT_INCOME_TYPE_FAILED'
export const GET_INCOME_TYPE = prefix + 'GETT_INCOME_TYPE'

//add sub income types
export const CHANGE_SUB_INCOME_TYPE = prefix + 'CHANGE_SUB_INCOME_TYPE'
export const GET_POSTED_SUB_TYPES = prefix + 'GET_POSTED_SUB_TYPES'
export const ADD_SUB_INCOME_TYPE = prefix + 'ADD_SUB_INCOME_TYPE'
export const ADD_SUB_INCOME_TYPE_FAILED = prefix + 'ADD_SUB_INCOME_TYPE_FAILED'

//EDIT INCOME TYPE
export const SET_INCOME_TYPE_FOR_EDIT = prefix + 'SET_INCOME_TYPE_FOR_EDIT'
export const EDIT_INCOME_TYPE_SUCCESS = prefix + 'EDIT_INCOME_TYPE_SUCCESS'
export const INCOME_TYPE_DELETED = prefix + 'INCOME_TYPE_DELETED'
export const RESET_INCOME_TYPE_FORM = prefix + 'RESET_INCOME_TYPE_FORM'

//edit suB income type
export const SET_SUBTYPES_FOR_EDIT = prefix + 'SET_SUBTYPES_FOR_EDIT'
export const EDIT_SUB_INCOME_DATA = prefix + 'EDIT_SUB_INCOME_DATA'
export const RESET_SUB_INCOME_TYPE = prefix + 'RESET_SUB_INCOME_TYPE'
export const DELETE_SUB_TYPE = prefix + 'DELETE_SUB_TYPE'

// manage Company

export const CHANGE_COMPANY_DOC = prefix + 'CHANGE_COMPANY_DOC'
export const SET_COMPANY_DOC = prefix + 'SET_COMPANY_DOC'
export const CLEAR_COMPANY_DOC = prefix + 'CLEAR_COMPANY_DOC'
export const UPDATE_COMPANY_DOC = prefix + 'UPDATE_COMPANY_DOC'

