import { ServiceMaker, buildQueryString } from "./index";

export const getStockData = (data: any) =>
  ServiceMaker(
    `verification/verifiedPackages?${buildQueryString(data)}`,
    "GET"
  );

// export const getStockCount = (data:any)=>
// ServiceMaker(`verification/verifiedPackagesCount?${buildQueryString(data)}`,"GET")

export const getStockCount = (data?: any) =>
  ServiceMaker(`verification/verifiedPackagesCount`, "GET", data);

export const verifyStockData = (data: any) =>
  ServiceMaker("verification/secondaryVerifyPackage", "PATCH", data);

export const rejectStockData = (data: any) =>
  ServiceMaker("verification/secondaryRejectPackage", "PATCH", data);

export const getVerifiedPackagesReport = (data: any) => 
  ServiceMaker(`report/verifiedPackages?${buildQueryString(data)}`, "GET");
