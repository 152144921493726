import React, { useState, ReactNode, useRef, ComponentProps } from "react";
import "./tooltip.css";

type TooltipProps = {
  children?: ReactNode;
  title: string | ReactNode;
  position?: "top" | "right" | "bottom" | "left";
  delay?: number; // Delay in showing the tooltip in ms
  className?: string;
} & Omit<ComponentProps<"div">, "title">;

const Tooltip: React.FC<TooltipProps> = ({
  children,
  title,
  position = "bottom",
  delay = 500,
  className = "",
  ...props
}) => {
  const [visible, setVisible] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const showTooltip = () => {
    const id = setTimeout(() => setVisible(true), delay);
    setTimeoutId(id);
  };

  const hideTooltip = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setVisible(false);
  };

  return (
    <div
      className={`taptap-tooltip-wrapper ${className}`}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      {...props}
    >
      {children}
      {visible && (
        <div
          className={`taptap-tooltip-box taptap-tooltip-${position}`}
          ref={tooltipRef}
        >
          {title}
          <span
            className={`taptap-tooltip-arrow taptap-tooltip-${position}-arrow`}
          />
        </div>
      )}
    </div>
  );
};

export default Tooltip;
