import { connect } from "react-redux";
import './customerTrackLogsDetails.css';
import MyImage from "../../Image/MyImage";
import { RootState } from "./../../../store";
import React, { useEffect, useState } from 'react';
import formatDate from '../../../services/FormatDate';
import { showHttpError } from "../../../utils/message";
import { Step, StepLabel, Stepper, useMediaQuery, useTheme } from "@mui/material";
import setTimeFormat from '../../../services/FormatTime';
import ToolTip from "../../../Components/ToolTip/ToolTip";
import { useLocation, useNavigate } from 'react-router-dom';
import PageTitle from "../../../Components/Title/PageTitle";
import { Dialog, NoDataFound, Select } from "../../../newSrc/components/UI";
import { showLoader, hideLoader } from "./../../../actions/UserActions";
import { ResetCustomerTracking } from './../../../actions/trackingModule';
import { setCustomerBookingTracking } from './../../../actions/customerBooking';
import { ButtonPrimary, ButtonSimple } from '../../../Components/Button/Buttoncontainer';
import { BackIcon, CalendarIcon, CashIcon, DistanceIcon, DocketNumberIcon, DropboxIcon, GalleryIcon, HoursIcon, InfoIcon, LocationIcon, MapLocationIcon, MapPinIcon, PackageIcon, QRCodeIcon, RupeeIcon, ServiceTypeIcon, SharpLocationIcon, TimeToReachIcon, TimelineIcon, UserIcon } from "../../../newSrc/constants/icons";

const CustomerTrackLogsDetails = (props: any) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const BranchData = location?.state?.BranchDetails;
  const [remarkImage, setRemarkImage] = useState('');
  const [isDeliveryImage, setIsDeliveryImage] = useState(false);
  const [logsData, setLogsData] = useState<any>([]);
  const [selectedPackageForLogs, setSelectedPackageForLogs] = useState<any>({ label: "Select Order Number", value: "Select Order Number" });
  const [statusForOrder, setStatusForOrder] = useState("");
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2
  const [showRemarkImages, setShowRemarkImages] = useState(false);

  const timeConvert = (time: any) => {
    const timeString12hr = new Date(time).toLocaleTimeString("en-US", {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });
    return timeString12hr;
  }

  const secondsToHms = (d: any) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " Hr, " : " Hr, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " Min " : " Min ") : "";
    return hDisplay + mDisplay;
  }

  const backToOrigin = () => {
    console.log("RootState", props?.customerTrackingData?.source)
    navigate(-1)
  }

  const ans = BranchData?.route &&
    BranchData?.route.sort((a: any, b: any) => {
      return a?.placeIndex - b?.placeIndex
    }) || []

  useEffect(() => {
    if (BranchData?.orderStatus === null) {
      setStatusForOrder("Upcoming")
    }
    if (BranchData?.orderStatus === "B" || BranchData?.orderStatus === "I") {
      setStatusForOrder("OnGoing")
    }
    if (BranchData?.orderStatus === "D") {
      setStatusForOrder("Delivered")
    }
  }, [])

  var iconsClassName = `text-primary-400 fs-link flex-shrink-0`

  const HeadingDivForBranchOrder = () => {
    return (
      <div className="">
        <div className=''>
          <div className='four-column t-two-column m-one-column gap-1'>
            <div className="flex ai-center gap-4 flex-wrap">
              <span><DocketNumberIcon className={`${iconsClassName}`} /></span>
              <p>
                {props?.customerTrackingData?.source === "Branch-Authenticated-BranchOrder" ? props?.customerTrackingData?.orderData?.docket.docketNumber : props?.customerTrackingData?.orderData?.docket.trackingNumber}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap">
              <span><CalendarIcon className={`${iconsClassName}`} />{" "}</span>
              <p>
                {formatDate(BranchData?.docket.createdAt)}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap">
              <span><TimelineIcon className={`${iconsClassName}`} />{" "}</span>
              <p>
                {BranchData?.docket.deliveredAt ? formatDate(BranchData?.docket.deliveredAt) : "In Transit"}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap">
              <span><CashIcon className={`${iconsClassName}`} />{" "}</span>
              <p>
                {" "} {BranchData?.docket.paymentMode}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap">
              <span><PackageIcon className={`${iconsClassName}`} />{" "}</span>
              <p>
                {BranchData?.docket?.packages?.length}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap">
              <span><LocationIcon className={`${iconsClassName}`} />{" "}</span>
              <p>
                {BranchData?.docket.originBranch.branchName}{" "}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap">
              <span><SharpLocationIcon className={`${iconsClassName}`} />{" "}</span>
              <p>
                {BranchData?.docket.destBranch.branchName}{" "}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap">
              <span><RupeeIcon className={`${iconsClassName}`} />{" "}</span>
              <p>
                {" "} {BranchData?.docket.gross}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const HeadingDivForCustomerOrder = () => {
    return (
      <div className="">
        <div className=''>
          <div className='log-history-general-info gap-1'>
            <div className="flex ai-center gap-4 flex-wrap fs-link">
              <span><CalendarIcon className={`${iconsClassName}`} />{" "}</span>
              <p>
                {formatDate(
                  BranchData?.createdAt)}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap fs-link">
              <span><DocketNumberIcon className={`${iconsClassName}`} /></span>
              <p>
                {" "}{
                  BranchData?.shipmentNumber}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap fs-link">
              <span><ServiceTypeIcon className={`${iconsClassName}`} />{" "}</span>
              <p>
                {" "} {
                  BranchData?.type}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap fs-link">
              <span>
                <MapLocationIcon className={`${iconsClassName}`} />{" "}
              </span>
              <p>
                {" "}{
                  BranchData?.route?.length}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap fs-link">
              <span><CashIcon className={`${iconsClassName}`} />{" "}</span>
              <p>
                {" "} {
                  BranchData?.paymentMode ?
                    BranchData?.paymentMode : "Cash"}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap fs-link">
              <span><HoursIcon className={`${iconsClassName}`} />{" "}</span>
              <p>
                {timeConvert(
                  BranchData?.createdAt)}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap fs-link">
              <span><TimelineIcon className={`${iconsClassName}`} />{" "}</span>
              <p>
                {" "}{statusForOrder}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap fs-link">
              <span><TimeToReachIcon className={`${iconsClassName}`} />{" "}</span>
              <p>
                {" "}{secondsToHms(ans[ans?.length - 1]?.duration)}
              </p>
            </div>
            {BranchData?.route?.length > 0 &&
              <div className="flex ai-center gap-4 flex-wrap fs-link">
                <span><DistanceIcon className={`${iconsClassName}`} />{" "}</span>
                <p>
                  {" "} {ans[ans?.length - 1]?.distance / 1000} KM
                </p>
              </div>
            }
            <div className="flex ai-center gap-4 flex-wrap fs-link">
              <span><RupeeIcon className={`${iconsClassName}`} />{" "}</span>
              <p>
                {" "} {BranchData?.gross}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  let currentDate: any = "";

  const handleSetDate = (data: any) => {
    currentDate = data;
    return <div className='max-content flex ai-center gap-4 bg-primary-50 text-primary-900 p-10 br-4 fs-link'><CalendarIcon />{data}</div>;
  };

  const RawStatusForCustomer = (props: any) => {
    return (
      <>
        <div className='grid fs-link'>
          <div className='flex-between gap-1 flex-wrap'>
            <div className="flex ai-center gap-4">
              <HoursIcon className="text-primary-400" />
              {setTimeFormat(props?.value?.trackingTime)}
            </div>
            <div className="flex ai-center gap-4 p-10 br-4 bg-info-50 text-info-900 fw-500">
              <TimelineIcon className="text-primary-400" />{" "}
              {props?.value?.action && `${props?.value?.action.toUpperCase()} `}
              {(props?.value?.action === "checkInAtLocation" || props?.value?.action === "checkOutAtLocation") && `At ${props?.value?.place?.name}`}
            </div>
          </div>
          <div className='flex ai-center gap-4'>
            <UserIcon className="text-primary-400" />
            {(props?.value?.action === "Booked" || props?.value?.action === "Accepted") ? props?.value?.customer?.name : props?.value?.user?.name?.fName}
          </div>
        </div>
      </>
    )
  }

  const getLoadingBranchLoaction = () => {
    let TempArr: any = [];
    {
      location?.state?.logsInfo.map((ele: any, index: any) => {
        if (ele?.action === "loading") {
          TempArr.push({ i: index, value: ele?.branch?.branchName })
        }
      })
    }
  }

  useEffect(() => {
    if (location.state.from === "Branch-Order-Track") {
      // setIsResolveIssue(
      //   BranchData?.reSolveIssues && BranchData?.reSolveIssues?.length > 0 ? true : false
      // );
    } else {
      getLoadingBranchLoaction();
    }
  }, [])

  useEffect(() => {
    console.log(`jhsdcnsdcvnsdv 2.0`, `sdvsdvwdv`, selectedPackageForLogs, `DASDFV`, location?.state, `SKebnscv`, props?.customerTrackingData);
    {
      location?.state?.AllProps?.PkgList?.find((g: any) => {
        if (g.order === location?.state?.orderNum) {
          setLogsData(g?.logs);
          setSelectedPackageForLogs({ label: g?.order, value: g?.order });
          console.log("defrlkvzdfvnadfv", g);
        }
      })
    }
  }, [location?.state?.orderNum]);

  useEffect(() => {
    // console.log(`jhsdcnsdcvnsdv 2.0`, `sdvsdvwdv`, location?.state);
    {
      location?.state?.AllProps?.PkgList?.find((g: any) => {
        if (g.order === selectedPackageForLogs.value) {
          setLogsData(g?.logs);
          // console.log("defrlkvzdfvnadfv", g);
        }
      })
    }
  }, [selectedPackageForLogs]);

  const GetMaterialType = () => {
    let ans2 = location?.state?.AllProps?.PkgList?.find((x: any) => selectedPackageForLogs?.value == x.order);
    // console.log("GetMaterialType", ans2, `Sjsdvbndv`);

    let lastStatus = location?.state?.AllProps?.statusArr[location?.state?.AllProps?.statusArr?.length - 1];
    const statusSplit = lastStatus.split("@");
    let ParcelReachedLastBranch: any = statusSplit[1];
    const steps = getSteps();
    function getSteps() {
      return [1, 2, 3, 4, 5];
    }

    let result, procStatus;
    switch (statusSplit[0]) {
      case "loading": {
        result = "In transit";
        procStatus = 1;
        break;
      }
      case "payment": {
        result = "Waiting for Delivery";
        procStatus = 3;
        // procStatus = "Waiting for Delivery";
        break;
      }
      case "unloading": {
        result = "Waiting for Delivery";
        procStatus = 2;
        // procStatus = "Waiting for Delivery";
        break;
      }
      case "booking": {
        result = "Booked";
        procStatus = 0;
        break;
      }
      case "delivery": {
        result = "Delivered";
        procStatus = 5;
        // procStatus = "Delivered";
        break;
      }
      case "cancel": {
        result = "Canceled";
        procStatus = 0;
        // procStatus = "Canceled";
        break;
      }
      case "cancelPending": {
        result = "Waiting for cancellation";
        // procStatus = "Waiting for cancellation";
        break;
      }
      case "review": {
        result = "Review";
        // procStatus = "Review";
        break;
      }
      case "missing": {
        result = "Missing";
        // procStatus = "Missing";
        break;
      }
      case "missingRequest": {
        result = "Missing Request";
        // procStatus = "Missing Request";
        break;
      }
      default: {
        result = "Booked";
        procStatus = 0;
      }
    }

    return (
      <div className="paper my-1">
        {mobileView ?
          <div className="grid gap-1">
            <div className='two-column gap-1'>
              <div className="flex ai-center gap-4" onClick={() => { alert('dfvjkdfvmn') }}>
                <PackageIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-500)" }} />
                <p> {location?.state?.AllProps?.PkgList?.length} </p>
              </div>
              <div className="flex ai-center gap-4">
                <MapPinIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />{" "}
                <p> {ParcelReachedLastBranch} </p>
              </div>
            </div>

            <div className="two-column gap-1">
              <div className="fs-link flex ai-center gap-4">
                <DropboxIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                <p>{ans2?.materialType && ans2?.materialType.name}</p>
              </div>
              <div className="fs-link flex ai-center gap-4">
                <PackageIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} />
                <p>{ans2?.packingType && ans2?.packingType.name}</p>
              </div>
            </div>

            <p className="p-10 py-1 border-white shadow-small br-4">
              <Stepper activeStep={procStatus} className={"singleProgressBar flex flex-wrap"}>
                {steps.map((label) => (
                  <Step key={label} className={"singleStepz"} >
                    <StepLabel></StepLabel>
                  </Step>
                ))}
              </Stepper>
              <p className="p-8 fs-link flex-center gap-4"><TimelineIcon className="text-primary-400" />{result}</p>
            </p>
          </div> :
          <div>
            <div className="flex ai-center jc-between">
              <p className="fs-link flex ai-center gap-4">
                <div><PackageIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-500)" }} /> </div>
                <div> {location?.state?.AllProps?.PkgList?.length} </div>
              </p>
              <p className="fs-link flex ai-center gap-4">
                <span><DropboxIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} /> </span>
                {ans2?.materialType && ans2?.materialType.name}
              </p>
              <p className="fs-link flex ai-center gap-4">
                <span><PackageIcon style={{ width: "28px", height: "28px", color: "var(--clr-primary-400)" }} /> </span>
                {ans2?.packingType && ans2?.packingType.name}
              </p>

              <p className="fs-link flex ai-center gap-4">
                <span><TimelineIcon className={`${iconsClassName}`} />{" "}</span>
                <div>
                  {ParcelReachedLastBranch}
                </div>
              </p>
            </div>
            <div className="progressBarDiv my-1 p-1 border-white shadow-small br-4">
              <Stepper activeStep={procStatus} className={"singleProgressBar"}>
                {steps.map((label) => (
                  <Step key={label} className={"singleStepz"} >
                    <StepLabel></StepLabel>
                  </Step>
                ))}
                <p className="lastResultName">{result}</p>
              </Stepper>
            </div>
          </div>}
      </div>
    )
  };

  return (
    <div className="w-100 max-w-1440 p-1 mx-auto">
      {mobileView ?
        <div>
          <div className="p-1 pt-0 bg-white-900 flex ai-center gap-8 relative fs-heading">
            <BackIcon className="text-primary-900" onClick={() => navigate(-1)} />
            <div className="" style={{ position: "absolute", left: "50%", transform: "translateX(-50%)" }}>
              <PageTitle title="Logs History" className="bg-transparent text-primary-900" style={{ fontSize: "clamp(1.75rem, 3vw, 2rem)" }} />
            </div>
          </div>

          {location?.state?.view === "Customer-Order" &&
            <div className='paper'>
              {BranchData?.logs?.map((ele: any) => {
                let time = ele?.trackingTime
                const date = new Date(time);
                return (
                  <div className='grid gap-10'>
                    <div>
                      {formatDate(date) !== currentDate
                        ? handleSetDate(formatDate(date))
                        : ""}
                    </div>
                    <div className='paper'>
                      <RawStatusForCustomer value={ele} />
                    </div>
                  </div>
                )
              })}
            </div>
          }

          {location?.state?.view === "Branch-Order" &&
            <div>
              <GetMaterialType />
            </div>
          }
          {location?.state?.view === "Branch-Order" &&
            <div className='paper mt-1 none-if-empty'>
              <Select
                label="Order Number"
                placeholder="Select Order Number"
                onChange={(e: any) => {
                  setSelectedPackageForLogs(e);
                }}
                value={selectedPackageForLogs}
                options={location?.state?.AllProps?.PkgList?.map((x: any) => { return { label: x?.order, value: x?.order } })}
              />
              {/* <div className="fs-link text-white-900 bg-primary-400 ai-center max-content p-8 mt-1 mb-1 br-8">{location?.state?.orderNum}</div> */}
              {logsData?.length > 0 ?
                <div className="mt-1">
                  {logsData?.map((item: any) => {
                    console.log(`props?.location?. 74dv24dv`, location?.state?.orderNum, `nsdjkcsdmnvsd`, item);
                    return (
                      <div className='containerForLogs'>
                        <div>
                          {formatDate(item?.trackingTime) !== currentDate
                            ? handleSetDate(formatDate(item?.trackingTime))
                            : ""}
                        </div>
                        <div className='paper mt-1'>
                          <div className="flex-between ai-center gap-1 flex-wrap">
                            <div className="flex-center gap-4">
                              <HoursIcon className={`${iconsClassName}`} />{setTimeFormat(item?.trackingTime)}
                            </div>
                            <div className="flex-center gap-4">
                              <TimelineIcon className={`${iconsClassName}`} />{item?.action && item?.action.toUpperCase()}{" "}
                              {item?.isQrScan && (
                                <QRCodeIcon
                                  className={`${iconsClassName}`}
                                />
                              )}
                            </div>
                            <div className="flex-center gap-4">
                              <LocationIcon className={`${iconsClassName}`} /> at {(item?.action === "loading" || item?.action === "inGstCustody") ? item?.regNumber : (item?.branch?.branchName ? item?.branch?.branchName : "No Branch Found")}
                            </div>
                            <div className="flex-center gap-4">
                              <UserIcon className={`${iconsClassName}`} />{item?.user?.name ? `${item?.user?.name?.fName} ${item?.user?.name?.lName}` : "No Name Found"}

                            </div>
                            {props.isResolveIssue && <div className="flex-center gap-4">
                              <ToolTip
                                title={
                                  <div style={{ fontSize: "14px" }}>Complaint Verification</div>
                                }
                              >
                                <InfoIcon className={`${iconsClassName}`} />
                              </ToolTip>
                            </div>}
                            {item?.action === "delivered" && <div>
                              <ButtonSimple onClick={() => console.log(`NOPOD`)}>
                                Attachments
                              </ButtonSimple>
                            </div>}
                            <div
                              onClick={() => {
                                if (item?.remarkImage && item.remarkImage?.length > 20) {
                                  setRemarkImage(item.remarkImage), setShowRemarkImages(true)
                                }
                                if (item.action === "delivery") {
                                  setRemarkImage(props?.customerTrackingData?.orderData?.docket?.receiverPhoto),
                                    setShowRemarkImages(true)
                                  setIsDeliveryImage(true)
                                }
                                else {
                                  showHttpError(`No Images Found`)
                                }
                              }}
                              className={`flex-center gap-4 pointer`}
                            >
                              <GalleryIcon className={`fs-link ${item?.remarkImage && item?.remarkImage?.length > 20 ? `text-primary-400` : `text-black-400`}`} />
                              <p className={`${item?.remarkImage && item?.remarkImage?.length > 20 ? `text-primary-400` :
                                item.action === "delivery" ? `text-primary-400` :
                                  `text-black-400`
                                }`}>
                                Image
                              </p>
                            </div>
                          </div>
                        </div>
                        {item?.remarks &&
                          <div className="track_remark_text">Remarks : {item?.remarks}</div>
                        }
                      </div>
                    );
                  })}
                </div>
                :
                <div>
                  <div className="heading">Select Any one Package Order Number</div>
                  <NoDataFound />
                </div>
              }
            </div>
            // <div className='paper grid gap-1 mt-1'>
            //   {location?.state?.logsInfo?.map((item: any) => {

            //     return (
            //       <div className=''>
            //         <div className="">
            //           {formatDate(item?.trackingTime) !== currentDate
            //             ? handleSetDate(formatDate(item?.trackingTime))
            //             : ""}
            //         </div>
            //         <div className='paper mt-1 fs-link grid gap-8'>
            //           <div className="flex-between gap-1 flex-wrap">
            //             <span className="flex ai-center gap-4"><HoursIcon className="text-primary-400" />{setTimeFormat(item?.trackingTime)}</span>
            //             <div className="span flex ai-center gap-4 bg-info-50 text-info-900 p-10 br-4 fw-500">
            //               <TimelineIcon />
            //               {item?.action && item?.action.toUpperCase()}{" "}
            //               {item?.isQrScan && (
            //                 <QRCodeIcon className={`${iconsClassName}`} />
            //               )}
            //             </div>
            //           </div>
            //           <div className="one-column gap-1">
            //             <span className="flex ai-center gap-4">
            //               <UserIcon className={`${iconsClassName}`} />
            //               {item?.user?.name ? `${item?.user?.name?.fName} ${item?.user?.name?.lName}` : "No Name Found"}
            //             </span>
            //             <span className="flex ai-center gap-4"><LocationIcon className={`${iconsClassName}`} />{item?.action === "loading" ? item?.regNumber : (item?.branch?.branchName ? item?.branch?.branchName : "No Branch Found")}</span>
            //           </div>
            //           <Button fullWidth variant="primary" className="flex-center" action="secondary" Icon={<GalleryIcon />}>Image</Button>
            //         </div>
            //       </div>
            //     );
            //   })}
            // </div>
          }
        </div>
        :
        <>
          <div className='flex-center flex-wrap gap-1'>
            <ButtonPrimary
              onClick={() => backToOrigin()}
              className="button-primary-primary fs-link py-8 min-w-auto"
            >
              <BackIcon />
            </ButtonPrimary>
            <div className='titleMainDiv'>
              <PageTitle title={"Logs History"} />
            </div>
          </div>

          {location?.state?.view === "Customer-Order" && <div className='p-1 br-10 shadow-small border-white mt-1'> <HeadingDivForCustomerOrder /> </div>}

          {location?.state?.view === "Branch-Order" && <div className='p-1 br-10 shadow-small border-white mt-1'> <HeadingDivForBranchOrder /> </div>}

          {location?.state?.view === "Customer-Order" &&
            <div className='paper mt-1'>
              {BranchData?.logs?.map((ele: any) => {
                console.log(`props?.location?.`, ele)
                let time = ele?.trackingTime
                const date = new Date(time);
                return (
                  <div className='containerForLogs'>
                    <div>
                      {formatDate(date) !== currentDate
                        ? handleSetDate(formatDate(date))
                        : ""}
                    </div>
                    <div className='paper mt-1'>
                      <RawStatusForCustomer value={ele} />
                    </div>
                  </div>
                )
              })}
            </div>
          }
          {location?.state?.view === "Branch-Order" &&
            <div>
              <GetMaterialType />
            </div>
          }
          {location?.state?.view === "Branch-Order" && (
            <div className='paper mt-1 none-if-empty'>
              <Select
                label="Order Number"
                placeholder="Select Order Number"
                onChange={(e: any) => {
                  setSelectedPackageForLogs(e);
                }}
                value={selectedPackageForLogs}
                options={location?.state?.AllProps?.PkgList?.map((x: any) => { return { label: x?.order, value: x?.order } })}
              />
              {/* <div className="fs-link text-white-900 bg-primary-400 ai-center max-content p-8 mt-1 mb-1 br-8">{location?.state?.orderNum}</div> */}
              {logsData?.length > 0 ?
                <div className="mt-1">
                  {logsData?.map((item: any) => {
                    console.log(`props?.location?. 74dv24dv`, location?.state?.orderNum, `nsdjkcsdmnvsd`, item);
                    return (
                      <div className='containerForLogs'>
                        <div>
                          {formatDate(item?.trackingTime) !== currentDate
                            ? handleSetDate(formatDate(item?.trackingTime))
                            : ""}
                        </div>
                        <div className='paper mt-1'>
                          <div className="flex-between ai-center gap-1 flex-wrap">
                            <div className="flex-center gap-4">
                              <HoursIcon className={`${iconsClassName}`} />{setTimeFormat(item?.trackingTime)}
                            </div>
                            <div className="flex-center gap-4">
                              <TimelineIcon className={`${iconsClassName}`} />{item?.action && item?.action.toUpperCase()}{" "}
                              {item?.isQrScan && (
                                <QRCodeIcon
                                  className={`${iconsClassName}`}
                                />
                              )}
                              {(item?.action === "loading" || item?.action === "unloading") && `${item?.branch?.branchName ? `( ${item?.branch?.branchName} )` : ''}`}
                            </div>
                            <div className="flex-center gap-4">
                              <LocationIcon className={`${iconsClassName}`} /> at {(item?.action === "loading" || item?.action === "inGstCustody") ? item?.regNumber : (item?.branch?.branchName ? item?.branch?.branchName : "No Branch Found")}
                            </div>
                            <div className="flex-center gap-4">
                              <UserIcon className={`${iconsClassName}`} />{item?.user?.name ? `${item?.user?.name?.fName} ${item?.user?.name?.lName}` : "No Name Found"}

                            </div>
                            {props.isResolveIssue && <div className="flex-center gap-4">
                              <ToolTip
                                title={
                                  <div style={{ fontSize: "14px" }}>Complaint Verification</div>
                                }
                              >
                                <InfoIcon className={`${iconsClassName}`} />
                              </ToolTip>
                            </div>}
                            {item?.action === "delivered" && <div>
                              <ButtonSimple onClick={() => console.log(`NOPOD`)}>
                                Attachments
                              </ButtonSimple>
                            </div>}
                            <div
                              onClick={() => {
                                if (item?.remarkImage && item?.remarkImage?.length > 20) {
                                  setRemarkImage(item.remarkImage), setShowRemarkImages(true)
                                }
                                if (item.action === "delivery") {
                                  setRemarkImage(props?.customerTrackingData?.orderData?.docket?.receiverPhoto),
                                    setShowRemarkImages(true)
                                  setIsDeliveryImage(true)
                                }
                                else {
                                  showHttpError(`No Images Found`)
                                }
                              }}
                              className={`flex-center gap-4 pointer`}
                            >
                              <GalleryIcon className={`fs-link ${item?.remarkImage && item?.remarkImage?.length > 20 ? `text-primary-400` :
                                item.action === "delivery" && props?.customerTrackingData?.orderData?.docket?.receiverPhoto?.length > 20 ? `text-primary-400` :
                                  `text-black-400`}`
                              } />
                              <p className={`${item?.remarkImage && item?.remarkImage?.length > 20 ? `text-primary-400` :
                                item.action === "delivery" && props?.customerTrackingData?.orderData?.docket?.receiverPhoto?.length > 20 ? `text-primary-400` :
                                  `text-black-400`
                                }`}>
                                Image
                              </p>
                            </div>
                          </div>
                          {item?.action === "booking" && location?.state?.BranchDetails?.docket?.remarks !== '' &&
                            <div className="track_remark_text">Remarks : {location?.state?.BranchDetails?.docket?.remarks}</div>
                          }
                          {item?.action === "delivery" && location?.state?.BranchDetails?.docket?.deliveryRemarks !== '' &&
                            <div className="track_remark_text">Remarks : {location?.state?.BranchDetails?.docket?.deliveryRemarks}</div>
                          }
                          {item?.remarks && item?.remarks !== "" &&
                            <div className="track_remark_text">Remarks : {item?.remarks}</div>
                          }
                        </div>
                      </div>
                    );
                  })}
                </div>
                :
                <div>
                  <div className="heading">Select Any one Package Order Number</div>
                  <NoDataFound />
                </div>
              }
            </div>)
          }
        </>
      }

      <Dialog state={showRemarkImages} setState={setShowRemarkImages} header={`${isDeliveryImage ? 'Delivery Person Image' : "Remark Image"}`} body={<MyImage objectID={remarkImage} pagination={false} rotate={false} />} size={"small"} />

    </div>
  )
}


const mapStateToProps = (state: RootState) => ({
  sub: state.user.loginType,
  entity:
    state.user.loginType === "B"
      ? state.user.opBranch._id
      : state.user.opFleet._id,
  customerTracking: state.customerBooking.tracking,
  user: state.customerLoginReducer,
  customerChatHistory: state.customerChatHistory,
  customerTrackingData: state.customerTracking,
  branchListData: state.branch.filteredBranches.data.Branches,
});

const mapDispatchToProps = {
  setCustomerBookingTracking,
  ResetCustomerTracking,
  showLoader,
  hideLoader
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTrackLogsDetails);