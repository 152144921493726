import { FaUserPlus } from "react-icons/fa";
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import DirectionsRendererComponent from "./DirectionRendererComponent";
import { connect } from "react-redux";
import { setCustomerBookingTracking } from "../../../actions/customerBooking";
import { RootState } from "../../../store";
import { useGoogleMapJsLoader } from "../../../hooks/useGoogleMapJsLoader";
let lebelArr = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"];

const CustomerTrackMapView = ({ route, isStarted }: any) => {
  // const { isLoaded } = useJsApiLoader({
  //   googleMapsApiKey: "AIzaSyA5kw1jdPoWOGfUDL0o2vW0Ezf2Cvq12OU",
  //   libraries: ["places"],
  //   nonce: 'nonce'
  // });
  const { isLoaded } = useGoogleMapJsLoader()
  // let Origin = route[0]?.placeId[0];
  // let Dest = route.sort((a:any, b:any) => a.placeIndex - b.placeIndex).filter((x:any) => !x.checkIn)[0].placeId[0];
  // let WayPoint = route.slice(1, -1);
  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2

  const [tripData, setTripData] = useState<any>({})

  useEffect(() => {
    if(route && route.length > 0){
      console.log(route, isStarted)
      if(isStarted){

        let origin:any = null
        let dest:any = null
        let wayPoint:any= null
  
        let data = route.sort((a:any, b:any) => a.placeIndex - b.placeIndex).filter((x:any) => !x?.checkOut)
        console.log("data",data)
        origin = data[0].placeId[0];
        dest = data[data.length - 1].placeId[0]
        wayPoint =  [...data.slice(1, -1)]

        setTripData(() => ({
          origin,
          dest,
          wayPoint
        }))
      }else{
        let origin:any = null
        let dest:any = null
        let wayPoint:any= null
  
        console.log('ssss')
        let data = route.sort((a:any, b:any) => a.placeIndex - b.placeIndex)

        origin = data[0]?.placeId[0];
        wayPoint =  data.slice(1, -1)
        dest = data.at(-1).placeId[0]
  
  
        setTripData(() => ({
          origin,
          dest,
          wayPoint
        }))
      }
    }
  },[isStarted, route])

  if (!isLoaded) {
    return <FaUserPlus />;
  }

  console.log('tripData', tripData)
  return (
    tripData?.origin && tripData?.dest && tripData?.wayPoint ? (
      <>
      {mobileView ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "250px",
            width: "100%",
            position: "relative",
            border: "0.2px solid transparent",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              left: "0",
              top: "0",
              width: "100%",
              height: "100%",
            }}
          >
            <GoogleMap
              center={{
                lat: tripData?.origin?.coordinate?.latitude,
                lng: tripData?.origin?.coordinate?.longitude,
              }}
              zoom={15}
              // defaultCenter={{lat: 28.539766666666665, lng: 77.05258166666667}}
              mapContainerStyle={{ width: "100%", height: "100%" }}
              options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,

                // center: new window.google.maps.LatLng(28.539766666666665, 77.05258166666667),
              }}
              onLoad={() => {}}
            >
              <>
                <MarkerClusterer>
                  {(clusterer: any) => (
                    <>
                      {tripData?.wayPoint &&
                        tripData?.wayPoint.length > 0 &&
                        tripData?.wayPoint?.map((e: any, i: any) => {
                          return (
                            <>
                              <Marker
                                position={{
                                  lat: e?.placeId[0].coordinate?.latitude,
                                  lng: e?.placeId[0].coordinate?.longitude,
                                }}
                                title="Place"
                                label={{
                                  text: lebelArr[i + 1],
                                  color: "white",
                                }}
                                clusterer={clusterer}
                              />
                            </>
                          );
                        })}
                      <Marker
                        position={{
                          lat: tripData?.origin?.coordinate?.latitude,
                          lng: tripData?.origin?.coordinate?.longitude,
                        }}
                        title="Origin"
                        label={{ text: lebelArr[0], color: "white" }}
                      />
                      <Marker
                        position={{
                          lat: tripData?.dest?.coordinate?.latitude,
                          lng: tripData?.dest?.coordinate?.longitude,
                        }}
                        title="Destination"
                        label={{
                          text: lebelArr[route.length - 1],
                          color: "white",
                        }}
                      />
                    </>
                  )}
                </MarkerClusterer>
                {/* <Marker
                  position={{
                    lat: driverLocation?.lat,
                    lng: driverLocation?.lng,
                  }}
                  title="Your Driver"
                  // icon={icon1}
                  icon={{ ...icon1, rotation: driverLocation?.position }}
                /> */}
                   <DirectionsRendererComponent
                  origin={tripData?.origin}
                  dest={tripData?.dest}
                  wayPoint={tripData?.wayPoint}
                />
              </>
            </GoogleMap>
          </Box>
          <></>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "450px",
            width: "100%",
            position: "relative",
            border: "0.2px solid transparent",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              left: "0",
              top: "0",
              width: "100%",
              height: "100%",
            }}
          >
            <GoogleMap
              center={{
                lat: tripData?.origin?.coordinate?.latitude,
                lng: tripData?.origin?.coordinate?.longitude,
              }}
              zoom={15}
              // defaultCenter={{lat: 28.539766666666665, lng: 77.05258166666667}}
              mapContainerStyle={{ width: "100%", height: "100%" }}
              options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
              }}
              onLoad={() => {}}
            >
              <>
                <MarkerClusterer>
                  {(clusterer: any) => (
                    <>
                      {tripData?.wayPoint &&
                        tripData?.wayPoint?.length > 0 &&
                        tripData?.wayPoint?.map((e: any, i: any) => {
                          return (
                            <>
                              <Marker
                                position={{
                                  lat: e?.placeId[0].coordinate?.latitude,
                                  lng: e?.placeId[0].coordinate?.longitude,
                                }}
                                title="Place"
                                label={{
                                  text: lebelArr[i + 1],
                                  color: "white",
                                }}
                                clusterer={clusterer}
                              />
                            </>
                          );
                        })}
                      <Marker
                        position={{
                          lat: tripData?.origin?.coordinate?.latitude,
                          lng: tripData?.origin?.coordinate?.longitude,
                        }}
                        title="Origin"
                        label={{ text: lebelArr[0], color: "white" }}
                      />
                      <Marker
                        position={{
                          lat: tripData?.dest?.coordinate?.latitude,
                          lng: tripData?.dest?.coordinate?.longitude,
                        }}
                        title="Destination"
                        label={{
                          text: lebelArr[route.length - 1],
                          color: "white",
                        }}
                      />
                    </>
                  )}
                </MarkerClusterer>
                {/* <Marker
                  position={{
                    lat: driverLocation?.lat,
                    lng: driverLocation?.lng,
                  }}
                  title="Your Driver"
                  icon={{ ...icon1, rotation: driverLocation?.position }}
                /> */}

                <DirectionsRendererComponent
                  origin={tripData?.origin}
                  dest={tripData?.dest}
                  wayPoint={tripData?.wayPoint}
                />
              </>
            </GoogleMap>
          </Box>
          <></>
        </div>
      )}
    </>
    ) : <></>
  );
};

const mapStateToProps = (state: RootState) => ({
  route: state.customerBooking.tracking.route
});

const mapDispatchToProps = {
  setCustomerBookingTracking
};

export default
  connect (
    mapStateToProps,
    mapDispatchToProps
  )(CustomerTrackMapView)
