export const testimonials = [
  {
    id: 0,
    name: "Charles Boyle",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/1.png",
    description:
      "Hi, I’m Rahul, and I love working at TapTap! The positive work culture makes each day a joy. Seeing customers smile when they get their deliveries is incredibly rewarding. I’m proud to be part of this amazing team!",
  },
  {
    id: 1,
    name: "Gina Lannety",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/2.png",
    description:
      "Joining TapTap as a Delivery Partner has been incredibly fulfilling. The quick application and onboarding process got me delivering fast. Their comprehensive joining kit and supportive call assistant team made my experience smooth. I appreciate the value TapTap places on its delivery partners.",
  },
  {
    id: 2,
    name: "Dug Judey",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "Hello, I'm Harsh, and as a delivery partner, I've found financial stability and empowerment through assured income, timely payments, and fantastic incentives. This job offers steady earnings and great perks, making it ideal for anyone seeking stability and career growth.",
  },
  {
    id: 3,
    name: "Dug Judey",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "My delivery partner journey has been transformatively focused on personal growth and chasing dreams. This role fuels my ambitions daily, driving real progress in my life and career, as I learn, adapt, and achieve. It's not just a job; it's a journey of continuous growth and achievement.",
  },
  {
    id: 4,
    name: "Dug Judey",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "Working with TapTap gives me peace of mind with medical insurance and vehicle use. Efficient last-mile delivery management and technology simplify my work, focusing on excellent service. TapTap offers stability and independence.",
  },
  {
    id: 5,
    name: "Dug Judey",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "As a delivery partner has been incredibly rewarding. The supportive team fosters a sense of community, making every day enjoyable. My overall experience has been filled with happiness and growth.",
  },
];

export const individualWithFleetTestimonials = [
  {
    id: 0,
    name: "Charles Boyle",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/1.png",
    description:
      "Hi, I’m Rahul, and I love working at TapTap! The positive work culture makes each day a joy. Seeing customers smile when they get their deliveries is incredibly rewarding. I’m proud to be part of this amazing team!",
  },
  {
    id: 1,
    name: "Gina Lannety",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/2.png",
    description:
      "Joining TapTap as a Delivery Partner has been incredibly fulfilling. The quick application and onboarding process got me delivering fast. Their comprehensive joining kit and supportive call assistant team made my experience smooth. I appreciate the value TapTap places on its delivery partners.",
  },
  {
    id: 2,
    name: "Dug Judey",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "Hello, I'm Harsh, and as a delivery partner, I've found financial stability and empowerment through assured income, timely payments, and fantastic incentives. This job offers steady earnings and great perks, making it ideal for anyone seeking stability and career growth.",
  },
  {
    id: 3,
    name: "Dug Judey",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "My delivery partner journey has been transformatively focused on personal growth and chasing dreams. This role fuels my ambitions daily, driving real progress in my life and career, as I learn, adapt, and achieve. It's not just a job; it's a journey of continuous growth and achievement.",
  },
  {
    id: 4,
    name: "Dug Judey",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "Working with TapTap gives me peace of mind with medical insurance and vehicle use. Efficient last-mile delivery management and technology simplify my work, focusing on excellent service. TapTap offers stability and independence.",
  },
  {
    id: 5,
    name: "Dug Judey",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "As a delivery partner has been incredibly rewarding. The supportive team fosters a sense of community, making every day enjoyable. My overall experience has been filled with happiness and growth.",
  },
];

export const intraCityTestimonials = [
  {
    id: 0,
    name: "Ravi - Home Theater Manufacturer",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/1.png",
    description:
      "TapTap's app is easy to use with budget-friendly prices. I get discount coupons with every booking.  For hassle-free, same-day delivery without maintaining your own fleet 24/7, try TapTap anytime.",
  },
  {
    id: 1,
    name: "Jay - Power Tools Enterprise Owner",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/2.png",
    description:
      "Having used TapTap for over a year, I'm thrilled with their service great call support, regular discounts, easy booking, quick partner allotment, and competitive pricing. TapTap is a must for your business!",
  },
  {
    id: 2,
    name: "Neelam - Electrical Lighting Manufacturer",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "TapTap offers great service with timely pickup and drop-offs at affordable prices compared to other apps. It's made arranging timely vehicle pickups much easier—sometimes, they even pick up from multiple locations, which is very convenient!",
  },
  {
    id: 3,
    name: "",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "TapTap in Delhi is equivalent to Mumbai's Dabbawallas So convenient. TapTap has made life so easy. These days I am getting food delivered in my office from my home through TapTap",
  },
  {
    id: 4,
    name: "Harteerath Singh",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "Thank you, TapTap, for partnering with hemkunt_foundation to deliver meals to COVID-19 patients! Collaboration and leveraging strengths efficiently are crucial in life! #COVID19India",
  },
  {
    id: 5,
    name: "",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "When you need to send Chicken Rice to your favorite Community Puppy who is recovering from Surgery at the Vet, Who You Gonna Call? TapTap thank you. Chotu got his post-surgery Chicken Rice meals.",
  },
  {
    id: 6,
    name: "Nimish",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "I love TapTap. Had to get some OTC medicines instantly for someone in need and your TapTap 'delivered it within 10 minutes. Wholeheartedly appreciate such service.",
  },
  {
    id: 7,
    name: "",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "Recently experienced TapTap service by TapTap honestly was a great experience. I left my laptop charger in the office and had a client call to discuss the mobile app. TapTap you guys are a savior.",
  },
  {
    id: 8,
    name: "",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "An appreciating message for in Your new service 'TapTap' is of immense help for delivering food to my loved ones. Your delivery boys are working faster than you think they are!",
  },
  {
    id: 9,
    name: "",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "The last day I used TapTap for the first time my delivery partner came in a few minutes, spoke impeccable queen English carried with a sophisticated demeanor, and delivered in the most time-efficient manner.",
  },
  {
    id: 10,
    name: "",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      " What I simply love about the TapTap experience. It has helped me so many times that I have lost count. Whether it was to send some document home cook food or even get medicines delivered the TapTap never stops. Thank you so much TapTap team.",
  },
  {
    id: 11,
    name: "Supply Chain Director at FMCG Brand",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "TapTap has transformed our supply chain with cost-effective, eco-friendly delivery solutions, streamlining distribution, and improving efficiency.",
  },
  {
    id: 12,
    name: "",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "TapTap's cost-effective delivery services have slashed our transportation expenses, boosting our financials and service quality, and enhancing our market competitiveness.",
  },
  {
    id: 13,
    name: "Dinesh - Mumbai",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "Excellent service by multiple drivers. I own a business and do multiple shifts. Rather than ask local drivers and bargain every time, I use a tap which fulfills all my needs. Thanks a lot!",
  },
  {
    id: 14,
    name: "Syamantak Mitra - Hyderabad",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "Smooth experience with TapTap's packers and movers! The team was fantastic, handling every detail. Highly recommend their services!",
  },
  {
    id: 15,
    name: "Ali Hemmati, Owner (PaRaDISE)",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "The kind of service TapTap for Business provides is way above their delivery charges.",
  },
  {
    id: 16,
    name: "Sushil Kanugolu, MD & Founder (Fipola)",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "TapTap helps us reach more customers on autopilot without having to manage our fleet.",
  },
  {
    id: 17,
    name: "Srikanth B, Cofounder (OOTABOX)",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "There’s nothing we have to do once we’ve placed our order on the Dashboard since TapTap takes care of the end-to-end order.",
  },
];

export const partLoadAndFullLoad = [
  {
    id: 0,
    name: "Mr. Sagar Kulkarni",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/1.png",
    description:
      "I am satisfied with the service and support process. The delivery process is also very smooth. I will use this service again in the future.",
  },
  {
    id: 1,
    name: "",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/2.png",
    description:
      "Excellent service from TapTap! On-time delivery, hassle-free truck booking, affordable rates, safe handling with real-time pricing and insurance assistance. Timely updates, seamless booking, and prompt material shipment. Highly satisfied with the quality and interface.",
  },
  {
    id: 2,
    name: "Javith",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "I'm pleased with the satisfactory services and quick response I received. The delivery also met my needs perfectly. Overall, the experience was excellent. It's a trucker's dream! Advance payment in 30 minutes, balance the next day, no deductions, and complete transparency through the app.",
  },
  {
    id: 3,
    name: "Yaduveer Singh",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "The TapTap is providing the best service. This application helped me find loads easily. Thank you.",
  },
  {
    id: 4,
    name: "Vivek Kumar",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "TapTap provides excellent service. The app makes finding loads easy and offers reliable GPS locations. I also use GPS and Fastag, and both work perfectly. Highly satisfied and thankful!",
  },
  {
    id: 5,
    name: "Rohit Nema",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "I had a Wonderful experience. I have been using your service for the last 2 years, and it's been amazing. The TapTap team is excellent and supportive in resolving any issues.",
  },
  {
    id: 6,
    name: "Shwetali P. Jangam",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "They have a young and dynamic team, knowledgeable and professional, passionate about exceeding customer expectations with high levels of satisfaction. Congratulations to the team, wishing them success in all future endeavors! Reliable for 6 years, delivering consignments promptly with a customer-oriented approach. Their daily MIS updates are invaluable. Working with their dedicated and talented team is a joy.",
  },
  {
    id: 7,
    name: "Shyam",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "The container truck transport service is outstanding in quality and rates. Their service is top-notch, offering any type of container truck within minutes. Goods are always delivered without damage.",
  },
  {
    id: 8,
    name: "Pushparaj",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "They provide cost-effective transportation solutions, ensuring safe product delivery with advanced IT systems and reliable service. Offering ample loads, 24/7 support, and an easy-to-use Trucker app, they come highly recommended!",
  },
  {
    id: 9,
    name: "",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "I'm impressed by TapTap's seamless load handling and rapid response times. Their dedication to on-time deliveries was evident throughout our collaboration. I wholeheartedly endorse their prompt, responsive, and well-organized logistics services across Pan India.",
  },
  {
    id: 10,
    name: "Sushil Sinha",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "TapTap stands out with its competitive pricing, professionalism, and prompt customer assistance. Their application is highly effective, simplifying booking and tracking. I recommend their services to anyone needing reliable logistics solutions.",
  },
];

export const expressParcelBranchToBranchTestimonials = [
  {
    id: 0,
    name: "Harsh S.",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/1.png",
    description:
      "Choosing TapTap for my parcel deliveries was the best decision. Their reliable service ensures my packages arrive on time, providing financial stability and peace of mind. The fantastic incentives and timely payments make working with TapTap truly rewarding.",
  },
  {
    id: 1,
    name: "Priya V.",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/2.png",
    description:
      "TapTap's parcel service has transformed my business logistics. The efficient door-to-door delivery and exceptional customer support have made my operations smoother and more cost-effective. Highly recommended!",
  },
  {
    id: 2,
    name: "Rahul K.",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "I've been using TapTap for all my shipping needs, and I couldn't be happier. Their extensive network and safe transport guarantee that my parcels reach their destination without issues. TapTap is the epitome of excellence in logistics.",
  },
  {
    id: 3,
    name: "",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "TapTap has been a game-changer for my small business. Their reliable and efficient parcel service ensures my products reach customers on time, every time. The seamless tracking and exceptional customer support have given me the confidence to focus on growing my business. TapTap truly delivers excellence.",
  },
];

export const expressParcelDoorDeliveryTestimonials = [
  {
    id: 0,
    name: "Ativeer (Chennai)",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/1.png",
    description:
      "TapTap courier service is one of the best professional courier services. I book most of my couriers through TapTap, and parcels arrive on time. They provide tracking updates, minimizing the risk of losing parcels. Their delivery is fast and very secure. I am happy with their service and recommend them.",
  },
  {
    id: 1,
    name: "Mohammad Alam (Hyderabad):",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/2.png",
    description:
      "I want to compliment your excellent service. Your entire team always goes to great lengths to help me enhance my business. The team knows just what to do to solve my issues quickly and always comes with a solution-oriented approach. Such service builds great working relationships.",
  },
  {
    id: 2,
    name: "Nestasia",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "TapTap's efficient NDR management, dedicated Account Manager and RTO predictor tool helped us reduce returns and shipping costs.",
  },
  {
    id: 3,
    name: "Pandit",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "TapTap helped us reduce our response time to under two hours, thereby building brand trust, reducing costs on shipping high-volume orders, and improving delivery speed.",
  },
  {
    id: 4,
    name: "Abhijit Kumar, New Delhi",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "I had to ship medicines to my sister, and TapTap came to my rescue. We thank you the TapTap team and every agent for ensuring that our packages arrive on time, even during the pandemic.",
  },
  {
    id: 5,
    name: "Arghya, Gurugram",
    rating: 5,
    img: "/assets/landing-page/testimonials/partners/individual-with-fleet/3.png",
    description:
      "I was able to use TapTap Direct to ship goods to my family. We had concerns about whether the parcel would arrive on time, but after sending the first shipment, I knew that this would never be a problem with TapTap.",
  },
];

export const testimonialsData = [
  {
    id: 0,
    page: "partners",
    partner: "Individual With Fleet",
    testimonials: individualWithFleetTestimonials,
  },
  {
    id: 1,
    page: "services",
    service: "Intra City",
    testimonials: intraCityTestimonials,
  },
  {
    id: 2,
    page: "services",
    service: "Part Load & Full Load",
    testimonials: partLoadAndFullLoad,
  },
  {
    id: 3,
    page: "services",
    service: "Express Parcel",
    expressParcelType: "Branch to Branch",
    testimonials: expressParcelBranchToBranchTestimonials,
  },
  {
    id: 4,
    page: "services",
    service: "Express Parcel",
    expressParcelType: "Door Delivery",
    testimonials: expressParcelDoorDeliveryTestimonials,
  },
];
