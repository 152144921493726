import React from 'react'
import { PageHeader } from "../UI"
import './user-agreement.css';

const UserAgreement = () => {
    return (
        <main className="page-container-grid">
            <PageHeader title="User Agreement" imgSrc="user-agreement/bg.jpg" />
            <div className="p-3 privacy-policy-container">
                {/* <aside className="privacy-policy-sidebar capitalize">
                    <ul>
                        <li className="fs-medium fw-400">Getting Started</li>
                        <li className="fs-medium fw-400">Overview</li>
                    </ul>
                    <ul>
                        <li className="fs-medium fw-400">Privacy</li>
                        <li className="fs-medium fw-400">TapTap Privacy Policy</li>
                    </ul>
                    <ul>
                        <li className="fs-medium fw-400" onClick={() => scrollToSection(whatWeCollectRef)}>What we collect</li>
                        <li className="fs-medium fw-400">Overview</li>
                    </ul>
                    <ul>
                        <li className="fs-medium fw-400">User Generated Content (UGC)</li>
                        <li className="fs-medium fw-400">Overview</li>
                    </ul>
                    <ul>
                        <li className="fs-medium fw-400">Surveys</li>
                    </ul>
                    <ul>
                        <li className="fs-medium fw-400">Compliance</li>
                        <li className="fs-medium fw-400">Cookies</li>
                        <li className="fs-medium fw-400">Automatic Logging of Session Data</li>
                    </ul>
                    <ul>
                        <li className="fs-medium fw-400">No Sharing: My Personal Information</li>
                        <li className="fs-medium fw-400">Service Providers and suppliers</li>
                        <li className="fs-medium fw-400">Companies in the same group</li>
                        <li className="fs-medium fw-400">Business partners and third-party vendors</li>
                        <li className="fs-medium fw-400">Disclosure Of Information</li>
                    </ul>
                    <ul>
                        <li className="fs-medium fw-400">Your rights Qua Personal information</li>
                    </ul>
                    <ul>
                        <li className="fs-medium fw-400">Eligibility to transact with TAPTAP</li>
                    </ul>
                    <ul>
                        <li className="fs-medium fw-400">Contact us</li>
                    </ul>
                </aside> */}
                <section className="my-container flex-center">
                    <section className="privacy-policy-content flex-col gap-2">
                        {/* Overview */}
                        <div className="flex-col gap-1">
                            <h1 className="fs-subheading m-0 fw-600">Overview</h1>
                            <p className="fs-medium fw-400 m-0 fw-400">
                                Our company values your privacy and is committed to protecting your personal information. We collect various types of information from our users, including names, email addresses, postal addresses, and payment details, to provide and improve our services. We take stringent measures to safeguard your data, employing industry-standard security protocols to prevent unauthorized access, use, or disclosure.
                            </p>
                            <p className="fs-medium fw-400 m-0">
                                This information is used for purposes such as processing orders, communicating with customers, and personalizing user experience. We may share certain personal information with trusted third-party service providers to facilitate these activities, but we do not sell or rent your information to third parties for marketing purposes.
                            </p>
                            <p className="fs-medium fw-400 m-0">
                                By using our services, you consent to the collection, use, and sharing of your information as described in this policy. We regularly review and update our privacy practices to ensure compliance with applicable laws and to maintain your trust.
                            </p>
                        </div>
                        {/* Privacy Policy */}
                        <div className="flex-col gap-1">
                            <h1 className="fs-subheading m-0 fw-600">Privacy</h1>
                            <p className="fs-medium fw-400 m-0">
                                This Privacy Policy provides for the practices for handling and securing user's Personal Information (defined hereunder) by TapTap and its subsidiaries and affiliates.
                            </p>
                            <p className="fs-medium fw-400 m-0">
                                This Privacy Policy is applicable to any person (‘User’) who purchases, intends to purchase, or inquire about any product(s) or service(s) made available by TapTap through any of redBus’s customer interface channels including its website, mobile site, mobile app & offline channels including call centers and offices (collectively referred herein as "Sales Channels").
                            </p>
                            <p className="fs-medium fw-400 m-0">
                                TapTap may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from 23rd February 2014.
                            </p>
                        </div>
                        {/* What We Collect */}
                        <div className="flex-col gap-1">
                            <h1 className="fs-subheading m-0 fw-600">What We Collect</h1>
                            <p className="fs-medium fw-400 m-0">We may collect the following information:</p>
                            <div className="px-1">
                                <ul className="m-0" style={{ listStyle: "disc" }}>
                                    <li className="fs-medium fw-400">Name and job title.</li>
                                    <li className="fs-medium fw-400">Contact information including email address.</li>
                                    <li className="fs-medium fw-400">Customize the content of our website, mobile site and mobile app.</li>
                                    <li className="fs-medium fw-400">Send any updates or changes to your booking(s).</li>
                                </ul>
                            </div>
                        </div>
                        {/* User Generated Content */}
                        <div className="flex-col gap-1">
                            <h1 className="fs-subheading m-0 fw-600">User Generated Content</h1>
                            <p className="fs-medium fw-400 m-0">TapTap provides an option to its users to post their experiences by way of review, ratings and general poll questions. The customers also have an option of posting questions w.r.t a service offered by TapTap or post answers to questions raised by other users. TapTap may also hire a third party to contact you and gather feedback about your recent booking with TapTap. Though the participation in the feedback process is purely optional, you may still receive emails, notifications (SMS, Whatsapp or any other messaging service) for you to share your feedback. The reviews may be written or in a video format. The reviews written or posted may also be visible on other travel or travel related platforms</p>
                            <p className="fs-medium fw-400 m-0">We may collect the following information:</p>
                            <div className="px-1">
                                <ul className="m-0" style={{ listStyle: "disc" }}>
                                    <li className="fs-medium fw-400">Review and Ratings.</li>
                                    <li className="fs-medium fw-400">Question and Answers.</li>
                                    <li className="fs-medium fw-400">Crowd Source Data Collection (poll questions).</li>
                                </ul>
                            </div>
                        </div>
                        {/* Surveys */}
                        <div className="flex-col gap-1">
                            <h1 className="fs-subheading m-0 fw-600">Surveys</h1>
                            <p className="fs-medium fw-400 m-0">We value opinions and comments from our Users and frequently conduct surveys, both online and offline. Participation in these surveys is entirely optional. Typically, the information received is aggregated, and used to make improvements to Website, other Sales Channels, services and to develop appealing content, features and promotions for members based on the results of the surveys. Identity of the survey participants is anonymous unless otherwise stated in the survey.</p>
                        </div>
                        {/* Compliance */}
                        <div className="flex-col gap-1">
                            <h1 className="fs-subheading m-0 fw-600">Compliance</h1>
                            <div className="fs-body flex-col gap-8">
                                <span className="fw-500 fs-medium">Cookies</span>
                                <p className="fs-medium fw-400 m-0">Cookies are small pieces of information that are stored by your browser on your device's hard drive. Cookies allow us to serve you better and more efficiently. Cookies also allow ease of access, by logging you in without having to type your login name each time (only your password is needed); we may also use such cookies to display any advertisement(s) to you while you are on the Website or to send you offers (or similar emails – provided you have not opted out of receiving such emails) focusing on destinations which may be of your interest.</p>
                            </div>
                            <div className="fs-body flex-col gap-8">
                                <span className="fw-500 fs-medium">Automatic Logging of Session Data</span>
                                <p className="fs-medium fw-400 m-0">Each time you access the Website your session data gets logged. Session data may consist of various aspects like the IP address, operating system and type of browser software being used and the activities conducted by the User while on the Website. We collect session data because it helps us analyze User’s choices, browsing pattern including the frequency of visits and duration for which a User is logged on. It also helps us diagnose problems with our servers and lets us better administer our systems. The aforesaid information cannot identify any User personally. However, it may be possible to determine a User's Internet Service Provider (ISP), and the approximate geographic location of User's point of connectivity through the above session data.</p>
                            </div>
                        </div>
                        {/* \WITH WHOM YOUR PERSONAL INFORMATION IS SHARED */}
                        <div className="flex-col gap-1">
                            <h1 className="fs-subheading m-0 fw-600">No Sharing: My Personal Information</h1>
                            <div className="fs-body flex-col gap-8">
                                <span className="fw-500 fs-medium">Service Providers And Suppliers</span>
                                <p className="fs-medium fw-400 m-0">Your information shall be shared with the end service providers like hotels, bus service providers, cab rental or any other suppliers who are responsible for fulfilling your booking. You may note that while making a booking with TapTap you authorize us to share your information with the said service providers and suppliers. It is pertinent to note that TapTap does not authorize the end service provider to use your information for any other purpose(s) except as may be for fulfilling their part of service. However, how the said service providers/suppliers use the information shared with them is beyond the purview and control of TapTap as they process Personal Information as independent data controllers, and hence we cannot be made accountable for the same. You are therefore advised to review the privacy policies of the respective service provider or supplier whose services you choose to avail.</p>
                            </div>
                            <div className="fs-body flex-col gap-8">
                                <span className="fw-500 fs-medium">Companies In The Same Group</span>
                                <p className="fs-medium fw-400 m-0">In the interests of improving personalization and service efficiency, we may, under controlled and secure circumstances, share your Personal Information with our affiliate or associate entities.</p>
                                <p className="fs-medium fw-400 m-0">If the assets of TapTap are acquired, our customer information may also be transferred to the acquirer depending upon the nature of such acquisition. In addition, as part of business expansion/development/restructuring or for any other reason whatsoever, if we decide to sell/transfer/assign our business, any part thereof, any of our subsidiaries or any business units, then as part of such restructuring exercise customer information including the Personal Information collected herein shall be transferred accordingly.</p>
                            </div>
                            <div className="fs-body flex-col gap-8">
                                <span className="fw-500 fs-medium">Business Partners And Third-Party Vendors</span>
                                <p className="fs-medium fw-400 m-0">We may also share certain filtered Personal Information to our corporate affiliates or business partners who may contact the customers to offer certain products or services, which may include free or paid products / services, which will enable the customer to have better travel experience or to avail certain benefits specially made for TapTap customers. Examples of such partners are entities offering co-branded credit cards, travel insurance, insurance cover against loss of wallet, banking cards or similar sensitive information etc. If you choose to avail any such services offered by our business partners, the services so availed will be governed by the privacy policy of the respective service provider.</p>
                                <p className="fs-medium fw-400 m-0">TapTap may share your Personal Information to third party that TapTap may engage to perform certain tasks on its behalf, including but not limited to payment processing, data hosting, and data processing platforms.</p>
                            </div>
                            <div className="fs-body flex-col gap-8">
                                <span className="fw-500 fs-medium">Disclosure Of Information</span>
                                <p className="fs-medium fw-400 m-0">In addition to the circumstances described above, TapTap may disclose User's Personal Information if required to do so:</p>
                                <div className="px-1">
                                    <ul className="m-0" style={{ listStyle: "disc" }}>
                                        <li className="fs-medium fw-400">by law, required by any enforcement authority for investigation, by court order or in reference to any legal process.</li>
                                        <li className="fs-medium fw-400">to conduct our business.</li>
                                        <li className="fs-medium fw-400">for regulatory, internal compliance and audit exercise(s).</li>
                                        <li className="fs-medium fw-400">to secure our systems.</li>
                                        <li className="fs-medium fw-400">to enforce or protect our rights or properties of TapTap or any or all of its affiliates, associates, employees, directors or officers or when we have reason to believe that disclosing Personal Information of User(s) is necessary to identify, contact or bring legal action against someone who may be causing interference with our rights or properties, whether intentionally or otherwise, or when anyone else could be harmed by such activities.</li>
                                    </ul>
                                </div>
                                <p className="fs-medium fw-400 m-0">Such disclosure and storage may take place without your knowledge. In that case, we shall not be liable to you or any third party for any damages howsoever arising from such disclosure and storage.</p>
                            </div>
                        </div>
                        {/* YOUR RIGHTS QUA PERSONAL INFORMATION */}
                        <div className="flex-col gap-1">
                            <h1 className="fs-subheading m-0 fw-600">Your Rights Qua Personal Information</h1>
                            <p className="fs-medium fw-400 m-0">
                                You may access your Personal Information from your user account with TapTap. You may also correct your personal information or delete such information (except some mandatory fields) from your user account directly. If you don’t have such a user account, then you write to privacy@TapTap.com
                            </p>
                        </div>
                        {/* YOUR RIGHTS QUA PERSONAL INFORMATION */}
                        <div className="flex-col gap-1">
                            <h1 className="fs-subheading m-0 fw-600">Eligibility To Transact With TAPTAP</h1>
                            <p className="fs-medium fw-400 m-0">
                                We reserve the rights to revise the Privacy Policy from time to time to suit various legal, business and customer requirement. We will duly notify the users as may be necessary.
                            </p>
                        </div>
                        {/* Contact Us */}
                        <div className="flex-col gap-1">
                            <h1 className="fs-subheading m-0 fw-600">Contact Us</h1>
                            <p className="fs-medium fw-400 m-0">If there are any questions regarding this privacy policy or if you wish to report a breach of the Privacy Policy, you may contact us using the information on the support page.
                            </p>
                        </div>
                    </section>
                </section>
            </div>
        </main>
    )
}

export default UserAgreement